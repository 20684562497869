import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'
import moment from 'moment'

const style = () => ({

})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

class MultipleLevelsLineChart extends Component {
  componentDidMount() {
    const chartElement = document.getElementById('lineLevels')
    const myChartRef = chartElement.getContext('2d');
    chartElement.height = '250'
    const { processedData } = this.props
    const { labels, data, dates } = processedData
    const yLabels = ['Sin Apoyo', 'Poco Apoyo', 'Apoyo Ocasional', 'Bastante Apoyo', 'Apoyo Total']

    this.chart = new Chart(myChartRef, {
      type: 'line',
      data: {
        // Bring in data
        labels,
        datasets: [
          {
            fill: false,
            data,
            backgroundColor: colors,
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              minRotation: 0,
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            offset: true,
          },
          {
            id: 'xAxis2',
            type: 'category',
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'italic',
              min: 1,
              max: 5,
              stepSize: 1,
              minRotation: 0,
              maxRotation: 90,
              callback: (value, index) => moment(dates[index]).format('DD/MM/YYYY')
            },
            gridLines: {
              display: false,
              drawOnChartArea: false, // only want the grid lines for one axis to show up
              drawBorder: false
            },
            offset: true,
          }
          ],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 1,
              max: 5,
              stepSize: 1,
              callback: value => yLabels[value - 1]
            },
            gridLines: {
              display: true,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        },
        legend: {
          display: false
        }
      }
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <canvas id="lineLevels" ref={this.chartRef} />
      </div>
    );
  }
}

MultipleLevelsLineChart.propTypes = {
  classes: propTypes.object.isRequired,
  processedData: propTypes.object.isRequired,
}

MultipleLevelsLineChart.defaultProps = {

}
export default withStyles(style)(MultipleLevelsLineChart)
