/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { weekDays } from '../../Utils/constants'
import CalendarEvent from './CalendarEvent'
import EventOverflow from './EventOverflow'
import EventsDetail from './EventsDetail'
import './animation.css'

const minHeight = 570
const EVENT_LIMIT = Math.floor((window.innerHeight - minHeight) / 140) + 1

const style = theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns:
      'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
    gridTemplateRows:
      'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
    gridGap: 2,
    height: '100%',
    boxSizing: 'border-box',
  },
  card: {
    background: 'white',
    color: 'black',
    textAlign: 'left',
  },
  todayCard: {
    background: 'white',
    textAlign: 'left',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    boxShadow: `0px 2px 4px ${theme.palette.primary.main}`,
  },
  greyCard: {
    background: theme.palette.indigo.lighter,
    color: 'grey',
    textAlign: 'left',
  },
  date: {
    margin: '5% 0',
    padding: '0 5%',
  },
  currentDate: {
    margin: '5% 0',
    padding: '0 5%',
    width: 'fit-content',
    background: theme.palette.primary.dark,
    fontColor: 'white',
    color: 'white',
    borderRadius: 3,
  },
})

class CalendarDays extends Component {
  constructor(props) {
    super(props)
    this.state = {
      monthDays: [],
      eventList: {},
      eventsDetail: false,
      dayEventsClicked: [],
      selectedDate: moment(new Date()),
    }
    this.closeEventDetails = this.closeEventDetails.bind(this)
    this.openEventDetails = this.openEventDetails.bind(this)
    this.handleSelectDay = this.handleSelectDay.bind(this)
  }

  componentDidMount() {
    this.renderEvents()
  }

  componentDidUpdate(newProps) {
    const { currentDate, events } = this.props
    if (
      JSON.stringify(newProps.currentDate) !== JSON.stringify(currentDate)
      || JSON.stringify(newProps.events) !== JSON.stringify(events)
    ) {
      this.renderEvents()
    }
  }

  getDays() {
    const {
      classes,
      currentDate,
      location: { search },
    } = this.props
    const { selectedDate } = this.state
    const currentMonth = moment(currentDate).format('MM')
    const currentYear = moment(currentDate).format('YYYY')
    const isSelectedMonth = selectedDate.format('MM') === currentMonth
    const isSelectedYear = selectedDate.format('YYYY') === currentYear
    const searchedDayNumber = parseInt(moment(search).format('DD'), 10) - 1
    const selectedDateNumber = parseInt(selectedDate.format('DD'), 10) - 1
    const isSelected = isSelectedMonth && isSelectedYear && selectedDateNumber
    const searchedDay = searchedDayNumber || isSelected
    const amountPastMonth = moment(currentDate)
      .subtract(1, 'months')
      .daysInMonth()
    const amountCurrentMonth = moment(currentDate).daysInMonth()
    const currentDay = parseInt(moment(new Date()).format('DD'), 10) - 1 // Starts at 1
    const firstDay = moment(currentDate)
      .startOf('month')
      .format('dddd')
    const dayIndex = weekDays.indexOf(firstDay)
    const monthDays = []
    const { eventList } = this.state
    let i = 0
    let j = 0
    while (i < dayIndex) {
      const day = amountPastMonth - dayIndex + i + 1
      monthDays.push(
        <div className={classes.greyCard} key={j}>
          <div className={classes.date}>
            <Typography style={{ fontSize: 15, color: 'unset' }}>{day}</Typography>
          </div>
        </div>
      )
      i += 1
      j += 1
    }
    i = 0
    while (i < amountCurrentMonth) {
      const day = i + 1
      const dayEvents = eventList[j] ? eventList[j].slice(0, EVENT_LIMIT) : []
      const eventsOverflow = eventList[j] ? eventList[j].length > EVENT_LIMIT : false
      const currentMoment = moment(`${currentYear}-${currentMonth}-${day}`)
      monthDays.push(
        <div
          className={i === searchedDay ? 'todayCard' : classes.card}
          key={j}
          onClick={this.handleSelectDay(currentMoment)}
        >
          <div
            className={
              i === currentDay && moment().format('MM') === currentMonth
                ? classes.currentDate
                : classes.date
            }
          >
            <Typography style={{ fontSize: 15, color: 'unset' }}>{day}</Typography>
          </div>
          {dayEvents}
          {eventsOverflow && (
            <EventOverflow events={eventList[j]} handleOpen={this.openEventDetails(eventList[j])} />
          )}
        </div>
      )
      i += 1
      j += 1
    }
    i = 1
    const totalExpected = 7 * (Math.floor(monthDays.length / 7) + 1)
    while (monthDays.length < totalExpected) {
      monthDays.push(
        <div className={classes.greyCard} key={j}>
          <div className={classes.date}>
            <Typography style={{ fontSize: 15, color: 'unset' }}>{i}</Typography>
          </div>
        </div>
      )
      i += 1
      j += 1
    }
    this.setState({ monthDays })
  }

  closeEventDetails() {
    this.setState({ eventsDetail: false })
  }

  openEventDetails(dayEvents) {
    return () => {
      this.setState({ eventsDetail: true, dayEventsClicked: dayEvents })
    }
  }

  handleSelectDay(date) {
    return () => {
      const { selectDate } = this.props
      this.setState({ selectedDate: date }, this.renderEvents)
      selectDate(date.format('YYYY-MM-DD'))
    }
  }

  renderEvents() {
    const { currentDate, events, handleEventClick } = this.props
    const firstDay = weekDays.indexOf(
      moment(currentDate)
        .startOf('month')
        .format('dddd')
    )
    const eventList = {}
    const currentMonth = moment(currentDate).format('MM')
    const currentYear = moment(currentDate).format('YYYY')
    const filteredEvents = events.filter(
      e => moment(e.event.date).format('MM') === currentMonth
        && moment(e.event.date).format('YYYY') === currentYear
    )
    filteredEvents
      .map(e => {
        const time = e.event.module
        const date = moment(e.event.date).format('YYYY-MM-DD')
        return {
          id: e.event.id,
          name: e.event.title,
          date,
          time,
          full: e,
        }
      })
      .forEach(event => {
        const day = parseInt(moment(event.date, 'YYYY-MM-DD').format('DD'), 10)
        const dayIndex = day + firstDay - 1
        if (eventList[dayIndex]) {
          eventList[dayIndex].push(
            <CalendarEvent key={event.id} event={event} handleClick={handleEventClick(event)} />
          )
        } else {
          eventList[dayIndex] = [
            <CalendarEvent key={event.id} event={event} handleClick={handleEventClick(event)} />,
          ]
        }
      })
    this.setState({ eventList }, this.getDays)
  }

  render() {
    const { classes, handleEventClick } = this.props
    const { monthDays, dayEventsClicked, eventsDetail } = this.state
    return (
      <div style={{ flexGrow: 1, position: 'relative' }}>
        <div className={classes.container}>{monthDays}</div>
        <EventsDetail
          events={dayEventsClicked}
          handleClose={this.closeEventDetails}
          open={eventsDetail}
          clickHandler={handleEventClick}
          dayClicked
        />
      </div>
    )
  }
}

CalendarDays.propTypes = {
  classes: propTypes.object.isRequired,
  currentDate: propTypes.string.isRequired,
  handleEventClick: propTypes.func.isRequired,
  events: propTypes.arrayOf(propTypes.object).isRequired,
  location: propTypes.object.isRequired,
  selectDate: propTypes.func.isRequired,
}

export default withRouter(withStyles(style)(CalendarDays))
