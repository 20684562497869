import React, { Component } from 'react'
import { withStyles, Paper, Typography, Radio, Button, Grid } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import { createContenderAction } from '../../Actions/Contenders'
import { addParticipantsAction } from '../../Actions/Events'
import MyTextField from '../Contenders/FormTextfield'
import SelectField from './Companion/TextField'
import autobind from '../../Utils/autobind'

const style = theme => ({
  container: {
    padding: 12,
    width: '30%',
  },
  button: {
    display: 'flex',
    marginTop: 6,
    justifyContent: 'flex-end',
  },
  greyDark: {
    color: theme.palette.grey.dark,
  },
})

class NewContender extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
      participantId: '',
      source: 'form',
    }
    autobind(NewContender, this)
  }

  handleSelect(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    const newParams = { ...params }
    newParams[target.name] = target.value
    this.setState({ params: newParams })
  }

  handleSend() {
    const { source } = this.state
    if (source === 'form') {
      const { params } = this.state
      const { enqueueSnackbar, newContender, createCallback } = this.props
      const body = { ...params }
      newContender(body, enqueueSnackbar, createCallback)
    } else {
      const { addParticipants, match, close } = this.props
      const { participantId } = this.state
      const { id } = match.params
      addParticipants(id, [participantId])
      close()
    }
  }

  renderExistingContender() {
    const { participantId } = this.state
    const { filtered, contenders, classes } = this.props
    const participants = contenders
      .filter(c => !filtered.includes(c.user.id))
      .map(c => ({ value: c.user.id, label: c.user.name }))
    return (
      <>
        <SelectField
          value={participantId}
          onChange={this.handleSelect}
          label="Buscar por nombre"
          placeholder="Lista aspirantes"
          name="participantId"
          selectOptions={participants}
        />
        <div className={classes.button}>
          <Button variant="contained" color="secondary" onClick={this.handleSend}>
            Guardar
          </Button>
        </div>
      </>
    )
  }

  renderNewContender() {
    const { classes } = this.props
    const { params, source } = this.state
    return (
      <>
        <MyTextField
          disabled={source !== 'form'}
          value={params.name || ''}
          onChange={this.handleChange}
          label="Nombre"
          name="name"
        />
        <MyTextField
          disabled={source !== 'form'}
          value={params.lastname || ''}
          onChange={this.handleChange}
          label="Apellido"
          name="lastname"
        />
        <MyTextField
          disabled={source !== 'form'}
          value={params.email || ''}
          onChange={this.handleChange}
          label="Correo"
          name="email"
        />
        <MyTextField
          disabled={source !== 'form'}
          value={params.rut || ''}
          onChange={this.handleChange}
          label="Rut"
          name="rut"
        />
        <MyTextField
          disabled={source !== 'form'}
          value={params.phone || ''}
          onChange={this.handleChange}
          label="Teléfono"
          name="phone"
          placeholder="+569"
        />
        <Typography variant="body2" className={classes.greyDark}>
          Medio de contacto
        </Typography>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              disabled={source !== 'form'}
              value="0"
              checked={params.contact === '0'}
              name="contact"
              onChange={this.handleChange}
            />
            <Typography variant="body2" className={classes.greyDark}>
              Formulario
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              disabled={source !== 'form'}
              value="1"
              checked={params.contact === '1'}
              name="contact"
              onChange={this.handleChange}
            />
            <Typography variant="body2" className={classes.greyDark}>
              Llamada
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              disabled={source !== 'form'}
              value="2"
              checked={params.contact === '2'}
              name="contact"
              onChange={this.handleChange}
            />
            <Typography variant="body2" className={classes.greyDark}>
              Correo
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              disabled={source !== 'form'}
              value="3"
              checked={params.contact === '3'}
              name="contact"
              onChange={this.handleChange}
            />
            <Typography variant="body2" className={classes.greyDark}>
              Otro
            </Typography>
          </div>
        </div>
        <div className={classes.button}>
          <Button variant="contained" color="secondary" onClick={this.handleSend}>
            Guardar
          </Button>
        </div>
      </>
    )
  }

  render() {
    const { classes, fullWidth } = this.props
    const { source } = this.state
    return (
      <Grid container style={{ padding: 12 }}>
        <Paper className={classes.container} style={fullWidth ? { width: '100%' } : {}}>
          <Typography
            style={{ textAlign: 'left', marginBottom: 12 }}
            variant="body1"
            className={classes.greyDark}
          >
            Añadir Postulante
          </Typography>
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Radio
                value="form"
                checked={source === 'form'}
                name="source"
                onChange={this.handleSelect}
              />
              <Typography variant="body2" className={classes.greyDark}>
                Nuevo Postulante
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Radio
                value="select"
                checked={source === 'select'}
                name="source"
                onChange={this.handleSelect}
              />
              <Typography variant="body2" className={classes.greyDark}>
                Seleccionar existente
              </Typography>
            </div>
          </div>
          {
            source === 'form' && this.renderNewContender()
          }
          {
            source === 'select' && this.renderExistingContender()
          }
        </Paper>
      </Grid>
    )
  }
}

NewContender.defaultProps = {
  fullWidth: false,
  createCallback: () => {},
  contenders: [],
  filtered: [],
  close: () => {},
}

NewContender.propTypes = {
  classes: propTypes.object.isRequired,
  newContender: propTypes.func.isRequired,
  addParticipants: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  fullWidth: propTypes.bool,
  match: propTypes.object.isRequired,
  close: propTypes.func,
  createCallback: propTypes.func,
  filtered: propTypes.arrayOf(propTypes.string),
  contenders: propTypes.arrayOf(propTypes.object)
}

const mapDispatchToProps = dispatch => ({
  newContender: (contender, snack, cb) => dispatch(createContenderAction(contender, snack, cb)),
  addParticipants: (id, participants) => dispatch(addParticipantsAction(id, participants))
})

export default withRouter(withSnackbar(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(style)(NewContender))
))
