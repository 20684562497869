import React, { Component, Fragment } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { calculateSupportLevels, criteria, sobrecargaLevel, supportLevels } from './utils'
import { getSupportLevelFamiliar } from './Baremos'

const style = theme => ({
  container: {
    '& > h2': {
      fontWeight: 600,
      margin: '24px 0'
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      width: 250,
      color: theme.palette.primary.main,
      marginRight: 12
    },
    padding: '6px 12px'
  },
  firstRow: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      width: 250,
      marginRight: 12
    },
    '& > *': {
      color: '#3E3E3E'
    },
    padding: '0px 12px 6px'
  },
  table: {
    border: '1px solid'
  },
  divider: {
    margin: 0
  }
})

class SupportLevelTable extends Component {
  render() {
    const { classes, questions, user } = this.props;
    const avgValues = calculateSupportLevels(questions, user)
    return (
      <div className={classes.container}>
        <Typography variant="h2">Niveles de apoyo por ámbito</Typography>
        <div className={classes.firstRow}>
          <Typography variant="subtitle1">Dimensión</Typography>
          <Typography variant="subtitle1">La familia percibe que requiere</Typography>
        </div>
        <div className={classes.table}>
          {criteria.map((criterium, index) => {
            const isSobrecarga = criterium === 'Sobrecarga del cuidador'
            const message = `${supportLevels[getSupportLevelFamiliar(avgValues[criterium], criterium)]} 
            para funcionamiento familiar asociado a esta dimensión`
            const sobrecargaResult = getSupportLevelFamiliar(avgValues[criterium], criterium)
            const sobrecargaMessage = `${sobrecargaLevel[sobrecargaResult[0]]} (${sobrecargaResult[1]}%)`
            return (
              <Fragment key={criterium}>
                {index > 0 && <Divider className={classes.divider} />}
                <div className={classes.row}>
                  <Typography variant="subtitle1">{criterium}</Typography>
                  <Typography variant="subtitle1">
                    {isSobrecarga ? sobrecargaMessage : message}
                  </Typography>
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>
    );
  }
}

SupportLevelTable.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  questions: propTypes.arrayOf(propTypes.object).isRequired
}

SupportLevelTable.defaultProps = {

}
export default withStyles(style)(SupportLevelTable)
