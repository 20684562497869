import React from 'react'
import { Paper, withStyles, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormAction } from '../../../../Actions/Contenders'
import InterviewNavigator from './InterviewNavigator'
import Loader from '../../../../Shared/Loader'
import CriteriumTitle from '../../../../Shared/Criterium'
import AnsweredQuestion from './AnsweredQuestion'
import GlobalComment from '../../../../Shared/GlobalComment'

const style = theme => ({
  container: {
    padding: 12,
    height: 'max-content',
    width: '80%'
  },
  mainContainer: {
    display: 'flex',
    gap: '12px',
    maxHeight: '80vh',
    overflow: 'auto',
    paddingBottom: 12,
    paddingRight: 12
  },
  btn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  isGenerated: {
    '& > div > *': {
      color: theme.palette.primary.dark
    }
  }
})

class IndividualInterviewResult extends React.Component {
  constructor(props) {
    super(props)
    this.criteria = []
  }

  renderResult() {
    const { forms, interviewName, classes } = this.props
    const actualForm = forms && forms.form ? forms.form[interviewName] : {}
    const indagationFormName = 'Indagación de indicadores descendidos'
    const renderedAnswers = Object.keys(actualForm).map((key, idx) => {
      this.criteria.push({ name: key, link: key })
      let isEmpty = true
      let criteriaAnswers = Object.keys(actualForm[key]).map(innerKey => {
        const questionsArray = Array.isArray(actualForm[key][innerKey])
          ? actualForm[key][innerKey]
          : actualForm[key][innerKey].questions
        const criteriums = Object.keys(actualForm[indagationFormName])
        const dynamicQuestions = []
        criteriums.forEach(criteriumName => {
          const filtered = actualForm[indagationFormName][criteriumName]
            .questions.filter(q => q.section_index === idx)
          dynamicQuestions.push(...filtered)
        })
        const filteredQuestions = questionsArray.filter(question => {
          if (indagationFormName === key) {
            return question.section_index === -1
          }
          if (question.dependant) {
            const { answers } = question
            const answer = answers[0]
            return answer.must_show
          }
          return true
        }).concat(dynamicQuestions)
        if (filteredQuestions.length > 0) {
          isEmpty = false
        }

        return filteredQuestions.map((question, index) => (
          <div className={question.generated_from ? classes.isGenerated : ''}>
            <AnsweredQuestion
              question={question}
              index={index + 1}
              interview={interviewName}
            />
          </div>
        ))
      })
      if (isEmpty) {
        criteriaAnswers = <Typography variant="h2" style={{ margin: 24, textAlign: 'left' }}>No hay preguntas asignadas a esta sección</Typography>
      }
      return (
        <div id={key}>
          <CriteriumTitle label={`${idx + 1}.- ${key}`} />
          {criteriaAnswers}
        </div>
      )
    })
    return renderedAnswers
  }

  render() {
    const { classes, event, getForm } = this.props
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
    }
    return (
      <div className={classes.mainContainer}>
        <Paper className={classes.container}>
          <Loader action={getForm} params={[body]}>
            <>
              <GlobalComment event={event} />
              {this.renderResult()}
            </>
          </Loader>
        </Paper>
        <InterviewNavigator links={this.criteria} />
      </div>
    )
  }
}

IndividualInterviewResult.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  getForm: propTypes.func.isRequired,
  forms: propTypes.object,
  interviewName: propTypes.string,
}

IndividualInterviewResult.defaultProps = {
  forms: {},
  interviewName: 'Entrevista Individual'
}

const mapStateToProps = state => ({
  contender: state.contenders.selected,
  forms: state.contenders.forms
})

const mapDispatchToProps = dispatch => ({
  getForm: body => dispatch(getFormAction(body))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(IndividualInterviewResult))
