import React, { Component } from 'react'
import { withStyles, Paper, TextField, Button, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { loginAction } from '../../Actions/Login'
import autobind from '../../Utils/autobind'

const style = theme => ({
  background: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `linear-gradient(${114}deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`
  },
  container: {
    padding: 12,
    width: '50%',
    height: '60%'
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12,
  },
  title: {
    color: theme.palette.confirm.main,
    margin: 'auto',
    fontSize: 24
  },
  content: {
    width: '50%',
    margin: 'auto',
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 15% 5% 0',
  },
  input: {
    textAlign: 'left',
    marginBottom: 8
  },
  logoImg: {
    width: '6vw',
    padding: 12,
    display: 'flex'
  },
  textfield: {
    marginBottom: 16
  }
})

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {}
    }
    autobind(Login, this)
  }

  handleSend() {
    const { login, enqueueSnackbar } = this.props
    const { params } = this.state
    login({ ...params, origin: 0 }, enqueueSnackbar)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    const newParams = { ...params }
    newParams[target.name] = target.value
    this.setState({ params: newParams })
  }

  handleKeyPress(event) {
    if (event.keyCode === 13) {
      this.handleSend()
    }
  }

  render() {
    const { classes } = this.props
    const { params } = this.state
    return (
      <div className={classes.background}>
        <Paper className={classes.container} onKeyUp={this.handleKeyPress}>
          <div className={classes.inline}>
            <img src="/logo_borde.png" alt="logo" className={classes.logoImg} />
            <Typography variant="h1" className={classes.title}>Ingresa a tu cuenta</Typography>
          </div>
          <div className={classes.content}>
            <Typography variant="subtitle2" color="primary" className={classes.input}>Correo</Typography>
            <TextField
              fullWidth
              name="email"
              value={params.email || ''}
              inputProps={{ style: { padding: '6px 0 7px 6px' } }}
              InputProps={{ style: { borderRadius: 0 } }}
              onChange={this.handleChange}
              variant="filled"
              className={classes.textfield}
            />
            <Typography variant="subtitle2" color="primary" className={classes.input}>Contraseña</Typography>
            <TextField
              fullWidth
              type="password"
              name="password"
              variant="filled"
              inputProps={{ style: { padding: '6px 0 7px 6px' } }}
              InputProps={{ style: { borderRadius: 0 } }}
              value={params.password || ''}
              onChange={this.handleChange}
              className={classes.textfield}
            />
            <div className={classes.button}>
              <Button color="secondary" variant="contained" style={{ fontWeight: 600 }} onClick={this.handleSend}>
                Ingresar
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  login: (body, snackbar) => dispatch(loginAction(body, snackbar))
})

export default withSnackbar(connect(null, mapDispatchToProps)(withStyles(style)(Login)))

Login.propTypes = {
  login: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
}
