import React, { Component } from 'react'
import StepContainer from '../../../Shared/StepContainer'
import SelectContender from './SelectContender'
import CompanionForm from './CompanionForm'
import Result from './Result'

export default class Companions extends Component {
  render() {
    return (
      <>
        <StepContainer>
          <SelectContender channel="ContenderChannel" title="Evaluación de actividades de la vida diaria del postulante" loadText="Esperando Escala de actividades de la vida diaria" />
          <CompanionForm title="Evaluación de actividades de vida diaria" />
          <Result />
        </StepContainer>
      </>
    )
  }
}
