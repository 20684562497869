import React, { Component } from 'react'
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

// Endpoint = http://{{app-url}}/api/events/c2d2c0b3-0fc7-49d3-a500-b8588189d97e/forms/f7d03b9c-1c95-433b-b88f-c7653e0b4837/activities_result?user=1a548bea-6625-452e-92ef-df714ae15c5f

const style = theme => ({
  secondHeader: {
    background: theme.palette.grey.light,
    textAlign: 'center',
    '&.last': {
      borderTopRightRadius: 6,
    },
    '&.first': {
      borderTopLeftRadius: 6,
    }
  },
  secondCell: {
    background: theme.palette.grey.light,
    textAlign: 'center',
  },
  secondFooter: {
    background: theme.palette.grey.light,
    borderBottom: 'unset',
    padding: 6,
    '&.last': {
      borderBottomRightRadius: 6,
    },
    '&.first': {
      borderBottomLeftRadius: 6,
    }
  },
  firstFooter: {
    borderBottom: 'unset'
  },
  totalCol: { fontWeight: 600, color: '#3E3E3E' }
})
class ProfilesTable extends Component {
  constructor() {
    super()
    this.headers = [
      'Administrativo',
      'Atención al cliente',
      'Operario',
      'Servicios de apoyo'
    ]
  }

  processData() {
    const { data } = this.props
    const results = data.map(competency => ({
      name: competency.name,
      values: this.headers.map(key => competency.values[key])
    }))
    return results
  }

  renderBody() {
    const { classes } = this.props
    return this.processData().map((competency, idx) => (
      <TableRow key={`${idx + 1}-competencia`}>
        <TableCell>
          <Typography variant="body1">{competency.name}</Typography>
        </TableCell>
        {competency.values.map((value, index) => (
          <TableCell className={`${classes.secondCell} first`} key={`${index + value}-${value}`}>
            <Typography variant="subtitle1">{value}</Typography>
          </TableCell>
        ))}
      </TableRow>
    ))
  }

  renderTotalRow() {
    const { desviations, classes } = this.props
    return (
      <TableRow>
        <TableCell>
          <Typography variant="body1" className={classes.totalCol}>Total</Typography>
        </TableCell>
        {desviations.map(d => (
          <TableCell className={`${classes.secondCell} first`} key={`${d.name}-desv`}>
            <Typography variant="subtitle1" className={classes.totalCol}>{d.desv}</Typography>
          </TableCell>
        ))}
      </TableRow>
    )
  }

  render() {
    const { classes } = this.props
    this.processData()
    return (
      <div className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={`${classes.secondHeader} first`}>
                <Typography variant="subtitle1">Administrativo</Typography>
              </TableCell>
              <TableCell className={`${classes.secondHeader}`}>
                <Typography variant="subtitle1">Atención al cliente</Typography>
              </TableCell>
              <TableCell className={`${classes.secondHeader}`}>
                <Typography variant="subtitle1">Operario</Typography>
              </TableCell>
              <TableCell className={`${classes.secondHeader} last`}>
                <Typography variant="subtitle1">Servicios de apoyo</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderBody()}
            {this.renderTotalRow()}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={`${classes.firstFooter}`} />
              <TableCell className={`${classes.secondFooter} first`} />
              <TableCell className={`${classes.secondFooter}`} />
              <TableCell className={`${classes.secondFooter}`} />
              <TableCell className={`${classes.secondFooter} last`} />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

ProfilesTable.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.arrayOf(propTypes.object),
  desviations: propTypes.arrayOf(propTypes.object)
}

ProfilesTable.defaultProps = {
  data: [],
  desviations: []
}

export default withStyles(style)(ProfilesTable)
