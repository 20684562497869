import React, { Component, Fragment } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { calculateSupportLevels, criteria, sobrecargaLevel, supportLevels } from './utils'
import { getSupportLevelFamiliar } from './Baremos'

const style = theme => ({
  container: {
    '& > h2': {
      fontWeight: 600,
      margin: '48px 0 24px'
    }
  },
  question: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      padding: '3px 6px',
    },
    '& > *:nth-child(2)': {
      minWidth: 150,
      textAlign: 'center',
      borderLeft: '1px solid',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  finalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      padding: '3px 6px',
      fontSize: 13,
      color: 'black'
    },
    '& > *:nth-child(2)': {
      minWidth: 150,
      textAlign: 'center',
      '& > *': {
        color: theme.palette.primary.main,
        fontSize: 14,
      }

    }
  },
  divider: {
    margin: 0
  },
  criterium: {
    border: '1px solid'
  },
  criteriumContainer: {
    margin: '24px 0',
    '& > h2': {
      marginBottom: 8
    }
  }
})

class EvaluationResume extends Component {
  renderCriteriaResume() {
    const { questions, classes, user } = this.props
    const avgValues = calculateSupportLevels(questions, user)
    return criteria.map(criterium => {
      const isSobrecarga = criterium === 'Sobrecarga del cuidador'
      const value = supportLevels[getSupportLevelFamiliar(avgValues[criterium], criterium)]
      const sobrecargaValue = sobrecargaLevel[getSupportLevelFamiliar(
        avgValues[criterium], criterium
      )]
      const finalValue = isSobrecarga ? sobrecargaValue : value
      const criteriumQuestions = questions.filter(question => question.criteria.includes(criterium))
      return (
        <div key={criterium} className={classes.criteriumContainer}>
          <Typography variant="h2">{criterium}</Typography>
          <div className={classes.criterium}>
            {criteriumQuestions.map((question, index) => (
              <Fragment key={question.text}>
                {index > 0 && <Divider className={classes.divider} />}
                <div className={classes.question}>
                  <Typography variant="subtitle1">{question.text}</Typography>
                  <div>
                    <Typography variant="subtitle1">{question.steps[question.answers[0].body - 1].label}</Typography>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
          <div className={classes.finalRow}>
            <Typography variant="subtitle1">Indicación:</Typography>
            <div>
              <Typography variant="subtitle1">{finalValue}</Typography>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h2">Resumen de evaluación</Typography>
        {this.renderCriteriaResume()}
      </div>
    );
  }
}

EvaluationResume.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  questions: propTypes.arrayOf(propTypes.object).isRequired
}

EvaluationResume.defaultProps = {

}
export default withStyles(style)(EvaluationResume)
