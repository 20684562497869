import React, { Component } from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
// import CompetenciesTable from '../../FunctionalEvaluation/Results/CompetenciesTable'
import DownloadableSection from 'Shared/DownloadableSection'
import RadarChart from '../../../Shared/Charts/RadarChart'
import AbilitySupportChart from './AbilitySupportChart'
import autobind from '../../../Utils/autobind'
import Evaluation from '../Evaluation/Evaluation'
import CompetenciesChart from './CompetenciesChart'
import { editEvaluationComment, getEvaluationComment } from '../../../API/Evaluations'
import GlobalComment from '../../../Shared/GlobalComment'

const style = theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 600
  },
  levelContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0'
  },
  level: {
    background: theme.palette.grey.light,
    padding: '6px 36px',
    marginLeft: 12,
    borderRadius: 5
  },
  competencies: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '12px',
    '&>*': {
      width: '50%'
    },
    margin: '48px 0'
  },
  observation: {
    margin: '24px 0 12px 0',
    textAlign: 'left',
    '& > *': {
      margin: '3px 0'
    }
  },
  tabs: {
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    '& > *': {
      marginRight: 6,
      '&:before': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        height: 2,
        width: 0,
        background: theme.palette.primary.main,
        transition: 'all 0.2s linear',
      },
      '&.selected:before': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        height: 2,
        width: '100%',
        background: theme.palette.primary.main,
        transition: 'all 0.2s linear',
      },
      '&.selected': {
        color: theme.palette.primary.main
      }
    },
    marginBottom: 24
  },
  points: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16
  },
  abilityChart: {
    padding: 12,
    '& > h2': {
      marginBottom: 24,
      fontWeight: 600
    }
  }
})

const radarOptions = {
  color: '#1F8095BB',
  maxTicksLimit: 6,
  height: '300px',
  minValue: 0,
  maxValue: 5,
  stepSize: 1
}

const supportLevels = [
  'Sin Apoyo',
  'Poco Apoyo',
  'Apoyo Ocasional',
  'Bastante Apoyo',
  'Apoyo Total'
]

class Results extends Component {
  constructor() {
    super()
    this.state = {
      tab: 0,
      comment: ''
    }
    autobind(Results, this)
  }

  async componentDidMount() {
    const { event, evaluation, contender } = this.props
    const body = {
      form_id: event.form_id,
      event_id: event.event_id,
      evaluation_id: evaluation,
      user_id: contender.user.id
    }
    const response = await getEvaluationComment(body)
    if (response.data.status !== 'success') return null
    return this.setState({ comment: response.data.info.comment })
  }

  handleChangeTab(value) {
    return () => {
      this.setState({ tab: value })
    }
  }

  handleChange(event) {
    const { target } = event
    this.setState({ comment: target.value })
  }

  handleSave() {
    const { comment } = this.state
    const { event, evaluation, contender } = this.props
    const body = {
      form_id: event.form_id,
      event_id: event.event_id,
      evaluation_id: evaluation,
      user_id: contender.user.id,
      comment
    }
    editEvaluationComment(body)
  }

  render() {
    const { classes, competency, evaluation: evId, event, name, contender } = this.props;
    const { competences_detail: compDetail, evaluation, allocate } = competency.result
    const competencesTableData = compDetail?.competencies || []
    const competencesRadarData = {}

    competencesTableData.forEach(element => {
      competencesRadarData[`${element.name.slice(0, 18)}${element.name.length > 18 ? '...' : ''}`] = element.total
    })

    let totalPoints = 0
    competencesTableData.forEach(comp => {
      comp.skills.forEach(skill => {
        totalPoints += skill.total
      })
    })

    const { tab } = this.state

    return (
      <div className={classes.container}>
        <div className={classes.tabs}>
          <Button onClick={this.handleChangeTab(0)} className={tab === 0 ? 'selected' : ''}>
            Resultados
          </Button>
          <Button onClick={this.handleChangeTab(1)} className={tab === 1 ? 'selected' : ''}>
            Formulario
          </Button>
        </div>
        {tab === 0
          ? (
            <>
              <div className={classes.header}>
                <Typography variant="h2">
                  {'Respondido por: '}
                  <span className={classes.bold}>{allocate}</span>
                </Typography>
                <Typography variant="h2">
                  {'Fecha de realización: '}
                  <span className={classes.bold}>{moment(evaluation.updated_at).format('DD/MM/YYYY')}</span>
                </Typography>
              </div>
              <div className={classes.observation}>
                <Typography variant="caption">Agregar comentarios sobre Usuario</Typography>
                <GlobalComment event={event} evaluation={evId} />
              </div>
              <DownloadableSection margin id="top" name={`Resumen evaluación de competencias ${contender.user.name} Ev_${name}`}>
                <div className={classes.points}>
                  <div className={classes.levelContainer}>
                    <Typography variant="h2">Resultado Nivel de apoyo</Typography>
                    <Typography variant="h2" className={classes.level}>{supportLevels[compDetail.avg - 1]}</Typography>
                  </div>
                  <div className={classes.levelContainer}>
                    <Typography variant="h2">Puntaje total</Typography>
                    <Typography variant="h2" className={classes.level}>{totalPoints || 0}</Typography>
                  </div>
                </div>
                <div className={classes.competencies}>
                  <CompetenciesChart data={competencesTableData} />
                  <RadarChart name="competencias" title="" options={radarOptions} data={competencesRadarData} />
                </div>
              </DownloadableSection>
              <DownloadableSection margin id="mid" name={`Apoyo por habilidad ${contender.user.name} Ev_${name}`}>
                <div className={classes.abilityChart}>
                  <Typography variant="h2">Nivel de apoyo por habilidad</Typography>
                  <AbilitySupportChart data={competencesTableData} />
                </div>
              </DownloadableSection>
            </>
          )
          : <Evaluation disabled event={event} evaluation={evId} />}
      </div>
    );
  }
}

Results.propTypes = {
  classes: propTypes.object.isRequired,
  competency: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  evaluation: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  contender: propTypes.object.isRequired,
}

Results.defaultProps = {

}

const mapStateToProps = state => ({
  competency: state.competency,
  contender: state.contenders.selected
})

export default connect(mapStateToProps)(withStyles(style)(Results))
