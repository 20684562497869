import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Typography } from '@material-ui/core'

class StatusCell extends Component {
  render() {
    const { value } = this.props
    return (
      <div style={{ border: '2px solid', borderColor: value.color, padding: 6, borderRadius: 24, width: 'fit-content' }}>
        <Typography
          variant="subtitle2"
          style={{
            color: value.color,
            padding: '0 6px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 800,
          }}
        >
          {value.name ? value.name : value}

        </Typography>
      </div>
    )
  }
}

StatusCell.propTypes = {
  value: propTypes.string
}

StatusCell.defaultProps = {
  value: ''
}

export default StatusCell
