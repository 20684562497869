import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import SectionTitle from './Components/SectionTitle'
import autobind from '../../Utils/autobind'
import FlexibleInput from '../../Utils/FieldRenderer'
import GlobalComment from '../../Shared/GlobalComment'
import SingleResultCharts from './SingleResultCharts'

const style = theme => ({
  container: {
    textAlign: 'left',
    padding: 24,
  },
  paper: {
    padding: 24
  },
  tabs: {
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    '& > *': {
      marginRight: 6,
      '&:before': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        height: 2,
        width: 0,
        background: theme.palette.primary.main,
        transition: 'all 0.2s linear',
      },
      '&.selected:before': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        height: 2,
        width: '100%',
        background: theme.palette.primary.main,
        transition: 'all 0.2s linear',
      },
      '&.selected': {
        color: theme.palette.primary.main
      }
    },
    marginBottom: 24
  },
})

class AutoevaluationSingleResult extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      tab: 0
    }
    autobind(AutoevaluationSingleResult, this)
  }

  componentDidMount() {
    const { event } = this.props
    const mainForm = event['Auto-evaluación'].empty
    const groupoOfQuestions = Object.values(mainForm)
    const questions = groupoOfQuestions.flat()
    const newParams = {}
    questions.forEach(question => {
      const name = question?.answers[0]?.id
      const answer = question?.answers[0]?.body?.answer
      newParams[name] = answer
    })
    return this.setState({ params: newParams })
  }

  handleChangeTab(value) {
    return () => {
      this.setState({ tab: value })
    }
  }

  getFormInfo() {
    const { event } = this.props
    const mainForm = event['Auto-evaluación'].empty
    return mainForm
  }

  renderQuestion(questions) {
    const { classes } = this.props
    const { params } = this.state
    return questions.map((question, index) => {
      const name = question?.answers[0]?.id
      const info = { ...question }
      info.name = name
      info.text = `${index + 1} - ${question.text}`
      return (
        <div className={classes.question}>
          <FlexibleInput
            complete={info}
            value={params[name]}
            disabled
          />
        </div>
      )
    })
  }

  renderForm() {
    const { event, classes } = this.props
    const mainForm = event['Auto-evaluación'].empty
    const sections = Object.keys(mainForm)
    return sections.map(section => {
      const questions = mainForm[section]
      return (
        <div className={classes.section}>
          <SectionTitle title={section} />
          {this.renderQuestion(questions)}
        </div>
      )
    })
  }

  render() {
    const { classes, evaluation, eventInfo, name, contender } = this.props
    const { tab } = this.state
    const form = this.getFormInfo()
    console.log(eventInfo, evaluation)
    return (
      <div className={classes.container}>
        <div style={{ margin: '12px 0' }}>
          <Typography variant="subtitle1">Comentarios</Typography>
          <GlobalComment event={eventInfo} evaluation={evaluation} />
        </div>
        <div className={classes.tabs}>
          <Button onClick={this.handleChangeTab(0)} className={tab === 0 ? 'selected' : ''}>
            Resultados
          </Button>
          <Button onClick={this.handleChangeTab(1)} className={tab === 1 ? 'selected' : ''}>
            Formulario
          </Button>
        </div>
        {tab === 0
          && <SingleResultCharts form={form} contender={contender} name={name} />}
        {tab === 1
          && (
            <Paper className={classes.paper}>
              <Typography variant="subtitle2">
                Fecha de realización:
                {' '}
                <span className={classes.bold}>12/10/2021</span>
              </Typography>
              {this.renderForm()}
            </Paper>
          )}
      </div>
    )
  }
}

AutoevaluationSingleResult.propTypes = {
  classes: propTypes.object.isRequired,
  evaluation: propTypes.string.isRequired,
  event: propTypes.object.isRequired,
  eventInfo: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  name: propTypes.string.isRequired,
}

AutoevaluationSingleResult.defaultProps = {
}

export default withStyles(style)(AutoevaluationSingleResult)
