import React, { Component } from 'react'
import { withStyles, Paper, TextField, Button, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { loginAction, logoutAction } from '../../Actions/Login'
import Header from '../External/Header/index'

const style = theme => ({
  background: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  container: {
    padding: 12,
    width: '50%',
    height: '60%',
    display: 'grid',
    gridTemplateRows: '1fr 5fr'
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12,
  },
  title: {
    color: theme.palette.grey.dark,
    margin: 'auto',
    fontSize: 24
  },
  content: {
    width: '50%',
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10%'
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    textAlign: 'left',
    marginBottom: 8,
    fontWeight: 900
  }
})

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {}
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSend = this.handleSend.bind(this)
    this.handleRegister = this.handleRegister.bind(this)
  }

  componentDidMount() {
    const { logout } = this.props
    logout()
  }

  handleSend() {
    const { login, enqueueSnackbar, history } = this.props
    const { params } = this.state
    login({ ...params, origin: 1 }, enqueueSnackbar).then(() => history.push('/'))
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    const newParams = { ...params }
    newParams[target.name] = target.value
    this.setState({ params: newParams })
  }

  handleRegister() {
    const { history } = this.props
    history.push('/register')
  }

  render() {
    const { classes } = this.props
    const { params } = this.state
    return (
      <div className={classes.background}>
        <Header />
        <Paper className={classes.container} elevation={3}>
          <div className={classes.inline}>
            <Typography variant="h1" className={classes.title}>Link de Redes</Typography>
          </div>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Typography variant="subtitle2" className={classes.label}>Correo</Typography>
              <TextField
                fullWidth
                name="email"
                value={params.email || ''}
                inputProps={{ style: { padding: '6px 0 7px 6px' } }}
                InputProps={{ style: { borderRadius: 0 } }}
                onChange={this.handleChange}
                variant="filled"
                style={{ marginBottom: 16 }}
              />
              <Typography variant="subtitle2" className={classes.label}>Contraseña</Typography>
              <TextField
                fullWidth
                type="password"
                name="password"
                variant="filled"
                inputProps={{ style: { padding: '6px 0 7px 6px' } }}
                InputProps={{ style: { borderRadius: 0 } }}
                value={params.password || ''}
                onChange={this.handleChange}
                style={{ marginBottom: 16 }}
              />
              <div className={classes.button}>
                <Button color="secondary" variant="contained" style={{ fontWeight: 600 }} onClick={this.handleSend}>
                  Ingresar
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  login: (body, snackbar) => dispatch(loginAction(body, snackbar)),
  logout: () => dispatch(logoutAction())
})

export default withSnackbar(connect(null, mapDispatchToProps)(withStyles(style)(Login)))

Login.propTypes = {
  login: propTypes.func.isRequired,
  logout: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  history: propTypes.func.isRequired
}
