import stateMaker from '../Utils/reducers'

const organizationsReducer = (state = { all: [], currentHealthForm: {} }, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'CREATE_EXTERNAL_ORGANIZATION':
      return stateMaker(newState, 'all', 'create', action)
    case 'LOAD_EXTERNAL_ORGANIZATIONS':
      return stateMaker(newState, 'all', 'fetch', action)
    case 'LOAD_EXTERNAL_HEALTH':
      return stateMaker(newState, 'currentHealthForm', 'fetch', action)
    default:
      return state
  }
}

export default organizationsReducer
