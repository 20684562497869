import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  withTheme,
  IconButton,
  Checkbox
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import propTypes from 'prop-types';
import moment from 'moment'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { ArrowBack, CheckCircle, CheckCircleOutline, ErrorOutlineOutlined } from '@material-ui/icons'
import ParticipantsComponent from '../Participants';
import { loadResultAction, createGencatAction, selectEventAction } from '../../../Actions/Events';
import ResultCriteria from './ResultCriteria';
import autobind from '../../../Utils/autobind';
import ResultPercentages from '../ResultPercentages';
import Loader from '../../../Shared/Loader';
import LoaderDialog from '../../../Shared/LoaderDialog';
import { editEvaluationComment, getEvaluationComment } from '../../../API/Evaluations'
import GlobalComment from '../../../Shared/GlobalComment';

const POST_GATHERING_EVENT_NAME = 'Mail Acompañante'

const style = theme => ({
  container: {
    padding: '12px 12px 12px 6px'
  },
  mainGrid: {
    display: 'flex',
    gridGap: 12,
    padding: 12
  },
  paper: {
    backgroundColor: theme.palette.paper.main,
    textAlign: 'left',
  },
  commentBox: {
    backgroundColor: theme.palette.grey.light,
    margin: '0 24px',
    padding: 12,
    paddingBottom: 24,
    color: '#3E3E3E',
    fontSize: 14
  },
  noBackgroundButton: {
    backgroundColor: theme.palette.paper.main
  },
  inlineTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  specialCriterium: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: 24
  },
  specialCriteriumContainer: {
    margin: 24
  },
  specialTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }
});

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      comment: '',
      open: false,
      criteria: '',
      loading: false
    };
    autobind(Results, this)
  }

  async componentDidMount() {
    const { event, loadResult, match, selectEvent } = this.props;
    if (event.selected.event.id !== match.params.id) {
      selectEvent(match.params.id)
    }
    const body = {
      form_id: match.params.form_id,
      event_id: match.params.id
    }
    loadResult(body);
    this.handleSelect(event.selected.participants.filter(p => p.attended)[0].id)
    const commentResponse = await getEvaluationComment({
      form_id: match.params.form_id,
      event_id: match.params.id,
      user_id: event.selected.participants.filter(p => p.attended)[0].id
    })
    if (commentResponse.data.status === 'success') {
      this.setState({ comment: commentResponse.data.info.comment })
    }
  }

  componentDidUpdate(prevProps) {
    const { event } = this.props
    const { selected } = this.state
    if (JSON.stringify(prevProps.event.result) !== JSON.stringify(event.result)) {
      this.handleSelect(selected)
    }
  }

  handleBack() {
    const { history, match } = this.props
    const eventId = match.params.id
    history.push(`/events/${eventId}/gathering`)
  }

  async handleSelect(id) {
    const { match } = this.props
    const commentResponse = await getEvaluationComment({
      form_id: match.params.form_id,
      event_id: match.params.id,
      user_id: id
    })
    if (commentResponse.data.status !== 'success') return this.setState({ selected: id }, this.renderCriteria)
    return this.setState({
      selected: id, comment: commentResponse.data.info.comment
    }, this.renderCriteria)
  }

  handleOpen() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  handleChange(event) {
    const { target } = event;
    this.setState({ comment: target.value });
  }

  handleSave() {
    this.setState({ open: false });
    const { match } = this.props
    const { comment, selected } = this.state
    const body = {
      form_id: match.params.form_id,
      event_id: match.params.id,
      user_id: selected,
      comment
    }
    editEvaluationComment(body)
  }

  handleOpenCriteria(name) {
    const { criteria } = this.state
    if (criteria === name) {
      this.setState({ criteria: '' })
    } else {
      this.setState({ criteria: name })
    }
  }

  handleGoToGencat(gencatResponse) {
    if (gencatResponse) {
      const { history } = this.props
      const { data: { info } } = gencatResponse
      history.push(`/events/${info.gencat.event.id}/gencat`)
    }
  }

  async handleGencatCreation() {
    const { event: { selected }, createGencat, user } = this.props
    const body = {
      date: moment(selected.event.date).add(1, 'weeks'),
      comment: null,
      module: 0,
      end_module: 0,
      participants: selected.participants.map(p => p.id),
      title: `${POST_GATHERING_EVENT_NAME} ${selected.event.title}`,
      owners:
        selected.owners.map(owner => owner.owner_user_id).filter(ownerId => ownerId !== user.id),
      type: 5,
    }
    this.setState({ loading: true })
    await createGencat(body, selected.event.id, this.handleGoToGencat)
    this.setState({ loading: false })
  }

  renderCriteria() {
    const { event, theme, match } = this.props;
    if (match.params.id === event.selected.event.id) {
      const results = event.result ? event.result.results : {};
      const { selected, criteria } = this.state;
      const participantsData = event.selected.participants || []
      const participants = participantsData.filter(p => p.attended)
      const participant = selected || participants[0].id
      if (results && participant) {
        const participantResult = results[participant] || {};
        if (participantResult.forms) {
          return Object.keys(participantResult.forms)
            .filter(crit => !crit.includes('Verbaliza') && !crit.includes('Patología') && !crit.includes('Agresividad'))
            .map(criterium => (
              <ResultCriteria
                key={criterium}
                info={participantResult.forms[criterium]}
                criterium={criterium}
                theme={theme}
                open={criterium === criteria}
                handleOpen={this.handleOpenCriteria}
                fullWidth
                withObservation
              />
            ))
        }
        return null
      }
    }
    return null
  }

  renderVerbalize() {
    const { event, theme, match, classes } = this.props;
    if (match.params.id === event.selected.event.id) {
      const results = event.result ? event.result.results : {};
      const { selected } = this.state;
      const participantsData = event.selected.participants || []
      const participants = participantsData.filter(p => p.attended)
      const participant = selected || participants[0].id
      if (results && participant) {
        const participantResult = results[participant] || {};
        if (participantResult.forms) {
          const key = Object.keys(participantResult.forms).find(criterium => criterium.includes('Verbaliza'))
          const verbalizeForm = participantResult.forms[key]
          const verbalizes = verbalizeForm?.question_groups?.[0]?.questions?.[0]?.question_check
          return (
            <div className={classes.specialCriterium}>
              <Checkbox
                disabled
                checked={verbalizes}
                icon={
                  <ErrorOutlineOutlined style={{ color: theme.palette.alert.main }} />
                }
                checkedIcon={(
                  <CheckCircle
                    style={{ color: theme.palette.confirm.main }}
                  />
                )}
                value="checkedH"
              />
              <Typography variant="subtitle2">
                Verbaliza su interés por trabajar
              </Typography>
            </div>
          )
        }
      }
    }
    return null
  }

  renderPatologies() {
    const { event, theme, match, classes } = this.props;
    if (match.params.id === event.selected.event.id) {
      const results = event.result ? event.result.results : {};
      const { selected } = this.state;
      const participantsData = event.selected.participants || []
      const participants = participantsData.filter(p => p.attended)
      const participant = selected || participants[0].id
      if (results && participant) {
        const participantResult = results[participant] || {};
        if (participantResult.forms) {
          const keys = Object.keys(participantResult.forms).filter(criterium => criterium.includes('Patología') || criterium.includes('Agresividad'))
          return keys.map(key => {
            const patologyQuestion = participantResult.forms[key]
            const patologyAnswers = patologyQuestion?.question_groups?.[0]?.questions?.[0]
            const isCorrectHandler = patologyAnswers?.answers?.[0]?.body?.answer === '1'
            const isCorrectCompanion = patologyAnswers?.answers?.[1]?.body?.answer <= 1
            const isBothCorrect = isCorrectHandler && isCorrectCompanion
            return (
              <>
                <div className={classes.specialCriteriumContainer}>
                  <div className={classes.specialTitle}>
                    <Checkbox
                      disabled
                      checked={isBothCorrect}
                      icon={
                        <ErrorOutlineOutlined style={{ color: theme.palette.alert.main }} />
                      }
                      checkedIcon={(
                        <CheckCircle
                          style={{ color: theme.palette.confirm.main }}
                        />
                      )}
                      value="checkedH"
                    />
                    <Typography variant="subtitle2">
                      {key}
                    </Typography>
                  </div>
                  <div style={{ marginLeft: 24 }}>
                    <div className={classes.specialTitle}>
                      {isCorrectHandler
                        ? <CheckCircleOutline style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />
                        : <ErrorOutlineOutlined style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />}
                      <Typography variant="subtitle1">
                        Facilitador:
                        {isCorrectHandler ? ' No' : ' Si'}
                      </Typography>
                    </div>
                    <div className={classes.specialTitle}>
                      {isCorrectCompanion
                        ? <CheckCircleOutline style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />
                        : <ErrorOutlineOutlined style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />}
                      <Typography variant="subtitle1">
                        Acompañante:
                        {isCorrectCompanion ? ' No' : ' Si'}
                      </Typography>
                    </div>
                  </div>
                </div>
              </>
            )
          })
        }
      }
    }
    return null
  }

  render() {
    const { classes, event, loadResult, match, id } = this.props;
    const { open, selected, loading } = this.state;
    const participantData = event.result.participants || [];
    const participants = participantData.filter(p => p.attended)
    const participant = participants.find(
      p => p.id === selected
    );
    const { results } = event.result
    const eventId = match.params.id || id
    const body = {
      form_id: match.params.form_id,
      event_id: eventId
    }

    return (
      <div className={classes.mainGrid}>
        <LoaderDialog open={loading} />
        <Loader action={loadResult} params={[body]}>
          <>
            <div style={{ minWidth: '15vw', marginRight: 12 }}>
              <ParticipantsComponent
                data={participants}
                select={this.handleSelect}
                selected={selected}
                showFinish={!event.selected.event.gencat_created}
                submit={this.handleGencatCreation}
                bottomLabel="Ir a envío de emails"
              />
            </div>
            <div style={{ width: '100%' }}>
              <Paper className={classes.paper}>
                <div className={classes.inlineTitle}>
                  <Typography variant="h1" color="primary">
                    Resultados de la Evaluación
                  </Typography>
                  <div className={classes.inline}>
                    <IconButton onClick={this.handleBack}>
                      <ArrowBack />
                    </IconButton>
                    <Typography>Atrás</Typography>
                  </div>

                </div>
                <Typography
                  variant="caption"
                  color="primary"
                  style={{ paddingLeft: 24 }}
                >
                  Agregar comentarios sobre
                  <span style={{ fontWeight: 600 }}>
                    {participant ? ` ${participant.name}` : ''}
                  </span>
                </Typography>
                {/* <div
                  className={classes.commentBox}
                  style={{
                    background: theme.palette.grey.light,
                    paddingBottom: open && 17
                  }}
                >
                  {open ? (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: { borderRadius: 0, paddingTop: 1 }
                      }}
                      value={comment}
                      multiline
                      autoFocus
                      placeholder="Agregar un comentario..."
                      onChange={this.handleChange}
                    />
                  )
                    : (
                      <Typography
                        variant="subtitle2"
                        style={{ color: theme.palette.grey.dark }}
                        onClick={this.handleOpen}
                      >
                        {comment || 'Agregar un comentario...'}
                      </Typography>
                    )}
                </div> */}
                <div style={{ margin: '12px 24px' }}>
                  <GlobalComment event={{ ...body, user_id: selected }} />
                </div>
                <Grid style={{
                  margin: '6px 24px 12px 24px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: 'auto',
                  position: 'relative'
                }}
                >
                  <ResultPercentages
                    result={selected && results ? results[selected] : {}}
                    title="Porcentanjes obtenidos"
                    subtitle1="Ponderación Facilitador/a Laboral"
                    subtitle2="Poderación Acompañante"
                  />
                  {open
                    && (
                      <Button
                        style={{ position: 'absolute', top: 0, right: 0 }}
                        size="small"
                        onClick={this.handleSave}
                      >
                        <Typography variant="subtitle1" color="primary">
                          Listo
                        </Typography>
                      </Button>
                    )}
                </Grid>
                {this.renderVerbalize()}
                {this.renderPatologies()}
                <Grid
                  container
                  style={{
                    margin: '6px 24px 12px 24px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 'auto'
                  }}
                >
                  <div>
                    <Typography variant="subtitle1">
                      Cumple con criterios:
                    </Typography>
                  </div>
                </Grid>
                <div style={{ padding: 24, paddingTop: 0 }}>
                  {this.renderCriteria()}
                </div>
              </Paper>
            </div>
          </>
        </Loader>
      </div>
    );
  }
}

Results.propTypes = {
  classes: propTypes.object.isRequired,
  loadResult: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  theme: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  createGencat: propTypes.func.isRequired,
  selectEvent: propTypes.func.isRequired,
  history: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  id: propTypes.string
};

Results.defaultProps = {
  id: '0'
}

const mapStateToProps = state => ({
  event: state.events,
  user: state.user.current
});

const mapDispatchToProps = dispatch => ({
  loadResult: id => dispatch(loadResultAction(id)),
  createGencat: (body, snackbar, callback) => dispatch(
    createGencatAction(body, snackbar, callback)
  ),
  selectEvent: id => dispatch(selectEventAction(id))
});

export default withSnackbar(withTheme(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(Results)))
));
