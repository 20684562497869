import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Divider, Typography, withStyles } from '@material-ui/core'

const style = theme => ({
  container: {
    margin: '24px 12px',
  },
  divider: {
    background: theme.palette.primary.main,
    margin: '6px 0 12px 0'
  }

})

class SectionTitle extends Component {
  render() {
    const { classes, title } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h2" className={classes.title}>{title}</Typography>
        <Divider className={classes.divider} />
      </div>
    )
  }
}

SectionTitle.propTypes = {
  classes: propTypes.object.isRequired,
  title: propTypes.string,
}

SectionTitle.defaultProps = {
  title: 'Titulo'
}

export default withStyles(style)(SectionTitle)
