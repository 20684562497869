import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import DownloadableSection from 'Shared/DownloadableSection'
import GlobalComment from '../../../Shared/GlobalComment'
import PercentileTable from './PercentileTable'
import SupportLevelTable from './SupportLevelTable'
import PercentileChart from './PercentileChart'
import EvaluationResume from './EvaluationResume'

const style = () => ({
  container: {
    padding: 24,
    textAlign: 'start'
  },
  firstSection: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > *:nth-child(2)': {
      minWidth: 500,
      flexGrow: 1
    },
    flexWrap: 'wrap'
  },
  sections: {
    '& > *': {
      margin: '24px 0'
    }
  }
})

class GencatIndividualResult extends Component {
  render() {
    const { classes, eventInfo, event, evaluation, contenders } = this.props;
    const questions = event?.['Escala de Calidad de Vida Familiar']?.['Escala de Calidad de Vida Familiar']?.['Escala de Calidad de Vida Familiar']
    const user = contenders?.selected?.user
    return (
      <Paper className={classes.container}>
        <Typography variant="subtitle1">
          {`Agregar comentarios sobre ${contenders?.selected?.user?.name}`}
        </Typography>
        <GlobalComment event={eventInfo} evaluation={evaluation} />
        <div className={classes.sections}>
          <div className={classes.firstSection}>
            <DownloadableSection margin id="first" name={`Percentil de la familia de ${contenders?.selected?.user?.name}`}>
              <PercentileTable questions={questions} user={user} />
            </DownloadableSection>
            <DownloadableSection margin id="second" name={`Niveles de apoyo de ${contenders?.selected?.user?.name}`}>
              <SupportLevelTable questions={questions} user={user} />
            </DownloadableSection>
          </div>
          <DownloadableSection margin id="third" name={`Percentil por dimensión de ${contenders?.selected?.user?.name}`}>
            <PercentileChart questions={questions} user={user} />
          </DownloadableSection>
          <EvaluationResume questions={questions} user={user} />
        </div>
      </Paper>
    );
  }
}

GencatIndividualResult.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  eventInfo: propTypes.object.isRequired,
  evaluation: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
}

GencatIndividualResult.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(GencatIndividualResult))
