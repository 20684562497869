import stateMaker from '../Utils/reducers'

const HandlerReducer = (
  state = { all: [], selected: { id: 2, name: 'default' }, guests: [], participants: [] },
  action
) => {
  let newState = {}
  switch (action.type) {
    case 'getGatheringGuests': {
      const guests = action.payload
      newState = { ...state, guests }
      return newState
    }
    case 'getGatheringParticipants': {
      newState = { ...state, participants: action.payload }
      return newState
    }
    case 'LOAD_HANDLERS': {
      return stateMaker(state, 'all', 'fetch', action)
    }
    default:
      return state
  }
}

export default HandlerReducer
