import React, { Component } from 'react'
import propTypes from 'prop-types'
import { withStyles, Paper, Button, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { getFormResultAction, createQuestionDynamicCriteriumAction, addQuestionToDynamicCriteriaAction, getDynamicCriteriaFormsAction } from '../../../../Actions/Contenders'
import Loader from '../../../../Shared/Loader'
import ActiveCriterium from '../Shared/ActiveCriterium'
import QuestionsDialog from '../Shared/QuestionsDialog'
import autobind from '../../../../Utils/autobind'
import SetViewContext from '../../../Contenders/Profile/SetViewProvider'

const style = theme => ({
  container: {
    padding: 12,
    textAlign: 'left'
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12
  },
  titleSelected: {
    color: theme.palette.confirm.main
  }

})

class InterviewAVD extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
      sections: {},
      open: false,
      criterium: '',
      criteriums: {}
    }

    autobind(InterviewAVD, this)
  }

  componentDidMount() {
    const { getDynamicCriteriaForms, event, title } = this.props
    let dynamicBody
    if (typeof event.dynamic_criterium_id === 'string') {
      dynamicBody = {
        dynamic_id: event.dynamic_criterium_id,
        form_id: event.form_id
      }
    } else {
      dynamicBody = {
        dynamic_id: event.dynamic_criterium_id[`Entrevista ${title}`],
        form_id: event.form_id
      }
    }
    getDynamicCriteriaForms(dynamicBody).then(() => {
      this.setupSelected()
    })
  }

  handleOpenDialog() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  handleBack() {
    const { setView, showPopup } = this.context
    const { title } = this.props
    showPopup(title)
    setView()
  }

  handleSelect(value, criteriumName) {
    return () => {
      const { selected, criteriums } = this.state
      const newCriteriums = { ...criteriums }
      const newSelected = [...selected]
      const index = newSelected.indexOf(value)
      if (index === -1) {
        newSelected.push(value)
      } else {
        newSelected.splice(index, 1)
      }
      newCriteriums[value] = criteriumName
      this.setState({ selected: newSelected, criteriums: newCriteriums })
    }
  }

  handleSelectSection(questionId) {
    return event => {
      const { target } = event
      const { sections } = this.state
      const newSections = sections
      newSections[questionId] = target.value
      this.setState({ sections: newSections })
    }
  }

  handleSubmit(criterium) {
    return () => {
      const { selected, sections, criteriums } = this.state
      const { addQuestionToDynamicCriteria, event, title } = this.props
      const body = {
        questions: selected,
        section_info: sections,
        criterium_name: criterium,
        criteriums_detail: criteriums,
      }
      body.criteria_id = typeof event.dynamic_criterium_id === 'string' ? event.dynamic_criterium_id : event.dynamic_criterium_id[`Entrevista ${title}`]
      addQuestionToDynamicCriteria(body)
      this.handleOpenDialog()
    }
  }

  handleSelectCriterium(criterium) {
    return () => {
      this.setState({ criterium }, this.handleOpenDialog)
    }
  }

  handleQuestionCreation(creationData) {
    const { createQuestionDynamicCriterium, event, title } = this.props
    return () => {
      const postBody = {
        ...creationData,
        criterium_id: typeof event.dynamic_criterium_id === 'string' ? event.dynamic_criterium_id : event.dynamic_criterium_id[`Entrevista ${title}`],
      }
      createQuestionDynamicCriterium(postBody).then(() => {
        this.setupSelected()
      })
    }
  }

  setupSelected() {
    const { contenders } = this.props
    const newSelected = []
    const newSections = {}
    const newCriteriums = {}
    const dynamics = contenders.dynamics ? contenders.dynamics : {}
    Object.entries(dynamics).forEach(([criteriumName, info]) => {
      info.questions.forEach(question => {
        if (question.checked) {
          if (newSelected.indexOf(question.id) === -1) {
            newSelected.push(question.id)
            newSections[question.id] = question.section_index
            newCriteriums[question.id] = criteriumName
          }
        }
      })
    })
    this.setState({ selected: newSelected, sections: newSections, criteriums: newCriteriums })
  }

  renderCriteria() {
    const { contenders, showAll } = this.props
    const { results } = contenders
    const dynamicCriteria = contenders.dynamics ? contenders.dynamics : {}
    const criteria = results.results && results.results.forms ? results.results.forms : {}
    return Object.entries(criteria).map(([key, value]) => {
      const dynamicCriterium = dynamicCriteria[key]
      let done = false
      if (contenders.dynamics && contenders.dynamics[key]) {
        contenders.dynamics[key].questions.forEach(question => {
          if (question.checked) {
            done = true
          }
        })
      }
      return (
        <ActiveCriterium
          key={key}
          info={value}
          criterium={key}
          fullWidth
          done={done}
          full={dynamicCriterium}
          handleSelect={this.handleSelectCriterium}
          showAll={showAll}
        />
      )
    })
  }

  render() {
    const { classes, getFormResult, event, contenders } = this.props
    const { selected, criterium, open, sections } = this.state
    const contender = contenders.selected
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
      user_id: contender.user.id,
      type: event.route
    }
    return (
      <Paper className={classes.container}>
        <Typography variant="subtitle1" style={{ marginBottom: 24 }}>Criterios de evaluación</Typography>
        <Loader action={getFormResult} params={[body]}>
          {this.renderCriteria()}
        </Loader>
        <QuestionsDialog
          open={open}
          onClose={this.handleOpenDialog}
          selected={selected}
          handleSelect={this.handleSelect}
          handleSelectSection={this.handleSelectSection}
          sections={sections}
          onCreation={this.handleQuestionCreation}
          onSubmit={this.handleSubmit(criterium)}
          criterium={criterium}
        />
        <div className={classes.button}>
          <Button color="secondary" variant="contained" size="small" onClick={this.handleBack}>
            Terminar selección
          </Button>
        </div>
      </Paper>
    )
  }
}

InterviewAVD.contextType = SetViewContext

InterviewAVD.propTypes = {
  classes: propTypes.object.isRequired,
  showAll: propTypes.bool,
  getFormResult: propTypes.func.isRequired,
  contenders: propTypes.object.isRequired,
  title: propTypes.string,
  event: propTypes.object.isRequired,
  createQuestionDynamicCriterium: propTypes.func.isRequired,
  getDynamicCriteriaForms: propTypes.func.isRequired,
  addQuestionToDynamicCriteria: propTypes.func.isRequired,

}

InterviewAVD.defaultProps = {
  showAll: false,
  title: 'Individual'
}

const mapStateToProps = state => ({
  contenders: state.contenders
})

const mapDispatchToProps = dispatch => ({
  getFormResult: id => dispatch(getFormResultAction(id)),
  getDynamicCriteriaForms: body => dispatch(getDynamicCriteriaFormsAction(body)),
  createQuestionDynamicCriterium: body => dispatch(createQuestionDynamicCriteriumAction(body)),
  addQuestionToDynamicCriteria: body => dispatch(addQuestionToDynamicCriteriaAction(body)),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(withStyles(style)(InterviewAVD))
