/* eslint-disable import/no-cycle */
import React, { Component } from 'react'
import { withStyles, Typography, Radio, withTheme } from '@material-ui/core'
import propTypes from 'prop-types'
import Comment from './Comment'
import { renderSubquestion } from '../../Utils/FieldRenderer'
import { shouldAlertInput } from './Utils'

const style = () => ({
  container: {
    padding: 12,
    margin: '-6px -12px',
    borderRadius: 12
  },
  select: {
    padding: '6px 0 7px 6px',
  },
})

class RadioGroup extends Component {
  checkIfShoudShowSubquestions() {
    const { complete, value } = this.props
    const { subquestions } = complete
    if (!subquestions) return [false, complete]
    if (!complete) return [false, complete]
    if (complete.expected_value !== 'multiple') return [complete?.expected_value?.toString()?.split('&')?.includes(value), complete]
    const newComplete = { ...complete }
    const newSubquestions = subquestions[value]
    if (!newSubquestions) return [false, complete]
    newComplete.subquestions = newSubquestions
    return [true, newComplete]
  }

  renderOptions(onChange, value, name) {
    const { complete, disabled } = this.props
    const { options } = complete
    return options.map((option, index) => (
      <div style={{ display: 'flex', alignItems: 'center' }} key={index.toString()}>
        <Radio
          value={index.toString()}
          key={index.toString()}
          name={name}
          onChange={onChange}
          checked={value === index.toString()}
          color="primary"
          disabled={disabled}
        />
        <Typography>
          {option}
        </Typography>
      </div>
    ))
  }

  render() {
    const {
      classes,
      onChange,
      value,
      comment,
      complete,
      onSubchange,
      onComment,
      subquestionsAnswers,
      disabled,
      highlighted,
      answered
    } = this.props
    const { subquestions, options } = complete
    const [shouldShow, processedComplete] = this.checkIfShoudShowSubquestions()
    const answerText = options[value] ? options[value] : '-'
    // const isReady = checkIfFinished(complete, value, subquestions, subquestionsAnswers)
    const shouldAlert = shouldAlertInput(complete, value, subquestions, subquestionsAnswers)
    let textColor = complete.bold ? '#3E3E3E' : '#707070'
    textColor = shouldAlert ? 'red' : textColor
    return (
      <>
        {!answered && (
          <div
            className={highlighted ? 'highlighted' : classes.container}
            style={{ gridArea: `area-${complete.id}`, background: shouldShow && complete.background ? complete.background : '', transition: 'all 0.4s ease-in-out' }}
          >
            <Typography style={{ fontWeight: complete.helper_text || complete.bold ? 'bold' : 400, color: textColor }}>
              {complete.text}
              {' '}
              {complete.required > 0 && '*'}
            </Typography>
            {complete.helper_text && <Typography>{complete.helper_text}</Typography>}
            <div style={{ display: 'grid', gridTemplateColumns: complete.columns || '1fr 1fr 1fr', width: '70%' }}>
              {this.renderOptions(onChange, value, complete.name)}
            </div>
            {shouldShow
            && renderSubquestion(
              processedComplete, onSubchange, subquestionsAnswers, disabled, answered
            )}
          </div>
        )}
        {answered
        && (
          <>
            <Typography style={{ fontWeight: 900, fontSize: 14, color: 'black' }} display="inline">{`${complete.text}: ${answerText}`}</Typography>
            {shouldShow
            && renderSubquestion(
              processedComplete, onSubchange, subquestionsAnswers, disabled, answered
            )}
          </>
        )}
        {complete.has_comment && (
          <Comment
            value={comment}
            onChange={onComment}
            name={complete.name}
            area={`comment-${complete.id}`}
            commentLabel={complete.comment_label}
            disabled={disabled}
          />
        )}
      </>
    )
  }
}

RadioGroup.propTypes = {
  classes: propTypes.object.isRequired,
  onChange: propTypes.func.isRequired,
  onComment: propTypes.func,
  onSubchange: propTypes.func,
  value: propTypes.string,
  comment: propTypes.string,
  complete: propTypes.object.isRequired,
  subquestionsAnswers: propTypes.object,
  disabled: propTypes.bool,
  highlighted: propTypes.bool,
  answered: propTypes.bool,
}

RadioGroup.defaultProps = {
  value: '',
  comment: '',
  onComment: undefined,
  onSubchange: undefined,
  subquestionsAnswers: {},
  disabled: false,
  highlighted: false,
  answered: false,
}

export default withTheme(withStyles(style)(RadioGroup))
