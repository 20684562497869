import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

const style = () => ({

})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

class ResultCharts extends Component {
  componentDidMount() {
    const { name } = this.props
    const chartElement = document.getElementById(name)
    const myChartRef = chartElement.getContext('2d');
    const [datasets, labels] = this.generateDatasets()

    this.chart = new Chart(myChartRef, {
      type: 'horizontalBar',
      data: {
        // Bring in data
        labels,
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              minRotation: 0,
              callback: value => `${value}% `,
              min: 0,
              max: 100,
              stepSize: 25,
            },
            gridLines: {
              display: true,
              drawBorder: false,
            },
            offset: true,
          },
          ],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              callback(label) {
                const array = label.split(' ')
                const chunked = []
                const chunk = 3
                for (let i = 0; i < array.length; i += chunk) {
                  chunked.push(array.slice(i, i + chunk))
                }
                return chunked.map(chunky => chunky.join(' '));
              }
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        },
        legend: {
          display: true,
        },
        animation: {
          onComplete: () => {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.font = 'Montserrat, sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.chart.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const metadata = parseFloat(dataset.data[index], 10).toFixed(1);
                // eslint-disable-next-line no-underscore-dangle
                ctx.fillText(metadata, bar._model.x + 12, bar._model.y + 7);
              });
            });
          }
        }
      },
      plugins: [
        {
          id: 'custom_canvas_background_color',
          beforeDraw: chart => {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        },
      ]
    })
  }

  generateDatasets() {
    const { data } = this.props
    const labels = Object.keys(data[0])
    const datasets = data.map((group, index) => ({
      fill: false,
      barThickness: 14,
      data: Object.values(group),
      backgroundColor: colors[index],
      label: `Evaluación N°${index + 1}`,
    }))
    return [datasets, labels]
  }

  render() {
    const { classes, name, title, data } = this.props;
    const labelsAmount = Object.keys(data[0]).length
    this.generateDatasets()
    return (
      <div className={classes.container}>
        <Typography variant="h2" style={{ marginBottom: 12, fontWeight: 600 }}>Porcentaje de logro por ámbito</Typography>
        <Typography variant="h1" style={{ margin: '12px 0', textAlign: 'center' }}>{title}</Typography>
        <canvas style={{ maxHeight: 100 * labelsAmount }} id={name} ref={this.chartRef} />
      </div>
    );
  }
}

ResultCharts.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
  name: propTypes.string.isRequired,
  title: propTypes.string
}

ResultCharts.defaultProps = {
  title: 'Titulo'
}

export default withStyles(style)(ResultCharts)
