import React, { Component } from 'react'
import { Checkbox, Dialog, Divider, Typography, withStyles, withTheme } from '@material-ui/core'
import propTypes from 'prop-types'
import { AddCircleOutline, CheckCircle } from '@material-ui/icons'
import { TextField } from '../../Shared/Fields/Text'
import autobind from '../../Utils/autobind'

const style = () => ({
  container: {
    padding: 24,
    '& > *': {
      margin: '6px 0'
    }
  },
  contender: {
    padding: '3px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  inline: {
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    margin: 0
  },
  contenders: {
    maxHeight: 350,
    overflow: 'auto'
  }
})

class AssignmentDialog extends Component {
  constructor() {
    super()
    this.state = {
      search: ''
    }
    autobind(AssignmentDialog, this)
  }

  handleChange(event) {
    const { target } = event
    this.setState({ search: target.value.toLowerCase() })
  }

  handleClick(contenderId) {
    return () => {
      const { onAssign } = this.props
      onAssign(contenderId)
    }
  }

  renderContenders() {
    const { contenders, classes, theme, handler } = this.props
    const { search } = this.state
    return contenders
      .filter(c => c?.user?.name?.toLowerCase()?.includes(search))
      .map((contender, index) => (
        <>
          {index > 0 && <Divider className={classes.divider} />}
          <div className={classes.contender} key={contender?.user?.id}>
            <Typography variant="subtitle1">{contender?.user?.name}</Typography>
            <div className={classes.inline}>
              <Typography variant="subtitle1">{contender.state_name}</Typography>
              <Checkbox
                onClick={this.handleClick(contender.contender.id)}
                icon={
                  <AddCircleOutline />
                }
                checkedIcon={(
                  <CheckCircle
                    style={{ color: theme.palette.confirm.main }}
                  />
                )}
                checked={contender?.handlers?.includes(handler?.id)}
              />
            </div>
          </div>
        </>
      ))
  }

  render() {
    const { classes, open, onClose } = this.props;
    const { search } = this.state
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h1" color="primary">Asignar usuarios</Typography>
          <TextField complete={{}} value={search} onChange={this.handleChange} />
          <div className={classes.contenders}>
            {this.renderContenders()}
          </div>
        </div>
      </Dialog>
    );
  }
}

AssignmentDialog.propTypes = {
  classes: propTypes.object.isRequired,
  theme: propTypes.object.isRequired,
  handler: propTypes.object.isRequired,
  contenders: propTypes.arrayOf(propTypes.object),
  open: propTypes.bool,
  onClose: propTypes.func,
  onAssign: propTypes.func
}

AssignmentDialog.defaultProps = {
  open: false,
  onClose: undefined,
  onAssign: undefined,
  contenders: []
}

export default withTheme(withStyles(style)(AssignmentDialog))
