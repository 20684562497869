/* eslint-disable import/prefer-default-export */
import instance from '../Utils/client';

export const getFormEvaluations = body => {
  const REQUEST = instance({
    method: 'get',
    url: `forms/${body.form_id}/evaluations`,
    params: body
  })
  return REQUEST
}

export const getUserEvaluations = body => instance({
  method: 'get',
  url: `forms/${body.form_id}/user_evaluations`,
  params: body
})

export const getFormEvaluation = body => {
  const REQUEST = instance({
    method: 'get',
    url: `forms/${body.form_id}/evaluations/${body.evaluation_id}`,
    params: body.params
  })
  return REQUEST
}

export const getEvaluationGroups = body => {
  // required_params: user_id
  const REQUEST = instance({
    method: 'get',
    url: `/forms/${body.form_id}/evaluation_groups`,
    params: body
  })
  return REQUEST
}

export const getEvaluationGroup = body => {
  const REQUEST = instance({
    method: 'get',
    url: `/forms/${body.form_id}/evaluation_groups/${body.evaluation_group_id}`,
    params: body.params
  })
  return REQUEST
}

export const createEvaluationGroup = body => {
  const REQUEST = instance({
    method: 'post',
    url: `/forms/${body.form_id}/evaluation_groups`,
    data: body
  })
  return REQUEST
}

export const createEvaluationGroupEvaluation = body => {
  const REQUEST = instance({
    method: 'post',
    url: `/forms/${body.form_id}/evaluation_groups/${body.evaluation_group_id}`,
    data: body
  })
  return REQUEST
}

export const changeEvaluationGroupStatus = body => {
  // required_params: status
  const REQUEST = instance({
    method: 'put',
    url: `forms/${body.form_id}/evaluation_groups/${body.evaluation_group_id}/status`,
    data: body
  })
  return REQUEST
}

export const changeFormStatus = body => {
  const REQUEST = instance({
    method: 'put',
    url: `evaluations/${body.evaluation_id}/change_status`,
    params: { status: body.status }
  })
  return REQUEST
}

export const createFormEvaluation = body => {
  const REQUEST = instance({
    method: 'post',
    url: `forms/${body.form_id}/evaluations`,
    data: body
  })
  return REQUEST
}
