import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import DownloadButton from 'Components/Dashboard/DownloadButton'
import RecurrentContext from '../RecurrentEvaluations/RecurrentContext'
import { getEvaluationGroup, getFormEvaluation } from '../../API/Recurrent'
import { calculateMultipleEvaluationAvgPerformance, getDataForEachCriteriaOfGroup } from './ResultComponents/utils'
import ProgressBars from './ResultComponents/ProgressBars'
import ProgressChart from './ResultComponents/ProgressChart'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import { editEvaluationComment, getEvaluationComment } from '../../API/Evaluations'
import autobind from '../../Utils/autobind'
import GlobalComment from '../../Shared/GlobalComment'
import HorizontalProgress from './ResultComponents/HorizontalProgress'

const style = () => ({
  results: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 12,
  },
  resultsContainer: {
    margin: '12px 0 0',
    position: 'relative'
  },
  bars: {
    maxWidth: 400,
    minWidth: 250,
    flexGrow: 1,
    marginRight: 24
  },
  comment: {
    marginBottom: 24
  },
  chart: {
    minWidth: 250,
    maxWidth: 1000
  },
  chartsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexBasis: '45%',
      minWidth: 350
    },
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
  section: {
    padding: 12,
  },
  sectionContainer: {
    margin: '24px 24px 0 0',
    position: 'relative'
  },
  downloadButton: {
    position: 'absolute',
    top: 6,
    right: 6
  }
})

class LaboralPerformanceMultipleResults extends Component {
  constructor() {
    super()
    this.state = {
      calculatedGroups: [],
      desagregatedGroups: [],
      loading: true,
      comment: ''
    }
    autobind(LaboralPerformanceMultipleResults, this)
  }

  async componentDidMount() {
    const { getEvaluations, event, type } = this.context
    const calculatedGroups = []
    const desagregatedGroups = []
    const response = await getEvaluations(event)
    if (response.data.status !== 'success') return null
    const groups = response.data.info
    const doneGroups = groups.filter(group => group.status === 'check')
    const evaluationGroups = await this.groupEvaluations(doneGroups)
    evaluationGroups.forEach(group => {
      const desagregatedGroupData = getDataForEachCriteriaOfGroup(group, type)
      const groupData = calculateMultipleEvaluationAvgPerformance(group, type)
      calculatedGroups.push(groupData)
      desagregatedGroups.push(desagregatedGroupData)
    })

    const commentBody = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: event.user_id
    }
    const commentResponse = await getEvaluationComment(commentBody)
    if (commentResponse.data.status !== 'success') return null
    return this.setState({
      calculatedGroups,
      desagregatedGroups,
      loading: false,
      comment: commentResponse.data.info.comment
    })
  }

  handleChange(event) {
    const { target } = event
    this.setState({ comment: target.value })
  }

  handleSave() {
    const { event } = this.context
    const { comment } = this.state
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: event.user_id,
      comment
    }
    editEvaluationComment(body)
  }

  async groupEvaluations(doneGroups) {
    const { event } = this.context
    const groupPromises = doneGroups.map(group => {
      // Get the group
      const groupBody = { form_id: event.form_id, evaluation_group_id: group.id }
      return getEvaluationGroup(groupBody)
    })
    const groupsResponses = await Promise.all(groupPromises)

    // Separate evaluations
    const groupedEvaluationsData = []
    groupsResponses.forEach(response => groupedEvaluationsData.push(response.data.info.evaluations))

    // Get evaluations results and group
    const evaluationGroupPromises = groupedEvaluationsData.map(evaluationGroup => {
      const evaluationResponses = evaluationGroup.filter(evaluation => evaluation.status === 'check').map(evaluation => {
        const evaluationBody = { form_id: event.form_id, evaluation_id: evaluation.id }
        return getFormEvaluation(evaluationBody)
      })
      return Promise.all(evaluationResponses)
    })

    const evaluationsResponses = await Promise.all(evaluationGroupPromises)
    const groupedEvaluationsResults = evaluationsResponses
      .map(evaluationGroup => evaluationGroup
        .map(evaluationResponse => evaluationResponse.data.info))

    return groupedEvaluationsResults
  }

  render() {
    const { classes } = this.props;
    const { event, contender } = this.context
    const { calculatedGroups, loading, desagregatedGroups } = this.state
    const sectionsToChart = desagregatedGroups.length > 0
      && Object.keys(desagregatedGroups[0]).map(section => {
        const sectionData = desagregatedGroups.map(group => group[section])
        return (
          <div key={section} className={classes.sectionContainer}>
            <DownloadButton title={`${section}`} name={`${section}-${contender?.name}`} />
            <Paper className={classes.section} id={`${section}`}>
              <HorizontalProgress data={sectionData} name={`${section}chart`} title={`${section}`} />
            </Paper>
          </div>
        )
      })
    return (
      <div className={classes.container}>
        {!loading && calculatedGroups.length > 0
          ? (
            <>
              <div className={classes.comment}>
                <Typography variant="subtitle1">Observaciones</Typography>
                <GlobalComment event={event} />
              </div>
              <div className={classes.resultsContainer}>
                <DownloadButton title="Resultados" name={`Resumen Desempeño laboral ${contender?.name}`} />
                <Paper className={classes.results} id="Resultados">
                  <div className={classes.bars}>
                    <ProgressBars data={calculatedGroups} />
                  </div>
                  <div className={classes.chart}>
                    <ProgressChart data={calculatedGroups} />
                  </div>
                </Paper>
              </div>
              <div className={classes.chartsContainer}>
                {sectionsToChart}
              </div>
            </>
          )
          : <LoaderAnimator loading={loading} />}
      </div>
    );
  }
}

LaboralPerformanceMultipleResults.contextType = RecurrentContext

LaboralPerformanceMultipleResults.propTypes = {
  classes: propTypes.object.isRequired
}

LaboralPerformanceMultipleResults.defaultProps = {

}
export default withStyles(style)(LaboralPerformanceMultipleResults)
