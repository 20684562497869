import React, { Component } from 'react'
import propTypes from 'prop-types'
import { withStyles, Typography, Button, IconButton } from '@material-ui/core'
import { Edit, CheckCircle } from '@material-ui/icons'
import Conditional from '../../Shared/Conditional'

const style = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    fontWeight: 600
  },
  iconbutton: {
    marginRight: 12
  },
  check: {
    color: theme.palette.confirm.main
  },
  subText: {
    fontWeight: 400,
    marginRight: 12
  }
})

class FormState extends Component {
  render() {
    const { classes, title, completed, answered, total, select, disabled } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.container}>
          <Typography className={classes.subText} variant="h1">{title}</Typography>
          <Conditional condition={completed} hasElse>
            <CheckCircle className={classes.check} />
            <Typography variant="caption">{`${answered} / ${total}`}</Typography>
          </Conditional>
        </div>
        <Conditional condition={completed && !disabled} hasElse>
          <div>
            <IconButton size="small" className={classes.iconbutton} onClick={select}>
              <Edit />
            </IconButton>
            <Button color="default" variant="outlined" disabled className={classes.button}>Finalizado</Button>
          </div>
          <Button color="secondary" variant="contained" className={classes.button} onClick={select}>{disabled ? 'Revisar' : 'Comenzar'}</Button>
        </Conditional>
      </div>
    )
  }
}

FormState.propTypes = {
  classes: propTypes.object.isRequired,
  title: propTypes.string,
  completed: propTypes.bool,
  select: propTypes.func.isRequired,
  answered: propTypes.number,
  total: propTypes.number,
  disabled: propTypes.bool
}

FormState.defaultProps = {
  title: 'No formulario...',
  completed: false,
  answered: 0,
  total: 0,
  disabled: false
}

export default withStyles(style)(FormState)
