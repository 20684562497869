import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { ContactPhone } from '@material-ui/icons'
import { loadContendersAction } from '../../../Actions/Contenders'
import GeneralTable from '../../../Shared/GeneralTable'
import { contenderTestList, contenderInfo, contenderFilters, contenderSortables } from './ContenderTableInfo'
import Loader from '../../../Shared/Loader'
import autobind from '../../../Utils/autobind'
import ContactDialog from './ContactDialog'

class Contenders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      selected: ''
    }

    autobind(Contenders, this)
  }

  handleGoto(row) {
    return () => {
      const { history } = this.props
      history.push(`/contenders/${row.id}`)
    }
  }

  handleOpenContact(row) {
    return () => {
      const { open } = this.state
      this.setState({ open: !open, selected: row.id })
    }
  }

  handleOpenDialog() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { contenders, getAllContenders } = this.props
    const allContenders = contenders.all || []
    const contenderList = contenderTestList(allContenders)
    const contenderAction = [
      { name: 'Info de contacto', action: this.handleOpenContact, icon: ContactPhone }
    ]

    const { open, selected } = this.state
    return (
      <div style={{ padding: 12 }}>
        <Loader action={getAllContenders}>
          <GeneralTable
            data={contenderList}
            info={contenderInfo}
            actions={contenderAction}
            mainAction={this.handleGoto}
            filters={contenderFilters}
            sortings={contenderSortables}
            name="contenders"
          />
          <ContactDialog
            open={open}
            contenders={contenders.all || []}
            selected={selected}
            handleClose={this.handleOpenDialog}
          />
        </Loader>
      </div>
    )
  }
}

Contenders.propTypes = {
  contenders: propTypes.object.isRequired,
  getAllContenders: propTypes.func.isRequired,
  history: propTypes.object.isRequired
}

const mapDispatchToProps = dispatch => ({
  getAllContenders: () => dispatch(loadContendersAction()),
})

const mapStateToProps = state => ({
  contenders: state.contenders,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contenders)
