import React, { Component } from 'react'
import { Button, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import autobind from '../../../Utils/autobind'
import './animations.css'

const style = theme => ({
  tabs: {
    fontWeight: 600
  },
  selectedTab: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    width: 130,
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      height: 2,
      width: '30%',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
      background: theme.palette.primary.main
    }
  },
  tab: {
    width: 130
  },
  showingTab: {
    padding: 24
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > * > *': {
      width: 130
    }
  }

})
class ViewTabs extends Component {
  constructor() {
    super()
    this.state = {
      selected: 0
    }
    autobind(ViewTabs, this)
  }

  handleSelectTab(selected) {
    return () => {
      this.setState({ selected })
    }
  }

  renderTabs() {
    const tabs = ['Formulario', 'Resultados']
    const { classes } = this.props
    const { selected } = this.state
    return tabs.map((tab, index) => (
      <Button
        className={selected === index ? `${classes.selectedTab} active` : classes.tab}
        onClick={this.handleSelectTab(index)}
      >
        {tab}
      </Button>
    ))
  }

  renderSelectedTab() {
    const { children } = this.props
    const { selected } = this.state
    const kids = React.Children.toArray(children)
    return kids[selected]
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.tabs}>
            {this.renderTabs()}
          </div>
        </div>
        <div className={classes.showingTab}>
          {this.renderSelectedTab()}
        </div>
      </div>

    );
  }
}

ViewTabs.propTypes = {
  classes: propTypes.object.isRequired,
  children: propTypes.node.isRequired,
}

ViewTabs.defaultProps = {

}
export default withStyles(style)(ViewTabs)
