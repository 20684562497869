/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { withStyles, IconButton, Typography, Menu, MenuItem } from '@material-ui/core'
import propTypes from 'prop-types'
import { MoreVertOutlined, Visibility } from '@material-ui/icons'
import classNames from 'classnames'
import autobind from '../../Utils/autobind'

const style = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 12px',
    background: theme.palette.grey.light,
    borderRadius: 5,
    boxShadow: '0px 1px 5px #00000029',
  },
  btn: {
    padding: 6,
    fontWeight: 600
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      marginRight: 6,
      color: theme.palette.confirm.main
    }
  },
  menuItem: {
    minWidth: 300,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tag: {
    padding: '3px 9px',
    borderRadius: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 12
  },
  pendingTag: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    '& > *': {
      color: theme.palette.secondary.dark,
      fontWeight: 600
    }
  },
  finalizedTag: {
    border: `1px solid ${theme.palette.primary.main}`,
    '& > *': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
})

class Participant extends Component {
  constructor() {
    super()
    this.state = {
      anchorEl: null,
      openMenu: false
    }
    autobind(Participant, this)
  }

  handleOpen(event) {
    const { target } = event
    const { openMenu } = this.state
    this.setState({ anchorEl: target, openMenu: !openMenu })
  }

  render() {
    const { classes, participant, onMenuClick, disabled, finished } = this.props
    const { openMenu, anchorEl } = this.state
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div className={classes.container}>
        <div className={classes.flex}>
          <IconButton size="small" onClick={this.handleOpen}>
            <MoreVertOutlined />
          </IconButton>
          <Typography
            variant="h2"
            style={{ color: disabled ? 'grey' : '', fontWeight: 400 }}
          >
            {participant.name}
          </Typography>
        </div>
        <div className={classes.flex}>
          <div className={classNames({ [classes.pendingTag]: !finished,
            [classes.finalizedTag]: finished,
            [classes.tag]: true })}
          >
            <Typography variant="body2" className={classes.tagText}>{finished ? 'Finalizado' : 'Pendiente'}</Typography>
          </div>
        </div>
        <Menu anchorEl={anchorEl} open={openMenu} onClose={this.handleOpen}>
          <MenuItem className={classes.menuItem} onClick={onMenuClick(participant.id, 'view')}>
            <Typography variant="subtitle2">Revisar avance de formularios</Typography>
            <Visibility />
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

Participant.propTypes = {
  classes: propTypes.object.isRequired,
  participant: propTypes.object.isRequired,
  onMenuClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
  finished: propTypes.bool,
}

Participant.defaultProps = {
  disabled: false,
  finished: false
}

export default withStyles(style)(Participant)
