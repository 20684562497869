import React, { useState } from 'react'
import { Typography, Divider, withStyles, Checkbox, TextField, Button } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { CheckCircle, RadioButtonUnchecked, HelpOutline } from '@material-ui/icons'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { setCheckupFollowDateAction, checkCheckupAction, setCheckupCommentAction } from '../../../../../Actions/Contenders'
import { DatePicker } from '../../../../../Shared/form/Fields'
import OnTouchTooltip from '../../../../../Shared/OnTouchTooltip'
import autobind from '../../../../../Utils/autobind'

const tooltipText = 'Cuando se seleccione una fecha de seguimiento el control aparecerá como no realizado/pendiente. Si luego se selecciona el check para marcar que fue realizado el exámen o control, la fecha ingresada en "seguimuiento" pasa automáticamente a la columna de "fecha de último control"'

const style = theme => ({
  container: {
    marginTop: theme.spacing(4)
  },
  header: {
    display: 'flex',
    gap: 6
  },
  tooltipIcon: {
    color: theme.palette.grey.darker,
    height: 20,
    width: 20,
  },
  divider: {
    background: theme.palette.primary.main
  },
  big: {
    height: 2
  },
  checkupHeader: {
    marginBottom: theme.spacing(1),
  },
  headerText: {
    color: theme.palette.confirm.main,
    fontWeight: 'bold'
  },
  datepicker: {
    background: theme.palette.grey.light,
    borderBottom: `2px solid ${theme.palette.grey.dark}`,
    paddingLeft: theme.spacing(1),
  },
  centeredContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  horizontallyCentered: {
    justifyContent: 'center',
    minWidth: 50
  },
  checkedRadio: {
    color: theme.palette.primary.main
  },
  rightMargin: {
    marginRight: theme.spacing(2),
  },
  checkupContainer: {
    display: 'flex',
    // gridTemplateColumns: '50px 1fr 1fr 1fr 1.5fr',
    maxWidth: 1200,
    width: '100%',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2)
    }
  },
  fullWidth: {
    width: '100%'
  }
})

const textFieldStyle = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '0.7fr 0.3fr',
    gap: theme.spacing(1)
  }
}))

const CommentTextField = ({ submit, checkupComment }) => {
  const [comment, setComment] = useState(checkupComment)
  const [editing, setEditing] = useState(false)
  const theme = useTheme()
  const classes = textFieldStyle()

  const handleChange = event => {
    setComment(event.target.value)
  }

  const handleSubmit = () => {
    submit(comment)
    setEditing(false)
  }

  const handleKeyPress = e => {
    if (e.target.value === checkupComment) return
    setEditing(true)
  }

  return (
    <div className={classes.container}>
      <TextField
        inputProps={{ style: { padding: '6px 0 7px 6px', borderBottom: `2px solid ${theme.palette.grey.dark}` } }}
        InputProps={{ style: { borderRadius: 0, padding: 0 } }}
        variant="filled"
        multiline
        onChange={handleChange}
        onKeyUp={editing ? undefined : handleKeyPress}
        value={comment}
        placeholder="Agregar un comentario"
      />
      <div>
        {editing && (
          <Button onClick={handleSubmit}>
            <Typography>
              Guardar
            </Typography>
          </Button>
        )}
      </div>
    </div>
  )
}

CommentTextField.propTypes = {
  submit: propTypes.func.isRequired,
  checkupComment: propTypes.string.isRequired
}

class CheckupSection extends React.Component {
  constructor(props) {
    super(props)
    autobind(CheckupSection, this)
  }

  handleChangeFollowDate(checkupId) {
    return value => {
      const { reload, setCheckupFollowDate } = this.props
      const requestBody = {
        date: value.format('DD/MM/YYYY'),
        checkup_id: checkupId
      }
      setCheckupFollowDate(requestBody).then(() => reload())
    }
  }

  handleCheckControl(event) {
    const { target } = event
    const { checkCheckup, reload } = this.props
    checkCheckup(target.name).then(() => reload())
  }

  handleSubmit(id) {
    const { setCheckupComment, reload } = this.props
    return comment => {
      const body = {
        checkup_id: id,
        comment
      }
      setCheckupComment(body).then(() => reload())
    }
  }

  renderHeader() {
    const { editable, classes } = this.props
    const titles = editable
      ? [
        { label: 'Tipo de control', width: '100%' },
        { label: 'Fecha de seguimiento', width: '100%' },
        { label: 'Fecha último control', width: '100%' },
        { label: 'Comentario', width: '100%', minWidth: '300px' }
      ]
      : [{ label: 'Tipo de control', width: '100%' }, { label: 'Fecha último control', width: '100%' }]
    return (
      <>
        <div>
          <div className={classes.header}>
            <Typography variant="h2">Controles</Typography>
            <div>
              <OnTouchTooltip title={tooltipText}>
                <HelpOutline className={classes.tooltipIcon} />
              </OnTouchTooltip>
            </div>
          </div>
          <Divider className={classNames(classes.divider, classes.big)} />
        </div>
        <div
          className={classNames(classes.checkupContainer, classes.checkupHeader)}
        >
          <div style={{ minWidth: 50 }} />
          {titles.map(({ label, width, minWidth }) => (
            <div style={{ width, minWidth }}>
              <Typography className={classes.headerText}>
                {label}
              </Typography>
            </div>
          ))}
        </div>
      </>
    )
  }

  renderCheckup(checkup) {
    const { classes, editable } = this.props
    const firstHistoryDate = checkup.histories.length === 0 ? null : checkup.histories[0].date
    return (
      <div
        className={classes.checkupContainer}
        key={checkup.id}
      >
        <div className={classNames(classes.centeredContainer, classes.horizontallyCentered)}>
          <Checkbox
            onChange={this.handleCheckControl}
            name={checkup.id}
            checked={checkup.checked}
            icon={
              <RadioButtonUnchecked />
            }
            checkedIcon={(
              <CheckCircle
                className={classes.checkedRadio}
              />
            )}
          />
        </div>
        <div className={classNames(classes.centeredContainer, classes.fullWidth)}>
          <Typography>
            {checkup.text}
          </Typography>
        </div>
        {editable && (
          <div className={classes.fullWidth}>
            <DatePicker
              disableToolbar={false}
              onChange={this.handleChangeFollowDate(checkup.id)}
              value={checkup.date}
              inputClassName={classes.datepicker}
            />
          </div>
        )}
        <div className={classes.fullWidth}>
          <DatePicker
            disabled
            value={firstHistoryDate}
            inputClassName={classes.datepicker}
            emptyLabel="Sin controles registrados"
          />
        </div>
        {editable && (
          <div className={classes.fullWidth} style={{ minWidth: 300 }}>
            <CommentTextField
              submit={this.handleSubmit(checkup.id)}
              checkupComment={checkup.comment}
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    const { classes, checkups = [] } = this.props
    return (
      <div className={classes.container}>
        {this.renderHeader()}
        {checkups.map(checkup => this.renderCheckup(checkup))}
      </div>
    )
  }
}

CheckupSection.propTypes = {
  classes: propTypes.object.isRequired,
  checkups: propTypes.arrayOf(propTypes.object).isRequired,
  reload: propTypes.func.isRequired,
  setCheckupFollowDate: propTypes.func.isRequired,
  setCheckupComment: propTypes.func.isRequired,
  checkCheckup: propTypes.func.isRequired,
  editable: propTypes.bool
}

CheckupSection.defaultProps = {
  editable: true
}

const mapDispatchToProps = dispatch => ({
  setCheckupFollowDate: body => dispatch(setCheckupFollowDateAction(body)),
  checkCheckup: body => dispatch(checkCheckupAction(body)),
  setCheckupComment: body => dispatch(setCheckupCommentAction(body))
})

export default connect(null, mapDispatchToProps)(withStyles(style)(CheckupSection))
