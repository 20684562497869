import StatusReducer from './StatusReducer'
import EventsReducer from './EventsReducer'
import HandlerReducer from './Handler'
import ContendersReducer from './ContendersReducer'
import UserReducer from './UserReducer'
import RoutesReducer from './RoutesReducer'
import SearchReducer from './SearchReducer'
import controllerReducer from './ControllerReducer'
import historyReducer from './HistoryReducer'
import taskReducer from './TaskReducer'
import BreadcrumbsReducer from './BreadcrumbsReducer'
import functionalReducer from './FunctionalReducer'
import authorizationsReducer from './AuthorizationsReducer'
import enterpriseReducer from './EnterpriseReducer'
import programReducer from './ProgramReducer'
import competencyEvaluationsReducer from './CompetencyEvaluationReducer'
import communeReducer from './CommuneReducer'
import organizationsReducer from './ExternalOrganizations'

const reducers = {
  status: StatusReducer,
  events: EventsReducer,
  contenders: ContendersReducer,
  user: UserReducer,
  routes: RoutesReducer,
  handler: HandlerReducer,
  search: SearchReducer,
  controller: controllerReducer,
  history: historyReducer,
  task: taskReducer,
  breadcrumbs: BreadcrumbsReducer,
  functional: functionalReducer,
  authorizations: authorizationsReducer,
  enterprises: enterpriseReducer,
  programs: programReducer,
  competency: competencyEvaluationsReducer,
  communes: communeReducer,
  organizations: organizationsReducer,
}

export default reducers
