import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { CheckCircle, ErrorOutline } from '@material-ui/icons'
import { connect } from 'react-redux'
import ReportTitles from '../ReportTitles'
import ResultPercentages from '../../../../Results/ResultPercentages'

const style = theme => ({
  content: {
    display: 'flex',
    alignItems: 'stretch',
    '& > div': {
      padding: 12,
      width: '50%'
    }
  },
  criteria: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '50%'
    },
    marginTop: 12
  },
  criterium: {
    display: 'flex',
    '& > *': {
      marginRight: 6
    },
    margin: '3px 0'
  },
  icon: {
    height: 16,
    width: 16
  },
  container: {
    marginBottom: 48
  },
  observations: {
    padding: 12,
    '& > *': {
      margin: '3px 0'
    }
  },
  error: {
    color: theme.palette.alert.main,
    height: 16,
    width: 16
  }
})

class POI extends Component {
  renderCriteria() {
    const { classes, contenders } = this.props
    const results = contenders.profileResults
    const { gathering } = results
    return Object.entries(gathering?.forms)?.map(([key, value]) => (
      <div className={classes.criterium} key={key}>
        {value.checked ? <CheckCircle color="primary" className={classes.icon} /> : <ErrorOutline className={classes.error} />}
        <Typography variant="subtitle2">
          {key}
        </Typography>
      </div>
    ))
  }

  render() {
    const { classes, contenders } = this.props
    const results = contenders.profileResults
    const { gathering, comments } = results
    return (
      <div className={classes.container}>
        <ReportTitles title="Pauta de Observación Inicial" />
        <div className={classes.content}>
          <Paper>
            <ResultPercentages
              title="Pauta de Observación Inicial"
              subtitle1="Ponderación Postulante"
              subtitle2="Ponderación Acompañante"
              result={{
                handler_percentage: gathering?.handler_percentage || 0,
                companion_percentage: gathering?.companion_percentage || 0,
                average_percentage: gathering?.average_percentage || 0
              }}
              containerStyle={{}}
            />
          </Paper>
          <div>
            <Typography variant="subtitle1">Cumple con criterios:</Typography>
            <div className={classes.criteria}>
              {this.renderCriteria()}
            </div>
          </div>
        </div>
        <div className={classes.observations}>
          <Typography variant="subtitle1">Observaciones de Pauta de Observación inicial</Typography>
          <Typography variant="body1">
            {comments?.gathering?.comment || 'No hay comentarios'}
          </Typography>
        </div>
      </div>
    );
  }
}

POI.propTypes = {
  classes: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
}

POI.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(POI))
