import React, { Component } from 'react'
import { withStyles, Grid } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    fontSize: 12,
    height: '10&',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 6,
    transition: '0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#DCE2F2',
    },
    '& p': {
      color: 'black !important',
    },
  },
  text: {
    padding: '0 6px',
    margin: 0,
  },
})

const minHeight = 570
const EVENT_LIMIT = Math.floor((window.innerHeight - minHeight) / 140) + 1

class CalendarEvent extends Component {
  render() {
    const { classes, handleOpen, events } = this.props
    const remainder = events.length - EVENT_LIMIT
    return (
      <Grid className={classes.container} onClick={handleOpen}>
        <p className={classes.text}>{`${remainder} más`}</p>
      </Grid>
    )
  }
}

CalendarEvent.propTypes = {
  classes: propTypes.object.isRequired,
  handleOpen: propTypes.func.isRequired,
  events: propTypes.arrayOf(propTypes.shape({ name: propTypes.string, time: propTypes.string }))
    .isRequired,
}

export default withStyles(style)(CalendarEvent)
