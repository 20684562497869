import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import DownloadableSection from 'Shared/DownloadableSection'
import RecurrentContext from '../../RecurrentEvaluations/RecurrentContext'
import GlobalComment from '../../../Shared/GlobalComment'
import ProgressLineChart from './ProgressLineChart'
import MultipleBarPercentile from './MultipleBarPercentile'
import { getFormEvaluation } from '../../../API/Recurrent'
import { getSupportLevelFamiliar } from './Baremos'
import { calculatePercentiles, calculateSupportLevels, calculateTotalPercentile, sobrecargaLevel } from './utils'
import LoaderDialog from '../../../Shared/LoaderDialog'

const style = () => ({
  headerCharts: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > *:first-child': {
      width: 500,
      marginRight: 24
    },
    margin: '36px 0'
  },
  row: {
    display: 'flex',
    '& > *': {
      minWidth: 120,
      maxWidth: 120,
      padding: '3px 12px',
    },
    '& > *:nth-child(3)': {
      minWidth: 200,
      maxWidth: 200,
      padding: '3px 12px',
    },
  },
  tableRow: {
    display: 'flex',
    '& > *': {
      minWidth: 120,
      maxWidth: 120,
      padding: '6px 12px',
    },
    '& > *:nth-child(3)': {
      minWidth: 200,
      maxWidth: 200,
      padding: '6px 12px',
    },
    borderRadius: 8,
    background: '#ECECEC',
    margin: '6px 0'
  },
  secondSection: {
    minWidth: 540,
    marginTop: 84
  }
})

class MultipleResultsGencat extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      evaluations: []
    }
  }

  async componentDidMount() {
    const { getEvaluations, event } = this.context
    this.setState({ loading: true })
    const response = await getEvaluations(event)
    if (response.data.status !== 'success') return this.setState({ loading: false })
    const evaluations = response.data.info
    const doneEvaluations = evaluations.filter(group => group.status === 'check')
    const evaluationPromises = doneEvaluations.map(evaluation => {
      const body = {
        form_id: event.form_id,
        evaluation_id: evaluation.id,
      }
      return getFormEvaluation(body)
    })
    const evaluationsResultResponse = await Promise.all(evaluationPromises)
    const finalEvaluations = evaluationsResultResponse.map(r => r.data.info)
    return this.setState({ loading: false, evaluations: finalEvaluations })
  }

  calculateData() {
    const { evaluations } = this.state
    const { user } = this.context
    const lineData = evaluations.map(evaluation => {
      const questions = evaluation?.['Escala de Calidad de Vida Familiar']?.['Escala de Calidad de Vida Familiar']?.['Escala de Calidad de Vida Familiar']
      return calculateTotalPercentile(questions, user)
    })

    const barData = evaluations.map(evaluation => {
      const questions = evaluation?.['Escala de Calidad de Vida Familiar']?.['Escala de Calidad de Vida Familiar']?.['Escala de Calidad de Vida Familiar']
      const result = calculatePercentiles(questions, user, true)
      return result
    })

    const sobrecargaValues = evaluations.map(evaluation => {
      const questions = evaluation?.['Escala de Calidad de Vida Familiar']?.['Escala de Calidad de Vida Familiar']?.['Escala de Calidad de Vida Familiar']
      const avgValues = calculateSupportLevels(questions, user)
      const level = sobrecargaLevel[getSupportLevelFamiliar(avgValues['Sobrecarga del cuidador'], 'Sobrecarga del cuidador')]
      const value = avgValues['Sobrecarga del cuidador']
      return { level, value }
    })
    return [lineData, barData, sobrecargaValues]
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state
    const { event, user } = this.context
    const [line, bar, sobre] = this.calculateData()
    return (
      <div className={classes.container}>
        <LoaderDialog open={loading} />
        <GlobalComment event={event} />
        <>
          <div className={classes.headerCharts}>
            <DownloadableSection id="fe" name={`Progreso Escala calidad de vida familiar ${user?.name}`}>
              <div>
                <ProgressLineChart data={line} />
              </div>
            </DownloadableSection>
            <div />
            <DownloadableSection id="fd" name={`Indicador de sobrecarga ${user?.name}`}>
              <div className={classes.secondSection}>
                <Typography variant="h2" style={{ fontWeight: 600, marginBottom: 12 }}>Indicador de Sobrecarga del cuidador</Typography>
                <div className={classes.row}>
                  <Typography variant="subtitle1">
                    N° Evaluación
                  </Typography>
                  <Typography variant="subtitle1">Puntaje</Typography>
                  <Typography variant="subtitle1">Indicación</Typography>
                </div>
                {sobre.map((sobrecargaInfo, index) => (
                  <div className={classes.tableRow}>
                    <Typography variant="subtitle1">
                      Evaluación
                      {index + 1}
                    </Typography>
                    <Typography variant="subtitle1">{sobrecargaInfo.value}</Typography>
                    <Typography variant="subtitle1">{sobrecargaInfo.level}</Typography>
                  </div>
                ))}
              </div>
            </DownloadableSection>
            <div />
          </div>
          <DownloadableSection id="ff" name={`Percentil por dimensión de ${user?.name}`}>
            <div style={{ margin: '64px 0' }}>
              <MultipleBarPercentile data={bar} />
            </div>
          </DownloadableSection>
        </>
      </div>
    );
  }
}

MultipleResultsGencat.contextType = RecurrentContext

MultipleResultsGencat.propTypes = {
  classes: propTypes.object.isRequired
}

MultipleResultsGencat.defaultProps = {

}
export default withStyles(style)(MultipleResultsGencat)
