import React, { Component } from 'react'
import { Button, Divider, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import FloatingComment from 'Shared/FloatingComment'
import FlexibleInput from '../../Utils/FieldRenderer'
import autobind from '../../Utils/autobind'
import { sendAnswersAction } from '../../Actions/Events'
import { changeEvaluationGroupStatus, changeFormStatus, createEvaluationGroupEvaluation, getEvaluationGroup, getFormEvaluation } from '../../API/Recurrent'

const style = theme => ({
  container: {
    padding: 24,
    margin: 'auto',
    textAlign: 'left',
    maxWidth: 800,
  },
  formContainer: {
    padding: 24
  },
  titleDivider: {
    marginBottom: 24,
    background: theme.palette.primary.main
  },
  question: {
    margin: '12px 0'
  },
  bold: {
    fontWeight: 600
  },
  disclaimer: {
    '& > *': {
      margin: '12px 0'
    }
  }
})

class LaboralPerformanceEvaluationLink extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      recentEvaluation: null,
      ready: false,
      group: null,
      name: '',
      type: null,
      recentEvaluationInfo: {}
    }
    this.positionQuestionId = ''
    autobind(LaboralPerformanceEvaluationLink, this)
  }

  async componentDidMount() {
    const { match } = this.props
    const { form_id: formId, group_id: groupId, cont_name: contenderName } = match.params

    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');

    const body = {
      form_id: formId,
      evaluation_group_id: groupId,
    }
    const oldGroupResonse = await getEvaluationGroup(body)
    if (oldGroupResonse.data.status !== 'success') return null
    const oldGroup = oldGroupResonse.data.info

    await this.handleCreateEvaluation(oldGroup)

    const groupResponse = await getEvaluationGroup(body)
    if (groupResponse.data.status !== 'success') return null
    const group = groupResponse.data.info

    this.setState({ group, type })
    const { evaluations = [] } = group

    if (evaluations.length === 0) return null

    const lastIndex = evaluations.length - 1
    const recentEvaluationInfo = evaluations[lastIndex]
    const evaluationBody = { form_id: group.form_group_id, evaluation_id: recentEvaluationInfo.id }
    const response = await getFormEvaluation(evaluationBody)

    if (response.data.status !== 'success') return null

    const recentEvaluation = response.data.info
    // feed params with latest answers
    this.feedParams(recentEvaluation, type)
    return this.setState({
      recentEvaluation, name: atob(contenderName), type, recentEvaluationInfo
    })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  async handleSave() {
    const { params, group } = this.state
    const { sendAnswers, match } = this.props
    const { event_id: eventId, form_id: formId, group_id: groupId } = match.params
    const answers = { ...params }
    const body = Object.entries(answers).map(([key, value]) => ({
      id: key,
      body: { answer: value },
      comment: '',
    }))
    sendAnswers({ answers: body }, eventId)
    const { evaluations = [] } = group
    const latest = evaluations[evaluations.length - 1]
    await changeFormStatus({ evaluation_id: latest.id, status: 'check' })
    if (answers[this.positionQuestionId] === '2') {
      // Si es una facilitadora
      const checkBody = {
        form_id: formId,
        evaluation_group_id: groupId,
        status: 'check'
      }
      changeEvaluationGroupStatus(checkBody)
    }
    this.setState({ ready: true })
  }

  async handleCreateEvaluation(group) {
    const body = {
      form_id: group.form_group_id,
      evaluation_group_id: group.id
    }
    const response = await createEvaluationGroupEvaluation(body)
    if (response.data.status !== 'success') return null
    const recentEvaluationInfo = response.data.info
    const evaluationBody = { form_id: group.form_group_id, evaluation_id: recentEvaluationInfo.id }
    const evaluationResponse = await getFormEvaluation(evaluationBody)

    if (evaluationResponse.data.status !== 'success') return null

    const recentEvaluation = evaluationResponse.data.info
    // feed params with new created evaluation
    return this.setState({ recentEvaluation, params: {}, recentEvaluationInfo })
  }

  feedParams(recentEvaluation, type) {
    const { params } = this.state
    const form = recentEvaluation[`Evaluación de Desempeño Laboral${type}`].empty
    Object.keys(form).forEach(key => {
      const questions = form[key]
      questions.forEach(question => {
        const hasAnswer = !!question?.answers?.[0]?.body
        if (!hasAnswer) return null
        const answer = question.answers[0]
        params[answer.id] = answer.body.answer
        return this.setState({ params })
      })
    })
  }

  renderQuestions(questions) {
    const { classes } = this.props
    const { params } = this.state
    return questions.map((question, index) => {
      const name = question?.answers[0]?.id
      const info = { ...question }
      info.name = name
      info.text = `${index + 1} - ${question.text}`
      if (question.text === 'Cargo:') {
        this.positionQuestionId = name
      }
      return (
        <div className={classes.question}>
          <FlexibleInput
            complete={info}
            value={params[name]}
            onChange={this.handleChange}
            onSubchange={this.handleSubChange}
          />
        </div>
      )
    })
  }

  renderSection(formSection, title) {
    const { classes } = this.props
    if (title === 'empty') {
      return (
        <div>
          {this.renderQuestions(formSection)}
        </div>
      )
    }
    return (
      <div className={classes.section}>
        <div>
          <Typography variant="h2">{title}</Typography>
          <Divider className={classes.titleDivider} />
          {this.renderQuestions(formSection)}
        </div>
      </div>
    )
  }

  renderEvaluation() {
    const { recentEvaluation, type } = this.state
    if (!recentEvaluation || type === null) return null
    const form = recentEvaluation[`Evaluación de Desempeño Laboral${type}`].empty
    return Object.keys(form).map(key => this.renderSection(form[key], key))
  }

  render() {
    const { classes, match } = this.props;
    const { ready, name, params, type, recentEvaluationInfo } = this.state
    const answeredQuestions = Object.keys(params).length
    const { form_id: formId, event_id: eventId } = match.params
    console.log(recentEvaluationInfo)
    const eventInfo = {
      event_id: eventId,
      form_id: formId
    }
    if (ready) {
      return (
        <div className={classes.container}>
          <Paper className={classes.formContainer}>
            <Typography variant="h1">¡Gracias por responder!</Typography>
          </Paper>
        </div>
      )
    }
    return (
      <div className={classes.container}>
        <FloatingComment event={eventInfo} evaluation={recentEvaluationInfo.id} />
        <Paper className={classes.formContainer}>
          <Typography variant="h2" className={classes.bold} style={{ fontSize: 17 }} color="primary">
            {`Evaluación de desempeño laboral${type} de ${name || 'cargando...'}`}
          </Typography>
          <div className={classes.disclaimer}>
            <Typography variant="h2">Gracias por tomar el tiempo para responder este formulario.</Typography>
            <Typography variant="h2" className={classes.bold}>Importante:</Typography>
            <Typography variant="subtitle1" style={{ paddingLeft: 24 }}>1. Por favor asegurarse de que el nombre del trabajador es el correcto. </Typography>
            <Typography variant="subtitle1" style={{ paddingLeft: 24 }}>2. Para enviar las respuestas debes hacer click en el botón guardar al término de la evaluación. </Typography>
            <Typography variant="subtitle1" style={{ paddingLeft: 24 }}>3. Recuerda que al hacer click en el botón guardar, no podrás editar respuestas enviadas.</Typography>
            <Typography variant="h2">¡Gracias!</Typography>
          </div>
          <Divider className={classes.titleDivider} />
          {this.renderEvaluation()}
          <Button color="primary" variant="contained" onClick={this.handleSave} disabled={answeredQuestions < 34}>
            Guardar
          </Button>
        </Paper>
      </div>
    );
  }
}

LaboralPerformanceEvaluationLink.propTypes = {
  classes: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  sendAnswers: propTypes.func.isRequired,
}

LaboralPerformanceEvaluationLink.defaultProps = {
}

const mapDispatchToProps = dispatch => ({
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id))
})

export default connect(
  null, mapDispatchToProps
)(withStyles(style)(LaboralPerformanceEvaluationLink))
