import React from 'react'
import propTypes from 'prop-types'
import FieldRenderer from '../../../../Utils/FieldRenderer'


class POIAnsweredQuestion extends React.Component {
  render() {
    const { question } = this.props
    const complete = {
      ...question,
      has_comment: false,
      id: question.answers.id,
      name: question.answers.id,
    }
    const answerBody = question.answers.body ? question.answers.body.answer : ''
    const subquestions = question.answers.body ? question.answers.body.subquestions : {}
    return (
      <div style={{ marginBottom: 6 }}>
        <FieldRenderer
          onChange={() => ({})}
          complete={complete}
          value={answerBody}
          subquestions={subquestions}
          disabled
          answered
        />
      </div>
    )
  }
}

POIAnsweredQuestion.propTypes = {
  question: propTypes.object.isRequired
}

export default POIAnsweredQuestion
