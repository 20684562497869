/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { Typography, withStyles, Divider, Paper, IconButton } from '@material-ui/core'
import propTypes from 'prop-types'
import { KeyboardArrowRight } from '@material-ui/icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import autobind from '../../../../Utils/autobind'
import { getContenderFormsAction } from '../../../../Actions/Contenders'
import Loader from '../../../../Shared/Loader'
import FormRenderer from './FormRenderer'
import SetViewContext from '../SetViewProvider'

const style = theme => ({
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0',
    '& h6': {
      color: theme.palette.grey.dark
    }
  },
  container: {
    padding: 24,
    textAlign: 'left'
  },
  title: {
    fontWeight: 600,
    marginBottom: 24
  },
  inline: {
    display: 'grid',
    gridTemplateColumns: '6fr 4fr 2fr 1fr',
    alignItems: 'center',
    '&:hover': {
      transform: 'scale(1.01)'
    },
    transition: 'all 0.2s ease-in-out'
  },
  bold: {
    fontWeight: 900
  },
  alignEnd: {
    justifySelf: 'flex-end'
  },
  inline2: {
    display: 'grid',
    gridTemplateColumns: '6fr 4fr 3fr',
    alignItems: 'center',
    '&:hover': {
      transform: 'scale(1.01)'
    },
    transition: 'all 0.2s ease-in-out'
  },
  eventContainer: {
    padding: 12,
    borderRadius: 8,
    background: theme.palette.grey.light,
    boxShadow: '0px 1px 8px #00000026'
  },
  eventContainerPending: {
    padding: 12,
    background: theme.palette.indigo.lighter,
    borderRadius: 8,
    boxShadow: '0px 1px 8px #00000026'
  },
  goToEvaluationContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

class Evaluations extends Component {
  constructor(props) {
    super(props)
    autobind(Evaluations, this)
  }

  componentDidMount() {
    const { getContenderForms, match } = this.props
    const { id } = match.params
    getContenderForms(id)
  }

  handleSetView(event) {
    return () => {
      const { setView } = this.context
      const { history, contenders } = this.props
      const { selected } = contenders
      setView(<FormRenderer event={event} history={history} contender={selected} />, event.name)
    }
  }

  renderPendingsLikeTable(data) {
    const { classes } = this.props
    const allData = Array.isArray(data) ? data : []
    return allData.map(event => {
      const allocationName = event.allocation_name ? event.allocation_name : 'Sin asignar'
      return (
        <div
          key={event.form_id}
          onClick={this.handleSetView(event)}
          role="button"
        >
          <div className={classes.inline2} style={event.disabled ? {} : { cursor: 'pointer' }}>
            <div>
              <Typography variant="body2" style={{ fontWeight: 600, padding: 12 }}>{event.name}</Typography>
            </div>
            <div>
              <Typography className={classes.bold}>
                {allocationName}
              </Typography>
            </div>
            <div className={classes.goToEvaluationContainer}>
              <IconButton size="small">
                <KeyboardArrowRight />
              </IconButton>
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
        </div>
      )
    })
  }

  renderConcludedLikeTable(data) {
    const { classes } = this.props
    const allData = Array.isArray(data) ? data : []
    return allData.map(event => {
      const allocationName = event.allocation_name ? event.allocation_name : 'Sin asignar'
      const formattedDate = moment(event.date).format('DD/MM/YYYY')
      return (
        <div
          key={event.form_id}
          onClick={event.disabled ? null : this.handleSetView(event)}
          role="button"
        >
          <div className={classes.inline} style={event.disabled ? {} : { cursor: 'pointer' }}>
            <div>
              <Typography variant="body2" style={{ fontWeight: 600, padding: 12 }}>{event.name}</Typography>
            </div>
            <div>
              <Typography className={classes.bold}>
                {allocationName}
              </Typography>
            </div>
            <div className={classes.alignEnd}>
              <Typography>
                {formattedDate}
              </Typography>
            </div>
            <div className={classes.goToEvaluationContainer}>
              <IconButton size="small">
                <KeyboardArrowRight />
              </IconButton>
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
        </div>
      )
    })
  }

  renderPendings() {
    const { contenders, classes } = this.props
    const { forms } = contenders
    const pends = forms.pending || {}
    const tables = Object.keys(pends).map((state, idx) => (
      <div
        className={classes.eventContainerPending}
        style={idx !== 0 ? { marginTop: 12 } : undefined}
      >
        <Typography>
          {state}
        </Typography>
        {this.renderPendingsLikeTable(pends[state])}
      </div>
    ))
    if (tables.length === 0) {
      return (
        <div className={classes.eventContainerPending}>
          <Typography>
            No hay evaluaciones pendientes
          </Typography>
        </div>
      )
    }
    return tables
  }

  renderConcluded() {
    const { contenders, classes } = this.props
    const { forms } = contenders
    const checks = forms.check || {}
    const tables = Object.keys(checks).map((state, idx) => (
      <div className={classes.eventContainer} style={idx !== 0 ? { marginTop: 12 } : undefined}>
        <Typography>
          {state}
        </Typography>
        {this.renderConcludedLikeTable(checks[state])}
      </div>
    ))
    if (tables.length === 0) {
      return (
        <div className={classes.eventContainer}>
          <Typography>
            No hay evaluaciones concluidas
          </Typography>
        </div>
      )
    }
    return tables
  }

  render() {
    const { classes, getContenderForms, match } = this.props
    const { id } = match.params
    return (
      <Paper className={classes.container}>
        <Typography variant="h3" className={classes.title}>Evaluaciones</Typography>
        <div className={classes.subtitle}>
          <Typography variant="subtitle2">Pendientes</Typography>
          <Divider style={{ flexGrow: 1, margin: '0 0 0 24px' }} />
        </div>
        <Loader action={getContenderForms} params={[id]}>
          {this.renderPendings()}
        </Loader>
        <div className={classes.subtitle}>
          <Typography variant="subtitle2">Concluidos</Typography>
          <Divider style={{ flexGrow: 1, margin: '0 0 0 24px' }} />
        </div>
        <Loader action={getContenderForms} params={[id]}>
          {this.renderConcluded()}
        </Loader>
      </Paper>
    )
  }
}

Evaluations.contextType = SetViewContext

Evaluations.propTypes = {
  classes: propTypes.object.isRequired,
  getContenderForms: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
}

Evaluations.defaultProps = {
}

const mapStateToProps = state => ({
  contenders: state.contenders
})

const mapDispathToProps = dispatch => ({
  getContenderForms: id => dispatch(getContenderFormsAction(id))
})

export default withRouter(
  connect(mapStateToProps, mapDispathToProps)(withStyles(style)(Evaluations))
)
