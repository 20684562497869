import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import autobind from '../../../Utils/autobind'
import HandlerProfileContext from './HandlerProfileContext'

const style = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *:nth-child(2)': {
      fontWeight: 600
    },
    margin: '24px 0'
  },
  itemTitle: {
    fontSize: 14,
    color: theme.palette.grey.dark,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  itemDivider: {
    backgroundColor: theme.palette.grey.light,
  },
})

class HandlerInfoRow extends Component {
  constructor() {
    super()
    this.state = {
      openEdit: false
    }
    autobind(HandlerInfoRow, this)
  }

  handleOpenEdit() {
    const { openEdit } = this.state
    this.setState({ openEdit: !openEdit })
  }

  render() {
    const { classes, name, label, info, editComponent: EditComp, hideText, format } = this.props
    const { openEdit } = this.state
    const { onChange } = this.context
    const placeholder = hideText ? '*******' : 'No ingresado'
    const answerValue = (info && info[name]) || placeholder
    const finalValue = (format && info && info[name]) ? format(answerValue) : answerValue
    return (
      <>
        <div className={classes.container}>
          <Typography className={classes.itemTitle}>{label}</Typography>
          {openEdit
            ? (
              <EditComp
                onChange={onChange}
                name={name}
                value={(info && info[name])}
                onBlur={this.handleOpenEdit}
              />
            ) : (
              <Typography
                onClick={this.handleOpenEdit}
                className={classes.itemTitle}
              >
                {finalValue}
              </Typography>
            )}
        </div>
        <Divider classes={{ root: classes.itemDivider }} />
      </>
    );
  }
}

HandlerInfoRow.contextType = HandlerProfileContext

HandlerInfoRow.propTypes = {
  classes: propTypes.object.isRequired,
  editComponent: propTypes.node,
  name: propTypes.string,
  info: propTypes.object,
  label: propTypes.string,
  hideText: propTypes.bool,
  format: propTypes.func
}

HandlerInfoRow.defaultProps = {
  name: 'name',
  label: 'Nombre',
  info: {},
  editComponent: null,
  hideText: false,
  format: undefined
}

export default withStyles(style)(HandlerInfoRow)
