import React, { Component } from 'react'
import { withStyles, Paper, Typography } from '@material-ui/core'
import propTypes from 'prop-types'


const style = theme => ({
  container: {
    width: '50vw',
    margin: 'auto',
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
  topButton: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.grey.dark,
  },
  header: {
    fontSize: 30,
    color: theme.palette.grey.dark,
    textAlign: 'center',
  },
  firstParagraph: {
    fontSize: 16,
    color: theme.palette.grey.dark,
    marginBottom: 30,
    textAlign: 'center',
  },
  secondParagraph: {
    fontSize: 16,
    color: theme.palette.grey.dark,
    textAlign: 'center',
  },
  icon: {
    color: theme.palette.indigo.light,
  },
  stepNumber: {
    color: theme.palette.grey.dark,
    fontSize: 30,
    margin: '0 5%',
    fontWeight: 'bold',
  },
  stepName: {
    color: theme.palette.grey.dark,
    fontSize: 16,
    fontWeight: 'bold',
  },
  star: {
    color: '#FFE622',
  },
})

class Result extends Component {
  render() {
    const { classes } = this.props
    return (
      <Paper className={classes.container}>
        <div style={{ margin: 12 }}>
          <Typography variant="h1" className={classes.header}>
            ¡Gracias!
          </Typography>
        </div>
        <div style={{ margin: 12, marginBottom: 30 }}>
          <Typography className={classes.firstParagraph}>
            Esta información es importante para conocer las potencialidades de cada persona y de cada familia, lo que nos permite decidir que servicios, programas y apoyos ofrecer.
          </Typography>
          <Typography className={classes.firstParagraph}>
            Hemos finalizado la jornada de presentación. A continuación le enviaremos un correo electrónico con información que nos debe devolver. Completar cada paso es fundamental para avanzar en el proceso.
          </Typography>
          <Typography className={classes.secondParagraph}>
            ¡Ánimo!
          </Typography>
        </div>
        <img src="/roadmap-01.png" alt="Gracias!" style={{ width: '85%' }} />
      </Paper>
    )
  }
}

Result.propTypes = {
  classes: propTypes.object.isRequired,
}

export default withStyles(style)(Result)
