import React, { Component } from 'react'
import { Dialog, Typography, withStyles, Checkbox, Button, MenuItem } from '@material-ui/core'
import propTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import { connect } from 'react-redux'
import TextField from '../../../Contenders/FormTextfield'
import autobind from '../../../../Utils/autobind'

const familiarSectionsText = ['Relaciones familiares', 'Experiencia educacional', 'Características personales, intereses y proyección', 'Perfil del candidato']
const individualSectionsText = ['Relaciones familiares', 'Experiencia educacional y laboral', 'Características personales, intereses y proyección']

const style = theme => ({
  container: {
    padding: 24
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  question: {
    display: 'grid',
    gridTemplateColumns: '42px auto 150px',
    alignItems: 'center',
    margin: '6px -24px',
    padding: '0 24px'
  },
  selectContainer: {
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
  },
  checkboxContainer: {
    justifySelf: 'start',
  },
  darkText: {
    color: theme.palette.grey.dark
  },
  buttondiv: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    background: theme.palette.confirm.main,
    marginTop: 24
  },
  newQuestion: {
    marginTop: 12,
  },
  select: {
    padding: '4px 0 6px 6px',
    color: 'black'
  }
})

class QuestionDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      questionText: ''
    }
    autobind(QuestionDialog, this)
  }

  handleTextChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  renderQuestions() {
    const { selected,
      sections,
      handleSelect,
      classes,
      contenders,
      criterium,
      handleSelectSection } = this.props
    const dynamicCriteria = contenders.dynamics ? contenders.dynamics : {}
    const dynamicCriterium = dynamicCriteria[criterium]
      ? dynamicCriteria[criterium] : { questions: [], form_group_name: 'Entrevista Individual' }
    const sectionsText = dynamicCriterium.form_group_name === 'Entrevista Individual' ? individualSectionsText : familiarSectionsText
    if (dynamicCriterium.questions.length) {
      return dynamicCriterium.questions.map(question => {
        const checked = selected.indexOf(question.id) !== -1
        const value = Object.keys(sections).includes(question.id) ? sections[question.id] : -1
        return (
          <div className={classes.question} style={{ background: selected.indexOf(question.id) !== -1 ? '#f1f4fa' : 'unset' }} key={question.id}>
            <div className={classes.checkboxContainer}>
              <Checkbox checked={checked} onClick={handleSelect(question.id, criterium)} color="primary" />
            </div>
            <Typography variant="subtitle2" className={classes.darkText}>{question.text}</Typography>
            <div className={classes.selectContainer}>
              <Select
                placeholder="Seleccionar"
                disabled={!checked}
                onChange={handleSelectSection(question.id)}
                fullWidth
                value={value}
                variant="filled"
                classes={{ select: classes.select }}
                style={{ borderRadius: 0 }}
              >
                <MenuItem value={-1}>
                  Sin asignar
                </MenuItem>
                {
                  sectionsText.map((section, idx) => (
                    <MenuItem value={idx} key={`${section}-key`}>
                      {section}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          </div>
        )
      })
    }
    return <Typography> Aún no hay preguntas en el banco </Typography>
  }

  render() {
    const { questionText } = this.state
    const { open, classes, onClose, criterium, onSubmit, onCreation, contenders } = this.props
    const dynamicCriteria = contenders.dynamics ? contenders.dynamics : {}
    const dynamicCriterium = dynamicCriteria[criterium]
    const criteriumId = dynamicCriterium ? dynamicCriterium.meta.id : null
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <div className={classes.container}>
          <div className={classes.title}>
            <Typography variant="subtitle2" className={classes.darkText}>Banco de Preguntas</Typography>
          </div>
          <Typography variant="h1" style={{ margin: '12px 0 24px 0' }} color="primary">{`Indicador: ${criterium}`}</Typography>
          <div className={classes.questionsContainer}>
            {this.renderQuestions()}
          </div>
          <div className={classes.newQuestion}>
            <TextField label="Nueva pregunta:" onChange={this.handleTextChange} name="questionText" value={questionText} labelColor="#3E3E3E" />
          </div>
          <div className={classes.buttondiv} style={{ marginBottom: 24 }}>
            <Button color="primary" variant="text" onClick={onCreation({ result_criterium_id: criteriumId, question_text: questionText })}>
              LISTO
            </Button>
          </div>
          <div className={classes.buttondiv}>
            {dynamicCriterium && (
              <Button color="primary" variant="contained" onClick={onSubmit}>
                Agregar Preguntas
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    )
  }
}

QuestionDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func,
  selected: propTypes.arrayOf(propTypes.string),
  handleSelect: propTypes.func,
  handleSelectSection: propTypes.func,
  sections: propTypes.object,
  onSubmit: propTypes.func,
  onCreation: propTypes.func.isRequired,
  criterium: propTypes.string,
  contenders: propTypes.object.isRequired
}

QuestionDialog.defaultProps = {
  open: false,
  onClose: undefined,
  selected: [],
  sections: {},
  handleSelect: undefined,
  handleSelectSection: undefined,
  onSubmit: undefined,
  criterium: 'Puntual'
}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(QuestionDialog))
