import React, { Component } from 'react'
import { Button, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = theme => ({
  container: {
    display: 'flex',
    position: 'relative',
    gap: '6px',
    '&>*': {
      flexGrow: 1
    },
  },
  tab: {
    fontSize: 11,
    height: 32,
    padding: '6px 0',
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
    color: 'grey',
    '&:before': {
      transition: 'all 0.2s ease-in-out',
      content: '""',
      position: 'absolute',
      width: 0,
      height: 3,
      background: theme.palette.primary.main,
      bottom: -3,
      borderRadius: 6
    },
    '&.selected:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 3,
      background: theme.palette.primary.main,
      bottom: -3
    },
    '&.selected': {
      fontWeight: 600,
      color: 'black'
    }
  }

})
class FunctionalTabs extends Component {
  renderTabs() {
    const { tabs, classes, onSelect, selected } = this.props
    return tabs.map((tab, index) => (
      <Button
        key={tab}
        className={`${classes.tab} ${selected === index ? 'selected' : ''}`}
        onClick={onSelect(index)}
      >
        {tab}
      </Button>
    ))
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.renderTabs()}
      </div>
    );
  }
}

FunctionalTabs.propTypes = {
  classes: propTypes.object.isRequired,
  selected: propTypes.number,
  onSelect: propTypes.func.isRequired,
  tabs: propTypes.arrayOf(propTypes.string)
}

FunctionalTabs.defaultProps = {
  selected: 0,
  tabs: []
}
export default withStyles(style)(FunctionalTabs)
