/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { Button, Dialog, Divider, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { Delete, NoteAdd } from '@material-ui/icons'
import moment from 'moment'
import { connect } from 'react-redux'
import ButtonsWithIcon from 'Shared/Buttons/ButtonsWithIcon'
import autobind from 'Utils/autobind'
import SetViewContext from 'Components/Contenders/Profile/SetViewProvider'
import { createAuthorization } from 'API/Authorizations'
import CSDEvaluation from './CSDEvaluation'
import CSDResult from './CSDResult'
import ConditionalWrapper from '../../Shared/ConditionalWrapper'

const style = theme => ({
  formsGroup: {
    margin: '24px 0'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& > *:nth-child(2)': {
      flexGrow: 1,
      opacity: 0.7,
      marginLeft: 12
    },
    marginBottom: 12
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)',
      cursor: 'pointer',
    },
    padding: 12
  },
  bluryTable: {
    background: theme.palette.grey.light,
    borderRadius: 8,
    boxShadow: '0px 1px 8px #00000026',
    marginBottom: 32
  },
  divider: {
    margin: '0 12px',
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    }
  },
  leftAlignedText: {
    textAlign: 'left'
  },
  rightAlignedText: {
    textAlign: 'right',
    '& > *': {
      marginLeft: 12
    }
  }
})

class Forms extends Component {
  constructor() {
    super()
    this.state = {
      openDelete: false,
      selectedEvaluation: null
    }
    autobind(Forms, this)
  }

  handleOpenDelete(evaluation) {
    return e => {
      e.stopPropagation()
      const { openDelete } = this.state
      this.setState({ openDelete: !openDelete, selectedEvaluation: evaluation })
    }
  }

  async handleConfirmDelete() {
    const { reload, contender } = this.props
    const { selectedEvaluation } = this.state
    const extraInfo = {
      evaluation_id: selectedEvaluation.id
    }
    const body = { type_id: 6,
      text: `Borrar CSD de ${contender.user.name}`,
      extra_info: extraInfo,
      contender_id: contender.contender.id }
    await createAuthorization(body, 'eliminar CSD')
    reload()
    this.setState({ openDelete: false })
  }

  handleClickPending(evaluation) {
    return () => {
      const { getEvaluationForm, event, changeStatus } = this.props
      const { setView } = this.context
      const body = {
        form_id: event.form_id,
        evaluation_id: evaluation.id
      }
      getEvaluationForm(body).then(response => {
        if (response.payload.data.status !== 'success') return null
        const data = response.payload.data.info
        setView(<CSDEvaluation data={data} event={event} evaluation={evaluation.id} changeStatus={changeStatus} />, 'Formulario de Evaluación')
        return true
      })
    }
  }

  handleClickCheck(evaluation) {
    return () => {
      const { getEvaluationForm, event, changeStatus } = this.props
      const { setView } = this.context
      const body = {
        form_id: event.form_id,
        evaluation_id: evaluation.id
      }
      getEvaluationForm(body).then(response => {
        if (response.payload.data.status !== 'success') return null
        const data = response.payload.data.info
        setView(<CSDResult data={data} event={event} evaluation={evaluation.id} changeStatus={changeStatus} />, 'Resultados')
        return true
      })
    }
  }

  handleClickNew() {
    const { newEvaluation, event, contenders } = this.props
    const body = {
      form_id: event.form_id,
      user_id: contenders?.selected?.user?.id
    }
    newEvaluation(body)
  }

  render() {
    const { openDelete } = this.state
    const { classes, evaluations } = this.props
    const tooltipWrapper = e => (
      <Tooltip title="La solicitud para eliminar está evaluación se está revisando">
        {e}
      </Tooltip>
    )
    const pendingEv = evaluations.filter(ev => ev.status === 'pending').map((evaluation, index) => {
      const isDiscarded = evaluation.discarded_at
      const clickHandler = isDiscarded ? undefined : e => {
        e.stopPropagation()
        this.handleClickPending(evaluation)()
      }
      return (
        <ConditionalWrapper
          wrapper={tooltipWrapper}
          condition={evaluation.to_be_deleted}
        >
          <div key={evaluation.id} style={{ position: 'relative' }}>
            {index > 0 && <Divider className={classes.divider} />}
            <div
              className={classes.row}
              onClick={clickHandler}
            >
              <Typography variant="subtitle1">{`CSD - ${evaluation.name}`}</Typography>
              <Typography variant="subtitle1">Responsable: Pendiente</Typography>
              <Typography variant="subtitle2">{moment(evaluation.created_at).format('DD [de] MMM [del] YYYY')}</Typography>
              <div />
            </div>
            <div style={{ position: 'absolute', right: 12, top: '50%', transform: 'translate(0, -50%)' }}>
              <IconButton disabled={evaluation.to_be_deleted} size="small" onClick={this.handleOpenDelete(evaluation)}>
                <Delete />
              </IconButton>
            </div>
          </div>
        </ConditionalWrapper>
      )
    })

    const historicEv = evaluations.filter(ev => ev.status === 'check').map((evaluation, index) => (
      <React.Fragment key={evaluation.id}>
        {index > 0 && <Divider className={classes.divider} />}
        <ConditionalWrapper
          wrapper={tooltipWrapper}
          condition={evaluation.to_be_deleted}
        >
          <div className={classes.row} onClick={this.handleClickCheck(evaluation)}>
            <Typography variant="subtitle1">{`CSD - ${evaluation.name}`}</Typography>
            <Typography variant="subtitle1">
              {`Responsable: ${evaluation.allocated_by}`}
            </Typography>
            <Typography variant="subtitle2">{moment(evaluation.created_at).format('DD [de] MMM [del] YYYY')}</Typography>
            <IconButton disabled={evaluation.to_be_deleted} size="small" onClick={this.handleOpenDelete(evaluation)}>
              <Delete />
            </IconButton>
          </div>
        </ConditionalWrapper>
      </React.Fragment>
    ))

    return (
      <div className={classes.container}>
        <ButtonsWithIcon Icon={NoteAdd} onClick={this.handleClickNew}>
          Nueva Evaluación
        </ButtonsWithIcon>
        <div className={classes.formsGroup}>
          <div className={classes.title}>
            <Typography variant="subtitle1">En proceso</Typography>
            <Divider />
          </div>
          <div className={classes.bluryTable}>
            {pendingEv}
          </div>

        </div>
        <div className={classes.formsGroup}>
          <div className={classes.title}>
            <Typography variant="subtitle1">Historial</Typography>
            <Divider />
          </div>
          <div className={classes.bluryTable}>
            {historicEv}
          </div>
        </div>
        <Dialog open={openDelete} onClose={this.handleOpenDelete()} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="h2">
              ¿Seguro que desea eliminar esta evaluación?
            </Typography>
            <div>
              <Typography>
                Al confirmar esta acción se creará una autorización para eliminar la evaluación
              </Typography>
            </div>
            <div className={classes.rightAlignedText}>
              <Button variant="outlined" onClick={this.handleOpenDelete()}>
                Cancelar
              </Button>
              <Button color="secondary" variant="outlined" onClick={this.handleConfirmDelete}>
                Eliminar
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

Forms.contextType = SetViewContext

Forms.propTypes = {
  classes: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  evaluations: propTypes.arrayOf(propTypes.object),
  getEvaluationForm: propTypes.func.isRequired,
  // getEvaluationResult: propTypes.func.isRequired,
  newEvaluation: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  changeStatus: propTypes.func.isRequired,
  reload: propTypes.func.isRequired,
}

Forms.defaultProps = {
  evaluations: []
}

const mapStateToProps = state => ({
  contender: state.contenders.selected
})

export default connect(mapStateToProps, null)(withStyles(style)(Forms))
