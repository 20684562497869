import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import ReportTitles from '../ReportTitles'
import InterviewAnswerElement from './InterviewAnswerElement'

const style = () => ({
  container: {
    marginBottom: 48
  }
})

class Familiar extends Component {
  renderAnswers() {
    const { contenders } = this.props
    const results = contenders.profileResults
    const { familiar } = results
    if (!familiar.body) return null
    const form = familiar.body['Entrevista Familiar']
    const filteredForm = Object.entries(form).filter(([key,]) => !key.includes('descendidos')).map(([, value]) => value)
    const allQuestions = filteredForm.map(element => [...element.empty]).reduce((x, y) => x.concat(y), []).filter(question => question.type === 'slider')
    const UID = contenders?.selected?.user?.id
    return allQuestions.map(question => {
      const answer = question.answers.find(a => a.user_id === UID)
      if (!answer) return null
      const value = Array.isArray(answer.body) ? answer.body : [{ companion_name: 'Facilitador/a', answer: answer?.body?.answer || 0 }]
      return (
        <InterviewAnswerElement
          value={value}
          name={question.text}
          title={question.text}
          key={question.text}
          user={UID}
        />
      )
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <ReportTitles title="Entrevista Familiar" />
        {this.renderAnswers()}
      </div>
    );
  }
}

Familiar.propTypes = {
  classes: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
}

Familiar.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(Familiar))
