import React, { Component } from 'react'
import { Divider, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import moment from 'moment'
import DownloadableSection from 'Shared/DownloadableSection'
import ResultCharts from './ResultComponents/ResultCharts'
import ResultTable from './ResultComponents/ResultTable'
import { getFormEvaluation } from '../../API/Recurrent'
import autobind from '../../Utils/autobind'
import { getChartProcessedInfo, getTableProcessedData } from './ResultComponents/utils'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import GlobalComment from '../../Shared/GlobalComment'

const style = () => ({
  container: {
    margin: 12,
    padding: 12,
    textAlign: 'left'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  bold: {
    fontWeight: 600
  },
  titleLine: {
    display: 'grid',
    maxWidth: 500,
    minWidth: 500,
    gridTemplateColumns: '1fr 1fr',
    margin: '8px 0'
  },
  section: {
    marginBottom: 24
  }
})

function processResults(results, type) {
  const filteredResults = results.filter(result => {
    const questionOfPosition = result[`Evaluación de Desempeño Laboral${type}`].empty.empty[2]
    if (questionOfPosition.answers.length === 0) return false
    return true
  })
  return filteredResults
}

class LaboralPerformanceSingleResult extends Component {
  constructor() {
    super()
    this.state = {
      results: [],
      loading: true
    }
    autobind(LaboralPerformanceSingleResult, this)
  }

  async componentDidMount() {
    const { group, type } = this.props
    const { evaluations = [] } = group
    if (evaluations.length === 0) return null
    // getAnswersForEachEvaluation
    const evaluationsResults = []
    const evaluationPromises = evaluations.map(evaluation => {
      const evaluationBody = {
        form_id: group.form_group_id,
        evaluation_id: evaluation.id
      }
      return getFormEvaluation(evaluationBody)
    })
    await Promise.all(evaluationPromises).then(responses => {
      responses.forEach(response => {
        if (response.data.status !== 'success') return null
        return evaluationsResults.push(response.data.info)
      })
    })
    return this.setState({ loading: false, results: processResults(evaluationsResults, type) })
  }

  renderPositions() {
    const { classes, type } = this.props
    const { results } = this.state
    return results.map(result => {
      const name = result?.[`Evaluación de Desempeño Laboral${type}`]?.empty?.empty?.[0]?.answers?.[0]?.body?.answer || 'No ingresado...'
      const positionOptions = result?.[`Evaluación de Desempeño Laboral${type}`]?.empty?.empty?.[type === '' ? 2 : 1]?.options || []
      const position = positionOptions[result?.[`Evaluación de Desempeño Laboral${type}`]?.empty?.empty?.[type === '' ? 2 : 1]?.answers?.[0]?.body?.answer || 0]
      return (
        <div className={classes.header}>
          <div className={classes.titleLine}>
            <Typography variant="h2"><span className={classes.bold}>Cargo:</span></Typography>
            <Typography variant="h2">{`${position}: ${name}`}</Typography>
          </div>
        </div>
      )
    })
  }

  render() {
    const { classes, group, event, type, name, contender } = this.props;
    const { results, loading } = this.state
    const unity = results[0]?.[`Evaluación de Desempeño Laboral${type}`]?.empty?.empty?.[3]?.answers?.[0]?.body?.answer || 'No ingresado...'
    const chartData = getChartProcessedInfo(results, type)
    const tableData = getTableProcessedData(results, type)
    return (
      <Paper className={classes.container}>
        <div>
          <div>
            <div className={classes.titleLine}>
              <Typography variant="h2"><span className={classes.bold}>Rotación:</span></Typography>
              <Typography variant="h2">N°1</Typography>
            </div>
          </div>
          <div className={classes.header}>
            <div className={classes.titleLine}>
              <Typography variant="h2"><span className={classes.bold}>Unidad:</span></Typography>
              <Typography variant="h2">{unity}</Typography>
            </div>
            <div className={classes.titleLine}>
              <Typography variant="h2"><span className={classes.bold}>Fecha:</span></Typography>
              <Typography variant="h2">{moment(group.updated_at).format('DD/MM/YYYY')}</Typography>
            </div>
          </div>
          {this.renderPositions()}
        </div>
        <Divider />
        <div className={classes.section}>
          <GlobalComment evaluation={group?.evaluations?.[0].id} event={event} />
        </div>
        {!loading
          ? (
            <>
              <DownloadableSection id="porcentaje-aspecto" name={`Desempeño laboral ${contender?.name} - Ev_${name}`}>
                <div className={classes.section}>
                  <ResultCharts data={chartData} />
                </div>
              </DownloadableSection>
              {Object.entries(tableData).map(([section, values]) => (
                <ResultTable title={section} data={values} />
              ))}
            </>
          )
          : <LoaderAnimator loading={loading} />}
      </Paper>
    );
  }
}

LaboralPerformanceSingleResult.propTypes = {
  classes: propTypes.object.isRequired,
  group: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  type: propTypes.string,
  name: propTypes.string,
  contender: propTypes.object
}

LaboralPerformanceSingleResult.defaultProps = {
  type: '',
  name: '0',
  contender: {}
}

export default withStyles(style)(LaboralPerformanceSingleResult)
