import React from 'react'
import { withStyles, Dialog, Typography, Button } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import propTypes from 'prop-types'

const styles = theme => ({
  container: {
    padding: 24,
    textAlign: 'center'
  },
  bigIcon: {
    height: 60,
    width: 60,
    color: theme.palette.grey.main,
    marginBottom: 24
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      flexBasis: '50%'
    },
    marginTop: 24
  }
})

class DeleteDialog extends React.Component {
  render() {
    const { classes, open, onClose, onConfirm } = this.props
    return (
      <Dialog open={open} onClose={onClose}>
        <div className={classes.container}>
          <Delete className={classes.bigIcon} />
          <Typography variant="h1">¿Estás seguro de eliminar el usuario?</Typography>
          <div className={classes.buttons}>
            <Button onClick={onConfirm} color="secondary" variant="contained">Confirmar</Button>
            <Button onClick={onClose}>Cancelar</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

DeleteDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired
}

export default withStyles(styles)(DeleteDialog)
