import stateMaker from '../Utils/reducers'

const communeReducer = (state = { all: [] }, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'GET_COMMUNES':
      return stateMaker(newState, 'all', 'fetch', action)
    default:
      return state
  }
}

export default communeReducer
