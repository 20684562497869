import axios from 'axios'
import instance from '../Utils/client'
import createSnackbar, { callSnackbar } from '../Utils/snackbars'

const loginAction = async body => {
  let REQUEST
  try {
    REQUEST = await axios.post(`${process.env.REACT_APP_API_URL}/auth/sign_in`, body)
  } catch {
    callSnackbar('Credenciales inválidas', 'error')
    console.log('ERROR')
    return {
      type: 'FAIL'
    }
  }
  callSnackbar('Inicio de sesión exitoso', 'success')
  return {
    type: 'LOGIN',
    payload: REQUEST,
  }
}

const logoutAction = () => ({
  type: 'LOGOUT',
})

const registerAction = (body, snackbar, callback) => {
  const REQUEST = instance({
    method: 'post',
    url: 'handlers/register',
    data: body
  }).then(response => {
    if (callback) callback()
    return response
  })
  createSnackbar(REQUEST, snackbar)
  return {
    type: 'REGISTER',
    payload: REQUEST,
  }
}

const updateCurrentUser = newUser => ({
  type: 'UPDATE_CURRENT_USER',
  payload: newUser
})

export { loginAction, logoutAction, registerAction, updateCurrentUser }
