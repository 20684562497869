import React, { Component } from 'react'
import { Button, Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { Archive, Close } from '@material-ui/icons'
import { MultilineTextField, TextField } from '../../../../Shared/Fields/Text'
import Select from '../../../../Shared/Fields/Select'
import autobind from '../../../../Utils/autobind'

const style = () => ({
  container: {
    padding: 18,
    position: 'relative'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 12
    },
    marginBottom: 12
  },
  inputs: {
    '& > *': {
      margin: '12px 0'
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: 12
    },
    marginTop: 24
  },
  floatingIcon: {
    position: 'absolute',
    top: 12,
    right: 12
  }
})

class FinalDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      type: undefined,
      observation: '',
    }
    autobind(FinalDialog, this)
  }

  handleChange(fieldName) {
    return event => {
      this.setState({ [fieldName]: event.target.value })
    }
  }

  handleSubmit() {
    const { onClose, onSubmit } = this.props
    const { text, type } = this.state
    const body = {
      type_id: parseInt(type, 10),
      text
    }
    onSubmit(body)
    onClose()
  }

  render() {
    const { classes, open, onClose } = this.props;
    const { text, type, observation } = this.state
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <div className={classes.title}>
            <Archive />
            <Typography variant="h1">Resultado Reclutamiento y Selección</Typography>
          </div>
          <div className={classes.inputs}>
            <TextField complete={{ placeholder: 'Sugerencia de proceso' }} value={text} onChange={this.handleChange('text')} />
            <Select complete={{ placeholder: 'Estado', options: ['Cambio de aspirante a postulante'] }} value={type} onChange={this.handleChange('type')} />
            <MultilineTextField complete={{ placeholder: 'Observaciones', rows: 3 }} value={observation} onChange={this.handleChange('observation')} />
          </div>
          <div className={classes.buttons}>
            <Button onClick={onClose}>Volver</Button>
            <Button color="primary" variant="contained" onClick={this.handleSubmit}>Terminar Proceso</Button>
          </div>
          <IconButton size="small" className={classes.floatingIcon} onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </Dialog>
    );
  }
}

FinalDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func,
  onSubmit: propTypes.func.isRequired
}

FinalDialog.defaultProps = {
  open: false,
  onClose: undefined
}

export default withStyles(style)(FinalDialog)
