import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Typography, Tooltip, withStyles } from '@material-ui/core'

const style = () => ({
  tag: {
    border: '2px solid',
    padding: '3px 6px',
    borderRadius: 24,
    textAlign: 'center'
  }
})

class StatusCellRender extends Component {
  render() {
    const { value, classes } = this.props
    const status = {
      dismissed: 'Rechazado',
      approved: 'Aprobado'
    }

    const color = {
      dismissed: '#DB6F30',
      approved: '#48A4B0'
    }

    return (
      <Tooltip title={value}>
        <Typography
          variant="subtitle1"
          className={classes.tag}
          style={{
            borderColor: color[value],
            color: color[value],
          }}
        >
          {status[value]}
        </Typography>
      </Tooltip>
    )
  }
}

StatusCellRender.propTypes = {
  classes: propTypes.object.isRequired,
  value: propTypes.string,
}

StatusCellRender.defaultProps = {
  value: '',
}

export default withStyles(style)(StatusCellRender)
