/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { withStyles, IconButton, Typography, withTheme, Button, Menu, Divider } from '@material-ui/core'
import propTypes from 'prop-types'
import { CheckCircle, Brightness1Outlined, PersonAdd, Edit } from '@material-ui/icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autobind from '../../Utils/autobind'
import { assignOwnerAction } from '../../Actions/Handler'

const style = ({
  container: {
    padding: '0 0 0 12px',
    background: 'theme.palette.grey.light',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
    boxShadow: '0px 1px 5px #00000029',
    transition: 'all 0.2s ease-in-out',
  },
  able: {
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.02)'
    }
  },
  btn: {
    padding: 6,
  },
  assignButton: {
    marginRight: 8,
    height: 18,
    width: 18
  },
  assignText: {
    color: '#1a1a1a'
  },
  barElement: {
    width: '30%',
  },
  barElementFinal: {
    width: '30%',
    textAlign: 'end'
  },
  dialogPaper: {
    maxWidth: 450,
    minWidth: 350,
    padding: 12
  },
  owner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  assignSubmitButton: {
    textAlign: 'end',
    marginTop: 24
  },
  miniDivider: {
    margin: '3px 0'
  },
  dialogTitle: {
    marginBottom: 24
  }
})

class Participant extends Component {
  constructor() {
    super()
    this.state = {
      openDialog: false,
      anchor: null,
      selectedOwner: ''
    }
    autobind(Participant, this)
  }

  componentDidMount() {
    const { participant } = this.props
    const element = document.getElementById(`bar-${participant.id}`)
    this.setState({ anchor: element })
  }

  handleOpenDialog(e) {
    e.preventDefault()
    this.setState({ openDialog: true })
  }

  handleCloseDialog() {
    this.setState({ openDialog: false })
  }

  handleSelectOwner(owner) {
    return () => {
      this.setState({ selectedOwner: owner })
    }
  }

  handleAssingHandler() {
    const { assignOwner, participant, match } = this.props
    const { selectedOwner } = this.state
    const body = {
      event_id: match.params.id,
      participant_id: participant.id,
      owner_user_id: selectedOwner
    }
    assignOwner(body)
    this.setState({ openDialog: false })
  }

  getOwnerName(ownerUserId) {
    const { handler } = this.props
    const allHandlers = handler.all || []
    const selectedHandler = allHandlers.find(h => h.user.id === ownerUserId)
    return selectedHandler?.user?.name || 'Cargando...'
  }

  renderOwners() {
    const { events, classes } = this.props
    const { selectedOwner } = this.state
    const { owners } = events.selected
    return owners.map(owner => {
      const assigned = selectedOwner === owner.owner_user_id
      return (
        <React.Fragment key={owner.owner_user_id}>
          <div className={classes.owner} onClick={this.handleSelectOwner(owner.owner_user_id)}>
            <Typography variant="subtitle2">{this.getOwnerName(owner.owner_user_id)}</Typography>
            <IconButton size="small" onClick={this.handleSelectOwner(owner.owner_user_id)}>
              {assigned ? <CheckCircle color="primary" checked={assigned} /> : <Brightness1Outlined />}
            </IconButton>
          </div>
          <Divider className={classes.miniDivider} />
        </React.Fragment>
      )
    })
  }

  render() {
    const { classes, participant, attend, attended, disabled, theme } = this.props
    const { openDialog, anchor } = this.state
    const assigned = participant.allocation
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div className={`${classes.container} ${disabled ? '' : classes.able}`}>
        <div className={classes.barElement}>
          <Typography
            color="primary"
            variant="subtitle1"
            style={{ color: disabled ? 'grey' : '', fontWeight: 400 }}
          >
            {participant.name}
          </Typography>
        </div>
        <div className={classes.barElement} id={`bar-${participant.id}`}>
          {!disabled
        && (
          <Button onClick={this.handleOpenDialog}>
            {assigned
              ? <Edit className={classes.assignButton} />
              : <PersonAdd className={classes.assignButton} />}
            <Typography
              variant="subtitle2"
              className={classes.assignText}
            >
              {assigned ? this.getOwnerName(participant.allocation) : 'Asignar Evaluador'}
            </Typography>
          </Button>
        )}
        </div>
        <div className={classes.barElementFinal}>
          <IconButton
            className={classes.btn}
            disabled={disabled}
            size="small"
            onClick={e => !disabled && attend(e, participant.id)}
          >
            {attended
              ? (
                <CheckCircle
                  style={{ color: theme.palette.confirm.main }}
                  checked={participant.attended}
                />
              )
              : <Brightness1Outlined color="primary" style={{ color: disabled ? 'lightgrey' : '' }} />}
          </IconButton>
        </div>
        <Menu
          open={openDialog}
          onClose={this.handleCloseDialog}
          variant="menu"
          anchorEl={anchor}
        >
          <div className={classes.dialogPaper}>
            <Typography variant="h2" className={classes.dialogTitle}>Evaluadores</Typography>
            <Divider className={classes.miniDivider} />
            {this.renderOwners()}
            <div className={classes.assignSubmitButton}>
              <Button color="primary" variant="contained" size="small" onClick={this.handleAssingHandler}>
                Asignar
              </Button>
            </div>
          </div>
        </Menu>
      </div>
    )
  }
}

Participant.propTypes = {
  classes: propTypes.object.isRequired,
  participant: propTypes.object.isRequired,
  attend: propTypes.func.isRequired,
  attended: propTypes.bool.isRequired,
  disabled: propTypes.bool,
  theme: propTypes.object.isRequired,
  events: propTypes.object.isRequired,
  handler: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  assignOwner: propTypes.func.isRequired
}

Participant.defaultProps = {
  disabled: false,
}

const mapStateToProps = state => ({
  events: state.events,
  handler: state.handler
})

const mapDispatchToProps = dispatch => ({
  assignOwner: body => dispatch(assignOwnerAction(body))
})

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(withTheme(withStyles(style)(Participant))))
