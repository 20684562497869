import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { loadContenderAction } from '../../../Actions/Contenders'
import InfoComponent from './Containers/Info'
import ReportContainer from './Containers/ReportContainer'
import Loader from '../../../Shared/Loader'
import Conditional from '../../../Shared/Conditional'
import autobind from '../../../Utils/autobind'
import ViewContainer from './Containers/ViewContainer'
import SetViewContext from './SetViewProvider'
import Popup from './Popup'
import { addBreadcrumbAction, selectBreadcrumbAction, setBreadcrumbsAction } from '../../../Actions/BreadCrumbs'

const style = () => ({
  main: {
    padding: 24,
    display: 'grid',
    gridTemplateColumns: '1.1fr 3fr',
    position: 'relative'
  },
})

class ContenderProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      View: undefined,
      selectedTab: 0,
      openPopup: false,
      popupTitle: 'Individual'
    }
    autobind(ContenderProfile, this)
  }

  componentDidMount() {
    const { setBreadcrumbs } = this.props
    setBreadcrumbs([])
  }

  handleTabChange(selectedTab) {
    return () => {
      this.setState({ selectedTab })
    }
  }

  handleShowPopup(title) {
    this.setState({ openPopup: true, popupTitle: title })
  }

  handleClosePopup() {
    this.setState({ openPopup: false })
  }

  handleSetView(component, name) {
    this.setState({ View: component })
    const { addBreadcrumb, setBreadcrumbs, selectBreadcrumb } = this.props
    if (name) {
      addBreadcrumb({
        name,
        action: () => {
          this.setState({ View: component })
          selectBreadcrumb(name)
        }
      })
    } else {
      setBreadcrumbs([])
    }
  }

  handleRemoveView(index) {
    return () => {
      const { setBreadcrumbs } = this.props
      this.setState({ View: undefined, selectedTab: index })
      setBreadcrumbs([])
    }
  }

  render() {
    const { classes, contender, loadContender, match } = this.props
    const { id } = match.params
    const { View, selectedTab, openPopup, popupTitle } = this.state
    return (
      <SetViewContext.Provider value={{
        setView: this.handleSetView,
        showPopup: this.handleShowPopup
      }}
      >
        <Loader action={loadContender} params={[id]}>
          <Conditional condition={View === undefined} hasElse>
            <div className={classes.main}>
              <>
                <InfoComponent contender={contender} />
                <ReportContainer
                  selectedTab={selectedTab}
                  handleTabChange={this.handleTabChange}
                />

              </>
            </div>
            <div>
              <ViewContainer returnToProfile={this.handleRemoveView} selectedTab={selectedTab}>
                {View}
              </ViewContainer>
            </div>
          </Conditional>
        </Loader>
        <Popup open={openPopup} handleClose={this.handleClosePopup} title={popupTitle} />
      </SetViewContext.Provider>
    )
  }
}

ContenderProfile.propTypes = {
  classes: propTypes.object.isRequired,
  loadContender: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  contender: propTypes.shape({ user: propTypes.object, contender: propTypes.object }).isRequired,
  addBreadcrumb: propTypes.func.isRequired,
  setBreadcrumbs: propTypes.func.isRequired,
  selectBreadcrumb: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  contender: state.contenders.selected,
})

const mapDispatchToProps = dispatch => ({
  loadContender: id => dispatch(loadContenderAction(id)),
  addBreadcrumb: breadcrumb => dispatch(addBreadcrumbAction(breadcrumb)),
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbsAction(breadcrumbs)),
  selectBreadcrumb: name => dispatch(selectBreadcrumbAction(name))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ContenderProfile))
