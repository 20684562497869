/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'
import { Typography, Checkbox, withStyles } from '@material-ui/core'
import MuiSlider from '@material-ui/core/Slider'
import Comment from './Comment'

const style = {
  notAnsweredCommon: {
    marginBottom: 6,
    textAlign: 'left'
  },
  notAnsweredBold: {
    fontWeight: 600,
    color: '#3E3E3E',
    fontSize: 14
  },
  notAnswered: {
    fontWeight: 400,
    color: '#707070',
    fontSize: 13
  },
  answered: {
    fontWeight: 900,
    color: 'black',
    fontSize: 14
  },
  answeredContainer: {
    width: '90%',
    margin: 'auto'
  },
  observationContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}

const exampleInfo = {
  text: 'Logra Comunicar una idea',
  steps: [
    { value: 4, label: 'primero', index: 0 },
    { value: 3, label: 'segunda', index: 1 },
    { value: 2, label: 'tercero', index: 2 },
    { value: 1, label: 'cuarto', index: 3 },
  ]
}

class SliderInput extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e, value) {
    const { onChange, complete } = this.props
    const event = { target: { value: this.getRealValue(value), name: complete.name } }
    onChange(event)
  }

  getRealValue(value) {
    const { steps, complete } = this.props
    const toMap = complete.steps || steps
    const step = toMap.find(s => (s.index + 1) === value)
    if (step) {
      return step.value
    }
    return '0'
  }

  setFomattedValue(value) {
    const { steps, complete } = this.props
    const toMap = complete.steps || steps
    const step = toMap.find(s => s.value === value)
    return step ? step.index + 1 : ''
  }

  parseSteps() {
    const { steps, complete } = this.props
    const toMap = complete.steps || steps
    return toMap.map(step => ({
      label: step.label,
      value: step.index + 1
    }))
  }

  render() {
    const { value,
      steps,
      onComment,
      comment, complete, disabled, highlighted, answered, hideTitle, commentHeight,
      disableNoObservation, classes
    } = this.props
    const formattedValue = this.setFomattedValue(parseInt(value, 10))
    return (
      <>
        <div style={{ gridArea: `area-${complete.id}` }} className={highlighted ? 'highlighted' : ''}>
          {(!answered && !hideTitle) && (
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: complete.text }}
              className={classNames(
                {
                  [classes.notAnswered]: !complete.bold,
                  [classes.notAnsweredBold]: complete.bold,
                  [classes.notAnsweredCommon]: true
                }
              )}
            />
          )}
          {answered && <Typography className={classes.answered} display="inline">{complete.text}</Typography>}
          <div className={classes.answeredContainer}>
            <MuiSlider
              valueLabelFormat={this.valueLabelFormat}
              step={null}
              valueLabelDisplay="off"
              marks={complete.steps ? this.parseSteps(complete.steps) : this.parseSteps(steps)}
              max={complete.steps ? complete.steps.length : steps.length}
              min={1}
              name={complete.name}
              onChange={this.handleChange}
              value={parseInt(formattedValue, 10) || 0}
              color={formattedValue ? 'primary' : 'secondary'}
              disabled={disabled}
            />
          </div>
          {!disableNoObservation && (
            <div className={classes.observationContainer}>
              <Checkbox checked={['0', 0].includes(value)} onChange={e => { this.handleChange(e, e.target.value) }} value={0} color="primary" disabled={disabled} />
              <Typography variant="subtitle1">No se observa</Typography>
            </div>
          )}
        </div>
        {complete.has_comment
          && (
            <Comment onChange={onComment} value={comment} name={complete.name} area={`comment-${complete.id}`} disabled={disabled} height={commentHeight} />
          )}
      </>
    )
  }
}

SliderInput.propTypes = {
  value: propTypes.oneOfType([propTypes.number, propTypes.string]),
  steps: propTypes.arrayOf(propTypes.object),
  onChange: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  onComment: propTypes.func,
  comment: propTypes.string,
  complete: propTypes.object.isRequired,
  disabled: propTypes.bool,
  highlighted: propTypes.bool,
  hideTitle: propTypes.bool,
  answered: propTypes.bool,
  commentHeight: propTypes.number,
  disableNoObservation: propTypes.bool
}

SliderInput.defaultProps = {
  value: 0,
  steps: exampleInfo.steps,
  comment: '',
  onComment: undefined,
  disabled: false,
  highlighted: false,
  answered: false,
  hideTitle: false,
  commentHeight: 1,
  disableNoObservation: false
}

export default withStyles(style)(SliderInput)
