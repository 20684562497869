import { withStyles, Typography, Paper, IconButton } from '@material-ui/core'
import { HighlightOffOutlined } from '@material-ui/icons'
import React from 'react'
import propTypes from 'prop-types'
import ActionCable from 'actioncable'
import { connect } from 'react-redux'
import autobind from './Utils/autobind'

const style = () => ({
  container: {
    position: 'fixed',
    bottom: 12,
    right: 12,
    minWidth: 300,
    maxWidth: 600,
    height: 80,
    background: '#4A86E8',
    zIndex: 10,
    borderRadius: 8,
    padding: 12,
    textAlign: 'start',
    transition: 'all 0.2s ease-in-out',
    '& > *': {
      color: 'white',
      '& > *': {
        color: 'white',
      }
    }

  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closed: {
    transform: 'translate(120%, 0)'
  }
})

class NotificationsController extends React.Component {
  constructor() {
    super()
    this.state = {
      data: {
        title: 'Notificación de prueba',
        body: 'Notificaciónd de prueba'
      },
      open: false
    }
    autobind(NotificationsController, this)
    this.event = new Event('notificationArrive')
  }

  componentDidMount() {
    const { user } = this.props
    this.cable = ActionCable.createConsumer(`${process.env.REACT_APP_API_WS}/cable`)
    this.channel = this.cable.subscriptions.create(
      {
        channel: 'NotificationsChannel', user: user.current.id
      },
      {
        connected: () => {
          console.log('connected!')
        },
        disconnected: () => {
          console.log('disconnected!')
        },
        received: data => {
          this.handleReceive(data)
        },
      }
    )
  }

  handleClose() {
    this.setState({ open: false })
  }

  handleReceive(data) {
    this.setState({ data, open: true })
    window.dispatchEvent(this.event)
    setTimeout(this.handleClose, 5000)
  }

  render() {
    const { classes } = this.props
    const { data, open } = this.state
    return (
      <Paper className={`${classes.container} ${open ? '' : classes.closed}`} elevation={5}>
        <div className={classes.header}>
          <Typography variant="h1">
            {data?.title}
          </Typography>
          <IconButton size="small" onClick={this.handleClose}>
            <HighlightOffOutlined />
          </IconButton>
        </div>
        <Typography variant="subtitle1">
          {data?.body}
        </Typography>
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

NotificationsController.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
}

NotificationsController.defaultProps = {
}

export default connect(mapStateToProps)(withStyles(style)(NotificationsController))
