/* eslint-disable import/no-cycle */
import React from 'react'
import { Typography, Divider } from '@material-ui/core'
import MuiTextfield from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import Comment from './Comment'
import { renderSubquestion } from '../../Utils/FieldRenderer'

const TextField = props => {
  const {
    onChange,
    value, comment,
    complete,
    onComment,
    disabled,
    highlighted,
    answered,
    subquestionsAnswers,
    onSubchange,
    onBlur,
    autofocus
  } = props
  const hasSubquestions = complete.subquestions !== undefined

  return (
    <>
      {!answered && (
        <div style={{ gridArea: `area-${complete.id}` }} className={highlighted ? 'highlighted' : ''}>
          <Typography style={{ fontWeight: complete.bold ? 600 : 400, color: complete.bold ? '#3E3E3E' : '#707070' }}>{complete.text}</Typography>
          <MuiTextfield
            onChange={onChange}
            value={value}
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0, padding: 0 } }}
            variant="filled"
            name={complete.name}
            placeholder={complete.placeholder || ''}
            disabled={disabled}
            onBlur={onBlur}
            autoFocus={autofocus}
            multiline={complete.multiline ?? true}
            type={complete.type === 'password' ? 'password' : 'text'}
          />
          {hasSubquestions
           && renderSubquestion(complete, onSubchange, subquestionsAnswers, disabled, answered)}
        </div>
      )}
      {answered
      && (
        <>
          <Typography style={{ fontWeight: complete.bold ? 600 : 400, color: complete.bold ? '#3E3E3E' : '#707070' }}>{complete.text}</Typography>
          <Typography style={{ fontWeight: 900, margin: '6px 0 0 6px', fontSize: 14, color: 'black' }}>{value}</Typography>
          <Divider style={{ background: '#8A8A8A', height: 3, margin: 0 }} />
        </>
      )}
      {complete.has_comment
        && (
          <Comment
            onChange={onComment}
            value={comment}
            name={complete.name}
            area={`comment-${complete.id}`}
            commentLabel={complete.comment_label}
            disabled={disabled}
          />
        )}
    </>
  )
}

const MultilineTextField = props => {
  const { onChange,
    value,
    comment,
    complete,
    onComment,
    disabled,
    highlighted,
    answered,
    onBlur } = props
  return (
    <>
      {!answered && (
        <div style={{ gridArea: `area-${complete.id}` }} className={highlighted ? 'highlighted' : ''}>
          <Typography style={{ fontWeight: complete.bold ? 600 : 400, color: complete.bold ? '#3E3E3E' : '#707070' }}>{complete.text}</Typography>
          <MuiTextfield
            onChange={onChange}
            value={value}
            fullWidth
            multiline
            rows={complete.rows}
            variant="filled"
            InputProps={{ style: { borderRadius: 0, padding: '6px 0 7px 6px' } }}
            name={complete.name}
            placeholder={complete.placeholder}
            disabled={disabled}
            onBlur={onBlur}
          />
        </div>
      )}
      {answered
      && (
        <>
          <Typography style={{ fontWeight: complete.bold ? 600 : 400, color: complete.bold ? '#3E3E3E' : '#707070' }}>{complete.text}</Typography>
          <Typography style={{ fontWeight: 900, margin: '6px 0 0 6px', fontSize: 14, color: 'black' }}>{value}</Typography>
          <Divider style={{ background: '#8A8A8A', height: 3, margin: 0 }} />
        </>
      )}
      {complete.has_comment
        && (
          <Comment
            onChange={onComment}
            value={comment}
            name={complete.name}
            area={`comment-${complete.id}`}
            commentLabel={complete.comment_label}
            disabled={disabled}
          />
        )}
    </>
  )
}

TextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onComment: PropTypes.func,
  value: PropTypes.string,
  comment: PropTypes.string,
  complete: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  autofocus: PropTypes.bool,
  answered: PropTypes.bool,
  subquestionsAnswers: PropTypes.object,
  onSubchange: PropTypes.func,
  onBlur: PropTypes.func,
}

MultilineTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onComment: PropTypes.func,
  value: PropTypes.string,
  comment: PropTypes.string,
  complete: PropTypes.object,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  answered: PropTypes.bool,
  onBlur: PropTypes.func,
}

MultilineTextField.defaultProps = {
  value: '',
  comment: '',
  onComment: undefined,
  disabled: false,
  highlighted: false,
  answered: false,
  complete: {},
  onBlur: undefined,
}

TextField.defaultProps = {
  value: '',
  comment: '',
  onComment: undefined,
  disabled: false,
  highlighted: false,
  answered: false,
  subquestionsAnswers: {},
  onSubchange: undefined,
  onBlur: undefined,
  autofocus: false
}

export { TextField, MultilineTextField }
