import React from 'react'
import { Dialog, DialogActions, DialogContent, Button, TextField, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import ClockIcon from '@material-ui/icons/AccessTime'
import PeopleIcon from '@material-ui/icons/People'
import RoomIcon from '@material-ui/icons/Room'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import { ExitToApp } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { DatePicker, TextDblClick, SelectWithCheckbox, TimePicker } from '../../../Shared/form/Fields'
import autobind from '../../../Utils/autobind'
import WarningDialog from '../../../Shared/WarningDialog'
import { selectEventAction } from '../../../Actions/Events'

const eventTypeMap = {
  'Jornada Presentación': 0,
  Seguimiento: 2,
  Reunión: 4,
  'Evaluación Funcional': 7,
  'ECV Familiar': 8,
  Otro: 6,
}

const initialState = {
  date: moment(),
  comment: '',
  participants: [],
  title: '',
  owners: [],
  type: 0,
  from: moment(),
  to: moment(),
  eventLoaded: false,
  warning: false,
}

const styles = () => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8rem',
  },
  tabText: {
    fontWeight: 'bold',
    fontSize: '0.6rem',
  },
  tabs: {
    margin: '12px 0px',
    display: 'grid',
    gap: '12px',
    justifyContent: 'space-between',
    gridTemplateColumns: 'auto auto auto auto auto auto auto'
  },
  content: {
    padding: '8px 12px',
  },
  button: {
    // width: '100%',
    padding: '6px 4px',
  },
  closeRow: {
    justifyContent: 'flex-end',
    padding: '6px 6px 0 0',
  },
  close: {
    color: 'gray',
    cursor: 'pointer',
  },
  buttonSelected: {
    backgroundColor: '#F6B343',
    color: 'white',
    padding: '6px 4px',
    '&:hover': {
      backgroundColor: '#F2A11A',
    },
  },
  redirectButton: {
    width: 150,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 6px',
    fontWeight: 600,
    margin: '12px 0 24px 0'
  },
  redirectIcon: {
    height: 18,
    width: 18
  }
})

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
    autobind(Form, this)
  }

  static getDerivedStateFromProps(props, oldState) {
    const { clickedEvent, selectedDate, open } = props
    if (clickedEvent && !oldState.eventLoaded) {
      const { full } = clickedEvent
      const { event } = full
      const eventDate = moment(event.date)
      const dayStart = eventDate.startOf('day')
      const [hours1, minutes1] = event.module ? event.module.split(':') : [0, 0]
      const [hours, minutes] = event.end_module ? event.end_module.split(':') : [0, 0]
      return {
        date: eventDate,
        comment: event.comment,
        participants: full.participants.map(p => p.id),
        from: dayStart.clone().add({ hours: hours1, minutes: minutes1 }),
        to: dayStart.clone().add({ hours, minutes }),
        title: event.title,
        owners: full.owners.map(o => o.owner_user_id),
        type: eventTypeMap[full.event_type_name],
        eventLoaded: event.id,
        links: full.links
      }
    }
    if (selectedDate !== oldState.date && !open) {
      return {
        date: selectedDate
      }
    }
    return null
  }

  handleTextChange(field) {
    return e => {
      this.setState({
        [field]: e.target.value,
      })
    }
  }

  handleDateChange(e) {
    this.setState({
      date: e,
    })
  }

  handleTimeChange(key) {
    return e => {
      this.setState({
        [key]: e,
      })
    }
  }

  handlePeopleAdd(e) {
    this.setState({
      participants: e.target.value,
    })
  }

  handleHandlersAdd(e) {
    this.setState({
      owners: e.target.value,
    })
  }

  handleCloseWrapper() {
    const { handleClose } = this.props
    this.handleResetFields()
    handleClose()
  }

  handleSubmitWrapper() {
    const { submit } = this.props
    const {
      date,
      comment,
      participants,
      owners,
      type,
      title,
      from,
      to,
      eventLoaded } = this.state
    const eventObject = { date,
      comment,
      module: from.format('HH:mm'),
      end_module: to.format('HH:mm'),
      participants,
      title,
      owners,
      type,
      eventLoaded }
    submit(eventObject)
    this.handleCloseWrapper()
  }

  handleTitleChange(e) {
    this.setState({
      title: e.target.value,
    })
  }

  handleGotoEvent() {
    const { links, eventLoaded } = this.state
    const { history, selectEvent } = this.props
    const url = links[0]?.link
    selectEvent(eventLoaded)
    history.push(url)
  }

  handleTypeChange(type) {
    return () => {
      this.setState({
        type,
      })
    }
  }

  handleResetFields() {
    this.setState(initialState)
  }

  handleWarning(warning) {
    return () => {
      this.setState({ warning })
    }
  }

  handleDelete(eventId) {
    const { handleClose, handleDelete } = this.props
    return () => {
      this.handleWarning(false)()
      this.handleResetFields()
      handleClose()
      handleDelete(eventId)
    }
  }

  render() {
    const { classes, open, contenders, handlers, currentUser } = this.props
    const { date,
      comment,
      participants,
      title,
      type,
      owners,
      eventLoaded,
      warning,
      from,
      to,
    } = this.state
    const possibleParticipants = contenders.sort((a, b) => {
      if (a.user.name.toLowerCase() > b.user.name.toLowerCase()) return 1
      if (a.user.name.toLowerCase() < b.user.name.toLowerCase()) return -1
      return 0
    })
    let selectableFilter
    if (type === 0) {
      selectableFilter = c => c.can_answer_gathering || participants.includes(c.id)
    }
    if (type === 7) {
      selectableFilter = c => c.can_answer_functional || participants.includes(c.id)
    }
    const getButtonClass = idx => {
      if (type === idx) {
        return classes.buttonSelected
      }
      return classes.button
    }

    const { links } = this.state
    const hasRecord = eventLoaded && links[0].link.includes('calendar')

    return (
      <Dialog
        open={open}
        maxWidth="md"
        onClose={this.handleCloseWrapper}
        PaperProps={{ square: true }}
      >
        <Grid container className={classes.closeRow}>
          <CloseIcon size="small" className={classes.close} onClick={this.handleCloseWrapper} />
        </Grid>
        <DialogContent className={classes.content}>
          <Grid container>
            <Grid item sm={12}>
              <TextField
                placeholder="Añade un título"
                fullWidth
                value={title}
                margin="dense"
                onChange={this.handleTitleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <div className={classes.tabs}>
            {Object.entries(eventTypeMap).map(([key, value]) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  className={getButtonClass(value)}
                  classes={{ text: classes.tabText }}
                  onClick={this.handleTypeChange(value)}
                >
                  {key}
                </Button>
              </div>
            ))}
          </div>
          {eventLoaded && !hasRecord
          && (
            <Button size="small" className={classes.redirectButton} color="primary" variant="contained" onClick={this.handleGotoEvent}>
              Ir a evento
              <ExitToApp className={classes.redirectIcon} />
            </Button>
          )}
          <Grid container>
            <Grid item sm={1}>
              <CalendarIcon />
            </Grid>
            <Grid item sm={11} className={classes.row}>
              <DatePicker value={date} onChange={this.handleDateChange} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={1}>
              <ClockIcon />
            </Grid>
            <Grid item sm={11} className={classes.row} style={{ display: 'grid', gridTemplateColumns: '18% 5% 18%', alignItems: 'center' }}>
              <div>
                <TimePicker value={from} onChange={this.handleTimeChange('from')} format="[Desde] HH:mm" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                -
              </div>
              <div>
                <TimePicker value={to} onChange={this.handleTimeChange('to')} format="[Hasta] HH:mm" />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={1}>
              <PeopleIcon />
            </Grid>
            <Grid item sm={11} className={classes.row}>
              <SelectWithCheckbox
                values={participants}
                handleChange={this.handlePeopleAdd}
                allValues={possibleParticipants.map(e => e.user)}
                selectableFilter={selectableFilter}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={1}>
              <PersonAddIcon />
            </Grid>
            <Grid item sm={11} className={classes.row}>
              <SelectWithCheckbox
                values={owners.filter(e => e !== currentUser.id)}
                handleChange={this.handleHandlersAdd}
                allValues={handlers.map(e => e.user).filter(e => e.email !== currentUser.email)}
                defaultText="Invitar a Facilitador"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={1}>
              <RoomIcon />
            </Grid>
            <Grid item sm={11} className={classes.row}>
              <TextDblClick value={comment} onChange={this.handleTextChange('comment')} />
            </Grid>
          </Grid>
        </DialogContent>
        { eventLoaded && (
          <WarningDialog
            open={warning}
            handleClose={this.handleWarning(false)}
            handleDelete={this.handleDelete(eventLoaded)}
          />
        )}
        <DialogActions>
          {eventLoaded ? (
            <Button onClick={this.handleWarning(true)}>
              Eliminar
            </Button>
          ) : (
            <Button onClick={this.handleCloseWrapper}>
              Cancelar
            </Button>
          )}
          <Button variant="contained" onClick={this.handleSubmitWrapper} disabled={!title}>
            Agendar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

Form.defaultProps = {
  clickedEvent: null,
  selectedDate: moment(new Date()).format('YYYY-MM-DD')
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  contenders: PropTypes.arrayOf(PropTypes.object).isRequired,
  handlers: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.object.isRequired,
  clickedEvent: PropTypes.object,
  selectedDate: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  selectEvent: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  selectEvent: eventId => dispatch(selectEventAction(eventId)),
})

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(Form)))
