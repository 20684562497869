import React from 'react'
import { withStyles, Paper, Typography, Button, Checkbox } from '@material-ui/core'
import { RadioButtonUnchecked, CheckCircle } from '@material-ui/icons'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { checkCheckupExternalAction } from '../../../Actions/Contenders'
import { DatePicker } from '../../../Shared/form/Fields'
import autobind from '../../../Utils/autobind'

const style = theme => ({
  container: {
    maxWidth: 650,
    margin: 'auto',
    padding: '12px 48px'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 0'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 12
  },
  bodyContainer: {
    width: '85%'
  },
  checkupHeader: {
    marginBottom: 6,
  },
  headerText: {
    color: theme.palette.confirm.main,
    fontWeight: 'bold'
  },
  checkupContainer: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr 1fr',
  },
  datepicker: {
    background: theme.palette.grey.light,
    borderBottom: `2px solid ${theme.palette.grey.dark}`,
    paddingLeft: 6
  },
  centeredContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  horizontallyCentered: {
    justifyContent: 'center'
  },
  checkedRadio: {
    color: theme.palette.primary.main
  },
  rightMargin: {
    marginRight: 6,
  },
  bodyMainContainer: {
    display: 'flex',
    justifyContent: 'center',
  }
})

class CheckupSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { checkupsInfo: {} }
    autobind(CheckupSection, this)
  }

  componentDidMount() {
    const { checkups = [] } = this.props
    const checkupsInfo = checkups.reduce((prev, actual) => {
      const [firstHistory] = actual.histories
      return {
        ...prev,
        [actual.id]: firstHistory?.date
      }
    }, {})
    this.setState({ checkupsInfo })
  }

  handleCheckbox(event) {
    const { checkupsInfo } = this.state
    const { target } = event
    checkupsInfo[target.name] = null
    this.setState({ checkupsInfo })
  }

  handleDateChange(checkupId) {
    return value => {
      this.setState(prevState => (
        {
          checkupsInfo: {
            ...prevState.checkupsInfo,
            [checkupId]: value.format('YYYY/MM/DD')
          }
        }
      ))
    }
  }

  handleBack() {
    const { handleNextStep } = this.props
    handleNextStep(0, { defaultKey: 2 })
  }

  handleEnd() {
    const { handleNextStep, checkCheckupExternal, userId } = this.props
    const { checkupsInfo } = this.state
    const checkups = Object.keys(checkupsInfo).reduce((prev, actual) => ({
      ...prev,
      [actual]: moment(checkupsInfo[actual]).format('DD/MM/YYYY')
    }), {})
    const requestBody = {
      user_id: userId,
      checkups
    }
    checkCheckupExternal(requestBody)
    handleNextStep(2)
  }

  render() {
    const { classes, message, checkups = [] } = this.props
    const { checkupsInfo } = this.state
    const checkupsBody = (
      <div className={classes.bodyContainer}>
        <div className={`${classes.checkupContainer} ${classes.checkupHeader}`}>
          <div />
          {['Tipo de control', 'Fecha de control'].map(text => (
            <Typography className={classes.headerText}>
              {text}
            </Typography>
          ))}
        </div>
        {checkups.map(checkup => (
          <div className={classes.checkupContainer} key={checkup.id}>
            <div className={`${classes.centeredContainer} ${classes.horizontallyCentered}`}>
              <Checkbox
                disabled
                onChange={this.handleCheckbox}
                name={checkup.id}
                checked={!!checkupsInfo[checkup.id]}
                icon={
                  <RadioButtonUnchecked />
                }
                checkedIcon={(
                  <CheckCircle
                    className={classes.checkedRadio}
                  />
                )}
              />
            </div>
            <div className={classes.centeredContainer}>
              <Typography>
                {checkup.text}
              </Typography>
            </div>
            <DatePicker
              disableToolbar={false}
              onChange={this.handleDateChange(checkup.id)}
              value={checkupsInfo[checkup.id]}
              inputClassName={classes.datepicker}
            />
          </div>
        ))}
      </div>
    )
    return (
      <Paper className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h1">Ficha de Salud</Typography>
          <Typography variant="subtitle2">Controles Preventidos de Salud</Typography>
        </div>
        <div>
          {message}
        </div>
        <div className={classes.bodyMainContainer}>
          {checkupsBody}
        </div>
        <div className={classes.buttonContainer}>
          <Button color="secondary" variant="outlined" onClick={this.handleBack} style={{ marginRight: 12 }}>Atrás</Button>
          <Button color="primary" variant="contained" onClick={this.handleEnd} style={{ marginRight: 12 }}>Terminar</Button>
        </div>
      </Paper>
    )
  }
}

CheckupSection.propTypes = {
  classes: propTypes.object.isRequired,
  handleNextStep: propTypes.func.isRequired,
  checkCheckupExternal: propTypes.func.isRequired,
  checkups: propTypes.arrayOf(propTypes.object).isRequired,
  message: propTypes.oneOfType([propTypes.node, propTypes.string]),
  userId: propTypes.string.isRequired
}

CheckupSection.defaultProps = {
  message: ''
}

const mapDispatchToProps = dispatch => ({
  checkCheckupExternal: body => dispatch(checkCheckupExternalAction(body))
})

export default connect(null, mapDispatchToProps)(withStyles(style)(CheckupSection))
