import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'
import { abreviateCriteria, calculatePercentiles } from './utils'

const style = () => ({
  container: {
    '& > h2': {
      fontWeight: 600,
      margin: '24px 0'
    },
    height: 400,
    paddingBottom: 32
  },
})

function getDatasets(questions, user) {
  const percentiles = calculatePercentiles(questions, user, true)
  const labels = Object.keys(percentiles).map(word => abreviateCriteria(word))
  const values = Object.values(percentiles)
  return [labels, values]
}

class PercentileChart extends Component {
  componentDidMount() {
    const { questions, user } = this.props
    const chartElement = document.getElementById('ambito')
    const myChartRef = chartElement.getContext('2d');
    chartElement.height = '400'
    const [labels, values] = getDatasets(questions, user)
    // eslint-disable-next-line no-new
    this.chart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels,
        datasets: [
          {
            data: values,
            barThickness: 32,
            backgroundColor: '#1F8095',
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            offset: true
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 0,
              max: 100,
              stepSize: 10,
              offset: true
            },
            gridLines: {
              display: true,
              drawBorder: false
            }
          }]
        },
        animation: {
          onComplete: () => {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.font = 'Montserrat, sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.chart.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const metadata = dataset.data[index];
                // eslint-disable-next-line no-underscore-dangle
                ctx.fillText(metadata, bar._model.x, bar._model.y - 5)
              });
            });
          }
        }
      },
      plugins: [
        {
          id: 'custom_canvas_background_color',
          beforeDraw: chart => {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        },
      ]
    })
  }

  componentDidUpdate(prevProps) {
    const { questions, user } = this.props
    if (prevProps.questions !== questions) {
      const [labels, values] = getDatasets(questions, user)
      this.chart.data.labels = labels
      this.chart.data.datasets = [
        {
          data: values,
          barThickness: 32,
          backgroundColor: '#1F8095',
        },
      ]
      this.chart.update()
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h2">Resultados de dimensiones por percentil</Typography>
        <canvas id="ambito" />
      </div>
    );
  }
}

PercentileChart.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  questions: propTypes.arrayOf(propTypes.object).isRequired
}

PercentileChart.defaultProps = {

}
export default withStyles(style)(PercentileChart)
