import React, { Component } from 'react'
import { withStyles, Paper, Typography, Radio, Button, Grid } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { createContenderAction } from '../../Actions/Contenders'
import MyTextField from './FormTextfield'
import LoaderDialog from '../../Shared/LoaderDialog'
import { callSnackbar } from '../../Utils/snackbars'

const style = theme => ({
  container: {
    padding: 12,
    minWidth: 400
  },
  button: {
    display: 'flex',
    marginTop: 6,
    justifyContent: 'flex-end',
  },
  greyDark: {
    color: theme.palette.grey.dark,
  },
})

class NewContender extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
      loading: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSend = this.handleSend.bind(this) // Set to endpoint and create new contender
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    const newParams = { ...params }
    newParams[target.name] = target.value
    this.setState({ params: newParams })
  }

  async handleSend() {
    const { params } = this.state
    const { enqueueSnackbar, newContender, createCallback } = this.props
    const body = { ...params }
    this.setState({ loading: true })
    const response = await newContender(body, enqueueSnackbar, createCallback)
    if (!response.payload) {
      callSnackbar('Correo ingresado no es válido', 'error')
      return this.setState({ loading: false })
    }
    return this.setState({ params: {}, loading: false })
  }

  render() {
    const { classes, fullWidth } = this.props
    const { params, loading } = this.state
    return (
      <Grid container style={{ padding: 12 }}>
        <Paper className={classes.container} style={fullWidth ? { width: '100%' } : {}}>
          <Typography
            style={{ textAlign: 'left', marginBottom: 12 }}
            variant="h2"
            className={classes.greyDark}
          >
            Nuevo Postulante
          </Typography>
          <MyTextField
            value={params.name || ''}
            onChange={this.handleChange}
            label="Nombre"
            name="name"
          />
          <MyTextField
            value={params.lastname || ''}
            onChange={this.handleChange}
            label="Apellido"
            name="lastname"
          />
          <MyTextField
            value={params.email || ''}
            onChange={this.handleChange}
            label="Correo"
            name="email"
          />
          <MyTextField
            value={params.rut || ''}
            onChange={this.handleChange}
            label="Rut"
            name="rut"
          />
          <MyTextField
            value={params.phone || ''}
            onChange={this.handleChange}
            label="Teléfono"
            name="phone"
            placeholder="+569"
          />
          <Typography variant="body2" className={classes.greyDark}>
            Medio de contacto
          </Typography>
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Radio
                value="0"
                checked={params.contact === '0'}
                name="contact"
                onChange={this.handleChange}
              />
              <Typography variant="body2" className={classes.greyDark}>
                Formulario
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Radio
                value="1"
                checked={params.contact === '1'}
                name="contact"
                onChange={this.handleChange}
              />
              <Typography variant="body2" className={classes.greyDark}>
                Llamada
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Radio
                value="2"
                checked={params.contact === '2'}
                name="contact"
                onChange={this.handleChange}
              />
              <Typography variant="body2" className={classes.greyDark}>
                Correo
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Radio
                value="3"
                checked={params.contact === '3'}
                name="contact"
                onChange={this.handleChange}
              />
              <Typography variant="body2" className={classes.greyDark}>
                Otro
              </Typography>
            </div>
          </div>
          <div className={classes.button}>
            <Button variant="contained" color="secondary" onClick={this.handleSend}>
              Guardar
            </Button>
          </div>
        </Paper>
        <LoaderDialog open={loading} />
      </Grid>
    )
  }
}

NewContender.defaultProps = {
  fullWidth: false,
  createCallback: () => { },
}

NewContender.propTypes = {
  classes: propTypes.object.isRequired,
  newContender: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  fullWidth: propTypes.bool,
  createCallback: propTypes.func,
}

const mapDispatchToProps = dispatch => ({
  newContender: (contender, snack, cb) => dispatch(createContenderAction(contender, snack, cb)),
})

export default withSnackbar(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(style)(NewContender))
)
