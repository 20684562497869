import React, { Component } from 'react'
import propTypes from 'prop-types'
import PhysicalResult from 'Components/PhysicalPotential/PhysicalResult'
import ResultAVD from '../../../Results/Individuals/AVD/ResultAVD'
import ResultPOI from '../../../Results/Individuals/POI/ResultPOI'
import HealthTab from '../../../Results/Individuals/HealthTab/HealthTab'
import GencatResult from '../../../Results/Individuals/Gencat/GencatResult'
import IndividualInterviewEdit from '../../../Results/Individuals/Interviews/IndividualInterviewEdit'
import IndividualInterview from '../../../Results/Individuals/Interviews/IndividualInterview'
import InterviewResult from '../../../Results/Individuals/Interviews/InterviewResult'
import FamiliarInterview from '../../../Results/Individuals/Interviews/FamiliarInterview'
import WorkProfile from '../../../FunctionalEvaluation/Results/WorkProfile'
import FunctionalContainer from '../../../Results/Individuals/Functional/FunctionalContainer'
import SupportLevel from '../../../FunctionalEvaluation/Results/SupportLevel'
import FunctionalDisabledForm from '../../../Results/Individuals/Functional/FunctionalDisabledForm'
import EvaluationView from '../../../CompetencyEvaluation/MainView/EvaluationView'
import CSDMain from '../../../CSD/CSDMain'
import LaboralPerformance from '../../../Desempeño/LaboralPerformance'
import Autoevaluation from '../../../Autoevaluation/Autoevaluation'
import EDPS from '../../../EDPS/EDPS'
import GencatResultsContainer from '../../../GencatFamiliar/Results/GencatResultsContainer'

export default class FormRenderer extends Component {
  render() {
    const { event, contender, history } = this.props
    switch (event.route) {
      case 'avd': {
        return (
          <ResultAVD
            event={event}
          />
        )
      }
      case 'gathering': {
        return (
          <ResultPOI
            event={event}
          />
        )
      }
      case 'ficha-salud': {
        return (
          <HealthTab
            event={event}
          />
        )
      }
      case 'gencat': {
        return (
          <GencatResult
            event={event}
          />
        )
      }
      case 'functional-interview': {
        // history.push(`/events/${event.event_id}/functional/${event.form_id}/result/profile`)
        return (
          <FunctionalContainer>
            <WorkProfile event={event} />
            <SupportLevel event={event} />
            <FunctionalDisabledForm event={event} />
          </FunctionalContainer>
        )
      }
      case 'individual-interview': {
        if (event.status === 'edit') {
          return (
            <IndividualInterviewEdit
              event={event}
            />
          )
        }
        if (event.status === 'check') {
          return (
            <InterviewResult
              event={event}
            />
          )
        }
        return (
          <IndividualInterview
            event={event}
          />
        )
      }
      case 'familiar-interview': {
        if (event.status === 'edit') {
          return (
            <IndividualInterviewEdit
              event={event}
              title="Familiar"
            />
          )
        }
        if (event.status === 'check') {
          return (
            <InterviewResult
              event={event}
              interviewName="Entrevista Familiar"
            />
          )
        }
        return (
          <FamiliarInterview
            event={event}
            title="Familiar"
          />
        )
      }
      case 'competencies': {
        return (
          <EvaluationView
            event={event}
            title="dasd"
          />
        )
      }
      case 'csd': {
        return (
          <CSDMain event={event} title="CSD" />
        )
      }
      case 'laboral-performance': {
        return (<LaboralPerformance event={event} title="Laboral" />)
      }
      case 'laboral-performance-graduated': {
        return (<LaboralPerformance event={event} title="Laboral Egresado" type=" (Egresados)" />)
      }
      case 'self-evaluation': {
        return (<Autoevaluation event={event} title="Autoevaluación" />)
      }
      case 'edps': {
        return (<EDPS event={event} title="Evaluación de Desarrollo Personal y Social" />)
      }
      case 'edps-v2': {
        return (<EDPS event={event} title="Evaluación de Desarrollo Personal y Social" type="new" />)
      }
      case 'gencat-familiar': {
        return (<GencatResultsContainer event={event} title="Escala de Calidad de Vida Familiar" />)
      }
      case 'physical-potential': {
        if (event.status === 'check') {
          return (<PhysicalResult event={event} title="Resultados Evaluación Física Funcional" />)
        }
        const url = `/physical/${event.event_id}/${event.form_id}/${event.evaluation_id}/${contender?.user?.name}`
        history.push(url)
        return null
      }
      default: {
        return (
          <div>Estamos trabajando en esta vista xd</div>
        )
      }
    }
  }
}

FormRenderer.propTypes = {
  event: propTypes.object,
  contender: propTypes.object.isRequired,
  history: propTypes.object.isRequired
}

FormRenderer.defaultProps = {
  event: {},
}
