const stateMaker = (state, name, type, action, { extendKey = 'unnamed_key', key = null, unshift = false } = {}) => {
  let newState
  if (action.payload) {
    const { data } = action.payload
    if (data && data.status === 'success') {
      switch (type) {
        case 'fetch': {
          newState = { ...state }
          newState[name] = data.info
          return newState
        }
        case 'fetch_and_extend': {
          newState = { ...state }
          newState[name] = { ...newState[name], [extendKey]: data.info }
          return newState
        }
        case 'fetch_and_add': {
          newState = { ...state }
          newState[name] = { ...newState[name],
            [extendKey]: [...newState[name][extendKey], data.info] }
          return newState
        }
        case 'create': {
          newState = {
            ...state,
            [name]: unshift ? [data.info, ...state[name]] : [...state[name], data.info],
          }
          return newState
        }
        case 'create_and_select': {
          newState = {
            ...state,
            [name]: unshift ? [data.info, ...state[name]] : [...state[name], data.info],
            selected: data.info
          }
          return newState
        }
        case 'delete': {
          const toDelete = state[name]
          const result = toDelete.filter(element => {
            const pivot = key ? element[key] : element
            return pivot.id.toString() !== data.info.toString()
          })
          return { ...state, [name]: result }
        }
        case 'select': {
          const selected = state[name].find(element => {
            const pivot = key ? element[key] : element
            return pivot.id.toString() === data.info.toString()
          })
          newState = { ...state, selected }
          return newState
        }
        case 'edit': {
          const toEdit = [...state[name]].map(e => {
            if (!key) {
              if (e.id === data.info.id) {
                return data.info
              }
            } else if (e[key].id === data.info[key].id) {
              return data.info
            }
            return e
          })
          newState = { ...state }
          newState[name] = toEdit
          return newState
        }
        default:
          return state
      }
    } else {
      return state
    }
  }
  return state
}

export default stateMaker
