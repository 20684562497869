import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Dialog, DialogContent, withStyles, Typography, IconButton, Divider, Collapse, Button } from '@material-ui/core'
import { Edit, Close, Phone, Mail, Room, SupervisorAccount, PermContactCalendar, LocalHospital } from '@material-ui/icons'
import moment from 'moment'
import { connect } from 'react-redux'
import Conditional from '../../../Shared/Conditional'
import autobind from '../../../Utils/autobind'
import EditableText from './EditDialog/EditableText'
import { editFlexContenderAction } from '../../../Actions/Contenders'

const style = () => ({
  container: {
    padding: 24,
    position: 'relative'
  },
  inlineSpaced: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  inlineLeft: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    padding: 6,
    marginRight: 6,
    height: 18,
    width: 18
  },
  disclaimer: {
    position: 'absolute',
    bottom: 3,
    right: 6
  },
  infoLines: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    '& > div': {
      flexBasis: '50%',
      margin: '0 12px 0 0'
    }
  },
  submitButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '12px 0',
    '& > *': {
      marginLeft: 6
    }
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12
  },
  iconEmergency: {
    color: '#f21231',
    height: 20,
    width: 20,
  }
})

class ContactDialog extends Component {
  constructor() {
    super()
    this.state = {
      openEdit: false,
      params: {
        contender: {},
        contacts: {}
      }
    }
    autobind(ContactDialog, this)
  }

  componentDidMount() {
    const { contenders, selected } = this.props
    if (selected !== '') {
      const contender = contenders.find(c => c.contender.id === selected)
      // eslint-disable-next-line prefer-destructuring
      const { contacts, user } = contender
      this.setState({ params: { contender: user, contacts } })
    }
  }

  componentDidUpdate(prevProps) {
    const { open, selected, contenders } = this.props
    if (open !== prevProps.open) {
      if (selected !== '') {
        const contender = contenders.find(c => c.contender.id === selected)
        const { contacts, user } = contender
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ params: { contender: user, contacts } })
      }
    }
  }

  handleOpenDialog() {
    const { openEdit } = this.state
    this.setState({ openEdit: !openEdit })
  }

  handleChange(event) {
    const { target } = event
    const { name, value } = target
    const { params } = this.state
    if (name.includes('contender')) params.contender[name.split('&')[1]] = value
    if (name.includes('contact')) {
      const index = name.split('&')[1]
      const inputName = name.split('&')[2]
      params.contacts[index] = { ...params.contacts[index], [inputName]: value }
    }
    this.setState({ params })
  }

  handleSend() {
    const { selected, editFlexContender } = this.props
    const { params } = this.state
    const body = { ...params }
    body.id = selected
    editFlexContender(body)
    this.setState({ openEdit: false })
  }

  render() {
    const { openEdit, params } = this.state
    const { open, handleClose, classes } = this.props
    const { contender, contacts } = params
    return (
      <Conditional condition={contender}>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogContent>
            <div className={classes.inlineSpaced}>
              <div className={classes.inlineLeft}>
                <img src={contender?.photo || './logo192.png'} alt="img" style={{ width: 48, height: 48, marginRight: 12, borderRadius: '50%' }} />
                <div>
                  <Typography variant="h1">{contender?.name}</Typography>
                  <Typography variant="caption">{`Ingresado el ${moment(contender?.created_at).format('DD [de] MMM [del] YYYY')}`}</Typography>
                </div>
              </div>
              <div>
                <IconButton size="small" onClick={this.handleOpenDialog}>
                  <Edit />
                </IconButton>
                <IconButton size="small" onClick={handleClose}>
                  <Close />
                </IconButton>
              </div>
            </div>
            <Divider />
            <Typography variant="subtitle1">Detalles de contacto</Typography>
            <div className={classes.infoLines}>
              <div>
                <EditableText
                  onChange={this.handleChange}
                  name="contender&phone"
                  open={openEdit}
                  value={contender?.phone}
                  Icon={Phone}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contender&email"
                  open={openEdit}
                  value={contender?.email}
                  Icon={Mail}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contender&address"
                  open={openEdit}
                  value={contender?.address}
                  Icon={Room}
                />
              </div>
            </div>
            <Divider />
            <div className={classes.inline}>
              <Typography variant="subtitle1">Contacto de emergencia</Typography>
              <LocalHospital className={classes.iconEmergency} />
            </div>
            <div className={classes.infoLines}>
              <div>
                <EditableText
                  onChange={this.handleChange}
                  name="contact&0&name"
                  open={openEdit}
                  value={contacts[0]?.name}
                  Icon={PermContactCalendar}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contact&0&relation"
                  open={openEdit}
                  value={contacts[0]?.relation}
                  Icon={SupervisorAccount}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contact&0&phone"
                  open={openEdit}
                  value={contacts[0]?.phone}
                  Icon={Phone}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contact&0&email"
                  open={openEdit}
                  value={contacts[0]?.email}
                  Icon={Mail}
                />
              </div>
            </div>
            <Typography variant="subtitle1">Contactos complementarios</Typography>
            <div className={classes.infoLines}>
              <div>
                <EditableText
                  onChange={this.handleChange}
                  name="contact&1&name"
                  open={openEdit}
                  value={contacts[1]?.name}
                  Icon={PermContactCalendar}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contact&1&relation"
                  open={openEdit}
                  value={contacts[1]?.relation}
                  Icon={SupervisorAccount}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contact&1&phone"
                  open={openEdit}
                  value={contacts[1]?.phone}
                  Icon={Phone}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contact&1&email"
                  open={openEdit}
                  value={contacts[1]?.email}
                  Icon={Mail}
                />
              </div>
              <div>
                <EditableText
                  onChange={this.handleChange}
                  name="contact&2&name"
                  open={openEdit}
                  value={contacts[2]?.name}
                  Icon={PermContactCalendar}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contact&2&relation"
                  open={openEdit}
                  value={contacts[2]?.relation}
                  Icon={SupervisorAccount}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contact&2&phone"
                  open={openEdit}
                  value={contacts[2]?.phone}
                  Icon={Phone}
                />
                <EditableText
                  onChange={this.handleChange}
                  name="contact&2&email"
                  open={openEdit}
                  value={contacts[2]?.email}
                  Icon={Mail}
                />
              </div>
            </div>
            <Collapse in={openEdit}>
              <div className={classes.submitButtons}>
                <Button onClick={this.handleOpenDialog}>Cancelar</Button>
                <Button variant="contained" color="primary" onClick={this.handleSend}>Guardar</Button>
              </div>
            </Collapse>
          </DialogContent>
        </Dialog>
      </Conditional>
    )
  }
}

ContactDialog.propTypes = {
  contenders: propTypes.arrayOf(propTypes.object),
  selected: propTypes.string,
  open: propTypes.bool,
  handleClose: propTypes.func,
  classes: propTypes.object.isRequired,
  editFlexContender: propTypes.func.isRequired
}

ContactDialog.defaultProps = {
  contenders: [],
  selected: '',
  open: false,
  handleClose: undefined
}

const mapDispatchToProps = dispatch => ({
  editFlexContender: body => dispatch(editFlexContenderAction(body))
})

export default connect(null, mapDispatchToProps)(withStyles(style)(ContactDialog))
