import React from 'react'
import { withStyles, Typography, TextField, MenuItem } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  selectRoot: {
    textAlign: 'left',
    padding: '6px 0px 7px 6px',
  },
})

const MyTextField = ({ name, label, value, onChange, placeholder, classes, selectOptions }) => {
  if (selectOptions.length) {
    return (
      <>
        <Typography
          style={{ textAlign: 'left', margin: '6px 0' }}
          variant="body2"
        >
          {label}
        </Typography>
        <TextField
          classes={{ root: classes.root }}
          onChange={onChange}
          name={name}
          select
          inputProps={{ style: { padding: '6px 0 7px 6px' } }}
          InputProps={{ style: { borderRadius: 0 } }}
          SelectProps={{ classes: { root: classes.selectRoot } }}
          value={value}
          variant="filled"
          fullWidth
          placeholder={placeholder}
          style={{ marginBottom: 6 }}
        >
          {selectOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </>
    )
  }
  return (
    <>
      <Typography style={{ textAlign: 'left', margin: '6px 0' }} variant="body2">
        {label}
      </Typography>
      <TextField
        classes={{ root: classes.root }}
        onChange={onChange}
        name={name}
        inputProps={{ style: { padding: '6px 0 7px 6px' } }}
        InputProps={{ style: { borderRadius: 0 } }}
        value={value}
        variant="filled"
        fullWidth
        placeholder={placeholder}
        style={{ marginBottom: 6 }}
      />
    </>
  )
}

MyTextField.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  placeholder: propTypes.string,
  classes: propTypes.object.isRequired,
  selectOptions: propTypes.arrayOf(
    propTypes.shape({ value: propTypes.string, label: propTypes.string })
  ),
}

MyTextField.defaultProps = {
  placeholder: '',
  selectOptions: [],
}

export default withStyles(style)(MyTextField)
