import React from 'react'
import propTypes from 'prop-types'
import MuiTextfield from '@material-ui/core/TextField'
import { ClickAwayListener } from '@material-ui/core'

class DblClickText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
    }
    this.handleDblClick = this.handleDblClick.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleDblClick() {
    this.setState({
      editing: true,
    })
  }

  handleBlur() {
    this.setState({
      editing: false,
    })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleBlur()
    }
  }

  render() {
    const { onChange, value } = this.props
    const { editing } = this.state
    return (
      <>
        {editing ? (
          <ClickAwayListener onClickAway={this.handleBlur}>
            <MuiTextfield
              autoFocus
              onKeyPress={this.handleKeyPress}
              onChange={onChange}
              value={value}
              fullWidth
              InputProps={{ style: { fontSize: '0.8rem' } }}
            />
          </ClickAwayListener>
        ) : (
          <div onDoubleClick={this.handleDblClick}>{value || 'Añadir observación'}</div>
        )}
      </>
    )
  }
}

DblClickText.propTypes = {
  onChange: propTypes.func.isRequired,
  value: propTypes.string.isRequired,
}

export default DblClickText
