import { Button, Divider, Typography, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { TextField } from '../../../../Shared/Fields/Text'
import autobind from '../../../../Utils/autobind'
import Checkbox from '../../../../Shared/Fields/Checkbox'
import RadioGroup from '../../../../Shared/Fields/RadioGroup'

const style = () => ({
  button: {
    textAlign: 'end'
  }
})

const options = [
  'Madre', 'Padre', 'Hermano(a)',
  'Hijo(a)',
  'Suegro(a)',
  'Tío(a)',
  'Yerno o Nuera',
  'Cuñado(a)',
  'Sobrino(a)',
  'Abuelo(a)',
  'Nieto(a)',
  'Tutor(a)',
]

class FirstStepCompanion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {}
    }
    autobind(FirstStepCompanion, this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleSend() {
    const { handleNextStep, handleShare, handleChangeView } = this.props
    const { params } = this.state
    handleShare({ companionName: params.name })
    handleNextStep(1)
    handleChangeView()
  }

  render() {
    const { params } = this.state
    const { classes } = this.props
    return (
      <div>
        <Typography variant="subtitle1">Identificación</Typography>
        <Divider />
        <TextField
          onChange={this.handleChange}
          value={params.name || ''}
          complete={{ text: 'Nombre completo acompañante', name: 'name' }}
        />
        <RadioGroup
          onChange={this.handleChange}
          value={params.option || ''}
          complete={{ text: '¿Vive con el aspirante?, ¿Cual es su relación con el aspirante?', options: ['Si', 'No'], name: 'option' }}
        />
        {params.option === '0'
          && (
            <Checkbox
              complete={{ text: '', name: 'relation', boxes: options }}
              onChange={this.handleChange}
              value={params.relation}
            />
          )}
        <Divider />
        <div className={classes.button}>
          <Button variant="contained" color="secondary" size="small" disabled={!params.name} onClick={this.handleSend}>
            Continuar
          </Button>
        </div>
      </div>
    )
  }
}

FirstStepCompanion.propTypes = {
  classes: propTypes.object.isRequired,
  handleNextStep: propTypes.func.isRequired,
  handleShare: propTypes.func.isRequired,
  handleChangeView: propTypes.func.isRequired,
}

FirstStepCompanion.defaultProps = {

}

export default withStyles(style)(FirstStepCompanion)
