import React from 'react'
import { withStyles } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { getAuthorizations } from 'API/Authorizations'
import { TextField } from 'Shared/Fields/Text'
import { loadAuthorizationsAction, approveAuthorizationAction, rejectAuthorizationAction } from '../../Actions/Authorizations'
import autobind from '../../Utils/autobind'
import GeneralTable from '../../Shared/GeneralTable'
import LoadAnimator from '../../Shared/LoaderAnimator'
import { formatDate, grantPermission, getAllowedRolesFromRole } from '../../Utils/functions'
import StatusCellRender from './StatusCellRender'

function formatEmpty(value) {
  if (value) return value
  return 'No aplica'
}

const style = () => ({
  container: { margin: 12 },
  scrollTable: {
    maxHeight: 400,
    overflow: 'auto'
  },
  input: {
    maxWidth: 400,
    margin: '24px 0 12px '
  }
})

const tableInfo = [
  { label: 'approved_by', name: 'Revisado por' },
  { label: 'generated_by', name: 'Generado por' },
  { label: 'type', name: 'Tipo' },
  { label: 'text', name: 'Motivo' },
  { label: 'contender_name', name: 'Trabajador', format: formatEmpty },
  { label: 'created_at', name: 'Fecha', format: formatDate },
]

const tableInfoReview = [
  { label: 'approved_by', name: 'Revisado por' },
  { label: 'generated_by', name: 'Generado por' },
  { label: 'type', name: 'Tipo' },
  { label: 'text', name: 'Motivo' },
  { label: 'updated_at', name: 'Fecha', format: formatDate },
  { label: 'contender_name', name: 'Trabajador', format: formatEmpty },
  { label: 'status', name: 'Estado', render: StatusCellRender }
]

class Authorizations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      offset: 0,
      step: 30,
      authorizations: [],
      search: ''
    }
    autobind(Authorizations, this)
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const response = await getAuthorizations()
    this.setState({ loading: false })
    const { data: { info: authorizations } } = response
    this.setState({ authorizations })
  }

  handleApproveAuthorization(row) {
    const { approveAuthorization } = this.props
    return () => {
      approveAuthorization(row.id)
    }
  }

  handleRejectAuthorization(row) {
    const { rejectAuthorization } = this.props
    return () => {
      rejectAuthorization(row.id)
    }
  }

  handleChange(event) {
    const { target } = event
    this.setState({ search: target.value })
  }

  async handleLoadMore() {
    const { offset, step, authorizations } = this.state
    const newOffset = offset + step
    const body = { offset: newOffset }
    const response = await getAuthorizations(body)
    const { data: { info: moreAuthorizations } } = response
    const newAuthorizations = authorizations.concat(moreAuthorizations)
    this.setState({ authorizations: newAuthorizations, offset: newOffset })
  }

  render() {
    const { classes, user } = this.props
    const { authorizations, search, loading } = this.state
    const canAction = grantPermission(user, ['director'])
    const actions = canAction ? [
      { name: 'Aprobar', icon: Check, action: this.handleApproveAuthorization },
      { name: 'Eliminar', icon: Close, action: this.handleRejectAuthorization }
    ] : []
    return (
      <div className={classes.container}>
        {loading ? <LoadAnimator loading />
          : (
            <GeneralTable
              info={tableInfo}
              title="Entradas pendientes"
              data={authorizations.filter(e => grantPermission(user,
                getAllowedRolesFromRole(e.scope)) && !e.approved_by_handler_id)}
              actions={actions}
              disablePagination
              fixed
            />
          )}
        {loading ? <LoadAnimator loading />
          : (
            <div style={{ marginBottom: 24 }}>
              <div className={classes.input}>
                <TextField complete={{ placeholder: 'Buscar...', value: search }} onChange={this.handleChange} value={search} />
              </div>
              <GeneralTable
                info={tableInfoReview}
                title="Solicitudes revisadas"
                data={authorizations
                  .filter(a => JSON
                    .stringify(a)
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()))
                  .filter(e => grantPermission(user, getAllowedRolesFromRole(e.scope))
                   && e.approved_by_handler_id)}
                disableFilters
                disablePagination
                name="processed"
                id="processed"
                scrollable
                onReachBottom={this.handleLoadMore}
                fixed
              />
            </div>
          )}
      </div>
    )
  }
}

Authorizations.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  approveAuthorization: propTypes.func.isRequired,
  rejectAuthorization: propTypes.func.isRequired
}

const mapStateToProps = state => ({
  authorizations: state.authorizations.all,
  user: state.user.current,
})

const mapDispatchToProps = dispatch => ({
  loadAuthorizations: () => dispatch(loadAuthorizationsAction()),
  approveAuthorization: id => dispatch(approveAuthorizationAction(id)),
  rejectAuthorization: id => dispatch(rejectAuthorizationAction(id))
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(withStyles(style)(Authorizations))
