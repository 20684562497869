import instance from '../Utils/client'

const loadHandlersAction = () => ({
  type: 'LOAD_HANDLERS',
  payload: instance({
    method: 'get',
    url: '/handlers',
  }),
})

export default loadHandlersAction
