const controllerReducer = (state = { snackbar: {} }, action) => {
  switch (action.type) {
    case 'LOAD_CONTROLLER':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default controllerReducer
