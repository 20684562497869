import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({

})
class ReportTitles extends Component {
  render() {
    const { classes, title } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h1">
          {title}
        </Typography>
        <Divider color="primary" />
      </div>
    );
  }
}

ReportTitles.propTypes = {
  classes: propTypes.object.isRequired,
  title: propTypes.string
}

ReportTitles.defaultProps = {
  title: 'Titulo'
}
export default withStyles(style)(ReportTitles)
