import React, { Component } from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import { getEventColor } from '../../Utils/functions'

const style = () => ({
  container: {
    background: 'lightslategrey',
    color: 'whitesmoke',
    fontSize: 12,
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  text: {
    padding: '0 6px',
    margin: 0,
    color: 'whitesmoke',
    fontSize: 12,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '2vh',
  },
  timeText: {
    padding: '0 6px',
    margin: 0,
    color: 'whitesmoke',
    fontSize: 11,
    lineHeight: '1.5',
  },
})

class CalendarEvent extends Component {
  render() {
    const { classes, event, handleClick } = this.props
    const isOtro = event.full.event_type_name === 'Otro'
    return (
      <Grid
        className={classes.container}
        style={{ backgroundColor: getEventColor(event.full.event_type_name), alignItems: 'center' }}
        onClick={handleClick}
      >
        <Typography variant="body2" className={classes.timeText} style={{ color: isOtro ? 'black' : null }}>
          {event.time}
        </Typography>
        <Typography variant="subtitle1" className={classes.text} style={{ color: isOtro ? 'black' : null }}>
          {event.name}
        </Typography>
      </Grid>
    )
  }
}

CalendarEvent.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.shape({ name: propTypes.string,
    time: propTypes.string,
    full: propTypes.object }).isRequired,
  handleClick: propTypes.func.isRequired,
}

export default withStyles(style)(CalendarEvent)
