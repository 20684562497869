import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Select from '../../../../Shared/Fields/Select'
import HandlerProfileContext from '../HandlerProfileContext'

const style = () => ({

})
class CommuneEdit extends Component {
  render() {
    const { classes, name, onChange, value = [], onBlur } = this.props;
    const { communes } = this.context
    return (
      <div className={classes.container}>
        <Select
          onBlur={onBlur}
          complete={{
            name,
            options: communes.map(commune => ({ label: commune.name, value: commune.id }))
          }}
          onChange={onChange}
          value={value}
        />
      </div>
    );
  }
}

CommuneEdit.contextType = HandlerProfileContext

CommuneEdit.propTypes = {
  classes: propTypes.object.isRequired,
  value: propTypes.string.isRequired,
  name: propTypes.string,
  onBlur: propTypes.func,
  onChange: propTypes.func
}

CommuneEdit.defaultProps = {
  name: '',
  onBlur: () => {},
  onChange: () => {},
}
export default withStyles(style)(CommuneEdit)
