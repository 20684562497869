/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { withStyles, Typography, Button, ListItem, Collapse, Paper } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'
import { ListAltRounded } from '@material-ui/icons'
import { loadExternalHealthByUserAction } from '../../../Actions/User'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import Header from '../Header/index'
import autobind from '../../../Utils/autobind'
import LoaderDialog from '../../../Shared/LoaderDialog'
import EditablePrevision from '../../Contenders/Profile/Tabs/Health/EditablePrevision'
import EditableHealthCriterium from '../../Contenders/Profile/Tabs/Health/EditableHealthCriterium'
import EditableSensorialEvaluation from '../../Contenders/Profile/Tabs/Health/EditableSensorialEvaluation'
import CheckupSection from '../../Contenders/Profile/Tabs/Health/CheckupSection'

const style = () => ({
  container: {
    padding: 12
  },
  message: {
    textAlign: 'center',
    width: '85%',
    margin: 'auto',
    marginTop: 46
  },
  index: {
    position: 'fixed',
    top: '40%',
    right: -150,
    width: 300,
    zIndex: 5,
    transition: 'all 0.3s ease-in-out'
  },
  indexDefault: {
    display: 'flex',
    padding: 12,
    '& > *': {
      marginRight: 6
    },
    cursor: 'pointer'
  },
  open: {
    right: 0
  }
})

const sections = [
  'Previsión de salud',
  'Salud Física',
  'Salud Mental',
  'Medicamentos',
  'Hospitalizaciones',
  'Eventos importantes',
  'Evaluación sensorial',
  'Salud sexual',
  'Exámenes y controles preventivos'
]

class Health extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      openIndex: false,

    }
    autobind(Health, this)
  }

  componentDidMount() {
    this.setState({ loading: true })
    const { loadExternalHealthByUser, match } = this.props
    const { params: { userId } } = match
    loadExternalHealthByUser(userId).then(() => {
      this.setState({ loading: false })
    })
  }

  handleOpenIndex() {
    const { openIndex } = this.state
    this.setState({ openIndex: !openIndex })
  }

  handleGotoSection(id) {
    return () => {
      const element = document.getElementById(id)
      if (!element) return null
      element.scrollIntoView({ behavior: 'smooth' })
      return this.setState({ openIndex: false })
    }
  }

  renderForm() {
    const { classes, event, match } = this.props
    const { loading, openIndex } = this.state
    const { params: { userId } } = match
    if (!event || Object.keys(event).length === 0) { return null }
    const form = event.forms ? event?.forms['Ficha de Salud'] : false
    const name = event?.participants.find(e => e.id === userId)?.name

    const saludFisica = form['Parte I']['Salud Física']
    const saludMental = form['Parte II']['Salud Mental']
    const medicamentos = form['Parte II'].Medicamentos
    const hospitalizaciones = form['Parte II'].Hospitalizaciones
    const eventosImportantes = form['Parte II']['Eventos importantes']
    const evaluacionSensorial = form['Parte II']['Evaluación Sensorial']
    const saludSexual = form['Parte III']['Salud Sexual']
    const checkups = event?.forms?.Controles || []
    return (
      <>
        <Paper className={`${classes.index} ${openIndex ? classes.open : ''} no-print`}>
          <div className={`${classes.indexDefault}`} onClick={this.handleOpenIndex}>
            <ListAltRounded />
            <Typography variant="subtitle1">Índice</Typography>
          </div>
          <Collapse in={openIndex}>
            {sections.map((section, index) => (
              <ListItem button key={section} onClick={this.handleGotoSection(`section-${index}`)}>
                <Typography variant="subtitle1">{`${index + 1}. ${section}`}</Typography>
              </ListItem>
            ))}
          </Collapse>
        </Paper>
        <Paper className={`${classes.container} print-no-border`}>
          <Button style={{ marginBottom: 12 }} color="primary" variant="contained" className="no-print" onClick={() => window.print()}>Descargar</Button>
          <Typography variant="h3" style={{ marginBottom: 12 }}>
            {`Ficha de Salud de: ${name}`}
          </Typography>
          <div id="section-0"><EditablePrevision editable={false} reload={this.handleReload} form={form} event={event} /></div>
          <div id="section-1"><EditableHealthCriterium editable={false} reload={this.handleReload} event={event} form={saludFisica} title="Salud Física" /></div>
          <div id="section-2"><EditableHealthCriterium editable={false} reload={this.handleReload} event={event} form={saludMental} title="Salud Mental" /></div>
          <div id="section-3"><EditableHealthCriterium editable={false} reload={this.handleReload} event={event} form={medicamentos} title="Medicamentos" /></div>
          <div id="section-4"><EditableHealthCriterium editable={false} reload={this.handleReload} event={event} form={hospitalizaciones} title="Hospitalizaciones" /></div>
          <div id="section-5"><EditableHealthCriterium editable={false} reload={this.handleReload} event={event} form={eventosImportantes} title="Eventos Importantes" /></div>
          <div id="section-6"><EditableSensorialEvaluation editable={false} reload={this.handleReload} form={evaluacionSensorial} event={event} title="Evaluación Sensorial" /></div>
          <div id="section-7"><EditableSensorialEvaluation editable={false} reload={this.handleReload} form={saludSexual} event={event} title="Salud Sexual" /></div>
          <div id="section-8">
            <CheckupSection
              checkups={checkups}
              reload={this.handleReload}
              isParentLoading={loading}
              editable={false}
            />
          </div>
          <LoaderDialog open={loading} />
        </Paper>
      </>
    )
  }

  render() {
    const { loading } = this.state
    return (
      <>
        <Header removeUserInfo />
        {loading ? <LoaderAnimator loading /> : this.renderForm()}

      </>
    )
  }
}

Health.propTypes = {
  classes: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  loadExternalHealthByUser: propTypes.func.isRequired,
  event: propTypes.object
}

Health.defaultProps = {
  event: undefined
}

const mapDispatchToProps = dispatch => ({
  loadExternalHealthByUser: contenderId => dispatch(loadExternalHealthByUserAction(contenderId))
})

const mapStateToProps = state => ({
  event: state.events.selected
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(Health)))
