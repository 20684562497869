import React, { Component } from 'react'
import { withStyles, Typography, Button } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import PropTypes from 'prop-types'

const style = () => ({
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 6,
    borderRadius: 5,
  },
})

class AddEvent extends Component {
  render() {
    const { classes, onClick } = this.props
    return (
      <Button className={classes.button} onClick={onClick} color="secondary" variant="contained">
        <Typography variant="body2" style={{ color: 'white', fontWeight: 600 }}>
          Crear
        </Typography>
        <AddCircle style={{ color: 'white' }} />
      </Button>
    )
  }
}

AddEvent.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default withStyles(style)(AddEvent)
