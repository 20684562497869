import React, { Component } from 'react'
import { Button, Divider, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Message from './Message'
import autobind from '../../Utils/autobind'
import FlexibleInput from '../../Utils/FieldRenderer'
import { changeFormStatus, sendAnswers } from '../../API/Events'
import { callSnackbar } from '../../Utils/snackbars'

const style = () => ({
  container: {
    maxWidth: 700,
    margin: 'auto',
    padding: 24,
  },
  message: {
    '& > *': {
      margin: '12px 0'
    }
  },
  question: {
    margin: '24px 0 32px',
    '& > div > p': {
      fontWeight: '600 !important',
      marginBottom: '0 !important'
    }
  }
})

class GencatFormContainer extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      end: false
    }
    autobind(GencatFormContainer, this)
  }

  componentDidMount() {
    const { selected, event } = this.props
    const params = {}
    if (!event) return null
    const mainForm = event.forms['Escala de Calidad de Vida Familiar']
    const stageNames = Object.keys(mainForm)
    const stages = stageNames.map(key => mainForm[key][key])
    stages.forEach(stage => {
      stage.forEach(question => {
        const answer = question?.answers?.find(a => a.user_id === selected)
        if (answer?.body) { params[answer.id] = answer.body }
      })
    })
    return this.setState({ params })
  }

  handleChange(event) {
    const { target: { name, value } } = event
    const { params } = this.state
    params[name] = value
    this.setState({ params })
  }

  async handleSend() {
    const { params } = this.state
    const { event, selected } = this.props
    const answers = { ...params }
    if (Object.keys(answers).length === 0) return null
    const body = Object.keys(answers).map(key => ({
      id: key,
      body: answers[key],
    }))
    const { id } = event
    const participant = event?.participants?.find(p => p.id === selected)
    const eventBody = {
      event_id: event?.event?.id,
      form_id: event?.forms_id?.[0]?.id,
      user: participant.id
    }
    await sendAnswers({ answers: body }, id)
    await changeFormStatus(eventBody)
    callSnackbar('Respuestas guardadas!', 'success')
    return this.setState({ end: true })
  }

  checkIfCompleted() {
    const { selected, event } = this.props
    const { params } = this.state
    if (!event) return null
    const mainForm = event.forms['Escala de Calidad de Vida Familiar']
    const stageNames = Object.keys(mainForm)
    const stages = stageNames.map(key => mainForm[key][key])
    let completed = true
    stages.forEach(stage => {
      stage.forEach(question => {
        const questionName = question?.answers?.find(a => a.user_id === selected).id
        if (!params[questionName]) {
          completed = false
        }
      })
    })
    return completed
  }

  renderForm() {
    const { selected, event, classes } = this.props
    const { params } = this.state
    if (!event) return null
    const mainForm = event.forms['Escala de Calidad de Vida Familiar']
    const stageNames = Object.keys(mainForm)
    const stages = stageNames.map(key => mainForm[key][key])
    return stages.map((stage, index) => {
      const name = stageNames[index]
      return (
        <div className={classes.form} key={name}>
          {index > 0 && <Divider className={classes.divider} />}
          {stage.map(question => {
            const questionName = question?.answers?.find(a => a.user_id === selected).id
            const complete = { ...question, name: questionName }
            return (
              <div className={classes.question} key={question?.id}>
                <FlexibleInput
                  complete={complete}
                  onChange={this.handleChange}
                  value={params[questionName]}
                  disableNoObservation
                />
              </div>
            )
          })}
        </div>
      )
    })
  }

  render() {
    const { classes, event, selected } = this.props;
    const { end } = this.state
    const participant = event?.participants?.find(p => p.id === selected)
    const disabled = !this.checkIfCompleted()
    if (end) {
      return (
        <Paper className={classes.container}>
          <Typography variant="h1">
            {`Escala de Calidad de Vida Familiar: ${participant?.name}`}
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 24 }} color="primary">
            Muchas gracias por responder este formulario,
            sus respuestas han sido guardadas correctamente.
            Ya puede cerrar la página
          </Typography>
        </Paper>
      )
    }
    return (
      <Paper className={classes.container}>
        <Typography variant="h1">
          {`Escala de Calidad de Vida Familiar: ${participant?.name}`}
        </Typography>
        <div className={classes.message}>
          <Message />
        </div>
        {this.renderForm()}
        <div className="right">
          <Button
            color="secondary"
            variant="contained"
            onClick={this.handleSend}
            disabled={disabled}
          >
            {disabled ? 'Faltan respuestas' : 'Finalizar'}
          </Button>
        </div>
      </Paper>
    );
  }
}

GencatFormContainer.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  selected: propTypes.string.isRequired,
}

GencatFormContainer.defaultProps = {

}

export default withStyles(style)(GencatFormContainer)
