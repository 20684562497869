import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import PercentageBar from '../../Shared/PercentageBar'

class ResultPercentages extends Component {
  render() {
    const { result, title, subtitle1, subtitle2, containerStyle, showSubtitles } = this.props
    return (
      <div style={containerStyle}>
        <Typography variant="subtitle1">
          {title}
        </Typography>
        <div style={{ paddingTop: 6 }}>
          <PercentageBar percentage={result ? result.average_percentage : 0} />
        </div>
        {showSubtitles && (
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: 24 }}>
            <div style={{ padding: 12, textAlign: 'center', borderTop: 'solid 2px #ECECEC', borderRight: 'solid 2px #ECECEC' }}>
              <Typography variant="body1">{subtitle1}</Typography>
              <Typography variant="h1">
                {`${result ? result.handler_percentage : 0} %`}
              </Typography>
            </div>
            <div style={{ padding: 12, textAlign: 'center', borderTop: 'solid 2px #ECECEC', }}>
              <Typography variant="body1">{subtitle2}</Typography>
              <Typography variant="h1">
                {`${result ? result.companion_percentage : 0} %`}
              </Typography>
            </div>
          </div>
        )}
      </div>
    )
  }
}

ResultPercentages.propTypes = {
  result: propTypes.object,
  title: propTypes.string,
  subtitle1: propTypes.string,
  subtitle2: propTypes.string,
  showSubtitles: propTypes.bool,
  containerStyle: propTypes.object,
}

ResultPercentages.defaultProps = {
  result: { average_percentage: null, handler_percentage: null, companion_percentage: {} },
  title: 'Titulo',
  showSubtitles: true,
  subtitle1: 'Sub1',
  subtitle2: 'Sub2',
  containerStyle: { minWidth: 600, width: '55%' }
}

export default ResultPercentages
