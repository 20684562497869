import React, { Component } from 'react'
import propTypes from 'prop-types'
import Chart from 'chart.js'
import './Setup'
import { withStyles } from '@material-ui/core'
import { splitPhrase } from '../../Utils/functions'

const style = () => ({
  labels: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: 100
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    marginRight: 12
  }
})

class BarChart extends Component {
  componentDidMount() {
    const { name, data, options } = this.props
    const chartElement = document.getElementById(name)
    const myChartRef = chartElement.getContext('2d');
    chartElement.parentNode.style.height = options.height || '250px';
    chartElement.parentNode.style.width = options.width || '250px';

    const parseLabelFunc = splitPhrase(13)
    // eslint-disable-next-line no-new
    this.chart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels: data.labels,
        datasets: data.datasets
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          enabled: false
        },
        hover: {
          mode: null
        },
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            ticks: {
              display: !options.detachedLabels, // Detach here
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              min: options.minValue || 0,
              max: options.maxValue || 0,
              stepSize: options.stepSize || 5,
              minRotation: options.minRotation || 0,
              maxRotation: options.maxRotation || 45,
              callback(value) {
                const returnValue = parseLabelFunc(value)
                return returnValue
              }
            },
            gridLines: {
              display: options.xEnableGrid || false,
              drawBorder: false
            },
            scaleLabel: {
              display: options.xHasTitle,
              labelString: options.xTitle,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: options.minValue || 0,
              max: options.maxValue || 100,
              stepSize: options.stepSize || 5,
              callback(value, index, allValues) {
                return options.callback ? options.callback(value, index, allValues) : value
              }
            },
            gridLines: {
              display: options.yEnableGrid || false,
              drawBorder: false
            },
            scaleLabel: {
              display: options.yHasTitle,
              labelString: options.yTitle,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        }
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (prevProps.data !== data) {
      this.chart.data.labels = data.labels
      this.chart.data.datasets = data.datasets
      this.chart.update()
    }
  }

  render() {
    const { name, title, showTitle } = this.props
    return (
      <div>
        {showTitle && (
          <div style={{ textAlign: 'start', marginBottom: 24 }}>
            {title}
          </div>
        )}
        <div>
          <canvas
            id={name}
            ref={this.chartRef}
          />
        </div>
      </div>
    )
  }
}

BarChart.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.object]),
  name: propTypes.string.isRequired,
  data: propTypes.arrayOf(propTypes.object),
  options: propTypes.object,
  showTitle: propTypes.bool
}

BarChart.defaultProps = {
  showTitle: true,
  title: 'Gráfico',
  data: [],
  options: {
    height: '200px',
    width: '100%',
    barThickness: 64,
    enableHover: false,
    detachedLabels: true,
    minValue: 0,
    maxValue: 100,
    stepSize: 20,
  }
}

export default withStyles(style)(BarChart)
