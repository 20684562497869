import instance from '../Utils/client'

const loadUserHistoriesAction = id => ({
  type: 'LOAD_USER_HISTORIES',
  payload: instance({
    method: 'get',
    url: `/users/${id}/histories`,
  }),
})

const searchUserHistoriesAction = (id, queryString) => ({
  type: 'SEARCH_USER_HISTORIES',
  payload: instance({
    method: 'get',
    url: `/users/${id}/histories?query=${queryString}`,
  }),
})

export { loadUserHistoriesAction, searchUserHistoriesAction }
