import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import FloatingComment from 'Shared/FloatingComment'
import RecurrentContext from '../RecurrentEvaluations/RecurrentContext'
import FlexibleInput from '../../Utils/FieldRenderer'
import autobind from '../../Utils/autobind'
import { sendAnswersAction } from '../../Actions/Events'
import AutoSaver from '../../Shared/AutoSaver'
import { changeFormStatus, getFormEvaluation } from '../../API/Recurrent'
import LoaderAnimator from '../../Shared/LoaderAnimator'

const style = theme => ({
  container: {
    padding: 24,
    margin: 'auto',
    textAlign: 'left',
    maxWidth: 900,
  },
  formContainer: {
    padding: 24,
    position: 'relative'
  },
  question: {
    margin: '12px 0'
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginBottom: 24
  },
  button: {
    textAlign: 'end'
  },
  bold: {
    fontWeight: 600
  }
})

class AutoevaluationForm extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      evaluation: null,
      ready: false,
    }
    autobind(AutoevaluationForm, this)
  }

  async componentDidMount() {
    const { match } = this.props
    const { form_id: formId, evaluation_id: evaluationId } = match.params
    const body = {
      form_id: formId,
      evaluation_id: evaluationId,
    }
    console.log(body)
    const response = await getFormEvaluation(body)
    console.log(response)
    if (response.data.status !== 'success') return false
    const evaluation = response.data.info
    this.setState({ evaluation })
    const mainForm = evaluation['Auto-evaluación'].empty
    const groupoOfQuestions = Object.values(mainForm)
    const questions = groupoOfQuestions.flat()
    const newParams = {}
    questions.forEach(question => {
      const name = question?.answers[0]?.id
      const answer = question?.answers[0]?.body?.answer
      newParams[name] = answer
    })
    return this.setState({ params: newParams })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleSave(end) {
    return async () => {
      const { params } = this.state
      const { sendAnswers, match } = this.props
      const { event_id: eventId, evaluation_id: evaluationId } = match.params
      const answers = { ...params }
      const body = Object.entries(answers).map(([key, value]) => ({
        id: key,
        body: { answer: value },
        comment: '',
      }))
      await sendAnswers({ answers: body }, eventId)
      if (end) {
        await changeFormStatus({ evaluation_id: evaluationId, status: 'check' })
        this.setState({ ready: true })
      }
      return true
    }
  }

  renderQuestions() {
    const { classes } = this.props
    const { params, evaluation } = this.state
    if (!evaluation) {
      return (
        <div style={{ height: 300 }}>
          <LoaderAnimator loading />
        </div>
      )
    }
    const mainForm = evaluation['Auto-evaluación'].empty
    const groupoOfQuestions = Object.values(mainForm)
    const questions = groupoOfQuestions.flat()
    return questions.map((question, index) => {
      const name = question?.answers[0]?.id
      const info = { ...question }
      info.name = name
      info.text = `${index + 1} - ${question.text}`
      return (
        <div className={classes.question}>
          <FlexibleInput
            complete={info}
            value={params[name]}
            onChange={this.handleChange}
          />
        </div>
      )
    })
  }

  render() {
    const { classes, match } = this.props
    const {
      cont_name: contenderName, event_id: eventId, evaluation_id: evaluationId, form_id: formId
    } = match.params
    const decodedName = atob(contenderName)
    const { ready } = this.state
    const eventInfo = {
      event_id: eventId,
      form_id: formId
    }
    if (ready) {
      return (
        <div className={classes.container}>
          <Paper className={classes.formContainer}>
            <Typography variant="h1">¡Gracias por responder!</Typography>
          </Paper>
        </div>
      )
    }
    return (
      <div className={classes.container}>
        <FloatingComment event={eventInfo} evaluation={evaluationId} />
        <Paper className={classes.formContainer}>
          <Typography variant="h1" className={classes.title}>Autoevaluación</Typography>
          <div className={classes.header}>
            <Typography variant="subtitle2">
              Usuario:
              {' '}
              <span className={classes.bold}>{decodedName }</span>
            </Typography>
          </div>
          {this.renderQuestions()}
          <div className={classes.button}>
            <Button color="secondary" variant="contained" onClick={this.handleSave(true)}>
              Finalizar
            </Button>
          </div>
        </Paper>
        <AutoSaver timeout={90000} onSave={this.handleSave(false)} />
      </div>
    )
  }
}

AutoevaluationForm.contextType = RecurrentContext

AutoevaluationForm.propTypes = {
  classes: propTypes.object.isRequired,
  sendAnswers: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
}

AutoevaluationForm.defaultProps = {
}

const mapDispatchToProps = dispatch => ({
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id))
})

export default connect(null, mapDispatchToProps)(withStyles(style)(AutoevaluationForm))
