import React, { Component } from 'react'
import propTypes from 'prop-types'
import { withStyles, Typography } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import BarChart from '../../../../Shared/Charts/BarChart'
import OnTouchTooltip from '../../../../Shared/OnTouchTooltip'

const style = theme => ({
  button: {
    color: theme.palette.grey.border,
    height: 20,
    width: 20,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12
  },
  titleResult: {
    fontWeight: 900,
    fontSize: '14px',
  },
})
class LineChartGencat extends Component {
  render() {
    const { data, classes, chartOptions, title, name } = this.props
    return (
      <>
        {title && (
          <div className={classes.inline}>
            <Typography className={classes.titleResult}>{title}</Typography>
            <OnTouchTooltip title="El valor es determinado por la tabla de baremos establecida, convirtiendo la puntuación directa obtenida en cada dimensión al percentíl de la tabla. Resultando en el porcentaje de personas que tienen una puntuación superior o inferior al usuario.">
              <HelpOutline className={classes.button} />
            </OnTouchTooltip>
          </div>
        )}
        <BarChart name={name} data={data} options={chartOptions} showTitle={false} />
      </>
    )
  }
}

LineChartGencat.propTypes = {
  data: propTypes.arrayOf(propTypes.object),
  classes: propTypes.object.isRequired,
  chartOptions: propTypes.object,
  title: propTypes.string,
  name: propTypes.string
}

LineChartGencat.defaultProps = {
  data: [],
  chartOptions: {
    detachedLabels: false,
    yEnableGrid: true,
    minValue: 0,
    maxValue: 100,
    stepSize: 10,
    height: '350px',
    width: '100%',
  },
  title: '',
  name: 'graph'
}

export default withStyles(style)(LineChartGencat)
