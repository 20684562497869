import { Button, Divider, Paper, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import propTypes from 'prop-types'
import autobind from 'Utils/autobind'
import { changeFormStatus, getFormEvaluation } from 'API/Recurrent'
import FlexibleInput from 'Utils/FieldRenderer'
import { sendAnswers } from 'API/Events'

const style = theme => ({
  container: {
    maxWidth: 650,
    margin: 'auto',
    padding: 24,
    textAlign: 'start',
    marginTop: 46
  },
  divider: {
    background: theme.palette.primary.main
  },
  question: {
    margin: '12px 0',
    '& > div > *:first-child': {
      fontWeight: '600 !important',
      margin: '6px 0 3px 0'
    },
    '& > div > *:nth-child(2)': {
      width: '100% !important',
      gap: 6
    }
  }
})

class PhysicalEvaluation extends React.Component {
  constructor() {
    super()
    this.state = {
      name: '',
      evaluation: [],
      params: {},
      finished: false
    }
    autobind(PhysicalEvaluation, this)
  }

  async componentDidMount() {
    const { match } = this.props
    const { params: { event_id: Eid, form_id: Fid, evaluation_id: Evid, cont_name: name } } = match
    const body = { event_id: Eid, form_id: Fid, evaluation_id: Evid }
    const response = await getFormEvaluation(body)
    const evaluationSections = response.data.info['Potencial Físico Funcional'].empty
    const evaluation = Object.values(evaluationSections).flat()
    console.log(response)
    this.setState({ evaluation, name })
  }

  handleChange(event) {
    const { target: { value, name } } = event
    const { params } = this.state
    params[name] = value
    console.log(params)
    this.setState({ params })
  }

  async handleSave() {
    const { match: { params: { event_id: eventId, evaluation_id: evaluationId } } } = this.props
    const { params } = this.state
    const answers = { ...params }
    const body = Object.entries(answers).map(([key, value]) => ({
      id: key,
      body: { answer: value },
      comment: '',
    }))
    await sendAnswers({ answers: body }, eventId)
    await changeFormStatus({ evaluation_id: evaluationId, status: 'check' })
    this.setState({ finished: true })
  }

  renderQuestions() {
    const { classes } = this.props
    const { evaluation, params } = this.state
    return evaluation.map(question => {
      const info = { ...question }
      const questionName = question.answers[0].id
      info.columns = '1fr'
      info.name = questionName
      return (
        <div className={classes.question}>
          <FlexibleInput
            complete={info}
            onChange={this.handleChange}
            value={params[questionName]}
          />
          {question.image_url
            && <img src={`/${question.image_url}`} alt="fotito" />}
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props
    const { name, evaluation, finished, params } = this.state
    const answeredQuestions = evaluation
      .filter(question => !!params[question.answers[0].id]).length
    const totalQuestions = evaluation.length
    if (finished) {
      return (
        <Paper className={classes.container}>
          <Typography variant="h1">Gracias por responder</Typography>
          <Divider />
          <Typography variant="subtitle1">
            {'Evaluación de potencial físico funcional de '}
            {' '}
            <span style={{ fontWeight: 600, color: 'black' }}>{name}</span>
            Respondida exitosamente
          </Typography>
        </Paper>
      )
    }
    return (
      <Paper className={classes.container}>
        <Typography variant="h1">Potencial Físico Funcional</Typography>
        <Divider />
        <Typography variant="subtitle1">
          {'Usuario: '}
          {' '}
          <span style={{ fontWeight: 600, color: 'black' }}>{name}</span>
          {' '}
        </Typography>
        {this.renderQuestions()}
        <div style={{ textAlign: 'end', marginTop: 12 }}>
          <Button color="secondary" variant="contained" onClick={this.handleSave} disabled={answeredQuestions !== totalQuestions}>
            { answeredQuestions === totalQuestions ? 'Finalizar' : `Preguntas: ${answeredQuestions}/${totalQuestions}` }
          </Button>
        </div>
      </Paper>
    )
  }
}

PhysicalEvaluation.propTypes = {
  classes: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
}

PhysicalEvaluation.defaultProps = {
}

export default withStyles(style)(PhysicalEvaluation)
