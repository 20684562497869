import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Paper, withStyles, Typography, Divider } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import FormState from './FormState'
import { checkIfAnswered, getTotal, getAnswered } from './utils'

const style = theme => ({
  container: {
    padding: 24,
    maxWidth: 600,
    margin: 'auto'
  },
  subText: {
    fontWeigth: 400
  },
  divider: {
    background: theme.palette.grey.dark,
    margin: '24px 0'
  }
})

class FormsStatusContainer extends Component {
  componentDidMount() {
    const { handleSelect } = this.props
    const ready = this.checkReady()
    if (ready) {
      handleSelect(2)()
    }
  }

  componentDidUpdate() {
    const { handleSelect } = this.props
    const ready = this.checkReady()
    if (ready) {
      handleSelect(2)()
    }
  }

  handleSelectForm(value) {
    const { handleSelect } = this.props
    return () => handleSelect(value)
  }

  checkReady() {
    const { event, match } = this.props
    let result = true
    if (event) {
      Object.keys(event.forms).forEach(formName => {
        result = checkIfAnswered(event.forms[formName], match.params.contender_id)
      })
    }
    return result
  }

  renderForms() {
    const { event, classes, handleSelect, match, disabled } = this.props
    if (event) {
      return Object.keys(event.forms).filter((formName => formName !== 'Escala de Calidad de Vida')).map((formName, index) => (
        <div key={formName}>
          <FormState
            title={formName}
            completed={
              getTotal(event.forms[formName])
              - getAnswered(event.forms[formName], match.params.contender_id) === 0
            }
            select={handleSelect(index + 1)}
            answered={getAnswered(event.forms[formName], match.params.contender_id)}
            total={getTotal(event.forms[formName])}
            disabled={disabled}
          />
          <Divider className={classes.divider} />
        </div>
      ))
    } return null
  }

  render() {
    const { classes } = this.props
    return (
      <Paper className={classes.container}>
        <Typography variant="h1" color="primary">Por favor responde los siguientes formularios:</Typography>
        <Divider className={classes.divider} />
        {this.renderForms()}
      </Paper>
    )
  }
}

FormsStatusContainer.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  handleSelect: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  disabled: propTypes.bool
}

FormsStatusContainer.defaultProps = {
  disabled: false
}

export default withRouter(withStyles(style)(FormsStatusContainer))
