/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import propTypes from 'prop-types'
import { connect } from 'react-redux';
import { MultilineTextField } from './Fields/Text';
import autobind from '../Utils/autobind';
import { editEvaluationComment, getEvaluationComment } from '../API/Evaluations';

class GlobalComment extends React.Component {
  constructor() {
    super()
    this.state = {
      comment: ''
    }
    autobind(GlobalComment, this)
  }

  async componentDidMount() {
    const { event, evaluation, contender } = this.props
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      evaluation_id: evaluation,
      user_id: event.user_id ?? contender.user.id
    }
    const response = await getEvaluationComment(body)
    if (response.data.status !== 'success') return null
    return this.setState({ comment: response.data.info.comment })
  }

  async componentDidUpdate(prevProps) {
    const { event, evaluation } = this.props
    if (event.user_id && event.user_id !== prevProps.event.user_id) {
      const body = {
        event_id: event.event_id,
        form_id: event.form_id,
        evaluation_id: evaluation,
        user_id: event.user_id
      }
      const response = await getEvaluationComment(body)
      if (response.data.status !== 'success') return null
      return this.setState({ comment: response.data.info.comment })
    }
    return true
  }

  handleChange(event) {
    const { target } = event
    this.setState({ comment: target.value })
  }

  handleSave() {
    const { event, evaluation, contender } = this.props
    const { comment } = this.state
    const body = {
      user_id: event.user_id ?? contender.user.id,
      event_id: event.event_id,
      form_id: event.form_id,
      evaluation_id: evaluation,
      comment,
    }
    editEvaluationComment(body)
  }

  render() {
    const { comment } = this.state
    return (
      <div>
        <MultilineTextField
          complete={{ rows: 3, placeholder: 'Observaciones' }}
          onChange={this.handleChange}
          onBlur={this.handleSave}
          value={comment}
        />
      </div>
    )
  }
}

GlobalComment.propTypes = {
  event: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  evaluation: propTypes.object
}

GlobalComment.defaultProps = {
  evaluation: null
}

const mapStateToProps = state => ({
  contender: state.contenders.selected
})

export default connect(mapStateToProps)(GlobalComment);
