import React, { Component } from 'react'
import StepContainer from '../../../Shared/StepContainer'
import SelectContender from './SelectContender'
import CompanionForm from './CompanionForm'
import Result from './Result'

export default class Companions extends Component {
  render() {
    return (
      <>
        <StepContainer>
          <SelectContender channel="CompanionChannel" title="Jornada de acompañante" loadText="Esperando Jornada acompañante" />
          <CompanionForm />
          <Result />
        </StepContainer>
      </>
    )
  }
}
