import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import setSearchQueryAction from '../Actions/Search'


const style = theme => ({
  textInput: {
    borderRadius: 5,
    background: theme.palette.indigo.lighter,
  },
})

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      searchFunc: null,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount() {
    const { searchFunction, setSearchQuery } = this.props
    this.setState({
      searchFunc: _.debounce(query => {
        setSearchQuery(query)
        searchFunction(query)
      }, 700),
    })
  }

  handleInputChange(input) {
    const { searchFunc } = this.state
    this.setState({
      query: input.target.value,
    })
    if (searchFunc) {
      searchFunc(input.target.value)
    }
  }

  render() {
    const { classes, fieldStyle } = this.props
    const { query } = this.state
    return (
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
          style: { height: 36 },
          disableUnderline: true,
        }}
        style={fieldStyle}
        onChange={this.handleInputChange}
        value={query}
        className={classes.textInput}
      />
    )
  }
}

SearchBar.defaultProps = {
  fieldStyle: {},
}

SearchBar.propTypes = {
  classes: propTypes.object.isRequired,
  fieldStyle: propTypes.object,
  searchFunction: propTypes.func.isRequired,
  setSearchQuery: propTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  setSearchQuery: query => dispatch(setSearchQueryAction(query)),
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(style)(SearchBar))
