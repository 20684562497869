import React, { Component } from 'react'
import { Button, Divider, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { HelpOutline } from '@material-ui/icons'
import DownloadableSection from 'Shared/DownloadableSection'
import ProfilesTable from './ProfilesTable'
import autobind from '../../../Utils/autobind'
import MixedChart from '../../../Shared/Charts/MixedChart'
import BarChart from '../../../Shared/Charts/BarChart'
import ProfilePercentage from './ProfilePercentage'
import { getFunctionalResultsAction } from '../../../Actions/FunctionalEvaluaction'
import OnTouchTooltip from '../../../Shared/OnTouchTooltip'
import { editEvaluationComment, getEvaluationComment } from '../../../API/Evaluations'
import GlobalComment from '../../../Shared/GlobalComment'

const profileColors = {
  Administrativo: 'rgba(31, 128, 149)',
  'Atención al cliente': 'rgba(251, 131, 104)',
  Operario: 'rgba(255, 179, 61)',
  'Servicios de apoyo': 'rgba(56, 180, 169)',
}

const options = {
  detachedLabels: false,
  yEnableGrid: true,
  minValue: 0,
  height: '400px',
  maxValue: 5,
  stepSize: 1,
  callback: value => {
    const labels = ['Apoyo Total', 'Bastante Apoyo', 'Apoyo Ocasional', 'Poco Apoyo', 'Sin Apoyo', ''].reverse()
    return labels[value]
  },
  width: '100%',
  minRotation: 45,
}

const barOptions = {
  detachedLabels: false,
  yEnableGrid: true,
  minValue: 0,
  maxValue: 7,
  stepSize: 1,
  height: '350px',
  width: '100%',
  minRotation: 45,
}

const style = theme => ({
  container: {
    margin: 12,
    padding: 12,
    textAlign: 'left'
  },
  title: {
    textAlign: 'left',
    fontSize: 16
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 48
  },
  sectionDivider: {
    display: 'flex',
    gap: '18px',
    marginBottom: 6
  },
  divider: {
    flexGrow: 1,
    background: '#6a6a6a',
    opacity: 0.7
  },
  readyButton: {
    textAlign: 'end'
  },
  finalSupportLevel: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: 24
  },
  supportLevel: {
    padding: '6px 18px',
    background: theme.palette.grey.light,
    textAlign: 'center',
    borderRadius: 6
  },
  bySkill: {
    margin: '36px 0'
  },
  finalCharts: {
    display: 'flex',
    gap: '2%',
    '&>*': {
      width: '48%',
      minWidth: '400px',
      maxWidth: '700px',
      flexGrow: 1,
      marginTop: 24,
    },
    flexWrap: 'wrap'
  },
  chartTitle: {
    fontWeight: 600,
    color: theme.palette.grey.dark,
  },
  button: {
    color: theme.palette.grey.border,
    height: 20,
    width: 20,
  },
  titleStyle: {
    margin: '12px 0',
    color: '#6A6A6A',
    fontWeight: 900,
    fontSize: 14,
  }
})
class WorkProfile extends Component {
  constructor() {
    super()
    this.state = {
      profiles: [],
      chartData: null,
      profileChart: null,
      percentages: null,
      comment: '',
      profilesDesviation: []
    }
    autobind(WorkProfile, this)
  }

  async componentDidMount() {
    const { getResults, match, contenders, event } = this.props
    const body = {}
    if (event === null) {
      body.id = match?.params.id
      body.form_id = match?.params.formId
      body.user_id = contenders.selected.user.id
    } else {
      body.id = event.event_id
      body.form_id = event.form_id
      body.user_id = contenders.selected.user.id
    }

    await getResults(body)
    this.getProfiles()
    this.setupChart()
    this.getProfilesDesviation()
    this.setupProfileChart()
    this.setupPercentages()

    const commentBody = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contenders.selected.user.id
    }
    const response = await getEvaluationComment(commentBody)
    if (response.data.status !== 'success') return null
    return this.setState({ comment: response.data.info.comment })
  }

  handleRedirect() {
    const { history, match } = this.props
    const newUrl = match.url.split('/')
    newUrl.pop()
    newUrl.push('support')
    const finalUrl = newUrl.join('/')
    history.push(finalUrl)
  }

  handleChangeComment(event) {
    const { target } = event
    this.setState({ comment: target.value })
  }

  handleSendComment() {
    const { contenders, event } = this.props
    const { comment } = this.state
    const userID = contenders.selected.user.id
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: userID,
      comment
    }
    editEvaluationComment(body)
  }

  getProfilesDesviation() {
    const { functional } = this.props
    const { result } = functional
    const data = Object.keys(profileColors).map(profileName => ({
      name: profileName,
      desv: result.result_detail[profileName]
    }))
    this.setState({ profilesDesviation: data })
  }

  getProfiles() {
    const { functional } = this.props
    const { result } = functional
    const test = result.competencies.map(competencie => (
      { name: competencie.name, values: result.quals_details[competencie.name] }
    ))
    this.setState({ profiles: test, comment: functional.result.comment })
  }

  setupChart() {
    const { functional } = this.props
    const { result } = functional
    const profilesData = {}
    result.profiles.forEach(profile => {
      profilesData[profile.name] = profile.competencies.map(c => c.ideal_score)
    })
    const competenciesLabels = []
    const competenciesValues = []
    result.competencies.forEach(c => {
      competenciesLabels.push(c.name)
      competenciesValues.push(c.total)
    })
    const chartData = {
      labels: competenciesLabels,
      datasets: [
        {
          type: 'line',
          label: 'Usuario',
          data: competenciesValues,
          backgroundColor: 'rgba(112, 112, 112, 0.7)',
          borderColor: '#707070',
        },
        {
          label: 'Administrativo',
          backgroundColor: 'rgba(31, 128, 149,0.8)',
          data: profilesData.Administrativo,
          type: 'bar'
        },
        {
          label: 'Atención al cliente',
          backgroundColor: 'rgba(251, 131, 104,0.8)',
          data: profilesData['Atención al cliente'],
          type: 'bar'
        },
        {
          label: 'Operario',
          backgroundColor: 'rgba(255, 179, 61, 0.8)',
          data: profilesData.Operario,
          type: 'bar'
        },
        {
          label: 'Servicios de apoyo',
          backgroundColor: 'rgba(56, 180, 169,0.8)',
          data: profilesData['Servicios de apoyo'],
          type: 'bar'
        },
      ]
    }
    this.setState({ chartData })
  }

  setupProfileChart() {
    const { functional } = this.props
    const { result } = functional
    const profileChart = Object.entries(result.result_detail).map(([profileName, value]) => (
      { label: profileName, value, color: profileColors[profileName] }
    ))
    this.setState({ profileChart })
  }

  setupPercentages() {
    const { functional } = this.props
    const { result } = functional
    const percentages = result.profile_adjust
    this.setState({ percentages })
  }

  render() {
    const { classes, functional, contenders, event } = this.props
    const { profiles,
      chartData,
      profilesDesviation,
      profileChart,
      percentages } = this.state
    return (
      <Paper className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h1" className={classes.title}>Resultados evaluación Funcional Diagnóstica</Typography>
          {event === null
          && (
            <Button variant="outlined" color="secondary" onClick={this.handleRedirect}>
              Ver Nivel de Apoyo
            </Button>
          )}
        </div>
        <div className={classes.sectionDivider}>
          <Typography variant="h1">Perfil de Competencias</Typography>
          <Divider className={classes.divider} />
        </div>
        <div className={classes.commentContainer}>
          <Typography variant="caption">
            Agregar comentarios sobre
            {' '}
            <span style={{ fontWeight: 600 }}>{contenders.selected.user.name}</span>
          </Typography>
          <GlobalComment event={event} />
          <div className={classes.readyButton}>
            <Button size="small" onClick={this.handleSendComment}>
              Listo
            </Button>
          </div>
        </div>
        <DownloadableSection id="header" name={`Resumen Evaluación Funcional ${contenders?.selected?.user?.name}`} margin>
          <div className={classes.finalSupportLevel}>
            <Typography variant="h2">Perfil sugerido</Typography>
            <div className={classes.supportLevel}>
              <Typography variant="h2">{functional.result.suggested_profile}</Typography>
            </div>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '40% auto', gap: 36 }}>
            <div>
              <div className={classes.titleContainer}>
                <Typography display="inline" className={classes.titleStyle}> Resultados de notas por perfil </Typography>
                <OnTouchTooltip title="Resultado del usuario en cada perfil en una escala de 1 al 7, cada perfil tiene competencias específicas que cambian según los resultados del usuario.">
                  <HelpOutline className={classes.button} />
                </OnTouchTooltip>
              </div>
              {profileChart && <BarChart name="quals-result" data={profileChart} options={barOptions} showTitle={false} />}
            </div>
            <div>
              <div className={classes.titleContainer}>
                <Typography display="inline" className={classes.titleStyle}> Porcentaje de ajuste a los perfiles </Typography>
                <OnTouchTooltip title="Porcentajes de ajuste según resultados de usuario en cada perfil, mientras mas cercano al 100% representa que es mas adecuado según lo evaluado.">
                  <HelpOutline className={classes.button} />
                </OnTouchTooltip>
              </div>
              {percentages && Object.entries(percentages).map(([profileName, percentage]) => (
                <ProfilePercentage
                  key={profileName}
                  title={profileName}
                  percentage={percentage}
                  barColor={profileColors[profileName]}
                />
              ))}
            </div>
          </div>
        </DownloadableSection>
        {chartData && (
          <DownloadableSection id="bottom" name={`Competencias vs resultado de ${contenders?.selected?.user?.name}`} margin>
            <div style={{ width: '100%' }}>
              <div className={classes.titleContainer} style={{ margin: '10px 0 0 0', paddingTop: 32 }}>
                <Typography display="inline" className={classes.titleStyle}> Competencias por perfiles vs Resultados de usuario </Typography>
                <OnTouchTooltip title="Gráfico donde se compara el resultado del usuario (línea gris) con los resultados esperados por perfil en cada competencia. Si el resultado del usuario esta sobre la barra en alguna competencia, significa que el usuario necesita MAS apoyo del que se espera en esa competencia en ese perfil.  De lo contrario, si la línea del usuario esta bajo alguna barra se debe interpretar como que el usuario necesita menos apoyo del necesario para esa competencia, lo cuál hace que el usuario sea mas adhoc para ese perfil. ">
                  <HelpOutline className={classes.button} />
                </OnTouchTooltip>
              </div>
              <MixedChart name="mixed" data={chartData} options={options} showTitle={false} />
            </div>
          </DownloadableSection>
        )}
        <div className={classes.titleContainer} style={{ margin: '0 0 10px 0' }}>
          <Typography display="inline" className={classes.titleStyle}>Comparación de Perfiles</Typography>
          <OnTouchTooltip title="Tabla comparativa de los resultados de trabajador por competencia y perfil. Se usa una escala de 1 al 7.">
            <HelpOutline className={classes.button} />
          </OnTouchTooltip>
        </div>
        <ProfilesTable data={profiles} desviations={profilesDesviation} />
      </Paper>
    );
  }
}

WorkProfile.propTypes = {
  classes: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  getResults: propTypes.func.isRequired,
  functional: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  event: propTypes.object
}

WorkProfile.defaultProps = {
  event: null
}

const mapStateToProps = state => ({
  functional: state.functional,
  contenders: state.contenders,
})

const mapDispatchToProps = dispatch => ({
  getResults: body => dispatch(getFunctionalResultsAction(body)),
})
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(WorkProfile))
)
