import { Typography } from '@material-ui/core'
import React, { Component } from 'react'

export default class Thanks extends Component {
  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1">
          ¡Muchas gracias por tus respuestas!
        </Typography>
      </div>
    )
  }
}
