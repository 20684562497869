import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Typography, Tooltip } from '@material-ui/core'

class OrganizationCell extends Component {
  render() {
    const { value, complete } = this.props
    return (
      <Tooltip title={value}>
        <div style={{ display: 'flex', alignItems: 'center', width: 250 }}>
          <div style={{ width: 32, height: 32, borderRadius: '50%', marginRight: 32, overflow: 'hidden' }}>
            <img src={complete.organization_photo || '/noimage.png'} alt="img" style={{ width: 32 }} />
          </div>
          <Typography
            variant="subtitle1"
            style={{ fontSize: 13, color: '#3E3E3E', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {value}

          </Typography>
        </div>
      </Tooltip>
    )
  }
}

OrganizationCell.propTypes = {
  value: propTypes.string,
  complete: propTypes.object
}

OrganizationCell.defaultProps = {
  value: '',
  complete: {}
}

export default OrganizationCell
