import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import IndicatorChart from '../../../../Results/Individuals/Interviews/IndicatorChart'

const style = () => ({
  answer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  chart: {
    minWidth: 400,
    maxWidth: 400
  },
  content: {
    flexGrow: 1,
    marginLeft: 12
  },
  container: {
    margin: 12
  }
})

const steps = [
  { label: 'Mal' },
  { label: 'Regular' },
  { label: 'Buena' },
  { label: 'Excelente' },
]

const options = ['No observado'].concat(steps.map(step => step.label))

class InterviewAnswerElement extends Component {
  render() {
    const { classes, value, title, comment, name } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="subtitle1">{title}</Typography>
        <div className={classes.answer}>
          <div className={classes.chart}>
            <IndicatorChart options={options} body={value} fullWidth name={name} />
          </div>
          <div className={classes.content}>
            <Typography variant="subtitle2">
              {comment}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

InterviewAnswerElement.propTypes = {
  classes: propTypes.object.isRequired,
  name: propTypes.object.isRequired,
  title: propTypes.string,
  comment: propTypes.string,
  value: propTypes.object,
}

InterviewAnswerElement.defaultProps = {
  title: 'Titulo generico',
  comment: 'No hay comentarios',
  value: []
}
export default withStyles(style)(InterviewAnswerElement)
