function getRealValue(answer, order, evaluation) {
  const baseOrder = [1, 2, 3, 4]
  const reverseOrder = [4, 3, 2, 1]
  if (order === 0) return baseOrder[answer - 1]
  if (order === 1) return reverseOrder[answer - 1]
  if (order === 2 && evaluation === 0) return baseOrder[answer - 1]
  return reverseOrder[answer - 1]
}

function calculateAverageAndResults(group, type) {
  const mainForm = group[0][`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`].empty
  const mainKeys = Object.keys(mainForm).filter(key => key !== 'empty')
  const results = { average: 0 }
  const totals = {}
  mainKeys.forEach(key => {
    results[key] = 0
    totals[key] = 0
  })
  group.forEach((evaluation, index) => {
    if (index >= 2) return false
    const form = evaluation[`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`]?.empty
    const keys = Object.keys(form).filter(k => k !== 'empty')
    keys.forEach(key => {
      const questions = mainForm[key]
      let tempValue = 0
      questions.forEach(question => {
        const answer = question?.answers[0]?.body?.answer
        if (!answer) return null
        const readOrder = question.read_order || 0
        const realValue = getRealValue(answer, readOrder, index)
        tempValue += parseInt(realValue, 10)
        return true
      })
      totals[key] = questions.length * 4
      results[key] += tempValue
      results.average += results[key]
    })
    return true
  })

  // Normalize
  mainKeys.forEach(key => {
    results[key] = Math.round((Math.round(results[key] / 2) * 100) / totals[key])
  })
  results.average = Math.round(results.average / (2 * mainKeys.length))
  return results
}

export default calculateAverageAndResults
