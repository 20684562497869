/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { Button, Dialog, IconButton, TextField, Typography, withStyles, Radio } from '@material-ui/core'
import propTypes from 'prop-types'
import { Close, Edit, LocalHospital } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { connect } from 'react-redux'
import Select from '../../../../Shared/Fields/Select'
import autobind from '../../../../Utils/autobind'
import { editContenderAction, editContenderContactAction, editContenderPhotoAction } from '../../../../Actions/Contenders'
import { getAllCommunes } from '../../../../API/Communes'

const style = theme => ({
  dialog: {
    display: 'flex',
    position: 'relative'
  },
  title: {
    fontWeight: 600,
    marginBottom: 12
  },
  formData: {
    padding: 12,
    paddingLeft: 18
  },
  photoAndMenu: {
    background: theme.palette.grey.light
  },
  img: {
    borderRadius: '50%'
  },
  imgContainer: {
    marginTop: 52,
    textAlign: 'center',
    padding: 12,
    position: 'relative',
  },
  buttonMenu: {
    padding: 12,
    margin: '12px 0',
    cursor: 'pointer'
  },
  input: {
    margin: '0 0 12px 0',
    width: 300
  },
  submitButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: 12
    },
    marginTop: 24
  },
  closeButton: {
    position: 'absolute',
    top: 6,
    right: 6
  },
  subtitle: {
    fontWeight: 600
  },
  hidden: {
    display: 'none'
  },
  centerIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12
  },
  iconEmergency: {
    color: '#f21231',
    height: 20,
    width: 20,
  }
})

function simulateClick() {
  document.getElementById('photo-input').click()
}

class EditDialog extends Component {
  constructor() {
    super()
    this.state = {
      selected: 0,
      params: {},
      communes: []
    }
    autobind(EditDialog, this)
  }

  async componentDidMount() {
    const { contender } = this.props
    const { contacts } = contender
    const newParams = {
      name: contender.user.name,
      email: contender.user.email,
      rut: contender.user.rut,
      commune_id: contender.user.commune_id,
      gender: contender.user.gender,
      date_birth: contender.user.date_birth || moment(new Date()).format('YYYY-MM-DD'),
      phone: contender.user.phone,
      has_certificate: contender.contender.has_certificate ? '1' : '0',
      certificate_comment: contender.contender.certificate_comment,
      address: contender.user.address,
      base_name: contacts[0] ? contacts[0].name : '',
      base_email: contacts[0] ? contacts[0].email : '',
      base_phone: contacts[0] ? contacts[0].phone : '',
      base_relation: contacts[0] ? contacts[0].relation : '',
      complementary_name: contacts[1] ? contacts[1].name : '',
      complementary_email: contacts[1] ? contacts[1].email : '',
      complementary_phone: contacts[1] ? contacts[1].phone : '',
      complementary_relation: contacts[1] ? contacts[1].relation : '',
    }

    const communeResponse = await getAllCommunes()
    const communes = communeResponse?.data?.status === 'success' ? communeResponse.data.info : []
    this.setState({ params: newParams, communes })
  }

  handleSelectMenu(value) {
    return () => {
      this.setState({ selected: value })
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleSelectFile(event) {
    const { contender, editContenderPhoto, onClose } = this.props
    const { target } = event
    const file = target.files[0]
    const fileName = file.name
    const body = new FormData()
    body.append('file', file)
    body.append('file_name', fileName)
    body.append('id', contender.contender.id)
    editContenderPhoto(body, contender.contender.id)
    onClose()
  }

  handleChangeDate(value) {
    const { params } = this.state
    params.date_birth = value.format('YYYY-MM-DD')
    this.setState({ params })
  }

  handleSave() {
    const { contender, editContender, onClose } = this.props
    const { params } = this.state
    const body = {
      ...params,
      has_certificate: params.has_certificate === '0',
      id: contender.contender.id,
    }
    editContender(body)
    onClose()
  }

  handleSaveContact() {
    const { editContenderContact, contender, onClose } = this.props
    const { params } = this.state
    const body = {
      ...params,
      id: contender.contender.id
    }
    editContenderContact(body)
    onClose()
  }

  renderAccountDetails() {
    const { params, communes } = this.state
    const { classes, onClose } = this.props
    const communeOptions = communes.map(commune => ({ label: commune.name, value: commune.id }))
    return (
      <div className={classes.formData}>
        <Typography variant="h2" className={classes.title}>Detalles de cuenta</Typography>
        <div className={classes.input}>
          <Typography variant="subtitle2">Nombre</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            name="name"
            onChange={this.handleChange}
            placeholder="Nombre"
            value={params.name}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Género</Typography>
          <Select
            onChange={this.handleChange}
            complete={{ options: ['Masculino', 'Femenino'], name: 'gender' }}
            value={params.gender}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Fecha de nacimiento</Typography>
          <DatePicker
            // disableToolbar
            autoOk
            // disablePast
            fullWidth
            format="D [de] MMMM [del] YYYY"
            style={{ background: '#00000017', }}
            name="date_birth"
            onChange={this.handleChangeDate}
            value={params.date_birth}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Rut</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            name="rut"
            onChange={this.handleChange}
            placeholder="Rut"
            value={params.rut}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="body2" className={classes.greyDark}>
            Certificado de discapacidad
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 12 }}>
              <Radio
                value="0"
                checked={params.has_certificate === '0'}
                name="has_certificate"
                onChange={this.handleChange}
              />
              <Typography variant="body2" className={classes.greyDark}>
                Sí
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Radio
                value="1"
                checked={params.has_certificate === '1'}
                name="has_certificate"
                onChange={this.handleChange}
              />
              <Typography variant="body2" className={classes.greyDark}>
                No
              </Typography>
            </div>
          </div>
        </div>
        {params.has_certificate === '1' && (
          <div className={classes.input}>
            <Typography variant="subtitle2">Comentarios certificado</Typography>
            <TextField
              fullWidth
              inputProps={{ style: { padding: '6px 0 7px 6px' } }}
              InputProps={{ style: { borderRadius: 0 } }}
              variant="filled"
              name="certificate_comment"
              onChange={this.handleChange}
              placeholder="Escribir comentario..."
              value={params.certificate_comment}
            />
          </div>
        )}
        <div className={classes.input}>
          <Typography variant="subtitle2">Mail</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            name="email"
            onChange={this.handleChange}
            placeholder="Mail"
            value={params.email}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Dirección</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            name="address"
            onChange={this.handleChange}
            placeholder="Direción"
            value={params.address}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Comuna</Typography>
          <Select
            fullWidth
            complete={{ name: 'commune_id', options: communeOptions }}
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            name="commune_id"
            onChange={this.handleChange}
            placeholder="Comuna"
            value={params.commune_id}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Celular</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            name="phone"
            onChange={this.handleChange}
            placeholder="Celular"
            value={params.phone}
          />
        </div>
        <div className={classes.submitButtons}>
          <Button onClick={onClose}>
            Cancelar
          </Button>
          <Button color="primary" variant="contained" onClick={this.handleSave}>
            Guardar
          </Button>
        </div>
      </div>
    )
  }

  renderContactsForm() {
    const { classes, onClose } = this.props
    const { params } = this.state
    return (
      <div className={classes.formData}>
        <Typography variant="h2" className={classes.title}>Contactos del Usuario</Typography>
        <div className={classes.inline}>
          <Typography variant="body2" className={classes.subtitle} color="primary">Persona de contacto</Typography>
          <LocalHospital className={classes.iconEmergency} />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Nombre</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            key="base_name"
            name="base_name"
            onChange={this.handleChange}
            placeholder="Nombre"
            value={params.base_name}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Relación</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            key="base_relation"
            name="base_relation"
            onChange={this.handleChange}
            placeholder="Relación con el trabajador"
            value={params.base_relation}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Mail</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            key="base_email"
            name="base_email"
            onChange={this.handleChange}
            placeholder="Mail"
            value={params.base_email}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Celular</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            key="base_phone"
            name="base_phone"
            onChange={this.handleChange}
            placeholder="Celular"
            value={params.base_phone}
          />
        </div>
        <Typography variant="body2" className={classes.subtitle} color="primary">Persona de contacto complementario</Typography>
        <div className={classes.input}>
          <Typography variant="subtitle2">Nombre</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            key="complementary_name"
            name="complementary_name"
            onChange={this.handleChange}
            placeholder="Nombre"
            value={params.complementary_name}
            disabled={!params.base_name}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Relación</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            key="complementary_relation"
            name="complementary_relation"
            onChange={this.handleChange}
            placeholder="Relación con el trabajador"
            value={params.complementary_relation}
            disabled={!params.base_name}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Mail</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            key="complementary_email"
            name="complementary_email"
            onChange={this.handleChange}
            placeholder="Mail"
            value={params.complementary_email}
            disabled={!params.base_name}
          />
        </div>
        <div className={classes.input}>
          <Typography variant="subtitle2">Celular</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            key="complementary_phone"
            name="complementary_phone"
            onChange={this.handleChange}
            placeholder="Celular"
            value={params.complementary_phone}
            disabled={!params.base_name}
          />
        </div>
        <div className={classes.submitButtons}>
          <Button onClick={onClose}>
            Cancelar
          </Button>
          <Button color="primary" variant="contained" onClick={this.handleSaveContact}>
            Guardar
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { classes, open, onClose, contender } = this.props;
    const { selected } = this.state
    return (
      <Dialog className={classes.container} open={open} onClose={onClose}>
        <div className={classes.dialog}>
          <div className={classes.photoAndMenu}>
            <input type="file" onChange={this.handleSelectFile} className={classes.hidden} id="photo-input" />
            <div className={classes.imgContainer}>
              <img src={contender.user.photo || '/noimage.png'} height="128px" width="128px" alt="fotoperfil" className={classes.img} onClick={simulateClick} />
              <IconButton className={classes.centerIcon} onClick={simulateClick}>
                <Edit />
              </IconButton>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="caption">Imágen formato 1x1</Typography>
            </div>
            <Typography variant="h2" onClick={this.handleSelectMenu(0)} className={classes.buttonMenu} style={{ background: selected === 0 ? 'white' : 'unset' }}>Detalles de cuenta</Typography>
            <Typography variant="h2" onClick={this.handleSelectMenu(1)} className={classes.buttonMenu} style={{ background: selected === 1 ? 'white' : 'unset' }}>Contactos de trabajador</Typography>
          </div>
          {selected === 0 ? this.renderAccountDetails() : this.renderContactsForm()}
          <div className={classes.closeButton}>
            <IconButton size="small" onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </div>
      </Dialog>
    );
  }
}

EditDialog.propTypes = {
  classes: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func,
  editContender: propTypes.func.isRequired,
  editContenderContact: propTypes.func.isRequired,
  editContenderPhoto: propTypes.func.isRequired,
}

EditDialog.defaultProps = {
  open: false,
  onClose: undefined
}

const mapDispatchToProps = dispatch => ({
  editContender: body => dispatch(editContenderAction(body)),
  editContenderContact: body => dispatch(editContenderContactAction(body)),
  editContenderPhoto: (body, id) => dispatch(editContenderPhotoAction(body, id))
})

export default connect(null, mapDispatchToProps)(withStyles(style)(EditDialog))
