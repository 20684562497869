/* eslint-disable import/prefer-default-export */
import instance from 'Utils/client'

export const getPrograms = () => instance({
  method: 'get',
  url: 'programs'
})

export const getPrgramDataResume = body => instance({
  method: 'get',
  url: `evaluations/${body.name}`,
  params: body
})
