import React, { Component } from 'react'
import { withStyles, Paper } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import DayList from './DayList'
import Header from '../../../History/HistoryHeader'
import autobind from '../../../../Utils/autobind'
import { loadUserHistoriesAction, searchUserHistoriesAction } from '../../../../Actions/History'
import setSearchQueryAction from '../../../../Actions/Search'

const style = () => ({
  container: {},
})

class HistoryTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 0,
    }
    autobind(HistoryTab, this)
  }

  componentDidMount() {
    const { loadUserHistories, setSearchQuery, contender } = this.props
    setSearchQuery()
    loadUserHistories(contender.user.id)
  }

  handleChangeDate(option) {
    if (option === 'add') {
      this.setState(prevState => ({ offset: prevState.offset + 1 }))
    } else {
      this.setState(prevState => ({ offset: prevState.offset - 1 }))
    }
  }

  handleTodayClick() {
    this.setState({ offset: 0 })
  }

  render() {
    const { classes, histories, search, searchUserHistories, contender } = this.props
    const { offset } = this.state
    const currentDate = moment().add(offset, 'weeks')
    const info = search.query ? histories.search : histories.selected
    return (
      <Paper className={classes.container}>
        <Header
          currentDate={currentDate}
          changeDate={this.handleChangeDate}
          handleToday={this.handleTodayClick}
          searchFunc={searchUserHistories(contender.user.id)}
        />
        <DayList backgroundStyle={1} info={info} offset={offset} />
      </Paper>
    )
  }
}

HistoryTab.propTypes = {
  classes: propTypes.object.isRequired,
  loadUserHistories: propTypes.func.isRequired,
  searchUserHistories: propTypes.func.isRequired,
  histories: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  setSearchQuery: propTypes.func.isRequired,
  search: propTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user.current,
  histories: state.history,
  contender: state.contenders.selected,
  search: state.search
})

const mapDispatchToProps = dispatch => ({
  loadUserHistories: id => dispatch(loadUserHistoriesAction(id)),
  setSearchQuery: query => dispatch(setSearchQueryAction(query)),
  searchUserHistories: id => query => dispatch(searchUserHistoriesAction(id, query))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(HistoryTab))
