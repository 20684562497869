function areasFormat(value) {
  if (!value.length) return 'No ingresado'
  const finalString = value.map(area => area.name).join(', ')
  return `${finalString.slice(0, 40)}${finalString.length > 40 ? '...' : ''}`
}

export const handlerInfo = [
  { name: 'Nombre', label: 'name', max_width: 150 },
  { name: 'Área(s)', label: 'areas', format: areasFormat },
  { name: 'Rol', label: 'role' },
  // { name: 'Usuarios a cargo', label: 'users_on_charge' },
]

export const handlerFilter = [
  { name: 'Rol', label: 'commune', getter: dataObj => dataObj.role },
]
