import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Typography, withStyles, IconButton, Tooltip, TableSortLabel, TextField, Menu, MenuItem, Button } from '@material-ui/core'
import { Search, KeyboardArrowDown, CancelRounded, KeyboardArrowRight, KeyboardArrowLeft, ArrowDownward } from '@material-ui/icons'
import Conditional from './Conditional'
import autobind from '../Utils/autobind'
import { compareValuesByKey } from '../Utils/functions'

const style = theme => ({
  row: {
    transition: 'all 0.2s, ease-in-out',
    transform: 'scale(1)',
    '&:hover': {
      background: theme.palette.indigo.lighter
    },
    '& div.hoverMenu': {
      position: 'absolute',
      right: 0,
      top: 0,
      opacity: 0,
      height: '100%',
      background: `linear-gradient(90deg, transparent 0%, ${theme.palette.indigo.lighter} 30%)`,
      transition: 'all 0.2s, ease-in-out',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '0 12px',
      paddingLeft: 32
    },
    '&:hover div.hoverMenu': {
      opacity: 1,
      visibility: 'visible',
    }
  },
  actionMenu: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    background: `linear-gradient(90deg, transparent 0%, ${theme.palette.indigo.lighter} 30%)`,
    transition: 'all 0.2s, ease-in-out',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 12px',
    paddingLeft: 32
  },
  hiddenMenu: {
    position: 'absolute',
    right: 0,
    top: 0,
    background: '#fff',
    opacity: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 12px',
    paddingLeft: 32
  },
  topBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '3px 0 6px 0',
    '&>*': {
      margin: '0 12px',
    }
  },
  dropdown: {
    background: theme.palette.grey.light,
    borderRadius: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 13
  },
  sortable: {
    paddingTop: 0,
    borderRadius: 0,
    paddingBottom: 0,
    fontSize: 13,
    height: 24
  },
  tag: {
    background: '#48A4B0',
    padding: '3px 9px',
    borderRadius: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 12
  },
  filterResult: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: 12
  }

})

class GeneralTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderBy: '',
      orderOn: 'desc',
      word: '',
      activeDropdown: '',
      filterTags: [],
      options: {},
      left: 0,
      right: props.disablePagination ? props.data.length : 1,
      step: 1
    }
    autobind(GeneralTable, this)
  }

  componentDidMount() {
    // Set options to filter dropdowns
    const { data, filters, disablePagination, name, scrollable, onReachBottom } = this.props
    const options = {}
    filters.forEach(filter => {
      const result = []
      data.forEach(element => {
        const elementData = filter.getter(element)
        if (result.indexOf(elementData) === -1) {
          result.push(elementData)
        }
      })
      options[filter.label] = result
    })
    this.setState({ options })
    if (!disablePagination) {
      this.getHeights()
    }

    const table = document.getElementById(`table-${name}`)
    if (scrollable) {
      table.addEventListener('scroll', () => {
        if (table.scrollHeight - table.scrollTop === table.clientHeight) {
          onReachBottom()
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { disablePagination, data } = this.props
    if (prevProps.data.length !== data.length) {
      if (disablePagination) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ right: data.length })
      }
    }
  }

  handleSort(label) {
    return () => {
      const { orderBy, orderOn } = this.state
      if (label === orderBy) {
        this.setState({ orderBy: label, orderOn: orderOn === 'desc' ? 'asc' : 'desc' })
      } else {
        this.setState({ orderBy: label, orderOn: 'desc' })
      }
    }
  }

  handleSearch(event) {
    const { target } = event
    this.setState({ word: target.value })
  }

  handleClose() {
    this.setState({ anchorEl: null, activeDropdown: '' })
  }

  handleAddFilter(name, label, getter) {
    return () => {
      const { filterTags, step } = this.state
      const { disablePagination, data } = this.props
      const newFilters = [...filterTags]
      const isFound = newFilters.find(filter => filter.name === name && filter.label === label)
      if (!isFound) {
        newFilters.push({ name, label, getter })
      }
      this.setState({
        filterTags: newFilters, left: 0, right: disablePagination ? data.length : step
      })
      this.handleClose()
    }
  }

  handleRemoveFilter(name, label) {
    return () => {
      const { filterTags, step } = this.state
      const { disablePagination, data } = this.props
      const newFilters = [...filterTags]
      const resultFilters = newFilters
        .filter(filter => !(filter.name === name && filter.label === label))
      this.setState({
        filterTags: resultFilters, left: 0, right: disablePagination ? data.length : step
      })
      this.handleClose()
    }
  }

  handleOpenDropdown(event, label) {
    this.setState({ anchorEl: event.target, activeDropdown: label })
  }

  handleNextPage(option) {
    return () => {
      const processedData = this.filter(this.search(this.sortedElements()))
      const { left, right, step } = this.state
      if (option > 0) {
        if (right < processedData.length) {
          this.setState({ left: left + step, right: right + step })
        }
      } else if (left > 0) {
        this.setState({ left: left - step, right: right - step })
      }
    }
  }

  getHeights() {
    const { name } = this.props
    const headers = document.getElementById(`head-${name}`)
    const body = document.getElementById(`body-${name}`)
    const totalHeight = window.innerHeight - 24 // padding
    if (headers && body && body.childNodes.length > 0) {
      const rowHeader = headers.childNodes[0].getBoundingClientRect()
      const rowBody = body.childNodes[0].getBoundingClientRect()
      const topBar = document.getElementById(`top-${name}`).getBoundingClientRect()
      const bottomBar = document.getElementById(`bottom-${name}`).getBoundingClientRect()
      const extraSpace = 12
      const availableHeight = totalHeight - rowHeader.height
        - (topBar.height + 9) - (bottomBar.height + 12) - extraSpace
      const amountOfRowsThatFits = Math.floor(availableHeight / rowBody.height)
      this.setState({ step: amountOfRowsThatFits, right: amountOfRowsThatFits })
    }
  }

  search(data) {
    const { info, name } = this.props;
    const target = document.getElementById(`searchBar-${name}`)
    if (target && target.value !== '') {
      const result = data.filter(cell => {
        let bool = false;
        info.forEach(element => {
          const labels = element.label.split('&');
          if (labels.length > 1) {
            if (
              String(cell[labels[0]][labels[1]])
                .toLocaleLowerCase()
                .includes(target.value.toLocaleLowerCase())
            ) {
              bool = true;
            }
          } else if (
            String(cell[labels[0]])
              .toLocaleLowerCase()
              .includes(target.value.toLocaleLowerCase())
          ) {
            bool = true;
          }
        });
        return bool;
      });
      return result;
    }
    return data;
  }

  filter(data) {
    const { filterTags } = this.state
    let temp = data
    filterTags.forEach(tag => {
      temp = temp.filter(element => tag.getter(element) === tag.name)
    })
    return temp
  }

  sortedElements() {
    const { data } = this.props
    const { orderBy, orderOn } = this.state
    const sortedData = data.sort(compareValuesByKey(orderBy, orderOn))
    return sortedData
  }

  renderFilters() {
    const { filters, classes } = this.props
    const { activeDropdown, anchorEl, options } = this.state
    return filters.map(filter => {
      const dropdownOptions = options[filter.label] || []
      return (
        <>
          <Button
            className={classes.dropdown}
            onClick={e => this.handleOpenDropdown(e, filter.label)}
          >
            <Typography variant="body2" style={{ marginRight: 6, fontSize: 13 }}>{filter.name}</Typography>
            <KeyboardArrowDown style={{ paddingLeft: 6 }} />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={activeDropdown === filter.label}
            onClose={this.handleClose}
          >
            {dropdownOptions.map(option => (
              <MenuItem
                key={option}
                onClick={this.handleAddFilter(option, filter.label, filter.getter)}
              >
                {option}

              </MenuItem>
            ))}

          </Menu>
        </>
      )
    })
  }

  renderSortings() {
    const { sortings, classes } = this.props
    const { orderBy, orderOn } = this.state
    return sortings.map(sortableHeader => (
      <>
        <Button
          className={classes.sortable}
          onClick={this.handleSort(sortableHeader.label)}
        >
          <Typography variant="body2" style={{ marginRight: 6, fontSize: 13 }} color="primary">{sortableHeader.name}</Typography>
          <Conditional condition={orderBy === sortableHeader.label}>
            <ArrowDownward
              color="primary"
              style={{
                height: 18,
                width: 18,
                transform: orderOn === 'desc' ? '' : 'rotate(180deg)',
                transition: 'all 0.1s linear'
              }}
            />
          </Conditional>
        </Button>
      </>
    ))
  }

  renderFilterTags() {
    const { filterTags } = this.state
    const { classes } = this.props
    return filterTags.map(tag => (
      <div className={classes.tag}>
        <Typography variant="body2" style={{ color: '#fff', fontWeight: 600 }}>{tag.name}</Typography>
        <IconButton size="small" style={{ marginLeft: 6 }} onClick={this.handleRemoveFilter(tag.name, tag.label)}>
          <CancelRounded style={{ color: '#fff', height: 16, width: 16 }} />
        </IconButton>
      </div>
    ))
  }

  renderActions(row) {
    const { actions } = this.props
    return actions.map((action, index) => (
      <Tooltip title={action.name} key={index.toString()}>
        <IconButton onClick={e => {
          e.stopPropagation()
          action.action(row)(e)
        }}
        >
          <action.icon row={row} />
        </IconButton>
      </Tooltip>
    ))
  }

  renderTableHead() {
    const { info, actions } = this.props
    const { orderBy, orderOn } = this.state
    const row = info.map((header, index) => (
      <TableCell key={index.toString()}>
        <TableSortLabel
          direction={orderOn}
          active={header.label === orderBy}
          onClick={this.handleSort(header.label)}
        >
          <Typography variant="subtitle2">{header.name}</Typography>
        </TableSortLabel>
      </TableCell>
    ))
    return (
      <TableRow>
        {row}
        {actions.length > 0 && <TableCell />}
      </TableRow>
    )
  }

  renderTableBody() {
    const { info, classes, responsive, mainAction, actions } = this.props
    const { left, right } = this.state
    const processedData = this.filter(this.search(this.sortedElements())).slice(left, right)
    return processedData.map((rowElement, index) => {
      const row = info.map((header, index2) => {
        let value = rowElement[header.label]
        if (header.format) {
          value = header.format(value, rowElement)
        }
        let rendered = header.render ? <header.render value={value} complete={rowElement} /> : false
        if (responsive) {
          rendered = header.responsive ? false : rendered
        }
        return (
          <TableCell key={index2.toString()} style={{ maxWidth: header.max_width ? header.max_width : 'unset' }}>
            <Conditional condition={rendered !== false} hasElse>
              <>{rendered}</>
              <Tooltip title={<Typography style={{ color: 'whitesmoke' }} variant="subtitle1">{value}</Typography>}>
                <Typography
                  variant="subtitle1"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {value}

                </Typography>
              </Tooltip>
            </Conditional>
          </TableCell>
        )
      })
      const toReturn = (
        <TableRow
          className={classes.row}
          key={(index * 10).toString()}
          onClick={mainAction(rowElement)}
        >
          {row}
          {actions.length > 0
            && (
              <TableCell style={{ textAlign: 'end' }}>
                {this.renderActions(rowElement)}
              </TableCell>
            )}
        </TableRow>
      )
      return toReturn
    })
  }

  render() {
    const {
      name,
      classes,
      disablePagination,
      disableFilters,
      title,
      fixed,
      scrollable
    } = this.props
    const processedData = this.filter(this.search(this.sortedElements()))
    const { filterTags } = this.state
    const { word, left, right, step } = this.state
    return (
      <>
        <Conditional condition={!disableFilters}>
          <div id={`top-${name}`}>
            <div className={classes.topBar}>
              {this.renderFilters()}
              {this.renderSortings()}
              <TextField
                id={`searchBar-${name}`}
                name="searchbar"
                placeholder="Buscar... "
                onChange={this.handleSearch}
                InputProps={{
                  endAdornment: <Search />
                }}
                value={word}
              />
            </div>
            <div style={{ height: 28, marginBottom: 12 }}>
              <Conditional condition={filterTags.length > 0}>
                <div className={classes.filterResult}>
                  <Typography variant="subtitle2" color="primary" style={{ marginRight: 12 }}>{'Resultado: '}</Typography>
                  {this.renderFilterTags()}
                </div>
              </Conditional>
            </div>
          </div>
        </Conditional>
        <Conditional condition={title}>
          <div style={{ textAlign: 'left', margin: '24px 0' }}>
            <Typography variant="h1">
              {title}
            </Typography>
          </div>
        </Conditional>
        <Paper style={{ maxHeight: scrollable ? 400 : '100%', overflow: 'auto', display: 'flex' }} onScroll={this.handleScrollBottom} id={`table-${name}`}>
          <Table style={{
            tableLayout: fixed ? 'fixed' : '',
          }}
          >
            <TableHead id={`head-${name}`}>{this.renderTableHead()}</TableHead>
            <TableBody id={`body-${name}`}>{this.renderTableBody()}</TableBody>
          </Table>
        </Paper>
        <Conditional condition={!disablePagination}>
          <Paper
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 12,
              padding: '0px 12px',
              boxSizing: 'border-box'
            }}
            id={`bottom-${name}`}
          >
            <Typography variant="body1">{`Página ${(left / step) + 1} de ${Math.ceil(processedData.length / step)}`}</Typography>
            <Typography variant="body1">{`${left + 1} - ${processedData.length < right ? processedData.length : right}`}</Typography>
            <div style={{ display: 'flex' }}>
              <IconButton onClick={this.handleNextPage(-1)}>
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton onClick={this.handleNextPage(1)}>
                <KeyboardArrowRight />
              </IconButton>
            </div>
          </Paper>
        </Conditional>
      </>
    )
  }
}

GeneralTable.propTypes = {
  classes: propTypes.object.isRequired,
  info: propTypes.arrayOf(propTypes.object),
  data: propTypes.arrayOf(propTypes.object),
  actions: propTypes.arrayOf(propTypes.object),
  filters: propTypes.arrayOf(propTypes.object),
  sortings: propTypes.arrayOf(propTypes.object),
  mainAction: propTypes.func,
  name: propTypes.string,
  disableFilters: propTypes.bool,
  disablePagination: propTypes.bool,
  title: propTypes.string,
  responsive: propTypes.bool,
  fixed: propTypes.bool,
  scrollable: propTypes.bool,
  onReachBottom: propTypes.func
}

GeneralTable.defaultProps = {
  info: [],
  data: [],
  actions: [],
  filters: [],
  sortings: [],
  name: 'table',
  disableFilters: false,
  disablePagination: false,
  responsive: false,
  title: undefined,
  fixed: false,
  scrollable: false,
  mainAction: () => () => { },
  onReachBottom: () => {}
}

export default withStyles(style)(GeneralTable)
