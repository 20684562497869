import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import SelectParticipantForm from './SelectParticipantForm'
import { loadEvent } from '../../API/Events'
import autobind from '../../Utils/autobind'
import LoaderDialog from '../../Shared/LoaderDialog'
import GencatFormContainer from './GencatFormContainer'

const style = () => ({
  container: {
    textAlign: 'start',
    margin: 24
  }
})

class FamiliarGencatForm extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      event: null,
      selectedUser: '',
      params: {},
      ready: false,
    }
    autobind(FamiliarGencatForm, this)
  }

  async componentDidMount() {
    const { match: { params: { id } } } = this.props
    this.setState({ loading: true })
    const response = await loadEvent({ id })
    const { data: { status, info: event } } = response
    if (status !== 'success') return this.setState({ loading: false })
    return this.setState({ loading: false, event })
  }

  handleChange(event) {
    const { target: { value, name } } = event
    const { params } = this.state
    params[name] = value
    this.setState({ params })
  }

  handleSelectUser(event) {
    const { target: { value } } = event
    this.setState({ selectedUser: value })
  }

  handleStart() {
    this.setState({ ready: true })
  }

  render() {
    const { classes } = this.props;
    const { loading, event, selectedUser, ready } = this.state
    return (
      <div className={classes.container}>
        <LoaderDialog open={loading} />
        {!ready && (
          <SelectParticipantForm
            event={event}
            onChange={this.handleSelectUser}
            onSubmit={this.handleStart}
            selected={selectedUser}
          />
        )}
        {ready && <GencatFormContainer event={event} selected={selectedUser} />}
      </div>
    );
  }
}

FamiliarGencatForm.propTypes = {
  classes: propTypes.object.isRequired,
  match: propTypes.object.isRequired
}

FamiliarGencatForm.defaultProps = {

}
export default withStyles(style)(FamiliarGencatForm)
