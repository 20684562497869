import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { Paper, Button, Typography } from '@material-ui/core'
import { ScreenRotation } from '@material-ui/icons'
import { withRouter } from 'react-router'
import autobind from '../Utils/autobind'
import { sendAnswersAction } from '../Actions/Events'
import AlwaysVisible from './AlwaysVisible'
import { getTotal } from '../Components/Gencat/utils'
import Conditional from './Conditional'
import FormWithGroups from '../Components/Gencat/FormWithGroups'
import FormContext from '../Components/Gencat/FormContext'
import { checkIfFinished } from './Fields/Utils'
import AutoSaver from './AutoSaver'

class StepFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
      groups: {},
      savedGroups: {},
      saved: {},
      comments: {},
      savedComments: {},
      actualKey: 0,
      isToSmall: window.innerWidth <= 500
    }

    autobind(StepFormContainer, this)
  }

  componentDidMount() {
    const { defaultKey } = this.props
    this.setState(prevState => ({ ...prevState, actualKey: defaultKey }))
    this.setAnsweredQuestions()
    const self = this
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 500) return self.setState({ isToSmall: true })
      return self.setState({ isToSmall: false })
    })
  }

  handleChange(event) {
    const { target } = event
    const { params, saved } = this.state
    const newSaved = { ...saved }
    const newParams = { ...params, ...newSaved }
    delete newSaved[target.name]
    if (newParams[target.name]) {
      newParams[target.name].answer = target.value
    } else {
      newParams[target.name] = { answer: target.value }
    }
    this.setState({ params: newParams, saved: newSaved })
  }

  handleGroupChange(event) {
    const { target } = event
    const { groups, savedGroups, saved, params } = this.state
    const newsavedGroups = { ...savedGroups }
    const newgroups = { ...groups, ...newsavedGroups }
    const newParams = { ...params }
    delete newsavedGroups[target.name]
    if (newgroups[target.name]) {
      newgroups[target.name].answer = target.value
      newParams[target.name.split('#')[0]] = params[target.name.split('#')[0]] || saved[target.name.split('#')[0]]
    } else {
      newgroups[target.name] = { answer: target.value }
      newParams[target.name.split('#')[0]] = params[target.name.split('#')[0]] || saved[target.name.split('#')[0]]
    }
    this.setState({ groups: newgroups, savedGroups: newsavedGroups, params: newParams })
  }

  handleSubchange(event) {
    const { target } = event
    const { params, saved } = this.state
    const newSaved = { ...saved }
    const newParams = { ...params, ...newSaved }
    const actualName = target.name.split('&')[0]
    delete newSaved[actualName]
    if (newParams[actualName].subquestions) {
      newParams[actualName].subquestions[target.name] = target.value
    } else {
      newParams[actualName] = {
        ...newParams[actualName], subquestions: { [target.name]: target.value }
      }
    }
    this.setState({ params: newParams, saved: newSaved })
  }

  handleGroupSubchange(event) {
    const { target } = event
    const { groups, savedGroups } = this.state
    const newsavedGroups = { ...savedGroups }
    const newgroups = { ...groups, ...newsavedGroups }
    const actualName = target.name.split('&')[0]
    delete newsavedGroups[actualName]
    if (newgroups[actualName].subquestions) {
      newgroups[actualName].subquestions[target.name] = target.value
    } else {
      newgroups[actualName] = {
        ...newgroups[actualName], subquestions: { [target.name]: target.value }
      }
    }
    this.setState({ groups: newgroups, savedGroups: newsavedGroups })
  }

  handleComment(event) {
    const { target } = event
    const { comments, savedComments, saved, params } = this.state
    const newComments = { ...comments }
    const newSavedComments = { ...savedComments }
    delete newSavedComments[target.name]
    newComments[target.name] = target.value

    const newParams = { ...params }
    const newSaved = { ...saved }

    newParams[target.name] = newSaved[target.name] || newParams[target.name]
    this.setState({ comments: newComments, savedComments: newSavedComments, params: newParams })
  }

  handleSave() {
    const { events, sendAnswers, eventId } = this.props
    const { params, saved, comments, savedComments, groups, savedGroups } = this.state
    const requestEventId = eventId || events.selected.event.id
    const newSaved = { ...saved, ...params }
    const newComments = { ...savedComments, ...comments }
    const newGroups = { ...savedGroups, ...groups }
    const answers = { ...params }
    const groupAnswers = { ...groups, ...savedGroups }
    const answersComments = { ...comments }
    const groupByGroups = {}
    Object.entries(groupAnswers).forEach(([groupAnswerName, answer]) => {
      const groupAnswerId = groupAnswerName.split('#')[0]
      if (!groupByGroups[groupAnswerId]) {
        groupByGroups[groupAnswerId] = {}
      }
      groupByGroups[groupAnswerId][groupAnswerName] = answer
    })
    if (Object.keys(answers).length > 0) {
      const body = Object.keys(answers).map(key => ({
        id: key,
        body: { ...answers[key], groups: groupByGroups[key] },
        comment: answersComments[key],
      }))
      this.setState({
        saved: newSaved,
        params: {},
        savedComments: newComments,
        comments: {},
        groups: {},
        savedGroups: newGroups
      })
      sendAnswers({ answers: body }, requestEventId)
      return true
    }
    return false
  }

  handleEnd(step) {
    return () => {
      this.handleSave()
      const { handleNextStep, endAction } = this.props
      if (endAction) {
        endAction()
      }
      handleNextStep(step)
    }
  }

  handleNextKey() {
    const { actualKey } = this.state
    this.handleSave()
    this.setState({ actualKey: actualKey + 1 })
    window.scrollTo({ top: 0 })
  }

  handlePrevKey() {
    const { actualKey } = this.state
    this.handleSave()
    this.setState({ actualKey: actualKey - 1 })
    window.scrollTo({ top: 0 })
  }

  getProgress() {
    const { form, shared, singleAnswer } = this.props
    const { params, saved } = this.state
    const answeredIds = Object.keys(params)
    const savedIds = Object.keys(saved)
    const total = getTotal(form)
    let answered = 0
    const keys = Object.keys(form)
    keys.forEach(key => {
      Object.keys(form[key]).forEach(subkey => {
        form[key][subkey].forEach(question => {
          const answer = singleAnswer
            ? question.answers[0]
            : question.answers.find(a => a.user_id === shared.selected)
          if (question.type !== 'title') {
            if (
              answer?.body
              || answeredIds.indexOf(answer?.id) !== -1
              || savedIds.indexOf(answer?.id) !== -1
            ) {
              answered += 1
            }
          }
        })
      })
    })
    return (answered * 100) / total
  }

  getTotal() {
    const { actualKey } = this.state
    const { form } = this.props
    const keys = Object.keys(form)
    let questions = 0
    if (form && form[keys[actualKey]]) {
      Object.keys(form[keys[actualKey]]).forEach(subkey => {
        const formQuestions = form[keys[actualKey]][subkey] || []
        questions += formQuestions?.filter(question => question.type !== 'title')?.length || 0
      })
    }
    return questions
  }

  setAnsweredQuestions() {
    const { events, form, canRenderWithoutEvent } = this.props
    const { selected } = events
    const { forms } = selected
    const { saved, savedComments } = this.state
    const newSaved = { ...saved }
    const newSavedComments = { ...savedComments }
    let newSavedGroups = {}
    if (forms || canRenderWithoutEvent) {
      Object.keys(form).forEach(criteriumKey => {
        Object.keys(form[criteriumKey]).forEach(criterium => {
          const questions = form[criteriumKey][criterium]
          questions.forEach(question => {
            const { answers } = question
            answers.forEach(answer => {
              if (answer.body !== null) {
                newSaved[answer.id] = answer.body
                if (answer.body.groups) {
                  newSavedGroups = { ...newSavedGroups, ...answer.body.groups }
                }
              }
              if (answer.comment !== null) {
                newSavedComments[answer.id] = answer.comment
              }
            })
          })
        })
      })
      this.setState({
        params: newSaved, saved: newSaved, comments: newSavedComments, savedGroups: newSavedGroups
      })
    }
  }

  checkAnswers() {
    const { shared, singleAnswer, match: { params: { contender_id: contenderId } } } = this.props
    const { params, actualKey, saved } = this.state
    const { form } = this.props
    const mix = { ...params, ...saved }
    const keys = Object.keys(form)
    let notAnswered = 0
    if (form && form[keys[actualKey]]) {
      Object.keys(form[keys[actualKey]]).forEach(subkey => {
        const questions = form[keys[actualKey]][subkey]
        questions.forEach(question => {
          const subquestions = question.subquestions ? [...question.subquestions] : []
          if (question.type === 'title') return 0

          const answer = singleAnswer
            ? question.answers[0]
            : question.answers.find(a => a.user_id === shared.selected)

          const valueFromParams = mix[answer?.id]

          let answerValue = ''
          let subquestionAnswers = {}
          const valueToUse = valueFromParams
          const isObject = typeof valueToUse === 'object'
          if (valueToUse !== undefined) {
            if (isObject) {
              answerValue = valueToUse.answer
            } else {
              answerValue = valueToUse
            }
            subquestionAnswers = valueToUse.subquestions
          }
          const isReady = checkIfFinished(
            question,
            answerValue,
            subquestions,
            subquestionAnswers,
            singleAnswer ? answer : question.answers.find(q => q.user_id === contenderId)
          )

          if (!isReady) {
            notAnswered += 1
          }
          return notAnswered
        })
      })
    }
    return notAnswered
  }

  render() {
    const { form,
      shared,
      title,
      disabled,
      message,
      singleAnswer,
      endButtonText,
      tooltips,
      disableNoObservation,
      endStep } = this.props
    const { params, saved, comments,
      savedComments, actualKey, groups, savedGroups, isToSmall } = this.state
    const leftAnswers = this.checkAnswers()
    const keys = Object.keys(form)
    const progress = this.getProgress()

    if (isToSmall) {
      return (
        <div>
          <Paper style={{ margin: 24, marginTop: 96, padding: 12 }}>
            <Typography variant="subtitle1" style={{ marginBottom: 12 }}>Por favor, para poder responder este formulario utilice el teléfono con orientación horizontal</Typography>
            <ScreenRotation />
          </Paper>
        </div>
      )
    }

    return (
      <div style={{ padding: 24 }}>
        <div style={{ position: 'fixed', top: 0, left: 0, background: '#48A4B0', width: `${progress}%`, height: 6, transition: 'all 0.5s linear' }} />
        <Paper style={{ maxWidth: 650, margin: 'auto', padding: '12px 48px', marginTop: 64 }} className="print-no-border">
          <AutoSaver onSave={this.handleSave} timeout={60000} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px 0' }}>
            <Typography variant="h1">{title}</Typography>
            <AlwaysVisible>
              <Typography variant="caption">{`${this.getTotal() - this.checkAnswers()} / ${this.getTotal()}`}</Typography>
            </AlwaysVisible>
            <Typography variant="subtitle2">{keys[actualKey]}</Typography>
          </div>
          <div>
            {message}
          </div>
          <FormContext.Provider value={{
            onChange: this.handleChange,
            onComment: this.handleComment,
            params,
            userId: shared.selected,
            onSubchange: this.handleSubchange,
            onGroupChange: this.handleGroupChange,
            onSubGroupChange: this.handleGroupSubchange,
            saved,
            comments,
            groups,
            savedGroups,
            savedComments,
            singleAnswer,
            tooltips,
            disableNoObservation
          }}
          >
            <FormWithGroups form={form[keys[actualKey]]} disabled={disabled} />
          </FormContext.Provider>
          <div className="no-print" style={{ display: 'flex', justifyContent: 'flex-end', padding: 12 }}>
            <Conditional condition={!disabled} hasElse>
              <div />
              <Button color="secondary" onClick={this.handleEnd(endStep)} style={{ marginRight: 12 }}>Inicio</Button>
            </Conditional>
            {actualKey > 0
              && <Button color="secondary" variant="outlined" onClick={this.handlePrevKey} style={{ marginRight: 6 }}>Volver</Button>}
            {actualKey < keys.length - 1
              ? <Button color="secondary" variant="contained" onClick={this.handleNextKey} disabled={leftAnswers > 0}>{leftAnswers > 0 ? `${leftAnswers} Preguntas restantes...` : 'Siguiente'}</Button>
              : <Button color="primary" variant="contained" onClick={this.handleEnd(endStep)} disabled={leftAnswers > 0}>{leftAnswers > 0 ? `${leftAnswers} Preguntas restantes...` : endButtonText}</Button>}
          </div>
        </Paper>
      </div>
    )
  }
}

StepFormContainer.propTypes = {
  form: propTypes.object.isRequired,
  shared: propTypes.object,
  sendAnswers: propTypes.func.isRequired,
  handleNextStep: propTypes.func.isRequired,
  title: propTypes.string,
  events: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  disabled: propTypes.bool,
  eventId: propTypes.string,
  canRenderWithoutEvent: propTypes.bool,
  message: propTypes.oneOfType([propTypes.node, propTypes.string]),
  singleAnswer: propTypes.bool,
  endStep: propTypes.number,
  defaultKey: propTypes.number,
  endAction: propTypes.func,
  endButtonText: propTypes.string,
  tooltips: propTypes.arrayOf(propTypes.object),
  disableNoObservation: propTypes.bool
}

StepFormContainer.defaultProps = {
  shared: {},
  title: '',
  disabled: false,
  message: '',
  eventId: undefined,
  canRenderWithoutEvent: false,
  singleAnswer: false,
  endStep: 0,
  defaultKey: 0,
  endAction: null,
  endButtonText: 'Terminar',
  tooltips: [],
  disableNoObservation: false
}

const mapStateToProps = state => ({
  events: state.events
})

const mapDispatchToProps = dispatch => ({
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepFormContainer))
