import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

class Unauthorized extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h1">
          Oops! Parece que no tienes los permisos para acceder aquí
          <br />
          <br />
          Comunícate con tu director(a) de área
        </Typography>
      </div>
    )
  }
}

Unauthorized.propTypes = {
  classes: propTypes.object.isRequired
}

export default withStyles(style)(Unauthorized)
