import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import OnTouchTooltip from '../OnTouchTooltip'

class TitleInput extends Component {
  render() {
    const { complete } = this.props
    const tooltipMessage = complete?.tooltip || null
    return (
      <div style={{ gridArea: `area-${complete.id}` }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="subtitle1" style={complete.style}>{complete.text}</Typography>
          {tooltipMessage && (
            <OnTouchTooltip title={tooltipMessage}>
              <HelpOutline style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </OnTouchTooltip>
          )}
        </div>
      </div>
    )
  }
}

TitleInput.propTypes = {
  complete: propTypes.object.isRequired
}

TitleInput.defaultProps = {

}

export default TitleInput
