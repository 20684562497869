import React, { Component } from 'react'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { KeyboardArrowRight } from '@material-ui/icons'
import { connect } from 'react-redux'
import HandlerInfoRow from './HandlerInfoRow'
import instance from '../../../Utils/client'
import LoaderDialog from '../../../Shared/LoaderDialog'
import TextEdit from './EditFields/TextEdit'
import HandlerProfileContext from './HandlerProfileContext'
import autobind from '../../../Utils/autobind'
import { callSnackbar } from '../../../Utils/snackbars'
import { updateCurrentUser } from '../../../Actions/Login'
import PasswordEdit from './EditFields/PasswordEdit'
import AreaEdit from './EditFields/AreaEdit'
import { getAllCommunes } from '../../../API/Communes'
import CommuneEdit from './EditFields/CommuneEdit'

const style = theme => ({
  container: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: 'left',
    maxWidth: 500,
  },
  header: {
    color: theme.palette.grey.darker,
    fontSize: 14,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 12
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginLeft: 12
    },
  },
  button: {
    transition: 'all 0.2s ease-in-out',
    background: theme.palette.primary.main,
    color: '#ffffff',
  },
  buttonCancel: {
    transition: 'all 0.2s ease-in-out',
  },
  show: {
    transform: 'scale(1)'
  },
  hide: {
    transform: 'scale(0)'
  },
  itemTitle: {
    fontSize: 14,
    color: theme.palette.grey.dark,
  },
})

class EditProfile extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      handler: null,
      hasChanged: false,
      areas: [],
      communes: []
    }
    autobind(EditProfile, this)
  }

  async componentDidMount() {
    const { user: { current: { id = '' } } } = this.props
    this.setState({ loading: true })
    const response = await instance({
      method: 'get',
      url: `handlers/${id}`
    })
    const areasResponse = await instance({
      method: 'get',
      url: 'areas'
    })
    const communeResponse = await getAllCommunes()
    if (response.status === 200) {
      const handler = response.data.info
      const areas = areasResponse.data.info
      const communes = communeResponse.data.info
      this.setState({ handler, areas, communes })
    }
    this.setState({ loading: false })
  }

  async handleSave() {
    const { user: { current: { id = '' } }, updateUser } = this.props
    const { handler } = this.state
    this.setState({ loading: true })
    const response = await instance({
      method: 'put',
      url: `handlers/${id}`,
      data: handler
    })
    if (response.status === 200) { callSnackbar('Información guardada con éxito', 'success') }
    updateUser(response.data.info)
    this.setState({ hasChanged: false, loading: false })
  }

  handleChange(event) {
    const { target } = event
    const { handler } = this.state
    handler[target.name] = target.value
    this.setState({ handler, hasChanged: true })
  }

  handleGetAreaNames(ids) {
    if (ids.length === 0) return 'No ingresado'
    const { areas } = this.state
    const selected = areas.filter(area => ids.includes(area.id)).map(area => area.name).join(', ')
    return selected
  }

  handleGetCommuneNames(ids) {
    if (ids.length === 0) return 'No ingresado'
    const { communes } = this.state
    const selected = communes.filter(commune => ids.includes(commune.id)).map(commune => commune.name).join(', ')
    return selected
  }

  render() {
    const { classes } = this.props;
    const { loading, handler, hasChanged, areas, communes } = this.state
    return (
      <Paper className={classes.container}>
        <div className={classes.header}>
          <Typography className={classes.header}>Configuración general</Typography>
          <KeyboardArrowRight />
          <Typography variant="h1" color="primary">Cuenta</Typography>
        </div>
        <div className={classes.itemTitle}>
          <LoaderDialog open={loading} />
          <HandlerProfileContext.Provider value={{ onChange: this.handleChange, areas, communes }}>
            <>
              <HandlerInfoRow label="Nombre" info={handler} name="name" editComponent={TextEdit} />
              <HandlerInfoRow label="RUT" info={handler} name="rut" editComponent={TextEdit} />
              <HandlerInfoRow label="Comuna" info={handler} name="commune_id" editComponent={CommuneEdit} format={this.handleGetCommuneNames} />
              <HandlerInfoRow label="Teléfono" info={handler} name="phone" editComponent={TextEdit} />
              <HandlerInfoRow label="Area" info={handler} name="areas" editComponent={AreaEdit} format={this.handleGetAreaNames} />
              <HandlerInfoRow label="Mail" info={handler} name="email" editComponent={TextEdit} />
              <HandlerInfoRow label="Contraseña" hideText info={handler} name="password" editComponent={PasswordEdit} />
            </>
          </HandlerProfileContext.Provider>
        </div>
        <div className={`${classes.buttons}`}>
          <Button className={`${classes.buttonCancel} ${hasChanged ? classes.show : classes.hide}`}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={this.handleSave}
            className={`${classes.button} ${hasChanged ? classes.show : classes.hide}`}
          >
            Guardar cambios
          </Button>
        </div>
      </Paper>
    );
  }
}

EditProfile.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  updateUser: propTypes.func.isRequired,
}

EditProfile.defaultProps = {

}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  updateUser: body => dispatch(updateCurrentUser(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(EditProfile))
