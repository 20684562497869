import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import propTypes from 'prop-types'

const style = theme => ({
  container: {
    display: 'grid',
    textAlign: 'center',
    justifiContent: 'center',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridGap: 6,
    marginTop: 12,
  },
  text: {
    color: theme.palette.grey.dark,
  },
})

class CalendarDaysTitles extends Component {
  constructor(props) {
    super(props)
    this.renderDayTitle = this.renderDayTitle.bind(this)
  }

  renderDayTitle() {
    const { classes } = this.props
    const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']
    return days.map(day => (
      <div key={day} style={{ padding: '0 12px' }}>
        <Typography className={classes.text}>{day}</Typography>
      </div>
    ))
  }

  render() {
    const { classes } = this.props
    return <div className={classes.container}>{this.renderDayTitle()}</div>
  }
}

CalendarDaysTitles.propTypes = {
  classes: propTypes.object.isRequired,
}

export default withStyles(style)(CalendarDaysTitles)
