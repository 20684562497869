import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  answer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '60%',
    margin: '5px 0'
  },
  container: {
    padding: 24,
    textAlign: 'left'
  }
})

class Diagnosis extends Component {
  getAnswerFromIndex(index) {
    const { answer } = this.props
    let result = false
    if (answer.subquestions) {
      Object.entries(answer.subquestions).forEach(([id, value]) => {
        const subIndex = parseInt(id.split('&')[1], 10)
        if (index === subIndex) {
          result = value
        }
      })
    }
    return result
  }

  dateIntervalAnswer(start, end, ans) {
    const { classes } = this.props
    const splitAns = ans.split('&')
    return (
      <React.Fragment key={ans}>
        <div className={classes.answer}>
          <Typography variant="h2">{start}</Typography>
          <Typography variant="h2" style={{ fontWeight: 600 }}>{splitAns[0]}</Typography>
        </div>
        <div className={classes.answer}>
          <Typography variant="h2">{end}</Typography>
          <Typography variant="h2" style={{ fontWeight: 600 }}>{splitAns[1]}</Typography>
        </div>
      </React.Fragment>
    )
  }

  simpleAnswer(text, ans) {
    const { classes } = this.props
    return (
      <div className={classes.answer} key={text}>
        <Typography variant="h2">{text}</Typography>
        <Typography variant="h2" style={{ fontWeight: 600 }}>{ans}</Typography>
      </div>
    )
  }

  renderAnswers() {
    const { questions } = this.props
    return questions.map((question, index) => {
      const ans = this.getAnswerFromIndex(index)
      if (ans) {
        if (question.type === 'date-interval') {
          return (
            this.dateIntervalAnswer(question.start_label, question.end_label, ans)
          )
        } if (question.type === 'select' || question.type === 'radio') {
          return (
            this.simpleAnswer(question.text, question.options[ans])
          )
        }
        return (
          this.simpleAnswer(question.text, ans)
        )
      }
      return null
    })
  }

  renderQuestion() {
    const { title } = this.props
    return (
      <>
        <Typography variant="h2" style={{ fontWeight: 600 }}>{title}</Typography>
        {this.renderAnswers()}
      </>
    )
  }

  render() {
    const { classes, questions } = this.props
    return (
      <div className={classes.container}>
        {questions.length > 0 && this.renderQuestion()}
      </div>
    )
  }
}

Diagnosis.propTypes = {
  answer: propTypes.object,
  questions: propTypes.arrayOf(propTypes.object),
  classes: propTypes.object.isRequired,
  title: propTypes.string
}

Diagnosis.defaultProps = {
  answer: { subquestions: [] },
  questions: [],
  title: ''
}

export default withStyles(style)(Diagnosis)
