import React, { Component } from 'react'
import { withStyles, Typography, withTheme } from '@material-ui/core'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { AccessTimeOutlined } from '@material-ui/icons'
import { getFormattedHistories } from 'Utils/functions'

const daysNames = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']

const style = theme => ({
  container: {
    padding: 12,
  },
  header: {
    paddingLeft: '4%',
    width: '25%',
    textAlign: 'left',
    borderRadius: 4,
    marginBottom: 12,
    marginRight: '25%',
  },
  headerThird: {
    paddingLeft: '4%',
    width: '25%',
    textAlign: 'left',
    borderRadius: 4,
    marginBottom: 12,
    marginRight: '25%',
    display: 'inline-flex',
  },
  headerTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  number: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'start',
  },
  day: {
    fontSize: 25,
  },
  body: {
    borderTop: '2px solid #a2a2a2',
    padding: '6px 6px',
  },
  emptyBody: {
    textAlign: 'left',
    minHeight: 20
  },
  textContainer: {
    width: '100%',
    textAlign: 'left',
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    color: '#3e3e3e',
    maxWidth: 120,
    flexBasis: '100%',
  },
  bodyText: {
    color: '#3e3e3e',
    fontWeight: 'bold',
    maxWidth: '65%',
    flexBasis: '100%',
    marginRight: 24
  },
  inline: {
    display: 'flex',
    alignItems: 'flex-end',
    marginRight: 36,
    '& > svg': {
      marginRight: 12,
      color: '#6a6a6a',
    },
    '& > *': {
      color: '#3e3e3e'
    }
  }
})

class DayList extends Component {
  render() {
    const {
      classes,
      backgroundStyle,
      offset,
      info,
      offsetUnit,
      formatFunc,
      showEmpty
    } = this.props
    const currentDate = moment().add(offsetUnit, offset)
    const formattedInfo = formatFunc(info, currentDate)
    const backgroundColor = backgroundStyle ? '#ffffff' : '#ECECEC'
    const days = formattedInfo.length ? formattedInfo : daysNames.map(() => [])
    return (
      <div className={classes.container} elevation={3}>
        {days.map((dayEvents, index) => {
          const weekDayNumber = currentDate.startOf('isoWeek').add(index, 'days').format('DD [de] MMMM')
          const dayNumber = days?.[index]?.[0]?.date ?? weekDayNumber
          if (showEmpty || dayEvents.length) {
            return (
              <div style={index === daysNames.length - 1 ? {} : { marginBottom: 12 }} key={`${index * 2}dias`}>
                <div>
                  <Typography className={classes.number}>
                    {dayNumber}
                  </Typography>
                </div>
                <div className={classes.body} style={{ backgroundColor }}>
                  {days[index].length ? days[index].map(event => (
                    <div
                      className={classes.textContainer}
                      key={event.id}
                    >
                      <div className={classes.inline}>
                        <AccessTimeOutlined />
                        <Typography variant="subtitle2">
                          {event.initHour ? event.initHour : moment(event.realDate).format('HH:mm')}
                        </Typography>
                      </div>
                      <Typography className={classes.bodyText}>
                        {event.body}
                      </Typography>
                      <Typography className={classes.name}>
                        {event.user}
                      </Typography>
                    </div>
                  )) : (
                    <div className={classes.emptyBody}>
                      <Typography>Sin eventos</Typography>
                    </div>
                  ) }
                </div>
              </div>
            )
          }
          return null
        })}
      </div>
    )
  }
}

DayList.defaultProps = {
  backgroundStyle: 0,
  offset: 0,
  offsetUnit: 'month',
  info: [],
  showEmpty: false,
  formatFunc: getFormattedHistories
}

DayList.propTypes = {
  classes: propTypes.object.isRequired,
  backgroundStyle: propTypes.number,
  info: propTypes.arrayOf(propTypes.object),
  offset: propTypes.number,
  offsetUnit: propTypes.string,
  formatFunc: propTypes.func,
  showEmpty: propTypes.bool,
}

export default withRouter(withTheme(withStyles(style)(DayList)))
