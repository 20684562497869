import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { Beenhere } from '@material-ui/icons'
import autobind from '../../Utils/autobind'

const style = theme => ({
  container: {
    padding: 24,
    textAlign: 'center'
  },
  bigIcon: {
    height: 80,
    width: 80,
    color: theme.palette.primary.main,
    marginBottom: 24
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      flexBasis: '50%'
    },
    marginTop: 24
  }
})

class SuccessDialog extends Component {
  constructor(props) {
    super(props)
    autobind(SuccessDialog, this)
  }

  handleGoToAuths() {
    const { history } = this.props
    history.push('/authorizations')
  }

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Beenhere className={classes.bigIcon} />
          <Typography variant="h1">Debes esperar que la solicitud sea aprobada para que el cambio se haga efectivo</Typography>
          <div className={classes.buttons}>
            <Button onClick={this.handleGoToAuths} color="secondary" variant="contained">Ver solicitud</Button>
            <Button onClick={onClose}>Volver</Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

SuccessDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func.isRequired,
  history: propTypes.object.isRequired,
}

SuccessDialog.defaultProps = {
  open: false
}
export default withRouter(withStyles(style)(SuccessDialog))
