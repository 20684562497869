import React, { Component } from 'react'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { Close } from '@material-ui/icons'

const style = () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 12
    },
  }
})
class DialogHeader extends Component {
  render() {
    const { classes, icon: Icon, title, onClose } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          {Icon !== null && <Icon />}
          <Typography variant="h1">{title}</Typography>
        </div>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </div>
    );
  }
}

DialogHeader.propTypes = {
  classes: propTypes.object.isRequired,
  icon: propTypes.node,
  title: propTypes.string,
  onClose: propTypes.func
}

DialogHeader.defaultProps = {
  icon: null,
  title: 'Titulo',
  onClose: () => {}
}
export default withStyles(style)(DialogHeader)
