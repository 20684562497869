import React from 'react'
import { withStyles, Grid } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    textDecoration: 'underline',
    marginTop: 6,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 2fr',
    cursor: 'pointer'
  },
  text: {
    textAlign: 'left',
    marginLeft: 6
  }
})

const AddUserButton = ({ classes, onClick }) => (
  <Grid className={classes.container} onClick={onClick}>
    <div>
      <PersonAdd />
    </div>
    <div className={classes.text}>
      Agregar usuario
    </div>
  </Grid>
)

AddUserButton.propTypes = {
  classes: propTypes.object.isRequired,
  onClick: propTypes.func.isRequired
}

export default withStyles(style)(AddUserButton)
