import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    top: 0,
    position: 'sticky',
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  subContainer: {
    padding: '6px 12px',
    background: '#00000080',
    borderRadius: 6,
    '& > *': {
      color: 'white'
    }
  }
})
class ParticipantLabel extends Component {
  render() {
    const { classes, name } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <Typography variant="subtitle1">
            {name}
          </Typography>
        </div>
      </div>
    );
  }
}

ParticipantLabel.propTypes = {
  classes: propTypes.object.isRequired,
  name: propTypes.string
}


ParticipantLabel.defaultProps = {
  name: ''
}
export default withStyles(style)(ParticipantLabel)
