import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Button, Divider, Typography, withStyles } from '@material-ui/core'
import autobind from '../../../../Utils/autobind'
import CriteriaForm from '../../../../Shared/CriteriaForm'

const style = () => ({
  button: {
    textAlign: 'end'
  }
})

class SecondStepCompanion extends Component {
  constructor(props) {
    super(props)
    autobind(SecondStepCompanion, this)
    this.state = {
      params: {},
      saved: {},
      comments: {}
    }
  }

  handleChange(event) {
    const { target } = event
    const { params, saved } = this.state
    const newSaved = { ...saved }
    const newParams = { ...params, ...newSaved }
    delete newSaved[target.name]
    if (newParams[target.name]) {
      newParams[target.name].answer = target.value
    } else {
      newParams[target.name] = { answer: target.value }
    }
    this.setState({ params: newParams, saved: newSaved })
  }

  handleComment(event) {
    const { target } = event
    const { comments } = this.state
    comments[target.name] = target.value
    this.setState({ comments })
  }

  handleSubchange(event) {
    const { target } = event
    const { params, saved } = this.state
    const newSaved = { ...saved }
    const newParams = { ...params, ...newSaved }
    const actualName = target.name.split('&')[0]
    delete newSaved[actualName]
    if (newParams[actualName].subquestions) {
      newParams[actualName].subquestions[target.name] = target.value
    } else {
      newParams[actualName] = {
        ...newParams[actualName], subquestions: { [target.name]: target.value }
      }
    }
    this.setState({ params: newParams, saved: newSaved })
  }

  handleSend() {
    const { params, comments } = this.state
    const { shared, sendAnswers, data, handleNextStep, handleChangeView } = this.props
    const finalAnswers = Object.entries(params).map(([questionId, answer]) => ({
      id: questionId,
      body: {
        companion_name: shared.companionName,
        answer: answer.answer,
        subquestions: answer.subquestions,
        comment: comments[questionId]
      }
    }))
    const body = {
      data: { answers: finalAnswers },
      event_id: data.event.id
    }
    sendAnswers(body)
    handleNextStep(2)
    handleChangeView()
  }

  renderQuestions() {
    const { data } = this.props
    const { forms, participants } = data
    const savedComments = {}
    this.criteria = []
    const { params, saved, comments } = this.state
    const actualform = forms ? forms['Entrevista Familiar'] : {}
    const completeForm = Object.entries(actualform).map(([criterium, empty], index) => {
      if (empty.empty) {
        const newCrit = { [criterium]: empty.empty }
        this.criteria.push({ name: criterium, link: criterium })
        return (
          <CriteriaForm
            id={criterium}
            key={criterium}
            title={`${index + 1} - ${criterium}`}
            criteria={newCrit}
            params={params}
            handleChange={this.handleChange}
            handleComment={this.handleComment}
            handleSubchange={this.handleSubchange}
            withButton={false}
            saved={saved}
            comments={comments}
            savedComments={savedComments}
            userId={participants[0].user_id}
            bigScreen={false}
            enumerate
          />
        )
      }
      return null
    })
    return completeForm
  }

  render() {
    const { classes, shared, data } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="subtitle2">{`Bienvenido ${shared.companionName}`}</Typography>
        <Typography variant="subtitle1">Preguntas</Typography>
        <Divider />
        { Object.keys(data).length === 0
          ? <Typography variant="subtitle2">Esperando entrevista...</Typography>
          : this.renderQuestions()}
        <Divider />
        <div className={classes.button}>
          <Button color="secondary" size="small" variant="contained" onClick={this.handleSend}>
            Terminar
          </Button>
        </div>
      </div>
    )
  }
}

SecondStepCompanion.propTypes = {
  classes: propTypes.object.isRequired,
  shared: propTypes.object.isRequired,
  handleNextStep: propTypes.func.isRequired,
  handleChangeView: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  sendAnswers: propTypes.func.isRequired,
}

SecondStepCompanion.defaultProps = {

}

export default withStyles(style)(SecondStepCompanion)
