import createSnackbar from '../Utils/snackbars'
import instance from '../Utils/client'

export const getEnterprisesAction = () => {
  const REQUEST = instance({
    method: 'get',
    url: 'enterprises'
  })
  return {
    type: 'GET_ENTERPRISES',
    payload: REQUEST
  }
}

export const createEnterpriseAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'enterprises',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_ENTERPRISE',
    payload: REQUEST
  }
}

export const editEnterpriseAction = body => {
  const REQUEST = instance({
    method: 'put',
    url: `enterprises/${body.id}`,
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_ENTERPRISE',
    payload: REQUEST
  }
}
