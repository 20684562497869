import stateMaker from '../Utils/reducers'

const UserReducer = (state = { current: {}, external: [] }, action) => {
  switch (action.type) {
    case 'LOGIN': {
      const { data } = action.payload
      const { headers } = action.payload
      const result = {
        ...state,
        current: data.info,
        'access-token': headers['access-token'],
        client: headers.client,
        uid: headers.uid,
      }
      return result
    }
    case 'LOGOUT': {
      const newState = { ...state, current: {} }
      return newState
    }
    case 'GOOGLE_SYNC': {
      const { data } = action.payload
      const result = {
        ...state,
        current: data.info,
      }
      return result
    }
    case 'UPDATE_CURRENT_USER': {
      const newUser = action.payload
      const newCurrentUser = { ...newUser }
      const newState = { ...state }
      newState.current = { ...newState.current, ...newCurrentUser }
      return newState
    }
    case 'CREATE_EXTERNAL_USER':
      return stateMaker(state, 'external', 'create', action)
    case 'DELETE_EXTERNAL_USER':
      return stateMaker(state, 'external', 'delete', action)
    case 'LOAD_EXTERNAL_USERS':
      return stateMaker(state, 'external', 'fetch', action)
    case 'LOAD_USERS':
      return stateMaker(state, 'all', 'fetch', action)
    case 'EDIT_EXTERNAL_USER':
      return stateMaker(state, 'external', 'edit', action)
    default:
      return state
  }
}

export default UserReducer
