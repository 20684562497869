import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import autobind from 'Utils/autobind'
import { SaveAltOutlined } from '@material-ui/icons'
import ButtonsWithIcon from 'Shared/Buttons/ButtonsWithIcon'
import { TextField } from 'Shared/Fields/Text'

const style = () => ({
  rotative: {
    '& > h6': {
      background: '#ececec',
      padding: '3px 6px',
      marginBottom: 6
    },
    '& > *': {
      margin: '3px 0'
    }
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      width: '40%'
    }
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      width: '40%'
    },
    '& > *:last-child': {
      flexGrow: 1
    }
  },
  infoContainer: {
    padding: 6
  }
})

class Rotative extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    autobind(Rotative, this)
  }

  componentDidMount() {
    const { rotative } = this.props
    const params = { ...rotative }
    this.setState({ params })
  }

  handleChange(event) {
    const { target: { name, value } } = event
    const { params } = this.state
    params[name] = value
    this.setState({ params })
  }

  handleCreate() {
    const { params } = this.state
    const { onSubmit } = this.props
    const body = { ...params }
    onSubmit(body)
  }

  render() {
    const { classes, rotative, index, edit = false } = this.props;
    const { params } = this.state
    console.log(rotative)
    return (
      <div className={classes.rotative}>
        <Typography variant="subtitle1">
          {`Rotativa ${index + 1}`}
        </Typography>
        {edit
          ? (
            <div className={classes.infoContainer}>
              <div className={classes.input}>
                <Typography variant="subtitle1">Unidad:</Typography>
                <TextField complete={{ name: 'unit' }} value={params.unit} onChange={this.handleChange} />
              </div>
              <div className={classes.input}>
                <Typography variant="subtitle1">Profesional PCF:</Typography>
                <TextField complete={{ name: 'professional' }} value={params.professional} onChange={this.handleChange} />
              </div>
              <div className={classes.input}>
                <Typography variant="subtitle1">Mentor:</Typography>
                <TextField complete={{ name: 'mentor' }} value={params.mentor} onChange={this.handleChange} />
              </div>
              <div className={classes.input}>
                <Typography variant="subtitle1">Fecha inicio:</Typography>
                <TextField complete={{ name: 'date' }} value={params.date} onChange={this.handleChange} />
              </div>
            </div>
          )
          : (
            <div className={classes.infoContainer}>
              <div className={classes.info}>
                <Typography variant="subtitle1">Unidad:</Typography>
                <Typography variant="subtitle1">{rotative.unit}</Typography>
              </div>
              <div className={classes.info}>
                <Typography variant="subtitle1">Profesional PCF:</Typography>
                <Typography variant="subtitle1">{rotative.professional}</Typography>
              </div>
              <div className={classes.info}>
                <Typography variant="subtitle1">Mentor:</Typography>
                <Typography variant="subtitle1">{rotative.mentor}</Typography>
              </div>
              <div className={classes.info}>
                <Typography variant="subtitle1">Fecha inicio:</Typography>
                <Typography variant="subtitle1">{rotative.date}</Typography>
              </div>
            </div>
          )}
        {edit && (
          <div style={{ marginTop: 12, justifyContent: 'end', display: 'flex' }}>
            <ButtonsWithIcon Icon={SaveAltOutlined} variant="text" color="primary" size="small" onClick={this.handleCreate}>
              Guardar
            </ButtonsWithIcon>
          </div>
        )}
      </div>
    );
  }
}

Rotative.propTypes = {
  classes: propTypes.object.isRequired,
  rotative: propTypes.object.isRequired,
  onSubmit: propTypes.func.isRequired,
  index: propTypes.string.isRequired,
  edit: propTypes.bool
}

Rotative.defaultProps = {
  edit: false
}

export default withStyles(style)(Rotative)
