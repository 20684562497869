import React, { Component } from 'react'
import { AddComment } from '@material-ui/icons'
import MuiTextfield from '@material-ui/core/TextField'
import propTypes from 'prop-types'
import { Typography } from '@material-ui/core'

class Comment extends Component {
  render() {
    const { area, onChange, value, name, commentLabel, disabled } = this.props
    return (
      <div style={{ gridArea: area, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
        <Typography variant="subtitle2" style={{ marginBottom: 6, fontWeight: 600 }}>{commentLabel}</Typography>
        <div style={{ display: 'flex', width: '100%' }}>
          <AddComment style={{ marginRight: 8, cursor: 'pointer' }} color="primary" onClick={this.handleOpen} />
          <MuiTextfield
            onChange={onChange}
            value={value}
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 } }}
            variant="filled"
            name={name}
            placeholder="Agrega un comentario..."
            onBlur={this.handleOpen}
            disabled={disabled}
            multiline
          />
        </div>
      </div>
    )
  }
}

Comment.propTypes = {
  area: propTypes.string,
  onChange: propTypes.func.isRequired,
  value: propTypes.string,
  name: propTypes.string,
  commentLabel: propTypes.string,
  disabled: propTypes.bool,
  height: propTypes.number
}

Comment.defaultProps = {
  area: '',
  value: '',
  name: '',
  commentLabel: '',
  disabled: false,
  height: 1
}

export default Comment
