import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { TextField } from '../../../../Shared/Fields/Text'

const style = () => ({
  container: {
    position: 'relative',
    textAlign: 'start'
  },
})

class PasswordEdit extends Component {
  render() {
    const { classes, onBlur, onChange, name, value } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="subtitle1">Nueva Contraseña</Typography>
        <TextField
          onChange={onChange}
          onBlur={onBlur}
          complete={{ name }}
          value={value}
          autofocus
        />
      </div>
    );
  }
}

PasswordEdit.propTypes = {
  classes: propTypes.object.isRequired,
  value: propTypes.string.isRequired,
  name: propTypes.string,
  onBlur: propTypes.func,
  onChange: propTypes.func
}

PasswordEdit.defaultProps = {
  name: '',
  onBlur: () => {},
  onChange: () => {},

}
export default withStyles(style)(PasswordEdit)
