import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

const style = () => ({
  container: {
    '& > h2': {
      marginBottom: 24,
      fontWeight: 600
    },
    height: 250
  }

})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

function getDatasets(data) {
  if (!data) return [[], []]
  if (!data[0]) return [[], []]
  const keys = Object.keys(data[0]).filter(key => key !== 'average')
  const labels = keys.map(key => key.split(' ').map(name => name[0]).join(''))
  const datasetsValues = data.map(info => keys.map(key => info[key]))

  const pointLabels = data.map((_, index) => `EDPS ${index + 1}`)
  const datasets = datasetsValues.map((datas, index) => ({
    data: datas,
    backgroundColor: `${colors[index]}aa`,
    borderColor: colors[index],
    pointRadius: 4,
    label: pointLabels[index]
  }))

  return [
    labels,
    datasets
  ]
}

class WebChart extends Component {
  componentDidMount() {
    const { data: chartData = {} } = this.props
    const chartElement = document.getElementById('radaredps')
    const myChartRef = chartElement.getContext('2d');
    chartElement.height = '250'

    const [labels, datasets] = getDatasets(chartData)

    // eslint-disable-next-line no-new
    this.chart = new Chart(myChartRef, {
      type: 'radar',
      data: {
        // Bring in data
        labels,
        datasets
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: true,
          labels: {
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
            fontColor: 'black',
            fontSize: 12,
            usePointStyle: true,
            boxWidth: 6
          },
        },
        title: {
          display: false,
        },
        scale: {
          beginAtZero: true,
          gridLines: {
            color: 'rgba(0, 0, 0, 0.2)',
          },
          ticks: {
            display: false,
            min: 0,
            max: 100,
            stepSize: 25,
          },
          pointLabels: {
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
            fontColor: 'black',
            fontSize: 12,
          }
        },
        elements: {
          line: {
            borderWidth: 2
          }
        }
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (prevProps.data !== data) {
      const [labels, datasets] = getDatasets(data)
      this.chart.data.labels = labels
      this.chart.data.datasets = datasets
      this.chart.update()
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h2">Nivel de apoyo por ámbito</Typography>
        <canvas id="radaredps" />
      </div>
    );
  }
}

WebChart.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
}

WebChart.defaultProps = {

}
export default withStyles(style)(WebChart)
