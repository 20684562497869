import React, { Component } from 'react'
import propTypes from 'prop-types'
import autobind from '../Utils/autobind'

class StepContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      shared: {},
      otherProps: {}
    }

    autobind(StepContainer, this)
  }

  handleNextStep(value, otherProps = {}) {
    this.setState({ step: value, otherProps })
  }

  handleShare(object) {
    const { shared } = this.state
    const newShared = { ...shared }
    Object.keys(object).forEach(key => {
      newShared[key] = object[key]
    })
    this.setState({ shared: newShared })
  }

  renderChild() {
    const { children } = this.props
    const kids = React.Children.toArray(children)
    const { step, shared, otherProps } = this.state
    const toRender = kids[step]
    const newComponent = React.cloneElement(toRender, {
      handleNextStep: this.handleNextStep,
      handleShare: this.handleShare,
      shared,
      ...otherProps
    })
    return newComponent
  }

  render() {
    return (
      <>
        {this.renderChild()}
      </>
    )
  }
}

StepContainer.propTypes = {
  children: propTypes.node.isRequired
}

StepContainer.defaultProps = {

}

export default StepContainer
