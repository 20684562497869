const statusReducer = (state = { message: '' }, action) => {
  switch (action.type) {
  case 'SET_SEARCH_QUERY':
    return {
      query: action.payload.data.info,
    }
  default:
    return state
  }
}

export default statusReducer
