import React, { Component } from 'react'
import {
  Paper,
  withStyles,
  ListItem,
  Typography,
  Tooltip,
} from '@material-ui/core'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { connect } from 'react-redux'
import { MuiIconsMap } from '../../Utils/constants'
import Loader from '../../Shared/Loader'
import { loadEventsAction, selectEventAction } from '../../Actions/Events'

const styles = theme => ({
  container: {
    overflowY: 'auto',
    textAlign: 'left',
    position: 'relative',
    maxHeight: 450,
    minHeight: 250
  },
  listItem: {
    padding: '6px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center'
  },
  event: {
    background: theme.palette.indigo.lighter,
    margin: '16px 12px',
    borderRadius: 5,
    boxShadow: '0px 1px 5px #534bae6e',
    position: 'relative'
  },
  title: {
    marginBottom: 12,
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 1,
    padding: 12,
  }
})

class Pendings extends Component {
  constructor(props) {
    super(props)

    this.handleGoto = this.handleGoto.bind(this)
  }

  handleGoto(link, event) {
    const { history, selectEvent } = this.props
    selectEvent(event.event.id)
    history.push(link.link)
  }

  renderPendings() {
    const { classes, events, baseEvents } = this.props
    const allEvents = events.all || []
    let eventsToUse = allEvents
    if (baseEvents !== null) eventsToUse = baseEvents
    return eventsToUse.map(pending => {
      const { links } = pending
      return (
        links
        && links.map(link => {
          const Icon = MuiIconsMap[link.icon]
          return (
            <div className={classes.event} key={link.key} elevation={3}>
              <Tooltip title={link.name}>
                <ListItem
                  button
                  className={classes.listItem}
                  onClick={() => this.handleGoto(link, pending)}
                >
                  <div style={{ maxWidth: 'calc(100% - 26px)', }}>
                    <Typography
                      name={`${link.name}`}
                      variant="subtitle1"
                      style={{
                        maxWidth: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {link.name}
                    </Typography>
                    <Typography name={link.name} variant="caption" color="primary" style={{ fontWeight: 600, fontSize: 11 }}>
                      {moment(link.date).fromNow()}
                    </Typography>
                  </div>
                  <Icon style={{ color: 'white' }} />
                </ListItem>
              </Tooltip>
            </div>
          )
        })
      )
    })
  }

  render() {
    const { classes, style, loadAllEvents } = this.props
    return (
      <Paper className={classes.container} style={style}>
        <Typography variant="subtitle1" className={classes.title} color="primary">Eventos Pendientes</Typography>
        <Loader action={loadAllEvents} params={[true]}>
          {this.renderPendings()}
        </Loader>
      </Paper>
    )
  }
}

Pendings.propTypes = {
  classes: propTypes.object.isRequired,
  style: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  events: propTypes.object.isRequired,
  selectEvent: propTypes.func.isRequired,
  loadAllEvents: propTypes.func.isRequired,
  baseEvents: propTypes.arrayOf(propTypes.object)
}

Pendings.defaultProps = {
  baseEvents: null
}

const mapStateToProps = state => ({
  events: state.events,
})

const mapDispatchToProps = dispatch => ({
  loadAllEvents: onlyActive => dispatch(loadEventsAction(onlyActive)),
  selectEvent: eventId => dispatch(selectEventAction(eventId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withStyles(styles)(Pendings))
)
