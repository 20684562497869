import React from 'react'
import { Dialog, DialogActions, Typography, Button, DialogContent, withStyles, IconButton } from '@material-ui/core'
import TitleIcon from '@material-ui/icons/MoveToInbox'
import CloseIcon from '@material-ui/icons/Clear'
import propTypes from 'prop-types'
import BinnacleContext from './BinnacleProvider'
import TextField from '../../../FormTextfield'

const style = () => ({
  title: {
    color: '#2c2c2c',
    fontSize: 14,
    marginLeft: 6,
    fontWeight: 900
  }
})

class EntryForm extends React.Component {
  render() {
    const { open, handleClose, classes, title, body } = this.props
    const { handleForm, handleChange, handleSave } = this.context
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: 500, height: 250, padding: 12 } }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 12 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TitleIcon />
            <Typography className={classes.title}> Entrada Bitácora</Typography>
          </div>
          <IconButton style={{ padding: 0 }} onClick={handleForm(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent style={{ padding: '0 12px' }}>
          <TextField name="title" label="" value={title} onChange={handleChange('title')} placeholder="Ingresar título" />
          <TextField name="title" label="" value={body} onChange={handleChange('body')} placeholder="Mensaje" multiline />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSave} variant="contained" color="primary"> Guardar </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EntryForm.contextType = BinnacleContext

EntryForm.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  body: propTypes.string.isRequired,
  handleClose: propTypes.func.isRequired,
}

export default withStyles(style)(EntryForm)
