import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { CheckCircle, ErrorOutline } from '@material-ui/icons'
import { connect } from 'react-redux'
import ReportTitles from '../ReportTitles'
import ResultPercentages from '../../../../Results/ResultPercentages'

const style = theme => ({
  content: {
    display: 'flex',
    alignItems: 'stretch',
    '& > div': {
      padding: 12,
      width: '50%'
    }
  },
  criteria: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '50%'
    },
    marginTop: 12
  },
  criterium: {
    display: 'flex',
    '& > *': {
      marginRight: 6
    },
    margin: '3px 0'
  },
  icon: {
    height: 16,
    width: 16
  },
  container: {
    marginBottom: 48
  },
  observations: {
    padding: 12,
    '& > *': {
      margin: '3px 0'
    }
  },
  error: {
    height: 16,
    width: 16,
    color: theme.palette.alert.main
  }
})
class EAVD extends Component {
  renderCriteria() {
    const { classes, contenders } = this.props
    const results = contenders.profileResults
    const { avd } = results
    return Object.entries(avd?.forms).map(([key, value]) => (
      <div className={classes.criterium} key={key}>
        {value.checked ? <CheckCircle color="primary" className={classes.icon} /> : <ErrorOutline className={classes.error} />}
        <Typography variant="subtitle2">{key}</Typography>
      </div>
    ))
  }

  render() {
    const { classes, contenders } = this.props
    const results = contenders.profileResults
    const { avd, comments } = results
    return (
      <div className={classes.container}>
        <ReportTitles title="EAVD" />
        <div className={classes.content}>
          <Paper>
            <ResultPercentages
              title="Índice de autonomía"
              subtitle1="Ponderación Postulante"
              subtitle2="Ponderación Acompañante"
              result={{
                handler_percentage: avd?.handler_percentage || 0,
                companion_percentage: avd?.companion_percentage || 0,
                average_percentage: avd?.average_percentage || 0
              }}
              containerStyle={{}}
            />
          </Paper>
          <div>
            <Typography variant="subtitle1">Cumple con indicadores:</Typography>
            <div className={classes.criteria}>
              {this.renderCriteria()}
            </div>
          </div>
        </div>
        <div className={classes.observations}>
          <Typography variant="subtitle1">Observaciones de Evaluacion de Actividades de la Vida Diaria</Typography>
          <Typography variant="body1">
            {comments?.avd?.comment || 'No hay comentarios'}
          </Typography>
        </div>
      </div>
    );
  }
}

EAVD.propTypes = {
  classes: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
}

EAVD.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(EAVD))
