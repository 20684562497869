/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import propTypes from 'prop-types'
import { Add, Edit } from '@material-ui/icons'
import { TextField } from '../../../Shared/Fields/Text'

const style = makeStyles(() => ({
  mainContainer: {
    width: 500
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    marginRight: 6
  },
  input: {
    margin: '24px 0 12px 0'
  },
  organizationsTitle: {
    fontWeight: 600,
    marginBottom: 6
  },
  organization: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '6px 0'
  },
  img: {
    position: 'relative',
    cursor: 'pointer',
    '& > svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0.4
    }
  }
}))

function simulateClick(id) {
  return () => {
    const element = document.getElementById(id)
    element.click()
  }
}

const CreateOrganizationDialog = ({
  open, onClose, onSave, organizations, onPhotoChange, reload
}) => {
  const [name, setName] = useState('')
  const [openCreate, setCreate] = useState(false)
  const classes = style()

  const handleChange = setFunc => e => {
    setFunc(e.target.value)
  }

  const handleSave = () => {
    onSave({
      name,
    })
    onClose()
  }

  const handleOpen = () => {
    setCreate(!openCreate)
  }

  const handleChangeImage = org => async event => {
    const { target } = event
    const file = target.files[0]
    const body = new FormData()
    body.append('id', org.id)
    body.append('file', file)
    body.append('file_name', file?.name)
    await onPhotoChange(body)
    reload()
  }

  return (
    <Dialog open={open} PaperProps={{ className: classes.mainContainer }} onClose={onClose}>
      <DialogTitle>
        <div className={classes.titleContainer}>
          <NoteAddIcon className={classes.titleIcon} />
          Nueva organización
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography
            className={classes.organizationsTitle}
          >
            Organizaciones actuales:
          </Typography>
          {organizations.map(org => (
            <div className={classes.organization} key={org.id}>
              <div className={classes.img} onClick={simulateClick(org.id)}>
                <div style={{ height: 48, width: 48, overflow: 'hidden', borderRadius: '50%' }}>
                  <img src={org.photo || '/noimage.png'} alt="logo" width="48px" />
                </div>
                <Edit />
                <input type="file" style={{ display: 'none' }} id={org.id} onChange={handleChangeImage(org)} />
              </div>
              <Typography>
                {org.name}
              </Typography>
            </div>
          ))}
        </div>
        <Button onClick={handleOpen} color="primary" style={{ textDecoration: 'underline' }}>
          <Add />
          {' '}
          Agregar organización
        </Button>
        <Collapse in={openCreate}>
          <div className={classes.input}>
            <TextField
              onChange={handleChange(setName)}
              complete={{ name: 'name', text: 'Nombre de organización' }}
              value={name}
            />
          </div>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Collapse in={openCreate}>
          <Button onClick={onClose}> Cancelar </Button>
          <Button onClick={handleSave} variant="contained" color="primary" disabled={!name}> Guardar </Button>
        </Collapse>
      </DialogActions>
    </Dialog>
  )
}

CreateOrganizationDialog.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
  organizations: propTypes.arrayOf(propTypes.object).isRequired,
  onPhotoChange: propTypes.func.isRequired,
  reload: propTypes.func.isRequired,
}

export default CreateOrganizationDialog
