import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import moment from 'moment'
import AddEvent from './AddEvent'
import MonthSelector from './MonthSelector'
import TypeSelection from './TypeSelection'
import Create from './Create'
import SearchBar from '../../Shared/Search'

const style = theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 10fr 1fr 3fr 2fr',
    gridGap: 12,
    background: '#F7F7F7',
    alignItems: 'center',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
  },
  textInput: {
    borderRadius: 5,
    background: theme.palette.indigo.lighter,
  },
})

class CalendarHeader extends Component {
  render() {
    const {
      classes,
      type,
      changeType,
      changeDate,
      currentDate,
      resetDate,
      openCreate,
      handleOpen,
      handleClose,
      submitCreation,
      contenders,
      handlers,
      currentUser,
      clickedEvent,
      selectedDate,
      searchFunc,
      handleDelete,
    } = this.props
    return (
      <>
        <div className={classes.container}>
          <AddEvent onClick={handleOpen} />
          <Create
            open={openCreate}
            handleClose={handleClose}
            submit={submitCreation}
            contenders={contenders}
            handlers={handlers}
            currentUser={currentUser}
            clickedEvent={clickedEvent}
            selectedDate={selectedDate}
            handleDelete={handleDelete}
          />
          <MonthSelector handleChange={changeDate} currentDate={currentDate} />
          <Button
            className={classes.button}
            onClick={resetDate}
            variant="contained"
            color="primary"
          >
            Hoy
          </Button>
          <SearchBar searchFunction={searchFunc} />
          <TypeSelection type={type} handleChange={changeType} />
        </div>
      </>
    )
  }
}

CalendarHeader.defaultProps = {
  clickedEvent: null,
  selectedDate: moment(new Date()).format('YYYY-MM-DD'),
}

CalendarHeader.propTypes = {
  classes: propTypes.object.isRequired,
  type: propTypes.number.isRequired,
  currentDate: propTypes.object.isRequired,
  changeDate: propTypes.func.isRequired,
  changeType: propTypes.func.isRequired,
  resetDate: propTypes.func.isRequired,
  openCreate: propTypes.bool.isRequired,
  handleOpen: propTypes.func.isRequired,
  handleClose: propTypes.func.isRequired,
  submitCreation: propTypes.func.isRequired,
  currentUser: propTypes.object.isRequired,
  contenders: propTypes.arrayOf(propTypes.object).isRequired,
  handlers: propTypes.arrayOf(propTypes.object).isRequired,
  clickedEvent: propTypes.object,
  selectedDate: propTypes.string,
  searchFunc: propTypes.func.isRequired,
  handleDelete: propTypes.func.isRequired
}

export default withStyles(style)(CalendarHeader)
