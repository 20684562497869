/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { Button, Checkbox, Collapse, Dialog, Divider, IconButton, Typography, withStyles, Tooltip } from '@material-ui/core'
import propTypes from 'prop-types'
import { Edit, HelpOutline, HighlightOff, KeyboardArrowDown, Save, Error } from '@material-ui/icons'
import { connect } from 'react-redux'
import autobind from '../../../../../Utils/autobind'
import UpdatedFieldRenderer from '../../../../../Shared/UpdatedFieldRenderer'
import LoaderAnimator from '../../../../../Shared/LoaderAnimator'
import { addSubgroupAction, sendAnswersAction, deleteSubgroupAction } from '../../../../../Actions/Events'
import OnTouchTooltip from '../../../../../Shared/OnTouchTooltip'

const style = theme => ({
  title: {
    '& > * > h2': {
      fontWeight: 600,
      fontSize: 16
    },
    marginTop: 24
  },
  question: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '2px 0',
    position: 'relative'
  },
  checkQuestion: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    margin: '3px 0',
    '& > *': {
      marginRight: 12
    }
  },
  questionText: {
    color: '#6A6A6A',
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 10
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
    margin: '15px 6px 6px 0',
    textTransform: 'uppercase'
  },
  questionTitle: {
    padding: '3px 0px',
    color: theme.palette.primary.dark,
    width: '100%',
    fontSize: 14,
    fontWeight: 900,
    marginLeft: 10,
  },
  answerText: {
    color: '#3E3E3E',
    fontSize: 14,
    fontWeight: 400,
    margin: '0px 0px 12px 12px',
  },
  subquestions: {
    paddingLeft: 24
  },
  answer: {
    flexBasis: '50%'
  },
  divider: {
    background: theme.palette.primary.main
  },
  big: {
    height: 2,
    margin: '5px 0px'
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  groupContainer: {
    background: '#DCE2F2',
    padding: '6px 12px',
    margin: '-6px -12px ',
    borderRadius: 12,
    marginBottom: 12
  },
  saveButton: {
    textAlign: 'end',
    marginTop: 12,
  },
  deleteButton: {
    textAlign: 'end'
  },
  button: {
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *:first-child > svg': {
      marginRight: 3
    }
  },
  dialog: {
    padding: 24,
    textAlign: 'center',
    '& > *': {
      marginBottom: 6
    }
  },
  dialogButtons: {
    marginTop: 12,
    '& > *': {
      margin: '0 12px'
    }
  },
  inlineHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '& > *': {
      transition: 'all 0.2s ease-in-out'
    }
  },
  openButton: {
    transform: 'rotate(180deg)'
  },
  tooltip: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  tooltipIcon: {
    height: 18,
    width: 18
  },
  alertIcon: {
    color: 'red',
    marginLeft: 4,
    fontSize: 18,
  },
  sliderText: {
    display: 'flex',
    alignItems: 'center'
  }
})

function parseQuestionToComplete(question) {
  const complete = { ...question }
  complete.id = question.answers?.id
  complete.name = question.answers?.id
  complete.type = question.type
  complete.required = 0
  return complete
}

function getGroupAnswer(groupQuestion, question, subgroupId, subIndex, editable) {
  const body = editable ? question.answers.body : question.answers[0].body
  const groupAnswers = body.groups
  if (groupAnswers) {
    const answerId = `${Object.keys(groupAnswers)[0].split('#')[0]}#${subgroupId}#${subIndex}`
    const answer = groupAnswers[answerId] ? groupAnswers[answerId].answer : null
    const subquestions = groupAnswers[answerId] ? groupAnswers[answerId].subquestions : null
    return [answer, subquestions]
  }
  return [false, false]
}

class EditableHealthCriterium extends Component {
  constructor() {
    super()
    this.state = {
      editing: false,
      selectedToDelete: false,
      groupToDeleteInfo: null,
      params: {},
      groups: {},
      savedGroups: {},
      comments: {},
      openGroup: null
    }
    this.betweenTitles = 0
    autobind(EditableHealthCriterium, this)
  }

  handleToggleWarning(groupId, answerId) {
    return () => {
      const groupToDeleteInfo = { groupId, answerId }
      this.setState({ selectedToDelete: groupId && answerId, groupToDeleteInfo })
    }
  }

  handleOpenGroup(id) {
    return () => {
      const { openGroup } = this.state
      if (openGroup === id) return this.setState({ openGroup: null })
      return this.setState({ openGroup: id })
    }
  }

  handleDeleteSubgroup() {
    const { deleteSubgroup, reload } = this.props
    const { groupToDeleteInfo } = this.state
    deleteSubgroup({
      answer_id: groupToDeleteInfo.answerId,
      answer_has_group_id: groupToDeleteInfo.groupId,
    }).then(() => {
      reload()
      this.setState({ selectedToDelete: false })
    })
  }

  handleOpenEdit() {
    const { form } = this.props
    const { editing, params, comments } = this.state
    if (!editing) {
      // Precharge answered questions
      let groups = {}
      form.forEach(question => {
        const name = question?.answers?.id
        const answer = question?.answers?.body?.answer
        const subquestions = question?.answers?.body?.subquestions
        const oldGroups = question?.answers?.body?.groups || {}
        const comment = question?.comment
        params[name] = { answer, subquestions }
        comments[name] = comment
        groups = { ...oldGroups, ...groups }
      })
      this.setState({ params, groups, comments })
    }
    this.setState({ editing: !editing })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = { ...params[target.name], answer: target.value }
    this.setState({ params })
  }

  handleComment(event) {
    const { target } = event
    const { comments } = this.state
    comments[target.name] = target.value
    this.setState({ comments })
  }

  handleSubChange(event) {
    const { target } = event
    const { params } = this.state
    const mainQuestionId = target.name.split('&')[0]
    const { subquestions } = params[mainQuestionId]
    params[mainQuestionId] = {
      ...params[mainQuestionId],
      subquestions: { ...subquestions, [target.name]: target.value }
    }
    this.setState({ params })
  }

  handleGroupChange(event) {
    const { target } = event
    const { groups, savedGroups, saved, params } = this.state
    const newsavedGroups = { ...savedGroups }
    const newgroups = { ...groups, ...newsavedGroups }
    const newParams = { ...params }
    delete newsavedGroups[target.name]
    if (newgroups[target.name]) {
      newgroups[target.name].answer = target.value
      newParams[target.name.split('#')[0]] = params[target.name.split('#')[0]] || saved[target.name.split('#')[0]]
    } else {
      newgroups[target.name] = { answer: target.value }
      newParams[target.name.split('#')[0]] = params[target.name.split('#')[0]] || saved[target.name.split('#')[0]]
    }
    this.setState({ groups: newgroups, savedGroups: newsavedGroups, params: newParams })
  }

  handleGroupSubchange(event) {
    const { target } = event
    const { groups, savedGroups } = this.state
    const newsavedGroups = { ...savedGroups }
    const newgroups = { ...groups, ...newsavedGroups }
    const actualName = target.name.split('&')[0]
    delete newsavedGroups[actualName]
    if (newgroups[actualName].subquestions) {
      newgroups[actualName].subquestions[target.name] = target.value
    } else {
      newgroups[actualName] = {
        ...newgroups[actualName], subquestions: { [target.name]: target.value }
      }
    }
    this.setState({ groups: newgroups, savedGroups: newsavedGroups })
  }

  handleSave() {
    const { event, sendAnswers, reload } = this.props
    const { params, saved, comments, savedComments, groups, savedGroups } = this.state
    const newSaved = { ...saved, ...params }
    const newComments = { ...savedComments, ...comments }
    const newGroups = { ...savedGroups, ...groups }
    const answers = { ...params }
    const groupAnswers = { ...groups, ...savedGroups }
    const answersComments = { ...comments }
    const groupByGroups = {}
    Object.entries(groupAnswers).forEach(([groupAnswerName, answer]) => {
      const groupAnswerId = groupAnswerName.split('#')[0]
      if (!groupByGroups[groupAnswerId]) {
        groupByGroups[groupAnswerId] = {}
      }
      groupByGroups[groupAnswerId][groupAnswerName] = answer
    })
    if (Object.keys(answers).length > 0) {
      const body = Object.keys(answers).map(key => ({
        id: key,
        body: { ...answers[key], groups: groupByGroups[key] },
        comment: answersComments[key],
      }))
      this.setState({
        saved: newSaved,
        params: {},
        savedComments: newComments,
        comments: {},
        groups: {},
        savedGroups: newGroups,
      })
      sendAnswers({ answers: body }, event.event_id).then(() => {
        reload()
        this.setState({ editing: false })
      })
    } else {
      this.setState({ editing: false })
    }
  }

  renderGroupSubquestions(subquestions, answers) {
    const { classes } = this.props
    return subquestions.map((subquestion, index) => {
      const answerId = Object.keys(answers).find(key => key.split('&')[1] === index.toString())
      const answer = answers[answerId]
      if (answer) {
        switch (subquestion.type) {
          case 'text': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography className={classes.answerText}>{answer}</Typography>
                </div>
              </div>
            )
          }
          case 'select': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography className={classes.answerText}>
                    {subquestion.options[answer]}
                  </Typography>
                </div>
              </div>
            )
          }
          case 'radio': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography className={classes.answerText}>
                    {subquestion.options[answer]}
                  </Typography>
                </div>
              </div>
            )
          }
          default: {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography className={classes.answerText}>{answer}</Typography>
                </div>
              </div>
            )
          }
        }
      }
      return null
    })
  }

  renderSingleSubgroupQuestion(question, answer, subquestionsAnswers) {
    const { classes } = this.props
    const { subquestions } = question
    const hasSubquestions = subquestions && subquestions.length > 0 && subquestionsAnswers
    switch (question.type) {
      case 'text': {
        return (
          <div className={classes.question}>
            <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
            <div className={classes.answer}>
              <Typography className={classes.answerText}>{answer}</Typography>
            </div>
          </div>
        )
      }
      case 'select': {
        return (
          <>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
              <div className={classes.answer}>
                <Typography variant="body1" className={classes.answerText}>{question.options[answer]}</Typography>
              </div>
            </div>
            <div className={classes.subquestions}>
              {hasSubquestions && this.renderGroupSubquestions(subquestions, subquestionsAnswers)}
            </div>
          </>
        )
      }
      case 'radio': {
        return (
          <>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
              <div className={classes.answer}>
                <Typography className={classes.answerText}>{question.options[answer]}</Typography>
              </div>
            </div>
            <div className={classes.subquestions}>
              {hasSubquestions && this.renderGroupSubquestions(subquestions, subquestionsAnswers)}
            </div>
          </>
        )
      }
      case 'date-interval': {
        const splitAnswer = answer.split('&')
        return (
          <div className={classes.subquestions}>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionText}>{question.start_label}</Typography>
              <div className={classes.answer}>
                <Typography className={classes.answerText}>{splitAnswer[1]}</Typography>
              </div>
            </div>
            {question.has_box
          && (
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionText}>{question.has_box}</Typography>
              <div className={classes.answer}>
                <Typography variant="body1" className={classes.answerText}>{splitAnswer[0] === '0' ? 'No' : 'Si'}</Typography>
              </div>
            </div>
          )}
            {splitAnswer[0] === '0' && (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{question.end_label}</Typography>
                <div className={classes.answer}>
                  <Typography className={classes.answerText}>{splitAnswer[2]}</Typography>
                </div>
              </div>
            )}
          </div>
        )
      }
      default: {
        return (
          <div className={classes.question}>
            <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
            <div className={classes.answer}>
              <Typography className={classes.answerText}>{answer}</Typography>
            </div>
          </div>
        )
      }
    }
  }

  renderSubgroups(question) {
    const { editable } = this.props
    const groups = editable
      ? question.answers.subquestion_groups || []
      : question.answers[0].subquestion_groups || []
    return groups.map(group => {
      const { id } = group
      const questions = group.question
      const renderedQuestions = questions.map((groupQuestion, index) => {
        const [answer, subquestions] = getGroupAnswer(groupQuestion, question, id, index, editable)
        if (answer) {
          return this.renderSingleSubgroupQuestion(groupQuestion, answer, subquestions)
        }
        return null
      })
      return (
        <React.Fragment key={id}>
          {renderedQuestions}
        </React.Fragment>
      )
    })
  }

  renderSubquestions(question) {
    const { classes } = this.props
    const { subquestions } = question
    const subquestionAnswers = question?.answers?.body?.subquestions || {}
    return subquestions.map((subquestion, index) => {
      const answerId = Object.keys(subquestionAnswers).find(key => key.split('&')[1] === index.toString())
      const answer = subquestionAnswers[answerId]
      if (answer) {
        switch (subquestion.type) {
          case 'text': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography Typography className={classes.answerText}>{answer}</Typography>
                </div>
              </div>
            )
          }
          case 'select': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography className={classes.answerText}>
                    {subquestion.options[answer]}
                  </Typography>
                </div>
              </div>
            )
          }
          case 'radio': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography className={classes.answerText}>
                    {subquestion.options[answer]}
                  </Typography>
                </div>
              </div>
            )
          }
          case 'checkbox': {
            const answers = answer.split('&')
            return (
              <>
                {answers.map(checkAnswer => (
                  <div className={classes.checkQuestion}>
                    <Checkbox checked color="primary" />
                    <div className={classes.answer}>
                      <Typography className={classes.answerText}>
                        {subquestion.boxes[checkAnswer]}
                      </Typography>
                    </div>
                  </div>
                ))}
              </>
            )
          }
          case 'date-interval': {
            const splitAnswer = answer.split('&')
            return (
              <div className={classes.subquestions}>
                <div className={classes.question}>
                  <Typography variant="subtitle2" className={classes.questionText}>{subquestion.start_label}</Typography>
                  <div className={classes.answer}>
                    <Typography className={classes.answerText}>{splitAnswer[1]}</Typography>
                  </div>
                </div>
                {question.has_box
              && (
                <div className={classes.question}>
                  <Typography variant="subtitle2" className={classes.questionText}>{subquestion.has_box}</Typography>
                  <div className={classes.answer}>
                    <Typography className={classes.answerText}>{splitAnswer[0] === '0' ? 'No' : 'Si'}</Typography>
                  </div>
                </div>
              )}
                {splitAnswer[0] === '0' && (
                  <div className={classes.question}>
                    <Typography variant="subtitle2" className={classes.questionText}>{subquestion.end_label}</Typography>
                    <div className={classes.answer}>
                      <Typography className={classes.answerText}>{splitAnswer[2]}</Typography>
                    </div>
                  </div>
                )}
              </div>
            )
          }
          default: {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography className={classes.answerText}>{answer}</Typography>
                </div>
              </div>
            )
          }
        }
      } else {
        return null
      }
    })
  }

  renderSingleQuestion(question, nextType) {
    const { classes, editable, alerts } = this.props
    const body = editable ? question?.answers?.body : question?.answers[0]?.body
    const isAnsweredWithYes = body?.answer === '0'
    const isAnsweredWithNI = body?.answer === '2'
    switch (question.type) {
      case 'title': {
        this.betweenTitles = 0
        return (
          <>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.sectionTitle}>{question.text}</Typography>
            </div>
          </>
        )
      }
      case 'radio': {
        // if (!isAnsweredWithYes) return null
        let toReturn = null
        if (nextType !== 'radio' && this.betweenTitles === 0 && !isAnsweredWithYes && !isAnsweredWithNI) {
          toReturn = (
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionTitle}>El usuario no tiene registros relacionados a la categoría</Typography>
            </div>
          )
        }
        if (isAnsweredWithYes) this.betweenTitles += 1

        return (
          <>
            {isAnsweredWithYes
            && (
              <>
                <div className={classes.question}>
                  <Typography variant="subtitle2" className={classes.questionTitle}>{`- ${question.text}`}</Typography>
                </div>
                {this.renderSubgroups(question)}
                {question.subquestions && this.renderSubquestions(question)}
              </>
            )}
            {isAnsweredWithNI
            && (
              <>
                <div className={classes.question}>
                  <Typography variant="subtitle2" className={classes.questionTitle}>{`- ${question.text} (Responde sin información)`}</Typography>
                </div>
              </>
            )}
            {toReturn}
          </>
        )
      }
      case 'slider': {
        const { steps } = question
        const selected = steps[question?.answers?.body?.answer - 1]
        const { comment } = question.answers
        const questionText = question.text
        const alertFecal = questionText.includes('fecal') && alerts?.fecal
        const alertUrinaria = questionText.includes('urinaria') && alerts?.urinaria
        return (
          <div className={classes.question}>
            <div className={classes.sliderText}>
              <Typography variant="subtitle1" className={classes.questionText}>
                {questionText}
              </Typography>
              {(alertFecal || alertUrinaria) && (<Error className={classes.alertIcon} size="small" />)}
            </div>
            <div className={classes.answer}>
              <Typography variant="subtitle1">{selected?.label || ''}</Typography>
            </div>
            <div className={classes.answer}>
              <Typography variant="subtitle1">
                {comment ? `Comentarios: ${comment}` : 'Sin comentarios'}
              </Typography>
            </div>
          </div>
        )
      }
      default: {
        return (
          <div className={classes.question}>
            <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
            <div className={classes.answer}>
              <Typography className={classes.answerText}>{question.answers.body ? question.answers.body.answer : ''}</Typography>
            </div>
          </div>
        )
      }
    }
  }

  renderQuestions(questions) {
    return questions.map((question, index) => this.renderSingleQuestion(question, index < questions.length - 1 ? questions[index + 1].type : 'text'))
  }

  renderSubGroups(questionGroup, answer, answerValue) {
    const { classes } = this.props
    const { groups, savedGroups, openGroup } = this.state
    return questionGroup.map(group => {
      const answerId = answer.id
      const groupId = group.id
      const questions = group.question.map((question, index) => {
        const info = { ...question }
        info.id = groupId
        info.name = `${answerId}#${groupId}#${index}`
        return info
      })
      const hasAnsweredAsExpected = answerValue === '0'
      let finalQuestionGroup = []
      if (hasAnsweredAsExpected) {
        finalQuestionGroup = questions.map((question, questionIndex) => {
          const valueFromParams = groups[`${answerId}#${groupId}#${questionIndex}`]
          const valueFromSaved = savedGroups[`${answerId}#${groupId}#${questionIndex}`]
          const valueToUse = valueFromSaved || valueFromParams
          let value = ''
          let subquestions = {}
          if (valueToUse) {
            value = valueToUse.answer || ''
            subquestions = valueToUse.subquestions || {}
          }
          return (
            <div className={classes.question} key={question.name}>
              <UpdatedFieldRenderer
                complete={question}
                value={value || ''}
                onChange={this.handleGroupChange}
                onSubchange={this.handleGroupSubchange}
                subquestions={subquestions}
              />
              {question.tooltip && (
                <div className={classes.tooltip}>
                  <Tooltip title={<Typography variant="subtitle1" style={{ color: 'white' }}>{question.tooltip}</Typography>}>
                    <HelpOutline className={classes.tooltipIcon} />
                  </Tooltip>
                </div>
              )}
            </div>
          )
        })
      }
      return (
        <>
          {finalQuestionGroup.length > 0
          && (
            <div className={hasAnsweredAsExpected ? classes.groupContainer : ''} key={groupId}>
              <div className={classes.inlineHeader} onClick={this.handleOpenGroup(groupId)}>
                <Typography variant="subtitle1">
                  {openGroup === groupId ? 'Colapsar' : 'Abrir'}
                  {' '}
                  Grupo
                </Typography>
                <KeyboardArrowDown className={openGroup === groupId ? classes.openButton : ''} />
              </div>
              <Collapse in={openGroup === groupId}>
                {finalQuestionGroup.length > 0 && (
                  <div className={classes.deleteButton}>
                    <Button
                      className={classes.button}
                      onClick={this.handleToggleWarning(groupId, answerId)}
                    >
                      <HighlightOff />
                      <Typography variant="subtitle1">Eliminar</Typography>
                    </Button>
                  </div>
                )}
                {finalQuestionGroup}
              </Collapse>
            </div>
          )}
        </>
      )
    })
  }

  renderSubGroupsActions(actions, answer, value) {
    const { classes } = this.props
    let actionsButton = []
    const positiveResponse = value === '0'
    const { addSubgroup, reload } = this.props

    if (positiveResponse) {
      actionsButton = actions.map(action => {
        const body = {
          answer_id: answer.id,
          subquestion_group_id: action.subquestion_group_id
        }

        function addSG(info) {
          return () => {
            this.setState({ loading: true })
            addSubgroup(info).then(() => {
              this.setState({ loading: false })
              reload()
            })
          }
        }

        const { loading } = this.state

        return (
          <>
            {loading
              ? (
                <div style={{ position: 'relative', height: 32 }}>
                  <LoaderAnimator loading small />
                </div>
              )
              : <Button onClick={addSG.bind(this)(body)} key={action.subquestion_group_id} className={classes.addButton}>{action.add_text || ''}</Button>}
          </>
        )
      })
    }
    return actionsButton
  }

  renderQuestionInput(questions) {
    const { params, comments } = this.state
    return questions.map(question => {
      const answer = question.answers
      const answerValue = params[question.answers.id]?.answer
      const subgroups = question.sub_group
      const hasSubgroups = subgroups && subgroups.length > 0
      const subgroupQuestions = question.answers.subquestion_groups
      const actions = question.group_actions
      return (
        <>
          <UpdatedFieldRenderer
            onSubchange={this.handleSubChange}
            value={params[question.answers.id]?.answer || ''}
            comment={comments[question.answers.id] || ''}
            complete={parseQuestionToComplete(question)}
            onChange={this.handleChange}
            onComment={this.handleComment}
            subquestions={params[question.answers.id]?.subquestions || ''}
          />
          {hasSubgroups && subgroupQuestions
        && this.renderSubGroups(subgroupQuestions, answer, answerValue, subgroups)}
          {hasSubgroups && subgroupQuestions
        && this.renderSubGroupsActions(actions, answer, answerValue)}
        </>
      )
    })
  }

  render() {
    const { editing, selectedToDelete } = this.state
    const { classes, form, title, editable, tooltip } = this.props
    const questions = form.filter(question => {
      const body = editable ? question.answers.body : question.answers[0].body
      const isTitle = question.type === 'title' && question.reset_enumeration
      const isRadioWithBody = question.type === 'radio' && body
      const isSlider = question.type === 'slider'
      return isTitle || isRadioWithBody || isSlider
    })
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <div className={classes.inline}>
            <Typography variant="h2">{title}</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OnTouchTooltip title={tooltip}>
                <HelpOutline style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              </OnTouchTooltip>
              {editable
            && (
              <>
                {editing
                  ? (
                    <IconButton className="no-print" size="small" onClick={this.handleSave}>
                      <Save />
                    </IconButton>
                  )
                  : (
                    <IconButton className="no-print" size="small" onClick={this.handleOpenEdit}>
                      <Edit />
                    </IconButton>
                  )}
              </>
            )}
            </div>
          </div>
          <Divider className={`${classes.divider} ${classes.big}`} />
        </div>
        <Dialog fullWidth maxWidth="sm" open={selectedToDelete} onClose={this.handleToggleWarning(false, false)}>
          <div className={classes.dialog}>
            <Typography variant="h1">¿Seguro que quieres cerrar esta casilla?</Typography>
            <Typography variant="subtitle1">La información que haz ingresado será borrada</Typography>
            <div className={classes.dialogButtons}>
              <Button color="secondary" variant="contained" onClick={this.handleToggleWarning(false, false)}>
                Seguir editando
              </Button>
              <Button onClick={this.handleDeleteSubgroup}>
                Eliminar casilla
              </Button>
            </div>
          </div>
        </Dialog>
        {editing
          ? (
            <>
              {this.renderQuestionInput(form)}
              <div className={classes.saveButton}>
                <Button onClick={this.handleSave} color="primary" variant="contained">Guardar</Button>
              </div>
            </>
          )
          : this.renderQuestions(questions)}

      </div>
    );
  }
}

EditableHealthCriterium.propTypes = {
  classes: propTypes.object.isRequired,
  form: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  title: propTypes.string,
  tooltip: propTypes.string,
  addSubgroup: propTypes.func.isRequired,
  deleteSubgroup: propTypes.func.isRequired,
  reload: propTypes.func.isRequired,
  sendAnswers: propTypes.func.isRequired,
  editable: propTypes.bool,
  alerts: propTypes.object
}

EditableHealthCriterium.defaultProps = {
  title: 'Titulo',
  tooltip: '',
  editable: true,
  alerts: undefined
}

const mapDispatchToProps = dispatch => ({
  addSubgroup: body => dispatch(addSubgroupAction(body)),
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id)),
  deleteSubgroup: body => dispatch(deleteSubgroupAction(body))
})

export default connect(null, mapDispatchToProps)(withStyles(style)(EditableHealthCriterium))
