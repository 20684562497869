import React, { Component } from 'react'
import { withStyles, Paper } from '@material-ui/core'
import propTypes from 'prop-types'
import moment from 'moment'
import { getHistory } from 'API/User'
import DayList from '../Contenders/Profile/Tabs/DayList'
import { days } from '../Contenders/Profile/fake'
import HistoryHeader from './HistoryHeader'
import autobind from '../../Utils/autobind'

const style = () => ({
  container: {
    margin: 12,
  },
})

class History extends Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 0,
      histories: [],
      search: ''
    }
    autobind(History, this)
  }

  async componentDidMount() {
    const response = await getHistory()
    const { data: { info: histories } } = response
    this.setState({ histories })
  }

  handleChangeDate(option) {
    if (option === 'add') {
      this.setState(prevState => ({ offset: prevState.offset + 1 }), this.handleReload)
    } else {
      this.setState(prevState => ({ offset: prevState.offset - 1 }), this.handleReload)
    }
  }

  async handleReload() {
    const { offset } = this.state
    const newDate = moment().add(offset, 'months')
    const month = newDate.month() + 1
    const year = newDate.year()
    const body = { month, year }
    const response = await getHistory(body)
    const { data: { info: histories } } = response
    this.setState({ histories })
  }

  handleTodayClick() {
    this.setState({ offset: 0 }, this.handleReload)
  }

  handleChange(value) {
    this.setState({ search: value })
  }

  render() {
    const { classes } = this.props
    const { offset, histories, search } = this.state
    const currentDate = moment().add(offset, 'months')
    const filtered = histories.filter(history => {
      const inBody = String(history.body).toLowerCase().includes(search.toLowerCase())
      const inUser = String(history.user).toLowerCase().includes(search.toLowerCase())
      const inTime = moment(history.date).format('HH:mm').includes(search.toLowerCase())
      return inTime || inUser || inBody
    })
    return (
      <div className={classes.container}>
        <Paper>
          <HistoryHeader
            changeDate={this.handleChangeDate}
            currentDate={currentDate}
            handleToday={this.handleTodayClick}
            searchFunc={this.handleChange}
          />
          <DayList days={days} info={filtered} offset={offset} thirdColumn />
        </Paper>
      </div>
    )
  }
}

History.propTypes = {
  classes: propTypes.object.isRequired,
}

export default withStyles(style)(History)
