import stateMaker from '../Utils/reducers'

const functionalReducer = (state = { forms: [], result: {} }, action) => {
  switch (action.type) {
    case 'GET_FUNCTIONAL_EVALUATION':
      return stateMaker(state, 'forms', 'fetch', action)
    case 'GET_FUNCTIONAL_RESULT': {
      return stateMaker(state, 'result', 'fetch', action)
      // const newState = { ...state }
      // newState.result = action.payload.data.info
      // return newState
    }
    default:
      return state
  }
}

export default functionalReducer
