import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Typography, IconButton } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import autobind from '../Utils/autobind'

class SmallTabs extends Component {
  constructor(props) {
    super(props)
    autobind(SmallTabs, this)
  }

  handleRight() {
    const { onChange, value, tabs } = this.props
    const len = Object.keys(tabs).length
    const newValue = ((value + 1) + len) % len
    onChange('', newValue)
  }

  handleLeft() {
    const { onChange, value, tabs } = this.props
    const len = Object.keys(tabs).length
    const newValue = ((value - 1) + len) % len
    onChange('', newValue)
  }

  renderActualTab() {
    const { tabs, value } = this.props
    let result
    Object.keys(tabs).forEach((key, index) => {
      if (index === value) {
        result = <Typography variant="subtitle1">{key}</Typography>
      }
    })
    return result
  }

  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton onClick={this.handleLeft}>
          <KeyboardArrowLeft />
        </IconButton>
        {this.renderActualTab()}
        <IconButton onClick={this.handleRight}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
    )
  }
}

SmallTabs.propTypes = {
  tabs: propTypes.object.isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.number
}

SmallTabs.defaultProps = {
  value: 0
}

export default SmallTabs
