import React, { Component } from 'react'
import { Paper, Typography, withStyles, withTheme, Button, Divider, IconButton, MenuItem, Menu } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { MoreVert } from '@material-ui/icons'
import autobind from '../../../../Utils/autobind'
import ResultPercentages from '../../ResultPercentages'
import Conditional from '../../../../Shared/Conditional'
import ResultCriteria from '../../POI/ResultCriteria'
import { getFormResultAction } from '../../../../Actions/Contenders'
import InterviewAVD from './InterviewAVD'
import SetViewContext from '../../../Contenders/Profile/SetViewProvider'
import POITabs from '../POI/POITabs'
import SingleCriteriumResult from '../POI/SingleCriteriumResult'
import { editEvaluationComment, getEvaluationComment } from '../../../../API/Evaluations'
import GlobalComment from '../../../../Shared/GlobalComment'

const style = theme => ({
  paper: {
    backgroundColor: theme.palette.paper.light,
    textAlign: 'left',
    position: 'relative',
  },
  commentBox: {
    backgroundColor: theme.palette.grey.light,
    margin: '0 24px',
    padding: 12,
    paddingBottom: 24,
    color: '#3E3E3E',
    fontSize: 14
  },
  divider: {
    background: '#8A8A8A',
    height: 3
  },
  moreButton: {
    position: 'absolute',
    right: 12,
    top: 12
  },
  menu: {
    position: 'absolute',
    width: '200px',
    right: '12px',
    top: '48px',
  },
  submenu: {
    position: 'absolute',
    width: '150px',
    right: '95%',
    top: '75%',
  }
})

class ResultAVD extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      comment: '',
      openMenu: null,
      openSubmenu: null
    }
    autobind(ResultAVD, this)
  }

  async componentDidMount() {
    // Fetch the result
    const { event, contender, getFormResult } = this.props
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
      user_id: contender.user.id,
      type: event.route,
      withanswers: true
    }

    const commentBody = {
      form_id: event.form_id,
      event_id: event.event_id,
      user_id: contender.user.id
    }
    getFormResult(body)
    const response = await getEvaluationComment(commentBody)
    if (response.data.status !== 'success') return null
    const commentInfo = response.data.info
    return this.setState({ comment: commentInfo.comment })
  }

  handleChange(event) {
    const { target } = event;
    this.setState({ comment: target.value });
  }

  handleOpen() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  handleOpenMenu(id) {
    return () => {
      let element = id
      if (id !== null) {
        element = document.getElementById(id)
      }
      this.setState({ openMenu: element })
    }
  }

  handleOpenSubMenu(id) {
    return () => {
      let element = id
      if (id !== null) {
        element = document.getElementById(id)
      }
      this.setState({ openSubmenu: element })
    }
  }

  handleSave() {
    this.setState({ open: false })
    const { contender, event } = this.props
    const { comment } = this.state
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contender.user.id,
      comment
    }
    editEvaluationComment(body)
  }

  handleSetView(View, name) {
    return () => {
      const { setView } = this.context
      setView(View, name)
    }
  }

  renderCriteriaForm(option) {
    const { results } = this.props
    if (results && results.results && !Object.keys(results).includes('forms')) {
      return <div />
    }
    const formgroup = results.forms[option]
    if (formgroup) {
      const formKeys = Object.keys(formgroup)
      return formKeys.map((formKey, idx) => (
        <SingleCriteriumResult
          title={formKey}
          step={idx}
          info={formgroup[formKey]}
          isLast={idx === formKeys.length - 1}
        />
      ))
    }
    return null
  }

  renderCriteria() {
    const { results, theme } = this.props
    if (results && results.results && Object.keys(results.results).length > 0) {
      const realResults = results.results
      const { forms } = realResults
      if (forms) {
        const formKeys = Object.keys(forms)
        return formKeys.map(key => {
          const info = { ...forms[key] }
          const oldGroups = [...info.question_groups]
          const newGroups = oldGroups.map(group => ({ ...group, options: ['No observado', 'No lo hace solo/a', 'Lo hace con ayuda', 'Con supervisión', 'Lo hace solo/a'] }))
          info.question_groups = newGroups
          return (
            <ResultCriteria
              key={key}
              info={info}
              criterium={key}
              theme={theme}
              open
              handleOpen={undefined}
              fullWidth
            />
          )
        })
      }
    }
    return null
  }

  render() {
    const { open, openMenu, openSubmenu } = this.state
    const participant = null
    const { classes, results, event } = this.props
    return (
      <Paper className={classes.paper}>
        <div>
          <IconButton size="small" color="primary" className={classes.moreButton} onClick={this.handleOpenMenu('menu')} id="menu">
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={openMenu}
            open={openMenu !== null}
            onClose={this.handleOpenMenu(null)}
          >
            <MenuItem>Descargar</MenuItem>
            <MenuItem
              disabled={Object.keys(event.dynamic_criterium_id).length === 0}
              onClick={this.handleOpenSubMenu('submenu')}
              id="submenu"
            >
              Seleccionar para Entrevista
            </MenuItem>
            <Menu
              anchorEl={openSubmenu}
              open={openSubmenu !== null}
              onClose={this.handleOpenSubMenu(null)}
            >
              <MenuItem
                onClick={this.handleSetView(<InterviewAVD event={event} showAll title="Individual" />, 'Selección entrevista Individual')}
              >
                Individual

              </MenuItem>
              <MenuItem
                onClick={this.handleSetView(<InterviewAVD event={event} showAll title="Familiar" />, 'Selección entrevista Familiar')}
              >
                Familiar

              </MenuItem>
            </Menu>
          </Menu>
        </div>
        <Typography variant="h1" color="primary" style={{ padding: 24 }}>
          Resultados Evaluacion Actividades de la Vida Diaria
        </Typography>
        <Typography
          variant="subtitle1"
          color="primary"
          style={{ paddingLeft: 24 }}
        >
          Observaciones:
          <span style={{ fontWeight: 600 }}>
            {participant ? ` ${participant.name}` : ''}
          </span>
        </Typography>
        <div style={{ margin: '12px 24px' }}>
          <GlobalComment event={event} />
        </div>
        <div style={{
          margin: '6px 24px 12px 24px',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 'auto',
          position: 'relative'
        }}
        >
          <ResultPercentages
            title="Índice de autonomía:"
            subtitle1="Ponderación Postulante"
            subtitle2="Ponderación Acompañante"
            result={results?.results}
          />
          <Conditional condition={open}>
            <Button
              style={{ position: 'absolute', top: 0, right: 0 }}
              size="small"
              onClick={this.handleSave}
            >
              <Typography variant="subtitle1" color="primary">
                Listo
              </Typography>
            </Button>
          </Conditional>
        </div>
        <Divider className={classes.divider} />
        <POITabs>
          <div style={{ padding: 24, display: 'grid', gridGap: 48 }}>
            <Typography variant="subtitle1"> Cumple con criterios:</Typography>
            {this.renderCriteria()}
          </div>
          <div>
            {this.renderCriteriaForm('contender')}
          </div>
          <div>
            {this.renderCriteriaForm('companion')}
          </div>
        </POITabs>
      </Paper>
    )
  }
}

ResultAVD.contextType = SetViewContext

ResultAVD.propTypes = {
  classes: propTypes.object.isRequired,
  theme: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  getFormResult: propTypes.func.isRequired,
  results: propTypes.object,
  event: propTypes.object.isRequired,
}

ResultAVD.defaultProps = {
  results: undefined,
}

const mapStatetoProps = state => ({
  contender: state.contenders.selected,
  results: state.contenders.results,
})

const mapDispatchToProps = dispatch => ({
  getFormResult: body => dispatch(getFormResultAction(body))
})

export default connect(mapStatetoProps, mapDispatchToProps)(withTheme(withStyles(style)(ResultAVD)))
