import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    padding: 24,
    '& > *': {
      margin: '6px 0'
    }
  },
  buttons: {
    textAlign: 'end',
    marginTop: 12,
    '& > *': {
      marginLeft: 12
    }
  }
})

class RestrictionDialog extends Component {
  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose}>
        <div className={classes.container}>
          <Typography variant="subtitle1">
            Lo sentimos, este grupo no puede ser eliminado debido a que la información
            ingresada ya está siendo usada en la parte 2 del formulario
          </Typography>
          <div className={classes.buttons}>
            <Button onClick={onClose}>
              Cerrar
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

RestrictionDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func
}

RestrictionDialog.defaultProps = {
  open: false,
  onClose: undefined
}
export default withStyles(style)(RestrictionDialog)
