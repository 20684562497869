import React, { Component } from 'react'
import propTypes from 'prop-types'

export default class TabsContainer extends Component {
  render() {
    const { children, selected } = this.props
    const kids = React.Children.toArray(children)
    return (
      <div style={{ padding: 12 }}>
        {kids[selected]}
      </div>
    )
  }
}

TabsContainer.propTypes = {
  children: propTypes.node.isRequired,
  selected: propTypes.number
}

TabsContainer.defaultProps = {
  selected: 0
}
