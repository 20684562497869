import React, { Component } from 'react'
import { Paper, withStyles } from '@material-ui/core'
import moment from 'moment'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import CalendarHeader from './CalendarHeader'
import CalendarDaysTitles from './CalendarDaysTitles'
import CalendarDays from './CalendarDays'
import { loadEventsAction, createEventAction, editEventAction, searchEventsAction, deleteEventAction } from '../../Actions/Events'
import { loadContendersAction } from '../../Actions/Contenders'
import loadHandlersAction from '../../Actions/Handlers'
import setSearchQueryAction from '../../Actions/Search'
import autobind from '../../Utils/autobind'
import Loader from '../../Shared/Loader'

const style = () => ({
  container: {
    background: '#F7F7F7',
    padding: 12,
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

class Calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentDate: moment(),
      calendarType: 0,
      openCreate: false,
      clickedEvent: null,
      selectedDate: moment().format('YYYY-MM-DD'),
    }
    autobind(Calendar, this)
  }

  componentDidMount() {
    const { loadPossibleParticipants, loadHandlers, setSearchQuery } = this.props
    setSearchQuery()
    loadPossibleParticipants()
    loadHandlers()
  }

  handleChangeType(event) {
    const { target } = event
    this.setState({ calendarType: parseInt(target.value, 10) })
  }

  handleChangeDate(option) {
    const { currentDate } = this.state
    if (option === 'add') {
      this.setState({ currentDate: currentDate.add(1, 'months') })
    } else {
      this.setState({
        currentDate: currentDate.subtract(1, 'months'),
      })
    }
  }

  handleResetDate() {
    this.setState({ currentDate: moment(new Date()) })
  }

  handleFormOpen() {
    this.setState({ openCreate: true })
  }

  handleEventClick(event) {
    return () => {
      this.setState({ openCreate: true, clickedEvent: event })
    }
  }

  handleFormClose() {
    this.setState({ openCreate: false, clickedEvent: null })
  }

  handleFormSubmit(eventInfo) {
    const { createEvent, enqueueSnackbar, editEvent } = this.props
    if (eventInfo.eventLoaded) {
      editEvent(eventInfo, enqueueSnackbar)
      return
    }
    createEvent(eventInfo, enqueueSnackbar)
  }

  handleSelectDate(selectedDate) {
    this.setState({ selectedDate })
  }

  handleDelete(id) {
    const { deleteEvent, enqueueSnackbar } = this.props
    deleteEvent(id, enqueueSnackbar)
  }

  render() {
    const { classes,
      events, contenders, handlers, currentUser, search, searchEvents, loadAllEvents } = this.props
    const { currentDate, calendarType, openCreate, clickedEvent, selectedDate } = this.state
    const allEvents = search.query ? events.search : events.all
    return (
      <div style={{ padding: 12, boxSizing: 'border-box', height: '100%' }}>
        <Paper className={classes.container}>
          <CalendarHeader
            openCreate={openCreate}
            handleOpen={this.handleFormOpen}
            handleClose={this.handleFormClose}
            submitCreation={this.handleFormSubmit}
            resetDate={this.handleResetDate}
            changeDate={this.handleChangeDate}
            currentDate={currentDate}
            selectedDate={selectedDate}
            type={calendarType}
            contenders={contenders ? contenders.all : []}
            handlers={handlers ? handlers.all : []}
            changeType={this.handleChangeType}
            currentUser={currentUser}
            clickedEvent={clickedEvent}
            searchFunc={searchEvents}
            handleDelete={this.handleDelete}
          />
          <CalendarDaysTitles />
          <Loader action={loadAllEvents}>
            <CalendarDays
              handleEventClick={this.handleEventClick}
              events={allEvents || []}
              currentDate={`${currentDate.format('YYYY-MM')}`}
              selectDate={this.handleSelectDate}
            />
          </Loader>
        </Paper>
      </div>
    )
  }
}

Calendar.propTypes = {
  classes: propTypes.object.isRequired,
  loadAllEvents: propTypes.func.isRequired,
  loadPossibleParticipants: propTypes.func.isRequired,
  loadHandlers: propTypes.func.isRequired,
  createEvent: propTypes.func.isRequired,
  currentUser: propTypes.object.isRequired,
  search: propTypes.object.isRequired,
  events: propTypes.shape({
    all: propTypes.arrayOf(propTypes.object),
    search: propTypes.arrayOf(propTypes.object),
  }).isRequired,
  contenders: propTypes.shape({ all: propTypes.arrayOf(propTypes.object) }).isRequired,
  handlers: propTypes.shape({ all: propTypes.arrayOf(propTypes.object) }).isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  setSearchQuery: propTypes.func.isRequired,
  editEvent: propTypes.func.isRequired,
  searchEvents: propTypes.func.isRequired,
  deleteEvent: propTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  loadAllEvents: () => dispatch(loadEventsAction()),
  loadPossibleParticipants: () => dispatch(loadContendersAction()),
  loadHandlers: () => dispatch(loadHandlersAction()),
  createEvent: (event, snackbar) => dispatch(createEventAction(event, snackbar)),
  editEvent: (event, snackbar) => dispatch(editEventAction(event, snackbar)),
  setSearchQuery: query => dispatch(setSearchQueryAction(query)),
  searchEvents: query => dispatch(searchEventsAction(query)),
  deleteEvent: (id, snackbar) => dispatch(deleteEventAction(id, snackbar))
})

const mapStateToProps = state => ({
  events: state.events,
  contenders: state.contenders,
  handlers: state.handler,
  currentUser: state.user.current,
  search: state.search,
})

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(Calendar))
)
