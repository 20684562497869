import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import RecurrentContext from './RecurrentContext'
import RecurrentContainer from './RecurrentContainer'
import autobind from '../../Utils/autobind'
import { createFormEvaluation, getFormEvaluation, getFormEvaluations } from '../../API/Recurrent'
import AutoevaluationForm from '../Autoevaluation/AutoevaluationForm'
import AutoevaluationSingleResult from '../Autoevaluation/AutoevaluationSingleResult'
import AutoevaluationMultipleResult from '../Autoevaluation/AutoevaluationMultipleResult'

const style = () => ({

})

const eventId = 'c2554b2e-cb60-4b20-9022-bdf9398fc2cd'
const formId = '3dafa94a-d416-4010-a0fb-22a9f18fc8f4'
const realEvent = {
  event_id: eventId,
  form_id: formId,
  user_id: 'dc835da9-30bb-4e6d-ab4f-c8e49f5a951f'
}

function handleGetEvaluations(event) {
  return getFormEvaluations(event)
}

function handleCreate(event) {
  return createFormEvaluation(event)
}

class TemporalTest extends Component {
  constructor() {
    super()
    this.state = {
      view: 0,
      evaluation: null,
    }
    autobind(TemporalTest, this)
  }

  handleClickPending(evaluation) {
    const { history } = this.props
    const url = `/autoevaluation/${realEvent.event_id}/${realEvent.form_id}/${evaluation.id}/Nombre`
    history.push(url)
  }

  handleGetResult(evaluation) {
    const body = {
      form_id: realEvent.form_id,
      evaluation_id: evaluation.id,
    }
    getFormEvaluation(body).then(response => {
      if (response.data.status !== 'success') return false
      return this.setState({ evaluation: { id: evaluation.id, ...response.data.info }, view: 2 })
    })
  }

  render() {
    const { classes } = this.props;
    const { view, evaluation } = this.state
    if (view === 1) {
      return (
        <div className={classes.container}>
          <AutoevaluationForm evaluation={evaluation} event={realEvent} />
        </div>
      )
    }
    if (view === 2) {
      return (
        <div className={classes.container}>
          <AutoevaluationSingleResult evaluation={evaluation} event={realEvent} />
        </div>
      )
    }
    return (
      <div className={classes.container}>
        <RecurrentContext.Provider value={{
          event: realEvent,
          getEvaluations: handleGetEvaluations,
          getEvaluation: this.handleClickPending,
          getEvaluationResult: this.handleGetResult,
          createNewEvaluation: handleCreate,
          evaluationName: 'Autoevaluación',
        }}
        >
          <RecurrentContainer resultTab={AutoevaluationMultipleResult} />
        </RecurrentContext.Provider>
      </div>
    );
  }
}

TemporalTest.propTypes = {
  classes: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
}

TemporalTest.defaultProps = {

}

export default withStyles(style)(TemporalTest)
