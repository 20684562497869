/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { Button, Dialog, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { Delete, RestoreFromTrash } from '@material-ui/icons'
import RecurrentContext from '../RecurrentEvaluations/RecurrentContext'
import { createAuthorization } from '../../API/Authorizations'
import autobind from '../../Utils/autobind'
import ConditionalWrapper from '../../Shared/ConditionalWrapper'

const style = () => ({
  container: {
    padding: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)'
    }
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    }
  },
  leftAlignedText: {
    textAlign: 'left'
  },
  rightAlignedText: {
    textAlign: 'right',
    '& > *': {
      marginLeft: 12
    }
  }
})

class EDPSCard extends Component {
  constructor() {
    super()
    this.state = {
      openDelete: false
    }
    autobind(EDPSCard, this)
  }

  handleOpenDelete() {
    const { openDelete } = this.state
    this.setState({ openDelete: !openDelete })
  }

  async handleConfirmDelete() {
    const { reload, contender, evaluation } = this.props
    const { evaluationName } = this.context
    const extraInfo = {
      evaluation_group_id: evaluation.id
    }
    const body = { type_id: 5,
      text: `Borrar ${evaluationName} de ${contender.user.name}`,
      contender_id: contender.contender.id,
      extra_info: extraInfo }
    await createAuthorization(body, 'eliminar EDPS')
    reload()
    this.setState({ openDelete: false })
  }

  render() {
    const { openDelete } = this.state
    const { classes, evaluation, onClick } = this.props;
    const { evaluationName } = this.context
    const tooltipWrapper = e => (
      <Tooltip title="La solicitud para eliminar está evaluación se está revisando">
        {e}
      </Tooltip>
    )
    return (
      <>
        <ConditionalWrapper condition={evaluation.to_be_deleted} wrapper={tooltipWrapper}>
          <div
            className={classes.container}
            onClick={e => {
              e.stopPropagation()
              onClick()
            }}
          >
            <Typography variant="subtitle1">{`${evaluationName} N°${evaluation.name}`}</Typography>
            <Typography variant="subtitle1">{`Responsable: ${evaluation.allocate || 'Sin registro aún'}`}</Typography>
            <Typography variant="subtitle1">{`${moment(evaluation.updated_at).format('DD/MM/YYYY')}`}</Typography>
            <IconButton
              size="small"
              disabled={evaluation.to_be_deleted}
              onClick={e => {
                e.stopPropagation()
                this.handleOpenDelete()
              }}
            >
              {evaluation?.discarded_at ? <RestoreFromTrash /> : <Delete />}
            </IconButton>
          </div>
        </ConditionalWrapper>
        <Dialog open={openDelete} onClose={this.handleOpenDelete} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="h2">
              ¿Seguro que desea eliminar esta evaluación?
            </Typography>
            <div>
              <Typography>
                Al confirmar esta acción se creará una autorización para eliminar la evaluación
              </Typography>
            </div>
            <div className={classes.rightAlignedText}>
              <Button variant="outlined" onClick={this.handleOpenDelete}>
                Cancelar
              </Button>
              <Button color="secondary" variant="outlined" onClick={this.handleConfirmDelete}>
                Eliminar
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

EDPSCard.contextType = RecurrentContext

EDPSCard.propTypes = {
  classes: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  evaluation: propTypes.object.isRequired,
  onClick: propTypes.func,
  reload: propTypes.func
}

EDPSCard.defaultProps = {
  onClick: undefined,
  reload: undefined
}

const mapStateToProps = state => ({
  contender: state.contenders.selected
})

export default connect(mapStateToProps, null)(withStyles(style)(EDPSCard))
