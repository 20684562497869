import React from 'react'
import { Paper, Typography, Button, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from '../../../../Shared/Loader'
import { getFormResultAction, getDynamicCriteriaFormsAction, addQuestionToDynamicCriteriaAction, createQuestionDynamicCriteriumAction } from '../../../../Actions/Contenders'
import CriteriumLineChart from './CriteriumLIneChart'
import autobind from '../../../../Utils/autobind'
import QuestionsDialog from '../Shared/QuestionsDialog'
import SetViewContext from '../../../Contenders/Profile/SetViewProvider'

const style = theme => ({
  container: {
    padding: 12,
    textAlign: 'left'
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12
  },
  titleSelected: {
    color: theme.palette.confirm.main
  }
})

class InterviewGencat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      criterium: '',
      selected: [],
      sections: {},
      criteriums: {}
    }
    autobind(InterviewGencat, this)
  }

  componentDidMount() {
    const { getDynamicCriteria, event, title } = this.props
    let body
    if (typeof event.dynamic_criterium_id === 'string') {
      body = {
        dynamic_id: event.dynamic_criterium_id,
        form_id: event.form_id
      }
    } else {
      body = {
        dynamic_id: event.dynamic_criterium_id[`Entrevista ${title}`],
        form_id: event.form_id
      }
    }
    getDynamicCriteria(body).then(() => this.setupSelected())
  }

  handleSelectCriterium(criterium) {
    return () => {
      this.setState({ criterium }, this.handleOpenForm)
    }
  }

  handleOpenForm() {
    this.setState({ open: true })
  }

  handleCloseForm() {
    this.setState({ open: false })
  }

  handleSelectSection(questionId) {
    return event => {
      const { target } = event
      const { sections } = this.state
      const newSections = sections
      newSections[questionId] = target.value
      this.setState({ sections: newSections })
    }
  }

  handleBack() {
    const { setView, showPopup } = this.context
    const { title } = this.props
    showPopup(title)
    setView()
  }

  handleSubmit(criterium) {
    return () => {
      const { selected, sections, criteriums } = this.state
      const { addQuestion, event, title } = this.props
      const body = {
        questions: selected,
        section_info: sections,
        criterium_name: criterium,
        criteriums_detail: criteriums
      }
      body.criteria_id = typeof event.dynamic_criterium_id === 'string' ? event.dynamic_criterium_id : event.dynamic_criterium_id[`Entrevista ${title}`]
      addQuestion(body)
      this.handleCloseForm()
    }
  }

  handleQuestionCreation(creationData) {
    const { createQuestion, event, title } = this.props
    return () => {
      const postBody = {
        ...creationData,
        criterium_id: typeof event.dynamic_criterium_id === 'string' ? event.dynamic_criterium_id : event.dynamic_criterium_id[`Entrevista ${title}`],
      }
      createQuestion(postBody).then(() => this.setupSelected())
    }
  }

  handleSelect(value, criteriumName) {
    return () => {
      const { selected, criteriums } = this.state
      const newSelected = [...selected]
      const newCriteriums = { ...criteriums }
      const index = newSelected.indexOf(value)
      if (index === -1) {
        newSelected.push(value)
      } else {
        newSelected.splice(index, 1)
      }
      newCriteriums[value] = criteriumName
      this.setState({ selected: newSelected, criteriums: newCriteriums })
    }
  }

  setupSelected() {
    const { contenders } = this.props
    const newSelected = []
    const newSections = {}
    const newCriteriums = {}
    const dynamics = contenders.dynamics ? contenders.dynamics : {}
    Object.entries(dynamics).forEach(([criteriumName, info]) => {
      info.questions.forEach(question => {
        if (question.checked) {
          if (newSelected.indexOf(question.id) === -1) {
            newSelected.push(question.id)
            newSections[question.id] = question.section_index
            newCriteriums[question.id] = criteriumName
          }
        }
      })
    })
    this.setState({ selected: newSelected, sections: newSections, criteriums: newCriteriums })
  }

  renderCriteria() {
    const { results, contenders, withCheckbox } = this.props
    const dynamics = contenders.dynamics ? contenders.dynamics : {}
    const resultsObj = results && Array.isArray(results.results)
      ? results.results : [{ results: [] }]
    const [gencat] = resultsObj
    return gencat.results.map(criterium => {
      const chartData = criterium.questions.map(question => ({
        label: question.text,
        value: question.value,
        color: criterium.interview_selectable ? '#6CD2B8' : '#A2A2A2'
      }))
      const indagationQuestions = Object.keys(dynamics).includes(criterium.name)
        ? dynamics[criterium.name].questions.filter(q => q.checked) : []
      return (
        <CriteriumLineChart
          data={chartData}
          chartRef={criterium.short}
          title={criterium.name}
          totalScore={criterium.score}
          relativeScore={criterium.std_score_percentage}
          indagationQuestions={indagationQuestions}
          withCheckbox={withCheckbox}
          handleSelect={this.handleSelectCriterium(criterium.name)}
          isSelectable={criterium.interview_selectable}
        />
      )
    })
  }

  render() {
    const { classes, getFormResult, event, contenders } = this.props
    const { open, criterium, selected, sections } = this.state
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
      user_id: contenders.selected.user.id,
      type: event.route
    }
    return (
      <Paper className={classes.container}>
        <Typography variant="subtitle1">Criterios de evaluación</Typography>
        <Loader action={getFormResult} params={[body]}>
          {this.renderCriteria()}
        </Loader>
        <QuestionsDialog
          open={open}
          onClose={this.handleCloseForm}
          selected={selected}
          handleSelect={this.handleSelect}
          handleSelectSection={this.handleSelectSection}
          sections={sections}
          onCreation={this.handleQuestionCreation}
          onSubmit={this.handleSubmit(criterium)}
          criterium={criterium}
        />
        <div className={classes.button}>
          <Button color="secondary" variant="contained" size="small" onClick={this.handleBack}>
            Terminar selección
          </Button>
        </div>
      </Paper>
    )
  }
}

InterviewGencat.contextType = SetViewContext

InterviewGencat.propTypes = {
  classes: propTypes.object.isRequired,
  getFormResult: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  contenders: propTypes.arrayOf(propTypes.object).isRequired,
  results: propTypes.object,
  getDynamicCriteria: propTypes.func.isRequired,
  addQuestion: propTypes.func.isRequired,
  createQuestion: propTypes.func.isRequired,
  withCheckbox: propTypes.bool,
  title: propTypes.string
}

InterviewGencat.defaultProps = {
  results: undefined,
  withCheckbox: false,
  title: 'Individual'
}

const mapStateToProps = state => ({
  results: state.contenders.results,
  contenders: state.contenders
})

const mapDispatchToProps = dispatch => ({
  getFormResult: id => dispatch(getFormResultAction(id)),
  getDynamicCriteria: body => dispatch(getDynamicCriteriaFormsAction(body)),
  addQuestion: body => dispatch(addQuestionToDynamicCriteriaAction(body)),
  createQuestion: body => dispatch(createQuestionDynamicCriteriumAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(InterviewGencat))
