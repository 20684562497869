import React from 'react'
import propTypes from 'prop-types'
import { MenuItem, Dialog, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { CheckCircle, AddCircleOutline } from '@material-ui/icons'
import { TextField } from '../../Fields/Text'
import autobind from '../../../Utils/autobind'

const styles = theme => ({
  root: {
    fontSize: '0.8rem',
  },
  icon: {
    color: theme.palette.confirm.main,
  },
  dark: {
    color: theme.palette.grey.dark,
  },
  input: {
    padding: 12
  },
  menu: {
    maxHeight: 300,
    minHeight: 300,
    overflowY: 'auto'
  }
})

class CustomSelectWithCheckbox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      open: false,
    }
    autobind(CustomSelectWithCheckbox, this)
    this.renderValueFunc = this.renderValueFunc.bind(this)
  }

  handleSearch(event) {
    const { target } = event
    this.setState({ search: target.value })
  }

  handleOpen() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  handleChange(id) {
    return () => {
      const { handleChange, values } = this.props
      const newValues = [...values]
      if (values.includes(id.toString())) {
        const index = values.indexOf(id.toString())
        newValues.splice(index, 1)
      } else {
        newValues.push(id.toString())
      }
      const newEvent = {
        target: {
          value: newValues
        }
      }
      handleChange(newEvent)
    }
  }

  applySearch() {
    const { search } = this.state
    const { allValues } = this.props
    const filteredValues = allValues.filter(value => value.name.toLowerCase().includes(search))
    return filteredValues
  }

  renderValueFunc(values) {
    const { allValues, defaultText } = this.props
    if (!values.length) {
      return defaultText
    }
    return allValues
      .filter(e => values.includes(e.id))
      .map(e => e.name)
      .join(', ')
  }

  render() {
    const { search, open } = this.state
    const { classes, values, allValues, selectableFilter, defaultText } = this.props
    const filteredValues = this.applySearch(allValues)
    const selectedValues = allValues
      .filter(element => values.includes(element.id))
      .map(element => element.name)
    return (
      <>
        {values.length > 0
          ? <Typography variant="subtitle1" onClick={this.handleOpen}>{selectedValues.join(', ')}</Typography>
          : <Typography variant="subtitle1" onClick={this.handleOpen}>{defaultText}</Typography>}
        <Dialog open={open} onClose={this.handleOpen}>
          <div className={classes.input}>
            <TextField complete={{ placeholder: 'Buscar' }} value={search} onChange={this.handleSearch} />
          </div>
          <div className={classes.menu}>
            {filteredValues.filter(selectableFilter).map(e => (
              <MenuItem
                value={e.id}
                key={e.id}
                style={{ display: 'flex', justifyContent: 'space-between', minWidth: 255 }}
                onClick={this.handleChange(e.id)}
              >
                {e.name}
                {values.indexOf(e.id.toString()) !== -1 ? (
                  <CheckCircle className={classes.icon} size="small" />
                ) : (
                  <AddCircleOutline className={classes.dark} />
                )}
              </MenuItem>
            ))}
          </div>
        </Dialog>
      </>
    )
  }
}

CustomSelectWithCheckbox.defaultProps = {
  defaultText: 'Añadir participantes',
  selectableFilter: () => true
}

CustomSelectWithCheckbox.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.arrayOf(propTypes.string.isRequired).isRequired,
  handleChange: propTypes.func.isRequired,
  allValues: propTypes.arrayOf(propTypes.object).isRequired,
  defaultText: propTypes.string,
  selectableFilter: propTypes.func
}

export default withStyles(styles)(CustomSelectWithCheckbox)
