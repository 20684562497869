import { Typography, withStyles } from '@material-ui/core'
import React from 'react'
import propTypes from 'prop-types'
import BarChart from 'Shared/Charts/BarChart'
import DownloadableSection from 'Shared/DownloadableSection'

const style = () => ({
  container: {
    padding: 24
  },
  markContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      fontWeight: 600
    }
  },
  mark: {
    fontSize: 18,
    background: '#e8e8e8',
    padding: '6px 12px',
    borderRadius: 8,
    marginLeft: 12
  },
  title: {
    fontWeight: 600,
    margin: '32px 0 24px 0'
  }
})

class SingleResultCharts extends React.Component {
  calculateValues() {
    const { form } = this.props
    const results = []
    const sections = Object.keys(form)
    let questionsAmount = 0
    let globalTotal = 0
    sections.forEach(section => {
      const questions = form[section]
      let total = 0
      const sectionQuestionsAmount = questions.length * 3
      questions.forEach(question => {
        const answerValue = question.answers[0].body?.answer
        total += answerValue
        globalTotal += answerValue
        questionsAmount += 3
      })
      const sectionPercentage = Math.round((total * 100) / sectionQuestionsAmount)
      results.push({ label: section.split(':')[0], value: sectionPercentage, color: '#48A4B0' })
    })
    const globalAverage = (((globalTotal * 6) / questionsAmount) + 1).toFixed(1)
    return [globalAverage, results]
  }

  render() {
    const { classes, contender, name } = this.props
    const [global, results] = this.calculateValues()
    return (
      <DownloadableSection id="chart" name={`Resumen autoevaluación N°${name} ${contender.name}`}>
        <div className={classes.markContainer}>
          <Typography variant="h2">Nota total de evaluación</Typography>
          <Typography variant="h2" className={classes.mark}>{global}</Typography>
        </div>
        <div>
          <Typography variant="h2" className={classes.title}>Porcentaje de logro por ámbito</Typography>
          <BarChart
            data={results}
            name="porcentjeambito"
            showTitle={false}
            options={{
              detachedLabels: false,
              width: '100%',
              maxValue: 100,
              stepSize: 25,
              yEnableGrid: true,
              callback: value => `${value} %`,
            }}
          />
        </div>
      </DownloadableSection>
    )
  }
}

SingleResultCharts.propTypes = {
  classes: propTypes.object.isRequired,
  form: propTypes.object.isRequired,
  name: propTypes.string,
  contender: propTypes.object,
}

SingleResultCharts.defaultProps = {
  name: '0',
  contender: {}
}

export default withStyles(style)(SingleResultCharts)
