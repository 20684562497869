import React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import IndicatorAnswer from './IndicatorAnswer'
import IndicatorCheckbox from './IndicatorCheckbox'
import SliderInput from '../../../../Shared/Fields/SliderInput'

const style = () => ({
  container: {
    display: 'block',
    textAlign: 'left',
    padding: '12px 24px',
  },
  title: {
    marginBottom: 4,
    color: 'black',
    fontSize: 14,
  }
})

class AnsweredQuestion extends React.Component {
  render() {
    const { classes, question, index, interview } = this.props
    const isFamiliar = interview !== 'Entrevista Individual'
    return (
      <div className={classes.container}>
        <Typography className={classes.title}>{`${index}.- ${question.text}`}</Typography>
        {question.answers.map(answer => {
          const complete = {
            ...question,
            id: answer.id,
            // has_comment: false,
            name: answer.id,
            columns: '1fr'
          }
          const { comment } = answer
          if (isFamiliar) {
            if (question.is_indicator) {
              if (question.type === 'slider') {
                const value = answer.body ? answer.body : []
                if (Array.isArray(value)) {
                  return <IndicatorAnswer complete={complete} value={value} />
                }
                return (
                  <SliderInput
                    complete={complete}
                    value={value?.answer}
                    comment={comment}
                    hideTitle
                    disabled
                    onChange={() => {}}
                  />
                )
              }
              if (question.type === 'text') {
                const values = answer.body ? answer.body : []
                if (Array.isArray(values)) {
                  return <IndicatorCheckbox answers={values} complete={complete} />
                }
                return (
                  <IndicatorCheckbox
                    answers={[values]}
                    complete={complete}
                    removeCompanionName
                  />
                )
              }
            }
            const value = answer.body ? answer.body.answer : ''
            return <Typography>{value}</Typography>
          }
          if (question.type === 'slider') {
            const value = answer.body ? answer.body?.answer : ''
            return (
              <SliderInput
                complete={complete}
                value={value}
                comment={comment}
                hideTitle
                disabled
                onChange={() => {}}
              />
            )
          }
          if (complete.subquestions) {
            const values = answer.body ? [answer.body] : []
            return <IndicatorCheckbox answers={values} complete={complete} removeCompanionName />
          }
          const value = answer.body ? answer.body.answer : ''
          return <Typography>{value}</Typography>
        })}
      </div>
    )
  }
}

AnsweredQuestion.propTypes = {
  classes: propTypes.object.isRequired,
  question: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
  interview: propTypes.string.isRequired
}

export default withStyles(style)(AnsweredQuestion)
