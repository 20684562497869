import React from 'react'
import propTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography, Button, IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import './animation.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import autobind from '../../Utils/autobind'
import { sendEventAction } from '../../Actions/Events'

const style = theme => ({
  container: {
    padding: '12px 6px 12px 0px',
  },
  paper: {
    height: '100%',
    backgroundColor: theme.palette.paper.main,
    textAlign: 'left',
    overflowY: 'auto',
    position: 'relative',
  },
  indicator1: {
    width: 6,
    marginRight: 18,
    backgroundColor: theme.palette.confirm.main,
    borderRadius: 5,
    margin: '6px 18px 6px 0',
  },
  indicator2: {
    width: 6,
    marginRight: 18,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    margin: '6px 18px 6px 0',
  },
  row: {
    padding: '6px 24px 6px 0',
    margin: '6px 0px',
    cursor: 'pointer',
  },
  selectedRow1: {
    padding: '6px 24px',
    margin: '6px 0px',
    backgroundColor: theme.palette.confirm.main,
    width: '100%',
    borderRadius: 5,
  },
  selectedRow2: {
    padding: '6px 24px',
    margin: '6px 0px',
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    borderRadius: 5,
  },
  selectedText: {
    color: 'white',
    fontWeight: 600,
  },
  text: {
    color: theme.palette.grey.dark,
  },
  btn: {
    position: 'absolute',
    top: 6,
    right: 6,
    color: theme.palette.grey.dark
  },
  hiddenCopyInput: {
    position: 'absolute',
    opacity: 0,
    top: 0,
  }
})

class Participants extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      subAnchor: null,
      openMenu: false,
    }

    autobind(Participants, this)
  }

  handleSelect(id) {
    const { select } = this.props
    return () => select(id)
  }

  handleOpenMenu(event) {
    this.setState({ anchorEl: event.currentTarget, openMenu: 'menu' })
  }

  handleOpenSubmenu(event, option) {
    const { target } = event
    this.setState({ openSubmenu: option, subAnchor: target })
  }

  handleCloseMenu() {
    this.setState({ openMenu: '' })
  }

  handleGotoAttendance() {
    const { history } = this.props
    history.push('attendance')
  }

  handleGotoCompanion() {
    const { history, events } = this.props
    const { participants } = events.selected
    history.push(`/events/${events.selected.event.id}/companion/${participants[0].id}`)
  }

  handleSendEvent(id, option) {
    return () => {
      const { sendEvent } = this.props
      sendEvent(id, option)
      this.setState({ openSubmenu: null })
    }
  }

  handleCopyToClipboard(option) {
    return () => {
      const { enqueueSnackbar } = this.props
      const element = document.getElementById('hiddenInput')
      if (option === 'eavd') {
        element.value = `${window.location.origin}/eavd`
        element.select()
        element.setSelectionRange(0, 99999)
        document.execCommand('copy');
        enqueueSnackbar('Enlace copiado', { variant: 'success' })
        this.setState({ openSubmenu: null })
      } else {
        element.value = `${window.location.origin}/jornada-acompañante`
        element.select()
        element.setSelectionRange(0, 99999)
        document.execCommand('copy');
        enqueueSnackbar('Enlace copiado', { variant: 'success' })
        this.setState({ openSubmenu: null })
      }
    }
  }

  render() {
    const { classes,
      data,
      showFinish,
      selected,
      submit,
      events,
      bottomLabel,
      showActions,
      user } = this.props
    const { anchorEl, openMenu, openSubmenu, subAnchor } = this.state
    const currentUser = user.current
    const getRowClass = participant => (participant.allocation === currentUser.id ? 'animation-base odd' : 'animation-base even')
    const myParticipants = data.filter(p => p.allocation === user.current.id)
    const notMyParticipants = data.filter(p => p.allocation !== user.current.id)
    const finalParticipants = myParticipants.concat(notMyParticipants)
    return (
      <div style={{ height: '90%', position: 'fixed', width: window.innerWidth > 800 ? '15%' : '25%' }}>
        <Paper className={classes.paper}>
          <Grid
            container
            direction="column"
            style={{ justifyContent: 'space-between', height: '100%', flexWrap: 'unset' }}
          >
            <Grid item>
              <Typography variant="subtitle1" style={{ padding: 24, fontSize: 14 }}>
                Participantes
              </Typography>
              {finalParticipants.map(e => (
                <Grid container style={{ width: '100%', marginBottom: 12 }} key={e.id}>
                  <Grid
                    item
                    className={
                      selected === e.id ? `${getRowClass(e)} selected` : getRowClass(e)
                    }
                    key={e.id}
                    onClick={this.handleSelect(e.id)}
                  >
                    <Typography variant="body1">{e.name}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item>
              {showFinish && submit && (
                <Button
                  style={{ width: '100%', paddingTop: 12, paddingBottom: 12 }}
                  onClick={submit}
                >
                  <Typography variant="subtitle1">
                    {bottomLabel}
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
          {showActions && (
            <div className={classes.btn}>
              <IconButton size="small" variant="contained" name="menu" onClick={this.handleOpenMenu} color="primary">
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={openMenu === 'menu'}
                onClose={this.handleCloseMenu}
              >
                <MenuItem onClick={event => this.handleOpenSubmenu(event, 'companion')}>
                  Link Jornada acompañante
                </MenuItem>
                <MenuItem onClick={event => this.handleOpenSubmenu(event, 'eavd')}>
                  Link EAVD postulante
                </MenuItem>
                <MenuItem onClick={this.handleGotoAttendance}>Volver a Lista</MenuItem>
              </Menu>
              <Menu open={openSubmenu === 'eavd'} anchorEl={subAnchor} onClose={this.handleOpenSubmenu}>
                <MenuItem onClick={this.handleCopyToClipboard('eavd')}>
                  Copiar link de EAVD
                </MenuItem>
                <MenuItem onClick={this.handleSendEvent(events.selected.event.id, 'avd')}>
                  Activar EAVD
                </MenuItem>
                <input className={classes.hiddenCopyInput} id="hiddenInput" />
              </Menu>
              <Menu open={openSubmenu === 'companion'} anchorEl={subAnchor} onClose={this.handleOpenSubmenu}>
                <MenuItem onClick={this.handleCopyToClipboard('companion')}>
                  Copiar link de Jornada acompañante
                </MenuItem>
                <MenuItem onClick={this.handleSendEvent(events.selected.event.id, 'companion')}>
                  Activar Jornada acompañante
                </MenuItem>
                <input className={classes.hiddenCopyInput} id="hiddenInput" />
              </Menu>
            </div>
          )}
        </Paper>
      </div>
    )
  }
}

Participants.defaultProps = {
  data: [],
  showFinish: false,
  showActions: false,
  bottomLabel: 'Finalizar Evaluación',
  submit: undefined,

}

Participants.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.arrayOf(propTypes.object),
  showFinish: propTypes.bool,
  selected: propTypes.string.isRequired,
  select: propTypes.func.isRequired,
  submit: propTypes.func,
  history: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  events: propTypes.object.isRequired,
  bottomLabel: propTypes.string,
  sendEvent: propTypes.func.isRequired,
  showActions: propTypes.bool,
  enqueueSnackbar: propTypes.func.isRequired
}

const mapStateToProps = state => ({
  events: state.events,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  sendEvent: (id, option) => dispatch(sendEventAction(id, option))
})

export default withSnackbar(connect(
  mapStateToProps, mapDispatchToProps
)(withRouter(withStyles(style)(Participants))))
