import React, { Component } from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { Visibility } from '@material-ui/icons'
import autobind from 'Utils/autobind'

const style = () => ({
  container: {
    margin: '12px 0',
    background: '#ECECEC',
    boxShadow: '0px 1px 5px #31313143',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 12px'
  },
  name: {
    fontWeight: 600
  },
  button: {
    '& > * > *': {
      marginLeft: 6
    },
    width: 150
  }
})

class ProgramDownloadCard extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(ProgramDownloadCard, this)
  }

  handleOpenDialog() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { classes, name, dialog: InnerDialog } = this.props;
    const { open } = this.state
    return (
      <div className={classes.container}>
        <InnerDialog open={open} onClose={this.handleOpenDialog} />
        <Typography variant="h2" className={classes.name}>{name}</Typography>
        <Button color="primary" className={classes.button} onClick={this.handleOpenDialog}>
          Ver
          <Visibility />
        </Button>
      </div>
    );
  }
}

ProgramDownloadCard.propTypes = {
  classes: propTypes.object.isRequired,
  name: propTypes.string,
  dialog: propTypes.node
}

ProgramDownloadCard.defaultProps = {
  name: 'Nombre',
  dialog: null
}
export default withStyles(style)(ProgramDownloadCard)
