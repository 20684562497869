import axios from 'axios'
import store from '../store'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  timeout: 30000,
})

instance.interceptors.request.use(config => {
  const newConfig = { ...config }
  const { uid, client } = store.getState().user
  const accessToken = store.getState().user['access-token']
  newConfig.headers.uid = uid
  newConfig.headers['Content-Type'] = 'application/json'
  newConfig.headers.Accept = 'application/json'
  newConfig.headers['access-token'] = accessToken
  newConfig.headers.client = client
  return config
})

instance.interceptors.response.use(response => response,
  error => {
    if (error.response.status === 401) {
      store.getState().controller.logout()
    } else if (error.response.status === 304) { // Not modified
      return
    }
    store.getState().controller.snackbar('Error de conexión, intentar más tarde', { variant: 'error' })
  })

export default instance
