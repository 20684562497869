import React, { Component } from 'react'
import propTypes from 'prop-types'
import Chart from 'chart.js'
import './Setup'
import { Typography, withStyles } from '@material-ui/core'
import Conditional from '../Conditional'

const style = () => ({
  labels: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: 100
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    marginRight: 12
  }
})

const multipleColors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

class BarChart extends Component {
  componentDidMount() {
    const { name, data, options, multiple } = this.props
    const chartElement = document.getElementById(name)
    const myChartRef = chartElement.getContext('2d');
    chartElement.parentNode.style.height = options.height || '250px';
    chartElement.parentNode.style.width = options.width || '250px';
    const { parseLabelFunc } = options
    const labels = data.map(element => element.label)
    const values = data.map(element => element.value)
    const colors = data.map(element => element.color)
    let datasets = [{
      data: values,
      barThickness: options.barThickness || 32,
      backgroundColor: colors,
    }]

    if (multiple) {
      datasets = []
      const evaluationsAmount = values[0]?.length || 0
      let i = 0
      while (i < evaluationsAmount) {
        datasets.push({
          label: `Evaluación N°${i + 1}`,
          data: [],
          barThickness: options.barThickness || 32,
          backgroundColor: multipleColors[i],
        })
        // eslint-disable-next-line no-loop-func
        values.forEach(valueSet => { datasets[i].data.push(valueSet[i]) })
        i += 1
      }
    }

    // eslint-disable-next-line no-new
    this.chart = new Chart(myChartRef, {
      type: options.type || 'bar',
      data: {
        // Bring in data
        labels,
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          enabled: true,
        },
        hover: {
          mode: null
        },
        legend: {
          display: options.legend || false
        },
        scales: {
          xAxes: [{
            ticks: {
              display: !options.detachedLabels, // Detach here
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              min: options.minValue || 0,
              max: options.maxValue || 0,
              stepSize: options.stepSize || 5,
              callback(value) {
                const returnValue = parseLabelFunc ? parseLabelFunc(value) : value
                return returnValue
              },
              minRotation: options.minRotation || 0,
              maxRotation: options.maxRotation || 45
            },
            gridLines: {
              display: options.xEnableGrid || false,
              drawBorder: false
            },
            scaleLabel: {
              display: options.xHasTitle,
              labelString: options.xTitle,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: options.minValue || 0,
              max: options.maxValue || 50,
              stepSize: options.stepSize || 5,
              callback(value, index, allValues) {
                return options.callback ? options.callback(value, index, allValues) : value
              }
            },
            gridLines: {
              display: options.yEnableGrid || false,
              drawBorder: false
            },
            scaleLabel: {
              display: options.yHasTitle,
              labelString: options.yTitle,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        },
        animation: {
          onComplete: () => {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.font = 'Montserrat, sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.chart.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const metadata = dataset.data[index];
                // eslint-disable-next-line no-underscore-dangle
                if (options.type === 'horizontalBar') {
                  ctx.fillText(metadata, bar._model.x + 12, bar._model.y + 5);
                } else {
                  ctx.fillText(metadata, bar._model.x, bar._model.y - 5);
                }
              });
            });
          }
        }
      },
      plugins: [
        {
          id: 'custom_canvas_background_color',
          beforeDraw: chart => {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        },
      ]
    })
  }

  componentDidUpdate(prevProps) {
    const { data, options, multiple } = this.props
    const { parseLabelFunc } = options
    if (data !== prevProps.data) {
      const labels = data.map(element => element.label)
      const values = data.map(element => element.value)
      const colors = data.map(element => element.color)

      let datasets = [{
        data: values,
        barThickness: options.barThickness || 32,
        backgroundColor: colors,
      }]

      if (multiple) {
        datasets = []
        const evaluationsAmount = values[0]?.length || 0
        let i = 0
        while (i < evaluationsAmount) {
          datasets.push({
            label: `Evaluación N°${i + 1}`,
            data: [],
            barThickness: options.barThickness || 32,
            backgroundColor: multipleColors[i],
          })
          // eslint-disable-next-line no-loop-func
          values.forEach(valueSet => { datasets[i].data.push(valueSet[i]) })
          i += 1
        }
      }

      this.chart.data.labels = labels
      this.chart.data.datasets = datasets

      const scales = {
        xAxes: [{
          ticks: {
            display: !options.detachedLabels, // Detach here
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
            min: options.minValue || 0,
            max: options.maxValue || 0,
            stepSize: options.stepSize || 5,
            callback(value) {
              const returnValue = parseLabelFunc ? parseLabelFunc(value) : value
              return returnValue
            },
            minRotation: options.minRotation || 0,
            maxRotation: options.maxRotation || 45
          },
          gridLines: {
            display: options.xEnableGrid || false,
            drawBorder: false
          },
          scaleLabel: {
            display: options.xHasTitle,
            labelString: options.xTitle,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
          }
        }],
        yAxes: [{
          ticks: {
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
            display: true,
            min: options.minValue || 0,
            max: options.maxValue || 50,
            stepSize: options.stepSize || 5,
            callback(value, index, allValues) {
              return options.callback ? options.callback(value, index, allValues) : value
            }
          },
          gridLines: {
            display: options.yEnableGrid || false,
            drawBorder: false
          },
          scaleLabel: {
            display: options.yHasTitle,
            labelString: options.yTitle,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
          }
        }]
      }

      this.chart.options.scales = scales

      this.chart.update()
    }
  }

  renderLabels() {
    const { classes, data } = this.props
    return data.map((element, index) => {
      const toReturn = (
        <div className={classes.label} key={`${index + 1} - label`}>
          <div className={classes.dot} style={{ background: element.color }} />
          <Typography variant="body1" style={{ fontWeight: 600 }}>{element.label}</Typography>
        </div>
      )
      return toReturn
    })
  }

  render() {
    const { name, title, classes, options, showTitle } = this.props
    return (
      <div>
        {showTitle && (
          <Typography
            variant="subtitle1"
            color="grey"
            style={{ textAlign: 'start', marginBottom: 24 }}
          >
            {title}

          </Typography>
        )}
        <div>
          <canvas
            id={name}
            ref={this.chartRef}
          />
        </div>
        <Conditional condition={options.detachedLabels}>
          <div className={classes.labels}>
            {this.renderLabels()}
          </div>
        </Conditional>
      </div>
    )
  }
}

BarChart.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.object]),
  name: propTypes.string.isRequired,
  classes: propTypes.object.isRequired,
  data: propTypes.arrayOf(propTypes.object),
  options: propTypes.object,
  showTitle: propTypes.bool,
  multiple: propTypes.bool,
}

BarChart.defaultProps = {
  showTitle: true,
  title: 'Gráfico',
  data: [],
  options: {
    height: '200px',
    width: '100%',
    barThickness: 64,
    enableHover: false,
    detachedLabels: true,
    minValue: 0,
    maxValue: 100,
    stepSize: 20,
  },
  multiple: false
}

export default withStyles(style)(BarChart)
