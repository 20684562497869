import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Paper, withStyles, Typography, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import { Assessment } from '@material-ui/icons'
import Binnacle from './Brief/Binnacle'
import AddForm from './Brief/BinnacleEntryForm'
import BinnacleContext from './Brief/BinnacleProvider'
import ResultPercentages from '../../../Results/ResultPercentages'
// import History from './History'
import GeneralTable from '../../../../Shared/GeneralTable'
import Conditional from '../../../../Shared/Conditional'
import autobind from '../../../../Utils/autobind'
import { getProfileResultsAction, createBinnacleEntryAction, deleteBinnacleEntryAction, getContenderEventsAction } from '../../../../Actions/Contenders'
import SetViewContext from '../SetViewProvider'
import ResultReportContainer from '../ResultsReport/ResultReportContainer'
import Pendings from '../../../Handlers/Pendings'
import Rotatives from './Brief/Rotatives'

const contederProfileInfo = [
  { name: 'Diagnóstico', label: 'diagnosis' },
  { name: 'Diagnóstico E.', label: 'specific' },
  { name: 'Crisis', label: 'crisis' },
  { name: 'Frecuencia', label: 'frequency' },
]

const data = [
  { diagnosis: 'Visión', specific: 'Miopía', crisis: 'No', frequency: '' },
  { diagnosis: 'Asma', specific: 'Asma Bronquial', crisis: 'Sí', frequency: 'Trimes' },
  { diagnosis: 'Depresión', specific: 'Depresión', crisis: 'No', frequency: '' },
  { diagnosis: 'Otro', specific: 'Diagnóstico', crisis: 'Sí', frequency: 'Anual' },
  { diagnosis: 'Más', specific: 'Otro mas', crisis: 'Sí', frequency: 'Mensual' },
]

const style = theme => ({
  status: {
    color: theme.palette.confirm.main,
    fontSize: 14,
    margin: '0 0 12px 6px'
  },
  statusText: {
    color: theme.palette.grey.dark,
    fontWeight: 'bold',
  },
  container: {
    textAlign: 'left',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 12
    }
  },
  gridContainer: {
    display: 'grid',
    gridTemplateRows: 'auto auto auto auto auto',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridGap: 12,
  },
  barTitle: {
    padding: '12px 0 0 12px',
    fontWeight: 900,
    fontSize: 14
  },
  briefContainer: {
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 12
  },
  reportButton: {
    width: 370,
    diplay: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  reportButtonContainer: {
    textAlign: 'left',
    marginBottom: 12,
  },
  binnacleContainer: { gridArea: 'bit', padding: 12, marginBottom: 12 },
  gencatContainer: { gridArea: 'gencat', display: 'grid', gridTemplateRows: '0.2fr 1fr', alignItems: 'center', padding: 12 },
  radarChartContainer: { width: '100%', display: 'flex', justifyContent: 'center', color: '#2c2c2c' },
  fullWidth: {
    width: '100%'
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    color: theme.palette.grey.border,
    height: 20,
    width: 20,
  },
  titleResult: {
    fontWeight: 900,
    fontSize: 14,
    marginLeft: '10px',
    color: '#6A6A6A',
  },
  chip: {
    padding: '6px 18px',
    border: '1px solid grey',
    borderRadius: 24,
    fontWeight: 600
  },
  withoutProgram: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& > *': {
      flexBasis: '55%',
      minWidth: 250,
      flexGrow: 1
    },
    '& > *:first-child': {
      flexBasis: '40%',
      margin: '0 12px 12px 0'
    }
  },
  withProgram: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& > *': {
      flexBasis: '55%',
      minWidth: 250,
      flexGrow: 1,
      margin: '0 12px 12px 0'
    },
    '& > *:first-child': {
      flexBasis: '35%',
    },
    '& > *:last-child': {
      margin: 0
    }
  }
})

const statusesColors = {
  Aspirante: '#ffb33d',
  Postulante: '#d5602c',
  Candidato: '#FB8368',
  Trabajando: '#38b4a9',
  Egresado: '#1f8095',
  'Egresado con apoyo': '#27676A',
  Derivado: '#6A6A6A',
}

class Brief extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      title: '',
      body: '',
    }
    autobind(Brief, this)
  }

  componentDidMount() {
    const { getProfileResults, contender: { contender: { id } }, getEvents } = this.props
    const body = { c_id: id }
    getProfileResults(body)
    getEvents(body)
  }

  handleChange(field) {
    return e => {
      this.setState({ [field]: e.target.value })
    }
  }

  handleForm(formStatus) {
    return () => {
      this.setState({ open: formStatus })
    }
  }

  handleGotoReport() {
    const { setView } = this.context
    setView(<ResultReportContainer />, 'Informe Conocimiento de la Persona')
  }

  handleSave() {
    const { contender, user, createBinnacleEntry } = this.props
    const { title, body } = this.state
    const requestBody = { data: { title, body }, c_id: contender.contender.id, user_id: user.id }
    createBinnacleEntry(requestBody)
    this.setState({ open: false, title: '', body: '' })
  }

  handleDelete(entry) {
    const { deleteBinnacleEntry, contender } = this.props
    const body = {
      c_id: contender.contender.id,
      n_id: entry.id
    }
    deleteBinnacleEntry(body)
  }

  renderPostulante() {
    const { classes, profileResults, contender } = this.props
    const { open, title, body } = this.state
    const containerGrid = profileResults && profileResults.avd ? '\'hist hist hist poi poi poi poi\' \'hist hist hist avd avd avd avd\' \'ficha ficha ficha ficha gencat gencat gencat\' \'ficha ficha ficha ficha gencat gencat gencat\' \'bit bit bit bit bit bit bit\''
      : '\'hist hist hist poi poi poi poi\' \'hist hist hist avd avd avd avd\' \'bit bit bit bit bit bit bit\''
    return (
      <div className={classes.gridContainer} style={{ gridTemplateAreas: containerGrid }}>
        <div style={{ gridArea: 'hist', overflow: 'hidden' }} elevation={2}>
          <Pendings baseEvents={contender.events || []} />
        </div>
        <Conditional condition={profileResults && profileResults.gathering}>
          <Paper style={{ gridArea: 'poi', display: 'grid', gridTemplateRows: '0.2fr 1fr' }} elevation={2}>
            <Typography className={classes.barTitle}> Pauta de Observación Inicial </Typography>
            <ResultPercentages title="Ponderaciones" subtitle1="Facilitadora Laboral" subtitle2="Acompañante" containerStyle={{ padding: 12 }} result={profileResults ? profileResults.gathering : undefined} />
          </Paper>
        </Conditional>
        <Conditional condition={profileResults && profileResults.avd}>
          <Paper style={{ gridArea: 'avd' }} elevation={2}>
            <Typography className={classes.barTitle}> Índice de autonomía </Typography>
            <ResultPercentages title="Ponderaciones" subtitle1="Usuario" subtitle2="Acompañante" containerStyle={{ padding: 12 }} result={profileResults ? profileResults.avd : undefined} />
          </Paper>
        </Conditional>
        <Conditional condition={profileResults && profileResults.ficha}>
          <Paper style={{ gridArea: 'ficha' }} elevation={2}>
            <Typography className={classes.barTitle} style={{ margin: 6 }}>
              Ficha de Salud
            </Typography>
            <GeneralTable
              data={data}
              info={contederProfileInfo}
              disableFilters
              disablePagination
              responsive
            />
          </Paper>
        </Conditional>
        <BinnacleContext.Provider value={{
          handleForm: this.handleForm,
          handleChange: this.handleChange,
          handleSave: this.handleSave
        }}
        >
          <Paper className={classes.binnacleContainer} elevation={2}>
            <Binnacle onDelete={this.handleDelete} entries={contender.notes || []} />
          </Paper>
          <AddForm open={open} handleClose={this.handleForm(false)} title={title} body={body} />
        </BinnacleContext.Provider>
      </div>
    )
  }

  renderWithProgram() {
    const { classes, contender } = this.props
    const { open, title, body } = this.state
    return (
      <div className={classes.withProgram}>
        <Pendings baseEvents={contender.events || []} />
        <Rotatives />
        <BinnacleContext.Provider value={{
          handleForm: this.handleForm,
          handleChange: this.handleChange,
          handleSave: this.handleSave
        }}
        >
          <Paper className={classes.binnacleContainer} elevation={2}>
            <Binnacle onDelete={this.handleDelete} entries={contender.notes || []} />
          </Paper>
          <AddForm open={open} handleClose={this.handleForm(false)} title={title} body={body} />
        </BinnacleContext.Provider>
      </div>
    )
  }

  renderWithoutProgram() {
    const { classes, contender } = this.props
    const { open, title, body } = this.state
    return (
      <div className={classes.withoutProgram}>
        <Pendings baseEvents={contender.events || []} />
        <BinnacleContext.Provider value={{
          handleForm: this.handleForm,
          handleChange: this.handleChange,
          handleSave: this.handleSave
        }}
        >
          <Paper className={classes.binnacleContainer} elevation={2}>
            <Binnacle onDelete={this.handleDelete} entries={contender.notes || []} />
          </Paper>
          <AddForm open={open} handleClose={this.handleForm(false)} title={title} body={body} />
        </BinnacleContext.Provider>
      </div>
    )
  }

  render() {
    const { classes, contender } = this.props
    const stateColor = statusesColors[contender?.contender.state_type_name]
    let resume = this.renderWithProgram()
    if (['Postulante', 'Aspirante', 'Candidato'].includes(contender?.contender?.state_type_name)) { resume = this.renderPostulante() }
    if (['Egresado', 'Egresado con apoyo', 'Derivado', 'Desvinculado'].includes(contender?.contender?.state_type_name)) { resume = this.renderWithoutProgram() }
    return (
      <div className={classes.briefContainer}>
        <Paper className={classes.container} style={{ marginBottom: 12 }} elevation={2}>
          <Typography display="inline" className={classes.statusText}> Estado: </Typography>
          <Typography display="inline" className={classes.chip} style={{ color: stateColor, borderColor: stateColor }}>
            {contender?.contender.state_type_name}
          </Typography>
          {contender.program && (
            <Typography display="inline" className={classes.chip}>
              {contender?.program}
            </Typography>
          )}
        </Paper>
        {(['Candidato'].includes(contender?.contender?.state_type_name))
          && (
            <div className={classes.reportButtonContainer}>
              <Button className={classes.reportButton} color="primary" variant="contained" onClick={this.handleGotoReport}>
                Ver informe Conocimiento de la Persona
                <Assessment />
              </Button>
            </div>
          )}
        {resume}
      </div>
    )
  }
}

Brief.contextType = SetViewContext

Brief.propTypes = {
  classes: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  profileResults: propTypes.object.isRequired,
  getProfileResults: propTypes.func.isRequired,
  createBinnacleEntry: propTypes.func.isRequired,
  deleteBinnacleEntry: propTypes.func.isRequired,
  getEvents: propTypes.func.isRequired,
}

const mapStatetoProps = state => ({
  profileResults: state.contenders.profileResults,
  contender: state.contenders.selected,
  user: state.user.current,
})

const mapDispatchToProps = dispatch => ({
  getProfileResults: body => dispatch(getProfileResultsAction(body)),
  createBinnacleEntry: body => dispatch(createBinnacleEntryAction(body)),
  deleteBinnacleEntry: body => dispatch(deleteBinnacleEntryAction(body)),
  getEvents: body => dispatch(getContenderEventsAction(body)),
})

export default connect(mapStatetoProps, mapDispatchToProps)(withStyles(style)(Brief))
