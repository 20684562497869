import React, { Component } from 'react'
import { withStyles, MenuItem, Typography } from '@material-ui/core'
import MuiSelect from '@material-ui/core/Select'
import propTypes from 'prop-types'
import Comment from './Comment'

const style = () => ({
  container: {
    padding: 0
  },
  select: {
    padding: '6px 0 7px 6px',
  }
})

class MultipleSelect extends Component {
  renderOptions() {
    const { options } = this.props
    return options.map(option => (
      <MenuItem value={option.id.toString()} key={option.id}>{option.name}</MenuItem>
    ))
  }

  render() {
    const { classes, label, onChange, value,
      name, area, hasComment, onComment, commentArea, comment, highlighted } = this.props
    return (
      <>
        <div style={{ gridArea: area }} className={highlighted ? 'highlighted' : ''}>
          <Typography>{label}</Typography>
          <MuiSelect
            value={value || []}
            onChange={onChange}
            name={name}
            fullWidth
            variant="filled"
            classes={{ select: classes.select }}
            style={{ borderRadius: 0 }}
            multiple
          >
            {this.renderOptions()}
          </MuiSelect>
        </div>
        {hasComment
          && (
            <Comment onChange={onComment} value={comment} name={name} area={commentArea} />
          )}
      </>

    )
  }
}

MultipleSelect.propTypes = {
  classes: propTypes.object.isRequired,
  options: propTypes.arrayOf(propTypes.object),
  label: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.arrayOf(propTypes.string),
  name: propTypes.string.isRequired,
  area: propTypes.string,
  commentArea: propTypes.string,
  hasComment: propTypes.bool,
  comment: propTypes.string,
  onComment: propTypes.func,
  highlighted: propTypes.bool
}

MultipleSelect.defaultProps = {
  options: [],
  value: [],
  area: '',
  commentArea: '',
  hasComment: false,
  comment: '',
  onComment: undefined,
  highlighted: false
}

export default withStyles(style)(MultipleSelect)
