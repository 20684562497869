import React from 'react'
import { withStyles, Divider, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'
import { loadExternalHealthByUserAction } from '../../../Actions/User'
import { allocateExternalUserAction } from '../../../Actions/Handler'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import StepFormContainer from '../../../Shared/StepFormContainer'
import StepContainer from '../../../Shared/StepContainer'
import ReturnToDashboard from '../Shared/ReturnToDashboard'
import Header from '../Header/index'
import CheckupSection from './CheckupSection'
import autobind from '../../../Utils/autobind'

const style = () => ({
  container: {
    padding: 12
  },
  message: {
    textAlign: 'center',
    width: '85%',
    margin: 'auto',
    marginTop: 46
  },
})

class Health extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
    autobind(Health, this)
  }

  componentDidMount() {
    this.setState({ loading: true })
    const { loadExternalHealthByUser, match } = this.props
    const { params: { contenderId } } = match
    loadExternalHealthByUser(contenderId).then(() => {
      this.setState({ loading: false })
    })
  }

  handleAllocateParticipant() {
    const { allocateParticipant, user, event, match } = this.props
    const { params: { contenderId } } = match
    const requestBody = {
      event_id: event.event.id,
      participant_id: contenderId,
      owner_user_id: user.id
    }
    allocateParticipant(requestBody)
  }

  renderForm() {
    const { classes, event, match } = this.props
    const healthFormName = 'Ficha de Salud'
    if (!event || Object.keys(event).length === 0) return null
    const eventId = event.event.id
    const { params: { userId } } = match
    const checkups = event?.forms?.Controles || []
    const fichaMessage = (
      <div className={classes.message}>
        <Typography variant="subtitle2" className={classes.textContent}>
          Muchas gracias por acceder a completar la información. Esta ficha contiene preguntas
          referentes a la salud de la persona con discapacidad que usted acompaña.
        </Typography>
        <Typography variant="subtitle2" className={classes.textContent}>
          La información aquí entregada es de carácter confidencial.
        </Typography>
        <Typography variant="subtitle2" className={classes.textContent}>
          Por favor recuerde responder todas las preguntas
        </Typography>
        <Typography variant="subtitle2" className={classes.textContent}>
          Si hay alguna respuesta que no conoce, seleccione la opción
          {' '}
          <span className={classes.bold}>Sin Información</span>
        </Typography>
        <Divider className={classes.divider} />
      </div>
    )
    return (
      <StepContainer>
        <StepFormContainer
          form={event?.forms?.[healthFormName] || {}}
          title={healthFormName}
          message={fichaMessage}
          eventId={eventId}
          canRenderWithoutEvent
          singleAnswer
          endButtonText="Pasar a controles"
          endStep={1}
          saveStep={2}
          endAction={this.handleAllocateParticipant}
        />
        <CheckupSection message={fichaMessage} checkups={checkups} userId={userId} />
        <ReturnToDashboard />
      </StepContainer>
    )
  }

  render() {
    const { loading } = this.state
    return (
      <>
        <Header removeUserInfo />
        {loading ? <LoaderAnimator loading /> : this.renderForm()}
      </>
    )
  }
}

Health.propTypes = {
  classes: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  loadExternalHealthByUser: propTypes.func.isRequired,
  allocateParticipant: propTypes.func.isRequired,
  event: propTypes.object
}

Health.defaultProps = {
  event: undefined
}

const mapDispatchToProps = dispatch => ({
  loadExternalHealthByUser: contenderId => dispatch(loadExternalHealthByUserAction(contenderId)),
  allocateParticipant: body => dispatch(allocateExternalUserAction(body))
})

const mapStateToProps = state => ({
  user: state.user.current,
  event: state.events.selected
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(Health)))
