import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles'
import propTypes from 'prop-types'

const style = () => ({})

const WarningDialog = ({ open, handleClose, handleDelete }) => (
  <div>
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">Eliminar Evento</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          ¿Está seguro de eliminar el evento?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleDelete}>
          Eliminar
        </Button>
        <Button color="primary" autoFocus onClick={handleClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  </div>
)

WarningDialog.propTypes = {
  open: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
  handleDelete: propTypes.func.isRequired
}

export default withStyles(style)(WarningDialog)
