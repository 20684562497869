import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  bar: {
    background: '#DCE2F2',
    position: 'relative',
    '& > *': {
      color: 'white',
      position: 'relative'
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 24,
    padding: '4px 18px',
    margin: '12px 0'
  },
  progress: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    borderRadius: 24,
    zIndex: 0
  }
})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

class ProgressBars extends Component {
  renderBars() {
    const { data = [], classes } = this.props
    return data.map((group, index) => {
      const sumOfGroup = Object.values(group).reduce((x, y) => x + y, 0)
      const avgOfGroup = Math.round(sumOfGroup / Object.keys(group).length)
      return (
        <div className={classes.bar}>
          <div className={classes.progress} style={{ background: colors[index], width: `${avgOfGroup}%` }} />
          <Typography variant="subtitle1" style={{ color: avgOfGroup < 15 ? '#6a6a6a' : 'white' }}>{`Evaluación N°${index + 1}:`}</Typography>
          <Typography variant="subtitle1" style={{ color: avgOfGroup < 95 ? '#6a6a6a' : 'white' }}>
            {avgOfGroup}
            %
          </Typography>
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h2" style={{ fontWeight: 600 }}>Porcentaje de logro total por evaluación</Typography>
        {this.renderBars()}
      </div>
    );
  }
}

ProgressBars.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
}

ProgressBars.defaultProps = {

}
export default withStyles(style)(ProgressBars)
