import React from 'react'
import { withRouter } from 'react-router-dom'
import { Typography, Button, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import autobind from '../../../Utils/autobind'

const style = () => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh'
  },
  text: {
    textAlign: 'center',
    marginBottom: 12,
  },
  button: {
    width: 100
  }
})

class ReturnToDashboard extends React.Component {
  constructor(props) {
    super(props)
    autobind(ReturnToDashboard, this)
  }

  handleGoToDashboard() {
    const { history } = this.props
    history.push('/')
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Typography className={classes.text} variant="h1"> Volver al Inicio </Typography>
        <Button variant="outlined" color="primary" onClick={this.handleGoToDashboard} className={classes.button}> Inicio </Button>
      </div>
    )
  }
}

ReturnToDashboard.propTypes = {
  history: propTypes.object.isRequired,
  classes: propTypes.object.isRequired
}

export default withStyles(style)(withRouter(ReturnToDashboard))
