import React, { Component } from 'react'
import Chart from 'chart.js'
import { Paper } from '@material-ui/core'
import propTypes from 'prop-types'

const colors = ['#48A4B0', '#D5602C', '#FFB300', '#3E3E3E', 'purple']

export default class Charts extends Component {
  componentDidMount() {
    const { body, name, options } = this.props
    const chartElement = document.getElementById(name)
    const dataBody = body || []
    const companionNames = Object.keys(dataBody)
    const dataColors = colors.slice(0, companionNames.length)
    const myChartRef = chartElement.getContext('2d');
    chartElement.parentNode.style.height = '130px';
    chartElement.parentNode.style.width = `${(Object.keys(dataBody).length * 120) + 200}px`;
    const labels = dataBody.map(e => e.companion_name)
    const datasets = [{
      label: 'Respuesta',
      data: dataBody.map(e => parseInt(e.answer, 10)),
      barThickness: 25,
      backgroundColor: dataColors,
    }]
    // eslint-disable-next-line no-new
    new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels,
        datasets
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 0,
              max: 4,
              stepSize: 1,
              callback(value) {
                return `${options[value]}    `;
              }
            },
            gridLines: {
              display: true,
              drawBorder: false
            }
          }]
        }
      }
    });
  }

  render() {
    const { name, fullWidth } = this.props
    return (
      <Paper style={{ padding: 12, width: !fullWidth && '60%' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <canvas
              id={name}
              ref={this.chartRef}
              style={{ width: 50 }}
            />
          </div>
        </div>
      </Paper>
    )
  }
}

Charts.propTypes = {
  body: propTypes.arrayOf(propTypes.object),
  name: propTypes.string,
  fullWidth: propTypes.bool,
  options: propTypes.arrayOf(propTypes.string)
}

Charts.defaultProps = {
  body: {},
  name: 'theChart',
  fullWidth: false,
  options: ['No Observado', 'Nunca', 'A veces', 'Frecuentemente', 'Siempre']
}
