/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import {
  withStyles,
  ListItemText,
  ListItemIcon,
  Typography,
  ListItem,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
  Button,
  Collapse,
} from '@material-ui/core'
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  AddComment,
  EventAvailable,
  EventBusy,
} from '@material-ui/icons'
import { withSnackbar } from 'notistack'

const style = theme => ({
  '@keyframes appear': {
    from: { height: 0 },
    to: { height: '100%' },
  },
  container: {
    padding: 0,
    background: theme.palette.grey.light,
  },
  openContainer: {
    padding: 0,
    background: theme.palette.indigo.lighter,
  },
  formContainerOpen: {
    padding: 12,
    paddingTop: 0,
  },
  radio: {
    flexDirection: 'row',
  },
  label: {
    fontSize: 13,
    fontWeight: 600,
  },
  radioRoot: {
    color: theme.palette.primary.main,
  },
  confirmedIcon: {
    color: theme.palette.grey.dark,
  },
  notConfirmedColor: {
    color: '#6A6A6A',
  },
})

class Guest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      option: '',
      observation: '',
      openForm: false,
    }

    this.handleOpenForm = this.handleOpenForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleObserv = this.handleObserv.bind(this)
    this.handleSendObservation = this.handleSendObservation.bind(this)
  }

  componentDidMount() {
    const { confirmed, guest } = this.props
    if (confirmed === true) {
      this.setState({ option: '1' })
    } else if (guest.confirm_observation !== null) {
      this.setState({ option: '0', observation: guest.confirm_observation })
    }
  }

  componentDidUpdate(prevProps) {
    const { confirmed, guest } = this.props
    if (prevProps.confirmed !== confirmed) {
      if (confirmed === true) {
        this.setState({ option: '1' })
      } else if (guest.confirm_observation !== null) {
        this.setState({ option: '0', observation: guest.confirm_observation })
      }
    }
  }

  handleOpenForm() {
    const { openForm } = this.state
    this.setState({ openForm: !openForm })
  }

  handleChange(event) {
    const { target } = event
    const { confirm } = this.props
    const { observation } = this.state
    this.setState({ option: String(target.value) })
    const body = {
      id: target.name,
      confirmed: target.value === '1',
      confirm_observation: observation,
    }
    confirm(body)
  }

  handleObserv(event) {
    const { target } = event
    this.setState({ observation: target.value })
  }

  handleSendObservation(id) {
    const { save, enqueueSnackbar } = this.props
    const { observation } = this.state
    save(id, observation)
    this.setState({ openForm: false })
    enqueueSnackbar('Observacion guardada con exito', { variant: 'success' })
  }

  render() {
    const { classes, guest, confirmed, open, index, handleOpen } = this.props
    const { option, observation, openForm } = this.state
    return (
      <Paper
        className={!open ? classes.container : classes.openContainer}
        square
        style={{ transition: 'all 1s' }}
      >
        <ListItem button onClick={() => handleOpen(index)} style={{ padding: 12 }}>
          <ListItemText
            primary={(
              <Grid container justify="left">
                <Grid item style={{ marginRight: 16 }}>
                  <Typography
                    variant="subtitle1"
                    className={confirmed === false ? classes.notConfirmedColor : undefined}
                  >
                    {guest.name}
                  </Typography>
                </Grid>
                <Grid item>
                  {confirmed === true && (
                    <EventAvailable fontSize="small" className={classes.confirmedIcon} />
                  )}
                  {confirmed === false && (
                    <EventBusy fontSize="small" className={classes.notConfirmedColor} />
                  )}
                </Grid>
              </Grid>
            )}
            secondary={(
              <Typography variant="subtitle2" className={confirmed === false ? classes.notConfirmedColor : undefined}>
                {guest.phone || 'Sin teléfono registrado'}
              </Typography>
            )}
          />
          <ListItemIcon style={{ minWidth: 0 }}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </ListItemIcon>
        </ListItem>
        <Collapse in={open}>
          <div className={classes.formContainerOpen}>
            <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
              Confirma asistencia a Jornada de Presentación
            </Typography>
            <div>
              <RadioGroup value={option} onChange={this.handleChange} className={classes.radio}>
                <FormControlLabel
                  label={(
                    <Typography variant="body1">
                      Sí
                    </Typography>
                  )}
                  control={(
                    <Radio
                      disabled={guest.attended}
                      classes={{ root: classes.radioRoot }}
                      color="primary"
                    />
                  )}
                  value="1"
                  name={guest.id}
                />
                <FormControlLabel
                  label={(
                    <Typography variant="body1">
                      No
                    </Typography>
                  )}
                  control={(
                    <Radio
                      disabled={guest.attended}
                      classes={{ root: classes.radioRoot }}
                      color="primary"
                    />
                  )}
                  value="0"
                  name={guest.id}
                />
              </RadioGroup>
            </div>
            {((!confirmed && confirmed !== '') || option === '0') && (
              <>
                <div style={{ display: 'flex' }}>
                  <AddComment
                    color="primary"
                    style={{ marginRight: 3 }}
                    onClick={this.handleOpenForm}
                  />
                  {openForm ? (
                    <TextField
                      placeholder="Escribe algo"
                      fullWidth
                      variant="filled"
                      InputProps={{
                        style: { borderRadius: 0, padding: 3, fontSize: 13, background: '#ffffff61' },
                      }}
                      multiline
                      value={observation}
                      onChange={this.handleObserv}
                      rows={3}
                      autoFocus
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      onClick={this.handleOpenForm}
                      style={{ padding: '1px 0 0 3px' }}
                    >
                      {observation}
                    </Typography>
                  )}
                </div>
                {openForm && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 6 }}>
                    <Button
                      color="primary"
                      style={{ position: 'relative', right: 0 }}
                      onClick={() => this.handleSendObservation(guest.id)}
                      classes={{ label: classes.label }}
                    >
                      Listo
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </Collapse>
      </Paper>
    )
  }
}

Guest.propTypes = {
  classes: propTypes.object.isRequired,
  guest: propTypes.object.isRequired,
  confirm: propTypes.func.isRequired,
  confirmed: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  save: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  index: propTypes.string,
  open: propTypes.bool,
  handleOpen: propTypes.func,
}

Guest.defaultProps = {
  confirmed: true,
  index: '',
  open: false,
  handleOpen: undefined,
}

export default withSnackbar(withStyles(style)(Guest))
