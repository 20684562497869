import stateMaker from '../Utils/reducers'

const authorizationsReducer = (state = { all: [] }, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'LOAD_AUTHORIZATIONS':
      return stateMaker(newState, 'all', 'fetch', action)
    case 'APPROVE_AUTHORIZATION':
      return stateMaker(newState, 'all', 'fetch', action)
    case 'REJECT_AUTHORIZATION':
      return stateMaker(newState, 'all', 'fetch', action)
    default:
      return state
  }
}

export default authorizationsReducer
