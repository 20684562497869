import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { withTheme, withStyles, Divider, Typography, Button, Paper, IconButton, MenuItem, Menu, Checkbox } from '@material-ui/core'
import { CheckCircle, CheckCircleOutline, ErrorOutlineOutlined, MoreVert } from '@material-ui/icons'
import { getFormResultAction, getFormAction } from '../../../../Actions/Contenders'
import Conditional from '../../../../Shared/Conditional'
import Loader from '../../../../Shared/Loader'
import StepContainer from '../../../../Shared/StepContainer'
import SingleCriteriumResult from './SingleCriteriumResult'
import ResultPercentages from '../../ResultPercentages'
import autobind from '../../../../Utils/autobind'
import IndividualInterviewPOI from './IndividualInterviewPOI'
import SetViewContext from '../../../Contenders/Profile/SetViewProvider'
import POITabs from './POITabs'
import ResultCriteria from '../../POI/ResultCriteria'
import { editEvaluationComment, getEvaluationComment } from '../../../../API/Evaluations'
import GlobalComment from '../../../../Shared/GlobalComment'

const style = theme => ({
  paper: {
    backgroundColor: theme.palette.paper.light,
    textAlign: 'left',
    position: 'relative'
  },
  commentBox: {
    backgroundColor: theme.palette.grey.light,
    margin: '0 24px',
    padding: 12,
    paddingBottom: 24,
    color: '#3E3E3E',
    fontSize: 14
  },
  divider: {
    background: '#8A8A8A',
    height: 3,
    margin: 18
  },
  moreButton: {
    position: 'absolute',
    right: 12,
    top: 12
  },
  menu: {
    position: 'absolute',
    width: '200px',
    right: '12px',
    top: '48px',
  },
  submenu: {
    position: 'absolute',
    width: '150px',
    right: '95%',
    top: '75%',
  },
  results: {
    padding: 12
  },
  specialCriterium: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  specialCriteriumContainer: {
    margin: '24px 0'
  },
  specialTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }
})

class ResultPOI extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      comment: '',
      openMenu: null,
      openSubmenu: null
    }
    autobind(ResultPOI, this)
  }

  async componentDidMount() {
    const { contender, getFormResult, event } = this.props
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
      user_id: contender.user.id,
      type: event.route,
      withanswers: true
    }
    const commentBody = {
      form_id: event.form_id, event_id: event.event_id, user_id: contender.user.id
    }
    getFormResult(body)
    const response = await getEvaluationComment(commentBody)
    if (response.data.status !== 'success') return null
    const commentInfo = response.data.info
    return this.setState({ comment: commentInfo.comment })
  }

  handleChange(event) {
    const { target } = event;
    this.setState({ comment: target.value });
  }

  handleOpen() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  handleOpenMenu(id) {
    return () => {
      let element = id
      if (id !== null) {
        element = document.getElementById(id)
      }
      this.setState({ openMenu: element })
    }
  }

  handleOpenSubMenu(id) {
    return () => {
      let element = id
      if (id !== null) {
        element = document.getElementById(id)
      }
      this.setState({ openSubmenu: element })
    }
  }

  handleSave() {
    this.setState({ open: false })
    const { contender, event } = this.props
    const { comment } = this.state
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contender.user.id,
      comment
    }
    editEvaluationComment(body)
  }

  handleSetView(View, name) {
    return () => {
      const { setView } = this.context
      setView(View, name)
    }
  }

  renderCriteria(option) {
    const { results } = this.props
    if (!Object.keys(results).includes('forms')) {
      return <div />
    }
    const formgroup = results.forms[option]
    if (formgroup) {
      const formKeys = Object.keys(formgroup)
      return formKeys.map((formKey, idx) => (
        <SingleCriteriumResult
          title={formKey}
          step={idx}
          info={formgroup[formKey]}
          isLast={idx === formKeys.length - 1}
        />
      ))
    }
    return null
  }

  renderResultCriteria() {
    const { results, theme } = this.props
    if (results && results.results && Object.keys(results.results).length > 0) {
      const formgroup = results.results.forms
      if (formgroup) {
        const formKeys = Object.keys(formgroup)
        const criteria = formKeys
          .filter(crit => !crit.includes('Verbaliza') && !crit.includes('Patología') && !crit.includes('Agresividad'))
          .map(formKey => (
            <ResultCriteria
              key={formKey}
              info={formgroup[formKey]}
              criterium={formKey}
              theme={theme}
              open
              handleOpen={this.handleOpenCriteria}
              fullWidth
              withObservation
            />
          ));
        return (
          <>
            {this.renderVerbalize()}
            {this.renderPatologies()}
            {criteria}
          </>
        )
      }
    }
    return null
  }

  renderVerbalize() {
    const { results, theme, classes } = this.props
    if (results && results.results && Object.keys(results.results).length > 0) {
      const formgroup = results.results.forms
      const mainForms = results.forms
      const companionQuestions = mainForms?.companion?.['Información General']?.empty
      if (formgroup) {
        const formKeys = Object.keys(formgroup)
        const verbalizeKey = formKeys.find(key => key.includes('Verbaliza'))
        const verbalizeForm = formgroup[verbalizeKey]
        const verbalizes = verbalizeForm?.question_groups?.[0]?.questions?.[0]?.question_check
        const isCorrectHandler = verbalizeForm?.question_groups?.[0]?.questions?.[0]?.answers?.[0]?.body?.answer === '0'
        const companionWorkInteresQuestion = companionQuestions?.find(q => q.text.includes('aspirante quiere trabajar'))
        const isCorrectCompanion = companionWorkInteresQuestion?.answers?.body?.answer === '0'
        return (
          <>
            <div className={classes.specialCriterium}>
              <Checkbox
                disabled
                checked={verbalizes}
                icon={
                  <ErrorOutlineOutlined style={{ color: theme.palette.alert.main }} />
                }
                checkedIcon={(
                  <CheckCircle
                    style={{ color: theme.palette.confirm.main }}
                  />
                )}
                value="checkedH"
              />
              <Typography variant="subtitle2">
                Verbaliza su interés por trabajar
              </Typography>
            </div>
            <div style={{ marginLeft: 24 }}>
              <div className={classes.specialTitle}>
                {isCorrectHandler
                  ? <CheckCircleOutline style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />
                  : <ErrorOutlineOutlined style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />}
                <Typography variant="subtitle1">
                  Facilitador:
                  {isCorrectHandler ? ' Si' : ' No'}
                </Typography>
              </div>
              <div className={classes.specialTitle}>
                {isCorrectCompanion
                  ? <CheckCircleOutline style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />
                  : <ErrorOutlineOutlined style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />}
                <Typography variant="subtitle1">
                  Acompañante:
                  {isCorrectCompanion ? ' Si' : ' No'}
                </Typography>
              </div>
            </div>
          </>
        )
      }
    }
    return null
  }

  renderPatologies() {
    const { results, theme, classes } = this.props
    if (results && results.results && Object.keys(results.results).length > 0) {
      const formgroup = results.results.forms
      if (formgroup) {
        const formKeys = Object.keys(formgroup)
        const keys = formKeys.filter(criterium => criterium.includes('Patología') || criterium.includes('Agresividad'))
        return keys.map(key => {
          const patologyQuestion = formgroup[key]
          const patologyAnswers = patologyQuestion?.question_groups?.[0]?.questions?.[0]
          const isCorrectHandler = patologyAnswers?.answers?.[0]?.body?.answer === '1'
          const isCorrectCompanion = patologyAnswers?.answers?.[1]?.body?.answer <= 1
          const isBothCorrect = isCorrectHandler && isCorrectCompanion
          return (
            <>
              <div className={classes.specialCriteriumContainer}>
                <div className={classes.specialTitle}>
                  <Checkbox
                    disabled
                    checked={isBothCorrect}
                    icon={
                      <ErrorOutlineOutlined style={{ color: theme.palette.alert.main }} />
                    }
                    checkedIcon={(
                      <CheckCircle
                        style={{ color: theme.palette.confirm.main }}
                      />
                    )}
                    value="checkedH"
                  />
                  <Typography variant="subtitle2">
                    {key}
                  </Typography>
                </div>
                <div style={{ marginLeft: 24 }}>
                  <div className={classes.specialTitle}>
                    {isCorrectHandler
                      ? <CheckCircleOutline style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />
                      : <ErrorOutlineOutlined style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />}
                    <Typography variant="subtitle1">
                      Facilitador:
                      {isCorrectHandler ? ' No' : ' Si'}
                    </Typography>
                  </div>
                  <div className={classes.specialTitle}>
                    {isCorrectCompanion
                      ? <CheckCircleOutline style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />
                      : <ErrorOutlineOutlined style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />}
                    <Typography variant="subtitle1">
                      Acompañante:
                      {isCorrectCompanion ? ' No' : ' Si'}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          )
        })
      }
    }
    return null
  }

  render() {
    const { open, openMenu, openSubmenu } = this.state
    const { classes, results, event, getForm, contender } = this.props
    const participant = null
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
      params: {
        user: contender.user.id,
      }
    }
    return (
      <div>
        <Paper className={classes.paper}>
          <div>
            <IconButton size="small" color="primary" className={classes.moreButton} onClick={this.handleOpenMenu('menu')} id="menu">
              <MoreVert />
            </IconButton>
            <Menu anchorEl={openMenu} open={openMenu !== null} onClose={this.handleOpenMenu(null)}>
              <MenuItem>Descargar</MenuItem>
              <MenuItem
                onClick={this.handleOpenSubMenu('submenu')}
                id="submenu"
                disabled={Object.keys(event.dynamic_criterium_id).length === 0}
              >
                Seleccionar para Entrevista
              </MenuItem>
              <Menu
                anchorEl={openSubmenu}
                open={openSubmenu !== null}
                onClose={this.handleOpenSubMenu(null)}
              >
                <MenuItem
                  onClick={this.handleSetView(<IndividualInterviewPOI
                    event={event}
                    showAll
                    title="Individual"
                  />, 'Selección entrevista Individual')}
                >
                  Individual

                </MenuItem>
                <MenuItem
                  onClick={this.handleSetView(<IndividualInterviewPOI
                    event={event}
                    showAll
                    title="Familiar"
                  />, 'Selección entrevista Familiar')}
                >
                  Familiar

                </MenuItem>
              </Menu>
            </Menu>
          </div>

          <Typography variant="h1" color="primary" style={{ padding: 24 }}>
            Resultados Pauta de Observación Inicial
          </Typography>
          <Typography
            variant="subtitle1"
            color="grey"
            style={{ paddingLeft: 24 }}
          >
            Observaciones:
            <span style={{ fontWeight: 600 }}>
              {participant ? ` ${participant.name}` : ''}
            </span>
          </Typography>
          <div style={{ margin: '12px 24px' }}>
            <GlobalComment event={event} />
          </div>
          <div style={{
            margin: '6px 24px 12px 24px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: 'auto',
            position: 'relative'
          }}
          >
            <ResultPercentages
              title="Ponderación Promedio:"
              subtitle1="Ponderación Facilitador/a Laboral"
              subtitle2="Ponderación Acompañante"
              result={results.results}
            />
            <Conditional condition={open}>
              <Button
                style={{ position: 'absolute', top: 0, right: 0 }}
                size="small"
                onClick={this.handleSave}
              >
                <Typography variant="subtitle1" color="primary">
                  Listo
                </Typography>
              </Button>
            </Conditional>
          </div>
          <Divider className={classes.divider} />
          <Loader action={getForm} params={[body]}>
            <POITabs>
              <div className={classes.results}>
                {this.renderResultCriteria()}
              </div>
              <StepContainer>
                {this.renderCriteria('contender')}
              </StepContainer>
              <StepContainer>
                {this.renderCriteria('companion')}
              </StepContainer>
            </POITabs>
          </Loader>
        </Paper>
      </div>
    )
  }
}

ResultPOI.contextType = SetViewContext

ResultPOI.propTypes = {
  classes: propTypes.object.isRequired,
  theme: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  getFormResult: propTypes.func.isRequired,
  getForm: propTypes.func.isRequired,
  results: propTypes.object,
  forms: propTypes.object,
  event: propTypes.object.isRequired
}

ResultPOI.defaultProps = {
  results: { results: [] },
  forms: {}
}

const mapStatetoProps = state => ({
  contender: state.contenders.selected,
  results: state.contenders.results,
  forms: state.contenders.forms
})

const mapDispatchToProps = dispatch => ({
  getFormResult: body => dispatch(getFormResultAction(body)),
  getForm: body => dispatch(getFormAction(body))
})

export default connect(mapStatetoProps, mapDispatchToProps)(withTheme(withStyles(style)(ResultPOI)))
