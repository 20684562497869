import React, { Component } from 'react'
import { withStyles, Paper } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import Forms from './Forms'
import { changeCompetencyEvaluationStateAction, createCompetencyEvaluationAction, getAllCompetenciesResultAction, getCompetencyEvaluationAction, getCompetencyEvaluationRestulAction, getCompetencyEvaluationsAction } from '../../Actions/Competency'
import autobind from '../../Utils/autobind'
import LoaderDialog from '../../Shared/LoaderDialog'

const style = () => ({
  container: {
    padding: 24
  }
})

class CSDMain extends Component {
  constructor() {
    super()
    this.state = {
      loading: false
    }

    autobind(CSDMain, this)
  }

  componentDidMount() {
    const { getCompetencyEvaluations, event, contenders } = this.props
    const body = {
      form_id: event.form_id,
      user_id: contenders?.selected?.user?.id
    }
    getCompetencyEvaluations(body)
  }

  handleCreate(body) {
    const { createCompetencyEvaluation } = this.props
    this.setState({ loading: true })
    createCompetencyEvaluation(body).then(() => {
      this.componentDidMount()
      this.setState({ loading: false })
    })
  }

  handleReload() {
    this.componentDidMount()
  }

  render() {
    const {
      classes,
      competency,
      getCompetencyEvaluation,
      getCompetencyEvaluationResult,
      // getAllCompetenciesResult,
      changeCompetencyEvaluationState,
      event,
      contenders
    } = this.props;
    const { loading } = this.state
    const allEvaluations = competency.evaluations || []
    return (
      <Paper className={classes.container}>
        <LoaderDialog open={loading} />
        <Forms
          event={event}
          evaluations={allEvaluations}
          getEvaluationForm={getCompetencyEvaluation}
          getEvaluationResult={getCompetencyEvaluationResult}
          newEvaluation={this.handleCreate}
          contenders={contenders}
          changeStatus={changeCompetencyEvaluationState}
          reload={this.handleReload}
        />
      </Paper>
    );
  }
}

CSDMain.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  competency: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  getCompetencyEvaluations: propTypes.func.isRequired,
  getCompetencyEvaluation: propTypes.func.isRequired,
  getCompetencyEvaluationResult: propTypes.func.isRequired,
  // getAllCompetenciesResult: propTypes.func.isRequired,
  changeCompetencyEvaluationState: propTypes.func.isRequired,
  createCompetencyEvaluation: propTypes.func.isRequired,
}

CSDMain.defaultProps = {

}

const mapStateToProps = state => ({
  competency: state.competency,
  contenders: state.contenders
})

const mapDispatchToProps = dispatch => ({
  getCompetencyEvaluations: body => dispatch(getCompetencyEvaluationsAction(body)),
  getCompetencyEvaluation: body => dispatch(getCompetencyEvaluationAction(body)),
  getCompetencyEvaluationResult: body => dispatch(getCompetencyEvaluationRestulAction(body)),
  changeCompetencyEvaluationState: body => dispatch(changeCompetencyEvaluationStateAction(body)),
  getAllCompetenciesResult: body => dispatch(getAllCompetenciesResultAction(body)),
  createCompetencyEvaluation: body => dispatch(createCompetencyEvaluationAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(CSDMain))
