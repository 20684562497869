import React from 'react'
import { withStyles, Typography, Button, Grid } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { logoutAction } from '../../../Actions/Login'
import AddUserButton from './AddUserButton'
import autobind from '../../../Utils/autobind'

const style = () => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  logo: {
    width: '5vw',
    padding: 12
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  userInfoContainer: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 12,
    textAlign: 'right'
  },
  logoutButton: {
    cursor: 'pointer',
    marginTop: 6,
  }
})

class Header extends React.Component {
  constructor(props) {
    super(props)
    autobind(Header, this)
  }

  handleGoToDashboard() {
    const { history } = this.props
    history.push('/')
  }

  render() {
    const { classes, handleAddClick, user, logout, removeUserInfo } = this.props
    const isLoggedIn = user.current && user.current.id
    const userInfo = !removeUserInfo && isLoggedIn && (
      <div className={classes.userInfoContainer}>
        <div>
          <Typography variant="h2">
            {`Coordinador: ${user.current.name}`}
          </Typography>
        </div>
        <AddUserButton onClick={handleAddClick} />
        <Button onClick={logout} className={classes.logoutButton} variant="outlined"> Cerrar sesión </Button>
      </div>
    )
    const logo = isLoggedIn ? (
      <Grid className={classes.logoContainer} onClick={this.handleGoToDashboard}>
        <img src="/logo_borde.png" alt="logo" className={classes.logo} />
        <img src={user.current.organization_photo} alt="logo" className={classes.logo} />
        <Typography variant="h2">
          {`Organización: ${user.current.organization_name}`}
        </Typography>
      </Grid>
    ) : (
      <div className={classes.logoContainer}>
        <img src="/logo_borde.png" alt="logo" className={classes.logo} />
      </div>
    )
    return (
      <div className={classes.container}>
        {logo}
        {userInfo}
      </div>
    )
  }
}

Header.propTypes = {
  classes: propTypes.object.isRequired,
  logout: propTypes.func.isRequired,
  handleAddClick: propTypes.func,
  user: propTypes.object.isRequired,
  removeUserInfo: propTypes.bool,
  history: propTypes.object.isRequired,
}

Header.defaultProps = {
  handleAddClick: undefined,
  removeUserInfo: false
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction())
})

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(Header)))
