import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers/'
import propTypes from 'prop-types'
import moment from 'moment'
import Comment from './Comment'
import autobind from '../../Utils/autobind'

export default class DateInput extends Component {
  constructor(props) {
    super(props)
    autobind(DateInput, this)
  }

  handleChange(value) {
    const { onChange, complete } = this.props
    const result = value?.format('MM-DD-YYYY') || 'DD/MM/YYYY'
    const event = { target: { name: complete.name, value: result } }
    onChange(event)
  }

  render() {
    const { value, comment, complete, onComment, disabled, highlighted } = this.props
    const validDate = value ? moment(value, 'MM-DD-YYYY') : moment(new Date(), 'MM-DD-YYYY')
    return (
      <>
        <div style={{ gridArea: `area-${complete.id}` }} className={highlighted ? 'highlighted' : ''}>
          <Typography style={{ fontWeight: complete.bold ? 600 : 400, color: complete.bold ? '#3E3E3E' : '#707070' }}>{complete.text}</Typography>
          <KeyboardDatePicker
            // disableToolbar
            autoOk
            // disablePast
            fullWidth
            disabled={disabled}
            InputProps={{
              style: { fontSize: '0.8rem', padding: '0 6px', color: disabled ? 'black' : 'default' },
              fullWidth: true,
            }}
            format="DD/MM/YYYY"
            value={validDate}
            onChange={this.handleChange}
            style={{ background: '#00000017' }}
          />
        </div>
        {complete.has_comment
          && (
            <Comment
              onChange={onComment}
              value={comment}
              name={complete.name}
              area={`comment-${complete.id}`}
              commentLabel={complete.comment_label}
              disabled={disabled}
            />
          )}
      </>
    )
  }
}

DateInput.propTypes = {
  onChange: propTypes.func.isRequired,
  onComment: propTypes.func,
  value: propTypes.string,
  comment: propTypes.string,
  complete: propTypes.object.isRequired,
  disabled: propTypes.bool,
  highlighted: propTypes.bool
}

DateInput.defaultProps = {
  value: '',
  comment: '',
  onComment: undefined,
  disabled: false,
  highlighted: false
}
