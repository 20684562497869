const CSDTooltips = {
  Base: [
    { title: 'Miembros del hogar', content: 'Consideran miembros del hogar a todas las personas que normalmente hacen vida en común y comparten un presupuesto de alimentación, es decir, que comen juntas.' },
    { title: 'Hogar', content: 'Está constituido por una persona o un grupo de personas que, siendo residentes de una misma vivienda, pueden tener (o no tener) vínculos de parentesco y habitualmente hacen vida en común.' },
    { title: 'Jefe o Jefa del Hogar', content: 'Miembro del hogar (hombre o mujer) considerado como tal por las otras personas del hogar, ya sea por razones de dependencia económica, parentesco, edad, autoridad o respeto.' },
  ],
  Hogar: [
    { title: 'Miembros del hogar', content: 'Consideran miembros del hogar a todas las personas que normalmente hacen vida en común y comparten un presupuesto de alimentación, es decir, que comen juntas.' },
    { title: 'Hogar', content: 'Está constituido por una persona o un grupo de personas que, siendo residentes de una misma vivienda, pueden tener (o no tener) vínculos de parentesco y habitualmente hacen vida en común.' },
    { title: 'Jefe o Jefa del Hogar', content: 'Miembro del hogar (hombre o mujer) considerado como tal por las otras personas del hogar, ya sea por razones de dependencia económica, parentesco, edad, autoridad o respeto.' },
    { title: '¿Algún miembro del hogar tiene dificultades para realizar ciertas actividades debido a su estado de salud?', content: 'Corresponde a un indicador internacional orientado a medir discapacidad, busca identificar a aquellas personas que, debido a las dificultades para realizar ciertas acciones básicas y universales, se encuentran en mayor riesgo que la población general de experimentar limitaciones en la participación.' },
  ],
  Laboral: [
    {
      title: '¿Su trabajo o negocio principal es de tipo...?',
      options: [
        { title: 'Permanente:', content: 'Cuando el trabajo de la persona no tiene fecha de término y se realiza regularmente a lo largo del año.' },
        { title: 'De temporada o estacional:', content: 'Cuando es un empleo estacional que se pacta sólo en determinadas épocas y no es continuo a lo largo del año' },
        { title: 'Ocasional o eventual:', content: 'Cuando el trabajo es por un tiempo definido y corresponde a labores específicas transitorias, cuya duración no debe exceder los 30 días en el año.' },
        { title: 'A prueba:', content: 'Para las personas que están sujetas a una evaluación para ser contratada posteriormente.' },
        { title: 'Por plazo o tiempo determinado:', content: 'Cuando es contratado por una faena o trabajo determinado, la diferencia con el ocasional o eventual, es que en este caso la actividad a realizar tiene un periodo de duración mayor de 30 días.' },
      ],
    }
  ],
  Ingresos: [
    {
      title: 'Pensiones',
      options: [
        { title: 'Pensión Básica Solidaria de vejez (PBSV):', content: `Beneficio monetario mensual al cual pueden acceder todas las personas que no tienen derecho a pensión en algún régimen previsional.
        El monto de la PBS de vejez es:
        Entre 65 y 74 años: $164.356
        De 75 años o más: $176.096
        ` },
        { title: 'Pensión Básica Solidaria de invalidez (PBSI):', content: `Beneficio monetario mensual al cual pueden acceder todas las personas calificadas como inválidas que no tienen derecho a pensión en algún régimen previsional.
        El monto de la PBS de invalidez es: $164.356
        ` },
        { title: 'Aporte Previsional Solidario de Vejez (APSV) y Aporte Previsional Solidario de Invalidez (APSI):', content: 'Beneficio monetario mensual, financiado por el Estado, que complementa las pensiones de vejez, de invalidez o sobrevivencia.' },
        { title: 'Jubilación o pensión de vejez:', content: 'Beneficio previsional al que tienen derecho los afiliados al sistema previsional, aplica a los 65 años de edad para los hombres y 60 años de edad las mujeres.' },
        { title: 'Jubilación o pensión de invalidez:', content: 'Beneficio para aquellos afiliados al sistema previsional que la Comisión Médica de la Superintendencia de Pensiones ha declarado con invalidez.' },
        { title: 'Montepío o pensión de viudez:', content: 'Beneficio mensual a la que tiene derecho el o la cónyuge del imponente fallecido en servicio activo o estando jubilado.' },
        { title: 'Pensión de orfandad:', content: 'Beneficio mensual al que tienen derecho los hijos de los imponentes que hayan fallecido en servicio activo o jubilados.' },
      ]
    }
  ],
  TI: [
    { title: 'Banda ancha fija', content: 'Corresponde al servicio de conexión a Internet dedicado, que entrega altas capacidades de descarga.' },
    { title: 'Banda ancha móvil (modem WIFI o USB):', content: 'Corresponde al servicio de conexión móvil a Internet.' },
  ],
  Vivienda: [
    {
      title: 'Tipo de vivienda',
      options: [
        { title: 'Casa aislada (no pareada):', content: 'Vivienda permanente e independiente, que no tiene muros comunes de la vivienda con otras viviendas de distintos propietarios.' },
        { title: 'Casa pareada:', content: 'Vivienda tiene un muro medianero común con otra vivienda de distinto propietario.' },
        { title: 'Casa pareada por ambos lados:', content: 'Vivienda tiene dos o más muros medianeros comunes con otras viviendas de distintos propietarios.' },
        { title: 'Departamento en edificio con ascensor:', content: 'Vivienda ubicada en un edificio de más de un piso de construcción permanente.' },
        { title: 'Departamento en edificio sin ascensor:', content: 'Vivienda ubicada en un edificio de más de un piso de construcción permanente.' },
        { title: 'Pieza en casa antigua o conventillo:', content: 'Pieza o conjunto de piezas ocupadas por un hogar, que tiene servicios higiénicos comunes.' },
        { title: 'Mediagua:', content: 'Construcción de material ligero, generalmente con tabique de madera sin forrar de carácter semipermanente.' },
        { title: 'Mejora:', content: 'Misma definición de mediagua, pero en estado de deterioro por el paso del tiempo.' },
        { title: 'Vivienda tradicional indígena:', content: 'Vivienda que por sus materiales de construcción y diseño arquitectónico se identifica específicamente con la cultura de alguno de los pueblos indígenas.' },
        { title: 'Rancho o choza:', content: 'Vivienda de material ligero (barro empajado, totora, pirca, etc.) separada o independiente de otras construcciones.' },
        { title: 'Vivienda precaria de materiales reutilizados (latas, plásticos, cartones, etc.):', content: 'Viviendas construidas con elementos como cartón, latas, sacos o restos de otro tipo de material de desecho o reutilizado.' },
      ]
    },
    {
      title: 'Muros',
      options: [
        { title: 'Hormigón armado:', content: 'muro compacto compuesto de hormigón (cemento portland, arena y pedregullo o canto rodado) y de una armadura metálica.' },
        { title: 'Albañilería (bloque de cemento, piedra o ladrillo):', content: 'muros que cuenten con alguna de las especificaciones siguientes: Ladrillo de arcilla cocida, Bloque de cemento.' },
        { title: 'Tabique forrado por ambas caras (madera, acero, lata u otro):', content: 'muros de poco espesor cuya firmeza le da una estructura de madera (pies derechos, soleras y diagonales)' },
        { title: 'Tabique sin forro interior (madera u otro):', content: 'muros de poco espesor cuya firmeza la da una estructura de madera (pies derechos, soleras y diagonales).' },
        { title: 'Adobe, barro u otro artesanal tradicional:', content: 'muros construidos con algunos de los materiales siguientes: Adobe (piezas de barro), Barro (estructura de palos y una mezcla de barro) ' },
        { title: 'Quincha:', content: 'muros de palos clavados en el suelo, unidos por varas o ramas horizontales, generalmente entretejidas.' },
        { title: 'Pirca:', content: 'Muros construidos con piedras superpuestas unas a otras, sin mortero que las afirme entre sí.' }
      ]
    },
    {
      title: 'Pisos',
      options: [
        { title: 'Parquet:', content: 'pequeñas tablillas de madera (palmetas).' },
        { title: 'Piso flotante:', content: 'material aglomerado con cubierta barnizada que semeja madera.' },
        { title: 'Cerámico, porcelanato, flexit o similar:', content: 'Plástico, piezas de dimensiones y colores variados.' },
        { title: 'Alfombra o cubrepiso:', content: 'cubierta de fibra natural o sintética, que está adherida al piso.' },
        { title: 'Baldosa de cemento:', content: 'piezas cuadradas de mortero.' },
        { title: 'Radier:', content: 'piso constituido por una plataforma de hormigón.' },
        { title: 'Tierra:', content: 'piso que no tiene revestimiento de ningún tipo. ' }
      ]
    },
    {
      title: 'Techos',
      options: [
        { title: 'Losa hormigón:', content: 'techo formado por una placa de hormigón armado, es decir, de concreto y enfierradura.' },
        { title: 'Planchas metálicas (zinc, cobre, etc.):', content: 'techos que estén construidos con planchas de Zinc, fierro galvanizado u otro material metálico.' },
        { title: 'Planchas de fibrocemento (pizarreño):', content: 'techos que estén construidos con planchas onduladas de asbesto cemento.' },
        { title: 'Fonolita o plancha de fieltro embreado:', content: 'techos construidos con planchas onduladas, de cartón impregnado con brea (material similar al alquitrán).' },
        { title: 'Paja, coirón, totora o caña:', content: 'techos construidos con material vegetal' }
      ]
    },
    {
      title: 'Tenencia de la vivienda',
      options: [
        { title: 'Propia pagada:', content: 'Se ha cancelado totalmente la vivienda. ' },
        { title: 'Propia pagándose:', content: 'hay una deuda por la compra de la casa' },
        { title: 'Propia compartida (pagada) con otros hogares de la vivienda:', content: 'hay más de un hogar en la vivienda y se encuentra completamente pagada.' },
        { title: 'Propia compartida (pagándose) con otros hogares de la vivienda:', content: 'hay más de un hogar en la vivienda y se encuentra con deuda o pagándose.' },
        { title: 'Arrendada con contrato:', content: 'no es propietario de la vivienda y paga un monto de arriendo por el uso de ella y tiene contrato de arrendamiento.' },
        { title: 'Arrendada sin contrato:', content: 'no es propietario de la vivienda y paga un monto de arriendo por el uso de ella, pero no tiene o no ha firmado contrato de arriendo.' },
        { title: 'Cedida por servicio o trabajo:', content: 'no es propietario de la vivienda y no paga por habitarla.' },
        { title: 'Cedida por familiar u otro:', content: 'no es propietario ni arrendatario, ocupa la vivienda de un familiar o amigo.' }
      ]
    },
    {
      title: 'Eliminación de aguas residuales',
      options: [
        { title: 'Con W.C. conectado al alcantarillado:', content: 'vivienda que está conectada a una red pública de colectores de alcantarillado.' },
        { title: 'Con W.C. conectado a fosa séptica:', content: 'vivienda que no está conectada a la red de alcantarillado.' },
        { title: 'Con letrina sanitaria conectada a pozo negro:', content: 'vivienda que no está conectada a la red de alcantarillado. En este caso, las excretas van a dar a una excavación hecha en la tierra.' },
        { title: 'Con cajón sobre pozo negro:', content: 'vivienda que no está conectada a red de alcantarillado. En este caso, las excretas van a dar a una excavación hecha en la tierra, sin separación adecuada con el medio.' },
        { title: 'Con cajón sobre acequia o canal:', content: 'vivienda que no está conectada a la red de alcantarillado. En este caso, las excretas van a dar a uno de los cursos de agua.' },
        { title: 'Con cajón conectado a otro sistema:', content: 'vivienda que disponga de cualquier otro sistema diferente a los incluidos en las categorías mencionadas.' },
        { title: 'Con baño químico dentro del sitio:', content: 'vivienda que no está conectada a la red de alcantarillado.' },
        { title: 'No dispone de sistema:', content: 'estas son depositadas a campo abierto en las inmediaciones de la vivienda.' }
      ]
    }
  ]
}

export default CSDTooltips
