import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  spacing: 6,
  shape: { borderRadius: 5 },
  palette: {
    primary: {
      main: '#48A4B0',
      dark: '#27676A',
      light: '#38b4a9',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#FFB300',
      contrastText: '#ffffff',
      dark: '#D5602C',
    },
    paper: {
      main: '#fff',
      dark: '#BABABA',
    },
    indigo: {
      main: '#F21231',
      dark: '#000051',
      light: '#534BAE',
      lighter: '#DCE2F2',
    },
    confirm: {
      main: '#48A4B0',
      light: '#6cd3b5',
      dark: '#27676A'
    },
    grey: {
      light: '#ECECEC',
      main: '#a2a2a2',
      dark: '#6A6A6A',
      darker: '#3E3E3E',
      border: '#CACACA',
    },
    alert: {
      main: '#DB6F30',
      red: '#F21231'
    },
    amber: {
      main: '#FFB300',
    },
    darkOrange: {
      main: '#D5602C'
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      color: '#6A6A6A',
      fontSize: 18,
      fontWeight: 600,
    },
    h2: {
      fontSize: 15,
      textAlign: 'left',
      color: '#3E3E3E'
    },
    h3: {
      fontSize: 16,
      color: '#1f8095',
    },
    subtitle1: {
      color: '#6A6A6A',
      fontSize: 12,
      fontWeight: 600,
    },
    subtitle2: {
      color: '#000000',
      fontSize: 12,
    },
    body1: {
      letterSpacing: 0,
      color: '#707070',
      fontSize: 12,
    },
    body2: {
      letterSpacing: 0,
      color: '#707070',
      fontSize: 12,
    },
    caption: {
      color: '#6A6A6A',
      fontStyle: 'italic',
      fontSize: 13,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'unset',
      },
      label: {
        fontWeight: 600,
      },
    },
    MuiSelect: {
      outlined: {
        padding: 8,
        color: '#191F83',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#191F83',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 32,
      },
      flexContainer: {
        flexFlow: 'wrap',
      },
    },
    MuiTab: {
      root: {
        padding: 0,
        whiteSpace: 'unset',
        textTransform: 'unset',
        minHeight: 32,
      },
    },
    MuiDivider: {
      root: {
        margin: '12px 0',
        backgroundColor: '#6a6a6a',
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 13,
      },
    },
    MuiTableCell: {
      head: {
        paddingBottom: 6
      },
      root: {
        padding: '3px 6px',
        height: 36
      }
    },
    MuiSlider: {
      root: {
        color: '#48A4B0'
      },
      rail: {
        height: 5,
        borderBottomRightRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopLeftRadius: 4,
      },
      track: {
        height: 5,
        borderBottomLeftRadius: 4,
        borderTopLeftRadius: 4,
      },
      mark: {
        height: 0,
        width: 0,
        borderRadius: '50%',
      },
      thumb: {
        height: 15,
        width: 15,
        '&.Mui-disabled': {
          height: 15,
          width: 15,
          marginTop: -5,
          marginLeft: -6,
          color: '#48A4B0'
        }
      },
      markLabel: {
        fontSize: 11,
        fontWeight: 600,
        whiteSpace: 'unset',
        maxWidth: '15%',
        textAlign: 'center'
      },
      thumbColorSecondary: {
        color: '#6A6A6A',
        width: 0
      },
      colorSecondary: {
        color: '#6A6A6A'
      },
    },
    MuiFilledInput: {
      multiline: {
        padding: '6px 0 7px 0'
      }
    },
    MuiRadio: {
      colorPrimary: {
        '&.Mui-disabled.Mui-checked': {
          color: '#48A4B0'
        }
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        '&.Mui-disabled.Mui-checked': {
          color: '#48A4B0'
        }
      }
    }
  },
})

export default theme
