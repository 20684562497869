/* eslint-disable react/sort-comp */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { Paper, Button, withStyles } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import CriteriaForm from '../../../Shared/CriteriaForm'
import { sendAnswersAction } from '../../../Actions/Events'
import { contactCreationAction, updateGenderByAnswerAction } from '../../../Actions/Contenders'
import { parseContactInfo } from '../../../Utils/functions'
import AutoSaver from '../../../Shared/AutoSaver'

const style = () => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 12,
    '& > *': {
      marginLeft: 12
    }
  }
})

class CompanionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
      saved: {},
      comments: {},
      savedComments: {},
      actualKey: 0,
    }

    this.interval = null

    autobind(CompanionForm, this)
  }

  componentDidMount() {
    this.setAnsweredQuestions()
    this.interval = setInterval(this.handleSave, 15000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    this.handleSave()
  }

  setAnsweredQuestions() {
    const { events } = this.props
    const { selected } = events
    const { forms } = selected
    const { saved, savedComments } = this.state
    const newSaved = { ...saved }
    const newSavedComments = { ...savedComments }
    if (forms) {
      Object.keys(forms).forEach(formKey => {
        const form = forms[formKey]
        Object.keys(form).forEach(criteriumKey => {
          const criterium = form[criteriumKey]
          criterium.forEach(question => {
            const { answers } = question
            answers.forEach(answer => {
              if (answer.body !== null) {
                newSaved[answer.id] = answer.body
              }
              if (answer.comment !== null) {
                newSavedComments[answer.id] = answer.comment
              }
            })
          })
        })
      })
      this.setState({ saved: newSaved, savedComments: newSavedComments })
    }
  }

  handleChange(event) {
    const { target } = event
    const { params, saved } = this.state
    const newSaved = { ...saved }
    const newParams = { ...params, ...newSaved }
    delete newSaved[target.name]
    if (newParams[target.name]) {
      newParams[target.name].answer = target.value
    } else {
      newParams[target.name] = { answer: target.value }
    }
    this.setState({ params: newParams, saved: newSaved })
  }

  handleSubchange(event) {
    const { target } = event
    const { params, saved } = this.state
    const newSaved = { ...saved }
    const newParams = { ...params, ...newSaved }
    const actualName = target.name.split('&')[0]
    delete newSaved[actualName]
    if (newParams[actualName].subquestions) {
      newParams[actualName].subquestions[target.name] = target.value
    } else {
      newParams[actualName] = {
        ...newParams[actualName], subquestions: { [target.name]: target.value }
      }
    }
    this.setState({ params: newParams, saved: newSaved })
  }

  handleComment(event) {
    const { target } = event
    const { comments, savedComments, saved, params } = this.state
    const newComments = { ...comments }
    const newSavedComments = { ...savedComments }
    delete newSavedComments[target.name]
    newComments[target.name] = target.value

    const newParams = { ...params }
    const newSaved = { ...saved }

    newParams[target.name] = newSaved[target.name] || newParams[target.name]
    this.setState({ comments: newComments, savedComments: newSavedComments, params: newParams })
  }

  handleSave() {
    const { events, sendAnswers } = this.props
    const { params, saved, comments, savedComments } = this.state
    const newSaved = { ...saved, ...params }
    const newComments = { ...savedComments, ...comments }
    const answers = { ...params }
    if (Object.keys(answers).length > 0) {
      const body = Object.keys(answers).map(key => ({
        id: key,
        body: answers[key],
        comment: newComments[key],
      }))
      this.setState({ saved: newSaved, params: {}, savedComments: newComments, comments: {} })
      sendAnswers({ answers: body }, events.selected.event.id)
      return true
    }
    return false
  }

  handleEnd() {
    this.handleSave()
    const { handleNextStep, shared, updateGenderByAnswer } = this.props
    updateGenderByAnswer({ user_id: shared.selected })
    handleNextStep(2)
  }

  handleNextKey() {
    const { actualKey, params } = this.state
    const { events, shared, contactCreation } = this.props
    const contactInfo = {}
    Object.keys(events.selected.contactInfo[shared.selected]).forEach(dataType => {
      const answerId = events.selected.contactInfo[shared.selected][dataType]
      contactInfo[dataType] = parseContactInfo(params[answerId], dataType, answerId)
    })
    contactCreation(shared.selected, contactInfo)
    this.handleSave()
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({ actualKey: actualKey + 1 })
  }

  handlePrevKey() {
    const { actualKey } = this.state
    this.handleSave()
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({ actualKey: actualKey - 1 })
  }

  checkAnswers() {
    const { events, shared } = this.props
    const { forms } = events.selected || []
    const { actualKey, params } = this.state
    const keys = Object.keys(forms)
    const form = forms[keys[actualKey]]
    const answeredIds = Object.keys(params)
    let notAnswered = 0
    Object.keys(form).forEach(subkey => {
      form[subkey].forEach(question => {
        const answer = question.answers.find(a => a.user_id === shared.selected)
        if (!answer.body && answeredIds.indexOf(answer.id) === -1) {
          notAnswered += 1
        }
      })
    })
    return notAnswered
  }

  render() {
    const { events, shared, title, classes } = this.props
    const { params, saved, comments, savedComments, actualKey } = this.state
    const { forms } = events.selected || []
    const leftAnswers = this.checkAnswers()
    const keys = Object.keys(forms)
    const actualFormTitle = actualKey === 0 ? `Información de acompañante de ${shared.name}` : keys[actualKey]
    const formTitle = title || actualFormTitle
    return (
      <div style={{ padding: 24 }}>
        <Paper style={{ maxWidth: 650, margin: 'auto' }}>
          <CriteriaForm
            criteria={forms[keys[actualKey]]}
            title={formTitle}
            handleChange={this.handleChange}
            params={params}
            comments={comments}
            userId={shared.selected}
            saved={saved}
            savedComments={savedComments}
            handleComment={this.handleComment}
            handleSubchange={this.handleSubchange}
            bigScreen={false}
            enumerate
          />
          <div className={classes.buttonContainer}>
            {actualKey >= 1
            && (
              <Button color="secondary" variant="outlined" onClick={this.handlePrevKey}>
                Atras
              </Button>
            )}
            {actualKey < keys.length - 1
              ? <Button color="secondary" variant="contained" onClick={this.handleNextKey} disabled={leftAnswers > 0}>{leftAnswers > 0 ? `${leftAnswers} Preguntas restantes...` : 'Siguiente'}</Button>
              : <Button color="secondary" variant="contained" onClick={this.handleEnd} disabled={leftAnswers > 0}>{leftAnswers > 0 ? `${leftAnswers} Preguntas restantes...` : 'Enviar'}</Button>}
          </div>
          <AutoSaver onSave={this.handleSave} timeout={60000} />
        </Paper>
      </div>
    )
  }
}

CompanionForm.propTypes = {
  events: propTypes.object.isRequired,
  shared: propTypes.object,
  sendAnswers: propTypes.func.isRequired,
  handleNextStep: propTypes.func.isRequired,
  contactCreation: propTypes.func.isRequired,
  updateGenderByAnswer: propTypes.func.isRequired,
  title: propTypes.string,
  classes: propTypes.object.isRequired
}

CompanionForm.defaultProps = {
  shared: {},
  title: ''
}

const mapStateToProps = state => ({
  events: state.events
})

const mapDispatchToProps = dispatch => ({
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id)),
  contactCreation: (id, body) => dispatch(contactCreationAction(id, body)),
  updateGenderByAnswer: body => dispatch(updateGenderByAnswerAction(body))
})

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(CompanionForm))
