import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import RecurrentContext from '../RecurrentEvaluations/RecurrentContext'
import RecurrentContainer from '../RecurrentEvaluations/RecurrentContainer'
import autobind from '../../Utils/autobind'
import { createFormEvaluation, getFormEvaluation, getFormEvaluations } from '../../API/Recurrent'
import AutoevaluationSingleResult from './AutoevaluationSingleResult'
import AutoevaluationMultipleResult from './AutoevaluationMultipleResult'
import SetViewContext from '../Contenders/Profile/SetViewProvider'
import { getUserById } from '../Desempeño/ResultComponents/utils'

const style = () => ({

})

function handleGetEvaluations(event) {
  return getFormEvaluations(event)
}

function handleCreate(event) {
  return createFormEvaluation(event)
}

class Autoevaluation extends Component {
  constructor() {
    super()
    autobind(Autoevaluation, this)
  }

  handleClickPending(evaluation) {
    const { history, event, contenders } = this.props
    const userId = contenders?.selected?.user?.id
    const user = getUserById(userId)
    if (!user) return null
    const encriptedName = btoa(user.user.name)
    const url = `/autoevaluation/${event.event_id}/${event.form_id}/${evaluation.id}/${encriptedName}`
    return history.push(url)
  }

  handleGetResult(evaluationInfo) {
    const { event, contenders } = this.props
    const body = {
      form_id: event.form_id,
      evaluation_id: evaluationInfo.id,
    }
    getFormEvaluation(body).then(response => {
      if (response.data.status !== 'success') return false
      const evaluation = response.data.info
      const { setView } = this.context
      return setView(<AutoevaluationSingleResult
        evaluation={evaluationInfo.id}
        event={evaluation}
        eventInfo={event}
        contender={contenders.selected.user}
        name={evaluationInfo.name}
      />, `Autoevaluación N°${evaluationInfo.name}`)
    })
  }

  render() {
    const { classes, event, contenders } = this.props;
    const userId = contenders?.selected?.user?.id
    event.user_id = userId
    return (
      <div className={classes.container}>
        <RecurrentContext.Provider value={{
          event,
          getEvaluations: handleGetEvaluations,
          getEvaluation: this.handleClickPending,
          getEvaluationResult: this.handleGetResult,
          createNewEvaluation: handleCreate,
          evaluationName: 'Autoevaluación',
          contender: contenders.selected.user
        }}
        >
          <RecurrentContainer resultTab={AutoevaluationMultipleResult} />
        </RecurrentContext.Provider>
      </div>
    );
  }
}

Autoevaluation.contextType = SetViewContext

Autoevaluation.propTypes = {
  classes: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  event: propTypes.object.isRequired
}

Autoevaluation.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withRouter(withStyles(style)(Autoevaluation)))
