/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { Popover, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import autobind from 'Utils/autobind'
import { Edit } from '@material-ui/icons'
import { connect } from 'react-redux'
import GlobalComment from './GlobalComment'

const style = theme => ({
  container: {
    width: 24,
    position: 'fixed',
    bottom: 12,
    right: 12,
    background: theme.palette.primary.dark,
    borderRadius: 10,
    zIndex: 10,
    padding: 12,
    '& > *': {
      color: 'white',
      fontWeight: 600
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    transition: 'all 0.2s linear',
    '&:hover': {
      width: 90
    }
  }
})

class FloatingComment extends Component {
  constructor() {
    super()
    this.state = {
      anchorEl: null
    }
    autobind(FloatingComment, this)
  }

  componentDidMount() {

  }

  handleClick() {
    const element = document.getElementById('menu-floating')
    const { anchorEl } = this.state
    if (anchorEl !== null) return this.setState({ anchorEl: null })
    return this.setState({ anchorEl: element })
  }

  render() {
    const { classes, event, evaluation, user } = this.props;
    const { anchorEl } = this.state
    if (!user?.current?.id) return null
    return (
      <>
        <div className={classes.container} id="menu-floating" onClick={this.handleClick}>
          <Edit style={{ marginRight: 12 }} />
          <Typography variant="h2">Anotar</Typography>
        </div>
        <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={this.handleClick}>
          <div style={{ width: '70vw', maxWidth: 600 }}>
            <Typography variant="subtitle1" style={{ padding: 12 }}>Observaciones</Typography>
            <GlobalComment event={event} evaluation={evaluation} />
          </div>
        </Popover>
      </>
    );
  }
}

FloatingComment.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  evaluation: propTypes.string.isRequired,
}

FloatingComment.defaultProps = {

}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(withStyles(style)(FloatingComment))
