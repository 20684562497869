import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import ReportTitles from '../ReportTitles'
import ProfilePercentage from '../../../../FunctionalEvaluation/Results/ProfilePercentage'
// import { MultilineTextField } from '../../../../../Shared/Fields/Text'

const style = () => ({
  cards: {
    display: 'flex',
    alignItems: 'stretch',
    '& > div': {
      width: 'calc(50% - 30px)',
    },
    justifyContent: 'space-between'
  },
  card: {
    padding: 12,
    textAlign: 'justify',
    '& > p': {
      lineHeight: 1.75
    }
  },
  colorTag: {
    textAlign: 'center',
    '& > h2': {
      color: 'white',
      textAlign: 'center',
      fontWeight: 600,
      background: 'rgba(255, 179, 61)',
      borderRadius: 24,
      padding: '6px 12px',
      margin: 'auto',
      width: 150,
      marginTop: 24,
      marginBottom: 24
    }
  },
  container: {
    marginBottom: 48
  },
  input: {
    marginTop: 12
  }
})

const profileColors = {
  Administrativo: 'rgba(31, 128, 149)',
  'Atención al cliente': 'rgba(251, 131, 104)',
  Operario: 'rgba(255, 179, 61)',
  'Servicios de apoyo': 'rgba(56, 180, 169)',
}

const profileDefs = {
  Administrativo: 'Los administrativos gestionan, organizan, planifican, atienden y realizan tareas administrativas, de soporte y apoyo a la organización. Dentro de las funciones principales que desarrollan los administrativos podemos destacar la tramitación de documentos o la comunicación, ya sea ésta a nivel interno o externo, de los flujos de información de la empresa u organización.',
  'Atención al cliente': 'Un representante de servicio al cliente es la persona que se encarga de proporcionar a los usuarios, información y soporte con relación a los productos o servicios de la empresa en la cual ejerce su cargo. Además, es el responsable de acoger las quejas y reclamos de los clientes de la empresa. El modo de atención al cliente puede ser de manera presencial, como también, vía telefónica o vía internet. Además de esto, si trabaja en tienda, es el encargado de ordenar la ropa y dar respuesta a las dudas de los clientes.',
  'Servicios de apoyo': 'Son los encargados de la mantención del aseo, desinfección y orden de las áreas asignadas dentro de la empresa para la óptima atención de los clientes y/o pacientes. También se encarga de la mantención de los insumos de limpieza dentro de la empresa. Además se encarga de realizar labores de apoyo en funciones del servicio (como estafeta, traslado de muebles para reparaciones menores, etc.), con la finalidad de contribuir con el adecuado funcionamiento de su lugar de trabajo.',
  Operario: 'Los operarios participan de los procesos de producción de las empresas u organizaciones. Dentro de sus tareas está organizar, transportar, clasificar, etiquetar y deshechar los materiales que se utilizan en su área de trabajo, además del uso de diversas herramientas y materiales dentro de sus tareas. Pueden realizar acciones asociadas al manejo de planillas de los insumos, despachos y mantención dentro de su  área.',
}

class CompetenciesProfile extends Component {
  render() {
    const { classes, contenders, values } = this.props
    const results = contenders.profileResults
    const { functional } = results
    const adjustments = functional?.profile_adjust || []
    return (
      <div className={classes.container}>
        <ReportTitles title="Perfil de Competencias" />
        <div className={classes.cards}>
          <Paper className={classes.card}>
            <Typography variant="subtitle1">Perfil de competencias</Typography>
            <div className={classes.colorTag}>
              <Typography variant="h2">{values?.profile?.[0]}</Typography>
            </div>
            <Typography variant="body1">{profileDefs?.[values?.profile?.[0]]}</Typography>
          </Paper>
          <Paper className={classes.card}>
            <Typography variant="subtitle1">Porcentaje de ajuste a los perfiles</Typography>
            {Object.entries(adjustments).map(([name, percentage]) => (
              <ProfilePercentage
                title={name}
                barColor={profileColors[name]}
                percentage={percentage}
              />
            ))}
          </Paper>
        </div>
        {/* <div className={classes.input}>
          <MultilineTextField
          complete={{ rows: 2, placeholder: 'Comentarios sobre nivel de apoyo' }} />
        </div> */}
      </div>
    );
  }
}

CompetenciesProfile.propTypes = {
  classes: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
}

CompetenciesProfile.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(CompetenciesProfile))
