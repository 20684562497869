import React, { Component } from 'react'
import { withStyles, Checkbox, Typography, Button, Tooltip } from '@material-ui/core'
import propTypes from 'prop-types'
import { CheckCircle, ArrowDownwardOutlined, Info, CheckCircleOutline, ErrorOutlineOutlined } from '@material-ui/icons'
import Questions from '../../POI/Questions'
import Charts from '../../POI/Charts'
import Conditional from '../../../../Shared/Conditional'

const style = theme => ({
  groupContainer: {
    marginLeft: 56,
    textAlign: 'left'
  },
  titleText: {
    fontSize: 17,
    marginLeft: 12
  },
  success: {
    color: theme.palette.confirm.main
  },
  error: {
    color: theme.palette.alert.red,
    height: 16,
    width: 16,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 16,
      width: 16,
      border: '2px solid',
      borderRadius: '50%',
      margin: -2
    }
  },
  container: {
    margin: -12,
    padding: '12px 24px 24px 0',
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: 12
  },
  editButton: {
    color: theme.palette.confirm.main,
    border: `2px solid ${theme.palette.confirm.main}`,
  },
  selectedContainer: {
    marginLeft: 56
  },
  questionTitle: {
    marginBottom: 12,
  },
  question: {
    marginBottom: 6
  },
  specialTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }
})

class ActiveCriterium extends Component {
  renderGroups(groups, criterium) {
    const { classes } = this.props
    if (groups) {
      const allGroups = groups.map((group, index) => (
        <div style={{ width: '100%' }} key={`-${index + 1}-`}>
          <Questions observations={group.questions} withObservation />
          <div className={classes.inline} style={{ marginBottom: 24 }}>
            <Charts
              questions={group.questions}
              name={`${criterium}-${index}`}
              fullWidth
              options={group.options}
            />
          </div>
        </div>
      ))
      return allGroups
    }
    return null
  }

  renderQuestions() {
    const { classes, full } = this.props
    if (full) {
      const checkedQuestions = full.questions.filter(q => q.checked)
      const bankQuestions = checkedQuestions
        .filter(q => q.from_bank)
        .map((question, index) => (
          <Typography key={question.id} variant="subtitle2" className={classes.question}>
            {`${index + 1} - ${question.text}`}
          </Typography>
        ))
      const notBankQuestions = checkedQuestions.filter(q => !q.from_bank).map((question, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography key={question.id} variant="subtitle2" display="inline">{`${bankQuestions.length + index + 1} - ${question.text}`}</Typography>
          <Tooltip title="No forma parte del banco de preguntas">
            <Info style={{ marginLeft: 4, fontSize: 18 }} />
          </Tooltip>
        </div>
      ))
      return bankQuestions.concat(notBankQuestions)
    }
    return null
  }

  render() {
    const { classes, info, criterium, done, handleSelect,
      showAll, disableCheck, special, companion } = this.props
    const isCorrectHandler = info?.question_groups?.[0]?.questions?.[0]?.answers?.[0]?.body?.answer === '0'
    const companionWorkInteresQuestion = companion?.find(q => q.text.includes('aspirante quiere trabajar'))
    const isCorrectCompanion = companionWorkInteresQuestion?.answers?.body?.answer === '0'

    const isDescended = (!criterium.includes('Verbaliza') && info.criterium_percentage <= 50)
    || (criterium.includes('Verbaliza') && isCorrectCompanion !== isCorrectHandler)

    if ((!showAll && isDescended) || showAll) {
      return (
        <div style={{ background: done ? '#f1f4fa' : 'unset' }} className={classes.container}>
          <div className={classes.inline}>
            <div className={classes.title}>
              {showAll && <Checkbox checked={done} color="primary" onClick={handleSelect(criterium)} />}
              <Conditional condition={!disableCheck}>
                <Conditional condition={!isDescended} hasElse>
                  <CheckCircle className={classes.success} />
                  <div className={classes.error}>
                    <ArrowDownwardOutlined className={classes.error} />
                  </div>
                </Conditional>
              </Conditional>
              <Typography variant="subtitle1" className={classes.titleText}>{criterium}</Typography>
            </div>
            <Conditional condition={done || !showAll}>
              <Button className={classes.editButton} variant="outlined" onClick={handleSelect(criterium)} size="small">
                {done ? 'Editar' : 'Seleccionar'}
                {' '}
                Preguntas
              </Button>
            </Conditional>
          </div>
          {special
            ? (
              <div style={{ marginLeft: 24 }}>
                <div className={classes.specialTitle}>
                  {isCorrectHandler
                    ? <CheckCircleOutline style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />
                    : <ErrorOutlineOutlined style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />}
                  <Typography variant="subtitle1">
                    Facilitador:
                    {isCorrectHandler ? ' Si' : ' No'}
                  </Typography>
                </div>
                <div className={classes.specialTitle}>
                  {isCorrectCompanion
                    ? <CheckCircleOutline style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />
                    : <ErrorOutlineOutlined style={{ height: 16, width: 16, marginRight: 12, color: '#6a6a6a' }} />}
                  <Typography variant="subtitle1">
                    Acompañante:
                    {isCorrectCompanion ? ' Si' : ' No'}
                  </Typography>
                </div>
              </div>
            )
            : (
              <div className={classes.groupContainer}>
                {this.renderGroups(info.question_groups, criterium)}
              </div>
            )}
          <Conditional condition={done}>
            <div className={classes.selectedContainer}>
              <Typography variant="subtitle1" color="primary" className={classes.questionTitle}>Preguntas de indagación:</Typography>
              {this.renderQuestions()}
            </div>
          </Conditional>

        </div>
      )
    }
    return null
  }
}

ActiveCriterium.propTypes = {
  classes: propTypes.object.isRequired,
  info: propTypes.object.isRequired,
  criterium: propTypes.string.isRequired,
  done: propTypes.bool,
  handleSelect: propTypes.func,
  showAll: propTypes.bool,
  disableCheck: propTypes.bool,
  special: propTypes.bool,
  full: propTypes.object,
  companion: propTypes.arrayOf(propTypes.object)
}

ActiveCriterium.defaultProps = {
  done: false,
  full: undefined,
  handleSelect: undefined,
  showAll: false,
  disableCheck: false,
  special: false,
  companion: []
}

export default withStyles(style)(ActiveCriterium)
