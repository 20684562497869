import React from 'react'
import { Paper, Grid, Typography, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { withStyles } from '@material-ui/core/styles'
import propTypes from 'prop-types'
import moment from 'moment'
import { NoteAdd } from '@material-ui/icons'
import Participant from './Participant'
import { loadEventAction, sendGencatAction } from '../../Actions/Events'
import autobind from '../../Utils/autobind'
import { callSnackbar } from '../../Utils/snackbars'
import { loadEvent } from '../../API/Events'
import LoaderDialog from '../../Shared/LoaderDialog'
import { getUserEvaluations } from '../../API/Recurrent'

const style = theme => ({
  container: {
    padding: 12,
    width: '100%',
    background: '#fff',
    textAlign: 'left',
    marginRight: 12,
  },
  title: {
    textAlign: 'left',
    paddingBottom: 24,
    marginBottom: 6,
    position: 'relative',
    '& > h2': {
      fontWeight: 600
    }
  },
  stateHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 60,
    '& > p': {
      fontSize: 12,
      position: 'absolute',
      fontWeight: 600,
      color: theme.palette.grey.dark
    }
  },
  radioBtns: {
    display: 'flex',
  },
  button: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  outer: {
    padding: 12,
    display: 'flex',
    maxWidth: 750,

  },
  right: {
    width: '30%',
  },
  iconButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      marginRight: 6
    },
    margin: '12px 0'
  },
  hidden: {
    position: 'absolute',
    opacity: 0,
    top: 0,
  }
})

function copyLink(id) {
  return () => {
    const element = document.getElementById('secret-input')
    element.value = `${window.location.origin}/events/${id}/gencat_familiar/form`
    element.select()
    element.setSelectionRange(0, 99999)
    document.execCommand('copy');
    callSnackbar('Enlace copiado', 'success')
  }
}

class Gencat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      event: null,
      loading: false,
      availableEvaluations: []
    }
    autobind(Gencat, this)
  }

  async componentDidMount() {
    const { match: { params: { id } } } = this.props
    const body = { id }
    this.setState({ loading: true })
    const response = await loadEvent(body)
    const { data: { status, info } } = response
    if (status !== 'success') return this.setState({ loading: false })

    const evResponse = await getUserEvaluations({
      event_id: id,
      form_id: info?.forms_id?.[0]?.id
    })

    const availableEvaluations = evResponse?.data?.info || []

    return this.setState({ loading: false, event: info, availableEvaluations })
  }

  handleParticipantClick(id, option) {
    return () => {
      const { history, event } = this.props
      if (option === 'view') {
        history.push(`/events/${event.event.id}/gencat_familiar_review/${id}`)
      } else {
        history.push(`/mail/fichagencat/${event.event.id}/${id}`)
      }
    }
  }

  handleMailSend(userId) {
    const { loadEventRedux, event, sendGencat, enqueueSnackbar } = this.props
    const loadEventWrapper = eventId => () => loadEventRedux(eventId, null, { fetch_form: false })
    return e => {
      e.stopPropagation()
      sendGencat(userId, event.event.id, loadEventWrapper(event.event.id), enqueueSnackbar)
    }
  }

  renderParticipants() {
    const { event, availableEvaluations } = this.state
    const { match: { params: { id } } } = this.props
    if (event && event.participants) {
      return event.participants
        .map(participant => {
          const participantEvaluation = availableEvaluations.find(
            ev => ev.event_id === id && ev.participant_user === participant.id
          )
          return (
            <Participant
              onMenuClick={this.handleParticipantClick}
              onButtonClick={this.handleMailSend(participant.id)}
              participant={participant}
              key={participant.id}
              attended
              finished={participantEvaluation?.status === 'check'}
            />
          )
        })
    }
    return null
  }

  render() {
    const { classes } = this.props
    const { loading, event } = this.state
    return (
      <Grid container>
        <LoaderDialog open={loading} />
        <Grid container item className={classes.outer}>
          <Paper className={classes.container}>
            <div className={classes.title}>
              <Typography variant="h2">
                {`Envío de Escala de Calidad de Vida Familiar: ${event?.event?.title || 'Cargando...'}`}
              </Typography>
              <Typography variant="caption">
                {moment(event?.event?.created_at).format('DD/MM/YYYY')}
              </Typography>
              <Button color="primary" variant="contained" className={classes.iconButton} onClick={copyLink(event?.event?.id)}>
                <input className={classes.hidden} id="secret-input" />
                <NoteAdd />
                Copiar link de evaluación
              </Button>
              <div className={classes.stateHeader}>
                <Typography
                  color="primary"
                  variant="body2"
                >
                  Estado
                </Typography>
              </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: 10 }}>
              {this.renderParticipants()}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

Gencat.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object,
  history: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  loadEventRedux: propTypes.func.isRequired,
  sendGencat: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
}

Gencat.defaultProps = {
  event: undefined,
}

const mapStateToProps = state => ({
  event: state.events.selected
})

const mapDispatchToProps = dispatch => ({
  loadEventRedux: (id, callback, params) => dispatch(loadEventAction(id, callback, params)),
  sendGencat: (userId,
    eventId,
    callback,
    snackbar) => dispatch(sendGencatAction(userId, eventId, callback, snackbar))
})

export default withSnackbar(connect(mapStateToProps,
  mapDispatchToProps)(withRouter(withStyles(style)(Gencat))))
