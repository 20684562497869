import React, { Component } from 'react'
import { Divider, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import QuestionRenderer from './QuestionRenderer'

const style = () => ({

})

class FormSheet extends Component {
  constructor() {
    super()
    this.indexList = []
  }

  renderAllQuestions() {
    const { form } = this.props
    return form.map((question, index) => (
      <>
        <QuestionRenderer
          indexList={this.indexList}
          key={`${index * 10}-i`} // Just to have a unique key
          question={question}
        />
        <Divider style={{ background: '#dce2f2' }} />
      </>
    ))
  }

  render() {
    const { classes } = this.props;
    this.indexList = []
    return (
      <div className={classes.container}>
        {this.renderAllQuestions()}
      </div>
    );
  }
}

FormSheet.propTypes = {
  classes: propTypes.object.isRequired,
  form: propTypes.arrayOf(propTypes.object),
}

FormSheet.defaultProps = {
  form: [],
}
export default withStyles(style)(FormSheet)
