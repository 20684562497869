import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import LaboralPerformanceEvaluation from './LaboralPerformanceEvaluation'
import LaboralPerformanceSingleResult from './LaboralPerformanceSingleResult'
import RecurrentContext from '../RecurrentEvaluations/RecurrentContext'
import RecurrentContainer from '../RecurrentEvaluations/RecurrentContainer'
import LaboralPerformanceMultipleResults from './LaboralPerformanceMultipleResults'
import autobind from '../../Utils/autobind'
import { createEvaluationGroup, getEvaluationGroup, getEvaluationGroups } from '../../API/Recurrent'
import SetViewContext from '../Contenders/Profile/SetViewProvider'
import LaboralRecurrentCard from './LaboralRecurrentCard'
import LoaderDialog from '../../Shared/LoaderDialog'

const style = () => ({

})

function handleGetEvaluations(event) {
  return getEvaluationGroups(event)
}

function handleCreate(event) {
  return createEvaluationGroup(event)
}

class LaboralPerformance extends Component {
  constructor() {
    super()
    this.state = {
      loading: false
    }
    autobind(LaboralPerformance, this)
  }

  handleClickPending(group) {
    const { event, contenders, classes, type } = this.props
    const { setView } = this.context
    const userId = contenders?.selected?.user?.id
    event.user_id = userId
    const body = {
      form_id: event.form_id,
      evaluation_group_id: group.id,
    }
    this.setState({ loading: true })
    getEvaluationGroup(body).then(response => {
      if (response.data.status !== 'success') return this.setState({ loading: false })
      this.setState({ loading: false })
      return setView(
        <div className={classes.container}>
          <LaboralPerformanceEvaluation group={response.data.info} event={event} type={type} />
        </div>,
        `Evaluación N°${group.name}`
      )
    })
  }

  handleGetResult(group) {
    const { event, contenders, classes, type } = this.props
    const { setView } = this.context
    const userId = contenders?.selected?.user?.id
    const contender = contenders?.selected?.user
    event.user_id = userId
    const body = {
      form_id: event.form_id,
      evaluation_group_id: group.id,
    }
    this.setState({ loading: true })
    getEvaluationGroup(body).then(response => {
      this.setState({ loading: false })
      if (response.data.status !== 'success') return false
      return setView(
        <div className={classes.container}>
          <LaboralPerformanceSingleResult
            group={response.data.info}
            event={event}
            type={type}
            name={group.name}
            contender={contender}
          />
        </div>,
        `Evaluación N°${group.name}`
      )
    })
  }

  render() {
    const { classes, event, contenders, type } = this.props;
    const { loading } = this.state
    const userId = contenders?.selected?.user?.id
    event.user_id = userId
    const contender = contenders?.selected?.user
    return (
      <div className={classes.container}>
        <LoaderDialog open={loading} />
        <RecurrentContext.Provider value={{
          event,
          type,
          contender,
          getEvaluations: handleGetEvaluations,
          getEvaluation: this.handleClickPending,
          getEvaluationResult: this.handleGetResult,
          createNewEvaluation: handleCreate,
          evaluationName: `Evaluación de Desempeño Laboral ${type}`,
          createButtonName: `Nueva ${type ? 'Evaluación' : 'Rotación'}`,
          card: LaboralRecurrentCard,
        }}
        >
          <RecurrentContainer resultTab={LaboralPerformanceMultipleResults} />
        </RecurrentContext.Provider>
      </div>
    );
  }
}

LaboralPerformance.contextType = SetViewContext

LaboralPerformance.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  type: propTypes.string
}

LaboralPerformance.defaultProps = {
  type: ''
}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(LaboralPerformance))
