import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import propTypes from 'prop-types'
import autobind from 'Utils/autobind'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { getContenderFormsAction } from 'Actions/Contenders'
import SetViewContext from '../SetViewProvider'
import ResultReportContainer from '../ResultsReport/ResultReportContainer'

const style = () => ({
  container: {
    textAlign: 'start',
    padding: 12,
    margin: 12,
    '& > *': {
      margin: '12px 0'
    },

  }
})

class Reports extends React.Component {
  constructor() {
    super()
    autobind(Reports, this)
  }

  handleClickCard() {
    const { match: { params: { id } } } = this.props
    const url = `${process.env.REACT_APP_API_URL}/api/downloads/${id}/ficha_salud_report.pdf`
    window.open(url)
  }

  handleOpenReport() {
    const { setView } = this.context
    setView(<ResultReportContainer />, 'Informe Conocimiento de la Persona')
  }

  getHealthEvent() {
    const { contenders } = this.props;
    const { pending, check } = contenders.forms
    if (!pending) return null
    const arrayOfPendingEvents = Object.values(pending).reduce((x, y) => x.concat(y), [])
    const arrayOfCheckedEvents = Object.values(check).reduce((x, y) => x.concat(y), [])
    const arrayOfEvents = arrayOfPendingEvents.concat(arrayOfCheckedEvents)
    const healthEvent = arrayOfEvents.find(event => event.name === 'Ficha de Salud')
    return healthEvent
  }

  render() {
    const { classes } = this.props
    return (
      <Paper className={classes.container}>
        <Typography variant="h1">Informes</Typography>
        <div>
          <Button color="primary" variant="contained" onClick={this.handleOpenReport}>
            Informe Conocimiento de la Persona
          </Button>
        </div>
        <div>
          <Button color="primary" variant="contained" onClick={this.handleClickCard}>
            Ficha de Salud
          </Button>
        </div>
      </Paper>
    )
  }
}

Reports.propTypes = {
  classes: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  contenders: propTypes.arrayOf(propTypes.object)
}

Reports.defaultProps = {
  contenders: []
}

Reports.contextType = SetViewContext

const mapStateToProps = state => ({
  contenders: state.contenders
})

const mapDispatchToProps = dispatch => ({
  getContenderForms: id => dispatch(getContenderFormsAction(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(Reports)))
