import React, { Component } from 'react'
import propTypes from 'prop-types'
import Chart from 'chart.js'
import { Typography, withStyles } from '@material-ui/core'

const style = () => ({
  labels: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: 100
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    marginRight: 12
  }
})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

class MultipleRadarChart extends Component {
  componentDidMount() {
    this.renderChart()
  }

  renderChart() {
    const { name, data } = this.props
    const chartElement = document.getElementById(name)
    const myChartRef = chartElement.getContext('2d');
    chartElement.height = '250'

    const labels = Object.keys(data[0])
    const values = data.map(e => Object.values(e))
    const datasets = values.map((datas, index) => ({
      data: datas,
      backgroundColor: `${colors[index]}aa`,
      borderColor: colors[index],
      pointRadius: 4,
      label: index === 0 ? 'EFD' : `EDC ${index}`
    }))
    // eslint-disable-next-line no-new
    this.chart = new Chart(myChartRef, {
      type: 'radar',
      data: {
        // Bring in data
        labels,
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: true,
          labels: {
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
            fontColor: 'black',
            fontSize: 12,
            usePointStyle: true,
            boxWidth: 6
          },
        },
        title: {
          display: false,
        },
        scale: {
          beginAtZero: true,
          gridLines: {
            color: 'rgba(0, 0, 0, 0.2)',
          },
          ticks: {
            display: false,
            min: 0,
            max: 5,
            stepSize: 1,
          },
          pointLabels: {
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
            fontColor: 'black',
            fontSize: 12,
          }
        },
        elements: {
          line: {
            borderWidth: 2
          }
        }
      }
    })
  }

  renderLabels() {
    const { classes, data } = this.props
    return Object.keys(data).map((element, index) => {
      const toReturn = (
        <div className={classes.label} key={`${index + 1} - label`}>
          <div className={classes.dot} style={{ background: element.color }} />
          <Typography variant="body1" style={{ fontWeight: 600 }}>{element.label}</Typography>
        </div>
      )
      return toReturn
    })
  }

  render() {
    const { name } = this.props
    return (
      <div>
        <canvas
          id={name}
          ref={this.chartRef}
        />
      </div>
    )
  }
}

MultipleRadarChart.propTypes = {
  name: propTypes.string.isRequired,
  classes: propTypes.object.isRequired,
  data: propTypes.object,
}

MultipleRadarChart.defaultProps = {
  data: [],
}

export default withStyles(style)(MultipleRadarChart)
