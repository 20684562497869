import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Label from '@material-ui/icons/Label'
import propTypes from 'prop-types'
import Select from '../../../Shared/Fields/Select'

const style = makeStyles(theme => ({
  mainContainer: {
    width: 500
  },
  titleContainer: {
    '& > *': {
      marginRight: 6
    },
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    marginRight: 6
  },
  input: {
    margin: '12px 0'
  },
  datepicker: {
    background: theme.palette.grey.light,
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    paddingLeft: 6
  },
  datepickerLabel: {
    fontWeight: 400,
    color: theme.typography.body1.color
  },
  descriptionContainer: {
    marginTop: 12
  }
}))

const selectOptions = ['Director(a)', 'Facilitador(a)', 'Administrador(a)']
const roles = ['director', 'handler', 'admin']
const optionToIndex = value => String(selectOptions.indexOf(value))
const roleToIndex = role => roles[Number(role)]

const EditRole = ({ open, onClose, selected, onSubmit }) => {
  const [role, setRole] = useState('')
  const classes = style()

  const handleSave = () => {
    const roleName = roleToIndex(role)
    onSubmit(selected.id, roleName)
    setRole('')
    onClose()
  }

  const handleChange = e => {
    setRole(e.target.value)
  }

  const actualValue = role || optionToIndex(selected?.role)
  return (
    <Dialog open={open} PaperProps={{ className: classes.mainContainer }}>
      <DialogTitle>
        <div className={classes.titleContainer}>
          <Label />
          Cambiar rol de usuario
        </div>
      </DialogTitle>
      <DialogContent>
        <Select
          onChange={handleChange}
          complete={{ options: selectOptions, name: 'role' }}
          value={actualValue}
        />
        <div className={classes.descriptionContainer}>
          {actualValue === '0' && (
            <Typography>
              El rol de director/a permite:
              <br />
              - Responder autorizaciones:
              <br />
              - Crear o borrar cuentas de evaluaciones
              <br />
              - Editar usuarios
            </Typography>
          )}
          {actualValue === '1' && (
            <Typography>
              El rol de facilitador/a permite:
              <br />
              - Responder autorizaciones:
              <br />
              - Crear o borrar cuentas de evaluaciones
              <br />
              - Editar usuarios
            </Typography>
          )}
          {actualValue === '2' && (
            <Typography>
              El rol de administrador/a permite:
              <br />
              - Responder autorizaciones:
              <br />
              - Crear o borrar cuentas de evaluaciones
              <br />
              - Editar usuarios
              <br />
              - Crear o borrar cuentas de Directores
            </Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}> Cancelar </Button>
        <Button onClick={handleSave} variant="contained" color="primary"> Guardar </Button>
      </DialogActions>
    </Dialog>
  )
}

EditRole.propTypes = {
  open: propTypes.bool.isRequired,
  selected: propTypes.object.isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
}

export default EditRole
