import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { HelpOutline } from '@material-ui/icons'
import UpdatedGeneralForm from '../../Shared/UpdatedGeneralForm'
import FormContext from './FormContext'
import OnTouchTooltip from '../../Shared/OnTouchTooltip'

const style = theme => ({
  form: {
    textAlign: 'left'
  },
  divider: {
    marginTop: 3,
    background: theme.palette.confirm.main
  },
  title: {
    fontSize: 18,
    marginTop: 12
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
class FormWithGroups extends Component {
  renderParts() {
    const { form, classes, disabled } = this.props
    const { tooltips } = this.context
    return Object.entries(form).map(([key, value]) => {
      const tooltip = tooltips.find(t => t.section === key)
      const hasKey = key !== 'empty'
      return (
        <div className={classes.form} key={key}>
          {hasKey
        && (
          <>
            <div className={classes.inline}>
              <Typography variant="subtitle1" className={classes.title}>{key}</Typography>
              {tooltip && (
                <OnTouchTooltip title={tooltip?.tooltip}>
                  <HelpOutline style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </OnTouchTooltip>
              )}
            </div>
            <Divider className={classes.divider} />
          </>
        )}
          <UpdatedGeneralForm questions={value} disabled={disabled} />
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.renderParts()}
      </div>
    );
  }
}

FormWithGroups.contextType = FormContext

FormWithGroups.propTypes = {
  classes: propTypes.object.isRequired,
  disabled: propTypes.bool,
  form: propTypes.object
}

FormWithGroups.defaultProps = {
  form: {},
  disabled: false
}
export default withStyles(style)(FormWithGroups)
