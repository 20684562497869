import instance from '../Utils/client'
import createSnackbar from '../Utils/snackbars'

export const getCompetencyEvaluationsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `forms/${body.form_id}/evaluations`,
    params: body
  })
  return {
    type: 'GET_COMPETENCY_EVALUATIONS',
    payload: REQUEST
  }
}

export const getCompetencyEvaluationAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `forms/${body.form_id}/evaluations/${body.evaluation_id}`,
    params: body.params
  })
  return {
    type: 'GET_COMPETENCY_EVALUATION',
    payload: REQUEST
  }
}

export const createCompetencyEvaluationAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `forms/${body.form_id}/evaluations`,
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_COMPETENCY_EVALUATION',
    payload: REQUEST
  }
}

export const changeCompetencyEvaluationStateAction = body => {
  const REQUEST = instance({
    method: 'put',
    url: `evaluations/${body.evaluation_id}/change_status`,
    params: { status: body.status }
  })
  createSnackbar(REQUEST)
  return {
    type: 'CHANGE_COMPETENCY_EVALUATION_STATUS',
    payload: REQUEST
  }
}

export const getCompetencyEvaluationRestulAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `evaluations/${body.evaluation_id}/result`,
  })
  return {
    type: 'GET_COMPETENCY_EVALUATION_RESULT',
    payload: REQUEST
  }
}

export const getAllCompetenciesResultAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `events/${body.event_id}/forms/${body.form_id}/result`,
    params: { type: body.type }
  })
  return {
    type: 'GET_ALL_COMPETENCIES_RESULT',
    payload: REQUEST
  }
}

// POST {{app-url}}/api/answers/ffbd895d-37dc-46ec-b227-42dbc3e80929/populate_answer_groups

export const populateAnswerGroupsAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `answers/${body.answer_id}/populate_answer_groups`
  })
  return {
    type: 'POPULATE_ANSWER_GROUP',
    payload: REQUEST
  }
}
