import React, { Component } from 'react'
import { Paper, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import DownloadButton from 'Components/Dashboard/DownloadButton'

const style = () => ({
  mainContainer: {
    position: 'relative'
  },
  container: {
    padding: 12
  }
})

class DownloadableSection extends Component {
  render() {
    const { classes, children, id, name, margin } = this.props;
    return (
      <div className={classes.mainContainer} style={{ margin: margin ? '24px 0' : 0 }}>
        <DownloadButton title={id} name={name} />
        <Paper className={classes.container} id={id}>
          {children}
        </Paper>
      </div>
    );
  }
}

DownloadableSection.propTypes = {
  classes: propTypes.object.isRequired,
  children: propTypes.node.isRequired,
  id: propTypes.string,
  name: propTypes.string,
  margin: propTypes.bool,
}

DownloadableSection.defaultProps = {
  id: 'id',
  name: 'name',
  margin: false
}
export default withStyles(style)(DownloadableSection)
