/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import moment from 'moment'
import { withStyles, IconButton, Typography, Button, Menu, MenuItem } from '@material-ui/core'
import propTypes from 'prop-types'
import { Send, MoreVertOutlined, Visibility, FileCopyOutlined } from '@material-ui/icons'
import autobind from '../../Utils/autobind'

const style = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 12px',
    background: theme.palette.grey.light,
    borderRadius: 5,
    boxShadow: '0px 1px 5px #00000029',
  },
  btn: {
    padding: 6,
    fontWeight: 600
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      marginRight: 6,
      color: theme.palette.confirm.main
    }
  },
  menuItem: {
    minWidth: 300,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

class Participant extends Component {
  constructor() {
    super()
    this.state = {
      anchorEl: null,
      openMenu: false
    }
    autobind(Participant, this)
  }

  handleOpen(event) {
    const { target } = event
    const { openMenu } = this.state
    this.setState({ anchorEl: target, openMenu: !openMenu })
  }

  render() {
    const { classes, participant, onMenuClick, onButtonClick, disabled } = this.props
    const { openMenu, anchorEl } = this.state
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div className={classes.container}>
        <div className={classes.flex}>
          <IconButton size="small" onClick={this.handleOpen}>
            <MoreVertOutlined />
          </IconButton>
          <div>
            <Typography
              variant="h2"
              style={{ color: disabled ? 'grey' : '', fontWeight: 400 }}
            >
              {participant.name}
            </Typography>
            {participant?.contacts?.map(contact => (
              <div>
                <Typography variant="caption">{contact.email}</Typography>
              </div>
            ))}
            {!participant?.contacts?.map(contact => !!contact.email)?.filter(Boolean).length
              && (
                <div>
                  <Typography variant="caption">Acompañante no presenta correo...</Typography>
                </div>
              )}
          </div>
        </div>
        <div className={classes.flex}>
          {participant.gencat_sent ? (
            <div>
              <Typography>
                {`enviado en: ${moment(participant.gencat_sent, 'DD-MM-YYYY').format('DD [de] MMMM [del] YYYY')}`}
              </Typography>
            </div>
          )
            : (
              <div>
                <Button
                  onClick={onButtonClick}
                  className={classes.btn}
                  size="small"
                  disabled={participant.gencat_sent}
                  variant="contained"
                  color="primary"
                >
                  Copiar Link
                  <FileCopyOutlined style={{ marginLeft: 12 }} />
                </Button>
                <input style={{ position: 'absolute', opacity: 0, zIndex: -10 }} id="secret-input" />
              </div>
            )}
        </div>
        <Menu anchorEl={anchorEl} open={openMenu} onClose={this.handleOpen}>
          <MenuItem className={classes.menuItem} onClick={onMenuClick(participant.id, 'view')}>
            <Typography variant="subtitle2">Revisar avance de formularios</Typography>
            <Visibility />
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={onMenuClick(participant.id, 'send')}>
            <Typography variant="subtitle2">Enviar a mail de acompañante</Typography>
            <Send />
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

Participant.propTypes = {
  classes: propTypes.object.isRequired,
  participant: propTypes.object.isRequired,
  onMenuClick: propTypes.func.isRequired,
  onButtonClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
}

Participant.defaultProps = {
  disabled: false,
}

export default withStyles(style)(Participant)
