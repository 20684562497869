import React, { Component } from 'react'
import Chart from 'chart.js'
import { Paper, Typography } from '@material-ui/core';
import { Brightness1 } from '@material-ui/icons'
import propTypes from 'prop-types'

export default class Charts extends Component {
  componentDidMount() {
    const { questions, name, options } = this.props
    const chartElement = document.getElementById(name)
    const myChartRef = chartElement.getContext('2d');
    chartElement.parentNode.style.height = '130px';
    chartElement.parentNode.style.width = `${(questions.length * 120) + 200}px`;

    const handlerAnswers = []
    const companionAnswers = []
    const questionLabels = []
    questions.forEach((question, index) => {
      questionLabels.push(`${index + 1}`)
      question.answers.forEach(answer => {
        if (!answer.companion && answer.body) {
          handlerAnswers.push(parseInt(answer.body.answer, 10))
        }
        if (answer.companion && answer.body) {
          companionAnswers.push(parseInt(answer.body.answer, 10))
        }
      })
    })

    // eslint-disable-next-line no-new
    new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels: questionLabels,
        datasets: [
          {
            label: 'Facilitador',
            data: handlerAnswers,
            barThickness: 25,
            backgroundColor: '#48A4B0',
          },
          {
            label: 'Acompañante',
            data: companionAnswers,
            barThickness: 25,
            backgroundColor: '#6cd3b5',
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 0,
              max: 4,
              stepSize: 1,
              callback(value) {
                return `${options[value]}    `;
              }
            },
            gridLines: {
              display: true,
              drawBorder: false
            }
          }]
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { questions, name, options } = this.props
    const chartElement = document.getElementById(name)
    const myChartRef = chartElement.getContext('2d');
    chartElement.parentNode.style.height = '130px';
    chartElement.parentNode.style.width = `${(questions.length * 120) + 200}px`;

    const handlerAnswers = []
    const companionAnswers = []
    const questionLabels = []
    questions.forEach((question, index) => {
      questionLabels.push(`${index + 1}`)
      question.answers.forEach(answer => {
        if (!answer.companion && answer.body) {
          handlerAnswers.push(parseInt(answer.body.answer, 10))
        }
        if (answer.companion && answer.body) {
          companionAnswers.push(answer.body.answer)
        }
      })
    })

    if (JSON.stringify(prevProps.questions) !== JSON.stringify(questions)) {
      // eslint-disable-next-line no-new
      new Chart(myChartRef, {
        type: 'bar',
        data: {
          // Bring in data
          labels: questionLabels,
          datasets: [
            {
              label: 'Facilitador',
              data: handlerAnswers,
              barThickness: 25,
              backgroundColor: '#48A4B0',
            },
            {
              label: 'Acompañante',
              data: companionAnswers,
              barThickness: 25,
              backgroundColor: '#6cd3b5',
            },
          ]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                display: true,
                fontFamily: 'Montserrat, sans-serif',
                fontStyle: 'bold',
              },
              gridLines: {
                display: false,
                drawBorder: false
              }
            }],
            yAxes: [{
              ticks: {
                fontFamily: 'Montserrat, sans-serif',
                fontStyle: 'bold',
                display: true,
                min: 0,
                max: 4,
                stepSize: 1,
                callback(value) {
                  return `${options[value]}    `;
                }
              },
              gridLines: {
                display: true,
                drawBorder: false
              }
            }]
          }
        }
      });
    }
  }

  render() {
    const { name, fullWidth } = this.props
    return (
      <Paper style={{ padding: 12, width: !fullWidth && '60%' }}>
        <div style={{ marginBottom: 24 }}>
          <div style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }}>
            <Brightness1 style={{ width: 14, height: 14, marginRight: 8, color: '#48A4B0' }} />
            <Typography variant="subtitle2">Respuesta Facilitador/a</Typography>
          </div>
          <div style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }}>
            <Brightness1 style={{ width: 14, height: 14, marginRight: 8, color: '#6cd3b5' }} />
            <Typography variant="subtitle2">Respuesta Acompañante</Typography>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <canvas
              id={name}
              ref={this.chartRef}
              style={{ width: 50 }}
            />
          </div>
        </div>
      </Paper>
    )
  }
}

Charts.propTypes = {
  questions: propTypes.arrayOf(propTypes.object),
  name: propTypes.string,
  fullWidth: propTypes.bool,
  options: propTypes.arrayOf(propTypes.string)
}

Charts.defaultProps = {
  questions: [],
  name: 'theChart',
  fullWidth: false,
  options: ['No Observado', 'Nunca', 'A veces', 'Frecuentemente', 'Siempre']
}
