import React, { Component } from 'react'
import { Select, MenuItem, TextField, withStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import propTypes from 'prop-types'
import autobind from '../../Utils/autobind'

const style = () => ({
  select: {
    padding: '4px 0 6px 6px',
    color: 'black'
  },
})

function renderMonths() {
  return moment.months().map((month, index) => (
    <MenuItem value={index} key={index.toString()}>
      {month[0].toUpperCase() + month.split('').splice(1, month.length - 1).join('')}
    </MenuItem>
  ))
}


class LongDateInput extends Component {
  constructor(props) {
    super(props)
    autobind(LongDateInput, this)
  }

  handleChange(e) {
    const { value, onChange, complete } = this.props
    const { target } = e
    const actualMonth = value.split('&')[0] || ''
    const actualYear = value.split('&')[1] || ''
    let newValue = ''
    if (target.name === 'month') {
      newValue = `${target.value}&${actualYear}`
    } else {
      newValue = `${actualMonth}&${target.value}`
    }
    const event = { target: { name: complete.name, value: newValue } }
    onChange(event)
  }


  render() {
    const { classes, complete, value, highlighted, disabled } = this.props
    const actualMonth = value.split('&')[0]
    const actualYear = value.split('&')[1]
    return (
      <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gridGap: 24 }} className={highlighted ? 'highlighted' : ''}>
        <div>
          <Typography>{complete.month_text}</Typography>
          <Select
            fullWidth
            variant="filled"
            classes={{ select: classes.select }}
            style={{ borderRadius: 0 }}
            name="month"
            disabled={disabled}
            onChange={this.handleChange}
            value={actualMonth || ''}
          >
            {renderMonths()}
          </Select>
        </div>
        <div>
          <Typography style={{ fontWeight: complete.bold ? 600 : 400, color: complete.bold ? '#3E3E3E' : '#707070' }}>{complete.year_text}</Typography>
          <TextField
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0, color: 'black' } }}
            variant="filled"
            placeholder="AAAA"
            name="year"
            disabled={disabled}
            onChange={this.handleChange}
            value={actualYear || ''}
          />
        </div>
      </div>
    )
  }
}

LongDateInput.propTypes = {
  classes: propTypes.object.isRequired,
  onChange: propTypes.func.isRequired,
  // onComment: propTypes.func,
  value: propTypes.string,
  // comment: propTypes.string,
  complete: propTypes.object.isRequired,
  highlighted: propTypes.bool,
  disabled: propTypes.bool
}

LongDateInput.defaultProps = {
  value: '&',
  // comment: '',
  // onComment: undefined,
  highlighted: false,
  disabled: false
}

export default withStyles(style)(LongDateInput)
