/* eslint-disable class-methods-use-this */
import React, { Component, Fragment } from 'react'
import { Button, Dialog, Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { HighlightOff } from '@material-ui/icons'
import UpdatedFieldRenderer from './UpdatedFieldRenderer'
import FormContext from '../Components/Gencat/FormContext'
import { addExternalSubgroupAction, deleteExternalSubgroupAction } from '../Actions/Events'
import LoaderAnimation from './LoaderAnimator'
import autobind from '../Utils/autobind'

const style = theme => ({
  container: {
    textAlign: 'left'
  },
  groupContainer: {
    background: '#DCE2F2',
    padding: '6px 12px',
    margin: '-6px -12px ',
    borderRadius: 12,
    marginBottom: 12
  },
  question: {
    margin: '18px 0'
  },
  divider: {
    background: theme.palette.grey.light
  },
  addButton: {
    color: theme.palette.confirm.main,
    fontSize: 15,
    fontWeight: 600
  },
  deleteButton: {
    textAlign: 'end'
  },
  button: {
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *:first-child > svg': {
      marginRight: 3
    }
  },
  dialog: {
    padding: 24,
    textAlign: 'center',
    '& > *': {
      marginBottom: 6
    }
  },
  dialogButtons: {
    marginTop: 12,
    '& > *': {
      margin: '0 12px'
    }
  },
})

class UpdatedGeneralForm extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      selectedToDelete: false,
      groupToDeleteInfo: {}
    }
    autobind(UpdatedGeneralForm, this)
  }

  handleToggleWarning(groupId, answerId) {
    return () => {
      const groupToDeleteInfo = { groupId, answerId }
      this.setState({ selectedToDelete: groupId && answerId, groupToDeleteInfo })
    }
  }

  handleDeleteSubgroup() {
    const { deleteSubgroup } = this.props
    const { groupToDeleteInfo } = this.state
    deleteSubgroup({
      answer_id: groupToDeleteInfo.answerId,
      answer_has_group_id: groupToDeleteInfo.groupId,
    }).then(() => {
      this.setState({ selectedToDelete: false })
    })
  }

  renderSubGroups(questionGroup, answer, answerValue) {
    const { classes, disabled } = this.props
    const { onGroupChange, groups, onSubGroupChange, savedGroups } = this.context
    return questionGroup.map(group => {
      const questions = group.question.map((question, index) => {
        const info = { ...question }
        info.id = group.id
        info.name = `${answer.id}#${group.id}#${index}`
        return info
      })
      const hasAnsweredAsExpected = answerValue === '0'
      let finalQuestionGroup = []
      if (hasAnsweredAsExpected) {
        finalQuestionGroup = questions.map((question, questionIndex) => {
          const valueFromParams = groups[`${answer.id}#${group.id}#${questionIndex}`]
          const valueFromSaved = savedGroups[`${answer.id}#${group.id}#${questionIndex}`]
          const valueToUse = valueFromSaved || valueFromParams
          let value = ''
          let subquestions = {}
          if (valueToUse) {
            value = valueToUse.answer || ''
            subquestions = valueToUse.subquestions || {}
          }
          return (
            <div className={classes.question} key={question.name}>
              <UpdatedFieldRenderer
                complete={question}
                value={value || ''}
                onChange={onGroupChange}
                onSubchange={onSubGroupChange}
                subquestions={subquestions}
                disabled={disabled}
              />
            </div>
          )
        })
      }
      return (
        <div className={hasAnsweredAsExpected ? classes.groupContainer : ''} key={group.id}>
          {finalQuestionGroup.length > 0
          && (
            <div className={classes.deleteButton}>
              <Button
                className={classes.button}
                onClick={this.handleToggleWarning(group.id, answer.id)}
              >
                <HighlightOff />
                <Typography variant="subtitle1">Eliminar</Typography>
              </Button>
            </div>
          )}
          {finalQuestionGroup}
        </div>
      )
    })
  }

  renderSubGroupsActions(actions, answer, value) {
    const { classes } = this.props
    let actionsButton = []
    const positiveResponse = value === '0'
    const { addSubgroup } = this.props

    if (positiveResponse) {
      actionsButton = actions.map(action => {
        const body = {
          answer_id: answer.id,
          subquestion_group_id: action.subquestion_group_id
        }

        function addSG(info) {
          return () => {
            this.setState({ loading: true })
            addSubgroup(info).then(() => this.setState({ loading: false }))
          }
        }

        const { loading } = this.state

        return (
          <>
            {loading
              ? (
                <div style={{ position: 'relative', height: 32 }}>
                  <LoaderAnimation loading small />
                </div>
              )
              : <Button onClick={addSG.bind(this)(body)} key={action.subquestion_group_id} className={classes.addButton}>{action.add_text || ''}</Button>}
          </>
        )
      })
    }
    return actionsButton
  }

  renderQuestions(questionsArray) {
    const { userId, params,
      saved, onChange, onComment,
      comments,
      onSubchange, singleAnswer, disableNoObservation = false } = this.context
    const { classes, disabled } = this.props
    const questions = questionsArray.map(question => {
      const answer = singleAnswer
        ? question.answers[0]
        : question.answers.find(ans => ans.user_id === userId)
      if (answer) {
        const info = { ...question }
        info.id = answer.id
        info.name = answer.id
        return info
      }
      return null
    }).filter(q => q !== null)
    let enumeration = 0
    let enumerate = true
    return questions.map(question => {
      const valueFromParams = params[question.name]
      const valueFromSaved = saved[question.name]

      const comment = comments[question.name]

      let answerValue = ''
      let subquestionAnswers = {}
      const valueToUse = valueFromSaved || valueFromParams
      const isObject = typeof valueToUse === 'object'
      if (valueToUse) {
        if (isObject) {
          answerValue = valueToUse.answer
        } else {
          answerValue = valueToUse
        }
        subquestionAnswers = valueToUse.subquestions
      }

      const answer = singleAnswer
        ? question.answers[0]
        : question.answers.find(ans => ans.user_id === userId)
      const subgroups = question.sub_group
      const hasSubgroups = subgroups && subgroups.length > 0
      const subgroupQuestions = answer.subquestion_groups
      const actions = question.group_actions
      if (question.reset_enumeration) {
        enumeration = 0
      }
      enumerate = question.type !== 'title'
      if (enumerate) {
        enumeration += 1
      }
      return (
        <Fragment key={question.id}>
          <div className={classes.question}>
            <UpdatedFieldRenderer
              key={question.id}
              complete={question}
              value={answerValue}
              subquestions={subquestionAnswers}
              onChange={onChange}
              comment={comment}
              onComment={onComment}
              onSubchange={onSubchange}
              disabled={disabled}
              enumeration={enumeration}
              enumerate={enumerate}
              disableNoObservation={disableNoObservation}
            />
          </div>
          {hasSubgroups && subgroupQuestions
          && this.renderSubGroups(subgroupQuestions, answer, answerValue, subgroups)}
          {hasSubgroups && subgroupQuestions && !disabled
          && this.renderSubGroupsActions(actions, answer, answerValue)}
          {hasSubgroups && subgroupQuestions && <Divider className={classes.divider} />}
        </Fragment>
      )
    })
  }

  render() {
    const { classes, questions } = this.props;
    const { selectedToDelete } = this.state
    return (
      <div className={classes.container}>
        {this.renderQuestions(questions)}
        <Dialog fullWidth maxWidth="sm" open={selectedToDelete} onClose={this.handleToggleWarning(false, false)}>
          <div className={classes.dialog}>
            <Typography variant="h1">¿Seguro que quieres cerrar esta casilla?</Typography>
            <Typography variant="subtitle1">La información que haz ingresado será borrada</Typography>
            <div className={classes.dialogButtons}>
              <Button color="secondary" variant="contained" onClick={this.handleToggleWarning(false, false)}>
                Seguir editando
              </Button>
              <Button onClick={this.handleDeleteSubgroup}>
                Eliminar casilla
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

UpdatedGeneralForm.contextType = FormContext

UpdatedGeneralForm.propTypes = {
  classes: propTypes.object.isRequired,
  questions: propTypes.arrayOf(propTypes.object),
  addSubgroup: propTypes.func.isRequired,
  deleteSubgroup: propTypes.func.isRequired,
  disabled: propTypes.bool,
}

UpdatedGeneralForm.defaultProps = {
  questions: [],
  disabled: false
}

const mapDispatchToProps = dispatch => ({
  addSubgroup: body => dispatch(addExternalSubgroupAction(body)),
  deleteSubgroup: body => dispatch(deleteExternalSubgroupAction(body))
})

export default connect(null, mapDispatchToProps)(withStyles(style)(UpdatedGeneralForm))
