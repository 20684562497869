import { withStyles, Button, Typography } from '@material-ui/core'
import React from 'react'
import propTypes from 'prop-types'
import POIAnsweredQuestion from './POIAnsweredQuestion'
import autobind from '../../../../Utils/autobind'
import AnsweredComment from './AnsweredComment'

const style = () => ({
  container: {
    padding: '12px 24px',
    width: '800px'
  }
})

class SingleCriteriumResult extends React.Component {
  constructor(props) {
    super(props)
    autobind(SingleCriteriumResult, this)
  }

  handlePrev() {
    const { handleNextStep, step } = this.props
    if (step > 0) {
      handleNextStep(step - 1)
    }
  }

  handleNext() {
    const { handleNextStep, step } = this.props
    handleNextStep(step + 1)
  }

  renderAnswers() {
    const { info } = this.props
    const answers = []
    let commentValue = null
    Object.keys(info).forEach(criterium => info[criterium].forEach(question => {
      let { cols } = question
      if (!cols) {
        cols = 12
      }
      const answer = <div style={{ flexBasis: cols >= 6 ? '100%' : '50%', padding: '12px 0' }}><POIAnsweredQuestion question={question} /></div>

      let comment = <div style={{ flexBasis: cols >= 6 ? '0%' : '50%', padding: '12px 0' }} />
      if (question.has_comment) {
        commentValue = question.answers.comment
        comment = (<div style={{ flexBasis: cols >= 6 ? '100%' : '50%', padding: '12px 0' }}><AnsweredComment value={commentValue} /></div>)
      }

      const line = (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {answer}
          {comment}
        </div>
      )
      answers.push(line)
    }))
    return answers
  }

  render() {
    const { title, step, isLast, classes } = this.props
    return (
      <div className={classes.container}>
        <Typography style={{ color: 'black', marginBottom: 24, fontSize: 14 }}>{title}</Typography>
        <div>
          {this.renderAnswers()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {step > 0
            ? <Button color="secondary" disabled={step === 0} variant="contained" onClick={this.handlePrev}> Atrás </Button> : <div />}
          {!isLast
            ? <Button color="secondary" disabled={isLast} variant="contained" onClick={this.handleNext}> Siguiente </Button> : <div />}
        </div>
      </div>
    )
  }
}

SingleCriteriumResult.propTypes = {
  classes: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
  handleNextStep: propTypes.func.isRequired,
  step: propTypes.number.isRequired,
  isLast: propTypes.bool.isRequired,
  info: propTypes.object.isRequired
}

export default withStyles(style)(SingleCriteriumResult)
