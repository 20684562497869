import React from 'react'
import { Dialog, DialogActions, Typography, Button, DialogContent } from '@material-ui/core'
import CheckCircle from '@material-ui/icons/CheckCircle'
import propTypes from 'prop-types'


class Popup extends React.Component {
  render() {
    const { handleClose, open, title } = this.props
    return (
      <Dialog open={open} onClose={handleClose} PaperProps={{ style: { width: 500, height: 250 } }}>
        <DialogContent style={{ display: 'grid', gridTemplateRows: 'auto auto', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckCircle style={{ fontSize: '6.5rem', color: '#48A4B0' }} />
          </div>
          <Typography style={{ fontWeight: 900, fontSize: 16 }}>
            {`¡Preguntas agregadas con éxito a Entrevista ${title}!`}
          </Typography>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleClose}> Cerrar </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

Popup.propTypes = {
  open: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
  title: propTypes.string
}

Popup.defaultProps = {
  title: 'Individual'
}

export default Popup
