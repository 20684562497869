import React, { Component } from 'react'
import { ClickAwayListener, Tooltip, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import autobind from '../Utils/autobind'

const style = () => ({
  tooltip: {
    color: 'white',
    fontSize: 13,
    padding: 6
  }
})

class OnTouchTooltip extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(OnTouchTooltip, this)
  }

  handleOpenTooltip() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  renderChildren() {
    const { children } = this.props
    const realChildren = React.cloneElement(children, { onClick: this.handleOpenTooltip })
    return realChildren
  }

  render() {
    const { classes, title } = this.props;
    const { open } = this.state
    const isMobile = window.innerWidth <= 1100
    return (
      <ClickAwayListener onClickAway={() => this.setState({ open: false })}>
        {isMobile
          ? (
            <Tooltip className="no-print" arrow open={open} title={<Typography variant="subtitle1" className={classes.tooltip}>{title}</Typography>}>
              {this.renderChildren()}
            </Tooltip>
          )
          : (
            <Tooltip className="no-print" arrow title={<Typography variant="subtitle1" className={classes.tooltip}>{title}</Typography>}>
              {this.renderChildren()}
            </Tooltip>
          )}

      </ClickAwayListener>
    );
  }
}

OnTouchTooltip.propTypes = {
  classes: propTypes.object.isRequired,
  children: propTypes.node.isRequired,
  title: propTypes.string.isRequired
}

OnTouchTooltip.defaultProps = {

}
export default withStyles(style)(OnTouchTooltip)
