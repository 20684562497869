import { Tooltip, Typography, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import propTypes from 'prop-types'
import autobind from '../../../../Utils/autobind'

const style = () => ({
  link: {
    cursor: 'pointer',
    margin: '24px 0',
    textAlign: 'left',
    fontWeight: 'bold'
  }
})

class NavigatorLink extends Component {
  constructor(props) {
    super(props)
    autobind(NavigatorLink, this)
  }

  handleClick() {
    const { link } = this.props
    const element = document.getElementById(link)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  render() {
    const { classes, name } = this.props
    return (
      <Tooltip title={name}>
        <Typography variant="subtitle1" role="button" className={classes.link} onClick={this.handleClick}>{name}</Typography>
      </Tooltip>
    )
  }
}

NavigatorLink.propTypes = {
  classes: propTypes.object.isRequired,
  link: propTypes.string,
  name: propTypes.string,
}

NavigatorLink.defaultProps = {
  name: 'link',
  link: '#'
}

export default withStyles(style)(NavigatorLink)
