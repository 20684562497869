import React, { Component } from 'react'
import { Paper, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import GlobalComment from '../../Shared/GlobalComment'
import ViewTabs from '../RecurrentEvaluations/ViewTabs'
import EDPSSingleCharts from './EDPSSingleCharts'
import EDPSDisableEvaluation from './EDPSDisableEvaluation'
import { getEvaluationGroup, getFormEvaluation } from '../../API/Recurrent'
import LoaderAnimator from '../../Shared/LoaderAnimator'

const style = () => ({
  container: {
    padding: 24
  }
})

function getRealValue(answer, order, evaluation) {
  const baseOrder = [1, 2, 3, 4]
  const reverseOrder = [4, 3, 2, 1]
  if (order === 0) return baseOrder[answer - 1]
  if (order === 1) return reverseOrder[answer - 1]
  if (order === 2 && evaluation === 0) return baseOrder[answer - 1]
  return reverseOrder[answer - 1]
}

class EDPSSingleResult extends Component {
  constructor() {
    super()
    this.state = {
      evaluations: null,
      evaluationsInfo: null,
    }
  }

  async componentDidMount() {
    const { event, group } = this.props
    const { form_id: formId } = event
    const body = {
      form_id: formId,
      evaluation_group_id: group.id,
    }
    this.setState({ loading: true })
    const response = await getEvaluationGroup(body)
    if (response?.data?.status !== 'success') return null
    const groupInfo = response.data.info
    const evaluationsInfo = groupInfo.evaluations
    const evaluationPromises = evaluationsInfo.map(async evaluation => {
      const evaluationBody = {
        form_id: formId,
        evaluation_id: evaluation.id,
      }
      const evaluationResponse = await getFormEvaluation(evaluationBody)
      if (evaluationResponse?.data?.status !== 'success') return null
      return evaluationResponse.data.info
    })
    const evaluations = await Promise.all(evaluationPromises)
    return this.setState({ evaluations, evaluationsInfo, loading: false })
  }

  processInfo() {
    const { type } = this.props
    const { evaluations } = this.state
    if (!evaluations) return null
    const results = { average: { 0: 0, 1: 0 } }
    const totals = { average: { 0: 0, 1: 0 } }
    const inicos = { average: { 0: 0, 1: 0 } }
    const finalResults = { average: { 0: 0, 1: 0 } }
    const mainKeys = Object.keys(evaluations[0][`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`]?.empty).filter(key => key !== 'empty')
    mainKeys.forEach(key => {
      results[key] = {
        0: 0,
        1: 0,

      }
      totals[key] = {
        0: 0,
        1: 0,

      }
      inicos[key] = {
        0: 0,
        1: 0,

      }
      finalResults[key] = {
        0: 0,
        1: 0,

      }
    })

    evaluations.forEach((evaluation, index) => {
      if (index >= 2) return false
      const mainForm = evaluation[`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`]?.empty
      const keys = Object.keys(mainForm).filter(k => k !== 'empty')
      keys.forEach(key => {
        const questions = mainForm[key]
        questions.forEach(question => {
          const answer = question?.answers[0]?.body?.answer
          if (!answer) return null
          const readOrder = question.read_order || 0
          const realValue = getRealValue(answer, readOrder, index)
          results[key][index] += parseInt(realValue, 10)
          totals[key][index] += 4
          return true
        })
      })

      keys.forEach(key => {
        finalResults[key][index] = totals[key][index] > 0
          ? Math.round((results[key][index] * 100) / totals[key][index]) : 0
        finalResults.average[index] += totals[key][index] > 0
          ? Math.round((results[key][index] * 100) / totals[key][index]) : 0
      })
      return true
    })
    // Post process

    const finalAverages = finalResults.average
    finalAverages[0] = Math.round(finalAverages[0] / 9)
    finalAverages[1] = Math.round(finalAverages[1] / 9)

    return finalResults
  }

  render() {
    const { classes, event, contender, name, type } = this.props;
    const { evaluations, evaluationsInfo, loading } = this.state
    const data = this.processInfo()
    return (
      <Paper className={classes.container}>
        <LoaderAnimator loading={loading} />
        <>
          {evaluationsInfo?.[0].id
        && <GlobalComment event={event} evaluation={evaluationsInfo?.[0].id} />}
          <div className={classes.content}>
            <ViewTabs tabs={['Resultados', 'Autoevaluación', 'Familiar']}>
              <EDPSSingleCharts
                data={data}
                contender={contender}
                name={name}
              />
              <EDPSDisableEvaluation evaluation={evaluations?.[0]} user="0" type={type} />
              <EDPSDisableEvaluation evaluation={evaluations?.[1]} user="1" type={type} />
            </ViewTabs>
          </div>
        </>
      </Paper>
    );
  }
}

EDPSSingleResult.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  group: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  name: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
}

EDPSSingleResult.defaultProps = {

}
export default withStyles(style)(EDPSSingleResult)
