import createSnackbar from '../Utils/snackbars'
import instance from '../Utils/client'

export const getProgramsAction = () => {
  const REQUEST = instance({
    method: 'get',
    url: 'programs'
  })
  return {
    type: 'GET_PROGRAMS',
    payload: REQUEST
  }
}

export const editProgramAction = body => {
  const REQUEST = instance({
    method: 'put',
    url: `programs/${body.id}`,
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_PROGRAM',
    payload: REQUEST
  }
}

export const createProgramAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'programs',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_PROGRAM',
    payload: REQUEST
  }
}

export const deleteProgramAction = body => {
  const REQUEST = instance({
    method: 'delete',
    url: `programs/${body.id}`,
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'DELETE_PROGRAM',
    payload: REQUEST
  }
}
