import React from 'react'
import propTypes from 'prop-types'
import { TimePicker } from '@material-ui/pickers/'

const CustomTimePicker = ({ onChange, value, FieldComponent, format }) => (
  <TimePicker
    cancelLabel="Cancelar"
    InputProps={{
      disableUnderline: true,
      style: { fontSize: '0.8rem' },
      fullWidth: true,
    }}
    TextFieldComponent={FieldComponent || undefined}
    ampm
    format={format}
    value={value}
    onChange={onChange}
  />
)

CustomTimePicker.propTypes = {
  onChange: propTypes.func.isRequired,
  value: propTypes.object.isRequired,
  FieldComponent: propTypes.object,
  format: propTypes.string
}

CustomTimePicker.defaultProps = {
  FieldComponent: undefined,
  format: 'HH:mm'
}

export default CustomTimePicker
