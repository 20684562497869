/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'
import { Button, Dialog, Divider, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { Delete, NoteAdd } from '@material-ui/icons'
import moment from 'moment'
import { connect } from 'react-redux'
import SetViewContext from '../../Contenders/Profile/SetViewProvider'
import Evaluation from '../Evaluation/Evaluation'
import autobind from '../../../Utils/autobind'
import Results from './Results'
import LoaderDialog from '../../../Shared/LoaderDialog'
import ConditionalWrapper from '../../../Shared/ConditionalWrapper'
import { createAuthorization } from '../../../API/Authorizations'

const style = theme => ({
  container: {
    textAlign: 'left'
  },
  iconButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      marginRight: 6
    },
    margin: '36px 0'
  },
  titleDivider: {
    display: 'flex',
    alignItems: 'center',
    '& *': {
      margin: '0 6px',
    },
    '& > *:nth-child(2)': {
      flexGrow: 1,
      background: theme.palette.grey.main
    },
    marginBottom: 24
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)',
      cursor: 'pointer',
    },
    padding: 12
  },
  bluryTable: {
    background: theme.palette.grey.light,
    borderRadius: 8,
    boxShadow: '0px 1px 8px #00000026',
    marginBottom: 32
  },
  divider: {
    margin: '0 12px',
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    }
  },
  leftAlignedText: {
    textAlign: 'left'
  },
  rightAlignedText: {
    textAlign: 'right',
    '& > *': {
      marginLeft: 12
    }
  }
})

class Forms extends Component {
  constructor() {
    super()
    this.state = {
      openDelete: false,
      selectedEvaluation: null,
      loading: false,
    }
    autobind(Forms, this)
  }

  handleOpenDelete(evaluation) {
    return e => {
      e.stopPropagation()
      const { openDelete } = this.state
      this.setState({ openDelete: !openDelete, selectedEvaluation: evaluation })
    }
  }

  async handleConfirmDelete() {
    const { reload, contender } = this.props
    const { selectedEvaluation } = this.state
    const extraInfo = {
      evaluation_id: selectedEvaluation.id
    }
    const body = { type_id: 6,
      text: `Borrar Eval. de competencias de ${contender.user.name}`,
      contender_id: contender.contender.id,
      extra_info: extraInfo }
    await createAuthorization(body, 'eliminar Eval. de competencias')
    reload()
    this.setState({ openDelete: false })
  }

  handleClickNew() {
    const { newEvaluation, event, contenders } = this.props
    const body = {
      form_id: event.form_id,
      user_id: contenders?.selected?.user?.id
    }
    newEvaluation(body)
  }

  handleClickPending(evaluation) {
    const { getEvaluationForm, event, changeStatus } = this.props
    const { setView } = this.context
    const body = {
      form_id: event.form_id,
      evaluation_id: evaluation.id
    }
    this.setState({ loading: true })
    getEvaluationForm(body).then(() => {
      this.setState({ loading: false })
      setView(<Evaluation event={event} evaluation={evaluation.id} changeStatus={changeStatus} />, 'Formulario de Evaluación')
    })
  }

  handleClickCheck(evaluation) {
    return () => {
      const { getEvaluationResult, getEvaluationForm, event } = this.props
      const { setView } = this.context
      const body = {
        form_id: event.form_id,
        evaluation_id: evaluation.id
      }
      this.setState({ loading: true })
      getEvaluationForm(body)
      getEvaluationResult(body).then(() => {
        this.setState({ loading: false })
        setView(<Results event={event} evaluation={evaluation.id} name={evaluation.name} />,
          `Resultados de Evaluación de competencias ${evaluation.name}`)
      })
    }
  }

  renderEvaluations() {
    const { classes, evaluations } = this.props
    const tooltipWrapper = e => (
      <Tooltip title="La solicitud para eliminar está evaluación se está revisando">
        {e}
      </Tooltip>
    )
    const pendingEv = evaluations.filter(ev => ev.status === 'pending').map((evaluation, index) => (
      <div key={evaluation.id} style={{ position: 'relative' }}>
        {index > 0 && <Divider className={classes.divider} />}
        <ConditionalWrapper
          wrapper={tooltipWrapper}
          condition={evaluation.to_be_deleted}
        >
          <div
            className={classes.row}
            onClick={e => {
              e.stopPropagation()
              this.handleClickPending(evaluation)
            }}
          >
            <Typography variant="subtitle1">{`Evaluación de competencias ${evaluation.name}`}</Typography>
            <Typography variant="subtitle1">Responsable: Pendiente</Typography>
            <Typography variant="subtitle2">{moment(evaluation.created_at).format('DD [de] MMM [del] YYYY')}</Typography>
            <div />
          </div>
          <div style={{ position: 'absolute', top: '50%', right: 12, transform: 'translate(0, -50%)' }}>
            <IconButton disabled={evaluation.to_be_deleted} size="small" onClick={this.handleOpenDelete(evaluation)}><Delete /></IconButton>
          </div>
        </ConditionalWrapper>
      </div>
    ))

    const historicEv = evaluations.filter(ev => ev.status === 'check').map((evaluation, index) => (
      <React.Fragment key={evaluation.id}>
        {index > 0 && <Divider className={classes.divider} />}
        <ConditionalWrapper
          wrapper={tooltipWrapper}
          condition={evaluation.to_be_deleted}
        >
          <div className={classes.row} onClick={this.handleClickCheck(evaluation)}>
            <Typography variant="subtitle1">{`Evaluación de competencias ${evaluation.name}`}</Typography>
            <Typography variant="subtitle1">
              {`Responsable: ${evaluation.allocated_by}`}
            </Typography>
            <Typography variant="subtitle2">{moment(evaluation.created_at).format('DD [de] MMM [del] YYYY')}</Typography>
            <IconButton disabled={evaluation.to_be_deleted} size="small" onClick={this.handleOpenDelete(evaluation)}><Delete /></IconButton>
          </div>
        </ConditionalWrapper>
      </React.Fragment>
    ))

    const pendings = (
      <>
        <div className={classes.titleDivider}>
          <Typography variant="h2">En proceso</Typography>
          <Divider />
        </div>
        <div className={classes.bluryTable}>
          {pendingEv}
        </div>
      </>
    )
    const historical = (
      <>
        <div className={classes.titleDivider}>
          <Typography variant="h2">Historial</Typography>
          <Divider />
        </div>
        <div className={classes.bluryTable}>
          {historicEv}
        </div>
      </>
    )
    return (
      <>
        {pendings}
        {historical}
      </>
    )
  }

  render() {
    const { classes } = this.props;
    const { openDelete, loading } = this.state
    return (
      <div className={classes.container}>
        <LoaderDialog open={loading} />
        <Button color="primary" variant="contained" className={classes.iconButton} onClick={this.handleClickNew}>
          <NoteAdd />
          Nueva Evaluación
        </Button>
        {this.renderEvaluations()}
        <Dialog open={openDelete} onClose={this.handleOpenDelete()} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="h2">
              ¿Seguro que desea eliminar esta evaluación?
            </Typography>
            <div>
              <Typography>
                Al confirmar esta acción se creará una autorización para eliminar la evaluación
              </Typography>
            </div>
            <div className={classes.rightAlignedText}>
              <Button variant="outlined" onClick={this.handleOpenDelete()}>
                Cancelar
              </Button>
              <Button color="secondary" variant="outlined" onClick={this.handleConfirmDelete}>
                Eliminar
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

Forms.contextType = SetViewContext

Forms.propTypes = {
  classes: propTypes.object.isRequired,
  evaluations: propTypes.arrayOf(propTypes.object),
  contender: propTypes.object.isRequired,
  getEvaluationForm: propTypes.func.isRequired,
  getEvaluationResult: propTypes.func.isRequired,
  newEvaluation: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  changeStatus: propTypes.func.isRequired,
  reload: propTypes.func.isRequired,
}

Forms.defaultProps = {
  evaluations: []
}

const mapStateToProps = state => ({
  contender: state.contenders.selected
})

export default connect(mapStateToProps, null)(withStyles(style)(Forms))
