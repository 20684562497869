import React, { Component } from 'react'
import { withStyles, Paper, Button, Typography, withTheme } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from '../../../../Shared/Loader'
import { getFormAction } from '../../../../Actions/Contenders'
import CriteriaForm from '../../../../Shared/CriteriaForm'
import autobind from '../../../../Utils/autobind'
import { sendAnswersAction, markFormAsFinishedAction, allocateEvaluationAction } from '../../../../Actions/Events'
import SetViewContext from '../../../Contenders/Profile/SetViewProvider'
import InterviewNavigator from './InterviewNavigator'
import { convertToRoman } from '../../../../Utils/functions'
import Criterium from '../../../../Shared/Criterium'
import AutoSaver from '../../../../Shared/AutoSaver'
import GlobalComment from '../../../../Shared/GlobalComment'

const style = theme => ({
  container: {
    padding: 12,
    height: 'max-content',
  },
  selectedText: {
    color: theme.palette.confirm.main
  },
  btn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  mainContainer: {
    display: 'flex',
    gap: '12px',
    maxHeight: '80vh',
    overflow: 'auto',
    paddingBottom: 12,
    paddingRight: 12
  },
  commentBox: {
    backgroundColor: theme.palette.grey.light,
    padding: 12,
    paddingBottom: 24,
    marginTop: 6,
    color: '#3E3E3E',
    fontSize: 14
  },
  commentContainer: {
    marginBottom: 12
  },
  subCriterium: {
    '& h3': {
      color: theme.palette.primary.dark,
      fontSize: 14,
      fontWeight: '900',
    }
  }
})

class FamiliarInterviewWithIndicators extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
      saved: {},
      comments: {},
      savedComments: {}
    }
    autobind(FamiliarInterviewWithIndicators, this)
    this.criteria = []
  }

  componentDidMount() {
    const { getForm, event, contender } = this.props
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
      user_id: contender.user.id
    }
    getForm(body).then(() => {
      this.setAnsweredQuestions()
    })
  }

  handleChange(event) {
    const { target } = event
    const { params, saved } = this.state
    const newSaved = { ...saved }
    const newParams = { ...newSaved, ...params, }
    delete newSaved[target.name]
    if (newParams[target.name]) {
      newParams[target.name].answer = target.value
    } else {
      newParams[target.name] = { answer: target.value }
    }
    this.setState({ params: newParams, saved: newSaved })
  }

  handleComment(event) {
    const { target } = event
    const { comments, savedComments } = this.state
    comments[target.name] = target.value
    if (Object.keys(savedComments).includes(target.name)) {
      savedComments[target.name] = null
    }

    this.setState({ comments, savedComments })
  }

  handleSubchange(event) {
    const { target } = event
    const { params, saved } = this.state
    const newSaved = { ...saved }
    const newParams = { ...newSaved, ...params }
    const actualName = target.name.split('&')[0]
    delete newSaved[actualName]
    if (newParams[actualName]?.subquestions) {
      newParams[actualName].subquestions[target.name] = target.value
    } else {
      newParams[actualName] = {
        ...newParams[actualName], subquestions: { [target.name]: target.value }
      }
    }
    this.setState({ params: newParams, saved: newSaved })
  }

  handleSave() {
    const { sendAnswers, event, contender, checkForm, allocateEvaluation } = this.props
    const { setView } = this.context
    const { params, saved, comments, savedComments } = this.state
    const newSaved = { ...saved, ...params }
    const newComments = { ...savedComments, ...comments }
    const answers = { ...params }
    const answersComments = { ...savedComments, ...comments }
    const newKeys = Array.from(new Set(Object.keys(answers).concat(Object.keys({ ...comments }))))
    if (newKeys.length > 0) {
      const body = newKeys.map(key => ({
        id: key,
        body: answers[key],
        comment: answersComments[key],
      }))
      this.setState({ saved: newSaved, params: {}, savedComments: newComments, comments: {} })
      sendAnswers({ answers: body }, event.event_id)
    }
    const assignBody = {
      evaluation_id: event.evaluation_id,
    }
    checkForm({ user_id: contender.user.id, event_id: event.event_id, form_id: event.form_id, status: 'check' })
    allocateEvaluation(assignBody)
    setView()
  }

  handleAutoSave() {
    const { event, sendAnswers } = this.props
    const { params, saved, comments, savedComments } = this.state
    const newSaved = { ...saved, ...params }
    const newComments = { ...savedComments, ...comments }
    const answers = { ...params }
    const answersComments = { ...savedComments, ...comments }
    const newKeys = Array.from(new Set(Object.keys(answers).concat(Object.keys({ ...comments }))))
    if (newKeys.length > 0) {
      const body = newKeys.map(key => ({
        id: key,
        body: answers[key],
        comment: answersComments[key],
      }))
      this.setState({ saved: newSaved, params: {}, savedComments: newComments, comments: {} })
      sendAnswers({ answers: body }, event.event_id)
      return true
    }
    return false
  }

  setAnsweredQuestions() {
    const { forms } = this.props
    const actualform = forms && forms.form ? forms.form['Entrevista Familiar'] : {}
    const { saved, savedComments } = this.state
    const newSaved = { ...saved }
    const newSavedComments = { ...savedComments }
    Object.entries(actualform).forEach(([, empty]) => {
      if (empty.empty) {
        const questions = empty.empty
        questions.forEach(question => {
          const { answers } = question
          answers.forEach(answer => {
            if (answer.body !== null) {
              newSaved[answer.id] = answer.body
            }
            if (answer.comment !== null) {
              newSavedComments[answer.id] = answer.comment
            }
          })
        })
      } else if (Object.entries(empty).length > 0) {
        return Object.entries(empty).forEach(([, info]) => {
          const incidenseQuestions = info.questions
          incidenseQuestions.forEach(question => {
            const { answers } = question
            answers.forEach(answer => {
              if (answer.body !== null) {
                newSaved[answer.id] = answer.body
              }
              if (answer.comment !== null) {
                newSavedComments[answer.id] = answer.comment
              }
            })
          })
        })
      }
      return 0
    })
    this.setState({ saved: newSaved, savedComments: newSavedComments })
  }

  renderCriteria() {
    const { forms, contender, classes } = this.props
    this.criteria = []
    const { params, saved, comments, savedComments } = this.state
    const actualform = forms && forms.form ? forms.form['Entrevista Familiar'] : {}
    const indagationFormName = 'Indagación de indicadores descendidos'
    const completeForm = Object.entries(actualform).map(([criterium, empty], index) => {
      const criteriums = Object.keys(actualform[indagationFormName])
      const dynamicQuestions = []
      criteriums.forEach(criteriumName => {
        const filtered = actualform[indagationFormName][criteriumName]
          .questions.filter(q => q.section_index === index)
        dynamicQuestions.push(...filtered)
      })
      if (empty.empty) {
        const newCrit = { [criterium]: empty.empty }
        this.criteria.push({ name: criterium, link: criterium })
        return (
          <CriteriaForm
            id={criterium}
            key={criterium}
            title={`${convertToRoman(index + 1)} - ${criterium}`}
            criteria={newCrit}
            params={params}
            handleChange={this.handleChange}
            handleComment={this.handleComment}
            handleSubchange={this.handleSubchange}
            withButton={false}
            saved={saved}
            dynamic={dynamicQuestions}
            comments={comments}
            savedComments={savedComments}
            userId={contender.user.id}
            bigScreen={false}
            enumerate
          />
        )
      }
      if (Object.entries(empty).length > 0) {
        this.criteria.push({ name: criterium, link: criterium })
        const subCategories = Object.entries(empty).map(([subcriterium, info], subindex) => {
          const questions = info.questions.filter(q => q.section_index === -1)
          if (questions.length > 0) {
            const newCrit = { [subcriterium]: questions }
            return (
              <div className={classes.subCriterium} style={{ marginLeft: 24 }}>
                <CriteriaForm
                  id={criterium}
                  title={`${convertToRoman(subindex + 1)} - ${subcriterium}`}
                  key={subcriterium}
                  criteria={newCrit}
                  params={params}
                  handleChange={this.handleChange}
                  handleComment={this.handleComment}
                  handleSubchange={this.handleSubchange}
                  withButton={false}
                  saved={saved}
                  dynamic={dynamicQuestions}
                  comments={comments}
                  savedComments={savedComments}
                  userId={contender.user.id}
                  bigScreen={false}
                  enumerate
                />
              </div>
            )
          }
          return null
        })
        return (
          <div id={criterium}>
            {subCategories.filter(cat => cat !== null).length > 0
              && <Criterium label={`${convertToRoman(index + 1)} - ${criterium}`} />}
            {subCategories}
          </div>
        )
      }
      return null
    })
    return completeForm
  }

  render() {
    const { classes, getForm, event, contender } = this.props
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
      user_id: contender.user.id
    }

    return (
      <div className={classes.mainContainer}>
        <Paper className={classes.container}>
          <Loader action={getForm} params={[body]}>
            {this.renderCriteria()}
            <div className={classes.commentContainer}>
              <Typography
                variant="h2"
                color="primary"
              >
                Agregar comentarios sobre entrevista
              </Typography>
              <div style={{ margin: '12px 0' }}>
                <GlobalComment event={event} />
              </div>
            </div>
          </Loader>
          <div className={classes.btn}>
            <Button color="secondary" variant="contained" onClick={this.handleSave}>
              Finalizar Entrevista
            </Button>
          </div>
        </Paper>
        <InterviewNavigator links={this.criteria} />
        <AutoSaver onSave={this.handleAutoSave} timeout={60000} />
      </div>
    )
  }
}

FamiliarInterviewWithIndicators.contextType = SetViewContext

FamiliarInterviewWithIndicators.propTypes = {
  classes: propTypes.object.isRequired,
  getForm: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  forms: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  sendAnswers: propTypes.func.isRequired,
  checkForm: propTypes.func.isRequired,
  allocateEvaluation: propTypes.func.isRequired,
  user: propTypes.object.isRequired,
}

FamiliarInterviewWithIndicators.defaultProps = {
}

const mapStateToProps = state => ({
  contender: state.contenders.selected,
  forms: state.contenders.forms,
  user: state.user.current
})

const mapDispatchToProps = dispatch => ({
  getForm: body => dispatch(getFormAction(body)),
  sendAnswers: body => dispatch(sendAnswersAction(body)),
  checkForm: body => dispatch(markFormAsFinishedAction(body)),
  allocateEvaluation: body => dispatch(allocateEvaluationAction(body))
})

export default withTheme(connect(
  mapStateToProps, mapDispatchToProps
)(withStyles(style)(FamiliarInterviewWithIndicators)))
