import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import DownloadableSection from 'Shared/DownloadableSection'
import MultipleLevelsLineChart from './MultipleLevelsLineChart'
import MultipleRadarChart from './MultipleRadarChart'
import MultipleAbilitySupportChart from './MultipleAbilitySupportChart'
import MultipleComparisonCompetency from './MultipleComparisonCompetency'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import { editEvaluationComment, getEvaluationComment } from '../../../API/Evaluations'
import autobind from '../../../Utils/autobind'
import GlobalComment from '../../../Shared/GlobalComment'

const style = theme => ({
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 600
  },
  levelContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0'
  },
  level: {
    background: theme.palette.grey.light,
    padding: '6px 36px',
    marginLeft: 12,
    borderRadius: 5
  },
  competencies: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '&>*': {
      width: '48%'
    },
    margin: '36px 0'
  },
  observation: {
    margin: '24px 0 12px 0',
    textAlign: 'left',
    '& > *': {
      margin: '3px 0'
    }
  },
  sectionTitle: {
    margin: '36px 0 24px',
    fontWeight: 'bold'
  }
})

const radarOptions = {
  color: '#1F8095BB',
  maxTicksLimit: 4,
  height: '300px',
  minValue: 0,
  maxValue: 5,
  stepSize: 1
}

class MultipleResults extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      comment: ''
    }
    autobind(MultipleResults, this)
  }

  async componentDidMount() {
    const { getResults, event, contender } = this.props
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      type: 'competences'
    }
    getResults(body).then(() => this.setState({ loading: false }))
    const commentBody = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contender.user.id
    }
    const response = await getEvaluationComment(commentBody)
    if (response.data.status !== 'success') return null
    return this.setState({ comment: response.data.info.comment })
  }

  handleChange(event) {
    const { target } = event
    this.setState({ comment: target.value })
  }

  handleSave() {
    const { comment } = this.state
    const { event, contender } = this.props
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contender.user.id,
      comment
    }
    editEvaluationComment(body)
  }

  processData() {
    const { competency } = this.props
    const multipleResults = competency?.allResults?.results || {}
    const original = Object.keys(multipleResults)
    const EFD = original[original.length - 1]
    const evaluationsKeys = [EFD].concat(original.splice(0, original.length - 1))
    const evaluationNames = Object.keys(multipleResults).map((name, index) => (index === 0 ? 'EFD' : `EDC ${index}`))
    const evaluationDates = evaluationsKeys.map(key => multipleResults[key]?.evaluation?.updated_at)

    // Lines
    const linesLabels = evaluationNames
    const linesValues = evaluationsKeys.map(key => multipleResults[key]?.competences_detail.avg)
    const linesDates = evaluationDates

    // Radar
    const radarData = evaluationsKeys.map(key => {
      const result = {}
      // eslint-disable-next-line no-unused-expressions
      multipleResults[key]?.competences_detail?.competencies?.forEach(comp => {
        result[`${comp.name.slice(0, 10)}...`] = comp.total
      })
      return result
    })

    // Comparison
    let competencies = []
    const comparisonData = evaluationsKeys.map(key => {
      competencies = multipleResults[key]?.competences_detail?.competencies.map(comp => comp.name)
      return multipleResults[key]?.competences_detail?.competencies.map(comp => comp.total)
    })
    const evaluations = evaluationNames

    // Skills

    const mainKey = evaluationsKeys[1]
    const skillLabels = Object.keys(multipleResults[mainKey]?.skill_detail)
    const skillsData = evaluationsKeys
      .map(key => skillLabels
        .map(label => multipleResults[key]?.skill_detail[label]?.avg || 0))

    return {
      lines: {
        data: linesValues,
        labels: linesLabels,
        dates: linesDates
      },
      radar: {
        data: radarData
      },
      comparison: {
        datas: comparisonData,
        evaluations,
        competencies
      },
      skills: {
        datas: skillsData,
        labels: skillLabels,
        evaluations: evaluationNames
      }
    }
  }

  render() {
    const { classes, event, contender } = this.props;
    const { loading } = this.state
    const processedData = loading ? {} : this.processData()
    const latestDate = processedData?.lines?.dates[processedData?.lines?.dates?.length - 1] || moment(new Date()).format('DD/MM/YYYY')
    return (
      <div className={classes.container}>
        {loading
          ? (
            <div style={{ height: 300, position: 'relative' }}>
              <LoaderAnimator loading />
            </div>
          )
          : (
            <>
              <div className={classes.header}>
                <Typography variant="h2">
                  {'Última modificación: '}
                  <span className={classes.bold}>{moment(latestDate).format('DD/MM/YYYY')}</span>
                </Typography>
              </div>
              <GlobalComment event={event} />
              <DownloadableSection margin id="head" name={`Progreso de evaluación de competencias ${contender.user.name}`}>
                <div className={classes.competencies}>
                  <div>
                    <Typography variant="h2" className={classes.sectionTitle}>Progreso de evaluaciones</Typography>
                    <MultipleLevelsLineChart
                      processedData={processedData.lines || { data: [], labels: [] }}
                    />
                  </div>
                  <div>
                    <Typography variant="h2" className={classes.sectionTitle}>Nivel de apoyo de competencias</Typography>
                    <MultipleRadarChart name="competencias" title="" options={radarOptions} data={processedData?.radar?.data || {}} />
                  </div>
                </div>
              </DownloadableSection>
              <DownloadableSection margin id="mid" name={`Competencias por evaluación ${contender.user.name}`}>
                <div className={classes.abilityChart}>
                  <Typography variant="h2" className={classes.sectionTitle}>Comparación de competencias</Typography>
                  <MultipleComparisonCompetency
                    data={processedData?.comparison
                    || { datas: [], evaluations: [], competencies: []
                    }}
                  />
                </div>
              </DownloadableSection>
              <DownloadableSection margin id="bot" name={`Nivel de apoyo por habilidades ${contender.user.name}`}>
                <div className={classes.abilityChart}>
                  <Typography variant="h2" className={classes.sectionTitle}>Nivel de apoyo por habilidades</Typography>
                  <MultipleAbilitySupportChart
                    data={processedData?.skills || { datas: [], labels: [], evaluations: [] }}
                  />
                </div>
              </DownloadableSection>
            </>
          )}
      </div>
    );
  }
}

MultipleResults.propTypes = {
  classes: propTypes.object.isRequired,
  competency: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  getResults: propTypes.func.isRequired,
  contender: propTypes.object.isRequired,
}

MultipleResults.defaultProps = {

}

const mapStateToProps = state => ({
  competency: state.competency,
  contender: state.contenders.selected
})

export default connect(mapStateToProps)(withStyles(style)(MultipleResults))
