import React, { Component } from 'react'
import { Paper, withStyles, Typography, Divider, ListItem, ListItemText } from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import propTypes from 'prop-types'
import moment from 'moment'
import { markNotificationsAsSeen } from 'API/notifications'

const style = () => ({
  container: {
    padding: 12,
    maxWidth: 500
  },
  oldNotifications: {
    maxHeight: 400,
    overflow: 'auto'
  }
})

// This should probably go on utils/functions
const colorizeText = text => {
  const colors = {
    'Etrevista individual y familiar': '#48A4B0',
    'Evaluación funcional': '#000051',
    Reunión: '#FFB300',
    'Seguimiento de derivación': '#3E3E3E',
    autorización: 'black',
    'Edición de programa': 'black'

  }
  if (typeof text === 'string') {
    let keyFound = false
    const newText = []
    Object.keys(colors).forEach(key => {
      if (text.includes(key) && !keyFound) {
        const split = text.split(key)
        split.forEach((text2, index) => {
          newText.push(text2)
          if (index !== split.length - 1) {
            newText.push(<span style={{ color: colors[key], fontWeight: 600 }}>{key}</span>)
          }
        })
        keyFound = true
      }
    })
    const replaced = [...newText]
    newText.forEach((subtext, index) => {
      replaced[index] = colorizeText(subtext)
    })
    return keyFound ? replaced.flat() : [text]
  }
  return text
}

class Notifications extends Component {
  constructor() {
    super()
    this.state = {
      openAll: false
    }
    this.handleOpenAll = this.handleOpenAll.bind(this)
  }

  async componentDidMount() {
    const { reload } = this.props
    reload()
    await markNotificationsAsSeen()
  }

  componentWillUnmount() {
    window.dispatchEvent(new Event('notificationArrive'))
  }

  handleOpenAll() {
    const { openAll } = this.state
    this.setState({ openAll: !openAll })
  }

  render() {
    const { classes, notifications } = this.props
    const { openAll } = this.state
    const newNotifications = notifications.filter(n => !n.seen)
    const AllOldNotifications = notifications.filter(n => n.seen)
    const oldNotifications = openAll ? AllOldNotifications : AllOldNotifications.slice(0, 6)
    return (
      <Paper className={classes.container}>
        <Typography variant="subtitle1" style={{ marginBottom: 24, padding: '4px 12px' }}>Notificaciones</Typography>

        <Typography variant="body2" style={{ padding: '4px 4px' }}>Últimas notificaciones</Typography>
        <Divider style={{ margin: 0 }} />
        <div className={classes.oldNotifications}>
          {newNotifications.map(element => {
            const newText = colorizeText(element.body)
            return (
              <>
                <Typography variant="body1" style={{ padding: '4px 12px' }}>
                  {newText.map(text => text)}
                </Typography>
                <div style={{ display: 'flex', padding: '0 12px', alignItems: 'center' }}>
                  <AccessTime style={{ height: 12, width: 12, color: '#6A6A6A' }} />
                  <Typography variant="body2" style={{ padding: '4px 6px' }}>
                    {moment(element.created_at).fromNow()}
                  </Typography>
                </div>
                <Divider style={{ margin: 0 }} />
              </>
            )
          })}
        </div>
        <Typography variant="body2" style={{ marginTop: 24, marginBottom: 0, padding: '4px 4px' }}>Notificaciones antiguas</Typography>
        <Divider style={{ margin: 0 }} />
        <div className={classes.oldNotifications}>
          {oldNotifications.map(element => {
            const newText = colorizeText(element.body)
            return (
              <>
                <Typography variant="body1" style={{ padding: '4px 12px' }}>
                  {newText.map(text => text)}
                </Typography>
                <div style={{ display: 'flex', padding: '4px 12px', alignItems: 'center' }}>
                  <AccessTime style={{ height: 12, width: 12, color: '#6A6A6A' }} />
                  <Typography variant="body2" style={{ padding: '4px 6px' }}>
                    {moment(element.created_at).fromNow()}
                  </Typography>
                </div>
                <Divider style={{ margin: 0 }} />
              </>
            )
          })}
        </div>
        <ListItem button onClick={this.handleOpenAll}>
          <ListItemText style={{ textAlign: 'center' }} primary={`Ver ${openAll ? 'Menos' : 'Todas'}`} />
        </ListItem>
      </Paper>
    )
  }
}

Notifications.propTypes = {
  classes: propTypes.object.isRequired,
  notifications: propTypes.arrayOf(propTypes.object).isRequired,
  reload: propTypes.func.isRequired
}

export default withStyles(style)(Notifications)
