import React, { Component } from 'react'
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Dialog,
  Typography,
} from '@material-ui/core'
import propTypes from 'prop-types'
import moment from 'moment'
import { getEventColor } from '../../Utils/functions'

const style = () => ({
  container: {},
  item: {
    color: 'black',
  },
  currDate: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    color: 'black',
  },
})

class EventsDetail extends Component {
  render() {
    const { classes, handleClose, open, events, clickHandler } = this.props
    const currDate = events[0]
      ? moment(events[0].props.event.date).format('DD [de] MMMM [del] YYYY')
      : moment().format('DD [de] MMMM [del] YYYY')
    return (
      <Dialog onClose={handleClose} open={open} className={classes.container}>
        <DialogTitle>
          Eventos
          <Typography display="inline" className={classes.currDate}>
            {` ${currDate}`}
          </Typography>
        </DialogTitle>
        <List style={{ display: 'grid', gridGap: 1 }}>
          {events.map(event => (
            <ListItem
              style={{ backgroundColor: getEventColor(event.props.event.full.event_type_name) }}
              button
              key={event.props.event.id}
              onClick={clickHandler(event.props.event)}
            >
              <ListItemText
                primaryTypographyProps={{ style: { color: 'white', fontWeight: 'bold' } }}
                primary={`${event.props.event.time} - ${event.props.event.name}`}
              />
            </ListItem>
          ))}
        </List>
      </Dialog>
    )
  }
}

EventsDetail.propTypes = {
  classes: propTypes.object.isRequired,
  handleClose: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  clickHandler: propTypes.func.isRequired,
  events: propTypes.arrayOf(
    propTypes.shape({ name: propTypes.string,
      time: propTypes.string,
      props: propTypes.object,
      full: propTypes.object })
  ).isRequired,
}

export default withStyles(style)(EventsDetail)
