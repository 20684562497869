import React, { Component } from 'react'
import { Button, Divider, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import autobind from '../../../../Utils/autobind'

const style = theme => ({
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    paddingBottom: 0
  },
  tab: {
    marginRight: 12,
    fontWeight: 400,
    borderBottom: '2px solid transparent'
  },
  divider: {
    background: '#8A8A8A',
    margin: '0 12px 12px 12px'
  },
  selected: {
    borderRadius: 0,
    fontWeight: 600,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  }
})

class POITabs extends Component {
  constructor() {
    super()
    this.state = {
      selected: 0
    }
    autobind(POITabs, this)
  }

  handleSelectTab(value) {
    return () => {
      this.setState({ selected: value })
    }
  }

  renderTabs() {
    const { classes } = this.props
    const { selected } = this.state
    const tabs = ['Resultados', 'Formulario Postulante', 'Formulario Acompañante']
    return tabs.map((tab, index) => (
      <Button className={`${classes.tab} ${selected === index ? classes.selected : ''}`} color="primary" onClick={this.handleSelectTab(index)}>
        {tab}
      </Button>
    ))
  }

  renderSelectedChildren() {
    const { children } = this.props
    const { selected } = this.state
    const kids = React.Children.toArray(children)
    return kids[selected]
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.tabsContainer}>
          {this.renderTabs()}
        </div>
        <Divider className={classes.divider} />
        {this.renderSelectedChildren()}
      </div>
    );
  }
}

POITabs.propTypes = {
  classes: propTypes.object.isRequired,
  children: propTypes.node.isRequired
}

POITabs.defaultProps = {

}
export default withStyles(style)(POITabs)
