import React from 'react'
import propTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Dialog, Button, Typography } from '@material-ui/core'

const style = () => ({
  dialog: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    }
  },
  rightAlignedText: {
    textAlign: 'right',
    '& > *': {
      marginLeft: 12
    }
  }
})

const ConfirmDeleteDialog = ({ classes, isOpen, onConfirm, onCancel, title, description }) => (
  <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
    <div className={classes.dialog}>
      <Typography variant="h2">
        {title}
      </Typography>
      <div>
        <Typography>
          {description}
        </Typography>
      </div>
      <div className={classes.rightAlignedText}>
        <Button variant="outlined" onClick={onCancel}>
          Cancelar
        </Button>
        <Button color="secondary" variant="outlined" onClick={onConfirm}>
          Eliminar
        </Button>
      </div>
    </div>
  </Dialog>
)

ConfirmDeleteDialog.propTypes = {
  classes: propTypes.object.isRequired,
  isOpen: propTypes.bool.isRequired,
  onConfirm: propTypes.func,
  onCancel: propTypes.func,
  title: propTypes.string,
  description: propTypes.string,
}

ConfirmDeleteDialog.defaultProps = {
  onConfirm: null,
  onCancel: null,
  title: '¿Estás seguro de realizar esta acción?',
  description: null
}

export default withStyles(style)(ConfirmDeleteDialog)
