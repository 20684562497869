import React, { Component } from 'react'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import 'moment/locale/es' // without this line it didn't work
import propTypes from 'prop-types'

const style = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    padding: '0 6px',
    color: theme.palette.grey.dark,
    fontSize: 18,
  },
  arrow: {
    color: theme.palette.grey.dark,
  },
  textContainer: {
    width: 200
  }
})

class MonthSelector extends Component {
  render() {
    const { classes, currentDate, handleChange, backgroundColor } = this.props
    const formatDate = currentDate.locale('es').format('MMMM-YYYY')
    const resultDate = formatDate.charAt(0).toUpperCase() + formatDate.slice(1)
    return (
      <div
        className={classes.container}
        style={backgroundColor ? { background: backgroundColor } : {}}
      >
        <IconButton onClick={() => handleChange('sub')}>
          <KeyboardArrowLeft className={classes.arrow} />
        </IconButton>
        <div className={classes.textContainer}>
          <Typography varinat="body1" className={classes.text}>
            {resultDate}
          </Typography>
        </div>
        <IconButton onClick={() => handleChange('add')}>
          <KeyboardArrowRight className={classes.arrow} />
        </IconButton>
      </div>
    )
  }
}

MonthSelector.defaultProps = {
  backgroundColor: '#F7F7F7',
}

MonthSelector.propTypes = {
  classes: propTypes.object.isRequired,
  currentDate: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  backgroundColor: propTypes.oneOf([null, propTypes.string]),
}

export default withStyles(style)(MonthSelector)
