import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import ReportTitles from '../ReportTitles'
import ReportContext from '../ReportProvider'
import { TextField } from '../../../../../Shared/Fields/Text'

const style = () => ({
  inlineInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      width: '50%'
    },
    margin: '6px 0',
    '& > div:first-child > h2': {
      fontWeight: 600
    },
    width: '50%',
    minWidth: 450
  },
  container: {
    marginBottom: 48
  },
  inlineComplete: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      width: '75%'
    },
    '& > div:first-child': {
      width: '25%',
      minWidth: 225
    },
    margin: '6px 0',
    '& > div:first-child > h2': {
      fontWeight: 600,
    },
    minWidth: 450
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12
  }
})

const info = [
  { name: 'Tipo de Discapacidad', key: 'mental_health' },
  { name: 'Antecedentes Mórbidos', key: 'physical_health' },
  { name: 'Centro Asistencial', key: 'center_answer' },
  { name: 'Alergias', key: 'allergies' },
  { name: 'Medicamentes actuales', value: 'Remedio' },
]

class Medics extends Component {
  renderInfo() {
    const { classes, data } = this.props
    return info.map(element => {
      const value = element.value ? element.value : data[element.key]
      return (
        <div className={classes.inlineInfo} key={element.name}>
          <div>
            <Typography variant="h2">{element.name}</Typography>
          </div>
          <div>
            <Typography variant="h2">{value}</Typography>
          </div>
        </div>
      )
    })
  }

  render() {
    const { classes, answers = [], values } = this.props;
    const { handleChange, handleBlur } = this.context
    const answerId = answers[0]?.id
    const value = values[answerId]
    return (
      <div className={classes.container}>
        <ReportTitles title="Antecedentes Médicos" />
        <div className={classes.infoContainer}>
          {this.renderInfo()}
          <div className={classes.inlineComplete}>
            <div>
              <Typography variant="h2">Otro</Typography>
            </div>
            <div>
              <TextField complete={{ placeholder: 'Comentarios' }} value={value} onChange={handleChange(answerId)} onBlur={handleBlur(answerId)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Medics.propTypes = {
  classes: propTypes.object.isRequired,
  answers: propTypes.arrayOf(propTypes.object).isRequired,
  data: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
}

Medics.defaultProps = {
}

Medics.contextType = ReportContext

export default withStyles(style)(Medics)
