/* eslint-disable import/no-cycle */
import React, { Component } from 'react'
import { withStyles, MenuItem, Typography } from '@material-ui/core'
import MuiSelect from '@material-ui/core/Select'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    padding: 0,
  },
  select: {
    padding: '4px 0 6px 6px',
    color: 'black'
  },
})

class SelectEntity extends Component {
  renderOptions() {
    const { complete } = this.props
    const { options } = complete
    return options.map(option => (
      <MenuItem value={option.id.toString()} key={option.id}>
        {option.name}
      </MenuItem>
    ))
  }

  render() {
    const {
      classes,
      onChange,
      value,
      complete,
      disabled,
      highlighted, hideTitle
    } = this.props
    return (
      <>
        <div className={highlighted ? 'highlighted' : ''} style={{ gridArea: `area-${complete.id}` }}>
          {!hideTitle && <Typography>{complete.text}</Typography>}
          <MuiSelect
            value={value}
            onChange={onChange}
            name={complete.name}
            fullWidth
            variant="filled"
            classes={{ select: classes.select }}
            style={{ borderRadius: 0 }}
            disabled={disabled}
          >
            {this.renderOptions()}
          </MuiSelect>
        </div>
      </>
    )
  }
}

SelectEntity.propTypes = {
  classes: propTypes.object.isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.string,
  complete: propTypes.object.isRequired,
  disabled: propTypes.bool,
  hideTitle: propTypes.bool,
  highlighted: propTypes.bool
}

SelectEntity.defaultProps = {
  value: '',
  disabled: false,
  highlighted: false,
  hideTitle: false,
}

export default withStyles(style)(SelectEntity)
