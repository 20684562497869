import React, { Component } from 'react'
import { withStyles, Typography, Divider } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    padding: 12,
    textAlign: 'left',
  },
})

class Criterium extends Component {
  render() {
    const { classes, label } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h3">{label}</Typography>
        <Divider style={{ marginBottom: 0 }} />
      </div>
    )
  }
}

Criterium.propTypes = {
  classes: propTypes.object.isRequired,
  label: propTypes.string
}

Criterium.defaultProps = {
  label: ''
}

export default withStyles(style)(Criterium)
