import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core/'
import propTypes from 'prop-types'
import { connect } from 'react-redux'

const style = theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    alignItems: 'center',
    gridGap: 6,
    padding: '0 12px'
  },
  bigContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.2fr',
    alignItems: 'center',
    gridGap: 6,
    padding: '0 12px'
  },
  tab: {
    fontSize: 13,
    color: theme.palette.grey.dark,
  },
  image: {
    height: 32,
    width: 32,
    borderRadius: '50%'
  },
  little: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxWidth: 150,
    '& > *:first-child': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '65%',
      marginRight: 12
    }
  }
})

class Tabs extends Component {
  render() {
    const { classes, names, selected, handler, breadcrumbs, contenders } = this.props
    const allBC = breadcrumbs.all || []
    const innerView = allBC.length > 0
    const selectedContender = contenders?.selected?.user?.name
    return (
      <div className={innerView ? classes.bigContainer : classes.container}>
        {names.map((tabName, index) => (
          <Button variant="text" key={tabName} className={classes.button} onClick={handler(index)}>
            <Typography
              className={classes.tab}
              style={selected === index ? { fontWeight: 'bold' } : {}}
              display="inline"
            >
              {tabName}
            </Typography>
          </Button>
        ))}
        {innerView
        && (
          <div className={classes.little}>
            <Typography variant="subtitle1">{selectedContender}</Typography>
            <img src={contenders?.selected?.user?.photo || '/noimage.png'} alt="Logo" className={classes.image} />
          </div>
        )}
      </div>
    )
  }
}

Tabs.propTypes = {
  classes: propTypes.object.isRequired,
  names: propTypes.arrayOf(propTypes.string).isRequired,
  selected: propTypes.number.isRequired,
  handler: propTypes.func.isRequired,
  breadcrumbs: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired
}

const mapStateToProps = state => ({
  breadcrumbs: state.breadcrumbs,
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(Tabs))
