import createSnackbar from '../Utils/snackbars'
import instance from '../Utils/client'

const sendAttendanceAction = (body, snackbar) => {
  const REQUEST = instance({ url: `/events/${body.id}/attendance`, data: body, method: 'post' })
  createSnackbar(REQUEST, snackbar)
  return {
    type: 'SEND_ATTENDANCE',
    payload: REQUEST,
  }
}

const sendConfirmationAction = (body, snackbar, callback) => {
  const REQUEST = instance({
    url: `/events/${body.id}/confirm`,
    data: { confirmed: body.confirmed, event_confirmed: body.event_confirmed },
    method: 'post',
  }).then(response => {
    callback()
    return response
  })
  createSnackbar(REQUEST, snackbar)
  return {
    type: 'SEND_CONFIRMATION',
    payload: REQUEST,
  }
}

const assignOwnerAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `events/${body.event_id}/participants/${body.participant_id}/allocate?owner_user_id=${body.owner_user_id}`
  })
  return {
    type: 'LOAD_EVENT',
    payload: REQUEST
  }
}

const allocateExternalUserAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `events/${body.event_id}/participants/${body.participant_id}/allocate?owner_user_id=${body.owner_user_id}`
  })
  return {
    type: 'ALLOCATE_EXTERNAL_USER',
    payload: REQUEST
  }
}

export {
  sendAttendanceAction,
  sendConfirmationAction,
  assignOwnerAction,
  allocateExternalUserAction }
