import React, { Component } from 'react'
import actioncable from 'actioncable'
import propTypes from 'prop-types'
import { Paper, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { logoutAction } from '../../../../Actions/Login'
import autobind from '../../../../Utils/autobind'
import StepContainer from '../../../../Shared/StepContainer'
import FirstStepCompanion from './FirstStepCompanion'
import SecondStepCompanion from './SecondStepCompanion'
import Thanks from './Thanks'
import { sendCompanionInterviewAction } from '../../../../Actions/Events'

const style = () => ({
  container: {
    padding: 12,
    textAlign: 'start'
  },
  paper: {
    width: '60%',
    margin: 'auto',
    padding: 24,
    marginTop: '10%'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12
  },
  button: {
    textAlign: 'end'
  }

})

class CompanionInterview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 'I',
      data: {}
    }
    autobind(CompanionInterview, this)
  }

  componentDidMount() {
    const { logout } = this.props
    logout()
    this.cable = actioncable.createConsumer(`${process.env.REACT_APP_API_WS}/cable`)
    this.channel = this.cable.subscriptions.create(
      {
        channel: 'FamiliarinterviewChannel',
      },
      {
        connected: () => {
          console.log('connected!')
        },
        disconnected: () => {
          console.log('disconnected!')
        },
        received: data => {
          this.handleReceive(data)
        },
      }
    )
  }

  handleChangeView() {
    const { view } = this.state
    this.setState({ view: `${view}I` })
  }

  handleReceive(data) {
    this.setState({ data })
  }

  render() {
    const { view, data } = this.state
    const { classes, sendInterviewAnsers } = this.props
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <div className={classes.title}>
            <Typography variant="h2">Entrevista Familiar</Typography>
            <Typography variant="subtitle2">
              {`Parte ${view}`}
            </Typography>
          </div>
          <StepContainer>
            <FirstStepCompanion handleChangeView={this.handleChangeView} />
            <SecondStepCompanion
              handleChangeView={this.handleChangeView}
              data={data}
              sendAnswers={sendInterviewAnsers}
            />
            <Thanks />
          </StepContainer>
        </Paper>
      </div>
    )
  }
}

CompanionInterview.propTypes = {
  classes: propTypes.object.isRequired,
  logout: propTypes.func.isRequired,
  sendInterviewAnsers: propTypes.func.isRequired,
}

CompanionInterview.defaultProps = {

}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction()),
  sendInterviewAnsers: body => dispatch(sendCompanionInterviewAction(body)),

})

export default connect(null, mapDispatchToProps)(withStyles(style)(CompanionInterview))
