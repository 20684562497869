import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import propTypes from 'prop-types'
import { TextField } from '../../../Shared/Fields/Text'
import Select from '../../../Shared/Fields/Select'

const style = makeStyles(() => ({
  mainContainer: {
    width: 500
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    marginRight: 6
  },
  input: {
    margin: '12px 0'
  }
}))

const CreateDialog = ({ open, onClose, onSave, selectOptions, user }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [organization, setOrganization] = useState('')
  const buttonText = user === null ? 'Guardar' : 'Editar'
  const classes = style()

  const handleChange = setFunc => e => {
    setFunc(e.target.value)
  }

  const handleSelectChange = e => {
    setOrganization(e.target.value)
  }

  const handleSave = () => {
    onSave({
      name,
      email,
      password,
      organization_id: organization
    })
    onClose()
  }
  useEffect(() => {
    if (user) {
      setName(user.name)
      setEmail(user.email)
      setOrganization(user.organization_id)
    }
  }, [user])
  return (
    <Dialog open={open} PaperProps={{ className: classes.mainContainer }}>
      <DialogTitle>
        <div className={classes.titleContainer}>
          <NoteAddIcon className={classes.titleIcon} />
          Nuevo usuario de Redes
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.input}>
          <TextField
            onChange={handleChange(setName)}
            complete={{ placeholder: 'Nombre de coordinador', name: 'name', text: 'Nombre' }}
            value={name}
          />
        </div>
        <div className={classes.input}>
          <TextField
            onChange={handleChange(setEmail)}
            complete={{ placeholder: 'Mail', name: 'email', text: 'Email' }}
            value={email}
          />
        </div>
        <div>
          <Select
            onChange={handleSelectChange}
            complete={{
              options: selectOptions.map(o => ({ label: o.name, value: o.id })),
              placeholder: 'Organización',
              text: 'Organización'
            }}
            value={organization}
          />
        </div>
        {user === null && (
          <div className={classes.input}>
            <TextField
              onChange={handleChange(setPassword)}
              complete={{ placeholder: 'Contraseña', name: 'password', type: 'password', text: 'Contraseña' }}
              value={password}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}> Cancelar </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateDialog.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
  selectOptions: propTypes.arrayOf(propTypes.object).isRequired,
  user: propTypes.object
}

CreateDialog.defaultProps = {
  user: null
}

export default CreateDialog
