import React, { Component } from 'react'
import { withStyles, Typography, Paper, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import moment from 'moment'
import { AccountBox, Home } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { addSubgroupAction, deleteSubgroupAction, sendAnswersAction, allocateEvaluationAction } from '../../Actions/Events'
import FlexibleInput from '../../Utils/FieldRenderer'
import { changeCompetencyEvaluationStateAction, getCompetencyEvaluationAction, populateAnswerGroupsAction } from '../../Actions/Competency'
import SetViewContext from '../Contenders/Profile/SetViewProvider'
import './animations.css'
import { callSnackbar } from '../../Utils/snackbars'
import WarningDialog from './WarningDialog'
import RestrictionDialog from './RestrictionDialog'
import NextStepDialog from './NextStepDialog'
import LoaderDialog from '../../Shared/LoaderDialog'
import GlobalComment from '../../Shared/GlobalComment'
import CSDTooltipContainer from './CSDTooltipContainer'
import CSDTooltips from './Tooltips'

const style = theme => ({
  container: {
    padding: 12,
    textAlign: 'left'
  },
  question: {
    textAlign: 'left',
    '& > *': {
      margin: '24px 0'
    },
    '& > div > h6,p': {
      fontWeight: 400,
      fontSize: 14
    }
  },
  group: {
    background: theme.palette.indigo.lighter,
    padding: 12,
    borderRadius: 8,
    animation: 'fadein 0.5s ease-in-out both'
  },
  submitButton: {
    marginTop: 24,
    textAlign: 'end'
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sectionTitleText: {
    padding: '6px 0',
    marginBottom: 12,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    flexGrow: 1,
  },
  section: {
    margin: '24px 0'
  },
  deleteButton: {
    textAlign: 'end'
  },
  member: {
    fontSize: 14,
    fontWeight: 600,
    color: '#1a1a1a'
  },
  memberCards: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  },
  memberCard: {
    border: '1px solid #1a1a1a',
    padding: 12,
    margin: 12,
    borderRadius: 8,
    minWidth: 300,
    '& > *:first-child': {
      fontSize: 13
    }
  },
  memberInfo: {
    marginTop: 12,
  },
  infoLine: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *:nth-child(2)': {
      fontWeight: 400
    }
  },
  cardTitle: {
    margin: '12px 0 24px 0',
  },
  cardInnerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

class CSDEvaluation extends Component {
  constructor() {
    super()
    this.state = {
      page: 0,
      params: {},
      groups: {},
      data: null,
      nameOptions: [],
      openWarning: null,
      openRestriction: false,
      openNextSetp: null,
      loading: false
    }

    this.familyMembersQuestions = []

    autobind(CSDEvaluation, this)
  }

  componentDidMount() {
    const { competency } = this.props
    const form = competency.selected_form
    this.setState({ data: form, loading: false }, this.setupAnswers)
  }

  handleOpenWarning(values) {
    return () => {
      this.setState({ openWarning: values })
    }
  }

  handleOpenNextStep(values) {
    return () => {
      this.setState({ openNextSetp: values })
    }
  }

  handleOpenRestriction() {
    const { openRestriction } = this.state
    this.setState({ openRestriction: !openRestriction })
  }

  handleChangePage(value) {
    return () => {
      const { populateAnswerGroup, getCompetencyEvaluation, event, evaluation } = this.props
      const { data } = this.state
      const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
      const answer = question?.answers[0]?.id
      const body = {
        answer_id: answer
      }
      const self = this

      function nextStep() {
        self.handleSendPage1(answer).then(() => {
          const evaluationBody = {
            form_id: event.form_id,
            evaluation_id: evaluation
          }
          getCompetencyEvaluation(evaluationBody).then(() => self.componentDidMount())
        })
        populateAnswerGroup(body)
        self.setState({ page: value })
        self.handleOpenNextStep(null)()
      }

      if (value === 1) {
        // Once the form for family members is completed
        this.handleOpenNextStep(nextStep)()
        return true
      }

      this.setState({ page: value })
      return true
    }
  }

  handleAddGroup(SubquestionGroupId, answerId) {
    return () => {
      const { addSubgroup, getCompetencyEvaluation, event, evaluation } = this.props
      const body = {
        answer_id: answerId,
        subquestion_group_id: SubquestionGroupId
      }

      return addSubgroup(body).then(() => {
        const evaluationBody = {
          form_id: event.form_id,
          evaluation_id: evaluation
        }
        getCompetencyEvaluation(evaluationBody).then(() => this.componentDidMount())
      })
    }
  }

  handleAddGroupPage1(SubquestionGroupId, answerId) {
    return () => {
      const { data } = this.state
      const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
      const answer = question?.answers[0]?.id
      this.handleSendPage1(answer).then(async () => {
        this.setState({ loading: true })
        await this.handleAddGroup(SubquestionGroupId, answerId)()
        this.setState({ loading: false })
      })
    }
  }

  handleAddGroupPage2(SubquestionGroupId, answerId) {
    return () => {
      this.handleSendPage2(false).then(async () => {
        this.setState({ loading: true })
        await this.handleAddGroup(SubquestionGroupId, answerId)()
        this.setState({ loading: false })
      })
    }
  }

  handleRemoveGroup(SubquestionGroupId, answerId) {
    return () => {
      const { deleteSubgroup, event, evaluation, getCompetencyEvaluation, competency } = this.props
      const { page, data } = this.state

      const form = competency.selected_form

      const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
      const savedQuestion = form['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]

      const answer = question?.answers[0]?.id
      const savedGroups = savedQuestion?.answers[0]?.body?.groups
      const savedGroupIds = Object.keys(savedGroups).map(groupName => groupName.split('#')[1])

      const self = this

      function deleteGroup() {
        self.setState({ loading: true })
        self.handleSendPage1(answer).then(() => {
          self.handleOpenWarning(null)()
          deleteSubgroup({
            answer_id: answerId,
            answer_has_group_id: SubquestionGroupId,
          })
            .then(async () => {
              const evaluationBody = {
                form_id: event.form_id,
                evaluation_id: evaluation
              }
              await getCompetencyEvaluation(evaluationBody).then(() => self.componentDidMount())
            })
        })
      }

      function deleteGroup2() {
        self.setState({ loading: true })
        self.handleSendPage2(false).then(() => {
          self.handleOpenWarning(null)()
          deleteSubgroup({
            answer_id: answerId,
            answer_has_group_id: SubquestionGroupId,
          })
            .then(() => {
              const evaluationBody = {
                form_id: event.form_id,
                evaluation_id: evaluation
              }
              getCompetencyEvaluation(evaluationBody).then(() => self.componentDidMount())
            })
        })
      }

      if (page === 0) {
        if (savedGroupIds.includes(SubquestionGroupId)) {
          this.handleOpenRestriction()
        } else {
          this.handleOpenWarning(deleteGroup)()
        }
        return true
      }
      this.handleOpenWarning(deleteGroup2)()
      return true
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = { ...params[target.name], answer: target.value }
    this.setState({ params })
  }

  handleSubChange(event) {
    const { target } = event
    const { params } = this.state
    const mainQuestionId = target.name.split('&')[0]
    const { subquestions } = params[mainQuestionId]
    params[mainQuestionId] = {
      ...params[mainQuestionId],
      subquestions: { ...subquestions, [target.name]: target.value }
    }
    this.setState({ params })
  }

  handleChangeGroup(event) {
    const { target } = event
    const { groups, data, page } = this.state

    // Restrict "Jefe del Hogar"
    const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]

    if (question) {
      const { id } = question.answers[0]

      // Check if answered before

      const keyToDelete = Object.keys(groups).find(groupKey => {
        const nameElements = groupKey.split('#')
        const answerId = nameElements[0]
        const questionIndex = nameElements[2]

        return (answerId === id && questionIndex === '2')
      })

      if (target.value === '0' && target?.name?.split('#')[2] === '2') {
        delete groups[keyToDelete]
      }
    }

    // restriction for family members on selects
    // Only applies on page 2
    if (page === 1) {
      const nameElements = target.name.split('#')
      const answerId = nameElements[0]
      const questionIndex = nameElements[2]
      const indexesOfAnswerInGroups = Object.keys(groups).map((groupName, index) => {
        const groupElements = groupName.split('#')
        if (answerId === groupElements[0] && questionIndex === groupElements[2]) return index
        return ''
      }).filter(String)

      // Means that the same question or a similar inside the group has been answered
      let isRepeated = false
      indexesOfAnswerInGroups.forEach(indexOfAnswerInGroups => {
        if (indexOfAnswerInGroups !== -1) {
          // Check if the answer is the same as the other
          // In that case we restrict it and send a warning
          const answer = Object.values(groups)[indexOfAnswerInGroups]?.answer
          if (answer === target.value) {
            isRepeated = true
            return isRepeated
          }
        }
        return false
      })

      // If the answer for family member is the same
      // and the question correspond to a family member type
      if (isRepeated && this.familyMembersQuestions.includes(target.name)) {
        return callSnackbar('Debe escoger otro miembro del hogar', 'warning')
      }
    }

    groups[target.name] = { ...groups[target.name], answer: target.value }
    return this.setState({ groups })
  }

  handleGroupSubChange(event) {
    const { target } = event
    const { groups } = this.state
    const mainQuestionId = target.name.split('&')[0]
    const { subquestions } = groups[mainQuestionId]
    groups[mainQuestionId] = {
      ...groups[mainQuestionId],
      subquestions: { ...subquestions, [target.name]: target.value }
    }
    this.setState({ groups })
  }

  handleSendPage1(key) {
    const { groups } = this.state
    const { sendAnswers, event } = this.props

    const body = [{
      id: key,
      body: { answer: null, groups },
      comment: '',
    }]
    const id = event.event_id
    return sendAnswers({ answers: body }, id)
  }

  handleSendPage2(end = true) {
    const { groups, params } = this.state
    const { sendAnswers,
      event,
      changeCompetencyEvaluationState,
      evaluation,
      allocateEvaluation,
      endFunction } = this.props
    const { setView } = this.context

    const groupByGroups = {}
    Object.entries(groups).forEach(([groupAnswerName, answer]) => {
      const groupAnswerId = groupAnswerName.split('#')[0]
      if (!groupByGroups[groupAnswerId]) {
        groupByGroups[groupAnswerId] = {}
      }
      groupByGroups[groupAnswerId][groupAnswerName] = answer
    })

    const allKeysWithAnswers = [...Object.keys(params), ...Object.keys(groupByGroups)]

    const body = allKeysWithAnswers.map(answerId => ({
      id: answerId,
      body: { ...params[answerId], groups: groupByGroups[answerId] },
      comment: ''
    }))

    const id = event.event_id
    return sendAnswers({ answers: body }, id).then(() => {
      if (end) {
        const statusBody = {
          evaluation_id: evaluation,
          status: 'check'
        }
        allocateEvaluation({ evaluation_id: evaluation })
        changeCompetencyEvaluationState(statusBody)
        if (endFunction) { return endFunction() }
        setView()
      }
      return null
    })
  }

  setupAnswers() {
    const { page, data } = this.state
    if (page === 0) {
      // Setup answers for family members

      const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
      const answer = question?.answers[0]

      if (!answer) return null
      if (!answer?.body) return null

      const actualGroups = answer?.subquestion_groups || []
      const { groups } = answer?.body
      const actualIds = actualGroups.map(ag => ag.id)
      const filteredGroupsAsArray = Object.entries(groups).filter(([groupName]) => actualIds.includes(groupName.split('#')[1]))
      const filteredGroups = Object.fromEntries(filteredGroupsAsArray)
      this.setState({ groups: filteredGroups })
    } else {
      // Setup family members as options

      const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
      const answer = question?.answers[0]

      if (!answer) return null
      if (!answer?.body) return null

      const actualGroups = answer?.subquestion_groups
      const { groups } = answer?.body
      const actualIds = actualGroups.map(ag => ag.id)
      const filteredGroupsAsArray = Object.entries(groups).filter(([groupName]) => actualIds.includes(groupName.split('#')[1]))
      const filteredGroups = Object.fromEntries(filteredGroupsAsArray)
      const keys = Object.keys(filteredGroups).map(key => key.replace(/#/, '&').split('&')[1])
      const indexes = []

      keys.forEach((key, index) => {
        if (key.split('#')[1] !== '0') return null
        indexes.push(index)
        return true
      })
      const names = Object.values(filteredGroups)
        .filter((value, index) => indexes.includes(index))
        .map(ob => ob.answer)
        .filter(v => !!v)

      // Setup answers for all form

      const sections = data['Caracterización socio-demográfica']['Caracterización Socio-demográfica']

      if (!sections) return null

      const sectionQuestions = Object.values(sections)

      let allGroups = {}
      const allParams = {}

      sectionQuestions.forEach(questions => {
        questions.forEach(q => {
          const answerBody = q.answers[0]
          if (answerBody.body === null) return null
          const answerForParams = answerBody.body
          const answerForGroups = answerBody.body.groups
          const answerId = answerBody.id
          allParams[answerId] = answerForParams
          allGroups = { ...allGroups, ...answerForGroups }
          return true
        })
      })

      this.setState({ nameOptions: names, params: allParams, groups: allGroups })
    }
    return null
  }

  Page1() {
    const { classes } = this.props
    const { data, page } = this.state

    if (page !== 0) return null
    if (!data) return null

    const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]

    if (!question) return null

    return (
      <div className={classes.question}>
        <div className={classes.sectionTitle}>
          <Typography variant="h2" className={classes.sectionTitleText}>Miembros del hogar</Typography>
          <CSDTooltipContainer tooltip={CSDTooltips.Base} />
        </div>
        <Typography variant="h2">
          {question.text}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={
            this.handleAddGroupPage1(
              question?.group_actions[0]?.subquestion_group_id,
              question?.answers[0]?.id
            )
          }
        >
          {question?.group_actions[0]?.add_text}
        </Button>
        {this.renderGroups()}
        <div className={classes.submitButton}>
          <Button color="secondary" variant="contained" onClick={this.handleChangePage(1)}>
            Continuar a parte 2
          </Button>
        </div>
      </div>

    )
  }

  Page2() {
    const { classes, event, evaluation } = this.props
    const { page, data, params } = this.state

    if (page !== 1) return null
    if (!data) return null

    const sections = data['Caracterización socio-demográfica']['Caracterización Socio-demográfica']

    if (!sections) return null

    const sectionNames = Object.keys(sections)
    const sectionQuestions = Object.values(sections)

    const sectionRender = sectionNames.map((name, index) => {
      const tooltips = CSDTooltips[name]
      const questions = sectionQuestions[index]
      const questionRender = questions.map(question => {
        const { id } = question.answers[0]
        const info = { ...question, name: id }
        return (
          <div className={classes.question}>
            <FlexibleInput
              complete={info}
              value={params[id]?.answer}
              subquestions={params[id]?.subquestions}
              onChange={this.handleChange}
              onSubchange={this.handleSubChange}
            />
            {this.renderGroups(question)}
          </div>
        )
      })

      return (
        <div className={classes.section}>
          <div className={classes.sectionTitle}>
            <Typography variant="h2" className={classes.sectionTitleText}>{name}</Typography>
            {tooltips && <CSDTooltipContainer tooltip={tooltips} />}
          </div>
          {questionRender}
        </div>
      )
    })

    return (
      <div>
        {this.renderCards()}
        {sectionRender}
        <GlobalComment event={event} evaluation={evaluation} />
        <div className={classes.submitButton}>
          <Button color="secondary" variant="contained" onClick={this.handleSendPage2}>
            Terminar
          </Button>
        </div>
      </div>
    )
  }

  renderCards() {
    const { data } = this.state
    const { classes } = this.props

    const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
    const answer = question?.answers[0]

    if (!answer) return null
    if (!answer.body) return null

    const groupQuestions = answer.subquestion_groups[0].question // questions and type for members

    const actualGroups = answer?.subquestion_groups
    const { groups } = answer?.body
    const actualIds = actualGroups.map(ag => ag.id)
    const filteredGroupsAsArray = Object.entries(groups).filter(([groupName]) => actualIds.includes(groupName.split('#')[1]))
    const filteredGroups = Object.fromEntries(filteredGroupsAsArray)
    const keys = Object.keys(filteredGroups)
    const keysStartingFromGroups = Object.keys(filteredGroups).map(key => key.replace(/#/, '&').split('&')[1])

    const member = [
      'name',
      'relation',
      'jefe',
      'gender',
      'birth',
    ]

    const members = {}

    // Group members by group_id
    keysStartingFromGroups.forEach((key, index) => {
      const keyInfo = key.split('#')
      const answerKey = keys[index]
      const groupId = keyInfo[0]
      const questionIndex = keyInfo[1]
      const questionObject = groupQuestions[questionIndex]
      let memberAnswer = filteredGroups[answerKey].answer
      if (questionObject.type === 'select') {
        const { options } = questionObject
        memberAnswer = options[parseInt(memberAnswer, 10)]
      }
      members[groupId] = { ...members[groupId], [member[questionIndex]]: memberAnswer }
    })

    // keys.forEach(key => {
    //   const memberIndexValue = key.split('#')[2]
    //   const memberAnswer = filteredGroups[key].answer
    //   const newMember = {}
    //   newMember[member[memberIndexValue]] = memberAnswer
    //   members.push(newMember)
    // })
    const membersArray = Object.values(members)
    const memberRender = membersArray.map((processedMember, index) => {
      const isBoss = processedMember.jefe === '0'
      const isUser = processedMember.relation === 'Usuario'
      let Icon = false
      if (isBoss) Icon = Home
      if (isUser) Icon = AccountBox
      return (
        <div className={classes.memberCard} key={`${index * 2}`}>
          <div className={classes.cardInnerTitle}>
            <div>
              <Typography variant="subtitle1">{`Miembro del hogar N°${index + 1}`}</Typography>
              {isBoss && <Typography variant="caption">Jefe del hogar</Typography>}
            </div>
            {Icon && <Icon color="primary" />}
          </div>
          <div className={classes.memberInfo}>
            <div className={classes.infoLine}>
              <Typography variant="subtitle1">Nombre:</Typography>
              <Typography variant="subtitle1">{processedMember.name || 'No especifica'}</Typography>
            </div>
            <div className={classes.infoLine}>
              <Typography variant="subtitle1">Relación:</Typography>
              <Typography variant="subtitle1">{processedMember.relation || 'No especifica'}</Typography>
            </div>
            <div className={classes.infoLine}>
              <Typography variant="subtitle1">Género:</Typography>
              <Typography variant="subtitle1">{processedMember.gender || 'No especifica'}</Typography>
            </div>
            <div className={classes.infoLine}>
              <Typography variant="subtitle1">Edad:</Typography>
              <Typography variant="subtitle1">{processedMember.birth ? `${moment(new Date()).diff(processedMember.birth, 'years')} Años` : 'No especifica'}</Typography>
            </div>
            <div className={classes.infoLine}>
              <Typography variant="subtitle1">Fecha de Nacimiento:</Typography>
              <Typography variant="subtitle1">{processedMember.birth || 'No especifica'}</Typography>
            </div>
          </div>
        </div>
      )
    })
    return (
      <>
        <div className={classes.cardTitle}>
          <Typography variant="h3">{`N° de integrantes del hogar: ${membersArray.length}`}</Typography>
        </div>
        <div className={classes.memberCards}>
          {memberRender}
        </div>
      </>
    )
  }

  renderGroupQuestion(questions, name) {
    if (!questions) return null

    const { groups, nameOptions } = this.state
    return questions.map((question, index) => {
      const info = { ...question, name: `${name}#${index}` }

      // Add family members as options to special selects

      if (question.type === 'select' && question.options === 'family-members') {
        if (!this.familyMembersQuestions.includes(`${name}#${index}`)) this.familyMembersQuestions.push(`${name}#${index}`)
        info.options = nameOptions
      }
      return (
        <FlexibleInput
          key={`${name}#${index * 2}`}
          complete={info}
          onChange={this.handleChangeGroup}
          onSubchange={this.handleGroupSubChange}
          value={groups[`${name}#${index}`]?.answer}
          subquestions={groups[`${name}#${index}`]?.subquestions}
        />
      )
    })
  }

  renderGroups(forcedQuestion = false) {
    const { classes } = this.props
    const { data, nameOptions } = this.state
    const question = forcedQuestion || data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
    if (!question) return null

    const groups = question.answers[0].subquestion_groups || []
    const { id } = question.answers[0]
    const actions = question?.group_actions
    const showButton = actions && actions[0].add_text && groups.length < nameOptions.length

    const groupsRenderer = groups.map((group, index) => {
      const { question: questions } = group
      return (
        <React.Fragment key={`${id}#${group.id}`}>
          <div className={classes.group}>
            {actions && actions[0].add_text && (
              <div className={classes.deleteButton}>
                <Button onClick={this.handleRemoveGroup(group.id, id)} size="small">
                  Eliminar
                </Button>
              </div>
            )}
            {actions && !actions[0].add_text && <Typography variant="caption" className={classes.member}>{`${nameOptions[index]}`}</Typography>}
            {this.renderGroupQuestion(questions, `${id}#${group.id}`)}
          </div>
        </React.Fragment>
      )
    })
    return (
      <>
        {groupsRenderer}
        {showButton && (
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleAddGroupPage2(actions[0].subquestion_group_id, id)}
          >
            {actions[0].add_text}
          </Button>
        )}
      </>
    )
  }

  render() {
    const { classes } = this.props
    const { openWarning, openRestriction, openNextSetp, loading } = this.state
    return (
      <Paper className={classes.container}>
        {this.Page1()}
        {this.Page2()}
        <WarningDialog
          open={openWarning !== null}
          onClose={this.handleOpenWarning(null)}
          action={openWarning}
        />
        <RestrictionDialog
          open={openRestriction}
          onClose={this.handleOpenRestriction}
        />
        <NextStepDialog
          open={openNextSetp !== null}
          onClose={this.handleOpenNextStep(null)}
          action={openNextSetp}
        />
        <LoaderDialog open={loading} />
      </Paper>
    );
  }
}

CSDEvaluation.contextType = SetViewContext

CSDEvaluation.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  addSubgroup: propTypes.func.isRequired,
  sendAnswers: propTypes.func.isRequired,
  populateAnswerGroup: propTypes.func.isRequired,
  competency: propTypes.object.isRequired,
  getCompetencyEvaluation: propTypes.func.isRequired,
  deleteSubgroup: propTypes.func.isRequired,
  evaluation: propTypes.string.isRequired,
  endFunction: propTypes.func,
  changeCompetencyEvaluationState: propTypes.func.isRequired,
  allocateEvaluation: propTypes.func.isRequired
}

CSDEvaluation.defaultProps = {
  endFunction: null
}

const mapStateToProps = state => ({
  competency: state.competency
})

const mapDispatchToProps = dispatch => ({
  addSubgroup: body => dispatch(addSubgroupAction(body)),
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id)),
  populateAnswerGroup: body => dispatch(populateAnswerGroupsAction(body)),
  getCompetencyEvaluation: body => dispatch(getCompetencyEvaluationAction(body)),
  deleteSubgroup: body => dispatch(deleteSubgroupAction(body)),
  changeCompetencyEvaluationState: body => dispatch(changeCompetencyEvaluationStateAction(body)),
  allocateEvaluation: body => dispatch(allocateEvaluationAction(body))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(CSDEvaluation))
)
