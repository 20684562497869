import html2canvas from 'html2canvas'
import moment from 'moment'

const trim = s => (s || '').replace(/^\s+|\s+$/g, '')

const splitPhrase = charLimit => phrase => {
  let acc = ''
  const splitted = phrase.split(' ')
  const response = []
  splitted.forEach(word => {
    const partial = acc + word
    if (partial.length > charLimit) {
      response.push(trim(acc))
      acc = `${word} `
    } else {
      acc += `${word} `
    }
  })
  if (acc && !response.includes(acc)) {
    response.push(trim(acc))
  }
  if (response.length > 4) {
    const slice = response.slice(0, 4)
    const lastWord = slice[slice.length - 1]
    slice[slice.length - 1] = `${lastWord}...`
    return slice
  }
  return response.slice(0, 4)
}

const getUrlWithQueryString = (baseUrl, params) => {
  let queryString = ''
  if (params) {
    queryString = Object.entries(params).reduce((prev, curr) => {
      const [key, value] = curr
      return `${prev}&${key}=${value}`
    }, '')
  }
  queryString = queryString.substring(1)
  return `${baseUrl}?${queryString}`
}

// TODO: Refactor to use only dateFilter
const getFormattedHistories = (histories, currentDate) => {
  const currentDay = currentDate.startOf('month')
  const toReturn = []
  for (let i = 1; i < moment(currentDate).daysInMonth() + 1; i += 1) {
    const filtered = histories
      .filter(history => moment(history.date).isSame(currentDay, 'day'))
      .map(el => {
        const { id, body, date, user } = el
        const dateString = moment(date).format('DD [de] MMMM')
        return { id, date: dateString, body, user, realDate: date }
      })
    toReturn.push(filtered)
    currentDay.add(1, 'days')
  }
  return toReturn
}

const getContenderEvents = (events, currentDate) => {
  const currentWeekStart = moment(currentDate).startOf('isoWeek')
  const formattedEvents = []
  for (let i = 0; i < 7; i += 1) {
    const filteredEvents = events
      .filter(event => moment(event.event.date).isSame(currentWeekStart, 'day'))
      .map(el => {
        const { event } = el
        const dateString = moment(event.date).format('DD [de] MMMM')
        return { id: event.id,
          date: dateString,
          body: event.title,
          realDate: event.date,
          initHour: event.module
        }
      })
    formattedEvents.push(filteredEvents)
    currentWeekStart.add({ days: 1 })
  }
  return formattedEvents
}

const isBetween = (start, end, target) => moment(target).isBetween(start, end, 'day', '[]')
const dateFilter = (startDate, endDate, { data, key, func }) => {
  if (func) {
    return data.filter(el => isBetween(startDate, endDate, el[key])).map(el => func(el))
  }
  return data.filter(el => isBetween(startDate, endDate, el[key]))
}

const getEventColor = type => {
  if (type === 'Jornada Presentación') {
    return '#1f8095'
  }
  if (type === 'Reunión') {
    return '#FFB300'
  }
  if (type === 'Entrevistas') {
    return '#48A4B0'
  }
  if (type === 'Otro') {
    return '#dce2f2'
  }
  if (type === 'Seguimiento') {
    return '#fb8368'
  }
  if (type === 'Evaluación Funcional') {
    return '#27676a'
  }

  return '#d5602c'
}

const completeRow = (colCount, line, lineHasComment) => {
  for (let i = 0; i < 6 - colCount; i += 1) {
    line.push('.')
  }
  for (let j = 0; j < 6; j += 1) {
    if (lineHasComment) {
      line.push(lineHasComment)
    } else {
      line.push('.')
    }
  }
}

const parseQuestions = (answers, bigScreen) => {
  const areas = []
  if (bigScreen) {
    let line = []
    let lineHasComment = false
    let colCount = 0
    answers.forEach(answer => {
      const colsAmount = answer.cols > 6 ? 6 : answer.cols
      for (let i = 0; i < colsAmount; i += 1) {
        line.push(`area-${answer.id}`)
        colCount += 1
      }
      if (answer.has_comment) {
        lineHasComment = `comment-${answer.id}`
      }
      if (answer.eol) {
        completeRow(colCount, line, lineHasComment)
        colCount = 0
        areas.push(line.join(' '))
        line = []
        lineHasComment = false
      }
    })
  } else {
    let line = []
    let lineHasComment = false
    answers.forEach(answer => {
      // First row with question
      for (let i = 0; i < 12; i += 1) {
        line.push(`area-${answer.id}`)
      }
      areas.push(line.join(' '))
      line = []
      // Second row with comment the question has
      if (answer.has_comment) {
        lineHasComment = `comment-${answer.id}`
        for (let x = 0; x < 12; x += 1) {
          line.push(lineHasComment)
        }
        areas.push(line.join(' '))
        line = []
        lineHasComment = false
      }
    })
  }
  return areas.join('" "')
}
const relations = ['Madre', 'Padre', 'Yerno o Nuera', 'Hermano(a)', 'Cuñado(a)', 'Sobrino(a)', 'Hijo(a)', 'Suegro(a)', 'Abuelo(a)', 'Nieto(a)', 'Bisabuelo(a)', 'Tío(a)', 'Tutor(a)']

const parseContactInfo = (answerObj, type, answerId) => {
  if (type === 'relation') {
    const { subquestions } = answerObj
    const [, relationIndex] = subquestions[`${answerId}&0`].split('&')
    return relations[relationIndex]
  }
  if (answerObj) {
    return answerObj.answer
  }
  return null
}

const formatDate = date => moment(date).format('DD/MM/YYYY')

const compareValuesByKey = (orderBy, orderOn) => {
  const order = orderOn === 'desc' ? 1 : -1
  return function sort(a, b) {
    let result = 0
    if (a[orderBy] < b[orderBy]) {
      result = -1;
    }
    if (a[orderBy] > b[orderBy]) {
      result = 1;
    }
    return result * order;
  }
}

const moduleChecker = {
  0: hour => hour === '08' || hour === '8',
  1: hour => hour === '9' || hour === '09',
  2: hour => hour === '10',
  3: hour => hour === '11',
  4: hour => hour === '12',
  5: hour => hour === '13' || hour === '14',
  6: hour => hour === '15',
  7: hour => hour === '16',
  8: hour => hour === '17',
  9: hour => hour === '18',
  10: hour => hour === '19',
  11: hour => hour === '20',
  12: hour => Number(hour) > 20 || Number(hour) < 8
}

const getModuleFromHour = module => {
  if (!module) { return 0 }
  const hour = module.split(':')[0]
  const [mod] = Object.entries(moduleChecker).filter(modObj => modObj[1](hour))
  return mod[0]
}

const getCronogramEvents = events => {
  const cronogramEvents = events.map(event => {
    const end = getModuleFromHour(event.event.end_module)
    let start = getModuleFromHour(event.event.module)
    if (end !== '12' && start === '12') {
      start = 0
    }
    return { id: event.event.id,
      title: event.event.title,
      start,
      end,
      date: event.event.date,
      type: event.event_type_name,
      font: event.event_type_name === 'Otro' ? 'black' : 'white',
      full: event.event }
  })
  return {
    events: cronogramEvents
  }
}

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

const getCronogramObject = data => {
  const dayNames = {
    Lunes: [],
    Martes: [],
    Miércoles: [],
    Jueves: [],
    Viernes: [],
  }
  let extend = false
  data.forEach(event => {
    if (event.start > 6 || event.end > 6) {
      extend = true
    }
    const dayName = capitalize(moment(event.date).format('dddd'))
    dayNames[dayName].push(event)
  })
  return { events: Object.entries(dayNames).map(([name, events]) => ({ name, events })), extend }
}

function convertToRoman(num) {
  const roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  let str = '';
  let newNum = num
  Object.keys(roman).forEach(i => {
    const q = Math.floor(newNum / roman[i]);
    newNum -= q * roman[i];
    str += i.repeat(q);
  })

  return str;
}

function transformToOptions(list) {
  return list.map(element => ({ label: element.name, value: element.id }))
}

const getAllowedRolesFromRole = role => {
  switch (role) {
    case 'handler': {
      return ['handler', 'director', 'admin']
    }
    case 'director': {
      return ['director', 'admin']
    }
    default: {
      return ['admin']
    }
  }
}

const grantPermission = (user, allowedRoles) => {
  if (allowedRoles.includes(user.role) || user.role === 'admin') { return true }
  return false
}

const downloadElement = (elementId, name) => {
  const element = document.getElementById(elementId)
  html2canvas(element, { scale: 1.1 }).then(canvas => {
    const base64 = canvas?.toDataURL('image/jpg')
    const a = document.createElement('a')
    a.href = base64
    a.download = `${name}`
    a.click()
  });
}

const firstUpperCase = word => word.charAt(0).toUpperCase() + word.slice(1)

export default parseQuestions
export {
  splitPhrase,
  getEventColor,
  getFormattedHistories, getContenderEvents, parseContactInfo, formatDate, compareValuesByKey,
  getCronogramEvents, dateFilter, getCronogramObject, getUrlWithQueryString, getModuleFromHour,
  convertToRoman, transformToOptions, grantPermission, getAllowedRolesFromRole, downloadElement,
  firstUpperCase
}
