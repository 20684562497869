import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Typography, withStyles, withTheme } from '@material-ui/core'
import { CheckCircle, ErrorOutline } from '@material-ui/icons'
import Conditional from '../../../Shared/Conditional'

const style = theme => ({
  container: {
    padding: 24,
    borderRadius: 5,
    flexGrow: 1,
    marginBottom: 24,
    background: theme.palette.indigo.lighter
  },
  icon: {
    height: 16,
    width: 16,
    color: theme.palette.grey.main
  }
})

function checkIfHasNumber(question) {
  const firstLetter = question[0]
  const n = parseInt(firstLetter, 10)
  return !Number.isNaN(n)
}

function formatQuestion(index, question) {
  let result = question
  let lastNumber = 0
  while (checkIfHasNumber(result)) {
    lastNumber += 1
    result = Array.from(question).slice(lastNumber).join('')
  }
  return `${index} - ${result.replace('.', '').replace('-', '')}`
}

class Questions extends Component {
  render() {
    const { observations, classes, withObservation, theme } = this.props
    const errorStyle = withObservation ? {} : { color: theme.palette.error.main }
    const successStyle = withObservation ? {} : { color: theme.palette.confirm.main }
    const obs = withObservation
    return (
      <div className={classes.container} style={{ paddingTop: obs ? 0 : 24 }}>
        {observations.map((observation, index) => (
          <div style={{ marginLeft: 12, paddingTop: obs ? 24 : 0 }} key={observation.text}>
            <div key={observation.text} style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
              {observation.question_check
                ? <CheckCircle className={classes.icon} style={successStyle} />
                : <ErrorOutline className={classes.icon} style={errorStyle} />}
              <Typography variant="subtitle1" style={{ marginLeft: 6 }}>{formatQuestion(index + 1, observation.text)}</Typography>
            </div>
            <Conditional condition={withObservation}>
              <Typography variant="body1">
                {observation.answers.find(o => !o.companion) ? observation.answers.find(o => !o.companion).comment || 'No hay observación... (No hay comentario)' : 'No hay observación... (No hay respuesta)'}
                {' '}
              </Typography>
            </Conditional>
          </div>
        ))}
      </div>
    )
  }
}

Questions.propTypes = {
  observations: propTypes.arrayOf(propTypes.object).isRequired,
  classes: propTypes.object.isRequired,
  withObservation: propTypes.bool,
  theme: propTypes.object.isRequired
}

Questions.defaultProps = {
  withObservation: false
}

export default withTheme(withStyles(style)(Questions))
