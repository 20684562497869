import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import ReportTitles from '../ReportTitles'
import ReportContext from '../ReportProvider'
import { MultilineTextField } from '../../../../../Shared/Fields/Text'

const style = theme => ({
  inputs: {
    padding: 12,
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    margin: '6px 0'
  },
  title: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    marginBottom: 3
  },
  container: {
    marginBottom: 48
  }
})

class OtherDeterminations extends Component {
  renderInputs() {
    const { classes, answers, values } = this.props
    const { handleChange, handleBlur } = this.context
    return answers.map(answer => (
      <div className={classes.input} key={answer.id}>
        <Typography variant="h2" className={classes.title}>{answer.question_text}</Typography>
        <MultilineTextField
          complete={{ placeholder: 'Observaciones', rows: 2 }}
          value={values[answer.id]}
          onChange={handleChange(answer.id)}
          onBlur={handleBlur(answer.id)}
        />
      </div>
    ))
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <ReportTitles title="Otras Determinaciones" />
        <div className={classes.inputs}>
          {this.renderInputs()}
        </div>
      </div>
    );
  }
}

OtherDeterminations.propTypes = {
  classes: propTypes.object.isRequired,
  answers: propTypes.arrayOf(propTypes.object),
  values: propTypes.object.isRequired
}

OtherDeterminations.defaultProps = {
  answers: null
}

OtherDeterminations.contextType = ReportContext

export default withStyles(style)(OtherDeterminations)
