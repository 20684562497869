/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'
import { Checkbox, Typography, withStyles } from '@material-ui/core'
import { CheckCircle, ErrorOutline } from '@material-ui/icons'
import propTypes from 'prop-types'
import Conditional from '../../../Shared/Conditional'
import Questions from './Questions'
import Charts from './Charts'
// import Tags from '../Tags'

function renderGroups(groups, fullWidth, criterium, classes, withObservation) {
  if (groups) {
    const allGroups = groups.map((group, index) => (
      <div style={{ width: '100%' }} key={`-${index + 1}-`}>
        <Questions observations={group.questions} withObservation={withObservation} />
        <div className={classes.inline} style={{ marginBottom: withObservation ? 0 : 24 }}>
          <Charts
            questions={group.questions}
            name={`${criterium}-${index}`}
            fullWidth={fullWidth}
            options={group.options}
          />
        </div>
      </div>
    ))
    return allGroups
  }
  return null
}

const style = theme => ({
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  tag: {
    background: '#e3e3e3',
    padding: '1px 12px',
    marginLeft: 12,
    borderRadius: 8,
    '&>h6': {
      color: theme.palette.grey.dark
    }
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
})

class ResultCriteria extends Component {
  open(name) {
    const { handleOpen } = this.props
    return () => {
      if (handleOpen !== undefined) {
        handleOpen(name)
      }
    }
  }

  render() {
    const { criterium, info, theme, open, fullWidth, classes, withObservation } = this.props
    return (
      <div>
        <div className={classes.title} key={criterium} onClick={this.open(criterium)}>
          <Checkbox
            disabled
            checked={info.checked}
            icon={
              <ErrorOutline style={{ color: theme.palette.alert.main }} />
            }
            checkedIcon={(
              <CheckCircle
                style={{ color: theme.palette.confirm.main }}
              />
            )}
            value="checkedH"
          />
          <Typography variant="subtitle2">
            {`${criterium} `}
            {info.escada ? '*' : ''}
          </Typography>
          <Conditional condition={!Number.isNaN(info.criterium_percentage)}>
            <div className={classes.tag}>
              <Typography variant="subtitle1">
                {info.criterium_percentage}
                {' '}
                %
              </Typography>
            </div>
          </Conditional>
        </div>
        <Conditional condition={open}>
          <>
            {renderGroups(info.question_groups, fullWidth, criterium, classes, withObservation)}
          </>
        </Conditional>
      </div>
    )
  }
}

ResultCriteria.propTypes = {
  criterium: propTypes.string,
  info: propTypes.object,
  theme: propTypes.object,
  open: propTypes.bool,
  handleOpen: propTypes.func,
  fullWidth: propTypes.bool,
  classes: propTypes.object.isRequired,
  withObservation: propTypes.bool
}

ResultCriteria.defaultProps = {
  criterium: '',
  info: {},
  theme: {},
  open: false,
  handleOpen: undefined,
  fullWidth: false,
  withObservation: false
}

export default withStyles(style)(ResultCriteria)
