import React, { Component } from 'react'
import { Paper, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import ViewTabs from '../CompetencyEvaluation/MainView/ViewTabs'
import RecurrentContext from './RecurrentContext'
import Forms from './Forms'

const style = () => ({
  container: {
    textAlign: 'left'
  }
})

const defaultResultTab = () => (<div>Resultados</div>)

class RecurrentContainer extends Component {
  render() {
    const { classes, resultTab: ResultTab } = this.props;
    return (
      <div className={classes.container}>
        <Paper>
          <ViewTabs>
            <Forms />
            <ResultTab />
          </ViewTabs>
        </Paper>
      </div>
    );
  }
}

RecurrentContainer.contextType = RecurrentContext

RecurrentContainer.propTypes = {
  classes: propTypes.object.isRequired,
  resultTab: propTypes.node
}

RecurrentContainer.defaultProps = {
  resultTab: defaultResultTab
}
export default withStyles(style)(RecurrentContainer)
