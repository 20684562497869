import React, { Component } from 'react'
import { Button, Paper, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { SaveRounded } from '@material-ui/icons'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import autobind from '../../../../../Utils/autobind'
import HealthCard2 from './HealthCard2'
import SetViewContext from '../../SetViewProvider'
import { getContenderFormsAction } from '../../../../../Actions/Contenders'

const style = theme => ({
  container: {
    padding: 12,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      flexGrow: 1,
      margin: '0 12px',
    },
    '& > *:not(:last-child)': {
      backgroundColor: 'unset',
      color: theme.palette.grey.dark,
    },
    marginBottom: 12
  },
  header: {
    color: theme.palette.primary.main
  },
  alertIcon: {
    marginLeft: 4,
    width: 18
  },
  buttondownload: {
    color: '#6A6A6A',
    textDecoration: 'none',
  },
})

class Health extends Component {
  constructor() {
    super()
    this.state = {
      alerts: { urinaria: false, fecal: false }
    }
    autobind(Health, this)
  }

  componentDidMount() {
    this.handleClickCard()
  }

  handleClickCard() {
    const { setView } = this.context
    const { history } = this.props
    const { alerts } = this.state
    const event = this.getHealthEvent()
    setView(<HealthCard2 event={event} history={history} alerts={alerts} />, 'Ficha de Salud')
  }

  getHealthEvent() {
    const { contenders } = this.props;
    const { pending, check } = contenders.forms
    if (!pending) return null
    const arrayOfPendingEvents = Object.values(pending).reduce((x, y) => x.concat(y), [])
    const arrayOfCheckedEvents = Object.values(check).reduce((x, y) => x.concat(y), [])
    const arrayOfEvents = arrayOfPendingEvents.concat(arrayOfCheckedEvents)
    const healthEvent = arrayOfEvents.find(event => event.name === 'Ficha de Salud')
    return healthEvent
  }

  render() {
    const { classes, match } = this.props;
    const { id } = match.params
    const { alerts } = this.state
    const event = this.getHealthEvent()
    const mustAlert = (alerts.fecal || alerts.urinaria)
    return (
      <Paper className={classes.container}>
        <div className={classes.buttons}>
          <Button variant="contained">
            Minutas de Salud
          </Button>
          <Button variant="contained">
            <a
              href={`${process.env.REACT_APP_API_URL}/api/downloads/${id}/ficha_salud_report.pdf`}
            >
              <div className={classes.buttondownload}>
                Descargar Informe
              </div>
            </a>
            <SaveRounded />
          </Button>
          {event && (
            <Button disabled={event?.event_id === null} color="primary" variant="contained" onClick={this.handleClickCard}>
              Ficha de Salud
              {/* {mustAlert && <Error className={classes.alertIcon} size="small" />} */}
              { mustAlert && (
                <img
                  className={classes.alertIcon}
                  src="/alert_whitebg.svg"
                  alt=""
                />
              )}
            </Button>
          )}
        </div>
      </Paper>
    );
  }
}

Health.contextType = SetViewContext

Health.propTypes = {
  classes: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired
}

Health.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

const mapDispatchToProps = dispatch => ({
  getContenderForms: id => dispatch(getContenderFormsAction(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(Health)))
