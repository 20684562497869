import React from 'react'
import propTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Header from '../Header/index'
import Main from '../../CSD/CSDEvaluation'
import { loadExternalCSDByUserAction } from '../../../Actions/User'
import { getCompetencyEvaluationAction } from '../../../Actions/Competency'
import autobind from '../../../Utils/autobind'

const style = theme => ({
  container: {
    margin: 'auto',
    maxWidth: 1000,
    padding: theme.spacing(4)
  }
})
class ExternalCSD extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      metaInfo: null
    }
    autobind(ExternalCSD, this)
  }

  async componentDidMount() {
    const { loadExternalCSDByUser, getCompetencyEvaluation, match } = this.props
    const { params: { userId } } = match
    const metaInfoResponse = await loadExternalCSDByUser(userId)
    const metaInfo = metaInfoResponse.payload.data.info
    await getCompetencyEvaluation(metaInfo)
    this.setState({ loading: false, metaInfo })
  }

  handleGoToDashboard() {
    const { history } = this.props
    history.push('/')
  }

  render() {
    const { classes } = this.props
    const { loading, metaInfo } = this.state
    return (
      <>
        <Header removeUserInfo />
        <div className={classes.container}>
          {!loading && (
            <Main
              event={metaInfo}
              evaluation={metaInfo.evaluation_id}
              endFunction={this.handleGoToDashboard}
            />
          )}
        </div>
      </>
    )
  }
}

ExternalCSD.propTypes = {
  classes: propTypes.object.isRequired,
  loadExternalCSDByUser: propTypes.func.isRequired,
  getCompetencyEvaluation: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
}

const mapStateToProps = null
const mapDispatchToProps = dispatch => ({
  loadExternalCSDByUser: contenderId => dispatch(loadExternalCSDByUserAction(contenderId)),
  getCompetencyEvaluation: body => dispatch(getCompetencyEvaluationAction(body))
})

export default withRouter(connect(mapStateToProps,
  mapDispatchToProps)(withStyles(style)(ExternalCSD)))
