import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import General from './General'
import Medics from './Medics'
import PersonalCaracteristics from './PersonalCaracteristics'
import OtherDeterminations from './OtherDeterminations'

const style = () => ({

})

class Past extends Component {
  render() {
    const { classes, answers, values, reportInfo } = this.props
    const personalCharsAnswers = answers ? answers.report_answers['Características personales'] : []
    const otherDetAnswers = answers ? answers.report_answers['Otras determinaciones'] : []
    const medicalAnswers = answers ? answers.report_answers['Antecedentes médicos'] : []
    return (
      <div className={classes.container}>
        <General data={reportInfo.gathering_info} />
        <Medics data={reportInfo.health_info} answers={medicalAnswers} values={values} />
        <PersonalCaracteristics answers={personalCharsAnswers} values={values} />
        <OtherDeterminations answers={otherDetAnswers} values={values} />
      </div>
    )
  }
}

Past.propTypes = {
  classes: propTypes.object.isRequired,
  answers: propTypes.arrayOf(propTypes.object),
  values: propTypes.object.isRequired,
  reportInfo: propTypes.object.isRequired
}

Past.defaultProps = {
  answers: undefined,
}

export default withStyles(style)(Past)
