import {
  AddCircle,
  PermContactCalendar,
  AccountBox,
  CalendarToday,
  History,
  SettingsApplicationsRounded,
  SupervisorAccount,
  HomeRounded,
  Beenhere,
  Work,
  CloudDownload
} from '@material-ui/icons'

const routes = [
  {
    id: 1,
    title: '',
    sub_routes: [
      {
        id: 2,
        name: 'Dashboard',
        icon: HomeRounded,
        path: '/dashboard',
        roles: ['admin', 'director']
      },
      {
        id: 3,
        name: 'Nuevo Trabajador',
        icon: AddCircle,
        path: '/newContender',
        roles: ['admin', 'director', 'handler']
      },
    ],
  },
  {
    id: 4,
    title: 'Trabajadores',
    sub_routes: [
      {
        id: 5,
        name: 'Usuarios',
        icon: PermContactCalendar,
        path: '/contenders',
        roles: ['admin', 'director', 'handler']
      },
      {
        id: 6,
        name: 'Facilitadoras',
        icon: SupervisorAccount,
        path: '/handlers',
        roles: ['admin', 'director', 'handler']
      },
      {
        id: 13,
        name: 'Empresas',
        icon: Work,
        path: '/enterprises',
        roles: ['admin', 'director', 'handler']
      },
    ],
  },
  {
    id: 7,
    title: 'Facilitador',
    sub_routes: [
      {
        id: 8,
        name: 'Perfil',
        icon: AccountBox,
        path: '/handler',
        roles: ['admin', 'director', 'handler']
      },
      {
        id: 10,
        name: 'Calendario',
        icon: CalendarToday,
        path: '/calendar',
        roles: ['admin', 'director', 'handler']
      },
      {
        id: 11,
        name: 'Historial',
        icon: History,
        path: '/history',
        roles: ['admin', 'director', 'handler']
      },
      {
        id: 11,
        name: 'Descargas',
        icon: CloudDownload,
        path: '/downloads',
        roles: ['admin', 'director', 'handler']
      },
      {
        id: 12,
        name: 'Autorizaciones',
        icon: Beenhere,
        path: '/authorizations',
        roles: ['admin', 'director', 'handler']
      },
      {
        id: 13,
        name: 'Configuracion',
        icon: SettingsApplicationsRounded,
        path: '/settings',
        roles: ['admin', 'director', 'handler']
      },
    ],
  },
]

export default routes
