import React, { Component } from 'react'
import propTypes from 'prop-types'
import { withStyles, Typography, Tooltip } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import OnTouchTooltip from '../../../../Shared/OnTouchTooltip'

const style = theme => ({
  container: {
    padding: 12,
    textAlign: 'left'
  },
  barContainer: {
    width: '100%',
    background: theme.palette.indigo.lighter,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderTopLeftRadius: 20,
  },
  upperContainer: {
    background: theme.palette.confirm.main,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderTopLeftRadius: 20,
    transition: 'all 0.5s ease-out',
    minWidth: 24
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  help: {
    width: 20,
    height: 20,
    color: theme.palette.grey.border
  },
  titleResult: {
    fontWeight: 900,
    fontSize: 14,
    color: '#6A6A6A',
  },
  barSupraContainer: {
    position: 'relative',
    marginBottom: 6
  }
})

class Percentile extends Component {
  render() {
    const { classes, data } = this.props
    const toShow = [...data].sort((a, b) => b - a)
    const colors = ['#1F8095', '#38B4A9', '#FFB33D']
    const tooltip = ['', '', '']
    data.forEach((n, i) => {
      const index = toShow.indexOf(n)
      tooltip[index] = `Gencat ${i + 1}`
    })

    return (
      <div className={classes.container}>
        <div className={classes.inline}>
          <Typography className={classes.titleResult}>Percentil del Índice de Calidad de Vida:</Typography>
          <OnTouchTooltip title="Si obtiene un percentil de “15”, significa que el 85% de la muestra obtuvo una puntuación mas alta en el Índice de Calidad de Vida. La escala va del 0 al 100.">
            <HelpOutline className={classes.help} />
          </OnTouchTooltip>
        </div>
        <div className={classes.barSupraContainer}>
          <div className={classes.barContainer}>
            <div style={{ height: 24 }} />
            {toShow.map((n, index) => (
              <Tooltip title={tooltip[index]} key={n}>
                <div
                  className={classes.upperContainer}
                  style={{ background: colors[index], width: `${n}%`, position: 'absolute', top: 0, left: 0, height: '100%' }}
                >

                  <Typography variant="subtitle1" style={{ color: 'white', textAlign: 'end', paddingRight: 8 }}>{n}</Typography>

                </div>
              </Tooltip>

            ))}
          </div>
        </div>
        <div style={{ textAlign: 'left' }}>
          <Typography style={{ fontSize: 12 }}>
            Indica el porcentaje de personas que tienen una puntuación superior o inferior,
            según el Índice de calidad de vida obtenido por el usuario.
          </Typography>
        </div>
      </div>
    )
  }
}

Percentile.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.arrayOf(propTypes.oneOfType([propTypes.string, propTypes.number]))
}

Percentile.defaultProps = {
  data: []
}

export default withStyles(style)(Percentile)
