import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import ReportTitles from '../ReportTitles'

const style = () => ({
  inlineInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      width: '50%'
    },
    margin: '6px 0',
    '& > div:first-child > h2': {
      fontWeight: 600
    },
    minWidth: 450
  },
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      width: '50%'
    },
    padding: 12
  },
  container: {
    marginBottom: 48
  }
})

const info = [
  { name: 'Nombre', key: 'name' },
  { name: 'Rut', key: 'rut' },
  { name: 'Edad', key: 'age' },
  { name: 'Fecha de Nacimiento', key: 'birth_date' },
  { name: 'Nacionalidad', key: 'nationality' },
  { name: 'Estado Civil', value: 'Soltero' },
  { name: 'Sexo', key: 'sex' },
  { name: 'Dirección', key: 'address' },
  { name: 'Comuna', key: 'commune' },
  { name: 'Teléfono', key: 'phone' },
  { name: 'Email', key: 'mail' },
  { name: 'Certificado de Discapacidad', value: 'En proceso' },
  { name: 'Contacto de Emergencia', key: 'emergency_contact_name' },
  { name: 'Telefono de Emergencia', key: 'emergency_contact_phone' },
  { name: 'Parentesco', key: 'emergency_contact_relation' },
]

class General extends Component {
  renderInfo() {
    const { classes, data } = this.props
    return info.map(element => {
      const value = element.value ? element.value : data[element.key]
      return (
        <div className={classes.inlineInfo} key={element.name}>
          <div>
            <Typography variant="h2">{element.name}</Typography>
          </div>
          <div>
            <Typography variant="h2">{value}</Typography>
          </div>
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <ReportTitles title="Antecedentes Generales" />
        <div className={classes.infoContainer}>
          {this.renderInfo()}
        </div>
      </div>
    );
  }
}

General.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired
}

General.defaultProps = {

}
export default withStyles(style)(General)
