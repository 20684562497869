import stateMaker from '../Utils/reducers'

const eventsReducer = (state = {
  all: [], forms: [], states: [], selected: {}, events: []
}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'LOAD_CONTENDERS':
      return stateMaker(newState, 'all', 'fetch', action)
    case 'LOAD_CONTENDER':
      return stateMaker(newState, 'selected', 'fetch', action)
    case 'EDIT_CONTENDER':
      return stateMaker(newState, 'selected', 'fetch', action)
    case 'LOAD_CONTENDER_EVENTS':
      return stateMaker(newState, 'selected', 'fetch_and_extend', action, { extendKey: 'events' })
    case 'SEARCH_CONTENDER_EVENTS':
      return stateMaker(newState, 'search', 'fetch', action)
    case 'CREATE_CONTENDER':
      return stateMaker(newState, 'all', 'create', action)
    case 'GET_CONTENDER_FORMS':
      return stateMaker(newState, 'forms', 'fetch', action)
    case 'GET_FORM_RESULTS':
      return stateMaker(newState, 'results', 'fetch', action)
    case 'GET_PROFILE_RESULTS':
      return stateMaker(newState, 'profileResults', 'fetch', action)
    case 'GET_DYNAMIC_QUESTIONS':
      return stateMaker(newState, 'dynamics', 'fetch', action)
    case 'CREATE_DYNAMIC_QUESTION':
      return stateMaker(newState, 'dynamics', 'fetch', action)
    case 'GET_REPORT_ANSWERS':
      return stateMaker(newState, 'reportAnswers', 'fetch', action)
    case 'GET_REPORT_INFO':
      return stateMaker(newState, 'reportInfo', 'fetch', action)
    case 'CREATE_BINNACLE_ENTRY':
      return stateMaker(newState, 'selected', 'fetch_and_add', action, { extendKey: 'notes' })
    case 'DELETE_BINNACLE_ENTRY':
      return stateMaker(newState, 'selected', 'fetch_and_extend', action, { extendKey: 'notes' })
    case 'GET_CONTENDER_EVENTS':
      return stateMaker(newState, 'selected', 'fetch_and_extend', action, { extendKey: 'events' })
    case 'GET_CONTENDER_STATES':
      return stateMaker(newState, 'states', 'fetch', action)
    default:
      return state
  }
}

export default eventsReducer
