import React, { Component } from 'react'
import propTypes from 'prop-types'
import Tabs from './Tabs'
// import Alerts from './Alerts'
import Brief from '../Tabs/Brief'
import History from '../Tabs/HistoryTab'
import { tabsNames, alerts } from '../fake'
import autobind from '../../../../Utils/autobind'
import CalendarTab from '../Tabs/Calendar'
import TabsContainer from './TabsContainer'
import Evaluations from '../Evaluations/Evaluations'
import Health from '../Tabs/Health/Health'
import Reports from '../Tabs/Reports'

class ReportContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alertList: alerts,
    }
    autobind(ReportContainer, this)
  }

  handleRemoveAlert(alertId) {
    return () => {
      this.setState(prevState => ({
        alertList:
          prevState.alertList.filter(el => el.id !== alertId)
      }))
    }
  }

  render() {
    const { selectedTab, handleTabChange } = this.props
    return (
      <div>
        <Tabs names={tabsNames} selected={selectedTab} handler={handleTabChange} />
        {/* <Alerts alerts={alertList} handleRemove={this.handleRemoveAlert} /> */}
        <TabsContainer selected={selectedTab}>
          <Brief />
          <Evaluations />
          <CalendarTab />
          <History />
          <Health />
          <Reports />
        </TabsContainer>
      </div>
    )
  }
}

ReportContainer.propTypes = {
  selectedTab: propTypes.number,
  handleTabChange: propTypes.func
}

ReportContainer.defaultProps = {
  selectedTab: 0,
  handleTabChange: undefined
}

export default ReportContainer
