import instance from '../Utils/client'

export const getGenderData = () => instance({
  method: 'get',
  url: 'dashboard/sex'
})
export const getAgeData = () => instance({
  method: 'get',
  url: 'dashboard/age'
})
export const getStateData = () => instance({
  method: 'get',
  url: 'dashboard/state'
})
export const getDisabilityData = () => instance({
  method: 'get',
  url: 'dashboard/disability'
})
export const getCommuneData = () => instance({
  method: 'get',
  url: 'dashboard/commune'
})
export const getProfileData = () => instance({
  method: 'get',
  url: 'dashboard/profile'
})
export const getComorbilityData = () => instance({
  method: 'get',
  url: 'dashboard/comorbility'
})
