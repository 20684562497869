import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { Phone } from '@material-ui/icons'
import { TextField } from '../../../../Shared/Fields/Text'

const style = () => ({
  icon: {
    padding: 6,
    marginRight: 6,
    height: 18,
    width: 18
  },
  inlineLeft: {
    display: 'flex',
    alignItems: 'center',
    margin: '6px 0',
    '& > div': {
      flexGrow: 1
    }
  },
})

class EditableText extends Component {
  render() {
    const { classes, value, onChange, open, Icon, name } = this.props;
    return (
      <div className={classes.container}>
        {open
          ? (
            <div className={classes.inlineLeft}>
              <Icon className={classes.icon} />
              <TextField complete={{ name }} value={value} onChange={onChange} />
            </div>
          )
          : (
            <div className={classes.inlineLeft}>
              <Icon className={classes.icon} />
              <Typography variant="body1">{`${value || 'No ingresado'}`}</Typography>
            </div>
          )}
      </div>
    );
  }
}

EditableText.propTypes = {
  classes: propTypes.object.isRequired,
  value: propTypes.string,
  onChange: propTypes.func,
  open: propTypes.bool,
  Icon: propTypes.node,
  name: propTypes.string.isRequired
}

EditableText.defaultProps = {
  value: '',
  onChange: undefined,
  open: false,
  Icon: Phone
}

export default withStyles(style)(EditableText)
