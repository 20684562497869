import instance from '../Utils/client'
// import createSnackbar from '../Utils/snackbars'

// eslint-disable-next-line import/prefer-default-export
export const getCommunesAction = () => {
  const REQUEST = instance({
    method: 'get',
    url: 'communes'
  })
  return {
    type: 'GET_COMMUNES',
    payload: REQUEST
  }
}
