/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Paper, Typography, Collapse, ListItem, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import { ListAltRounded, SaveRounded, } from '@material-ui/icons'
import autobind from '../../../../../Utils/autobind'
import Name from '../../../../Results/Individuals/HealthTab/ResultComponents/Name'
import LoaderAnimator from '../../../../../Shared/LoaderAnimator'
import SetViewContext from '../../SetViewProvider'
import { getFormResultAction } from '../../../../../Actions/Contenders'
import EditableHealthCriterium from './EditableHealthCriterium'
import EditablePrevision from './EditablePrevision'
import EditableSensorialEvaluation from './EditableSensorialEvaluation'
import LoaderDialog from '../../../../../Shared/LoaderDialog'
import CheckupSection from './CheckupSection'

const style = () => ({
  title: {
    display: 'flex'
  },
  container: {
    padding: 24,
    position: 'relative',
    textAlign: 'left'
  },
  moreButton: {
    position: 'absolute',
    right: 12,
    top: 12
  },
  menu: {
    position: 'absolute',
    width: '200px',
    right: '12px',
    top: '48px',
  },
  submenu: {
    position: 'absolute',
    width: '150px',
    right: '95%',
    top: '75%',
  },
  index: {
    position: 'fixed',
    top: '40%',
    right: -150,
    width: 300,
    zIndex: 5,
    transition: 'all 0.3s ease-in-out'
  },
  indexDefault: {
    display: 'flex',
    padding: 12,
    '& > *': {
      marginRight: 6
    },
    cursor: 'pointer'
  },
  open: {
    right: 0
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttondownload: {
    fontSize: 12,
    color: '#6A6A6A',
    background: '#ECECEC',
  },
})

const sections = [
  'Previsión de salud',
  'Salud Física',
  'Salud Mental',
  'Medicamentos',
  'Hospitalizaciones',
  'Eventos importantes',
  'Evaluación sensorial',
  'Salud sexual',
  'Exámenes y controles preventivos'
]

class HealthCard2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      openIndex: false
    }
    autobind(HealthCard2, this)
  }

  async componentDidMount() {
    const { contender, getFormResult, event } = this.props
    this.setState({ loading: true })
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
      user_id: contender.contender.user_id,
      type: event.route,
      withanswers: true
    }
    await getFormResult(body)
    this.setState({ loading: false })
  }

  handleOpenIndex() {
    const { openIndex } = this.state
    this.setState({ openIndex: !openIndex })
  }

  handleGotoSection(id) {
    return () => {
      const element = document.getElementById(id)
      if (!element) return null
      element.scrollIntoView({ behavior: 'smooth' })
      return this.setState({ openIndex: false })
    }
  }

  handleSetView(View, name) {
    return () => {
      const { setView } = this.context
      setView(View, name)
    }
  }

  handleReload() {
    this.componentDidMount()
  }

  render() {
    const { classes, results, event, contender, alerts } = this.props
    const { forms } = results
    const { loading, openIndex } = this.state
    const form = forms ? forms['Ficha de Salud'] : false
    const name = contender?.user?.name
    if (form) {
      const saludFisica = form['Parte I']['Salud Física']
      const saludMental = form['Parte II']['Salud Mental']
      const medicamentos = form['Parte II'].Medicamentos
      const hospitalizaciones = form['Parte II'].Hospitalizaciones
      const eventosImportantes = form['Parte II']['Eventos importantes']
      const evaluacionSensorial = form['Parte II']['Evaluación Sensorial']
      const saludSexual = form['Parte III']['Salud Sexual']
      const checkups = form.Controles
      return (
        <>
          <Paper className={`${classes.index} ${openIndex ? classes.open : ''} no-print`}>
            <div className={`${classes.indexDefault}`} onClick={this.handleOpenIndex}>
              <ListAltRounded />
              <Typography variant="subtitle1">Índice</Typography>
            </div>
            <Collapse in={openIndex}>
              {sections.map((section, index) => (
                <ListItem button key={section} onClick={this.handleGotoSection(`section-${index}`)}>
                  <Typography variant="subtitle1">{`${index + 1}. ${section}`}</Typography>
                </ListItem>
              ))}
            </Collapse>
          </Paper>
          <Paper className={`${classes.container} print-no-border`}>
            <div className={classes.header}>
              <Button className={`${classes.buttondownload} no-print`} variant="contained" onClick={() => window.print()}>
                Descargar
                <SaveRounded />
              </Button>
            </div>
            <img src="/logo_borde.png" alt="logo" height="64px" className="only-print" />
            <Typography className="only-print" variant="h3" style={{ margin: '12px 0' }}>
              {`Ficha de Salud de: ${name}`}
            </Typography>
            <Name form={form} />
            <div id="section-0"><EditablePrevision reload={this.handleReload} form={form} event={event} /></div>
            <div id="section-1">
              <EditableHealthCriterium
                tooltip={
                  `En este apartado se busca conocer todos los diagnósticos de salud física que presenta el usuario en los diferentes ámbitos. Una persona puede tener más de un diagnóstico en el mismo ámbito.
              Si posee diagnósticos en alguno de estos ámbitos, presionar "abrir grupo" para completar toda la información referente a ese diagnóstico.
              Recordar completar el desplegable "abrir grupo" para especificar la información de cada diagnóstico por separado.
               Si se desconoce información siempre registrar "Sin información".`
                }
                reload={this.handleReload}
                event={event}
                form={saludFisica}
                title="Salud Física"
                alerts={alerts}
              />
            </div>
            <div id="section-2">
              <EditableHealthCriterium
                tooltip={
                  `En este apartado se busca conocer los diagnósticos de salud de origen genético, hereditario o perinatal
              Si posee diagnósticos en alguno de estos ámbitos, presionar "abrir grupo" para completar toda la información referente a ese diagnóstico.
              Recordar completar el desplegable "abrir grupo" para especificar la información de cada diagnóstico por separado.
               Si se desconoce información siempre registrar "Sin información"`
                }
                reload={this.handleReload}
                event={event}
                form={saludMental}
                title="Salud Mental"
              />
            </div>
            <div id="section-3">
              <EditableHealthCriterium
                tooltip={
                  `En esta categoría se busca conocer el esquema farmacológico actual y el historial de medicamentos del usuario para realizar acompañamientos ajustados.
              Si utiliza o ha utilizado medicamentos presionar "abrir grupo" para completar toda la información referente a ese medicamento.
              Recordar completar el desplegable "abrir grupo" para especificar la información de cada diagnóstico por separado.
               Si se desconoce información siempre registrar "Sin información"`
                }
                reload={this.handleReload}
                event={event}
                form={medicamentos}
                title="Medicamentos"
              />
            </div>
            <div id="section-4">
              <EditableHealthCriterium
                tooltip={
                  `Se busca conocer la ocurrencia de episodios  de salud que han requerido de hospitalizaciones para su abordaje y que pudiesen afectar en futuras situaciones. 
              Recordar especificar motivo y lugar de atención. 
              Si se desconoce información siempre registrar "Sin información" `
                }
                reload={this.handleReload}
                event={event}
                form={hospitalizaciones}
                title="Hospitalizaciones"
              />
            </div>
            <div id="section-5">
              <EditableHealthCriterium
                tooltip={
                  `Registrar eventos traumáticos o accidentes que le hayan ocurrido al usuario durante el periodo cercano a su nacimiento y durante su vida. 
              En "eventos traumáticos" recordar "abrir grupo" y  especificar la información respecto al evento ocurrido.
              Si se desconoce información siempre registrar "Sin información"`
                }
                reload={this.handleReload}
                event={event}
                form={eventosImportantes}
                title="Eventos Importantes"
              />
            </div>
            <div id="section-6">
              <EditableSensorialEvaluation
                reload={this.handleReload}
                form={evaluacionSensorial}
                event={event}
                tooltip="La evaluación sensorial busca identificar conductas que podrían alertar sobre dificultades asociadas a la organización de los estímulos que pueden requerir apoyo en el contexto laboral"
                title="Evaluación Sensorial"
              />

            </div>
            <div id="section-7">
              <EditableSensorialEvaluation
                reload={this.handleReload}
                form={saludSexual}
                event={event}
                tooltip="En este apartado se busca obtener información respecto a la salud sexual del usuario. Antes de realizar estas preguntas cuidar que el contexto sea privado y adecuado para la conversación. "
                title="Salud Sexual"
              />

            </div>
            <div id="section-8">
              <CheckupSection
                checkups={checkups}
                reload={this.handleReload}
                isParentLoading={loading}
              />
            </div>
            <LoaderDialog open={loading} />
          </Paper>
        </>
      )
    }
    return (
      <LoaderAnimator loading />
    )
  }
}

HealthCard2.contextType = SetViewContext

HealthCard2.propTypes = {
  getFormResult: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  results: propTypes.object,
  event: propTypes.object.isRequired,
  alerts: propTypes.object.isRequired
}

HealthCard2.defaultProps = {
  results: { results: {} }

}

const mapStateToProps = state => ({
  results: state.contenders.results,
  contender: state.contenders.selected
})

const mapDispatchToProps = dispatch => ({
  getFormResult: body => dispatch(getFormResultAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(HealthCard2))
