export const addBreadcrumbAction = breadcrumb => ({
  type: 'ADD_BREADCRUMB',
  payload: breadcrumb
})

export const setBreadcrumbsAction = breadcrumbs => ({
  type: 'SET_BREADCRUMBS',
  payload: breadcrumbs
})

export const selectBreadcrumbAction = name => ({
  type: 'SELECT_BREADCRUMB',
  payload: name
})
