import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import ReportTitles from '../ReportTitles'
import InterviewAnswerElement from './InterviewAnswerElement'

const style = () => ({
  container: {
    marginBottom: 48
  }
})

class Individual extends Component {
  renderAnswers() {
    const { contenders } = this.props
    const results = contenders.profileResults
    const { individual } = results
    if (!individual.body) return null
    const form = individual.body['Entrevista Individual']
    const filteredForm = Object.entries(form).filter(([key,]) => !key.includes('descendidos')).map(([, value]) => value)
    const allQuestions = filteredForm.map(element => [...element.empty]).reduce((x, y) => x.concat(y), []).filter(question => question.type === 'slider')
    const UID = contenders?.selected?.user?.id
    return allQuestions.map(question => {
      const answer = question.answers.find(a => a.user_id === UID)
      const value = [{ companion_name: 'Facilitador/a', answer: answer?.body?.answer || 0 }]
      return (
        <InterviewAnswerElement
          value={value}
          name={question.text}
          title={question.text}
          key={question.text}
          user={UID}
        />
      )
    })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <ReportTitles title="Entrevista Individual" />
        {this.renderAnswers()}
      </div>
    );
  }
}

Individual.propTypes = {
  classes: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
}

Individual.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(Individual))
