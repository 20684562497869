import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import App from './App'
import theme from './Config/theme'
import store from './store'
import CacheBuster from './CacheBuster';

const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={4} preventDuplicate>
              <CacheBuster>
                {({
                  loading,
                  isLatestVersion,
                  refreshCacheAndReload
                }) => {
                  if (loading) {
                    return null;
                  }
                  if (!loading && !isLatestVersion) {
                    refreshCacheAndReload();
                  }
                  return <App />;
                }}
              </CacheBuster>
            </SnackbarProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
