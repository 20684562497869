import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { Create } from '@material-ui/icons'
import { Typography, withStyles, makeStyles } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import classNames from 'classnames'
import Loader from '../../../Shared/Loader'
import CreateDialog from './CreateDialog'
import GeneralTable from '../../../Shared/GeneralTable'
import loadHandlersAction from '../../../Actions/Handlers'
import { registerAction } from '../../../Actions/Login'
import { handlerInfo, handlerFilter } from './TableInfo'
import autobind from '../../../Utils/autobind'
import { updateUserAccountType } from '../../../API/User'

const style = theme => ({
  mainContainer: {
    padding: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2)
  }
})

const textActionStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    marginRight: 6,
  },
  confirmMainColor: {
    color: theme.palette.confirm.main,
  },
}))

const translateRole = role => {
  if (role === 'handler') {
    return 'Facilitador(a)'
  } if (role === 'director') {
    return 'Director(a)'
  }
  return 'Administrador(a)'
}

const handlerInfoParser = data => data.map(element => (
  {
    id: element.user.id,
    name: element.user.name,
    role: translateRole(element.user.account_type),
    areas: element.areas,
  }
))

const EditRoleAction = () => {
  const classes = textActionStyles()
  return (
    <div className={classes.container}>
      <Typography className={classNames(classes.text, classes.confirmMainColor)}>
        Modificar
      </Typography>
      <Create className={classes.confirmMainColor} />
    </div>
  )
}

class HandlersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openCreate: false,
      selected: undefined,
    }
    autobind(HandlersList, this)
  }

  handleCreateClose() {
    this.setState({ openCreate: false })
  }

  handleOpenCreate(e) {
    return () => {
      this.setState({ openCreate: true, selected: e })
    }
  }

  async handleRegister(id, role) {
    const { getAllHandlers } = this.props
    await updateUserAccountType(id, role)
    getAllHandlers()
  }

  render() {
    const { openCreate, selected } = this.state
    const { handlers, getAllHandlers, classes } = this.props
    const actions = [
      { name: 'Modificar rol', icon: EditRoleAction, action: this.handleOpenCreate },
    ]
    const handlerList = handlerInfoParser(handlers.all)
    return (
      <div className={classes.mainContainer}>
        <Loader action={getAllHandlers}>
          <GeneralTable
            data={handlerList}
            info={handlerInfo}
            actions={actions}
            filters={handlerFilter}
            name="handlers"
            disablePagination
          />
          <CreateDialog
            open={openCreate}
            onClose={this.handleCreateClose}
            onSubmit={this.handleRegister}
            selected={selected}
          />
        </Loader>
      </div>
    )
  }
}

HandlersList.propTypes = {
  classes: propTypes.object.isRequired,
  handlers: propTypes.object.isRequired,
  getAllHandlers: propTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  getAllHandlers: () => dispatch(loadHandlersAction()),
  register: (body, snackbar) => dispatch(registerAction(body, snackbar)),
})

const mapStateToProps = state => ({
  handlers: state.handler,
})

export default withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(HandlersList)))
