import React, { Component } from 'react'
import { withStyles, Paper, Tabs, Tab, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import CriteriaForm from './CriteriaForm'
import Conditional from './Conditional'
import SmallTabs from './SmallTabs'

const style = theme => ({
  container: {
    color: 'black',
    position: 'relative',
  },
  tabs: {
    display: 'flex',
  },
  tab: {
    padding: 6,
    background: 'red',
  },
  selected: {
    minWidth: 'unset',
    fontSize: '65%',
    fontWeight: 'bold',
    color: theme.palette.grey.dark,
  },
  notSelected: {
    minWidth: 'unset',
    fontSize: '65%',
    fontWeight: 'bold',
    color: 'grey',
  },
  formContainer: {
    maxHeight: '90vh',
    overflow: 'auto',
    marginTop: 12,
    position: 'relative'
  },
  labelContaienr: {
    position: 'absolute',
    right: 6,
    top: 6,
    height: '95%'
  },
  label: {
    padding: '6px 12px',
    background: '#00000080',
    borderRadius: 6,
    '& > *': {
      color: 'white'
    },
    position: 'sticky',
    float: 'right',
    top: 6,
    right: 0,
  },
  criteriaContainer: {
    position: 'relative'
  }
})

class FormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 0,
    }

    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect(event, value) {
    this.setState({ selected: value })
    const { handleSave } = this.props
    handleSave()
  }

  renderTabs() {
    const { form, classes } = this.props
    const { selected } = this.state
    if (form) {
      const width = `${(100 / Object.keys(form).length).toString()}%`
      return (
        form
        && Object.keys(form).map((key, idx) => (
          <Tab
            key={key}
            label={key}
            style={{
              width,
            }}
            className={selected === idx ? classes.selected : classes.notSelected}
          />
        ))
      )
    }
    return null
  }

  renderForm() {
    const { selected } = this.state
    const { form, userId, params, handleChange,
      saved, comments, savedComments, handleComment, handleSubchange } = this.props
    if (form) {
      let criteria
      Object.keys(form).forEach((key, index) => {
        if (index === selected) {
          criteria = (
            <CriteriaForm
              criteria={form[key]}
              handleChange={handleChange}
              handleSubchange={handleSubchange}
              params={params}
              comments={comments}
              userId={userId}
              saved={saved}
              savedComments={savedComments}
              handleComment={handleComment}
              bigScreen={window.innerWidth >= 800}
            />
          )
        }
      })
      return criteria
    }
    return null
  }

  render() {
    const { selected } = this.state
    const { classes, form, userName } = this.props
    return (
      <div className={classes.container}>
        <Paper square>
          <Conditional condition={window.innerWidth > 800} hasElse>
            <Tabs value={selected} indicatorColor="primary" onChange={this.handleSelect}>
              {this.renderTabs()}
            </Tabs>
            <SmallTabs tabs={form} onChange={this.handleSelect} value={selected} />
          </Conditional>
        </Paper>
        <Paper square className={classes.formContainer}>
          <div className={classes.criteriaContainer}>
            <div className={classes.labelContaienr}>
              <div className={classes.label}>
                <Typography variant="subtitle1">
                  {userName}
                </Typography>
              </div>
            </div>
            {this.renderForm()}
          </div>
        </Paper>
      </div>
    )
  }
}

FormContainer.propTypes = {
  classes: propTypes.object.isRequired,
  form: propTypes.object,
  userId: propTypes.string.isRequired,
  params: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleSubchange: propTypes.func.isRequired,
  saved: propTypes.object.isRequired,
  handleSave: propTypes.func.isRequired,
  comments: propTypes.object.isRequired,
  savedComments: propTypes.object.isRequired,
  handleComment: propTypes.func,
  userName: propTypes.string
}

FormContainer.defaultProps = {
  handleComment: undefined,
  form: {},
  userName: ''
}

export default withStyles(style)(FormContainer)
