import React, { Component } from 'react'
import { Button, Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { NoteAdd } from '@material-ui/icons'
import RecurrentContext from './RecurrentContext'
import EvaluationCard from './EvaluationCard'
import autobind from '../../Utils/autobind'
import LoaderDialog from '../../Shared/LoaderDialog'

const style = theme => ({
  divider: {
    display: 'flex',
    alignItems: 'center',
    '& *': {
      margin: '0 6px',
    },
    '& > *:nth-child(2)': {
      flexGrow: 1,
      background: theme.palette.grey.main
    },
    marginBottom: 24
  },
  evaluations: {
    background: theme.palette.grey.light,
    borderRadius: 8,
    boxShadow: '0px 1px 8px #00000026',
    marginBottom: 32,
  },
  evaluationDivider: {
    margin: '0 12px',
  },
  iconButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      marginRight: 6
    },
    margin: '36px 0'
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    },
    '& > div': {
      textAlign: 'end'
    },
    '& > div > *': {
      marginLeft: 12
    }
  }
})

class Forms extends Component {
  constructor() {
    super()
    this.state = {
      pending: [],
      done: [],
      loading: false
    }
    autobind(Forms, this)
  }

  componentDidMount() {
    const { event, getEvaluations } = this.context
    Promise.resolve(getEvaluations(event)).then(response => {
      if (!response) return null
      if (response.data.status !== 'success') return false
      const evaluations = response.data.info
      const pending = evaluations.filter(ev => ev.status === 'pending')
      const done = evaluations.filter(ev => ev.status === 'check')
      return this.setState({ pending, done })
    })
  }

  handleReload() {
    this.componentDidMount()
  }

  handleClickPending(evaluation) {
    return () => {
      const { getEvaluation } = this.context
      getEvaluation(evaluation)
    }
  }

  handleClickDone(evaluation) {
    return () => {
      const { getEvaluationResult } = this.context
      getEvaluationResult(evaluation)
    }
  }

  async handleClickNew() {
    const { event, createNewEvaluation } = this.context
    this.setState({ loading: true })
    await createNewEvaluation(event)
    this.componentDidMount()
    this.setState({ loading: false })
  }

  renderPending() {
    const { pending } = this.state
    const { classes } = this.props
    const { card: Card = EvaluationCard } = this.context
    return pending.map((evaluation, index) => (
      <>
        {index > 0 && <Divider className={classes.evaluationDivider} />}
        <Card
          reload={this.handleReload}
          evaluation={evaluation}
          onClick={this.handleClickPending(evaluation)}
        />
      </>
    ))
  }

  renderDone() {
    const { done } = this.state
    const { classes } = this.props
    const { card: Card = EvaluationCard } = this.context
    return done.map((evaluation, index) => (
      <>
        {index > 0 && <Divider className={classes.evaluationDivider} />}
        <Card
          reload={this.handleReload}
          evaluation={evaluation}
          onClick={this.handleClickDone(evaluation)}
        />
      </>
    ))
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state
    const { createButtonName = 'Nueva evaluación', disabledCreate = false } = this.context
    return (
      <div className={classes.container}>
        <LoaderDialog open={loading} />
        {!disabledCreate
        && (
          <Button color="primary" variant="contained" className={classes.iconButton} onClick={this.handleClickNew}>
            <NoteAdd />
            {createButtonName}
          </Button>
        )}
        <div className={classes.divider}>
          <Typography variant="h2">En proceso</Typography>
          <Divider />
        </div>
        <div className={classes.evaluations}>
          {this.renderPending()}
        </div>
        <div className={classes.divider}>
          <Typography variant="h2">Historial</Typography>
          <Divider />
        </div>
        <div className={classes.evaluations}>
          {this.renderDone()}
        </div>
      </div>
    );
  }
}

Forms.contextType = RecurrentContext

Forms.propTypes = {
  classes: propTypes.object.isRequired
}

Forms.defaultProps = {

}
export default withStyles(style)(Forms)
