import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

const style = () => ({

})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

class ResultCharts extends Component {
  componentDidMount() {
    const chartElement = document.getElementById('comparison')
    const myChartRef = chartElement.getContext('2d');
    chartElement.maxHeight = '250'
    const [datasets, labels] = this.generateDatasets()

    this.chart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels,
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              minRotation: 0,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
            offset: true,
          },
          ],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 0,
              max: 100,
              stepSize: 25,
              callback: value => `${value}% `
            },
            gridLines: {
              display: true,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        },
        legend: {
          display: true,
        },
        animation: {
          onComplete: () => {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.font = 'Montserrat, sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.chart.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const metadata = dataset.data[index];
                // eslint-disable-next-line no-underscore-dangle
                ctx.fillText(metadata, bar._model.x, bar._model.y - 5);
              });
            });
          }
        }
      },
      plugins: [
        {
          id: 'custom_canvas_background_color',
          beforeDraw: chart => {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        },
      ]
    })
  }

  generateDatasets() {
    const { data } = this.props
    const labels = Object.keys(data[0])
    const datasets = data.map((group, index) => ({
      fill: false,
      barThickness: 25,
      data: Object.values(group),
      backgroundColor: colors[index],
      label: `Evaluación N°${index + 1}`,
    }))
    return [datasets, labels]
  }

  render() {
    const { classes } = this.props;
    this.generateDatasets()
    return (
      <div className={classes.container}>
        <Typography variant="h2" style={{ marginBottom: 12, fontWeight: 600 }}>Porcentaje de logro por aspecto</Typography>
        <canvas style={{ height: 400, maxHeight: 400 }} id="comparison" ref={this.chartRef} />
      </div>
    );
  }
}

ResultCharts.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
}

ResultCharts.defaultProps = {

}
export default withStyles(style)(ResultCharts)
