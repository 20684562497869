import { createStore, combineReducers, applyMiddleware } from 'redux'
import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import storage from 'redux-persist/lib/storage'
import ReduxPromise from 'redux-promise'
import ReduxLogger from 'redux-logger'
import reducers from './Reducers/Reducers'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2

}

const reducersCombined = combineReducers(reducers)
const pReducer = persistReducer(persistConfig, reducersCombined)
const store = createStore(pReducer, applyMiddleware(ReduxPromise, ReduxLogger))

export default store
