import React, { Component } from 'react'
import { Divider, Paper, Typography, withStyles, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import moment from 'moment'
import autobind from '../../Utils/autobind'
import { getFormEvaluation } from '../../API/Recurrent'
import SetViewContext from '../Contenders/Profile/SetViewProvider'
import { getUserById, retrieveEvaluationImportantInfo } from './ResultComponents/utils'

const style = theme => ({
  container: {
    padding: 24,
    margin: 'auto',
    textAlign: 'left',
    maxWidth: 1200,
  },
  formContainer: {
    padding: 24,
    position: 'relative'
  },
  titleDivider: {
    marginBottom: 24,
    background: theme.palette.primary.main
  },
  question: {
    margin: '12px 0'
  },
  menu: {
    position: 'absolute',
    top: 12,
    right: 12
  },
  hiddenCopyInput: {
    position: 'absolute',
    opacity: 0,
    top: 0,
  },
  evaluationCard: {
    padding: 18,
    display: 'grid',
    textAlign: 'start',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    '& > *': {
      margin: '0 6px'
    }
  },
  evaluations: {
    background: theme.palette.grey.light,
    borderRadius: 8,
    boxShadow: '0px 1px 8px #00000026',
    marginBottom: 32,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 600
  },
  disclaimer: {
    '& > *': {
      margin: '12px 0'
    }
  }
})

class LaboralPerformanceEvaluation extends Component {
  constructor() {
    super()
    this.state = {
      allEvaluations: []
    }
    this.positionQuestionId = ''
    autobind(LaboralPerformanceEvaluation, this)
  }

  async componentDidMount() {
    const { group } = this.props
    const { evaluations = [] } = group

    if (evaluations.length === 0) return null
    // feed params with latest answers
    return this.setState({ allEvaluations: evaluations.filter(ev => ev.status === 'check') }, this.handleGetEvaluationInfo)
  }

  async handleGetEvaluationInfo() {
    const { group } = this.props
    const { allEvaluations } = this.state
    const evaluationsPromises = allEvaluations.map(evaluation => {
      const evaluationBody = { form_id: group.form_group_id, evaluation_id: evaluation.id }
      return getFormEvaluation(evaluationBody)
    })
    const responses = await Promise.all(evaluationsPromises)
    const processedEvaluations = responses.map(response => {
      if (response.data.status !== 'success') return {}
      return retrieveEvaluationImportantInfo(response.data.info)
    })
    return this.setState({ allEvaluations: processedEvaluations })
  }

  handleNewEvaluation() {
    const { group, event, type } = this.props
    const user = getUserById(event.user_id)
    if (!user) return null
    const encriptedName = btoa(user.user.name)
    const url = `/performance/${event.event_id}/${group.form_group_id}/${group.id}/${encriptedName}?type=${type}`
    const path = `${window.location.origin}${url}`
    return window.open(path)
  }

  renderEvaluationRecords() {
    const { allEvaluations = [] } = this.state
    const { classes } = this.props
    return allEvaluations
      .map(evaluation => {
        if (evaluation.name) {
          return (
            <div className={classes.evaluationCard}>
              <Typography variant="subtitle1">
                {`Nombre: ${evaluation?.name || 'Cargando...'}`}
              </Typography>
              <Typography variant="subtitle1">
                {`Cargo: ${evaluation?.position || 'Cargando...'}`}
              </Typography>
              <Typography variant="subtitle1">
                {`Rotación: ${evaluation?.rotation || 'Cargando...'}`}
              </Typography>
              <Typography variant="subtitle1">
                {`Unidad: ${evaluation?.unity || 'Cargando...'}`}
              </Typography>
              <Typography variant="subtitle1">
                {`Fecha: ${moment(evaluation?.date).format('DD/MM/YYYY') || 'Cargando...'}`}
              </Typography>
            </div>
          )
        }
        return (
          <div className={classes.evaluationCard}>
            <Typography variant="subtitle1">Cargando...</Typography>
          </div>
        )
      })
  }

  render() {
    const { classes } = this.props;
    const { allEvaluations } = this.state
    return (
      <div className={classes.container}>
        <Paper className={classes.formContainer}>
          <div className={classes.header}>
            <Typography variant="h3">Evaluación de desempeño laboral</Typography>
            <Button color="primary" variant="contained" onClick={this.handleNewEvaluation}>
              Abrir formulario
            </Button>
          </div>
          <div className={classes.disclaimer}>
            <Typography variant="subtitle1" className={classes.bold}>Para responder la evaluación:</Typography>
            <Typography variant="subtitle1">1 Apretar el botón Abrir formulario</Typography>
            <Typography variant="subtitle1" style={{ paddingLeft: 24 }}>1.1 Esto abrirá una pestaña con un link público</Typography>
            <Typography variant="subtitle1" style={{ paddingLeft: 24 }}>1.2 Enviar a todos los evaluadores/as (incluyendo a la Facilitadora).</Typography>
            <Typography variant="subtitle1">
              2. La facilitadora debe ser la
              {' '}
              <span className={classes.bold}>última</span>
              {' '}
              en responder la evaluación.
            </Typography>
          </div>
          <Divider className={classes.titleDivider} />
          {allEvaluations.length > 0
          && (
            <>
              <Typography variant="h2" style={{ marginBottom: 24 }}>Evaluaciones realizadas:</Typography>
              <div className={classes.evaluations}>
                {this.renderEvaluationRecords()}
              </div>
            </>
          )}
        </Paper>
      </div>
    );
  }
}

LaboralPerformanceEvaluation.contextType = SetViewContext

LaboralPerformanceEvaluation.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  group: propTypes.object,
  type: propTypes.string
}

LaboralPerformanceEvaluation.defaultProps = {
  group: {},
  type: ''
}

export default withStyles(style)(LaboralPerformanceEvaluation)
