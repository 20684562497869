import instance from '../Utils/client'
import createSnackbar from '../Utils/snackbars'
import { getUrlWithQueryString } from '../Utils/functions'

const createExternalCoordinatorUserAction = (body, snackbar) => {
  const REQUEST = instance({
    method: 'post',
    url: '/users/external_coordinator',
    data: body,
  })
  if (snackbar) {
    createSnackbar(REQUEST, snackbar)
  }
  return {
    type: 'CREATE_EXTERNAL_USER',
    payload: REQUEST,
  }
}

const createExternalUserAction = (body, snackbar) => {
  const REQUEST = instance({
    method: 'post',
    url: '/users/external_coordinator',
    data: body,
  })
  if (snackbar) {
    createSnackbar(REQUEST, snackbar)
  }
  return {
    type: 'CREATE_EXTERNAL_USER',
    payload: REQUEST,
  }
}

const createExternalContenderAction = (body, snackbar) => {
  const REQUEST = instance({
    method: 'post',
    url: '/users/contender_external',
    data: body,
  })
  if (snackbar) {
    createSnackbar(REQUEST, snackbar)
  }
  return {
    type: 'CREATE_EXTERNAL_USER',
    payload: REQUEST,
  }
}

const editExternalUserAction = (body, snackbar) => {
  const REQUEST = instance({
    method: 'put',
    url: `/users/${body.user_id}`,
    data: body,
  })
  if (snackbar) {
    createSnackbar(REQUEST, snackbar)
  }
  return {
    type: 'EDIT_EXTERNAL_USER',
    payload: REQUEST,
  }
}

const deleteExternalUserAction = (userId, snackbar) => {
  const REQUEST = instance({
    method: 'delete',
    url: `/users/external/${userId}`,
  })
  if (snackbar) {
    createSnackbar(REQUEST, snackbar)
  }
  return {
    type: 'DELETE_EXTERNAL_USER',
    payload: REQUEST,
  }
}

const loadUsersAction = () => ({
  type: 'LOAD_USERS',
  payload: instance({
    method: 'get',
    url: '/users',
  }),
})

const loadExternalUsersAction = params => {
  const url = getUrlWithQueryString('/users/external', params)
  return {
    type: 'LOAD_EXTERNAL_USERS',
    payload: instance({
      method: 'get',
      url
    }),
  }
}

const loadExternalHealthByUserAction = userId => ({
  type: 'LOAD_EVENT',
  payload: instance({
    method: 'get',
    url: `/users/${userId}/external_ficha`,
  }),
})

const loadExternalCSDByUserAction = userId => ({
  type: 'LOAD_CSD_EXTERNAL',
  payload: instance({
    method: 'get',
    url: `/users/${userId}/external_csd`,
  }),
})

const downloadFichaAction = userId => {
  const url = getUrlWithQueryString('/downloads/ficha_salud', { user_id: userId })
  return {
    type: 'DOWNLOAD_FICHA',
    payload: instance({
      method: 'get',
      url,
    })
  }
}

export {
  createExternalUserAction,
  createExternalCoordinatorUserAction,
  createExternalContenderAction,
  deleteExternalUserAction,
  downloadFichaAction,
  editExternalUserAction,
  loadUsersAction,
  loadExternalCSDByUserAction,
  loadExternalUsersAction,
  loadExternalHealthByUserAction,
}
