import React, { Component } from 'react'
import { withStyles, Zoom } from '@material-ui/core'
import propTypes from 'prop-types'
import { withSnackbar } from 'notistack'

const style = () => ({

})
class AutoSaver extends Component {
  constructor() {
    super()
    this.timer = null
  }

  componentDidMount() {
    const { onSave, enqueueSnackbar, timeout } = this.props
    this.timer = setInterval(() => {
      const showNotistack = onSave()
      if (showNotistack) {
        enqueueSnackbar('Se han guardado los cambios', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          TransitionComponent: Zoom,
          variant: 'info',
          autoHideDuration: 1500
        })
      }
    }, timeout)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container} />
    );
  }
}

AutoSaver.propTypes = {
  classes: propTypes.object.isRequired,
  onSave: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  timeout: propTypes.number
}

AutoSaver.defaultProps = {
  timeout: 10000
}
export default withSnackbar(withStyles(style)(AutoSaver))
