import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

function getColor() {
  return '#1F8095BB'
}

const style = () => ({

})

class CompetenciesChart extends Component {
  componentDidMount() {
    const processedData = this.processData()
    const chartElement = document.getElementById('comps')
    const myChartRef = chartElement.getContext('2d');
    chartElement.parentNode.style.width = '50%';
    chartElement.height = '500'

    const { labels, data } = processedData
    const colors = data.map(getColor)
    const yLabels = ['Sin Apoyo', 'Poco Apoyo', 'Apoyo Ocasional', 'Bastante Apoyo', 'Apoyo Total']

    this.chart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels,
        datasets: [
          {
            data: data.map(d => (d === 1 ? 1.3 : d)),
            barThickness: 12,
            backgroundColor: colors,
            xAxisId: 'xAxis1'
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            id: 'xAxis1',
            type: 'category',
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              min: 1,
              max: 5,
              stepSize: 1,
              minRotation: 30,
              maxRotation: 45,
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            },
            offset: true
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 1,
              max: 5,
              stepSize: 1,
              callback: value => yLabels[value - 1]
            },
            gridLines: {
              display: true,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        },
        legend: {
          display: false
        }
      }
    })
  }

  processData() {
    const { data } = this.props
    const labels = data.map(element => element.name)
    const dataValues = data.map(element => element.total)
    return {
      labels,
      data: dataValues,
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <canvas id="comps" ref={this.chartRef} />
      </div>
    );
  }
}

CompetenciesChart.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.arrayOf(propTypes.object).isRequired
}

CompetenciesChart.defaultProps = {

}
export default withStyles(style)(CompetenciesChart)
