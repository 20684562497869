import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import RecurrentContext from '../RecurrentEvaluations/RecurrentContext'
import RecurrentContainer from '../RecurrentEvaluations/RecurrentContainer'
import autobind from '../../Utils/autobind'
import { createEvaluationGroup, getEvaluationGroup, getEvaluationGroups } from '../../API/Recurrent'
import SetViewContext from '../Contenders/Profile/SetViewProvider'
import { getUserById } from '../Desempeño/ResultComponents/utils'
import EDPSSingleResult from './EDPSSingleResult'
import EDPSCard from './EDPSCard'
import EDPSMultipleResults from './EDPSMultipleResults'

const style = () => ({

})

function handleGetEvaluations(event) {
  return getEvaluationGroups(event)
}

function handleCreate(event) {
  return createEvaluationGroup(event)
}

class LaboralPerformance extends Component {
  constructor() {
    super()
    autobind(LaboralPerformance, this)
  }

  handleClickPending(group) {
    const { history, event, contenders, type } = this.props
    const userId = contenders?.selected?.user?.id
    const user = getUserById(userId)
    if (!user) return null
    const encriptedName = btoa(user.user.name)
    const url = `/edps/${event.event_id}/${event.form_id}/${group.id}/${encriptedName}?type=${type}`
    return history.push(url)
  }

  handleGetResult(group) {
    const { event, contenders, classes, type } = this.props
    const { setView } = this.context
    const userId = contenders?.selected?.user?.id
    event.user_id = userId
    const body = {
      form_id: event.form_id,
      evaluation_group_id: group.id,
    }
    getEvaluationGroup(body).then(response => {
      if (response.data.status !== 'success') return false
      return setView(
        <div className={classes.container}>
          <EDPSSingleResult
            group={response.data.info}
            event={event}
            name={group.name}
            contender={contenders?.selected?.user}
            type={type}
          />
        </div>,
        `Evaluación N°${group.name}`
      )
    })
  }

  render() {
    const { classes, event, contenders, type } = this.props;
    const userId = contenders?.selected?.user?.id
    event.user_id = userId
    const contender = contenders?.selected?.user
    return (
      <div className={classes.container}>
        <RecurrentContext.Provider value={{
          event,
          getEvaluations: handleGetEvaluations,
          getEvaluation: this.handleClickPending,
          getEvaluationResult: this.handleGetResult,
          createNewEvaluation: handleCreate,
          evaluationName: 'Evaluación de Desarrollo Personal y Social',
          createButtonName: 'Nueva Evaluación',
          card: EDPSCard,
          contender,
          type
        }}
        >
          <RecurrentContainer resultTab={EDPSMultipleResults} />
        </RecurrentContext.Provider>
      </div>
    );
  }
}

LaboralPerformance.contextType = SetViewContext

LaboralPerformance.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  type: propTypes.string,
}

LaboralPerformance.defaultProps = {
  type: 'normal'
}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default withRouter(connect(mapStateToProps)(withStyles(style)(LaboralPerformance)))
