import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import FlexibleInput from '../../Utils/FieldRenderer'
import autobind from '../../Utils/autobind'

const style = theme => ({
  container: {
    textAlign: 'start'
  },
  section: {
    margin: '24px 0 12px 0',
    '& > h2': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  question: {
    margin: '6px 0'
  },
  input: {
    maxWidth: 300
  }
})

function getText(user, question) {
  if (user === '0') return question.text
  if (user === '1') return question.companion_text
  return question.handler_text
}

class EDPSDisableEvaluation extends Component {
  constructor() {
    super()
    this.state = {
      evaluationType: '0'
    }
    autobind(EDPSDisableEvaluation, this)
  }

  handleChangeEvaluation(event) {
    const { target } = event
    this.setState({ evaluationType: target.value })
  }

  renderForm() {
    const { evaluation, classes, user, type } = this.props
    const { evaluationType } = this.state
    if (!evaluation) return null
    const mainForm = evaluation[`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`].empty
    const mainKeys = Object.keys(mainForm).filter(key => key !== 'empty')
    return mainKeys.map(key => {
      const questions = mainForm[key].filter(question => {
        if (evaluationType === '0') return true
        if (evaluationType === '1') return !!question.inico
        if (evaluationType === '2') return !question.inico
        return false
      }).map((question, index) => {
        const name = question?.answers[0]?.id
        const info = { ...question }
        info.name = name
        info.text = `${index + 1} - ${getText(user, question)}`
        return (
          <div className={classes.question}>
            <FlexibleInput
              complete={info}
              value={question?.answers[0]?.body?.answer}
              disabled
            />
          </div>
        )
      })
      if (questions.length === 0) return null
      return (
        <div className={classes.section}>
          <Typography variant="h2">{key}</Typography>
          <Divider />
          {questions}
        </div>
      )
    })
  }

  render() {
    const { classes, evaluation, type } = this.props
    const mainForm = evaluation[`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`].empty
    const name = mainForm?.empty?.[0]?.answers?.[0]?.body?.answer
    return (
      <div className={classes.container}>
        <Typography variant="h1">{name}</Typography>
        {this.renderForm()}
      </div>
    );
  }
}

EDPSDisableEvaluation.propTypes = {
  classes: propTypes.object.isRequired,
  evaluation: propTypes.object.isRequired,
  user: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
}

EDPSDisableEvaluation.defaultProps = {

}
export default withStyles(style)(EDPSDisableEvaluation)
