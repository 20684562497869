import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

const style = () => ({
  container: {
    '& > h2': {
      marginBottom: 24,
      fontWeight: 600
    },
    height: 400
  }
})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

function getDatasets(data) {
  if (!data) return [[], []]
  const keys = Object.keys(data).filter(key => key !== 'average')
  const labels = keys
  const datasetsValues = [[], []]
  let i = 0
  while (i < 2) {
    const index = i
    keys.forEach(key => {
      datasetsValues[index].push(data[key][index])
    })
    i += 1
  }

  const pointLabels = ['Autoevaluación', 'Familiar']
  const datasets = datasetsValues.map((datas, index) => ({
    data: datas,
    backgroundColor: `${colors[index]}`,
    borderColor: colors[index],
    label: pointLabels[index],
    barThickness: 16
  }))

  return [
    labels,
    datasets
  ]
}

class CriteriaBarChart extends Component {
  componentDidMount() {
    const { data } = this.props
    const chartElement = document.getElementById('baredps')
    const myChartRef = chartElement.getContext('2d');
    chartElement.height = '400'
    const [labels, datasets] = getDatasets(data)
    // eslint-disable-next-line no-new
    this.chart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels,
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            offset: true
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 0,
              max: 100,
              stepSize: 10,
              offset: true
            },
            gridLines: {
              display: true,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        },
        legend: {
          display: true,
          labels: {
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
            fontColor: 'black',
            fontSize: 12,
            boxWidth: 36
          },
        },
        animation: {
          onComplete: () => {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.font = 'Montserrat, sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.chart.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const metadata = dataset.data[index];
                // eslint-disable-next-line no-underscore-dangle
                ctx.fillText(metadata, bar._model.x, bar._model.y - 5);
              });
            });
          }
        }
      },
      plugins: [
        {
          id: 'custom_canvas_background_color',
          beforeDraw: chart => {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        },
      ]
    })
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (prevProps.data !== data) {
      const [labels, datasets] = getDatasets(data)
      this.chart.data.labels = labels
      this.chart.data.datasets = datasets
      this.chart.update()
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h2">
          Resultados por ámbito
        </Typography>
        <canvas id="baredps" />
      </div>
    );
  }
}

CriteriaBarChart.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
}

CriteriaBarChart.defaultProps = {

}
export default withStyles(style)(CriteriaBarChart)
