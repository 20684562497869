import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

const style = () => ({
  container: {
    '& > h2': {
      marginBottom: 24,
      fontWeight: 600
    },
    height: 250
  }
})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

class LineChartUser extends Component {
  componentDidMount() {
    const chartElement = document.getElementById('lineLevels')
    const myChartRef = chartElement.getContext('2d');
    chartElement.height = '250'

    this.chart = new Chart(myChartRef, {
      type: 'line',
      data: {
        // Bring in data
        labels: [],
        datasets: [
          {
            fill: false,
            data: [],
            backgroundColor: colors,
            radius: 6
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              minRotation: 0,
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            offset: true,
          },
          ],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 0,
              max: 100,
              stepSize: 25,
              offset: true
            },
            gridLines: {
              display: true,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        },
        legend: {
          display: false
        },
        animation: {
          onComplete: () => {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.font = 'Montserrat, sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.fillStyle = 'black'

            this.chart.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const metadata = dataset.data[index];
                // eslint-disable-next-line no-underscore-dangle
                ctx.fillText(metadata, bar._model.x, bar._model.y - 8);
              });
            });
          }
        }
      },
      plugins: [
        {
          id: 'custom_canvas_background_color',
          beforeDraw: chart => {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        },
      ]
    })
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (prevProps.data !== data) {
      const datasets = [
        {
          fill: false,
          data: data.map(element => element.average),
          backgroundColor: colors,
          radius: 6
        },
      ]
      this.chart.data.labels = data.map((_, index) => `EDPS ${index + 1}`)
      this.chart.data.datasets = datasets
      this.chart.update()
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h2">Progreso de evaluaciones</Typography>
        <canvas id="lineLevels" ref={this.chartRef} />
      </div>
    );
  }
}

LineChartUser.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
}

LineChartUser.defaultProps = {

}
export default withStyles(style)(LineChartUser)
