import React from 'react'
import { MenuItem } from '@material-ui/core'
import { getAllCommunes } from '../../API/Communes'

/* eslint-disable import/prefer-default-export */
export const parseOptionsType = async optionType => {
  if (optionType === 'communes') {
    const communeResponse = await getAllCommunes()
    if (communeResponse?.data?.status !== 'success') return []
    const communes = communeResponse.data.info
    return communes.map(commune => (
      <MenuItem value={commune.id.toString()} key={commune.id.toString()}>
        {commune.name}
      </MenuItem>
    ))
  }
  return []
}
