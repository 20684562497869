import React, { Component } from 'react'
import { Typography, Checkbox } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers/'
import propTypes from 'prop-types'
import moment from 'moment'
import Comment from './Comment'
import autobind from '../../Utils/autobind'

export default class DateInterval extends Component {
  constructor(props) {
    super(props)
    autobind(DateInterval, this)
  }

  handleChange(incomingValue) {
    const { onChange, complete, value } = this.props
    const valueDate = incomingValue.format('MM-DD-YYYY')
    const checkvalue = value.split('&')[0] || '0'
    const endValue = value.split('&')[2] || moment().format('MM-DD-YYYY')
    const result = [checkvalue, valueDate, endValue].join('&')
    const event = { target: { name: complete.name, value: result } }
    onChange(event)
  }

  handleChangeSecond(incomingValue) {
    const { onChange, complete, value } = this.props
    const valueDate = incomingValue.format('MM-DD-YYYY')
    const checkvalue = value.split('&')[0] || '0'
    const startValue = value.split('&')[1] || moment().format('MM-DD-YYYY')
    const result = [checkvalue, startValue, valueDate].join('&')
    const event = { target: { name: complete.name, value: result } }
    onChange(event)
  }

  handleClickBox() {
    const { onChange, complete, value } = this.props
    const checkvalue = value.split('&')[0]
    let finalValue = '0'
    if (checkvalue !== '1') {
      finalValue = '1'
    }
    const startValue = value.split('&')[1] || moment().format('MM-DD-YYYY')
    const endValue = value.split('&')[2] || moment().format('MM-DD-YYYY')
    const result = [finalValue, startValue, endValue].join('&')
    const event = { target: { name: complete.name, value: result } }
    onChange(event)
  }

  render() {
    const { value, comment, complete, onComment, disabled, highlighted } = this.props
    const checkValue = value ? value.split('&')[0] : '0'
    const validDateStart = value ? moment(value.split('&')[1]) : moment()
    const validDateEnd = value ? moment(value.split('&')[2]) : moment()
    return (
      <>
        <div
          style={{ gridArea: `area-${complete.id}`, display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 8 }}
          className={highlighted ? 'highlighted' : ''}
        >
          <div style={{ flexGrow: 1 }}>
            <Typography style={{ fontWeight: complete.bold ? 600 : 400, color: complete.bold ? '#3E3E3E' : '#707070' }}>{complete.start_label}</Typography>
            <KeyboardDatePicker
              autoOk
              // disablePast
              fullWidth
              InputProps={{
                style: { fontSize: '0.8rem', padding: '0 6px', },
                fullWidth: true,
              }}
              format="D [de] MMMM [del] YYYY"
              value={validDateStart}
              style={{ background: '#00000017' }}
              onChange={this.handleChange}
              disabled={disabled}

            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <Typography style={{ fontWeight: complete.bold ? 600 : 400, color: complete.bold ? '#3E3E3E' : '#707070' }}>{complete.end_label}</Typography>
            <KeyboardDatePicker
              autoOk
              // disablePast
              fullWidth
              InputProps={{
                style: { fontSize: '0.8rem', padding: '0 6px', },
                fullWidth: true,
              }}
              format="D [de] MMMM [del] YYYY"
              value={validDateEnd}
              style={{ background: '#00000017' }}
              onChange={this.handleChangeSecond}
              disabled={checkValue === '1' || disabled}
            />
          </div>
        </div>
        {complete.has_box
          && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}>
              <Checkbox onClick={this.handleClickBox} color="primary" checked={checkValue === '1'} disabled={disabled} />
              <Typography>{complete.has_box}</Typography>
            </div>
          )}
        {complete.has_comment
          && (
            <Comment
              onChange={onComment}
              value={comment}
              name={complete.name}
              area={`comment-${complete.id}`}
              commentLabel={complete.comment_label}
              disabled={disabled}
            />
          )}
      </>
    )
  }
}

DateInterval.propTypes = {
  onChange: propTypes.func.isRequired,
  onComment: propTypes.func,
  value: propTypes.string,
  comment: propTypes.string,
  complete: propTypes.object.isRequired,
  disabled: propTypes.bool,
  highlighted: propTypes.bool
}

DateInterval.defaultProps = {
  value: '',
  comment: '',
  onComment: undefined,
  disabled: false,
  highlighted: false
}
