/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react'
import { Paper, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { withRouter } from 'react-router'
import ReportTabs from './ReportTabs'
import Past from './Past/Past'
import Evaluations from './Evaluations/Evaluations'
import ReportContext from './ReportProvider'
import autobind from '../../../../Utils/autobind'
import { createAuthorizationAction } from '../../../../Actions/Authorizations'
import { getReportAnswersAction,
  getReportInfoAction,
  saveReportAnswerAction,
  getProfileResultsAction,
  getReportResultsAction } from '../../../../Actions/Contenders'
import FinalDialog from './FinalDialog'

const style = () => ({
  container: {
    textAlign: 'left'
  }
})

class ResultReportContainer extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      savedAnswers: {},
      values: {}
    }
    autobind(ResultReportContainer, this)
  }

  componentDidMount() {
    const { getReportAnswers,
      contender,
      getReportInfo,
      getReportResults,
      getProfileResults,
      match } = this.props
    const body = { contender_id: contender.contender.id }
    const { id } = match.params
    const reportBody = { id }
    getReportResults(reportBody).then(
      response => this.setState({ values: response?.payload?.data?.info || {} })
    )
    getProfileResults({ c_id: id })
    getReportAnswers(body).then(() => {
      this.setupAnswers()
    })
    getReportInfo(body)
  }

  handleBlur(answerId) {
    return () => {
      const { saveReportAnswer, contender } = this.props
      const { savedAnswers } = this.state
      const body = {
        contender_id: contender.contender.id,
        report_answer_id: answerId,
        request_body: { body: { answer: savedAnswers[answerId] } }
      }
      saveReportAnswer(body)
    }
  }

  handleChange(answerId) {
    return event => {
      const { savedAnswers } = this.state
      const newAnswers = { ...savedAnswers }
      newAnswers[answerId] = event.target.value
      this.setState({ savedAnswers: newAnswers })
    }
  }

  handleOpenDialog() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  handleSubmit(body) {
    const { createAuthorization, match } = this.props
    const { id } = match.params
    const requestBody = { ...body, contender_id: id }
    createAuthorization(requestBody)
  }

  setupAnswers() {
    const { reportAnswers } = this.props
    const data = reportAnswers?.report_answers || {}
    const answers = Object.keys(data).reduce((acc, key) => {
      const sectionAnswers = data[key]
      sectionAnswers.forEach(answer => {
        acc[answer.id] = answer.body ? answer.body.answer : ''
      })
      return acc
    }, {})
    this.setState({ savedAnswers: answers })
  }

  render() {
    const { classes, reportAnswers, reportInfo, contender } = this.props;
    const { open, savedAnswers, values } = this.state
    return (
      <Paper className={`${classes.container} print-no-border`}>
        <img src="/logo_borde.png" alt="logo" height="64px" className="only-print" />
        <ReportTabs
          disabled={contender?.contender?.state_type >= 2}
          handleOpenDialog={this.handleOpenDialog}
        >
          <ReportContext.Provider value={{
            handleChange: this.handleChange,
            handleBlur: this.handleBlur }}
          >
            {reportInfo
              && (
                <>
                  <Past answers={reportAnswers} values={savedAnswers} reportInfo={reportInfo} />
                  <div className="only-print">
                    <Evaluations values={values} />
                  </div>
                </>
              )}
          </ReportContext.Provider>
          <ReportContext.Provider value={{
            handleChange: this.handleChange,
            handleBlur: this.handleBlur }}
          >
            <div className="only-print">
              <Past answers={reportAnswers} values={savedAnswers} reportInfo={reportInfo} />
            </div>
            <Evaluations values={values} />
          </ReportContext.Provider>
        </ReportTabs>
        <FinalDialog open={open} onClose={this.handleOpenDialog} onSubmit={this.handleSubmit} />
      </Paper>
    );
  }
}

ResultReportContainer.propTypes = {
  classes: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  reportAnswers: propTypes.arrayOf(propTypes.object).isRequired,
  reportInfo: propTypes.arrayOf(propTypes.object).isRequired,
  getReportAnswers: propTypes.func.isRequired,
  saveReportAnswer: propTypes.func.isRequired,
  getReportInfo: propTypes.func.isRequired,
  getReportResults: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  getProfileResults: propTypes.func.isRequired,
  createAuthorization: propTypes.func.isRequired
}

ResultReportContainer.defaultProps = {}

const mapStateToProps = state => ({
  reportAnswers: state.contenders.reportAnswers,
  reportInfo: state.contenders.reportInfo,
  contender: state.contenders.selected,
})

const mapDispatchToProps = dispatch => ({
  getReportAnswers: body => dispatch(getReportAnswersAction(body)),
  getReportInfo: body => dispatch(getReportInfoAction(body)),
  saveReportAnswer: body => dispatch(saveReportAnswerAction(body)),
  getReportResults: body => dispatch(getReportResultsAction(body)),
  getProfileResults: body => dispatch(getProfileResultsAction(body)),
  createAuthorization: body => dispatch(createAuthorizationAction(body))
})

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(withStyles(style)(ResultReportContainer)))
