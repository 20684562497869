import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { abreviateCriteria, calculatePercentiles, calculateTotalPercentile, criteriaReduced } from './utils'

const style = theme => ({
  container: {
    marginRight: 24,
    minWidth: 400,
    '& > h2': {
      fontWeight: 600,
      margin: '24px 0'
    }
  },
  table: {
    margin: 12,
    marginBottom: 6
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '6px 0',
    '& > *': {
      color: '#3E3E3E'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '6px 0'
  },
  total: {
    background: '#ECECEC',
    paddingLeft: 12,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 12,
    '& > h6': {
      color: theme.palette.primary.main,
    },
    '& > h6:first-child': {
      fontSize: 15,
      color: '#3E3E3E'
    }
  },
  percentile: {
    background: '#ECECEC',
    padding: '6px 24px',
    borderRadius: 8,
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 13,
    color: '#3E3E3E'
  }
})

class PercentileTable extends Component {
  render() {
    const { classes, questions, user } = this.props;
    const percentiles = calculatePercentiles(questions, user)
    return (
      <div className={classes.container}>
        <Typography variant="h2">Percentil de la familia</Typography>
        <div className={classes.table}>
          <div className={classes.firstRow}>
            <Typography variant="subtitle1">Dimensión</Typography>
            <Typography variant="subtitle1">Percentil</Typography>
          </div>
          {criteriaReduced.map(criterium => (
            <div className={classes.row} key={criterium}>
              <Typography variant="subtitle1">{abreviateCriteria(criterium)}</Typography>
              <Typography variant="subtitle1" className={classes.percentile}>{percentiles[criterium]}</Typography>
            </div>
          ))}
        </div>
        <div className={classes.total}>
          <Typography variant="subtitle1">Total</Typography>
          <Typography variant="subtitle1" className={classes.percentile}>{calculateTotalPercentile(questions, user)}</Typography>
        </div>
      </div>
    );
  }
}

PercentileTable.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  questions: propTypes.arrayOf(propTypes.object).isRequired
}

PercentileTable.defaultProps = {

}
export default withStyles(style)(PercentileTable)
