import AccountCircle from '@material-ui/icons/AccountCircle'
import Add from '@material-ui/icons/Add'

export const weekDays = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo']
export const MuiIconsMap = {
  AccountCircle,
  Add,
}
export const AUTHORIZATION_TYPES = [
  { label: 'Cambio de estado', value: 0 },
  { label: 'Nuevo programa', value: 1 },
]

export const EFD_COMMENT_TEMPLATE = '✏️ Cálculos matemáticos\n\n✏️ Orientación espacial\n\n✏️ Comprensión lectora\n\n✏️ Potencial físico'
