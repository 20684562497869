/* eslint-disable import/no-cycle */
import React, { Component } from 'react'
import { withStyles, MenuItem, Typography } from '@material-ui/core'
import MuiSelect from '@material-ui/core/Select'
import propTypes from 'prop-types'
import _ from 'lodash'
import Comment from './Comment'
import { renderSubquestion } from '../../Utils/FieldRenderer'
import autobind from '../../Utils/autobind'
import { parseOptionsType } from './OptionsParser'

const style = () => ({
  container: {
    padding: 0,
  },
  select: {
    padding: '4px 0 6px 6px',
    color: 'black'
  },
})

class Select extends Component {
  constructor() {
    super()
    this.state = {
      dynamicOptions: [],
    }
    autobind(Select, this)
  }

  async handleParseOptions(options) {
    const { dynamicOptions } = this.state
    if (dynamicOptions.length > 0) return true
    const newDynamicOptions = await parseOptionsType(options)
    return this.setState({ dynamicOptions: newDynamicOptions })
  }

  checkIfShoudShowSubquestions() {
    const { complete, value } = this.props
    const { subquestions } = complete
    if (!subquestions) return [false, complete]
    if (!complete) return [false, complete]
    if (complete.expected_value !== 'multiple') return [complete?.expected_value?.toString()?.split('&')?.includes(value), complete]
    const newComplete = { ...complete }
    const newSubquestions = subquestions[value]
    if (!newSubquestions) return [false, complete]
    newComplete.subquestions = newSubquestions
    return [true, newComplete]
  }

  renderOptions() {
    const { complete } = this.props
    const { dynamicOptions } = this.state
    const { options } = complete
    if (typeof options === 'string') {
      this.handleParseOptions(options)
      return dynamicOptions
    }
    if (!Array.isArray(options)) return []
    return options.map((option, index) => {
      const isObject = _.isObject(option)
      if (isObject) {
        return (
          <MenuItem value={option.value.toString()} key={option.value.toString()}>
            {option.label}
          </MenuItem>
        )
      }
      return (
        <MenuItem value={index.toString()} key={index.toString()}>
          {option}
        </MenuItem>
      )
    })
  }

  render() {
    const {
      classes,
      onChange,
      value,
      onComment,
      comment,
      complete,
      disabled,
      highlighted, hideTitle, onSubchange, subquestionsAnswers, answered, multiple
    } = this.props
    const [shouldShow, processedComplete] = this.checkIfShoudShowSubquestions()
    return (
      <>
        <div className={highlighted ? 'highlighted' : ''} style={{ gridArea: `area-${complete.id}` }}>
          {!hideTitle && <Typography>{complete.text}</Typography>}
          <MuiSelect
            multiple={multiple}
            value={value}
            onChange={onChange}
            name={complete.name}
            fullWidth
            variant="filled"
            classes={{ select: classes.select }}
            style={{ borderRadius: 0 }}
            disabled={disabled}
          >
            {this.renderOptions()}
          </MuiSelect>
        </div>
        {shouldShow
            && renderSubquestion(
              processedComplete, onSubchange, subquestionsAnswers, disabled, answered
            )}
        {complete.has_comment && (
          <Comment onChange={onComment} value={comment} name={complete.name} area={`comment-${complete.id}`} disabled={disabled} />
        )}
      </>
    )
  }
}

Select.propTypes = {
  classes: propTypes.object.isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.string,
  comment: propTypes.string,
  onComment: propTypes.func,
  onSubchange: propTypes.func,
  complete: propTypes.object.isRequired,
  subquestionsAnswers: propTypes.object,
  disabled: propTypes.bool,
  hideTitle: propTypes.bool,
  answered: propTypes.bool,
  highlighted: propTypes.bool,
  multiple: propTypes.bool,
}

Select.defaultProps = {
  value: '',
  onComment: undefined,
  comment: '',
  onSubchange: undefined,
  subquestionsAnswers: {},
  disabled: false,
  highlighted: false,
  hideTitle: false,
  answered: false,
  multiple: false
}

export default withStyles(style)(Select)
