/* eslint-disable import/prefer-default-export */
import instance from '../Utils/client'
import { callSnackbar } from '../Utils/snackbars'

export const createAuthorization = async (body, action) => {
  /**
   * body: { type_id: <int>, text: <string>, extra_info: <object>}
   * action: <string> used to describe auth action on snackbar (e.g. "eliminar")
   */
  const response = await instance({
    method: 'post',
    url: 'authorizations',
    data: body
  })
  if (response.data.status === 'success') { callSnackbar(`Autorización para ${action} creada`, 'success') }
  return response
}

export const getAuthorizations = body => instance({
  method: 'get',
  url: 'authorizations',
  params: body
})
