import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import DownloadsContainer from 'Components/Downloads/DownloadsContainer'
import PhysicalEvaluation from 'Components/PhysicalPotential/PhysicalEvaluation'
import { grantPermission } from '../Utils/functions'
import Status from '../Components/Status/Status'
import GatheringResults from '../Components/Results/POI/Results'
import Calendar from '../Components/Calendar/Calendar'
import Contenders from '../Components/Contenders/All/Contenders'
import Profile from '../Components/Handlers/Profile'
import Settings from '../Components/Settings/Settings'
import ExternalUsers from '../Components/Settings/ExternalUsers/index'
import Login from '../Components/Login/Login'
import ConfirmationList from '../Components/Gathering/ConfirmationList'
import Attendance from '../Components/Gathering/Attendance'
import NewContender from '../Components/Contenders/NewContender'
import Gathering from '../Components/Gathering/Gathering'
import Gencat from '../Components/Gencat/Gencat'
import GencatFamiliar from '../Components/GencatFamiliar/GencatFamiliar'
import Authorizations from '../Components/Authorizations/Authorizations'
import CompanionResult from '../Components/Gathering/Companion/Result'
import ContenderProfile from '../Components/Contenders/Profile/Profile'
import Register from '../Components/Login/Register'
import ExternalLogin from '../Components/Login/ExternalUserLogin'
import Dashboard from '../Components/Dashboard/Dashboard'
import Companions from '../Components/Gathering/Companion/Companions'
import History from '../Components/History/History'
import HomeGencat from '../Components/Gencat/HomeGencat'
import HandlersList from '../Components/Handlers/HandlersList'
import AvdParticipant from '../Components/Gathering/Companion/AvdParticipant'
import CompanionInterview from '../Components/Results/Individuals/Interviews/CompanionInterview'
import FunctionalEvaluation from '../Components/FunctionalEvaluation/FunctionalEvaluation'
import WorkProfile from '../Components/FunctionalEvaluation/Results/WorkProfile'
import SupportLevel from '../Components/FunctionalEvaluation/Results/SupportLevel'
import MultipleResults from '../Components/FunctionalEvaluation/Multiple/MultipleResults'
import Enterprises from '../Components/Enterprises/Enterprises'
import Programs from '../Components/Programs/Programs'
import TemporalTest from '../Components/RecurrentEvaluations/TemporalTest'
import LaboralPerformanceEvaluationLink from '../Components/Desempeño/LaboralPerformanceEvaluationLink'
import AutoevaluationForm from '../Components/Autoevaluation/AutoevaluationForm'
import SelectUser from '../Components/EDPS/SelectUser'
import EDPSEvaluation from '../Components/EDPS/EDPSEvaluation'
import EditProfile from '../Components/Settings/Profile/EditProfile'
import RoleManagement from '../Components/Settings/RoleManagement/RoleManagement'
import Unauthorized from '../Shared/Unauthorized'
import FamiliarGencatForm from '../Components/GencatFamiliar/FamiliarGencatForm'
import ReviewGencat from '../Components/GencatFamiliar/ReviewGencat'

class Routes extends Component {
  componentDidMount() {
    // Check if user is admin here or has any permission
  }

  render() {
    const { user } = this.props
    return (
      <Switch>
        <Route exact path="/register" component={Register} />
        <Route exact path="/external" component={ExternalLogin} />
        <Route path="/events/:id/gencat_familiar/form" component={FamiliarGencatForm} user={user} />
        <PrivateRoute path="/contenders/:id" component={ContenderProfile} user={user} />
        <PrivateRoute path="/contenders" component={Contenders} user={user} />
        <PrivateRoute path="/handlers" component={HandlersList} user={user} />
        <PrivateRoute path="/status" component={Status} user={user} />
        <PrivateRoute path="/authorizations" component={Authorizations} user={user} />
        <PrivateRoute path="/dashboard" component={Dashboard} user={user} />
        <PrivateRoute path="/calendar" component={Calendar} user={user} />
        <PrivateRoute path="/downloads" component={DownloadsContainer} user={user} />
        <PrivateRoute path="/settings/profile" component={EditProfile} user={user} />
        <PrivateRoute path="/settings/external-users" component={ExternalUsers} user={user} />
        <PrivateRoute path="/settings/role-management" component={RoleManagement} user={user} roles={['director']} />
        <PrivateRoute path="/settings" component={Settings} user={user} />
        <PrivateRoute path="/events/:id/result/:form_id" component={GatheringResults} user={user} noHeader />
        <PrivateRoute path="/events/:id/confirmation" component={ConfirmationList} user={user} />
        <PrivateRoute path="/events/:id/attendance/:formId" component={Attendance} user={user} />
        <PrivateRoute path="/events/:id/attendance" component={Attendance} user={user} />
        <PrivateRoute path="/events/:event_id/gencat/:contender_id" component={HomeGencat} user={user} />
        <PrivateRoute path="/events/:id/gencat" component={Gencat} user={user} />
        <PrivateRoute path="/events/:id/gencat_familiar" component={GencatFamiliar} user={user} />
        <PrivateRoute path="/events/:id/gencat_familiar_review/:user_id" component={ReviewGencat} user={user} />
        <PrivateRoute path="/events/:id/gathering" component={Gathering} user={user} noHeader />
        <PrivateRoute path="/events/:id/functional/:formId/result/profile" component={WorkProfile} user={user} noHeader />
        <PrivateRoute path="/events/:id/functional/:formId/result/support" component={SupportLevel} user={user} noHeader />
        <PrivateRoute path="/events/:id/functional/:formId/results" component={MultipleResults} user={user} noHeader />
        <PrivateRoute path="/events/:id/functional/:formId" component={FunctionalEvaluation} user={user} noHeader />
        <PrivateRoute
          path="/events/:id/companion/:contender_id/result"
          component={CompanionResult}
          user={user}
          noHeader
        />
        <Route path="/jornada-acompañante" component={Companions} />
        <Route path="/entrevista" component={CompanionInterview} />
        <Route path="/eavd" component={AvdParticipant} />
        <Route path="/performance/:event_id/:form_id/:group_id/:cont_name" component={LaboralPerformanceEvaluationLink} />
        <Route path="/autoevaluation/:event_id/:form_id/:evaluation_id/:cont_name" component={AutoevaluationForm} />
        <Route path="/edps/evaluation/:event_id/:group_id/:evaluation_id/:form_id/:cont_name/:user_type" component={EDPSEvaluation} />
        <Route path="/edps/:event_id/:form_id/:group_id/:cont_name" component={SelectUser} />
        <Route path="/physical/:event_id/:form_id/:evaluation_id/:cont_name" component={PhysicalEvaluation} />

        <Route path="/mail/fichagencat/:event_id/:contender_id" component={HomeGencat} />

        <PrivateRoute path="/history" component={History} user={user} />
        <PrivateRoute path="/enterprises" component={Enterprises} user={user} />
        <PrivateRoute path="/programs" component={Programs} user={user} />
        <PrivateRoute path="/handler" component={Profile} user={user} />
        <PrivateRoute path="/newContender" component={NewContender} user={user} />
        <PrivateRoute path="/temp" component={TemporalTest} user={user} />
        <PrivateRoute path="/*" component={Calendar} user={user} />
      </Switch>
    )
  }
}

const ROLES = ['admin', 'handler', 'director']
function PrivateRoute({ component: comp, user, path, roles }) {
  if (user.current && user.current.id && user.current.role) {
    const hasPermission = grantPermission(user.current, roles)
    if (hasPermission) {
      return (
        <>
          <Route exact path={path} component={comp} />
        </>
      )
    }
    return <Unauthorized />
  }
  return <Route exact path={path} component={Login} />
}

PrivateRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.object, propTypes.func]).isRequired,
  user: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
  roles: propTypes.arrayOf(propTypes.string)
}

PrivateRoute.defaultProps = {
  roles: ROLES
}

Routes.propTypes = {
  user: propTypes.object.isRequired,
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps)(Routes)
