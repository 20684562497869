import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { getMultipleFunctionalResultsAction } from '../../../Actions/FunctionalEvaluaction'
import EvaluationParticipants from '../EvaluationParticipants'
import autobind from '../../../Utils/autobind'
import WorkProfile from './WorkProfile'
import SupportLevel from './SupportLevel'

const style = () => ({
  container: {
    display: 'flex',
    padding: 24,
    paddingBottom: 0
  }
})

class MultipleResults extends Component {
  constructor() {
    super()
    this.state = {
      selectedParticipant: '',
      option: 0
    }

    autobind(MultipleResults, this)
  }

  componentDidMount() {
    const { event, match, getResults, events } = this.props
    const body = {}
    if (event === null) {
      body.id = match?.params.id
      body.form_id = match?.params.formId
    } else {
      body.id = event.event_id
      body.form_id = event.form_id
    }
    getResults(body)
    this.setState({ selectedParticipant: events.selected.participants[0].id })
  }

  handleSelectParticipant(selectedParticipant) {
    return () => {
      this.setState({ selectedParticipant })
    }
  }

  handleChangeView(view) {
    return () => {
      this.setState({ option: view })
    }
  }

  render() {
    const { classes, events } = this.props
    const { selectedParticipant, option } = this.state
    return (
      <div className={classes.container}>
        <EvaluationParticipants
          participants={events.selected.participants}
          selected={selectedParticipant}
          onSelect={this.handleSelectParticipant}
          withMenu={false}
          withSubmit={false}
          // onSubmit={this.handleSend}
        />
        {selectedParticipant !== '' && (
          <>
            {option === 0
              ? (
                <WorkProfile
                  userId={selectedParticipant}
                  handleChangeView={this.handleChangeView(1)}
                />
              )
              : (
                <SupportLevel
                  userId={selectedParticipant}
                  handleChangeView={this.handleChangeView(0)}
                />
              )}
          </>
        )}
      </div>
    );
  }
}

MultipleResults.propTypes = {
  classes: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  event: propTypes.object,
  getResults: propTypes.func.isRequired,
  events: propTypes.object.isRequired
}

MultipleResults.defaultProps = {
  event: null
}

const mapStateToProps = state => ({
  contenders: state.contenders,
  events: state.events
})

const mapDispatchToProps = dispatch => ({
  getResults: body => dispatch(getMultipleFunctionalResultsAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(MultipleResults))
