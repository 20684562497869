import React, { Component } from 'react'
import { withStyles, Paper, Typography, TextField, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { registerAction, logoutAction } from '../../Actions/Login'

const style = theme => ({
  background: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `linear-gradient(${114}deg, ${theme.palette.grey.dark}, ${
      theme.palette.grey.dark
    })`,
  },
  container: {
    padding: 12,
    width: '50%',
    height: '70%',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12,
  },
  title: {
    color: theme.palette.confirm.main,
    margin: '5% 0 10% 0',
    fontSize: 24,
  },
  content: {
    width: '50%',
    margin: 'auto',
  },
})

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleRegister = this.handleRegister.bind(this)
    this.callback = this.callback.bind(this)
  }

  componentDidMount() {
    const { logout } = this.props
    logout()
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    const newParams = { ...params }
    newParams[target.name] = target.value
    this.setState({ params: newParams })
  }

  handleRegister() {
    const { register, enqueueSnackbar } = this.props
    const { params } = this.state
    const body = { ...params }
    register(body, enqueueSnackbar, this.callback)
  }

  callback() {
    const { history } = this.props
    history.push('/login')
  }

  render() {
    const { classes } = this.props
    const { params } = this.state
    return (
      <div className={classes.background}>
        <Paper className={classes.container}>
          <Typography variant="h1" className={classes.title}>
            Registra tu cuenta
          </Typography>
          <div className={classes.content}>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ textAlign: 'left', marginBottom: 8 }}
            >
              Correo
            </Typography>
            <TextField
              fullWidth
              name="email"
              value={params.email || ''}
              inputProps={{ style: { padding: '6px 0 7px 6px' } }}
              InputProps={{ style: { borderRadius: 0 } }}
              onChange={this.handleChange}
              variant="filled"
              style={{ marginBottom: 16 }}
            />
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ textAlign: 'left', marginBottom: 8 }}
            >
              Contraseña
            </Typography>
            <TextField
              fullWidth
              type="password"
              name="password"
              variant="filled"
              inputProps={{ style: { padding: '6px 0 7px 6px' } }}
              InputProps={{ style: { borderRadius: 0 } }}
              value={params.password || ''}
              onChange={this.handleChange}
              style={{ marginBottom: 16 }}
            />
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ textAlign: 'left', marginBottom: 8 }}
            >
              Nombre
            </Typography>
            <TextField
              fullWidth
              name="name"
              value={params.name || ''}
              inputProps={{ style: { padding: '6px 0 7px 6px' } }}
              InputProps={{ style: { borderRadius: 0 } }}
              onChange={this.handleChange}
              variant="filled"
              style={{ marginBottom: 16 }}
            />
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ textAlign: 'left', marginBottom: 8 }}
            >
              Rut
            </Typography>
            <TextField
              fullWidth
              name="rut"
              value={params.rut || ''}
              inputProps={{ style: { padding: '6px 0 7px 6px' } }}
              InputProps={{ style: { borderRadius: 0 } }}
              onChange={this.handleChange}
              variant="filled"
              style={{ marginBottom: 16 }}
            />
            <div className={classes.button}>
              <Button
                color="secondary"
                variant="contained"
                style={{ fontWeight: 600 }}
                onClick={this.handleRegister}
              >
                Completar Registro
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  register: (body, snackbar, callback) => dispatch(registerAction(body, snackbar, callback)),
  logout: () => dispatch(logoutAction()),
})

Register.propTypes = {
  classes: propTypes.object.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  register: propTypes.func.isRequired,
  history: propTypes.object.isRequired,
  logout: propTypes.func.isRequired,
}

export default withSnackbar(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(style)(Register))
)
