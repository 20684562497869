import React, { Component, Fragment } from 'react'
import { MenuItem, Select, Tooltip, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { Help } from '@material-ui/icons'
import SliderInput from '../../Shared/Fields/SliderInput'
import FunctionalContext from './NewFormContext'

const style = theme => ({
  container: {
    textAlign: 'left'
  },
  questionTitle: {
    margin: '24px 0',
    fontSize: 14,
    color: '#3e3e3e',
    textDecorationLine: 'underline',
    width: '90%',
  },
  skills: {
    paddingLeft: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  skill: {
    display: 'flex',
    gap: '25px',
    flexWrap: 'wrap',
    padding: '15px 0',
    '&>:nth-child(1)': {
      minWidth: '350px',
      maxWidth: '450px',
      flexGrow: 1,
    },
    '&>:nth-child(2)': {
      minWidth: '250px',
      maxWidth: '450px',
      flexGrow: 1,
    }
  },
  select: {
    padding: '4px 0 6px 6px',
    color: 'black'
  },
  selectTitle: {
    marginBottom: 6
  },
  selectContainer: {
    maxWidth: '50%'
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  help: {
    color: theme.palette.secondary.main,
    height: 24,
    width: 24
  },
})

function getQuestionTitle(question) {
  const text = Object.keys(question).filter(q => q !== 'type')[0]
  return text.split('&')
}

function getQuestionSkills(question) {
  const title = getQuestionTitle(question)
  const skills = Object.entries(question[title.join('&')])
  const processedSkills = skills.map(([skill, entry]) => ({
    id: entry.id,
    text: skill,
    name: entry.id,
    bold: true,
    has_comment: true,
    steps: [
      { value: 1, label: 'Sin Apoyo', index: 0 },
      { value: 2, label: 'Poco Apoyo', index: 1 },
      { value: 3, label: 'Apoyo Ocasional', index: 2 },
      { value: 4, label: 'Bastante Apoyo', index: 3 },
      { value: 5, label: 'Apoyo Total', index: 4 },
    ]
  }))
  return processedSkills
}

function renderQuestion(question, classes, indexList, context) {
  const { params, onChange, onComment, comments } = context
  const skills = getQuestionSkills(question)
  const [title, tooltip] = getQuestionTitle(question)
  indexList.push(1)
  return (
    <Fragment key={indexList.length}>
      <div className={classes.inline}>
        <Typography variant="subtitle2" className={classes.questionTitle}>{`${indexList.length} - ${title}`}</Typography>
        {tooltip
        && (
          <Tooltip title={tooltip}>
            <Help className={classes.help} />
          </Tooltip>
        )}
      </div>
      <div className={classes.skills}>
        {skills.map(skill => (
          <div className={classes.skill} key={skill.id}>
            <SliderInput
              complete={skill}
              onChange={onChange}
              value={params[skill.name] || ''}
              onComment={onComment}
              comment={comments[skill.name]}
              commentHeight={3}
            />
          </div>
        ))}
      </div>
    </Fragment>
  )
}

class QuestionRenderer extends Component {
  renderOriginalQuestion() {
    const { question, classes, indexList } = this.props
    const { params, onChange, onComment, comments, selectedParticipant } = this.context
    if (question.type === 'plain') {
      const skills = getQuestionSkills(question)
      const [title, tooltip] = getQuestionTitle(question)
      indexList.push(1)
      return (
        <div className={classes.skillContainer}>
          <div className={classes.inline}>
            <Typography variant="body1" className={classes.questionTitle}>{`${indexList.length} - ${title}`}</Typography>
            {tooltip
            && (
              <Tooltip title={(
                <>
                  <Typography color="inherit">{tooltip}</Typography>
                </>
              )}
              >
                <Help className={classes.help} />
              </Tooltip>
            )}
          </div>
          <div className={classes.skills}>
            {skills.map(skill => (
              <div className={classes.skill} key={skill.id}>
                <SliderInput
                  complete={skill}
                  onChange={onChange}
                  value={params[skill.name] || ''}
                  onComment={onComment}
                  comment={comments[skill.name]}
                  commentHeight={3}
                />
              </div>
            ))}
          </div>
        </div>
      )
    }
    const selectName = `noanswer-${selectedParticipant}-${question.options.join('&')}`
    const subquestions = question[params[selectName]] || {}
    const isExcelActivity = !!question.options.map(option => option.includes('Excel')).filter(Boolean).length
    const isWordActivity = !!question.options.map(option => option.includes('en computador')).filter(Boolean).length
    let text = 'Seleccionar actividad'
    if (isExcelActivity) { text = 'Seleccionar actividad en Excel' }
    if (isWordActivity) { text = 'Seleccionar actividad en Word' }
    return (
      <>
        <Typography variant="subtitle1" className={classes.selectTitle}>{text}</Typography>
        <div className={classes.selectContainer}>
          <Select
            value={params[selectName] || ''}
            onChange={onChange}
            name={selectName}
            displayEmpty
            variant="filled"
            classes={{ select: classes.select }}
            fullWidth
          >
            <MenuItem value="" disabled>Seleccionar actividad</MenuItem>
            {question.options
              .map(option => <MenuItem value={option} key={option}>{option}</MenuItem>)}
          </Select>
        </div>
        {Object.entries(subquestions)
          .map(([questionName, skills]) => (renderQuestion(
            { [questionName]: skills }, classes, indexList, this.context
          )))}
      </>
    )
  }

  render() {
    const { classes, question } = this.props;
    return (
      <div className={classes.container}>
        {this.renderOriginalQuestion(question)}
      </div>
    );
  }
}

QuestionRenderer.propTypes = {
  classes: propTypes.object.isRequired,
  question: propTypes.object,
  indexList: propTypes.arrayOf(propTypes.number),
}

QuestionRenderer.defaultProps = {
  question: {},
  indexList: [1],
}

QuestionRenderer.contextType = FunctionalContext
export default withStyles(style)(QuestionRenderer)
