import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = theme => ({
  title: {
    '& > h2': {
      fontWeight: 600,
      fontSize: 16
    },
    marginTop: 24
  },
  question: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '3px 0'
  },
  questionText: {
    fontWeight: 600,
    color: '#1a1a1a'
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    fontSize: 18,
    margin: '12px 0 6px 0'
  },
  divider: {
    background: theme.palette.primary.main
  },
  big: {
    height: 2
  }
})
class HealthFormResult extends Component {
  renderSingleQuestion(question) {
    const { classes } = this.props
    const answer = question?.answers.body ? question.answers.body.answer : null
    switch (question.type) {
      case 'title': {
        return (
          <div className={classes.question}>
            <Typography variant="subtitle2" className={classes.sectionTitle}>{question.text}</Typography>
          </div>
        )
      }
      case 'radio': {
        return (
          <>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
              <Typography variant="subtitle2">{question.options[answer]}</Typography>
            </div>
          </>
        )
      }
      default: {
        return (
          <div className={classes.question}>
            <Typography variant="subtitle1" className={classes.questionText}>{question.text}</Typography>
            <div className={classes.answer}>
              <Typography variant="subtitle1">{question?.answers.body ? question.answers.body.answer : ''}</Typography>
            </div>
          </div>
        )
      }
    }
  }

  renderQuestions(questions) {
    return questions.map(question => this.renderSingleQuestion(question))
  }

  render() {
    const { classes, form, title } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h2">{title}</Typography>
          <Divider className={`${classes.divider} ${classes.big}`} />
        </div>
        {this.renderQuestions(form)}
      </div>
    );
  }
}

HealthFormResult.propTypes = {
  classes: propTypes.object.isRequired,
  form: propTypes.object.isRequired,
  title: propTypes.string
}

HealthFormResult.defaultProps = {
  title: 'Titulo'
}
export default withStyles(style)(HealthFormResult)
