import instance from '../Utils/client'
import createSnackbar from '../Utils/snackbars'

const loadUserTasksAction = id => ({
  type: 'LOAD_USER_TASKS',
  payload: instance({
    method: 'get',
    url: `/users/${id}/tasks`,
  }),
})

const checkUserTaskAction = (userId, taskId, snackbar = null) => {
  const REQUEST = instance({
    method: 'post',
    url: `/users/${userId}/tasks/${taskId}/check`,
  })
  if (snackbar) {
    createSnackbar(REQUEST, snackbar)
  }
  return {
    type: 'CHECK_USER_TASK',
    payload: REQUEST
  }
}

const createTaskAction = (userId, task, snackbar = null) => {
  const REQUEST = instance({
    method: 'post',
    url: `/users/${userId}/tasks`,
    data: task,
  })
  if (snackbar) {
    createSnackbar(REQUEST, snackbar)
  }
  return {
    type: 'CREATE_USER_TASK',
    payload: REQUEST,
  }
}

export { loadUserTasksAction, checkUserTaskAction, createTaskAction }
