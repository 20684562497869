import stateMaker from '../Utils/reducers'

const historyReducer = (state = { all: [] }, action) => {
  switch (action.type) {
    case 'LOAD_USER_HISTORIES':
      return stateMaker(state, 'selected', 'fetch', action)
    case 'SEARCH_USER_HISTORIES':
      return stateMaker(state, 'search', 'fetch', action)
    default:
      return state
  }
}

export default historyReducer
