import React, { Component } from 'react'
import { Button, Dialog, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { NoteAdd } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { TextField } from '../../Shared/Fields/Text'
import DateInput from '../../Shared/Fields/DateInput'
import RadioGroup from '../../Shared/Fields/RadioGroup'
import SelectEntity from '../../Shared/Fields/SelectEntity'
import DialogHeader from '../../Shared/Dialogs/DialogHeader'
import DialogButtons from '../../Shared/Dialogs/DialogButtons'

const style = () => ({
  container: {
    padding: 24,
  },
  form: {
    '& > *': {
      margin: '6px 0'
    }
  }
})

class ProgramDialog extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    autobind(ProgramDialog, this)
  }

  componentDidMount() {
    const { selected } = this.props
    this.setState({
      params: {
        start_month: moment(new Date()).format('MM-DD-YYYY'),
        end_month: moment(new Date()).add(1, 'months').format('MM-DD-YYYY')
      }
    })
    if (selected !== null) {
      const newParams = { ...selected }
      this.setState({ params: newParams })
    }
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props
    if (prevProps.selected !== selected) {
      const newParams = { ...selected }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ params: newParams })
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleSubmit() {
    const { onSubmit } = this.props
    const { params } = this.state
    const body = { ...params }
    onSubmit(body)
    this.setState({ params: {} })
  }

  render() {
    const { classes, open, onClose, option, enterprises, communes } = this.props;
    const { params } = this.state
    const allEnterprises = enterprises.all || []
    const allCommunes = communes.all || []
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <DialogHeader icon={NoteAdd} title={`${option} Programa`} onClose={onClose} />
          <div className={classes.form}>
            <TextField complete={{ text: 'Nombre', name: 'name' }} onChange={this.handleChange} value={params.name} />
            <SelectEntity complete={{ options: allEnterprises, text: 'Empresa', name: 'enterprise_id' }} onChange={this.handleChange} value={params.enterprise_id} />
            <SelectEntity complete={{ options: allCommunes, text: 'Comuna', name: 'commune_id' }} onChange={this.handleChange} value={params.commune_id} />
            <DateInput complete={{ text: 'Mes de inicio', name: 'start_month' }} onChange={this.handleChange} value={params.start_month} />
            <DateInput complete={{ text: 'Mes de término', name: 'end_month' }} onChange={this.handleChange} value={params.end_month} />
            <RadioGroup complete={{ columns: '1fr', options: ['ConFuturo', 'ConInclusión'], text: 'Tipo de programa', name: 'program_type' }} onChange={this.handleChange} value={params.program_type} />
          </div>
          <DialogButtons>
            <Button onClick={onClose}>
              Volver
            </Button>
            <Button onClick={this.handleSubmit} color="primary" variant="contained">
              {option}
            </Button>
          </DialogButtons>
        </div>
      </Dialog>
    );
  }
}

ProgramDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  option: propTypes.string,
  selected: propTypes.object,
  enterprises: propTypes.arrayOf(propTypes.object).isRequired,
  communes: propTypes.arrayOf(propTypes.object).isRequired,
}

ProgramDialog.defaultProps = {
  open: false,
  option: null,
  selected: null
}

const mapStateToProps = state => ({
  enterprises: state.enterprises
})

export default connect(mapStateToProps)(withStyles(style)(ProgramDialog))
