export const genderData = [
  { label: 'Femenino', value: 60, color: '#48A4B0 ' },
  { label: 'Masculino', value: 48, color: '#D5602C' }
]

export const genderOptions = {
  height: '200px',
  width: '100%',
  barThickness: 64,
  enableHover: false,
  detachedLabels: true,
  minValue: 0,
  maxValue: 100,
  stepSize: 1,
  yEnableGrid: true,
}

export const statusOptions = {
  height: '200px',
  width: '100%',
  barThickness: 64,
  enableHover: false,
  detachedLabels: true,
  minValue: 0,
  maxValue: 100,
  stepSize: 1,
  yEnableGrid: true,
}

export const comunesOptions = {
  barThickness: 20,
  height: '250px',
  width: '100%',
  enableHover: false,
  detachedLabels: false,
  minValue: 0,
  maxValue: 60,
  stepSize: 2,
  xEnableGrid: false,
  yEnableGrid: true,
  yHasTitle: true,
  yTitle: 'N° Trabajadores'
}

export const typeOptions = {
  height: '230px',
  width: '100%',
  type: 'pie',
  detachedLabels: true,
  percentages: true
}

export const contractOptions = {
  height: '200px',
  width: '100%',
  detachedLabels: true,
}

export const profileOptions = {
  height: '200px',
  width: '100%',
  type: 'pie',
  detachedLabels: true,
  percentages: false
}

export const hoursOptions = {
  height: '250px',
  width: '100%',
  enableHover: false,
  detachedLabels: true,
  minValue: 0,
  maxValue: 30,
  stepSize: 10,
  xEnableGrid: false,
  xHasTitle: true,
  xTitle: 'Horas Semanales',
  yHasTitle: true,
  yTitle: 'N° Trabajadores'
}

export const agesOptions = {
  height: '250px',
  width: '100%',
  enableHover: false,
  detachedLabels: false,
  minValue: 0,
  maxValue: 60,
  stepSize: 1,
  xEnableGrid: false,
  yEnableGrid: true,
  yHasTitle: true,
  yTitle: 'N° Trabajadores',
}

export const radarOptions = {
  color: '#fb83688c',
  maxTicksLimit: 6,
}
