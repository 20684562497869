import stateMaker from '../Utils/reducers'

const taskReducer = (state = { all: [] }, action) => {
  switch (action.type) {
    case 'LOAD_USER_TASKS':
      return stateMaker(state, 'selected', 'fetch', action)
    case 'CREATE_USER_TASK':
      return stateMaker(state, 'selected', 'create', action, { unshift: true })
    case 'CHECK_USER_TASK':
      return stateMaker(state, 'selected', 'edit', action)
    default:
      return state
  }
}

export default taskReducer
