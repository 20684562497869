import React, { Component } from 'react'
import { Dialog, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import './animation.css'

const style = () => ({
  container: {
    width: 250,
    height: 250,
    position: 'relative'
  }
})

class LoaderDialog extends Component {
  render() {
    const { classes, open } = this.props;
    return (
      <Dialog open={open}>
        <div className={classes.container}>
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </Dialog>
    );
  }
}

LoaderDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool
}

LoaderDialog.defaultProps = {
  open: false
}

export default withStyles(style)(LoaderDialog)
