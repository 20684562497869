/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react'
import { Button, Divider, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router'
import { MultilineTextField } from '../../../Shared/Fields/Text'
import ProfilesTable from '../Results/ProfilesTable'
import autobind from '../../../Utils/autobind'
import MixedChart from '../../../Shared/Charts/MixedChart'
import BarChart from '../../../Shared/Charts/BarChart'
import ProfilePercentage from '../Results/ProfilePercentage'
import { getFunctionalResultsAction } from '../../../Actions/FunctionalEvaluaction'
import LoaderDialog from '../../../Shared/LoaderDialog'
import { editEvaluationComment, getEvaluationComment } from '../../../API/Evaluations'
import { EFD_COMMENT_TEMPLATE } from '../../../Utils/constants'

const profileColors = {
  Administrativo: 'rgba(31, 128, 149)',
  'Atención al cliente': 'rgba(251, 131, 104)',
  Operario: 'rgba(255, 179, 61)',
  'Servicios de apoyo': 'rgba(56, 180, 169)',
}

const options = {
  detachedLabels: false,
  yEnableGrid: true,
  minValue: 0,
  height: '400px',
  maxValue: 5,
  stepSize: 1,
  callback: value => {
    const labels = ['Apoyo Total', 'Bastante Apoyo', 'Apoyo Ocasional', 'Poco Apoyo', 'Sin Apoyo', ''].reverse()
    return labels[value]
  },
  width: '100%',
  minRotation: 45,
}

const barOptions = {
  detachedLabels: false,
  yEnableGrid: true,
  minValue: 0,
  maxValue: 7,
  stepSize: 1,
  height: '350px',
  width: '100%',
  minRotation: 45,
}

const style = theme => ({
  container: {
    margin: '0 12px 12px',
    padding: 12,
    textAlign: 'left',
    flexGrow: 1
  },
  title: {
    textAlign: 'left',
    fontSize: 16
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 48
  },
  sectionDivider: {
    display: 'flex',
    gap: '18px',
    marginBottom: 6
  },
  divider: {
    flexGrow: 1,
    background: '#6a6a6a',
    opacity: 0.7
  },
  readyButton: {
    textAlign: 'end'
  },
  finalSupportLevel: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: 24
  },
  supportLevel: {
    padding: '6px 18px',
    background: theme.palette.grey.light,
    textAlign: 'center',
    borderRadius: 6
  },
  bySkill: {
    margin: '36px 0'
  },
  finalCharts: {
    display: 'flex',
    gap: '2%',
    '&>*': {
      width: '48%',
      minWidth: '400px',
      maxWidth: '700px',
      flexGrow: 1,
      marginTop: 24,
    },
    flexWrap: 'wrap'
  },
  chartTitle: {
    fontWeight: 600,
    color: theme.palette.grey.dark
  }
})
class WorkProfile extends Component {
  constructor() {
    super()
    this.state = {
      profiles: [],
      chartData: null,
      profileChart: null,
      percentages: null,
      comment: '',
      profilesDesviation: [],
    }
    autobind(WorkProfile, this)
  }

  async componentDidMount() {
    this.getProfiles()
    this.setupChart()
    this.getProfilesDesviation()
    this.setupProfileChart()
    this.setupPercentages()

    const { match, userId } = this.props
    const commentBody = {
      event_id: match.params.id,
      form_id: match.params.formId,
      user_id: userId
    }
    const response = await getEvaluationComment(commentBody)
    if (response.data.status !== 'success') return null
    return this.setState({ comment: response.data.info.comment })
  }

  componentDidUpdate(prevProps) {
    const { functional, userId } = this.props
    const prevValue = prevProps?.functional?.result[userId]
    const { result } = functional
    if (result[userId] !== prevValue || userId !== prevProps.userId) {
      this.componentDidMount()
      // eslint-disable-next-line react/no-unused-state
      // eslint-disable-next-line react/no-did-update-set-state
    }
  }

  handleRedirect() {
    const { history, match } = this.props
    const newUrl = match.url.split('/')
    newUrl.pop()
    newUrl.push('support')
    const finalUrl = newUrl.join('/')
    history.push(finalUrl)
  }

  handleChangeComment(event) {
    const { target } = event
    this.setState({ comment: target.value })
  }

  handleSendComment() {
    const { match, userId } = this.props
    const { comment } = this.state
    const body = {
      event_id: match.params.id,
      form_id: match.params.formId,
      user_id: userId,
      comment
    }
    editEvaluationComment(body)
  }

  getProfilesDesviation() {
    const { functional, userId } = this.props
    const { result } = functional
    const data = Object.keys(profileColors).map(profileName => ({
      name: profileName,
      desv: result[userId]?.result_detail[profileName]
    }))
    this.setState({ profilesDesviation: data })
  }

  getProfiles() {
    const { functional, userId } = this.props
    const { result } = functional
    // const test = result[userId]?.competencies
    //   .map(competencie => (
    //     { name: competencie.name,
    //       values: Object.values(result[userId]?.quals_details[competencie.name]) }
    //   ))
    // this.setState({ profiles: test, comment: functional.result[userId]?.comment })

    // const { functional } = this.props
    // const { result } = functional
    const test = result[userId]?.competencies.map(competencie => (
      { name: competencie.name, values: result[userId]?.quals_details[competencie.name] }
    ))
    this.setState({ profiles: test, comment: functional.result[userId]?.comment })
  }

  setupChart() {
    const { functional, userId } = this.props
    const { result } = functional
    const profilesData = {}
    const profiles = result[userId]?.profiles || []
    profiles.forEach(profile => {
      profilesData[profile.name] = profile.competencies.map(c => c.ideal_score)
    })
    const competenciesLabels = []
    const competenciesValues = []
    const competencies = result[userId]?.competencies || []
    competencies.forEach(c => {
      competenciesLabels.push(c.name)
      competenciesValues.push(c.total)
    })
    const chartData = {
      labels: competenciesLabels,
      datasets: [
        {
          type: 'line',
          label: 'Usuario',
          data: competenciesValues,
          backgroundColor: 'rgba(112, 112, 112, 0.7)',
          borderColor: '#707070',
        },
        {
          label: 'Administrativo',
          backgroundColor: 'rgba(31, 128, 149,0.8)',
          data: profilesData.Administrativo,
          type: 'bar'
        },
        {
          label: 'Atención al cliente',
          backgroundColor: 'rgba(251, 131, 104,0.8)',
          data: profilesData['Atención al cliente'],
          type: 'bar'
        },
        {
          label: 'Operario',
          backgroundColor: 'rgba(255, 179, 61, 0.8)',
          data: profilesData.Operario,
          type: 'bar'
        },
        {
          label: 'Servicios de apoyo',
          backgroundColor: 'rgba(56, 180, 169,0.8)',
          data: profilesData['Servicios de apoyo'],
          type: 'bar'
        },
      ]
    }
    this.setState({ chartData })
  }

  setupProfileChart() {
    const { functional, userId } = this.props
    const { result } = functional
    if (!result[userId]) return this.setState({ profileChart: [] })
    const profileChart = Object
      .entries(result[userId]?.result_detail).map(([profileName, value]) => (
        { label: profileName, value, color: profileColors[profileName] }
      ))
    return this.setState({ profileChart })
  }

  setupPercentages() {
    const { functional, userId } = this.props
    const { result } = functional
    if (!result[userId]) return this.setState({ percentages: {} })
    const percentages = result[userId]?.profile_adjust
    return this.setState({ percentages })
  }

  render() {
    const { classes, functional, contenders, userId, handleChangeView } = this.props
    const { profiles,
      comment,
      chartData,
      profilesDesviation,
      profileChart,
      percentages,
    } = this.state
    const { result } = functional
    const selectedContenderName = contenders
      .all.find(c => c.user.id.toString() === userId)?.user?.name

    if (!result[userId]) {
      return (
        <LoaderDialog open />
      )
    }
    return (
      <Paper className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h1" className={classes.title}>Resultados evaluación Funcional Diagnóstica</Typography>
          <Button variant="outlined" color="secondary" onClick={handleChangeView}>
            Ver Niveles de Apoyo
          </Button>
        </div>
        <div className={classes.sectionDivider}>
          <Typography variant="h1">Perfil de Competencias</Typography>
          <Divider className={classes.divider} />
        </div>
        <div className={classes.commentContainer}>
          <Typography variant="caption">
            Agregar comentarios sobre
            {' '}
            <span style={{ fontWeight: 600 }}>{selectedContenderName}</span>
          </Typography>
          <MultilineTextField onChange={this.handleChangeComment} value={comment ||  EFD_COMMENT_TEMPLATE} />
          <div className={classes.readyButton}>
            <Button size="small" onClick={this.handleSendComment}>
              Listo
            </Button>
          </div>
        </div>
        <div className={classes.finalSupportLevel}>
          <Typography variant="h2">Perfil sugerido</Typography>
          <div className={classes.supportLevel}>
            <Typography variant="h2">{functional.result[userId]?.suggested_profile}</Typography>
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '40% auto', gap: 36 }}>
          <div>
            <div>
              <Typography style={{ margin: '12px 0', color: '#3E3E3E', fontWeight: 900, fontSize: 15 }}> Resultados de notas por perfil </Typography>
            </div>
            {profileChart && <BarChart name="quals-result" data={profileChart} options={barOptions} showTitle={false} />}
          </div>
          <div>
            <Typography style={{ margin: '12px 0', color: '#3E3E3E', fontWeight: 900, fontSize: 15 }}> Porcentaje de ajuste a los perfiles </Typography>
            {percentages && Object.entries(percentages).map(([profileName, percentage]) => (
              <ProfilePercentage
                key={profileName}
                title={profileName}
                percentage={percentage}
                barColor={profileColors[profileName]}
              />
            ))}
          </div>
        </div>
        {chartData && (
          <div style={{ width: '100%' }}>
            <MixedChart name="mixed" data={chartData} options={options} showTitle={false} />
          </div>
        )}
        <Typography variant="h2">Comparación de Perfiles</Typography>
        <ProfilesTable data={profiles} desviations={profilesDesviation} />
      </Paper>
    );
  }
}

WorkProfile.propTypes = {
  classes: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  userId: propTypes.string.isRequired,
  functional: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  handleChangeView: propTypes.func.isRequired,
}

WorkProfile.defaultProps = {
}

const mapStateToProps = state => ({
  functional: state.functional,
  contenders: state.contenders,
})

const mapDispatchToProps = dispatch => ({
  getResults: body => dispatch(getFunctionalResultsAction(body)),
})
export default withRouter(withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(WorkProfile))
))
