import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

function getColor(value) {
  if (value === 5) return '#D5602C'
  if (value > 3) return '#FFB33D'
  return '#6A6A6A'
}

const style = () => ({

})

class AbilitySupportChart extends Component {
  componentDidMount() {
    const processedData = this.processData()
    const chartElement = document.getElementById('abilities')
    const myChartRef = chartElement.getContext('2d');
    chartElement.parentNode.style.width = '100%';
    chartElement.height = '900'

    const { labels, data } = processedData
    const colors = data.map(getColor)
    const yLabels = ['Sin Apoyo', 'Poco Apoyo', 'Apoyo Ocasional', 'Bastante Apoyo', 'Apoyo Total']
    let actualLabel = ''

    this.chart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels,
        datasets: [
          {
            data: data.map(d => (d === 1 ? 1.3 : d)),
            barThickness: 12,
            backgroundColor: colors,
            xAxisId: 'xAxis1'
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            id: 'xAxis1',
            type: 'category',
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              min: 1,
              max: 5,
              stepSize: 1,
              minRotation: 90,
              maxRotation: 90,
              callback: label => label.split('&')[1]
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            },
            offset: true
          }, {
            id: 'xAxis2',
            type: 'category',
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              min: 1,
              max: 5,
              stepSize: 1,
              minRotation: 90,
              maxRotation: 90,
              callback: label => {
                const labelToUse = label.split('&')[0]
                if (actualLabel === labelToUse) return ''
                actualLabel = labelToUse
                return labelToUse
              },
              padding: 12
            },
            gridLines: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
              drawBorder: false
            },
            offset: true,
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 1,
              max: 5,
              stepSize: 1,
              callback: value => yLabels[value - 1]
            },
            gridLines: {
              display: true,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        },
        legend: {
          display: false
        }
      }
    })
  }

  processData() {
    const { data } = this.props
    const competencies = data.map(element => element.name)
    const labelsArrays = data.map(element => element.skills.map(skill => `${element.name}&${skill.name}`))
    const dataValuesArrays = data.map(element => element.skills.map(skill => skill.total))
    const labels = labelsArrays.reduce((x, y) => x.concat(y), [])
    const dataValues = dataValuesArrays.reduce((x, y) => x.concat(y), [])
    return {
      labels,
      data: dataValues,
      competencies
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <canvas id="abilities" ref={this.chartRef} />
      </div>
    );
  }
}

AbilitySupportChart.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.arrayOf(propTypes.object).isRequired
}

AbilitySupportChart.defaultProps = {

}
export default withStyles(style)(AbilitySupportChart)
