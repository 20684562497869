import React from 'react'
import propTypes from 'prop-types'
import { DatePicker } from '@material-ui/pickers/'

const CustomDatePicker = props => {
  const { onChange, value, FieldComponent, inputClassName, emptyLabel, ...otherProps } = props
  return (
    <DatePicker
      disableToolbar
      autoOk
      variant="inline"
      fullWidth
      InputProps={{
        disableUnderline: true,
        style: { fontSize: '0.8rem' },
        fullWidth: true,
      }}
      inputProps={{
        className: inputClassName
      }}
      emptyLabel={emptyLabel}
      TextFieldComponent={FieldComponent || undefined}
      format="D [de] MMMM [del] YYYY"
      value={value}
      onChange={onChange}
      {...otherProps}
    />
  )
}

CustomDatePicker.propTypes = {
  onChange: propTypes.func.isRequired,
  value: propTypes.object.isRequired,
  FieldComponent: propTypes.object,
  inputClassName: propTypes.string,
  emptyLabel: propTypes.string
}

CustomDatePicker.defaultProps = {
  FieldComponent: null,
  inputClassName: undefined,
  emptyLabel: 'Seleccionar fecha'
}

export default CustomDatePicker
