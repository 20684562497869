/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
// import { MoreVert } from '@material-ui/icons'
import { connect } from 'react-redux'

const style = theme => ({
  container: {
    position: 'relative',
    width: window.innerWidth > 800 ? '20%' : '25%',
    margin: '0 0 12px 0',
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  participant: {
    position: 'relative',
    padding: '6px 0',
    cursor: 'pointer',
    zIndex: 0,
    margin: '12px 0',
    transition: 'all 0.2s ease-in-out',
    '&::before': {
      content: '""',
      transition: 'all 0.2s ease-in-out',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 6,
      height: '100%',
      borderRadius: 6,
      zIndex: -1
    },
    '&.selected:before': {
      content: '""',
      transition: 'all 0.2s ease-in-out',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      borderRadius: 6,
      zIndex: -1
    },
    '&.selected>h6': {
      color: 'white'
    }
  },
  indicator1: {
    '&::before': {
      background: theme.palette.confirm.main,
    }
  },
  indicator2: {
    '&::before': {
      background: '#b6b6b6',
    }
  },

  submitButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 50,
    letterSpacing: 0,
    color: '#3E3E3E',
    fontSize: 12,
  }

})
class EvaluationParticipants extends Component {
  getIndicatorColor(participant) {
    const { classes, user } = this.props
    if (participant.allocation === user.current.id) {
      return classes.indicator1
    }
    return classes.indicator2
  }

  renderParticipants() {
    const { participants, classes, onSelect, selected, user } = this.props
    const myParticipants = participants.filter(p => p.allocation === user.current.id)
    const notMyParticipants = participants.filter(p => p.allocation !== user.current.id)
    const finalParticipants = myParticipants.concat(notMyParticipants)
    return finalParticipants.map(participant => (
      <div
        className={`${classes.participant} ${this.getIndicatorColor(participant)} ${selected === participant.id ? 'selected' : ''}`}
        onClick={onSelect(participant.id)}
        role="button"
        key={participant.id}
      >
        <Typography variant="subtitle1" key={participant}>{participant.name}</Typography>
      </div>
    ))
  }

  render() {
    const { classes, onSubmit, withSubmit } = this.props
    return (
      <Paper className={classes.container}>
        <div className={classes.inline}>
          <Typography variant="subtitle1" style={{ padding: 24, fontSize: 14 }}>
            Participantes
          </Typography>
        </div>
        {this.renderParticipants()}
        {withSubmit && (
          <Button className={classes.submitButton} onClick={onSubmit}>
            Finalizar Evaluacion
          </Button>
        )}
      </Paper>
    )
  }
}

EvaluationParticipants.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  participants: propTypes.arrayOf(propTypes.oneOfType([propTypes.object, propTypes.string])),
  onSelect: propTypes.func,
  onSubmit: propTypes.func,
  selected: propTypes.oneOfType([propTypes.object, propTypes.string]),
  withSubmit: propTypes.bool,
}

EvaluationParticipants.defaultProps = {
  participants: [],
  onSelect: selected => () => console.log(selected),
  selected: '',
  onSubmit: () => console.log('Submit'),
  withSubmit: true
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(withStyles(style)(EvaluationParticipants))
