import React, { Component } from 'react'
import {
  Paper,
  withStyles,
  ListItem,
  Typography,
  Checkbox,
  IconButton
} from '@material-ui/core'
import { CheckBox } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { withTheme } from '@material-ui/core/styles'
import propTypes from 'prop-types'
import autobind from '../../Utils/autobind'

const styles = () => ({
  container: {
    overflowY: 'auto',
    textAlign: 'left',
    maxHeight: 500,
  },
  listItem: {
    padding: '6px 12px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  event: {
    background: '#ebebeb',
    margin: '16px 12px',
    borderRadius: 5,
    boxShadow: '0px 1px 5px #534bae6e',
  },
  task: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 9fr',
    alignItems: 'center'
  },
  checked: {
    color: 'red',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 1,
    padding: 12
  }
})

class Tasks extends Component {
  constructor(props) {
    super(props)
    autobind(Tasks, this)
  }

  handleCheckTask(taskId) {
    const { checkTask } = this.props
    return () => {
      checkTask(taskId)
    }
  }

  renderTasks() {
    const { classes, tasks, theme } = this.props
    return (
      tasks
      && tasks.map(task => (
        <div className={classes.event} key={task.id} elevation={3}>
          <ListItem
            key={task.key}
            button
            className={classes.listItem}
          >
            <div className={classes.task}>
              <div style={{ gridColumn: '1 / 2' }}>
                <Checkbox
                  onClick={this.handleCheckTask(task.id)}
                  checked={task.checked}
                  checkedIcon={(
                    <CheckBox
                      style={{ color: theme.palette.confirm.main }}
                    />
                  )}
                />
              </div>
              <div style={{ gridColumn: '2 / 10' }}>
                <Typography variant="subtitle1">
                  {task.body}
                </Typography>
              </div>
            </div>
          </ListItem>
        </div>
      ))
    )
  }

  render() {
    const { classes, style, handleOpenForm } = this.props
    return (
      <Paper className={classes.container} style={style}>
        <div className={classes.title}>
          <div>
            <Typography variant="subtitle1" color="primary">Tareas</Typography>
          </div>
          <div>
            <IconButton onClick={handleOpenForm} size="small"><AddIcon /></IconButton>
          </div>
        </div>
        {this.renderTasks()}
      </Paper>
    )
  }
}

Tasks.propTypes = {
  classes: propTypes.object.isRequired,
  style: propTypes.object.isRequired,
  theme: propTypes.object.isRequired,
  tasks: propTypes.arrayOf(propTypes.object),
  handleOpenForm: propTypes.func.isRequired,
  checkTask: propTypes.func.isRequired
}

Tasks.defaultProps = {
  tasks: [],
}

export default withTheme(withStyles(styles)(Tasks))
