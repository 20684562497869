import React, { Component } from 'react'
import propTypes from 'prop-types'
import { withStyles, Paper, Typography, Button } from '@material-ui/core'
import moment from 'moment'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import Guest from './Guest'
import { sendConfirmationAction } from '../../Actions/Handler'

const style = () => ({
  container: {
    padding: 12,
    width: '40vw',
    background: 'white',
    textAlign: 'left',
    marginLeft: 12,
  },
  title: {
    textAlign: 'left',
    marginBottom: 12,
  },
  radioBtns: {
    display: 'flex',
  },
  button: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 12
  },
})

class ConfirmationList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmed: [],
      confirmedIds: [],
      open: -1,
    }

    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleSend = this.handleSend.bind(this)
    this.handleSendAndConfirm = this.handleSendAndConfirm.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.callback = this.callback.bind(this)
  }

  componentDidMount() {
    const { confirmed, confirmedIds } = this.state
    const newConfirmed = [...confirmed]
    const newConfirmedIds = [...confirmedIds]
    const { events } = this.props
    const { selected } = events
    selected.participants.forEach(guest => {
      if (guest.confirmed) {
        newConfirmed.push(guest)
        newConfirmedIds.push(guest.id)
      } else if (guest.confirm_observation !== null) {
        newConfirmed.push(guest)
        newConfirmedIds.push(guest.id)
      }
    })
    this.setState({ confirmed: newConfirmed, confirmedIds: newConfirmedIds })
  }

  componentDidUpdate(prevProps) {
    const { events } = this.props
    if (prevProps.events.selected !== events.selected) {
      const { confirmed, confirmedIds } = this.state
      const newConfirmed = [...confirmed]
      const newConfirmedIds = [...confirmedIds]
      const { selected } = events
      selected.participants.forEach(guest => {
        if (guest.confirmed) {
          newConfirmed.push(guest)
          newConfirmedIds.push(guest.id)
        } else if (guest.confirm_observation !== null) {
          newConfirmed.push(guest)
          newConfirmedIds.push(guest.id)
        }
      })
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ confirmed: newConfirmed, confirmedIds: newConfirmedIds })
    }
  }

  handleConfirm(body) {
    const { confirmed, confirmedIds } = this.state
    const newConfirmed = [...confirmed]
    const newConfirmedIds = [...confirmedIds]
    const index = newConfirmedIds.indexOf(body.id)
    if (index === -1) {
      newConfirmed.push(body)
      newConfirmedIds.push(body.id)
    } else {
      newConfirmed[index] = body
      newConfirmedIds[index] = body.id
    }
    this.setState({ confirmed: newConfirmed, confirmedIds: newConfirmedIds })
  }

  handleOpen(index) {
    const { open } = this.state
    if (open === index) {
      this.setState({ open: -1 })
    } else {
      this.setState({ open: index })
    }
  }

  handleSave(id, observation) {
    const { confirmedIds, confirmed } = this.state
    const index = confirmedIds.indexOf(id)
    const newConfirmed = [...confirmed]
    newConfirmed[index].confirm_observation = observation
    this.setState({ confirmed: newConfirmed })
  }

  handleSend() {
    const { sendConfirmation, enqueueSnackbar, match } = this.props
    const { confirmed } = this.state
    const { id } = match.params
    const body = { confirmed, id, event_confirmed: false }
    sendConfirmation(body, enqueueSnackbar, this.callback)
  }

  handleSendAndConfirm() {
    const { sendConfirmation, enqueueSnackbar, match } = this.props
    const { confirmed } = this.state
    const { id } = match.params
    const body = { confirmed, id, event_confirmed: true }
    sendConfirmation(body, enqueueSnackbar, this.callback)
  }

  callback() {
    const { history } = this.props
    history.push('/handler')
  }

  renderGuests() {
    const { events } = this.props
    const { confirmedIds, confirmed, open } = this.state
    const { selected } = events
    return selected.participants.map((guest, idx) => {
      const index = confirmedIds.indexOf(guest.id)
      const result = index !== -1 ? confirmed[index].confirmed : ''
      return (
        <Guest
          guest={guest}
          confirmed={result}
          confirm={this.handleConfirm}
          save={this.handleSave}
          index={idx}
          open={open === idx}
          handleOpen={this.handleOpen}
          key={guest.id}
        />
      )
    })
  }

  render() {
    const { classes, events } = this.props
    const { confirmed } = this.state
    const isFunctional = events.selected.event_type_name.includes('Funcional')
    const { selected } = events
    const disabledEvaluation = selected.participants.length !== confirmed.length
    return (
      <Paper className={classes.container}>
        <div className={classes.title}>
          <Typography variant="body1" color="primary" style={{ fontWeight: 600 }}>
            {`Convocatoria ${isFunctional ? 'Evaluación Funcional Diagnósitica' : 'Jornada de presentación'}`}
          </Typography>
          <Typography variant="caption" color="primary">
            {moment(new Date()).format('DD/MM/YYYY. HH:mm')}
          </Typography>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: 8 }}>
          {this.renderGuests()}
        </div>
        <div className={classes.button}>
          <div className={classes.button}>
            <Button
              color="secondary"
              style={{ fontWeight: 600 }}
              onClick={this.handleSend}
            >
              Guardar Confirmados
            </Button>
          </div>
          <div className={classes.button}>
            <Button
              variant="contained"
              color="secondary"
              style={{ fontWeight: 600 }}
              onClick={this.handleSendAndConfirm}
              disabled={disabledEvaluation}
            >
              Evaluación
            </Button>
          </div>
        </div>
      </Paper>
    )
  }
}

ConfirmationList.propTypes = {
  classes: propTypes.object.isRequired,
  events: propTypes.object.isRequired,
  sendConfirmation: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
}

const mapStateToProps = state => ({
  events: state.events,
})

const mapDispatchToProps = dispatch => ({
  sendConfirmation: (body, snack, cb) => dispatch(sendConfirmationAction(body, snack, cb)),
})

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(ConfirmationList))
)
