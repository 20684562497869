import React, { Component } from 'react'
import propTypes from 'prop-types'
import Conditional from './Conditional'
import './animation.css'

class LoaderAnimator extends Component {
  render() {
    const { loading, children, small } = this.props
    return (
      <Conditional condition={loading} hasElse>
        <div className={small ? 'lds-ring-small' : 'lds-ring'}>
          <div />
          <div />
          <div />
          <div />
        </div>
        <>
          {children}
        </>
      </Conditional>
    )
  }
}

LoaderAnimator.propTypes = {
  loading: propTypes.bool,
  children: propTypes.node.isRequired,
  small: propTypes.bool,
}

LoaderAnimator.defaultProps = {
  loading: false,
  small: false
}

export default LoaderAnimator
