export const checkIfAnswered = (form, userId) => {
  let answered = true
  Object.keys(form).forEach(section => {
    Object.keys(form[section]).forEach(criterium => {
      form[section][criterium].forEach(question => {
        if (question.type !== 'title' && question.answers.find(a => a.user_id === userId).body === null) {
          answered = false
        }
      })
    })
  })
  return answered
}

export const getTotal = form => {
  let questions = 0
  // const types = { required: 0, notRequired: 0 }
  Object.keys(form).forEach(section => {
    Object.keys(form[section]).forEach(criterium => {
      questions += form[section][criterium].filter(question => question.type !== 'title').length
      // if (question.type !== 'title') {
      //   if (types[question.type] !== undefined) {
      //     types[question.type] += 1
      //   } else {
      //     types[question.type] = 1
      //   }

      //   if (question.required === 1) {
      //     types.required += 1
      //   } else {
      //     types.notRequired += 1
      //   }
      // }
    })
  })
  // console.log(types)
  return questions
}

export const getAnswered = (form, userId) => {
  let answered = 0
  Object.keys(form).forEach(section => {
    Object.keys(form[section]).forEach(criterium => {
      const questions = form[section][criterium].filter(question => question.required !== 0)
      questions.forEach(question => {
        if (question.answers.find(a => a.user_id === userId).body !== null) {
          answered += 1
        }
      })
    })
  })
  return answered
}
