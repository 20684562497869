import React, { Component } from 'react'
import propTypes from 'prop-types'
import { withStyles, Typography, Divider } from '@material-ui/core'

const style = () => ({
  question: {
    paddingLeft: 12,
    margin: '12px 0'
  },
  container: {
    marginBottom: 36
  }
})

class QuestionGroup extends Component {
  renderQuestions() {
    const { group, classes, dynamic } = this.props
    let offset = 0
    let totalQuestions = 0
    const groupQuestions = group.map((q, index) => {
      let mustShow = true
      if (q.dependant) {
        mustShow = q.answers[0].must_show
      }
      if (mustShow) {
        totalQuestions += 1
        return <Typography key={q.text} className={classes.question} variant="h2">{`${index - offset + 1}. ${q.text}`}</Typography>
      }
      offset += 1
      return null
    })
    const dynamicQuestions = dynamic.map((q, index) => <Typography key={q.text} className={classes.question} style={{ color: '#D5602C' }} variant="h2">{`${index + totalQuestions + 1}. ${q.text}`}</Typography>)
    return groupQuestions.concat(dynamicQuestions)
  }

  render() {
    const { title, classes } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h2">{title}</Typography>
        <Divider />
        {this.renderQuestions()}
      </div>
    )
  }
}

QuestionGroup.propTypes = {
  classes: propTypes.object.isRequired,
  group: propTypes.arrayOf(propTypes.object),
  title: propTypes.string,
  dynamic: propTypes.arrayOf(propTypes.object)
}

QuestionGroup.defaultProps = {
  group: [],
  dynamic: [],
  title: 'Cargando...'
}

export default withStyles(style)(QuestionGroup)
