import React, { Component } from 'react'
import { Select, MenuItem } from '@material-ui/core'
import propTypes from 'prop-types'

export default class TypeSelection extends Component {
  render() {
    const { type, handleChange } = this.props
    return (
      <Select variant="outlined" value={type} onChange={handleChange}>
        <MenuItem value={0}>Mes</MenuItem>
        <MenuItem value={1}>Semana</MenuItem>
      </Select>
    )
  }
}

TypeSelection.propTypes = {
  type: propTypes.number.isRequired,
  handleChange: propTypes.func.isRequired,
}
