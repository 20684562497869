import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Select from '../../../../Shared/Fields/Select'
import HandlerProfileContext from '../HandlerProfileContext'

const style = () => ({
  container: {
    maxWidth: 400
  }
})

class AreaEdit extends Component {
  render() {
    const { classes, name, onChange, value = [], onBlur } = this.props;
    const { areas } = this.context
    return (
      <div className={classes.container}>
        <Select
          onBlur={onBlur}
          multiple
          complete={{ name, options: areas.map(area => ({ label: area.name, value: area.id })) }}
          onChange={onChange}
          value={value}
        />
      </div>
    );
  }
}

AreaEdit.contextType = HandlerProfileContext

AreaEdit.propTypes = {
  classes: propTypes.object.isRequired,
  value: propTypes.string.isRequired,
  name: propTypes.string,
  onBlur: propTypes.func,
  onChange: propTypes.func
}

AreaEdit.defaultProps = {
  name: '',
  onBlur: () => {},
  onChange: () => {},
}
export default withStyles(style)(AreaEdit)
