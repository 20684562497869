import React, { Component } from 'react'
import { Paper, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import FunctionalTabs from './FunctionalTabs'
import FormSheet from './FormSheet'
import ParticipantLabel from './ParticipantLabel'

const style = () => ({
  mainContainer: {
    flexGrow: 1,
    marginLeft: 24
  },
  formContainer: {
    padding: 12,
    marginTop: 6,
    maxHeight: '85vh',
    overflow: 'auto',
    position: 'relative'
  }
})

function getTabs(forms) {
  return Object.keys(forms)
}

function getSelectedForm(selectedTab, forms) {
  return forms[getTabs(forms)[selectedTab]]
}

class MainForm extends Component {
  render() {
    const { classes, selectedTab, onSelectTab, forms, selectedParticipant } = this.props;
    return (
      <div className={classes.mainContainer}>
        <FunctionalTabs selected={selectedTab} onSelect={onSelectTab} tabs={getTabs(forms)} />
        <Paper className={classes.formContainer} id="form-container">
          <ParticipantLabel name={selectedParticipant} />
          <FormSheet form={getSelectedForm(selectedTab, forms)} />
        </Paper>
      </div>
    );
  }
}

MainForm.propTypes = {
  classes: propTypes.object.isRequired,
  selectedTab: propTypes.number.isRequired,
  onSelectTab: propTypes.func.isRequired,
  forms: propTypes.object,
  selectedParticipant: propTypes.string
}

MainForm.defaultProps = {
  forms: {},
  selectedParticipant: ''
}
export default withStyles(style)(MainForm)
