import React from 'react'
import { withStyles, IconButton, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import ArrowRight from '@material-ui/icons/KeyboardArrowRight'

const style = () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 12,
  },
  title: {
    color: '#3E3E3E',
    fontWeight: 'bold',
    fontSize: 16
  }
})

class PageHandler extends React.Component {
  render() {
    const { classes, containerStyle, text, handleNext, title, handlePrev } = this.props
    return (
      <div className={classes.container} style={containerStyle}>
        <div>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        </div>
        <div>
          <Typography display="inline">
            {text}
          </Typography>
          <IconButton size="small" style={{ margin: '0 6px' }} onClick={handlePrev}>
            <ArrowLeft />
          </IconButton>
          <IconButton size="small" onClick={handleNext}>
            <ArrowRight />
          </IconButton>
        </div>
      </div>
    )
  }
}

PageHandler.propTypes = {
  classes: propTypes.object.isRequired,
  containerStyle: propTypes.object,
  text: propTypes.string,
  handleNext: propTypes.func,
  handlePrev: propTypes.func,
  title: propTypes.string
}

PageHandler.defaultProps = {
  containerStyle: {},
  text: '',
  title: '',
  handleNext: () => {},
  handlePrev: () => {},
}
export default withStyles(style)(PageHandler)
