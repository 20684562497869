/* eslint-disable react/no-did-update-set-state */
import { Paper, Typography, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import propTypes from 'prop-types'
import NavigatorLink from './NavigatorLink'
import { convertToRoman } from '../../../../Utils/functions'
import autobind from '../../../../Utils/autobind'

const style = () => ({
  container: {
    width: '30%',
    position: 'sticky',
    top: 0,
  },
  paper: {
    width: '100%',
    position: 'sticky',
    padding: 6,
    boxSizing: 'border-box',
  }
})

class InterviewNavigator extends Component {
  constructor() {
    super()
    this.state = {
      started: false
    }
    autobind(InterviewNavigator, this)
  }

  componentDidUpdate(prevProps) {
    const { links } = this.props
    const { started } = this.state
    if (prevProps.links !== links && links.length > 0) {
      if (!started) {
        const element = document.getElementById(links[0].link)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
          this.setState({ started: true })
        }
      }
    }
  }

  renderLinks() {
    const { links } = this.props
    return links.map((link, index) => (
      <NavigatorLink key={link.name} name={`${convertToRoman(index + 1)} - ${link.name}`} link={link.link} />
    ))
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Typography variant="h2">Entrevista:</Typography>
          {this.renderLinks()}
        </Paper>
      </div>
    )
  }
}

InterviewNavigator.propTypes = {
  classes: propTypes.object.isRequired,
  links: propTypes.arrayOf(propTypes.object)
}

InterviewNavigator.defaultProps = {
  links: [
    { name: 'Test 1', link: 'asd' },
    { name: 'Test 2', link: 'asd' },
    { name: 'Test 3', link: 'asd' },
  ]
}

export default withStyles(style)(InterviewNavigator)
