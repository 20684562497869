import stateMaker from '../Utils/reducers'

const programReducer = (state = { all: [] }, action) => {
  switch (action.type) {
    case 'GET_PROGRAMS': {
      return stateMaker(state, 'all', 'fetch', action)
    }
    case 'EDIT_PROGRAM': {
      return stateMaker(state, 'all', 'fetch', action)
    }
    case 'CREATE_PROGRAM': {
      return stateMaker(state, 'all', 'fetch', action)
    }
    case 'DELETE_PROGRAM': {
      return stateMaker(state, 'all', 'fetch', action)
    }
    default: {
      return state
    }
  }
}

export default programReducer
