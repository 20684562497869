import { Paper, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import propTypes from 'prop-types'
import GlobalComment from 'Shared/GlobalComment'
import { getFormEvaluation } from 'API/Recurrent'
import FlexibleInput from 'Utils/FieldRenderer'

const style = theme => ({
  container: {
    padding: 24,
    textAlign: 'start'
  },
  divider: {
    background: theme.palette.primary.main
  },
  question: {
    '& > div > *:first-child': {
      fontWeight: '600 !important',
      margin: '6px 0 3px 0'
    },
    '& > div > *:nth-child(2)': {
      width: '100% !important',
      gap: 6
    }
  },
  questionContainer: {
    margin: '12px 0 24px',
  },
  answerBar: {
    borderRadius: 24,
    height: 24,
    width: '50%',
    position: 'relative',
    background: '#DCE2F2',
  },
  barResult: {
    borderRadius: 24,
    position: 'absolute',
    left: 0,
    top: 0,
    background: 'green',
    width: '40%',
    height: 24
  },
  barText: {
    position: 'absolute',
    right: 12,
    top: '50%',
    transform: 'translate(0, -50%)',
    fontWeight: 600
  }

})

const valueColors = {
  100: '#1F8095',
  75: '#38B4A9',
  50: '#FFB33D',
  25: '#D5602C'
}

class PhysicalResult extends React.Component {
  constructor() {
    super()
    this.state = {
      evaluation: []
    }
  }

  async componentDidMount() {
    const { event } = this.props
    const { event_id: eventId, form_id: formId, evaluation_id: evaluationId } = event
    const body = { event_id: eventId, form_id: formId, evaluation_id: evaluationId }
    const response = await getFormEvaluation(body)
    console.log(response)
    const evaluationSections = response.data.info['Potencial Físico Funcional'].empty
    const evaluation = Object.values(evaluationSections).flat()
    console.log(response)
    this.setState({ evaluation })
  }

  renderAnswers() {
    const { classes } = this.props
    const { evaluation } = this.state
    return evaluation.map(question => {
      const info = { ...question }
      const answer = question.answers[0]?.body?.answer
      info.columns = '1fr'
      info.name = question.answers[0]?.id
      const value = (parseInt(answer, 10) + 1) * 25
      return (
        <div className={classes.questionContainer}>
          <div className={classes.question}>
            <FlexibleInput
              complete={info}
              disabled
              value={answer}
            />
            {question.image_url
            && <img src={`/${question.image_url}`} alt="fotito" />}
          </div>
          {question.type === 'radio'
        && (
          <div className={classes.answerBar}>
            <div className={classes.barResult} style={{ width: `${value}%`, background: valueColors[value] }} />
            <Typography variant="subtitle1" className={classes.barText}>
              {`${value}%`}
            </Typography>
          </div>
        )}
        </div>
      )
    })
  }

  render() {
    const { classes, event } = this.props
    return (
      <Paper className={classes.container}>
        <Typography color="primary" variant="h1" style={{ marginBottom: 24 }}>Resultados Evaluación Física Funcional</Typography>
        <GlobalComment event={event} />
        {this.renderAnswers()}
      </Paper>
    )
  }
}

PhysicalResult.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
}

PhysicalResult.defaultProps = {
}

export default withStyles(style)(PhysicalResult)
