import store from '../store'

const createSnackbar = (payload, snackbar = null) => {
  const snackbarFunction = snackbar || store.getState().controller.snackbar
  payload.then(response => {
    if (!response) return null
    const { data } = response;
    if (data.status === 'error') {
      return snackbarFunction(data.message, { variant: 'error' });
    }
    return snackbarFunction(data.message, { variant: 'success' });
  });
};

export const callSnackbar = (message, variant = 'success') => {
  const { snackbar } = store.getState().controller
  snackbar(message, { variant })
}

export default createSnackbar
