import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import propTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  itemTitle: {
    fontSize: 14,
    color: theme.palette.grey.dark,
  },
  itemAction: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  iconClass: {
    height: 18,
    width: 18,
    marginLeft: 8,
    color: '#6A6A6A'
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const SingleSettingRow = ({ contextText,
  actionText,
  optionalText = null,
  ActionComponent = null,
  buttonClassname = null,
  icon = null,
  buttonOnClick = undefined }) => {
  const classes = useStyles()
  const Icon = icon
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid className={classes.content}>
        <div className={classes.content}>
          <Typography display="inline" className={classes.itemTitle}>
            {contextText}
          </Typography>
          {icon ? <Icon className={classes.iconClass} /> : null}
        </div>
        <Typography
          display="inline"
          className={classes.itemTitle}
          style={{ fontWeight: 'bold', marginLeft: 30 }}
        >
          {optionalText}
        </Typography>
      </Grid>
      <Grid style={{ cursor: 'pointer', marginLeft: 30, position: 'relative', justifyContent: 'center' }}>
        <Button
          className={buttonClassname || classes.itemAction}
          onClick={buttonOnClick}
        >
          {ActionComponent ? (
            <ActionComponent />
          ) : actionText}
        </Button>
      </Grid>
    </Grid>
  )
}

SingleSettingRow.propTypes = {
  contextText: propTypes.string.isRequired,
  actionText: propTypes.string.isRequired,
  ActionComponent: propTypes.node,
  optionalText: propTypes.string,
  buttonClassname: propTypes.string,
  buttonOnClick: propTypes.func,
  icon: propTypes.node,
}

SingleSettingRow.defaultProps = {
  optionalText: null,
  ActionComponent: null,
  buttonClassname: null,
  buttonOnClick: undefined,
  icon: null,
}

export default SingleSettingRow
