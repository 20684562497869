import React from 'react'
import { withStyles, Typography, IconButton, Dialog, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import propTypes from 'prop-types'
import moment from 'moment'
import { Delete } from '@material-ui/icons'
import BinnacleContext from './BinnacleProvider'
import autobind from '../../../../../Utils/autobind'

const style = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addButton: {
    padding: 6
  },
  entryName: {
    fontWeight: 900,
    color: theme.palette.primary.main,
    marginRight: 6,
  },
  authorName: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.grey.dark
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      marginLeft: 6
    }
  },
  icon: {
    height: 20,
    width: 20
  },
  dialog: {
    padding: 24
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: 12
    }
  }
})

class Binnacle extends React.Component {
  constructor() {
    super()
    this.state = {
      selected: null
    }
    autobind(Binnacle, this)
  }

  handleOpenDelete(entry) {
    return () => {
      this.setState({ selected: entry })
    }
  }

  handleDelete() {
    const { onDelete } = this.props
    const { selected } = this.state
    onDelete(selected)
    this.setState({ selected: null })
  }

  render() {
    const { classes, entries } = this.props
    const { selected } = this.state
    const { handleForm } = this.context
    const isEmpty = entries.length === 0
    return (
      <>
        <div className={classes.container}>
          <Typography style={{ fontWeight: 900, fontSize: 14 }}> Bitácora </Typography>
          <IconButton style={{ padding: 6 }} onClick={handleForm(true)}>
            <AddIcon style={{ color: '#38b4a9', fontSize: 18 }} />
          </IconButton>
        </div>
        <Dialog
          open={selected !== null}
          onClose={() => this.setState({ selected: null })}
          fullWidth
          maxWidth="sm"
        >
          <div className={classes.dialog}>
            <Typography variant="subtitle1" style={{ marginBottom: 12 }}>¿Seguro que deseas eliminar esta bitácora?</Typography>
            <div className={classes.container}>
              <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
                <Typography className={classes.entryName}>
                  {selected?.title}
                </Typography>
                <Typography className={classes.authorName}>
                  {`/${selected?.author}`}
                </Typography>
              </div>
              <div className={classes.inline}>
                <Typography>
                  {moment(selected?.date).format('DD-MM-YYYY')}
                </Typography>
              </div>
            </div>
            <div>
              <Typography style={{ textAlign: 'justify' }}>
                {selected?.body}
              </Typography>
            </div>
            <div className={classes.dialogButtons}>
              <Button onClick={() => this.setState({ selected: null })}>
                Cancelar
              </Button>
              <Button color="primary" variant="contained" onClick={this.handleDelete}>
                Eliminar
              </Button>
            </div>
          </div>
        </Dialog>
        {entries.map(entry => (
          <div style={{ marginTop: 12 }} key={entry.id}>
            <div className={classes.container}>
              <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
                <Typography className={classes.entryName}>
                  {entry.title}
                </Typography>
                <Typography className={classes.authorName}>
                  {`/${entry.author}`}
                </Typography>
              </div>
              <div className={classes.inline}>
                <Typography>
                  {moment(entry.date).format('DD-MM-YYYY')}
                </Typography>
                <IconButton size="small" onClick={this.handleOpenDelete(entry)}>
                  <Delete className={classes.icon} />
                </IconButton>
              </div>
            </div>
            <div>
              <Typography style={{ textAlign: 'justify' }}>
                {entry.body}
              </Typography>
            </div>
          </div>
        ))}
        {isEmpty && <Typography> Aún no existen entradas en la bitácora </Typography>}
      </>
    )
  }
}

Binnacle.contextType = BinnacleContext

Binnacle.propTypes = {
  classes: propTypes.object.isRequired,
  entries: propTypes.arrayOf(propTypes.object),
  onDelete: propTypes.func.isRequired
}

Binnacle.defaultProps = {
  entries: [],
}

export default withStyles(style)(Binnacle)
