import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import SliderInput from '../../../../Shared/Fields/SliderInput'

const style = () => ({
  container: {
    padding: 12,
    width: '600px'
  },
  criteria: {
    margin: '12px 0 24px'
  },
  question: {
    margin: '6px 0'
  },
  title: {
    fontSize: 15,
    marginBottom: 12,
    fontWeight: 600
  }
})

class GencatResultForm extends Component {
  renderQuestion(questions) {
    const { classes } = this.props
    const steps = [
      { value: 1, label: 'Nunca', index: 0 },
      { value: 2, label: 'A veces', index: 1 },
      { value: 3, label: 'Frecuentemente', index: 2 },
      { value: 4, label: 'Siempre', index: 3 }
    ]
    return questions.map(question => {
      const complete = { steps, text: question.text }
      return (
        <div className={classes.question}>
          <SliderInput complete={complete} disabled value={question.value} />
        </div>
      )
    })
  }

  renderForm(results) {
    const { classes } = this.props
    return results.map((criteria, index) => {
      const title = criteria.name || ''
      const questions = criteria.questions || []
      return (
        <div className={classes.criteria} key={title}>
          {index > 0 && <Divider />}
          <Typography variant="h2" className={classes.title}>{title}</Typography>
          {this.renderQuestion(questions)}
        </div>
      )
    })
  }

  render() {
    const { classes, results } = this.props
    const allResults = results[0]?.results || []
    return (
      <div className={classes.container}>
        {this.renderForm(allResults)}
      </div>
    )
  }
}

GencatResultForm.propTypes = {
  classes: propTypes.object.isRequired,
  results: propTypes.arrayOf(propTypes.object)
}

GencatResultForm.defaultProps = {
  results: []
}

export default withStyles(style)(GencatResultForm)
