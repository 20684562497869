import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Typography, Tooltip } from '@material-ui/core'

class UserCell extends Component {
  render() {
    const { value, complete } = this.props
    return (
      <Tooltip title={value}>
        <div style={{ display: 'flex', alignItems: 'center', width: 250 }}>
          <img src={complete.img || './logo192.png'} alt="img" style={{ width: 32, height: 32, marginRight: 12, borderRadius: '50%' }} />
          <Typography
            variant="subtitle1"
            style={{ fontSize: 13, color: '#3E3E3E', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {value}

          </Typography>
        </div>
      </Tooltip>
    )
  }
}

UserCell.propTypes = {
  value: propTypes.string,
  complete: propTypes.object
}

UserCell.defaultProps = {
  value: '',
  complete: {}
}

export default UserCell
