import React, { Component, Fragment } from 'react'
import propTypes from 'prop-types'
import Criterium from './Criterium'
import GeneralForm from './GeneralForm'
import parseQuestions from '../Utils/functions'

class CriteriaForm extends Component {
  renderCriterium() {
    const {
      criteria,
      handleChange,
      params,
      userId,
      saved,
      comments,
      savedComments,
      handleComment,
      handleSubchange,
      title,
      bigScreen,
      disabled,
      highlighted,
      enumerate,
      dynamic
    } = this.props
    return Object.keys(criteria).map(key => {
      const questionsArray = criteria[key]
      const questions = questionsArray.filter(question => {
        const answer = question.answers.find(ans => ans.user_id === userId)
        if (answer) {
          if (question.dependant) {
            return answer.must_show
          }
          return true
        }
        return false
      }).concat(dynamic).map(question => {
        const answer = question.answers.find(ans => ans.user_id === userId)
        if (answer) {
          const info = { ...question }
          info.id = answer.id
          info.name = answer.id
          return info
        }
        return null
      })
      if (!questions.includes(null)) {
        return (
          <Fragment key={key}>
            <Criterium label={title || key} />
            <GeneralForm
              info={questions}
              params={params}
              handleChange={handleChange}
              handleComment={handleComment}
              handleSubchange={handleSubchange}
              withButton={false}
              saved={saved}
              comments={comments}
              savedComments={savedComments}
              areas={parseQuestions(questions, bigScreen)}
              bigScreen={bigScreen}
              disabled={disabled}
              highlighted={highlighted}
              enumerate={enumerate}
            />
          </Fragment>
        )
      }
      return null
    })
  }

  render() {
    const { id } = this.props
    return <div id={id}>{this.renderCriterium()}</div>
  }
}

CriteriaForm.propTypes = {
  criteria: propTypes.object,
  handleChange: propTypes.func.isRequired,
  handleComment: propTypes.func,
  params: propTypes.object.isRequired,
  userId: propTypes.string.isRequired,
  saved: propTypes.object.isRequired,
  comments: propTypes.object.isRequired,
  savedComments: propTypes.object.isRequired,
  handleSubchange: propTypes.func,
  title: propTypes.string,
  bigScreen: propTypes.bool,
  disabled: propTypes.bool,
  highlighted: propTypes.bool,
  enumerate: propTypes.bool,
  dynamic: propTypes.arrayOf(propTypes.object),
  id: propTypes.string
}

CriteriaForm.defaultProps = {
  criteria: {},
  dynamic: [],
  handleComment: undefined,
  handleSubchange: undefined,
  title: '',
  bigScreen: true,
  disabled: false,
  highlighted: false,
  enumerate: false,
  id: ''
}

export default CriteriaForm
