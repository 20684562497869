const BreadcrumbsReducer = (state = { all: [] }, action) => {
  let newState = state
  switch (action.type) {
    case 'SET_BREADCRUMBS': {
      newState = { ...state, all: action.payload }
      break
    }
    case 'ADD_BREADCRUMB': {
      const old = [...state.all]
      old.push(action.payload)
      newState = { ...state, all: old }
      break
    }
    case 'SELECT_BREADCRUMB': {
      const name = action.payload
      const selected = state.all.find(b => b.name === name)
      const index = state.all.indexOf(selected)
      const newAll = [...state.all]
      const sliced = newAll.slice(0, index + 1)
      newState = { ...state, all: sliced }
      break
    }
    default: {
      break
    }
  }
  return newState
}

export default BreadcrumbsReducer
