import React, { Component } from 'react'
import { withStyles, Paper, Typography, Button, Divider, withTheme, Grid } from '@material-ui/core'
import propTypes from 'prop-types'
import moment from 'moment'
import { PersonAdd } from '@material-ui/icons'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import Participant from './Participant'
import { loadContendersAction } from '../../Actions/Contenders'
import { sendAttendanceAction } from '../../Actions/Handler'
import { addParticipantsAction, selectEventAction } from '../../Actions/Events'
import NewParticipant from './NewParticipant'
import autobind from '../../Utils/autobind'

const style = () => ({
  container: {
    padding: 12,
    width: '100%',
    background: '#fff',
    textAlign: 'left',
    marginRight: 12,
  },
  title: {
    textAlign: 'left',
    paddingBottom: 24,
    position: 'relative',
  },
  radioBtns: {
    display: 'flex',
  },
  button: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  outer: {
    padding: 12,
    display: 'flex',
    maxWidth: 700,
  },
  right: {
    width: '30%',
  },
})

class Attendance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      attended: [],
      openForm: false,
    }

    autobind(Attendance, this)
    this.newContenderCallback = this.newContenderCallback.bind(this)
  }

  componentDidMount() {
    const { events, loadPossibleParticipants } = this.props
    const { selected } = events
    const { participants } = selected
    const newAttended = []
    participants.forEach(participant => {
      if (participant.attended) {
        newAttended.push(participant.id)
      }
    })
    loadPossibleParticipants()
    this.setState({ attended: newAttended })
  }

  handleAttend(event, id) {
    const { attended } = this.state
    const newAttended = [...attended]
    const index = attended.indexOf(id)
    if (index === -1) {
      newAttended.push(id)
    } else {
      newAttended.splice(index, 1)
    }
    this.setState({ attended: newAttended })
  }

  handleSend() {
    const { sendAttendance, enqueueSnackbar, match, history, events } = this.props
    const { attended } = this.state
    const { id, formId } = match.params
    const body = { attended, id }
    sendAttendance(body, enqueueSnackbar)
    const eventType = events.selected.event_type_name
    const isFunctional = eventType ? eventType.includes('Funcional') : false
    if (isFunctional) {
      history.push(`/events/${id}/functional/${formId}`)
    } else {
      history.push(`/events/${id}/gathering`)
    }
  }

  handleOpen() {
    const { openForm } = this.state
    this.setState({ openForm: !openForm })
  }

  handleCloseForm() {
    this.setState({ openForm: false })
  }

  newContenderCallback(createdContender) {
    const {
      data: {
        info: { user },
      },
    } = createdContender
    const { addParticipants, match } = this.props
    const { id } = match.params
    addParticipants(id, [user.id])
    this.setState({ openForm: false })
  }

  renderParticipants() {
    const { events } = this.props
    const { attended } = this.state
    return events.selected.participants
      .filter(participant => participant.confirmed)
      .map(participant => (
        <Participant
          attend={this.handleAttend}
          participant={participant}
          key={participant.id}
          attended={attended.indexOf(participant.id) !== -1}
        />
      ))
  }

  renderNotConfirmed() {
    const { events } = this.props
    const { owners } = events.selected
    return events.selected.participants
      .filter(participant => !participant.confirmed)
      .map(participant => (
        <Participant
          attend={this.handleAttend}
          participant={participant}
          owners={owners}
          key={participant.id}
          attended={false}
          disabled
        />
      ))
  }

  render() {
    const { classes, theme, contenders, events } = this.props
    const { openForm, attended } = this.state
    const contendersData = contenders ? contenders.all : []
    const eventType = events.selected.event_type_name
    const isFunctional = eventType ? eventType.includes('Funcional') : false
    const filteredContenders = contendersData.filter(contender => {
      if (isFunctional) return contender.user.can_answer_functional
      return contender.user.can_answer_gathering
    })
    return (
      <Grid container>
        <Grid container item className={classes.outer}>
          <Paper className={classes.container}>
            <div className={classes.title}>
              <Typography color="primary" variant="body1" style={{ fontWeight: 600 }}>
                {`Asistencia ${isFunctional ? 'Evaluación Funcional Diagnósitica' : 'Jornada de presentación'}`}
              </Typography>
              <Typography color="primary" variant="caption">
                {moment(new Date()).format('DD/MM/YYYY. HH:mm')}
              </Typography>
              <Typography
                variant="body2"
                style={{ fontSize: 12, position: 'absolute', bottom: 3, right: 0 }}
              >
                Presente/Ausente
              </Typography>
              <PersonAdd
                onClick={this.handleOpen}
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  cursor: 'pointer',
                  color: theme.palette.grey.dark,
                }}
              />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: 10 }}>
              {this.renderParticipants()}
            </div>
            <Divider style={{ margin: '24px 0 0 0' }} />
            <Typography variant="body1" style={{ margin: '12px 0' }}>
              No confirmados
            </Typography>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: 10 }}>
              {this.renderNotConfirmed()}
            </div>
            <div className={classes.button}>
              <Button
                disabled={attended.length === 0}
                onClick={this.handleSend}
                variant="contained"
                color="secondary"
              >
                Evaluación
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid container item className={classes.right}>
          {openForm
            && (
              <NewParticipant
                fullWidth
                createCallback={this.newContenderCallback}
                contenders={filteredContenders}
                filtered={events.selected.participants
                  .filter(participant => participant.confirmed).map(p => p.id)}
                close={this.handleCloseForm}
              />
            )}
        </Grid>
      </Grid>
    )
  }
}

Attendance.propTypes = {
  classes: propTypes.object.isRequired,
  events: propTypes.object.isRequired,
  contenders: propTypes.shape({ all: propTypes.arrayOf(propTypes.object) }).isRequired,
  sendAttendance: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  theme: propTypes.object.isRequired,
  addParticipants: propTypes.func.isRequired,
  loadPossibleParticipants: propTypes.func.isRequired
}

const mapStateToProps = state => ({
  events: state.events,
  contenders: state.contenders,
})

const mapDispatchToProps = dispatch => ({
  sendAttendance: (body, snackbar) => dispatch(sendAttendanceAction(body, snackbar)),
  addParticipants: (id, participants) => dispatch(addParticipantsAction(id, participants)),
  loadPossibleParticipants: () => dispatch(loadContendersAction()),
  selectEvent: eventId => dispatch(selectEventAction(eventId)),
})

export default withTheme(
  withSnackbar(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(style)(Attendance))
  )
)
