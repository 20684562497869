import React from 'react'
import propTypes from 'prop-types'
import { FormControl, Select, MenuItem, InputBase } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  root: {
    fontSize: '0.8rem',
  },
})

// const CustomInputBase = withStyles(() => ({}))(InputBase)

class CustomSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.renderValueFunc = this.renderValueFunc.bind(this)
  }

  renderValueFunc(value) {
    const { defaultText, allValues } = this.props
    if (value === -1) {
      return defaultText
    }
    return allValues[value].text
  }

  render() {
    const { classes, value, handleChange, allValues, defaultText } = this.props
    return (
      <FormControl>
        <Select
          classes={{ root: classes.root }}
          input={<InputBase />}
          value={value}
          onChange={handleChange}
          renderValue={defaultText ? this.renderValueFunc : undefined}
        >
          {allValues.map((e, idx) => (
            <MenuItem value={idx} key={e.id}>
              {e.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

CustomSelect.defaultProps = {
  defaultText: '',
}

CustomSelect.propTypes = {
  classes: propTypes.object.isRequired,
  value: propTypes.number.isRequired,
  handleChange: propTypes.func.isRequired,
  allValues: propTypes.arrayOf(propTypes.object).isRequired,
  defaultText: propTypes.string,
}

export default withStyles(styles)(CustomSelect)
