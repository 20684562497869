/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { Button, Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import moment from 'moment'
import { Delete } from '@material-ui/icons'
import RecurrentContext from '../../RecurrentEvaluations/RecurrentContext'
import autobind from '../../../Utils/autobind'
import { destroyEvaluation } from '../../../API/Evaluations'
import { getFormEvaluation } from '../../../API/Recurrent'

const style = () => ({
  container: {
    padding: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)'
    }
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    },
    '& > div': {
      textAlign: 'end'
    },
    '& > div > *': {
      marginLeft: 12
    }
  }
})

class FamiliarCard extends Component {
  constructor() {
    super()
    this.state = {
      selectedEvaluation: null,
      openDelete: false,
      name: ''
    }
    autobind(FamiliarCard, this)
  }

  async componentDidMount() {
    const { evaluation } = this.props
    const body = {
      form_id: evaluation.form_group_id,
      evaluation_id: evaluation.id,
    }
    if (evaluation.status !== 'check') return true
    const response = await getFormEvaluation(body)
    const form = response.data.info
    const question = form['Escala de Calidad de Vida Familiar'].empty.empty[0]
    const name = question.answers.find(ans => ans.user_id === evaluation.participant_user).body
    return this.setState({ name })
  }

  handleOpenDelete(evaluation) {
    return () => {
      const { openDelete } = this.state
      this.setState({ openDelete: !openDelete, selectedEvaluation: evaluation })
    }
  }

  handleClick() {
    const { onClick } = this.props
    onClick()
  }

  async handleConfirmDelete() {
    const { reload } = this.props
    const { selectedEvaluation } = this.state
    const body = { id: selectedEvaluation.id }
    await destroyEvaluation(body)
    reload()
    this.setState({ openDelete: false, selectedEvaluation: null })
  }

  render() {
    const { openDelete, name } = this.state
    const { classes, evaluation, onClick } = this.props;
    const { evaluationName } = this.context
    return (
      <>
        <div
          className={classes.container}
          onClick={e => {
            e.stopPropagation()
            onClick()
          }}
        >
          <Typography variant="subtitle1">{`${evaluationName} N°${evaluation.name}`}</Typography>
          <Typography variant="subtitle1">{`Responsable: ${name || 'Sin registro aún'}`}</Typography>
          <Typography variant="subtitle1">{`${moment(evaluation.updated_at).format('DD/MM/YYYY')}`}</Typography>
          <IconButton
            size="small"
            disabled={!evaluation?.deletable}
            onClick={e => {
              e.stopPropagation()
              this.handleOpenDelete(evaluation)()
            }}
          >
            <Delete />

          </IconButton>
        </div>
        <Dialog open={openDelete} onClose={this.handleOpenDelete()} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="h2">¿Seguro que desea eliminar esta evaluación?</Typography>
            <div>
              <Button variant="outlined" onClick={this.handleOpenDelete()}>
                Cancelar
              </Button>
              <Button color="secondary" variant="outlined" onClick={this.handleConfirmDelete}>
                Eliminar
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

FamiliarCard.contextType = RecurrentContext

FamiliarCard.propTypes = {
  classes: propTypes.object.isRequired,
  evaluation: propTypes.object.isRequired,
  onClick: propTypes.func,
  reload: propTypes.func,
}

FamiliarCard.defaultProps = {
  onClick: undefined,
  reload: undefined
}
export default withStyles(style)(FamiliarCard)
