import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withStyles, Paper, Typography, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import actioncable from 'actioncable'
import moment from 'moment'
import MyTextField from './TextField'
import Conditional from '../../../Shared/Conditional'
import { setEventAction } from '../../../Actions/Events'
import { logoutAction } from '../../../Actions/Login'
import autobind from '../../../Utils/autobind'

const style = theme => ({
  container: {
    width: '550px',
    margin: 'auto',
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  button: {
    display: 'flex',
    marginTop: 6,
    justifyContent: 'flex-end',
  },
  header: {
    marginBottom: 0,
    color: theme.palette.grey.dark,
  },
  dateText: {
    marginBottom: theme.spacing(2)
  },
  subHeader: {
    marginBottom: theme.spacing(2),
    fontSize: 15,
    color: theme.palette.confirm.main,
  },
})

class SelectContender extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rut: '',
      participantId: '',
      received: false,
      source: null,
    }
    autobind(SelectContender, this)
  }

  componentDidMount() {
    const { logout, channel } = this.props
    logout()
    this.cable = actioncable.createConsumer(`${process.env.REACT_APP_API_WS}/cable`)
    this.channel = this.cable.subscriptions.create(
      {
        channel,
      },
      {
        connected: () => {
          console.log('connected!')
        },
        disconnected: () => {
          console.log('disconnected!')
        },
        received: data => {
          this.handleReceive(data)
        },
      }
    )
  }

  componentWillUnmount() {
    this.cable.subscriptions.consumer.disconnect()
  }

  handleReceive(data) {
    const { setEvent } = this.props
    setEvent(data)
    this.setState({ received: true })
  }

  handleChange(event) {
    const { target } = event
    if (target.name === 'participantId') {
      this.setState({ [target.name]: target.value, rut: '', source: 'name' })
      return
    }
    this.setState({ [target.name]: target.value, participantId: '', source: 'rut' })
  }

  handleSelectAndStart() {
    const { event, handleNextStep, handleShare, enqueueSnackbar } = this.props
    const { participantId, source, rut } = this.state
    let participant
    if (source === 'name') {
      participant = event.participants.find(p => p.id === participantId)
    } else {
      participant = event.participants.find(p => p.rut === rut)
    }
    if (participant) {
      handleShare({ selected: participant.id, name: participant.name })
      handleNextStep(1)
    } else {
      enqueueSnackbar('No hay participante con ese RUT', { variant: 'error' })
    }
  }

  render() {
    const { classes, event, title, loadText } = this.props
    const { participantId, received } = this.state
    let participants = []
    let formattedDate = ''
    if (event && received) {
      participants = event.participants
        .filter(p => p.attended)
        .map(p => ({ value: p.id, label: p.name }))
      formattedDate = moment(event.date).format('DD [de] MMMM [del] YYYY')
    }
    return (
      <>
        <Conditional condition={received} hasElse>
          <Paper className={classes.container}>
            <Typography variant="h1" className={classes.header}>
              {title}
            </Typography>
            <Typography className={classes.dateText}>
              {formattedDate}
            </Typography>
            <MyTextField
              value={participantId}
              onChange={this.handleChange}
              label="Buscar por nombre"
              placeholder="Lista aspirantes"
              name="participantId"
              selectOptions={participants}
            />
            <div className={classes.button}>
              <Button variant="contained" color="secondary" onClick={this.handleSelectAndStart}>
                Guardar
              </Button>
            </div>
          </Paper>
          <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
            <Typography
              variant="h1"
              style={{
                position: 'absolute',
                left: '50%',
                top: '40%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {`${loadText}...`}
            </Typography>
          </div>
        </Conditional>
      </>
    )
  }
}

SelectContender.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  setEvent: propTypes.func.isRequired,
  logout: propTypes.func.isRequired,
  handleNextStep: propTypes.func.isRequired,
  handleShare: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  channel: propTypes.string.isRequired,
  title: propTypes.string,
  loadText: propTypes.string
}

SelectContender.defaultProps = {
  title: 'Título',
  loadText: 'Cargando'
}

const mapStateToProps = state => ({
  event: state.events.selected,
})

const mapDispatchToProps = dispatch => ({
  setEvent: body => dispatch(setEventAction(body)),
  logout: () => dispatch(logoutAction()),
})

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(SelectContender))
)
