import instance from '../Utils/client'

export const getFunctionalEvaluationAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `events/${body.id}/forms/${body.form_id}/activities`
  })
  return {
    type: 'GET_FUNCTIONAL_EVALUATION',
    payload: REQUEST
  }
}

export const sendSkillAnswerAction = body => {
  const REQUEST = instance({
    url: `/events/${body.id}/skill_answer`,
    method: 'put',
    data: {
      ...body,
    },
  })
  return {
    type: 'SEND_SKILL_ANSWERS',
    payload: REQUEST,
  }
}

export const getFunctionalResultsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `/events/${body.id}/forms/${body.form_id}/activities_result?user=${body.user_id}`
  })
  return {
    type: 'GET_FUNCTIONAL_RESULT',
    payload: REQUEST
  }
}

export const getMultipleFunctionalResultsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `/events/${body.id}/forms/${body.form_id}/activities_result`
  })
  return {
    type: 'GET_FUNCTIONAL_RESULT',
    payload: REQUEST
  }
}
