import React from 'react'
import { withStyles, Paper, Typography, Button, Menu, MenuItem, IconButton } from '@material-ui/core'
import { KeyboardArrowRight, AddCircle, Edit, Delete, Work, MoreVert } from '@material-ui/icons'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import ButtonsWithIcon from '../../../Shared/Buttons/ButtonsWithIcon'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import GeneralTable from '../../../Shared/GeneralTable'
import autobind from '../../../Utils/autobind'
import CreateDialog from './CreateDialog'
import CreateOrganizationDialog from './CreateOrganizationDialog'
import { createExternalCoordinatorUserAction,
  loadExternalUsersAction,
  deleteExternalUserAction, editExternalUserAction } from '../../../Actions/User'
import { loadExternalOrganizationsAction, createExternalOrganizationAction, changeOrganizationPhotoAction } from '../../../Actions/Organizations'
import DeleteDialog from './DeleteDialog'
import { callSnackbar } from '../../../Utils/snackbars'
import OrganizationCell from './OrganizationCell'

const style = theme => ({
  mainContainer: {
    padding: 12,
    margin: '24px 12px'
  },
  bread: {
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    '& > *': {
      margin: '0 12px'
    },
    '& > *:last-child': {
      color: theme.palette.primary.main
    }
  },
  leftButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '24px 0'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 3
  },
  icon: {
    height: 18,
    width: 18
  }
})

function handleCopyLink() {
  const element = document.getElementById('link-input')
  element.value = `${window.location.origin}/external`
  element.select()
  element.setSelectionRange(0, 99999)
  document.execCommand('copy');
  callSnackbar('Enlace copiado', 'success')
}

class ExternalUsers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      openCreate: false,
      openCreateOrganization: false,
      userToEdit: null,
      userIdToDelete: null,
      anchorEl: null
    }
    autobind(ExternalUsers, this)
  }

  componentDidMount() {
    const { loadExternalUsers, loadExternalOrganizations } = this.props
    loadExternalUsers({ origin: 1 })
    loadExternalOrganizations()
  }

  handleReload() {
    this.componentDidMount()
  }

  handleOpenCreate(status) {
    return userToEdit => () => {
      this.setState({ openCreate: status, userToEdit })
    }
  }

  handleOpenCreateOrganization(status) {
    return () => {
      this.setState({ openCreateOrganization: status })
    }
  }

  handleCreate(body) {
    const { createExternalUser } = this.props
    createExternalUser(body)
  }

  handleEdit(body) {
    const { userToEdit } = this.state
    const { editExternalUser } = this.props
    editExternalUser({ ...body, user_id: userToEdit.id })
  }

  handleCreateOrganization(body) {
    const { createExternalOrganization } = this.props
    createExternalOrganization(body)
  }

  handleCloseDeleteDialog() {
    this.setState({ userIdToDelete: null })
  }

  handleDeleteClick(row) {
    return () => {
      this.setState({ userIdToDelete: row.id })
    }
  }

  handleDeleteUser() {
    const { deleteExternalUser } = this.props
    const { userIdToDelete } = this.state
    deleteExternalUser(userIdToDelete).then(() => {
      this.handleCloseDeleteDialog()
      this.componentDidMount()
    })
  }

  handleOpenMenu(id) {
    return () => {
      if (!id) return this.setState({ anchorEl: null })
      const element = document.getElementById(id)
      return this.setState({ anchorEl: element })
    }
  }

  render() {
    const { classes, externalUsers, organizations, editOrganizationPhoto } = this.props
    const {
      loading,
      openCreate,
      openCreateOrganization,
      userIdToDelete,
      userToEdit,
      anchorEl
    } = this.state
    const tableInfo = [
      { name: 'Nombre', label: 'name' },
      { name: 'Mail', label: 'email' },
      { name: 'Organización', label: 'organization_name', render: OrganizationCell },
    ]

    const actions = [
      { name: 'Editar usuario', icon: Edit, action: this.handleOpenCreate(true) },
      { name: 'Eliminar usuario', icon: Delete, action: this.handleDeleteClick }
    ]
    const AddOrganizationIcon = () => (
      <div className={classes.iconContainer}>
        +
        <Work className={classes.icon} />
      </div>
    )
    const saveFunc = userToEdit === null ? this.handleCreate : this.handleEdit
    const openDeleteDialog = userIdToDelete !== null
    return (
      <div className={classes.mainContainer}>
        <Paper className={classes.bread}>
          <Typography variant="h1">Configuración general</Typography>
          <KeyboardArrowRight />
          <Typography variant="h1">Usuarios Link de Redes </Typography>
        </Paper>
        <Menu open={anchorEl !== null} anchorEl={anchorEl} onClose={this.handleOpenMenu()}>
          <MenuItem onClick={this.handleOpenCreateOrganization(true)}>
            <ButtonsWithIcon
              color="primary"
              variant="text"
              buttonProps={{ style: { width: '100%' } }}
              Icon={AddOrganizationIcon}
              onClick={this.handleOpenCreateOrganization(true)}
            >
              Agregar organización
            </ButtonsWithIcon>
          </MenuItem>
          <MenuItem onClick={this.handleOpenCreate(true)(null)}>
            <ButtonsWithIcon
              color="primary"
              variant="text"
              buttonProps={{ style: { width: '100%' } }}
              Icon={AddCircle}
              onClick={this.handleOpenCreate(true)(null)}
            >
              Agregar usuario
            </ButtonsWithIcon>
          </MenuItem>
          <MenuItem onClick={handleCopyLink}>
            <Button color="primary" variant="text" onClick={handleCopyLink}>
              Copiar enlace link de redes
            </Button>
            <input style={{ opacity: 0, position: 'absolute' }} id="link-input" />
          </MenuItem>
        </Menu>
        <div className={classes.leftButton}>
          <IconButton onClick={this.handleOpenMenu('menu')} id="menu">
            <MoreVert />
          </IconButton>
        </div>
        {loading
          ? <LoaderAnimator loading />
          : (
            <GeneralTable
              info={tableInfo}
              data={externalUsers.filter(Boolean)}
              actions={actions}
            />
          )}
        <DeleteDialog
          open={openDeleteDialog}
          onClose={this.handleCloseDeleteDialog}
          onConfirm={this.handleDeleteUser}
        />
        <CreateDialog
          open={openCreate}
          onSave={saveFunc}
          onClose={this.handleOpenCreate(false)(null)}
          selectOptions={organizations || []}
          user={userToEdit}
        />
        <CreateOrganizationDialog
          open={openCreateOrganization}
          onSave={this.handleCreateOrganization}
          onClose={this.handleOpenCreateOrganization(false)}
          organizations={organizations || []}
          onPhotoChange={editOrganizationPhoto}
          reload={this.handleReload}
        />
      </div>
    )
  }
}

ExternalUsers.propTypes = {
  classes: propTypes.object.isRequired,
  createExternalUser: propTypes.func.isRequired,
  createExternalOrganization: propTypes.func.isRequired,
  loadExternalUsers: propTypes.func.isRequired,
  loadExternalOrganizations: propTypes.func.isRequired,
  externalUsers: propTypes.arrayOf(propTypes.object),
  organizations: propTypes.arrayOf(propTypes.object),
  deleteExternalUser: propTypes.func.isRequired,
  editExternalUser: propTypes.func.isRequired,
  editOrganizationPhoto: propTypes.func.isRequired,
}

ExternalUsers.defaultProps = {
  externalUsers: [],
  organizations: []
}

const mapStateToProps = state => ({
  externalUsers: state.user.external,
  organizations: state.organizations.all
})

const mapDispatchToProps = dispatch => ({
  createExternalUser: body => dispatch(createExternalCoordinatorUserAction(body)),
  loadExternalUsers: body => dispatch(loadExternalUsersAction(body)),
  editOrganizationPhoto: body => dispatch(changeOrganizationPhotoAction(body)),
  deleteExternalUser: id => dispatch(deleteExternalUserAction(id)),
  loadExternalOrganizations: () => dispatch(loadExternalOrganizationsAction()),
  createExternalOrganization: body => dispatch(createExternalOrganizationAction(body)),
  editExternalUser: body => dispatch(editExternalUserAction(body))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ExternalUsers))
