import stateMaker from '../Utils/reducers'

const eventsReducer = (state = { all: [], selected: {}, result: {} }, action) => {
  const { payload } = action
  switch (action.type) {
    case 'LOAD_EVENTS':
      return stateMaker(state, 'all', 'fetch', action)
    case 'LOAD_GOOGLE_CALENDARS':
      return stateMaker(state, 'google', 'fetch', action)
    case 'CREATE_EVENT':
      return stateMaker(state, 'all', 'create', action)
    case 'EDIT_EVENT':
      return stateMaker(state, 'all', 'edit', action, { key: 'event' })
    case 'DELETE_EVENT':
      return stateMaker(state, 'all', 'delete', action, { key: 'event' })
    case 'SELECT_EVENT':
      return stateMaker(state, 'all', 'select', action, { key: 'event' })
    case 'ADD_PARTICIPANTS':
      return stateMaker(state, 'selected', 'fetch', action)
    case 'LOAD_EVENT':
      return stateMaker(state, 'selected', 'fetch', action)
    case 'LOAD_RESULT':
      return stateMaker(state, 'result', 'fetch', action)
    case 'SEARCH_EVENTS':
      return stateMaker(state, 'search', 'fetch', action)
    case 'CREATE_GENCAT_EVENT': {
      if (!payload) return state
      return { ...state,
        selected: action.payload.data.info.gencat,
        all: state.all.concat(action.payload.data.info.gencat).map(event => {
          if (event.event.id === action.payload.data.info.ref_event.event.id) {
            return action.payload.data.info.ref_event
          }
          return event
        }) }
    }
    case 'SET_EVENT': {
      const newState = { ...state }
      newState.selected.event = payload.event
      newState.selected.forms = payload.forms
      newState.selected.participants = payload.participants
      newState.selected.contactInfo = payload.contact_info
      return newState
    }
    default:
      return state
  }
}

export default eventsReducer
