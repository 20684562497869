import React, { Component } from 'react'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { AccountBox, Home } from '@material-ui/icons'
import moment from 'moment'
import autobind from '../../Utils/autobind'
import FlexibleInput from '../../Utils/FieldRenderer'
import SetViewContext from '../Contenders/Profile/SetViewProvider'
import { editEvaluationComment, getEvaluationComment } from '../../API/Evaluations'
import GlobalComment from '../../Shared/GlobalComment'

const style = theme => ({
  container: {
    padding: 12,
    textAlign: 'left'
  },
  question: {
    textAlign: 'left',
    '& > *': {
      margin: '12px 0'
    }
  },
  group: {
    background: theme.palette.indigo.lighter,
    padding: 12,
    borderRadius: 8,
    animation: 'fadein 0.5s ease-in-out both'
  },
  submitButton: {
    marginTop: 24,
    textAlign: 'end'
  },
  sectionTitleText: {
    padding: '6px 0',
    marginBottom: 12,
    borderBottom: `1px solid ${theme.palette.primary.main}`
  },
  section: {
    margin: '24px 0'
  },
  deleteButton: {
    textAlign: 'end'
  },
  member: {
    fontSize: 14,
    fontWeight: 600,
    color: '#1a1a1a'
  },
  memberCards: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  },
  memberCard: {
    border: '1px solid #1a1a1a',
    padding: 12,
    margin: 12,
    borderRadius: 8,
    minWidth: 300,
    '& > *:first-child': {
      fontSize: 13
    }
  },
  memberInfo: {
    marginTop: 12,
  },
  infoLine: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *:nth-child(2)': {
      fontWeight: 400
    }
  },
  cardTitle: {
    margin: '12px 0 24px 0',
  },
  cardInnerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

class CSDResult extends Component {
  constructor() {
    super()
    this.state = {
      data: null,
      nameOptions: [],
      params: {},
      groups: {},
      comment: ''
    }

    this.familyMembersQuestions = []

    autobind(CSDResult, this)
  }

  async componentDidMount() {
    const { competency, event, evaluation, contender } = this.props
    const form = competency.selected_form
    if (!contender.user) return null
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      evaluation_id: evaluation,
      user_id: contender.user.id
    }
    const response = await getEvaluationComment(body)
    this.setState({ data: form }, this.setupAnswers)
    if (response.data.status !== 'success') return null
    return this.setState({ comment: response.data.info.comment })
  }

  handleBack() {
    const { endFunction } = this.props
    if (endFunction) { return endFunction() }
    const { setView } = this.context
    setView()
    return null
  }

  handleChange(event) {
    const { target } = event
    this.setState({ comment: target.value })
  }

  handleSave() {
    const { comment } = this.state
    const { event, evaluation, contender } = this.props
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      evaluation_id: evaluation,
      user_id: contender.user.id,
      comment
    }
    editEvaluationComment(body)
  }

  setupAnswers() {
    const { page, data } = this.state
    if (page === 0) {
      // Setup answers for family members

      const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
      const answer = question?.answers[0]
      const actualGroups = answer?.subquestion_groups
      const { groups } = answer?.body
      const actualIds = actualGroups.map(ag => ag.id)
      const filteredGroupsAsArray = Object.entries(groups).filter(([groupName]) => actualIds.includes(groupName.split('#')[1]))
      const filteredGroups = Object.fromEntries(filteredGroupsAsArray)
      this.setState({ groups: filteredGroups })
    } else {
      // Setup family members as options

      const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
      const answer = question?.answers[0]

      if (!answer) return null
      if (!answer.body) return null

      const actualGroups = answer?.subquestion_groups
      const { groups } = answer?.body
      const actualIds = actualGroups.map(ag => ag.id)
      const filteredGroupsAsArray = Object.entries(groups).filter(([groupName]) => actualIds.includes(groupName.split('#')[1]))
      const filteredGroups = Object.fromEntries(filteredGroupsAsArray)
      const keys = Object.keys(filteredGroups).map(key => key.replace(/#/, '&').split('&')[1])
      const indexes = []

      keys.forEach((key, index) => {
        if (key.split('#')[1] !== '0') return null
        indexes.push(index)
        return true
      })
      const names = Object.values(filteredGroups)
        .filter((value, index) => indexes.includes(index))
        .map(ob => ob.answer)
        .filter(v => !!v)

      // Setup answers for all form

      const sections = data['Caracterización socio-demográfica']['Caracterización Socio-demográfica']

      if (!sections) return null

      const sectionQuestions = Object.values(sections)

      let allGroups = {}
      const allParams = {}

      sectionQuestions.forEach(questions => {
        questions.forEach(q => {
          const answerBody = q.answers[0]
          if (answerBody.body === null) return null
          const answerForParams = answerBody.body
          const answerForGroups = answerBody.body.groups
          const answerId = answerBody.id
          allParams[answerId] = answerForParams
          allGroups = { ...allGroups, ...answerForGroups }
          return true
        })
      })

      this.setState({ nameOptions: names, params: allParams, groups: allGroups })
    }
    return null
  }

  renderGroups(forcedQuestion = false) {
    const { classes } = this.props
    const { data, nameOptions } = this.state
    const question = forcedQuestion || data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
    if (!question) return null

    const groups = question.answers[0].subquestion_groups || []
    const { id } = question.answers[0]
    const actions = question?.group_actions

    return groups.map((group, index) => {
      const { question: questions } = group
      const name = nameOptions[index] ?? 'Sin respuestas'
      return (
        <React.Fragment key={`${id}#${group.id}`}>
          <div className={classes.group}>
            {actions && !actions[0].add_text && <Typography variant="caption" className={classes.member}>{`${name}`}</Typography>}
            {this.renderGroupQuestion(questions, `${id}#${group.id}`)}
          </div>
        </React.Fragment>
      )
    })
  }

  renderGroupQuestion(questions, name) {
    if (!questions) return null

    const { groups, nameOptions } = this.state
    return questions.map((question, index) => {
      const info = { ...question, name: `${name}#${index}` }

      // Add family members as options to special selects

      if (question.type === 'select' && question.options === 'family-members') {
        if (!this.familyMembersQuestions.includes(`${name}#${index}`)) this.familyMembersQuestions.push(`${name}#${index}`)
        info.options = nameOptions
      }
      return (
        <FlexibleInput
          key={`${name}#${index * 2}`}
          complete={info}
          onChange={this.handleChangeGroup}
          onSubchange={this.handleGroupSubChange}
          value={groups[`${name}#${index}`]?.answer}
          subquestions={groups[`${name}#${index}`]?.subquestions}
          disabled
        />
      )
    })
  }

  renderResults() {
    const { classes } = this.props
    const { data, params } = this.state

    if (!data) return null

    const sections = data['Caracterización socio-demográfica']['Caracterización Socio-demográfica']

    if (!sections) return null

    const sectionNames = Object.keys(sections)
    const sectionQuestions = Object.values(sections)

    const sectionRender = sectionNames.map((name, index) => {
      const questions = sectionQuestions[index]
      const questionRender = questions.map(question => {
        if (!question) return null
        if (!question.answers[0]) return null
        const { id } = question.answers[0]
        const info = { ...question, name: id }
        return (
          <div className={classes.question}>
            <FlexibleInput
              complete={info}
              value={params[id]?.answer}
              subquestions={params[id]?.subquestions}
              onChange={this.handleChange}
              onSubchange={this.handleSubChange}
              disabled
            />
            {this.renderGroups(question)}
          </div>
        )
      })

      return (
        <div className={classes.section}>
          <div className={classes.sectionTitle}>
            <Typography variant="h2" className={classes.sectionTitleText}>{name}</Typography>
          </div>
          {questionRender}
        </div>
      )
    })

    return (
      <div>
        {this.renderCards()}
        {sectionRender}
        <div className={classes.submitButton}>
          <Button color="secondary" variant="contained" onClick={this.handleBack}>
            Volver
          </Button>
        </div>
      </div>
    )
  }

  renderCards() {
    const { data } = this.state
    const { classes, event, evaluation } = this.props

    const question = data['Caracterización socio-demográfica']['Descripción de hogar']?.empty[0]
    const answer = question?.answers[0]

    if (!answer) return null
    if (!answer.body) return null

    const groupQuestions = answer.subquestion_groups[0].question // questions and type for members

    const actualGroups = answer?.subquestion_groups
    const { groups } = answer?.body
    const actualIds = actualGroups.map(ag => ag.id)
    const filteredGroupsAsArray = Object.entries(groups).filter(([groupName]) => actualIds.includes(groupName.split('#')[1]))
    const filteredGroups = Object.fromEntries(filteredGroupsAsArray)
    const keys = Object.keys(filteredGroups)
    const keysStartingFromGroups = Object.keys(filteredGroups).map(key => key.replace(/#/, '&').split('&')[1])

    const member = [
      'name',
      'relation',
      'jefe',
      'gender',
      'birth',
    ]

    const members = {}

    // Group members by group_id
    keysStartingFromGroups.forEach((key, index) => {
      const keyInfo = key.split('#')
      const answerKey = keys[index]
      const groupId = keyInfo[0]
      const questionIndex = keyInfo[1]
      const questionObject = groupQuestions[questionIndex]
      let memberAnswer = filteredGroups[answerKey].answer
      if (questionObject.type === 'select') {
        const { options } = questionObject
        memberAnswer = options[parseInt(memberAnswer, 10)]
      }
      members[groupId] = { ...members[groupId], [member[questionIndex]]: memberAnswer }
    })

    const membersArray = Object.values(members)
    const memberRender = membersArray.map((processedMember, index) => {
      const isBoss = processedMember.jefe === '0'
      const isUser = processedMember.relation === 'Usuario'
      let Icon = false
      if (isBoss) Icon = Home
      if (isUser) Icon = AccountBox
      return (
        <div className={classes.memberCard} key={`${index * 2}`}>
          <div className={classes.cardInnerTitle}>
            <div>
              <Typography variant="subtitle1">{`Miembro del hogar N°${index + 1}`}</Typography>
              {isBoss && <Typography variant="caption">Jefe del hogar</Typography>}
            </div>
            {Icon && <Icon color="primary" />}
          </div>
          <div className={classes.memberInfo}>
            <div className={classes.infoLine}>
              <Typography variant="subtitle1">Nombre:</Typography>
              <Typography variant="subtitle1">{processedMember.name || 'No especifica'}</Typography>
            </div>
            <div className={classes.infoLine}>
              <Typography variant="subtitle1">Relación:</Typography>
              <Typography variant="subtitle1">{processedMember.relation || 'No especifica'}</Typography>
            </div>
            <div className={classes.infoLine}>
              <Typography variant="subtitle1">Género:</Typography>
              <Typography variant="subtitle1">{processedMember.gender || 'No especifica'}</Typography>
            </div>
            <div className={classes.infoLine}>
              <Typography variant="subtitle1">Edad:</Typography>
              <Typography variant="subtitle1">{processedMember.birth ? `${moment(new Date()).diff(processedMember.birth, 'years')} Años` : 'No especifica'}</Typography>
            </div>
            <div className={classes.infoLine}>
              <Typography variant="subtitle1">Fecha de Nacimiento:</Typography>
              <Typography variant="subtitle1">{processedMember.birth || 'No especifica'}</Typography>
            </div>
          </div>
        </div>
      )
    })
    return (
      <>
        <>
          <Typography variant="subtitle1">Observaciones</Typography>
          <GlobalComment event={event} evaluation={evaluation} />
        </>
        <div className={classes.cardTitle}>
          <Typography variant="h3">{`N° de integrantes del hogar: ${membersArray.length}`}</Typography>
        </div>
        <div className={classes.memberCards}>
          {memberRender}
        </div>
      </>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.container}>
        {this.renderResults()}
      </Paper>
    );
  }
}

CSDResult.contextType = SetViewContext

CSDResult.propTypes = {
  classes: propTypes.object.isRequired,
  competency: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  evaluation: propTypes.string.isRequired,
  endFunction: propTypes.func,
}

CSDResult.defaultProps = {
  endFunction: null
}

const mapStateToProps = state => ({
  competency: state.competency,
  contender: state.contenders.selected
})

export default connect(mapStateToProps)(withStyles(style)(CSDResult))
