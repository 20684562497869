import stateMaker from '../Utils/reducers'

const statusReducer = (state = { message: '' }, action) => {
  switch (action.type) {
  case 'SETUP_APP':
    return stateMaker(state, 'message', 'fetch', action)
  default:
    return state
  }
}

export default statusReducer
