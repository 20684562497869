const tabsNames = [
  'Resumen',
  'Evaluación',
  'Calendario',
  'Historial',
  'Salud',
  'Informes'
]

const alerts = [
  { text: 'Seguimiento Pendiente', id: 0 },
  { text: 'Evaluación', id: 1 },
  { text: 'Seguimiento Pendiente', id: 2 },
]

const days = [
  [
    { id: 0, date: '9 am - 10 am', body: 'Reunión con Juanito', user: 'Facilitador 01' },
    {
      id: 1,
      date: '12 pm - 2 pm',
      body: 'Escala de conducta adaptativas II de @Jorge en sala 02',
      user: 'Facilitador 01',
    },
    { id: 2, date: '9 am - 10 am', body: 'Taller de Habilidades Sociales', user: 'Facilitador 01' },
  ],
  [
    { id: 3, date: '9 am - 10 am', body: 'Reunión con Juanito', user: 'Facilitador 01' },
    {
      id: 4,
      date: '12 pm - 2 pm',
      body: 'Escala de conducta adaptativas II de @Jorge en sala 02',
      user: 'Facilitador 01',
    },
  ],
  [
    { id: 5, date: '9 am - 10 am', body: 'Reunión con Juanito', user: 'Facilitador 01' },
    {
      id: 6,
      date: '12 pm - 2 pm',
      body: 'Escala de conducta adaptativas II de @Jorge en sala 02',
      user: 'Facilitador 01',
    },
  ],
  [{ id: 7, date: '9 am - 10 am', body: 'Jornada Informativa', user: 'Facilitador 01' }],
  [{ id: 8, date: '9 am - 10 am', body: 'Jornada Informativa', user: 'Facilitador 01' }],
  [{ id: 9, date: '9 am - 10 am', body: 'Jornada Informativa', user: 'Facilitador 01' }],
]

export { tabsNames, alerts, days }
