import React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import FieldRenderer from '../../../../Utils/FieldRenderer'

const style = theme => ({
  companionName: {
    color: 'black',
    marginBottom: theme.spacing.unit * 2
  }
})

class IndicatorCheckbox extends React.Component {
  render() {
    const { classes, answers, complete, removeCompanionName } = this.props
    const { subquestions } = complete
    const subquestion = subquestions[0]
    return answers.map(value => {
      const subquestionValue = value.subquestions ? Object.values(value.subquestions)[0] : ''
      return (
        <div className={classes.container}>
          {!removeCompanionName && <Typography className={classes.companionName}>{`Respuesta de ${value.companion_name}`}</Typography>}
          <Typography>{value.answer}</Typography>
          <FieldRenderer
            onChange={() => ({})}
            complete={subquestion}
            value={subquestionValue}
            disabled
            answered
          />
        </div>
      )
    })
  }
}

IndicatorCheckbox.propTypes = {
  classes: propTypes.object.isRequired,
  answers: propTypes.arrayOf(propTypes.object),
  complete: propTypes.object.isRequired,
  removeCompanionName: propTypes.bool
}

IndicatorCheckbox.defaultProps = {
  answers: [],
  removeCompanionName: false
}

export default withStyles(style)(IndicatorCheckbox)
