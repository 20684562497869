import React, { Component } from 'react'
import propTypes from 'prop-types'
import Chart from 'chart.js'
import { withStyles, Typography } from '@material-ui/core'
import Conditional from '../Conditional'

const style = () => ({
  labels: {
    marginTop: 24,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 6
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    marginRight: 12
  }
})

class CircleChart extends Component {
  componentDidMount() {
    const { name, data, options } = this.props
    const chartElement = document.getElementById(name)
    const myChartRef = chartElement.getContext('2d');
    chartElement.parentNode.style.height = options.height || '100%';
    chartElement.parentNode.style.width = options.width || '100%';

    const labels = data.map(element => element.label)
    const values = data.map(element => element.value)
    const colors = data.map(element => element.color)
    // eslint-disable-next-line no-new
    this.chart = new Chart(myChartRef, {
      type: options.type || 'doughnut',
      data: {
        // Bring in data
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: colors,
            borderWidth: 0
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        cutoutPercentage: options.type === 'pie' ? 0 : 70,
        hover: {
          mode: null
        },
        legend: {
          display: false
        },
        tooltips: {
          bodyFontFamily: 'Montserrat, sans-serif',
          callbacks: {
            label(tooltipItem, tooltipData) {
              const { index } = tooltipItem
              const label = tooltipData.labels[index]
              const value = tooltipData.datasets[0].data[index]
              const total = tooltipData.datasets[0].data.reduce((a, b) => a + b, 0)
              let result = ` - ${label}: ${value}`
              if (options.percentages) {
                result = ` - ${label}: ${Math.round((value * 100) / total)}%`
              }
              return result
            }
          },
        },
        // animation: {
        //   onComplete: () => {
        //     const chartInstance = this.chart;
        //     const { ctx } = chartInstance;
        //     ctx.font = 'Montserrat, sans-serif';
        //     ctx.fontSize = '13px'
        //     ctx.textAlign = 'center';
        //     ctx.textBaseline = 'bottom';

        //     this.chart.data.datasets.forEach((dataset, i) => {
        //       for (var i = 0; i < dataset.data.length; i++) {
        //         const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
        //         const { total } = dataset._meta[Object.keys(dataset._meta)[0]];
        //         const mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2;
        //         const start_angle = model.startAngle;
        //         const end_angle = model.endAngle;
        //         const mid_angle = start_angle + (end_angle - start_angle) / 2;

        //         const x = mid_radius * Math.cos(mid_angle);
        //         const y = mid_radius * Math.sin(mid_angle);

        //         ctx.fillStyle = '#fff';
        //         if (i == 3) { // Darker text color for lighter background
        //           ctx.fillStyle = '#444';
        //         }
        //         const percent = `${String(Math.round(dataset.data[i] / total * 100))}%`;
        //         // Don't Display If Legend is hide or value is 0

        //         ctx.fillText(dataset.data[i], model.x + x, model.y + y);
        //         // Display percent in another line, line break doesn't work for fillText
        //         ctx.fillText(percent, model.x + x, model.y + y + 15);
        //       }
        //     });
        //   }
        // }
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (data !== prevProps.data) {
      const labels = data.map(element => element.label)
      const values = data.map(element => element.value)
      const colors = data.map(element => element.color)
      this.chart.data.labels = labels
      this.chart.data.datasets = [
        {
          data: values,
          backgroundColor: colors,
          borderWidth: 0
        },
      ]
      this.chart.update()
    }
  }

  renderLabels() {
    const { classes, data } = this.props
    return data.map(element => {
      const toReturn = (
        <div className={classes.label}>
          <div className={classes.dot} style={{ background: element.color }} />
          <Typography variant="body1" style={{ fontWeight: 600 }}>{element.label}</Typography>
        </div>
      )
      return toReturn
    })
  }

  render() {
    const { name, title, classes, options, showTitle } = this.props
    return (
      <div>
        {showTitle && (
          <Typography
            variant="subtitle1"
            color="grey"
            style={{ textAlign: 'start', marginBottom: 24 }}
          >
            {title}

          </Typography>
        )}
        <div>
          <canvas
            id={name}
            ref={this.chartRef}
          />
        </div>
        <Conditional condition={options.detachedLabels}>
          <div className={classes.labels}>
            {this.renderLabels()}
          </div>
        </Conditional>
      </div>
    )
  }
}

CircleChart.propTypes = {
  classes: propTypes.object.isRequired,
  title: propTypes.string,
  name: propTypes.string.isRequired,
  data: propTypes.arrayOf(propTypes.object),
  options: propTypes.object,
  showTitle: propTypes.bool,
}

CircleChart.defaultProps = {
  showTitle: true,
  data: [],
  title: 'Gráfico',
  options: {}
}

export default withStyles(style)(CircleChart)
