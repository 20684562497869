import stateMaker from '../Utils/reducers'

const routesReducer = (state = { in: '' }, action) => {
  switch (action.type) {
  case 'SET_ROUTE':
    return stateMaker(state, 'in', 'fetch', action)
  default:
    return state
  }
}

export default routesReducer
