import React, { Component } from 'react'
import { Typography, Paper, Divider, IconButton, Menu, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import propTypes from 'prop-types'
import { Assignment, Delete, Description, Edit, ImportExport, MoreVert } from '@material-ui/icons'
import moment from 'moment'
import autobind from 'Utils/autobind'
import ConfirmDeleteDialog from 'Shared/Dialogs/ConfirmDeleteDialog'
import { createAuthorization } from 'API/Authorizations'
import EditDialog from './EditDialog'
import StateChangeDialog from './StateChangeDialog'

const styles = theme => ({
  image: {
    borderRadius: '50%',
    width: 120,
    height: 120,
    margin: theme.spacing(2),
  },
  name: {
    color: theme.palette.grey.dark,
    fontSize: 24,
  },
  infoContainer: {
    padding: 12,
    paddingTop: 0,
    textAlign: 'left',
    '& h6': {
      color: theme.palette.grey.dark
    }
  },
  divider: {
    margin: 12,
  },
  subtitle: {
    marginTop: 18,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menu: {
    position: 'absolute',
    top: 6,
    right: 6
  },
  menuItem: {
    width: 150,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > svg': {
      height: 18,
      width: 18
    }
  }
})

const getGenderText = genderType => {
  if (genderType === 0) return 'Masculino'
  if (genderType === 1) return 'Femenino'
  return 'No ingresado'
}

class Info extends Component {
  constructor() {
    super()
    this.state = {
      openMenu: false,
      anchor: '',
      openDialog: false,
      openConfirmDeleteDialog: false,
      openStateChange: false
    }
    autobind(Info, this)
  }

  handleOpen(event) {
    const { target } = event
    const { openMenu } = this.state
    this.setState({ openMenu: !openMenu, anchor: target })
  }

  handleOpenEdit() {
    const { openDialog } = this.state
    this.setState({ openDialog: !openDialog, openMenu: false })
  }

  handleOpenStateChange() {
    const { openStateChange } = this.state
    this.setState({ openStateChange: !openStateChange, openMenu: false })
  }

  handleSetConfirmDeleteState(state) {
    return () => {
      this.setState({ openConfirmDeleteDialog: state })
    }
  }

  async handleCreateDeleteAuthorization() {
    const { contender } = this.props
    const body = {
      type_id: 7,
      text: `Borrar cuenta de usuario de ${contender.user.name}`,
      contender_id: contender.contender.id,
    }
    await createAuthorization(body, 'eliminar')
    this.setState({ openConfirmDeleteDialog: false })
  }

  render() {
    const { classes, contender } = this.props
    const { anchor, openMenu, openDialog, openStateChange, openConfirmDeleteDialog } = this.state
    return (
      <div>
        <StateChangeDialog open={openStateChange} onClose={this.handleOpenStateChange} />
        <Paper elevation={3} style={{ height: 'min-content', position: 'relative' }}>
          <IconButton size="small" color="primary" className={classes.menu} onClick={this.handleOpen}>
            <MoreVert />
          </IconButton>
          <img src={contender && contender.user.photo ? contender.user.photo : '/noimage.png'} alt="Logo" className={classes.image} />
          <Typography variant="h1" className={classes.name}>
            {contender && contender.user.name}
          </Typography>
          <Divider className={classes.divider} />
          <div className={classes.infoContainer}>
            <div className={classes.inline}>
              <Typography variant="subtitle1">Edad:</Typography>
              <Typography variant="subtitle2">{`${contender.user.date_birth ? `${moment(new Date()).diff(contender.user.date_birth, 'years')} años` : 'No ingresado'}`}</Typography>
            </div>
            <div className={classes.inline}>
              <Typography variant="subtitle1">Rut:</Typography>
              <Typography variant="subtitle2">{`${contender.user.rut || 'No ingresado'}`}</Typography>
            </div>
            <div className={classes.inline}>
              <Typography variant="subtitle1">Fecha de nacimiento:</Typography>
              <Typography variant="subtitle2">{contender.user.date_birth || 'No ingresado'}</Typography>
            </div>
            <div className={classes.inline}>
              <Typography variant="subtitle1">Comuna:</Typography>
              <Typography variant="subtitle2">{contender.commune || 'No ingresado'}</Typography>
            </div>
            <div className={classes.inline}>
              <Typography variant="subtitle1">Género:</Typography>
              <Typography variant="subtitle2">{getGenderText(contender.user.gender)}</Typography>
            </div>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Contacto Postulante
            </Typography>
            <div className={classes.inline}>
              <Typography variant="subtitle1">Celular:</Typography>
              <Typography variant="subtitle2">{contender.user.phone || 'No ingresado'}</Typography>
            </div>
            <div className={classes.inline}>
              <Typography variant="subtitle1">Email:</Typography>
              <Typography variant="subtitle2">{`${contender.user.email || 'No ingresado'}`}</Typography>
            </div>
            <div className={classes.inline}>
              <Typography variant="subtitle1">Direccion:</Typography>
              <Typography variant="subtitle2">{contender.user.address || 'No ingresado'}</Typography>
            </div>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Contacto Acompañante
            </Typography>
            {contender && contender.contacts
              && contender.contacts.length ? contender.contacts.map(contact => (
                <div key={contact.id}>
                  <div className={classes.inline}>
                    <Typography variant="subtitle1">Nombre:</Typography>
                    <Typography variant="subtitle2">{`${contact.name}`}</Typography>
                  </div>
                  <div className={classes.inline}>
                    <Typography variant="subtitle1">Celular:</Typography>
                    <Typography variant="subtitle2">{`${contact.phone}`}</Typography>
                  </div>
                  <div className={classes.inline}>
                    <Typography variant="subtitle1">Email:</Typography>
                    <Typography variant="subtitle2">{`${contact.email}`}</Typography>
                  </div>
                  <div className={classes.inline}>
                    <Typography variant="subtitle1">Relación:</Typography>
                    <Typography variant="subtitle2">{`${contact.relation ? contact.relation : ''}`}</Typography>
                  </div>
                  <Divider />
                </div>
              ))
              : <Typography variant="subtitle1">Sin registro...</Typography>}
          </div>
        </Paper>
        <EditDialog open={openDialog} onClose={this.handleOpenEdit} contender={contender} />
        <Menu anchorEl={anchor} open={openMenu} onClose={this.handleOpen}>
          <MenuItem>
            <div className={classes.menuItem}>
              <Typography variant="subtitle1">
                Archivos
              </Typography>
              <Description />
            </div>
          </MenuItem>
          <MenuItem onClick={this.handleOpenEdit}>
            <div className={classes.menuItem}>
              <Typography variant="subtitle1">
                Editar Perfil
              </Typography>
              <Edit />
            </div>
          </MenuItem>
          <MenuItem>
            <div className={classes.menuItem}>
              <Typography variant="subtitle1">
                Crear CV
              </Typography>
              <Assignment />
            </div>
          </MenuItem>
          <MenuItem onClick={this.handleOpenStateChange}>
            <div className={classes.menuItem}>
              <Typography variant="subtitle1">
                Cambio de estado
              </Typography>
              <ImportExport />
            </div>
          </MenuItem>
          <MenuItem onClick={this.handleSetConfirmDeleteState(true)}>
            <div className={classes.menuItem}>
              <Typography variant="subtitle1">
                Borrar cuenta
              </Typography>
              <Delete />
            </div>
          </MenuItem>
        </Menu>
        <ConfirmDeleteDialog
          onCancel={this.handleSetConfirmDeleteState(false)}
          onConfirm={this.handleCreateDeleteAuthorization}
          isOpen={openConfirmDeleteDialog}
          description="Al confirmar, estarás generando una autorización para eliminar esta cuenta de usuario"
        />
      </div>
    )
  }
}

Info.defaultProps = {
  contender: undefined,
}

Info.propTypes = {
  classes: propTypes.object.isRequired,
  contender: propTypes.shape({
    user: propTypes.object,
    contender: propTypes.object,
    contacts: propTypes.arrayOf(propTypes.object),
    commune: propTypes.string,
  }),
}

export default withStyles(styles)(Info)
