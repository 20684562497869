import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { Button, Divider, Typography, withStyles } from '@material-ui/core'
import { logoutAction } from '../../Actions/Login'
import StepContainer from '../../Shared/StepContainer'
import Welcome from './Welcome'
import GencatResult from './GencatResult'
import StepFormContainer from '../../Shared/StepFormContainer'
import { loadEventAction, markFormAsFinishedAction } from '../../Actions/Events'
import Loader from '../../Shared/Loader'
import autobind from '../../Utils/autobind'

const style = theme => ({
  message: {
    textAlign: 'center',
    width: '85%',
    margin: 'auto',
    marginTop: 46
  },
  bold: {
    fontWeight: 600
  },
  textContent: {
    margin: '12px 0',
    fontSize: 15
  },
  divider: {
    height: 2,
    background: theme.palette.grey.border,
    marginBottom: 46,
  }
})

const tooltipsFS = [
  { section: 'Previsión de salud y emergencia', tooltip: 'Información general del usuario' },
  { section: 'Salud Física', tooltip: `En este apartado se busca conocer todos los diagnósticos de salud física que presenta el usuario en los diferentes ámbitos. Una persona puede tener más de un diagnóstico en el mismo ámbito.
  Si posee diagnósticos en alguno de estos ámbitos, presionar "abrir grupo" para completar toda la información referente a ese diagnóstico.
  Recordar completar el desplegable "abrir grupo" para especificar la información de cada diagnóstico por separado.
   Si se desconoce información siempre registrar "Sin información".` },
  { section: 'Salud Mental', tooltip: `En este apartado se busca conocer los diagnósticos de salud de origen genético, hereditario o perinatal
  Si posee diagnósticos en alguno de estos ámbitos, presionar "abrir grupo" para completar toda la información referente a ese diagnóstico.
  Recordar completar el desplegable "abrir grupo" para especificar la información de cada diagnóstico por separado.
   Si se desconoce información siempre registrar "Sin información"` },
  { section: 'Medicamentos', tooltip: `En esta categoría se busca conocer el esquema farmacológico actual y el historial de medicamentos del usuario para realizar acompañamientos ajustados.
  Si utiliza o ha utilizado medicamentos presionar "abrir grupo" para completar toda la información referente a ese medicamento.
  Recordar completar el desplegable "abrir grupo" para especificar la información de cada diagnóstico por separado.
   Si se desconoce información siempre registrar "Sin información"` },
  { section: 'Hospitalizaciones', tooltip: `Se busca conocer la ocurrencia de episodios  de salud que han requerido de hospitalizaciones para su abordaje y que pudiesen afectar en futuras situaciones. 
  Recordar especificar motivo y lugar de atención. 
  Si se desconoce información siempre registrar "Sin información" ` },
  { section: 'Eventos importantes', tooltip: `Registrar eventos traumáticos o accidentes que le hayan ocurrido al usuario durante el periodo cercano a su nacimiento y durante su vida. 
  En "eventos traumáticos" recordar "abrir grupo" y  especificar la información respecto al evento ocurrido.
  Si se desconoce información siempre registrar "Sin información"` },
  { section: 'Evaluación Sensorial', tooltip: 'La evaluación sensorial busca identificar conductas que podrían alertar sobre dificultades asociadas a la organización de los estímulos que pueden requerir apoyo en el contexto laboral' },
  { section: 'Salud Sexual', tooltip: 'En este apartado se busca obtener información respecto a la salud sexual del usuario. Antes de realizar estas preguntas cuidar que el contexto sea privado y adecuado para la conversación. ' }
]

class HomeGencat extends Component {
  constructor(props) {
    super(props)
    autobind(HomeGencat, this)
  }

  componentDidMount() {
    const { logout, match } = this.props
    if (match.path.includes('mail')) {
      logout()
    }
  }

  handleBack() {
    const { history } = this.props
    history.goBack()
  }

  handleCheck(forms) {
    return () => {
      const { markFormAsFinished, enqueueSnackbar, match } = this.props
      const [form1, form2] = forms
      const body1 = {
        form_id: form1.id,
        user_id: match.params.contender_id,
        event_id: match.params.event_id,
        status: 'check'
      }
      const body2 = { ...body1 }
      body2.form_id = form2.id
      markFormAsFinished(body1, enqueueSnackbar).then(() => {
        markFormAsFinished(body2, enqueueSnackbar)
      })
    }
  }

  render() {
    const { events, match, loadEvent, classes } = this.props
    const { forms } = events.selected
    let disabled = false
    let keys
    const formList = []
    const formIds = events.selected.forms_id
    if (forms) {
      keys = Object.keys(forms)
      disabled = match.path.includes('events')
      keys.forEach(key => formList.push(forms[key]))
    }

    const fichaMessage = (
      <div className={classes.message}>
        <Typography variant="subtitle2" className={classes.textContent}>
          Muchas gracias por acceder a completar la información. Esta ficha contiene preguntas
          referentes a la salud de la persona con discapacidad que usted acompaña.
        </Typography>
        <Typography variant="subtitle2" className={classes.textContent}>
          La información aquí entregada es de carácter confidencial.
        </Typography>
        <Typography variant="subtitle2" className={classes.textContent}>
          Por favor recuerde responder todas las preguntas
        </Typography>
        <Typography variant="subtitle2" className={classes.textContent}>
          Si hay alguna respuesta que no conoce, seleccione la opción
          {' '}
          <span className={classes.bold}>Sin Información</span>
        </Typography>
        <Divider className={classes.divider} />
      </div>
    )

    return (
      <>
        <Loader action={loadEvent} params={[match.params.event_id]}>
          <>
            {disabled
              ? (
                <Button
                  onClick={this.handleBack}
                  style={{ position: 'absolute', top: 24, left: '50%', transform: 'translate(-50%, 0)' }}
                  variant="outlined"
                >
                  Volver a la lista
                </Button>
              )
              : <img src="/Asset1.png" alt="logo" style={{ position: 'absolute', top: 24, left: 24, width: 76 }} />}
            <StepContainer>
              <Welcome disabled={disabled} markReady={this.handleCheck} />
              {formList.length > 0
                ? <StepFormContainer tooltips={tooltipsFS} form={formList[1]} title={keys ? keys[1] : 'Cargando...'} disabled={disabled} message={fichaMessage} />
                : <StepFormContainer form={{}} title={keys ? keys[1] : 'Cargando...'} disabled={disabled} message={fichaMessage} />}
              <GencatResult event={events.selected} forms={formIds} markReady={this.handleCheck} />
            </StepContainer>
          </>
        </Loader>
      </>
    )
  }
}

HomeGencat.propTypes = {
  logout: propTypes.func.isRequired,
  events: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  loadEvent: propTypes.func.isRequired,
  markFormAsFinished: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  classes: propTypes.object.isRequired
}

HomeGencat.defaultProps = {

}

const mapStateToProps = state => ({
  events: state.events
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction()),
  loadEvent: id => dispatch(loadEventAction(id)),
  markFormAsFinished: (body, snackbar) => dispatch(markFormAsFinishedAction(body, snackbar))
})

export default withStyles(style)(
  withSnackbar(withRouter((connect(mapStateToProps, mapDispatchToProps)(HomeGencat))))
)
