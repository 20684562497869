import React, { Component } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    margin: '24px 0 36px 0'
  }
})

class ResultTable extends Component {
  constructor() {
    super()
    this.state = {
      headers: []
    }
  }

  componentDidMount() {
    const { data } = this.props
    const headers = Object.keys(Object.values(data)[0])
    const lastHeader = headers.splice(1, 1)
    headers.push(lastHeader[0])
    this.setState({ headers })
  }

  renderHeaders() {
    const { headers = [] } = this.state
    const headerCells = headers.map(name => <TableCell style={{ textAlign: 'center', fontWeight: 600 }}>{name?.split('-')[0]}</TableCell>)
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '35vw' }} />
          {headerCells}
        </TableRow>
      </TableHead>
    )
  }

  renderBody() {
    const { data } = this.props
    const { headers } = this.state
    const rows = Object.entries(data).map(([question, values]) => {
      const valueCells = headers.map(header => (
        <TableCell style={{ border: '1px solid rgba(224, 224, 224, 1)', textAlign: 'center' }}>{values[header]}</TableCell>
      ))
      return (
        <TableRow>
          <TableCell>{question}</TableCell>
          {valueCells}
        </TableRow>
      )
    })
    const lastHeader = headers[headers.length - 1]
    let sumOfAvg = 0
    const amountOfAnswers = Object.values(data).length
    Object.values(data).forEach(values => {
      sumOfAvg += parseInt(values[lastHeader]?.split('%')[0], 10)
    })

    const finalValue = Math.round(sumOfAvg / amountOfAnswers)
    const finalRow = (
      <TableRow>
        {headers.map(() => <TableCell style={{ border: 'none' }} />)}
        <TableCell style={{ textAlign: 'center', color: '#1F8095', fontWeight: 600, border: 'none' }}>
          {finalValue}
          %
        </TableCell>
      </TableRow>
    )
    return (
      <TableBody>
        {rows}
        {finalRow}
      </TableBody>
    )
  }

  render() {
    const { classes, title } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h2" style={{ fontWeight: 600 }}>{title}</Typography>
        <Table style={{ tableLayout: 'fixed' }}>
          {this.renderHeaders()}
          {this.renderBody()}
        </Table>
      </div>
    );
  }
}

ResultTable.propTypes = {
  classes: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
  data: propTypes.object.isRequired,
}

ResultTable.defaultProps = {

}
export default withStyles(style)(ResultTable)
