import getPercentileFamiliar, { getFinalPercentile } from './Baremos'

export const criteria = [
  'Clima Familiar',
  'Autonomía de la Persona con Discapacidad Intelectual',
  'Bienestar Económico',
  'Organización y funcionamiento familiar',
  'Apoyo familiar a la persona con discapacidad intelectual',
  'Sobrecarga del cuidador'
]

export const criteriaReduced = [
  'Clima Familiar',
  'Autonomía de la Persona con Discapacidad Intelectual',
  'Bienestar Económico',
  'Organización y funcionamiento familiar',
  'Apoyo familiar a la persona con discapacidad intelectual',
]

export const supportLevels = [
  'Bastante apoyo',
  'Apoyo ocasional',
  'Poco apoyo',
  'No requiere apoyo',
]

export const sobrecargaLevel = [
  'Ausencia de Sobrecarga',
  'Cuidador presenta Sobrecarga'
]

export function abreviateCriteria(name) {
  const lowerName = name.toLowerCase()
  const newName = lowerName.replace('persona con discapacidad intelectual', 'PCDI')
  const finalName = newName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  return finalName
}

export function calculatePercentiles(questions, user, reduced = false) {
  const results = {}
  const allCriteria = reduced ? criteriaReduced : criteria
  allCriteria.forEach(criterium => {
    let total = 0
    questions.forEach(question => {
      if (question.criteria.includes(criterium)) {
        const answer = question.answers.find(a => a.user_id === user.id).body
        total += answer
      }
    })
    results[criterium] = getPercentileFamiliar(total, criterium)
  })
  return results
}

export function calculateSupportLevels(questions, user) {
  const results = {}
  criteria.forEach(criterium => {
    let total = 0
    questions.forEach(question => {
      if (question.criteria.includes(criterium)) {
        const answer = question.answers.find(a => a.user_id === user.id).body
        total += answer
      }
    })
    results[criterium] = total
  })
  return results
}

export function calculateTotalPercentile(questions, user) {
  let total = 0
  questions.forEach(question => {
    if (question.criteria.includes('Sobrecarga del cuidador')) return null
    const answer = question.answers.find(a => a.user_id === user.id).body
    total += answer
    return true
  })
  return getFinalPercentile(total)
}

export function firstUpperCase(word) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}
