import React from 'react'
import { withStyles } from '@material-ui/core'
import { AddComment } from '@material-ui/icons'
import MuiTextfield from '@material-ui/core/TextField'
import propTypes from 'prop-types'

const style = () => ({
  disabled: {
    color: 'black'
  }
})

class AnsweredComment extends React.Component {
  render() {
    const { containerStyle, value, classes } = this.props
    return (
      <div style={containerStyle}>
        <div style={{ display: 'flex', width: '100%' }}>
          <AddComment style={{ marginRight: 8 }} color="primary" />
          <MuiTextfield
            value={value}
            fullWidth
            inputProps={{ style: { padding: '6px 0 7px 6px' } }}
            InputProps={{ style: { borderRadius: 0 }, classes: { disabled: classes.disabled } }}
            variant="filled"
            disabled
          />
        </div>
      </div>
    )
  }
}

AnsweredComment.propTypes = {
  classes: propTypes.object.isRequired,
  containerStyle: propTypes.object,
  value: propTypes.string
}

AnsweredComment.defaultProps = {
  containerStyle: {},
  value: ''
}

export default withStyles(style)(AnsweredComment)
