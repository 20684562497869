import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    padding: 24,
    '& > *': {
      margin: '6px 0'
    }
  },
  buttons: {
    textAlign: 'end',
    marginTop: 12,
    '& > *': {
      marginLeft: 12
    }
  }
})

class WarningDialog extends Component {
  render() {
    const { classes, action, open, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose}>
        <div className={classes.container}>
          <Typography variant="subtitle1">¿Seguro que quieres eliminar estos elementos?</Typography>
          <div className={classes.buttons}>
            <Button onClick={onClose}>
              Cancelar
            </Button>
            <Button color="primary" variant="contained" onClick={action}>
              Eliminar
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

WarningDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func,
  action: propTypes.func,
}

WarningDialog.defaultProps = {
  open: false,
  onClose: undefined,
  action: undefined
}

export default withStyles(style)(WarningDialog)
