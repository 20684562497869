import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import BarChart from 'Shared/Charts/BarChart'
import DownloadButton from 'Components/Dashboard/DownloadButton'

const style = () => ({
  container: {
    padding: 24,
    '& > h2': {
      fontWeight: 600,
    },
    '& > h6': {
      marginBottom: 24
    },
    borderRadius: 24
  },
  mainContainer: {
    position: 'relative',
    margin: 12,
  },
  labels: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: 12
    }
  },
  label: {
    height: 16,
    width: 32,
    borderRadius: 3,
    marginRight: 6
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center'
  }
})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D'
]

class PerformanceSection extends Component {
  render() {
    const { classes, data, subtitle } = this.props;
    const name = Object.keys(data)[0]
    const chartData = Object.values(data)[0]
    const criteria = Object.keys(chartData)
    const values = Object.values(chartData)
    const finalData = criteria.map((crit, index) => ({
      label: crit, value: values[index], color: colors[index]
    }))
    return (
      <div className={classes.mainContainer}>
        <DownloadButton title={`${name}chart`} name={`${name}-${subtitle}`} />
        <Paper className={classes.container} id={`${name}chart`}>
          <Typography variant="h2">{name}</Typography>
          <Typography variant="subtitle1">{subtitle}</Typography>
          <BarChart
            data={finalData}
            name={`performance${name}`}
            showTitle={false}
            options={{
              detachedLabels: false,
              maxValue: 100,
              stepSize: 25,
              yEnableGrid: false,
              xEnableGrid: true,
              parseLabelFunc: value => `${value} %`,
              legend: false,
              width: '100%',
              barThickness: 24,
              type: 'horizontalBar'
            }}
          />
          <div className={classes.labels}>
            <div className={classes.labelContainer}>
              <div className={classes.label} style={{ background: '#1F8095', }} />
              <Typography variant="subtitle1">Rotativa 1</Typography>
            </div>
            <div className={classes.labelContainer}>
              <div className={classes.label} style={{ background: '#D5602C', }} />
              <Typography variant="subtitle1">Rotativa 2</Typography>
            </div>
            <div className={classes.labelContainer}>
              <div className={classes.label} style={{ background: '#FFB33D' }} />
              <Typography variant="subtitle1">Rotativa 3</Typography>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

PerformanceSection.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
  subtitle: propTypes.string
}

PerformanceSection.defaultProps = {
  subtitle: ''
}
export default withStyles(style)(PerformanceSection)
