import React from 'react'
import { TextField, Typography } from '@material-ui/core'
import propTypes from 'prop-types'

const MyTextField = ({ name, label, value, onChange, placeholder, labelColor, ...props }) => (
  <>
    {label && (
      <Typography style={{ textAlign: 'left', color: labelColor }} variant="body2">
        {label}
      </Typography>
    )}
    <TextField
      onChange={onChange}
      name={name}
      inputProps={{ style: { padding: '6px 0 7px 6px' } }}
      InputProps={{ style: { borderRadius: 0 } }}
      value={value}
      variant="filled"
      fullWidth
      placeholder={placeholder}
      style={{ marginBottom: 6 }}
      {...props}
    />
  </>
)

MyTextField.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  placeholder: propTypes.string,
  labelColor: propTypes.string,
}

MyTextField.defaultProps = {
  placeholder: '',
  labelColor: '#3E3E3E'
}

export default MyTextField
