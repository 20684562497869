import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Paper } from '@material-ui/core'
import propTypes from 'prop-types'
import DayListComponent from './DayList'
import Header from '../../../History/HistoryHeader'
import autobind from '../../../../Utils/autobind'
import { loadContenderEventsAction, searchContenderEventsAction } from '../../../../Actions/Contenders'
import { getContenderEvents } from '../../../../Utils/functions'
import setSearchQueryAction from '../../../../Actions/Search'

class Calendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 0
    }
    autobind(Calendar, this)
  }

  componentDidMount() {
    const { loadContenderEvents, setSearchQuery, contender } = this.props
    setSearchQuery()
    loadContenderEvents(contender.selected.contender.id)
  }

  handleDateChange(option) {
    if (option === 'add') {
      this.setState(prevState => ({ offset: prevState.offset + 1 }))
    } else {
      this.setState(prevState => ({ offset: prevState.offset - 1 }))
    }
  }

  handleTodayClick() {
    this.setState({ offset: 0 })
  }

  render() {
    const { contender, searchContenderEvents, search } = this.props
    const { offset } = this.state
    const info = search.query ? contender.search : contender.selected.events
    const offsetUnit = 'weeks'
    const currentDate = moment().add(offset, offsetUnit)
    return (
      <Paper>
        <Header
          changeDate={this.handleDateChange}
          currentDate={currentDate}
          handleToday={this.handleTodayClick}
          searchFunc={searchContenderEvents(contender.selected.contender.id)}
        />
        <DayListComponent
          offset={offset}
          offsetUnit={offsetUnit}
          info={info}
          formatFunc={getContenderEvents}
          showEmpty
        />
      </Paper>
    )
  }
}

Calendar.propTypes = {
  loadContenderEvents: propTypes.func.isRequired,
  searchContenderEvents: propTypes.func.isRequired,
  contender: propTypes.object.isRequired,
  setSearchQuery: propTypes.func.isRequired,
  search: propTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user.current,
  contender: state.contenders,
  search: state.search
})

const mapDispatchToProps = dispatch => ({
  loadContenderEvents: id => dispatch(loadContenderEventsAction(id)),
  searchContenderEvents: id => query => dispatch(searchContenderEventsAction(id, query)),
  setSearchQuery: query => dispatch(setSearchQueryAction(query))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar)
