import moment from 'moment'
import instance from '../Utils/client'
import createSnackbar from '../Utils/snackbars'
import { getUrlWithQueryString } from '../Utils/functions'

const loadEventsAction = (onlyActive = false) => {
  let url = '/events?'
  url += onlyActive ? 'active=true' : ''
  return {
    type: 'LOAD_EVENTS',
    payload: instance({
      method: 'get',
      url,
    }),
  }
}

const searchEventsAction = queryString => ({
  type: 'SEARCH_EVENTS',
  payload: instance({
    method: 'get',
    url: `/events?query=${queryString}`,
  }),
})

const loadGoogleCalendarsAction = () => ({
  type: 'LOAD_GOOGLE_CALENDARS',
  payload: instance({
    method: 'get',
    url: '/events/google_calendars',
  }),
})

const createEventAction = (event, snackbar = null, callback = null) => {
  const REQUEST = instance({
    method: 'post',
    url: '/events',
    data: event,
  }).then(response => {
    if (callback) {
      callback(response)
    }
    return response
  })
  if (snackbar) {
    createSnackbar(REQUEST, snackbar)
  }
  return {
    type: 'CREATE_EVENT',
    payload: REQUEST,
  }
}

const createGencatAction = (event, refId, callback) => {
  const REQUEST = instance({
    method: 'post',
    url: `/events/${refId}/gencat`,
    data: event,
  }).then(response => {
    if (callback) {
      callback(response)
    }
    return response
  })
  return {
    type: 'CREATE_GENCAT_EVENT',
    payload: REQUEST,
  }
}

const editEventAction = (event, snackbar) => {
  const REQUEST = instance({
    method: 'put',
    url: `/events/${event.eventLoaded}`,
    data: event,
  })
  createSnackbar(REQUEST, snackbar)
  return {
    type: 'EDIT_EVENT',
    payload: REQUEST,
  }
}

const deleteEventAction = (eventId, snackbar) => {
  const REQUEST = instance({
    method: 'delete',
    url: `/events/${eventId}`,
  })
  createSnackbar(REQUEST, snackbar)
  return {
    type: 'DELETE_EVENT',
    payload: REQUEST,
  }
}

const setGoogleCalendarAction = googleId => ({
  type: 'SET_GOOGLE_CALENDAR_ID',
  payload: instance({
    method: 'post',
    url: '/events/google_calendars/set',
    data: {
      google_calendar_id: googleId,
    },
  }),
})

const selectEventAction = event => ({
  type: 'SELECT_EVENT',
  payload: {
    data: {
      info: event,
      status: 'success',
    },
  },
})

const loadEventAction = (id, callback, params) => {
  const baseUrl = `/events/${id}`
  const url = getUrlWithQueryString(baseUrl, params)
  const REQUEST = instance({
    url,
    method: 'get',
  })

  if (callback) {
    REQUEST.then(() => {
      callback()
    })
  }

  return {
    type: 'LOAD_EVENT',
    payload: REQUEST,
  }
}

const loadResultAction = body => {
  const REQUEST = instance({
    url: `/events/${body.event_id}/forms/${body.form_id}/result?type=gathering`,
    method: 'get',
  })

  return {
    type: 'LOAD_RESULT',
    payload: REQUEST,
  }
}

const sendAnswersAction = (body, id) => {
  const REQUEST = instance({
    url: `/events/${id}/answer`,
    method: 'put',
    data: {
      ...body,
    },
  })
  return {
    type: 'SEND_ANSWERS',
    payload: REQUEST,
  }
}

const addParticipantsAction = (eventId, participants) => {
  const REQUEST = instance({
    method: 'post',
    url: `/events/${eventId}/participants`,
    data: { participants },
  })
  return {
    type: 'ADD_PARTICIPANTS',
    payload: REQUEST,
  }
}

const sendEventAction = (id, option) => {
  const REQUEST = instance({
    method: 'get',
    url: `/events/${id}/send_${option}`
  })
  return {
    type: 'SEND_EVENT',
    payload: REQUEST
  }
}

const setEventAction = body => ({
  type: 'SET_EVENT',
  payload: body
})

const sendGencatAction = (userId, eventId, callback, snackbar) => {
  const REQUEST = instance({
    method: 'post',
    url: `/users/${userId}/gencat/${eventId}`,
    data: { date: moment().format('DD-MM-YYYY') },
  }).then(response => {
    if (callback) {
      callback()
    }
    return response
  })
  if (snackbar) {
    createSnackbar(REQUEST, snackbar)
  }
  return {
    type: 'SEND_GENCAT',
    payload: REQUEST,
  }
}

const markFormAsFinishedAction = body => {
  const data = body.status ? { status: body.status } : {}
  const REQUEST = instance({
    method: 'post',
    url: `events/${body.event_id}/forms/${body.form_id}/status`,
    params: { user: body.user_id },
    data
  })
  return {
    type: 'CHECK_FORM',
    payload: REQUEST
  }
}

const sendCompanionInterviewAction = body => {
  const REQUEST = instance({
    method: 'put',
    url: `events/${body.event_id}/indicator_answer`,
    data: body.data
  })
  return {
    type: 'SEND_INDICATOR_ANSWER',
    payload: REQUEST
  }
}

const addSubgroupAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `answers/${body.answer_id}/add_subquestion_group?group_id=${body.subquestion_group_id}`,
  })
  return {
    type: 'LOAD_EVENT',
    payload: REQUEST
  }
}

const deleteSubgroupAction = body => {
  const REQUEST = instance({
    method: 'delete',
    url: `answers/${body.answer_id}/delete_subquestion_group?answer_has_group_id=${body.answer_has_group_id}`,
  })
  return {
    type: 'LOAD_EVENT',
    payload: REQUEST
  }
}

const addExternalSubgroupAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `answers/${body.answer_id}/add_external_subquestion_group?group_id=${body.subquestion_group_id}`,
  })
  return {
    type: 'LOAD_EVENT',
    payload: REQUEST
  }
}

const deleteExternalSubgroupAction = body => {
  const REQUEST = instance({
    method: 'delete',
    url: `answers/${body.answer_id}/delete_external_subquestion_group?answer_has_group_id=${body.answer_has_group_id}`,
  })
  return {
    type: 'LOAD_EVENT',
    payload: REQUEST
  }
}

const allocateEvaluationAction = body => {
  const REQUEST = instance({
    method: 'put',
    url: `evaluations/${body.evaluation_id}/allocate`,
    data: body.data
  })
  return {
    type: 'ALLOCATE_EVALUATION',
    payload: REQUEST
  }
}

const allocateEvaluationGroupAction = body => {
  const REQUEST = instance({
    method: 'put',
    url: `evaluation_groups/${body.evaluation_group_id}/allocate`,
    data: body.data
  })
  return {
    type: 'ALLOCATE_EVALUATION',
    payload: REQUEST
  }
}

export {
  allocateEvaluationAction,
  addExternalSubgroupAction,
  addParticipantsAction,
  addSubgroupAction,
  allocateEvaluationGroupAction,
  createEventAction,
  createGencatAction,
  editEventAction,
  deleteEventAction,
  deleteSubgroupAction,
  deleteExternalSubgroupAction,
  loadEventsAction,
  loadGoogleCalendarsAction,
  loadEventAction,
  loadResultAction,
  markFormAsFinishedAction,
  setGoogleCalendarAction,
  selectEventAction,
  sendAnswersAction,
  searchEventsAction,
  sendEventAction,
  setEventAction,
  sendGencatAction,
  sendCompanionInterviewAction
}
