import React, { Component } from 'react'
import { Button, Dialog, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { NoteAdd } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { TextField } from '../../Shared/Fields/Text'
import DialogHeader from '../../Shared/Dialogs/DialogHeader'
import DialogButtons from '../../Shared/Dialogs/DialogButtons'
import Select from '../../Shared/Fields/Select'
import { getAllCommunes } from '../../API/Communes'
import { getAllAreas } from '../../API/Areas'

const style = () => ({
  container: {
    padding: 24,
  },
  form: {
    '& > *': {
      margin: '6px 0'
    }
  }
})

class ProgramDialog extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      communes: [],
      areas: []
    }
    autobind(ProgramDialog, this)
  }

  async componentDidMount() {
    const communeResponse = await getAllCommunes()
    const areasResponse = await getAllAreas()
    if (communeResponse?.data?.status !== 'success') return null
    const communes = communeResponse.data.info
    const areas = areasResponse.data.info
    return this.setState({ communes, areas })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleSubmit() {
    const { onSubmit } = this.props
    const { params } = this.state
    const body = { ...params }
    onSubmit(body)
    this.setState({ params: {} })
    this.handleCloseWrapper()
  }

  handleCloseWrapper() {
    const { onClose } = this.props
    this.setState({ params: {} })
    onClose()
  }

  render() {
    const { classes, open, onClose } = this.props;
    const { params, communes, areas } = this.state
    const communeOptions = communes.map(commune => ({ label: commune.name, value: commune.id }))
    const areaOptions = areas.map(area => ({ label: area.name, value: area.id }))
    return (
      <Dialog open={open} onClose={this.handleCloseWrapper} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <DialogHeader icon={NoteAdd} title="Agregar facilitador" onClose={onClose} />
          <div className={classes.form}>
            <TextField complete={{ text: 'Nombre', name: 'name' }} onChange={this.handleChange} value={params.name} />
            <TextField complete={{ text: 'E-mail', name: 'email' }} onChange={this.handleChange} value={params.email} />
            <TextField complete={{ text: 'Teléfono', name: 'phone' }} onChange={this.handleChange} value={params.phone} />
            <TextField complete={{ text: 'Rut', name: 'rut' }} onChange={this.handleChange} value={params.rut} />
            <Select complete={{ text: 'Comuna', name: 'commune_id', options: communeOptions }} onChange={this.handleChange} value={params.commune_id} />
            <Select complete={{ text: 'Area', name: 'area_id', options: areaOptions }} onChange={this.handleChange} value={params.area_id} />
            <TextField complete={{ text: 'Contraseña', name: 'password', type: 'password', multiline: false }} onChange={this.handleChange} value={params.password} />
          </div>
          <DialogButtons>
            <Button onClick={onClose}>
              Volver
            </Button>
            <Button onClick={this.handleSubmit} color="primary" variant="contained">
              Crear
            </Button>
          </DialogButtons>
        </div>
      </Dialog>
    );
  }
}

ProgramDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired
}

ProgramDialog.defaultProps = {
  open: false
}

const mapStateToProps = state => ({
  enterprises: state.enterprises
})

export default connect(mapStateToProps)(withStyles(style)(ProgramDialog))
