import instance from '../Utils/client'

const messageAction = () => ({
  type: 'SETUP_APP',
  payload: {
    data: {
      status: 'success',
      info: messageAction,
    }
  },
})

const requestAction = () => {
  const REQUEST = instance.get('/events')
  return {
    type: 'TEST_REQUEST',
    payload: REQUEST
  }
}

export { messageAction, requestAction }
