import React from 'react'
import { withStyles, Typography, Divider, Button, Checkbox, Tooltip } from '@material-ui/core'
import { ArrowDownwardOutlined, CheckCircle, Info } from '@material-ui/icons'
import propTypes from 'prop-types'
import LineChart from './LineChartGencat'
import Conditional from '../../../../Shared/Conditional'
import { splitPhrase } from '../../../../Utils/functions'

const chartOptions = {
  height: '400px',
  width: '100%',
  enableHover: false,
  detachedLabels: false,
  minValue: 0,
  maxValue: 4,
  stepSize: 1,
  yEnableGrid: true,
  xEnableGrid: false,
  parseLabelFunc: splitPhrase(13) }

const style = theme => ({
  error: {
    color: theme.palette.alert.red,
    height: 16,
    width: 16,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 16,
      width: 16,
      border: '2px solid',
      borderRadius: '50%',
      margin: -2
    }
  },
  editButton: {
    color: theme.palette.confirm.main,
    border: `2px solid ${theme.palette.confirm.main}`,
  },
  selectButton: {
    color: 'white',
    background: theme.palette.confirm.main
  },
  title: {
    fontSize: 14,
    marginRight: 6,
  },
  success: {
    color: theme.palette.confirm.main
  },
})

class CriteriumBarChart extends React.Component {
  render() {
    const { classes,
      data,
      chartRef,
      title,
      totalScore,
      indagationQuestions,
      withCheckbox,
      isSelectable,
      relativeScore,
      handleSelect } = this.props
    const hasQuestions = indagationQuestions.length !== 0
    const bankQuestions = indagationQuestions.filter(q => q.from_bank)
    // const mustShowIndagation = isSelectable && !withCheckbox
    const mustShowCheckbox = withCheckbox && isSelectable
    const buttonClass = hasQuestions ? classes.editButton : classes.selectButton
    const buttonVariant = hasQuestions ? 'outlined' : 'contained'
    return (
      <div style={{ background: indagationQuestions.length ? '#f1f4fa' : 'unset', margin: '60px 0', padding: 12 }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '6px', padding: 12 }}>
          {mustShowCheckbox && (
            <Checkbox style={{ padding: 2 }} checked={hasQuestions} onClick={handleSelect} color="primary" />
          )}
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <Typography variant="subtitle1" display="inline" className={classes.title} style={isSelectable ? null : { color: '#A2A2A2' }}>{title}</Typography>
            <Conditional condition={relativeScore < 0.5} hasElse>
              <div className={classes.error}>
                <ArrowDownwardOutlined className={classes.error} />
              </div>
              <CheckCircle className={classes.success} />
            </Conditional>
          </div>
          <div style={{ background: '#E3E3E3', borderRadius: 6, padding: '6px 18px', }}>
            <Typography style={{ color: 'black' }}>{totalScore}</Typography>
          </div>
          <div style={{ flexGrow: 1 }}>
            <Divider style={{ backgroundColor: '#bababa' }} variant="middle" />
          </div>
          {isSelectable
          && (
            <div>
              <Button className={buttonClass} variant={buttonVariant} onClick={handleSelect} size="small">
                {hasQuestions ? 'Editar Preguntas' : 'Seleccionar Preguntas'}
              </Button>
            </div>
          )}
        </div>
        <LineChart
          name={chartRef}
          data={data}
          chartOptions={chartOptions}
        />
        {isSelectable && (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 12 }}>
              <div>
                <Typography style={{ fontSize: 16, fontWeight: 'bold', color: '#27676A' }}>Preguntas de indagación</Typography>
              </div>
            </div>
            <div style={{ padding: 12 }}>
              {bankQuestions.map((question, idx) => (
                <div>
                  <Typography style={{ color: 'black' }}>{`${idx + 1}.- ${question.text}`}</Typography>
                </div>
              ))}
              {indagationQuestions.filter(q => !q.from_bank).map((question, idx) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography key={question.id} display="inline" style={{ color: 'black' }}>{`${bankQuestions.length + idx + 1} - ${question.text}`}</Typography>
                  <Tooltip title="No forma parte del banco de preguntas">
                    <Info style={{ marginLeft: 4, fontSize: 18 }} />
                  </Tooltip>
                </div>
              ))}
              {
                !hasQuestions && <Typography style={{ color: 'black' }}> Aún no se han seleccionado preguntas de indagación </Typography>
              }
            </div>
          </>
        )}
        <Divider style={{ backgroundColor: '#bababa' }} variant="middle" />
      </div>
    )
  }
}

CriteriumBarChart.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.arrayOf(propTypes.object),
  chartRef: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  totalScore: propTypes.number,
  indagationQuestions: propTypes.arrayOf(propTypes.object),
  withCheckbox: propTypes.bool,
  handleSelect: propTypes.func,
  isSelectable: propTypes.bool,
  relativeScore: propTypes.number.isRequired
}

CriteriumBarChart.defaultProps = {
  data: [],
  totalScore: 0,
  indagationQuestions: [],
  withCheckbox: false,
  isSelectable: false,
  handleSelect: () => {}
}

export default withStyles(style)(CriteriumBarChart)
