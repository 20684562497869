import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import GeneralForm from '../../Shared/GeneralForm'

const style = () => ({
  container: {
    padding: 12,
  },
})

const info = [
  { name: 'name', label: 'Nombre', type: 'text', area: 'text', colums: 3, eol: true },
  { name: 'observation', label: 'Observación', type: 'multiline', area: 'multiline1', rows: 3 },
  {
    name: 'city',
    area: 'select',
    label: 'Comuna',
    type: 'select',
    options: [{ name: 'Mi Casa', id: 1 }, { name: 'Tu Casa', id: 2 }, { name: 'Su Casa', id: 3 }],
  },
  { name: 'observation', label: 'Observación', type: 'multiline', area: 'multiline2', rows: 3 },
  {
    name: 'color',
    label: 'Colores',
    area: 'multiple',
    type: 'multiple',
    options: [
      { name: 'Rojo', id: 1 },
      { name: 'Verde', id: 2 },
      { name: 'Azul', id: 3 },
      { name: 'Negro', id: 4 },
    ],
  },
  { name: 'observation', label: 'Observación', type: 'multiline', area: 'multiline3', rows: 3 },
  {
    name: 'vote',
    label: 'Voto',
    area: 'radio',
    type: 'radio',
    options: [{ name: 'Rojo', id: 1 }, { name: 'Verde', id: 2 }, { name: 'Azul', id: 3 }],
  },
  { name: 'observation', label: 'Observación', type: 'multiline', area: 'multiline4', rows: 3 },
]

const areas = `
"text select multiline1 multiline1"
". . multiline2 multiline2"
". . multiline3 multiline3"
"radio radio multiline4 multiline4"
"radio radio multiline4 multiline4"
`

class StatusTestForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    const newParams = { ...params }
    newParams[target.name] = target.value
    this.setState({ params: newParams })
  }

  handleSubmit() {
    const { params } = this.state
    const body = { ...params }
    console.log(body)
  }

  render() {
    const { classes } = this.props
    const { params } = this.state
    return (
      <div className={classes.container}>
        <GeneralForm
          info={info}
          title="Prueba de formulario"
          submit="Probado"
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          params={params}
          areas={areas}
        />
      </div>
    )
  }
}

StatusTestForm.propTypes = {
  classes: propTypes.object.isRequired,
}

export default withStyles(style)(StatusTestForm)
