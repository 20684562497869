import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]
const style = () => ({

})

class MultipleAbilitySupportChart extends Component {
  componentDidMount() {
    const chartElement = document.getElementById('abilities')
    const myChartRef = chartElement.getContext('2d');
    chartElement.parentNode.style.width = '100%';
    chartElement.height = '550'
    const { data: dataProps } = this.props

    const { labels, evaluations, datas } = dataProps
    const yLabels = ['Sin Apoyo', 'Poco Apoyo', 'Apoyo Ocasional', 'Bastante Apoyo', 'Apoyo Total']

    const datasets = datas.map((data, index) => ({
      data: data.map(d => (d === 1 ? 1.3 : d)),
      barThickness: 12,
      backgroundColor: colors[index],
      label: evaluations[index],
    })).reverse()

    this.chart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels,
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            id: 'xAxis1',
            type: 'category',
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              min: 0,
              max: 5,
              stepSize: 1,
              minRotation: 90,
              maxRotation: 90,
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            },
            stacked: true,
            offset: true
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 1,
              max: 5,
              stepSize: 1,
              callback: value => yLabels[value - 1],
              beginAtZero: true
            },
            gridLines: {
              display: true,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            },
            stacked: false,
          }]
        },
        legend: {
          display: true,
          reverse: true,
          labels: {
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
            fontColor: 'black',
            fontSize: 12,
            boxWidth: 36
          },
        },
      }
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <canvas id="abilities" ref={this.chartRef} />
      </div>
    );
  }
}

MultipleAbilitySupportChart.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
}

MultipleAbilitySupportChart.defaultProps = {

}
export default withStyles(style)(MultipleAbilitySupportChart)
