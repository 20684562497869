/* eslint-disable import/prefer-default-export */
import instance from '../Utils/client'

export const updateUserAccountType = async (id, newRole) => {
  const response = await instance({
    method: 'put',
    url: `users/${id}/account_type`,
    data: {
      account_type: newRole,
    }
  })
  return response
}

export const enableUserAccount = async id => {
  const response = await instance({
    method: 'post',
    url: `users/${id}/enable`,
  })
  return response
}

export const disableUserAccount = async id => {
  const response = await instance({
    method: 'post',
    url: `users/${id}/disable`,
  })
  return response
}

export const getHistory = body => instance({
  method: 'get',
  url: 'users/histories',
  params: body
})
