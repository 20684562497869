import instance from '../Utils/client'
import createSnackbar, { callSnackbar } from '../Utils/snackbars'

const createExternalOrganizationAction = (body, snackbar) => {
  const REQUEST = instance({
    method: 'post',
    url: '/external_organizations',
    data: body,
  })
  if (snackbar) {
    createSnackbar(REQUEST, snackbar)
  }
  return {
    type: 'CREATE_EXTERNAL_ORGANIZATION',
    payload: REQUEST,
  }
}

const loadExternalOrganizationsAction = () => ({
  type: 'LOAD_EXTERNAL_ORGANIZATIONS',
  payload: instance({
    method: 'get',
    url: '/external_organizations',
  }),
})

const changeOrganizationPhotoAction = async body => {
  const REQUEST = instance({
    method: 'post',
    url: '/external_organizations/photo',
    data: body
  })
  await REQUEST
  callSnackbar('Foto guardada exitosamente', 'success')
  return {
    type: 'LOAD_EXTERNAL_ORGANIZATIONS',
    payload: REQUEST
  }
}

export {
  createExternalOrganizationAction,
  loadExternalOrganizationsAction,
  changeOrganizationPhotoAction
}
