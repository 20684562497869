/* eslint-disable import/prefer-default-export */
import instance from '../Utils/client'

export const getIncontinenceAlerts = async body => {
  const response = await instance({
    method: 'get',
    url: `contenders/${body.contender_id}/incontinence_alerts`,
  })
  return response
}

export const assignContenderToHandler = body => instance({
  method: 'post',
  url: `handlers/${body.id}/assign`,
  data: body
})

export const updateCommune = body => instance({
  method: 'post',
  url: 'contenders/commune',
  data: body
})

export const getRotatives = body => instance({
  method: 'get',
  url: `contenders/${body.id}/rotatives`
})

export const createRotative = body => instance({
  method: 'post',
  url: `contenders/${body.contender_id}/rotatives`,
  data: body
})

export const editRotative = body => instance({
  method: 'put',
  url: `contenders/${body.contender_id}/rotatives/${body.id}`,
  data: body
})
