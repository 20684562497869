import React, { Component } from 'react'
import propTypes from 'prop-types'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { ArrowBack, KeyboardArrowRight } from '@material-ui/icons'
import Conditional from '../../../Shared/Conditional'
import SetViewContext from './SetViewProvider'

const style = theme => ({
  last: {
    color: theme.palette.confirm.main,
    cursor: 'pointer',
    fontSize: 14
  },
  text: {
    cursor: 'pointer',
    fontSize: 14
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px'
  }
})
class BreadCrumbs extends Component {
  renderBreadcrumbs() {
    const { breadcrumbs, classes } = this.props
    return breadcrumbs.all.map((breadcrumb, index) => {
      const isLast = index === breadcrumbs.all.length - 1
      return (
        <>
          <Typography
            color="primary"
            variant="h1"
            role="button"
            className={isLast ? classes.last : classes.text}
            onClick={breadcrumb.action}
          >
            {breadcrumb.name}

          </Typography>
          {
            !isLast
            && <KeyboardArrowRight color="primary" />
          }
        </>
      )
    })
  }

  render() {
    const { classes, breadcrumbs } = this.props
    const { setView } = this.context
    const len = breadcrumbs.all.length
    return (
      <div className={classes.container}>
        <Conditional condition={len > 0}>
          <IconButton onClick={() => setView()} size="small">
            <ArrowBack />
          </IconButton>
        </Conditional>
        {this.renderBreadcrumbs()}
      </div>
    )
  }
}

BreadCrumbs.contextType = SetViewContext

BreadCrumbs.propTypes = {
  classes: propTypes.object.isRequired,
  breadcrumbs: propTypes.object.isRequired
}

BreadCrumbs.defaultProps = {

}

const mapStateToProps = state => ({
  breadcrumbs: state.breadcrumbs
})

export default connect(mapStateToProps)(withStyles(style)(BreadCrumbs))
