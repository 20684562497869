import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = theme => ({
  title: {
    '& > h2': {
      fontWeight: 600,
      fontSize: 16
    }
  },
  question: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 450,
    width: '100%',
    margin: '3px 6px'
  },
  questionText: {
    fontWeight: 600,
    color: '#1a1a1a'
  },
  questionsContainer: {
  },
  divider: {
    background: theme.palette.primary.main
  },
  big: {
    height: 2
  }
})

function getQuestionAnswer(question) {
  switch (question.type) {
    case 'text': {
      if (question.answers.body) {
        return question.answers.body.answer
      }
      return ''
    }
    case 'select': {
      if (question.answers.body) {
        const { options } = question
        const { answer } = question.answers.body
        return options[answer]
      }
      return ''
    }
    case 'radio': {
      if (question.answers.body) {
        const { options } = question
        const { answer } = question.answers.body
        return options[answer]
      }
      return ''
    }
    default: {
      if (question.answers.body) {
        return question.answers.body.answer
      }
      return ''
    }
  }
}

function getSubquestionAnswer(question, subquestion, index) {
  const subquestionAnswer = Object.entries(question.answers.body.subquestions)
    .find(([id,]) => parseInt(id.split('&')[1], 10) === index)[1]
  switch (subquestion.type) {
    case 'text': {
      return subquestionAnswer
    }
    case 'select': {
      const { options } = subquestion
      const { answer } = question.answers.body
      return options[answer]
    }
    case 'radio': {
      const { options } = subquestion
      const { answer } = question.answers.body
      return options[answer]
    }
    default: {
      return question.answers.body.answer
    }
  }
}

class PrevisionEmergencies extends Component {
  renderSubquestions(question, subquestions) {
    const { classes } = this.props
    return subquestions.map((subquestion, index) => {
      const answer = (
        <div className={classes.question}>
          <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
          <Typography variant="subtitle1">{getSubquestionAnswer(question, subquestion, index)}</Typography>
        </div>
      )
      return answer
    })
  }

  renderQuestions(questions) {
    return questions.map(question => {
      let subquestions = []
      if (question.subquestions) {
        subquestions = question.subquestions
      }
      const { classes } = this.props
      const answer = (
        <div className={classes.question}>
          <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
          <Typography variant="subtitle1">{getQuestionAnswer(question)}</Typography>
        </div>
      )
      return (
        <>
          {answer}
          {question.answers.body
            && question.answers.body.subquestions
            && this.renderSubquestions(question, subquestions)}
        </>
      )
    })
  }

  render() {
    const { classes, form } = this.props;
    const questions = form['Parte I']['Previsión de salud y emergencia']
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h2">Previsión de salud y emergencias</Typography>
          <Divider className={`${classes.divider} ${classes.big}`} />
        </div>
        <div className={classes.questionsContainer}>
          {this.renderQuestions(questions)}
        </div>
      </div>
    );
  }
}

PrevisionEmergencies.propTypes = {
  classes: propTypes.object.isRequired,
  form: propTypes.object.isRequired
}

PrevisionEmergencies.defaultProps = {

}
export default withStyles(style)(PrevisionEmergencies)
