import UserCell from 'Shared/Table/UserCell'
import StatusCell from 'Shared/Table/StatusCell'
import { formatDate } from 'Utils/functions'

// const enterprises =
// const comunas =
const statusesColors = {
  Aspirante: '#ffb33d',
  Postulante: '#d5602c',
  Candidato: '#FB8368',
  Trabajando: '#38b4a9',
  Egresado: '#1f8095',
  'Egresado con apoyo': '#27676A',
  Derivado: '#6A6A6A',
}
const getStatusObject = statusName => ({ name: statusName, color: statusesColors[statusName] })

export const contenderTestList = contenders => contenders.map(contender => {
  const info = {
    name: contender.user.name,
    handler: 'Paz Martinez',
    created_at: contender.contender.created_at,
    updated_at: contender.contender.updated_at,
    // enterprise: (enterprises),
    status: getStatusObject(contender.state_name),
    program: contender.program || 'Sin programa',
    commune: contender.commune || 'No ingresado',
    id: contender.contender.id,
    img: contender.user.photo,
  }
  return info
})

export const contenderInfo = [
  { name: 'Nombre', label: 'name', render: UserCell, max_width: 120 },
  // { name: 'Facilitador', label: 'handler' },
  { name: 'Fecha ingreso', label: 'created_at', format: formatDate },
  // { name: 'Empresa', label: 'enterprise', max_width: 120 },
  { name: 'Programa', label: 'program' },
  // { name: 'Comuna', label: 'commune' },
  { name: 'Estado', label: 'status', render: StatusCell }
]

export const contenderFilters = [
  { name: 'Programa', label: 'program', getter: dataObj => dataObj.program },
  { name: 'Comuna', label: 'commune', getter: dataObj => dataObj.commune },
  { name: 'Estado', label: 'status', getter: dataObj => dataObj.status.name },
]

export const contenderSortables = [
  { name: 'Ultima modificación', label: 'updated_at' }
]
