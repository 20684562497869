import instance from '../Utils/client'
import createSnackbar from '../Utils/snackbars'

export const getContenderStatesAction = () => {
  const REQUEST = instance({
    method: 'get',
    url: 'contenders/states',
  })
  return {
    type: 'GET_CONTENDER_STATES',
    payload: REQUEST
  }
}

export const changeContenderStateAction = body => {
  const REQUEST = instance({
    method: 'put',
    url: `contenders/${body.id}/state`,
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_CONTENDER_STATE',
    payload: REQUEST
  }
}
