import React, { Component } from 'react'
import { withStyles, Typography, withTheme } from '@material-ui/core'
import propTypes from 'prop-types'

const style = theme => ({
  container: {
    width: '100%',
    background: theme.palette.indigo.lighter,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderTopLeftRadius: 20,
  },
  upperContainer: {
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderTopLeftRadius: 20,
    transition: 'all 0.5s ease-out',
  }

})

class PercentageBar extends Component {
  render() {
    const { classes, percentage, theme, barColor } = this.props
    return (
      <div style={{ position: 'relative' }}>
        <div className={classes.container}>
          <Typography
            variant="subtitle1"
            style={{ fontSize: 15, textAlign: 'right', paddingRight: 12, position: 'relative', zIndex: 1, color: '#3E3E3E', }}
          >
            {`${percentage}%`}

          </Typography>
          <div className={classes.upperContainer} style={{ width: `${percentage}%`, position: 'absolute', top: 0, left: 0, height: '100%', background: barColor || theme.palette.confirm.main }} />
        </div>
      </div>
    )
  }
}

PercentageBar.propTypes = {
  classes: propTypes.object.isRequired,
  theme: propTypes.object.isRequired,
  barColor: propTypes.string,
  percentage: propTypes.number,
}

PercentageBar.defaultProps = {
  barColor: null,
  percentage: 0
}

export default withTheme(withStyles(style)(PercentageBar))
