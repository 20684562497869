import React, { Component } from 'react'
import { Button, Divider, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import DownloadableSection from 'Shared/DownloadableSection'
import RadarChart from '../../../Shared/Charts/RadarChart'
import CompetenciesTable from './CompetenciesTable'
import BarChart from '../../../Shared/Charts/BarChart'
import autobind from '../../../Utils/autobind'
import { getFunctionalResultsAction } from '../../../Actions/FunctionalEvaluaction'
import { editEvaluationComment, getEvaluationComment } from '../../../API/Evaluations'
import GlobalComment from '../../../Shared/GlobalComment'

const style = theme => ({
  container: {
    margin: 12,
    padding: 12,
    textAlign: 'left'
  },
  title: {
    textAlign: 'left',
    fontSize: 16
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 48
  },
  sectionDivider: {
    display: 'flex',
    gap: '18px',
    marginBottom: 6
  },
  divider: {
    flexGrow: 1,
    background: '#6a6a6a',
    opacity: 0.7
  },
  readyButton: {
    textAlign: 'end'
  },
  finalSupportLevel: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: 24
  },
  supportLevel: {
    padding: '6px 18px',
    background: theme.palette.grey.light,
    textAlign: 'center',
    borderRadius: 6
  },
  competencies: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '12px',
    '&>*': {
      width: '50%'
    }
  },
  bySkill: {
    margin: '36px 0'
  },
  finalCharts: {
    display: 'flex',
    gap: '2%',
    '&>*': {
      width: '48%',
      minWidth: '400px',
      maxWidth: '700px',
      flexGrow: 1,
      marginTop: 24,
    },
    flexWrap: 'wrap'
  },
  chartTitle: {
    fontWeight: 600,
    color: theme.palette.grey.dark
  }
})

const radarOptions = {
  color: '#1F8095BB',
  maxTicksLimit: 4,
  height: '300px',
  minValue: 0,
  maxValue: 5,
  stepSize: 1
}

const options = {
  detachedLabels: false,
  yEnableGrid: true,
  minValue: 0,
  maxValue: 5,
  stepSize: 1,
  callback: value => {
    const labels = ['Apoyo Total', 'Bastante Apoyo', 'Apoyo Ocasional', 'Poco Apoyo', 'Sin Apoyo', ''].reverse()
    return labels[value]
  },
  width: '100%',
  minRotation: 45,
}

class SupportLevel extends Component {
  constructor() {
    super()
    this.state = {
      competencies: [],
      skills: [],
      comment: ''
    }
    autobind(SupportLevel, this)
  }

  async componentDidMount() {
    const { getResults, match, contenders, event } = this.props
    const body = {}
    if (event === null) {
      body.id = match?.params.id
      body.form_id = match?.params.formId
      body.user_id = contenders.selected.user.id
    } else {
      body.id = event.event_id
      body.form_id = event.form_id
      body.user_id = contenders.selected.user.id
    }
    await getResults(body)
    this.getCompetencies()
    this.getSkills()

    const commentBody = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contenders.selected.user.id
    }
    const response = await getEvaluationComment(commentBody)
    if (response.data.status !== 'success') return null
    return this.setState({ comment: response.data.info.comment })
  }

  handleChangeComment(event) {
    const { target } = event
    this.setState({ comment: target.value })
  }

  handleSendComment() {
    const { contenders, event } = this.props
    const { comment } = this.state
    const userID = contenders.selected.user.id
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: userID,
      comment
    }
    editEvaluationComment(body)
  }

  handleRedirect() {
    const { history, match } = this.props
    const newUrl = match.url.split('/')
    newUrl.pop()
    newUrl.push('profile')
    const finalUrl = newUrl.join('/')
    history.push(finalUrl)
  }

  getCompetencies() {
    const { functional } = this.props
    const { result } = functional
    this.setState({ competencies: result.competencies, comment: result.comment })
  }

  getSkills() {
    let skills = []
    const { competencies } = this.state
    competencies.forEach(competencie => { skills = skills.concat(competencie.skills) })
    this.setState({ skills })
  }

  chartCompetencies() {
    const { competencies } = this.state
    const result = {}
    competencies.forEach(competencie => { result[`${competencie.name.slice(0, 10)}...`] = competencie.total })
    return result
  }

  renderCharts() {
    const { classes } = this.props
    const { competencies } = this.state
    const colors = ['#1F8095', '#38B4A9', '#FFB33D', '#D5602C', '#A2A2A2', '#FB8368', '#38B4A9', '#6A6A6A', '#FFB33D', '#1F8095']
    return competencies.map((competencie, index) => {
      const { skills } = competencie
      const data = skills.map(skill => (
        { label: skill.name, value: skill.total, color: colors[index] }
      ))
      const title = <Typography variant="h2" className={classes.chartTitle}>{competencie.name}</Typography>
      return (
        <div style={{ height: 400 }} key={competencie.id}>
          <BarChart name={competencie.name} data={data} options={options} title={title} />
        </div>
      )
    })
  }

  render() {
    const { classes, functional, contenders, event } = this.props;
    const { competencies, skills } = this.state
    return (
      <Paper className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h1" className={classes.title}>Resultados evaluación Funcional Diagnóstica</Typography>
          {event === null
          && (
            <Button variant="outlined" color="secondary" onClick={this.handleRedirect}>
              Ver Perfil Laboral
            </Button>
          )}
        </div>
        <div className={classes.sectionDivider}>
          <Typography variant="h1">Nivel de apoyo</Typography>
          <Divider className={classes.divider} />
        </div>
        <div className={classes.commentContainer}>
          <Typography variant="caption">
            Agregar comentarios sobre
            {' '}
            <span style={{ fontWeight: 600 }}>{contenders.selected.user.name}</span>
          </Typography>
          <GlobalComment event={event} />
          <div className={classes.readyButton}>
            <Button size="small" onClick={this.handleSendComment}>
              Listo
            </Button>
          </div>
        </div>
        <div className={classes.finalSupportLevel}>
          <Typography variant="h2">Resultado nivel de apoyo</Typography>
          <div className={classes.supportLevel}>
            <Typography variant="h2">{functional.result.support_level}</Typography>
          </div>
        </div>
        <Typography variant="h2">Competencias</Typography>
        <div className={classes.competencies}>
          <CompetenciesTable data={competencies} />
          <DownloadableSection id="radar" name={`Nivel de apoyo por competencia ${contenders.selected.user.name}`}>
            <RadarChart name="competencias" title="" options={radarOptions} data={this.chartCompetencies()} />
          </DownloadableSection>
        </div>
        <div className={classes.bySkill}>
          <Typography variant="h2">Resultado nivel de apoyo por habilidad</Typography>
          <CompetenciesTable data={skills} />
        </div>
        <Typography variant="h2">Resultado Nivel de apoyo por competencia y sus habilidades</Typography>
        <div className={classes.finalCharts}>
          {this.renderCharts()}
        </div>
      </Paper>
    );
  }
}

SupportLevel.propTypes = {
  classes: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  getResults: propTypes.func.isRequired,
  functional: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
  event: propTypes.object
}

SupportLevel.defaultProps = {
  event: null
}

const mapStateToProps = state => ({
  functional: state.functional,
  contenders: state.contenders
})

const mapDispatchToProps = dispatch => ({
  getResults: body => dispatch(getFunctionalResultsAction(body)),
})

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(SupportLevel))
)
