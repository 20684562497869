import React from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { withStyles } from '@material-ui/core/styles'
import propTypes from 'prop-types'
import moment from 'moment'
import { callSnackbar } from 'Utils/snackbars'
import Participant from './Participant'
import { loadEventAction, sendGencatAction } from '../../Actions/Events'

const style = () => ({
  container: {
    padding: 12,
    width: '100%',
    background: '#fff',
    textAlign: 'left',
    marginRight: 12,
  },
  title: {
    textAlign: 'left',
    paddingBottom: 24,
    marginBottom: 6,
    position: 'relative',
    '& > h2': {
      fontWeight: 600
    }
  },
  radioBtns: {
    display: 'flex',
  },
  button: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  outer: {
    padding: 12,
    display: 'flex',
    maxWidth: 750,

  },
  right: {
    width: '30%',
  },
})

class Gencat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.renderParticipants = this.renderParticipants.bind(this)
    this.handleParticipantClick = this.handleParticipantClick.bind(this)
    this.handleMailSend = this.handleMailSend.bind(this)
  }

  handleParticipantClick(id, option) {
    return () => {
      const { history, event } = this.props
      if (option === 'view') {
        history.push(`/events/${event.event.id}/gencat/${id}`)
      } else {
        this.handleMailSend(id)
      }
    }
  }

  handleMailSend(userId) {
    const { loadEvent, event, sendGencat, enqueueSnackbar } = this.props
    const loadEventWrapper = eventId => () => loadEvent(eventId, null, { fetch_form: false })
    sendGencat(userId, event.event.id, loadEventWrapper(event.event.id), enqueueSnackbar)
  }

  handleCopyLink(id) {
    return () => {
      const { event } = this.props
      const link = `${window.location.origin}/mail/fichagencat/${event.event.id}/${id}`
      const element = document.getElementById('secret-input')
      element.value = link
      element.select()
      element.setSelectionRange(0, 99999)
      document.execCommand('copy');
      callSnackbar('Enlace copiado', 'success')
    }
  }

  renderParticipants() {
    const { event } = this.props
    if (event && event.participants) {
      return event.participants
        .map(participant => (
          <Participant
            onMenuClick={this.handleParticipantClick}
            onButtonClick={this.handleCopyLink(participant.id)}
            participant={participant}
            key={participant.id}
            attended
          />
        ))
    }
    return null
  }

  render() {
    const { classes, event } = this.props
    return (
      <Grid container>
        <Grid container item className={classes.outer}>
          <Paper className={classes.container}>
            <div className={classes.title}>
              <Typography variant="h2">
                {`Gestión: ${event.event.title}`}
              </Typography>
              <Typography variant="caption">
                {moment(event.event.created_at).format('DD/MM/YYYY')}
              </Typography>
              <Typography
                color="primary"
                variant="body2"
                style={{ fontSize: 12, position: 'absolute', bottom: 3, right: 0 }}
              >
                Enviar
              </Typography>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: 10 }}>
              {this.renderParticipants()}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

Gencat.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object,
  history: propTypes.object.isRequired,
  loadEvent: propTypes.func.isRequired,
  sendGencat: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
}

Gencat.defaultProps = {
  event: undefined,
}

const mapStateToProps = state => ({
  event: state.events.selected
})

const mapDispatchToProps = dispatch => ({
  loadEvent: (id, callback, params) => dispatch(loadEventAction(id, callback, params)),
  sendGencat: (userId,
    eventId,
    callback,
    snackbar) => dispatch(sendGencatAction(userId, eventId, callback, snackbar))
})

export default withSnackbar(connect(mapStateToProps,
  mapDispatchToProps)(withRouter(withStyles(style)(Gencat))))
