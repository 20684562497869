import React, { Component } from 'react'
import { withStyles, Typography, Paper, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import QuestionGroup from './QuestionGroup'
import EditableQuestionGroup from './EditableQuestionGroup'
import { getFormAction } from '../../../../Actions/Contenders'
import Loader from '../../../../Shared/Loader'
import { markFormAsFinishedAction } from '../../../../Actions/Events'
import autobind from '../../../../Utils/autobind'
import SetViewContext from '../../../Contenders/Profile/SetViewProvider'
import { convertToRoman } from '../../../../Utils/functions'

const style = theme => ({
  container: {
    padding: 12,
    textAlign: 'start'
  },
  breads: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24
  },
  selectedText: {
    color: theme.palette.confirm.main
  },
  button: {
    textAlign: 'end'
  }
})

class IndividualInterviewEdit extends Component {
  constructor(props) {
    super(props)
    autobind(IndividualInterviewEdit, this)
  }

  handleCheckInterview() {
    const { event, contender, markForm } = this.props
    const { setView } = this.context
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contender.user.id,
      status: 'pending'
    }
    markForm(body)
    setView()
  }

  renderBaseQuestions() {
    const { forms, title } = this.props
    if (forms.form) {
      const form = forms.form[`Entrevista ${title}`]
      const baseQuestions = Object.entries(form).filter(([, value]) => {
        const criteriumName = Object.keys(value)[0]
        return (criteriumName === 'empty')
      })
      return baseQuestions.map(([text, value], index) => {
        const indagationFormName = 'Indagación de indicadores descendidos'
        const criteriums = Object.keys(form[indagationFormName])
        const dynamicQuestions = []
        criteriums.forEach(criterium => {
          const filtered = form[indagationFormName][criterium]
            .questions.filter(q => q.section_index === index)
          dynamicQuestions.push(...filtered)
        })
        return (<QuestionGroup key={`${text}`} title={`${convertToRoman(index + 1)}. ${text}`} group={value.empty} dynamic={dynamicQuestions} />)
      })
    }
    return null
  }

  renderSelectableQuestions() {
    const { forms, title } = this.props
    if (forms.form) {
      const form = forms.form[`Entrevista ${title}`]
      const editQuestions = Object.entries(form).filter(([, value]) => (Object.keys(value)[0] !== 'empty'))

      return editQuestions.map(([text, value]) => (<EditableQuestionGroup interviewType={title} title={`${title === 'Familiar' ? convertToRoman(5) : convertToRoman(4)}. ${text}`} groups={value} key={text} />))
    }
    return null
  }

  render() {
    const { classes, getForm, event } = this.props
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
    }
    return (
      <Paper className={classes.container}>
        <Loader action={getForm} params={[body]}>
          {this.renderBaseQuestions()}
          {this.renderSelectableQuestions()}
        </Loader>
        <div className={classes.button}>
          <div>
            <Button color="secondary" size="small" variant="contained" onClick={this.handleCheckInterview}>Comenzar entrevista</Button>
            <div>
              <Typography variant="caption" color="primary">Terminar selección de preguntas y comenzar la entrevista individual</Typography>
            </div>
          </div>
        </div>
      </Paper>
    )
  }
}

IndividualInterviewEdit.contextType = SetViewContext

IndividualInterviewEdit.propTypes = {
  classes: propTypes.object.isRequired,
  title: propTypes.string,
  event: propTypes.object.isRequired,
  getForm: propTypes.func.isRequired,
  forms: propTypes.object,
  contender: propTypes.object.isRequired,
  markForm: propTypes.func.isRequired

}

IndividualInterviewEdit.defaultProps = {
  title: 'Individual',
  forms: {},

}

const mapStateToProps = state => ({
  contender: state.contenders.selected,
  forms: state.contenders.forms
})

const mapDispatchToProps = dispatch => ({
  getForm: body => dispatch(getFormAction(body)),
  markForm: body => dispatch(markFormAsFinishedAction(body))
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(withStyles(style)(IndividualInterviewEdit))
