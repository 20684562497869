import React, { Component } from 'react'
import { Paper } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { requestAction, messageAction } from '../../Actions/Status'
// import Form from '../../Shared/form/Layout'
import StatusTestForm from './StatusTestForm'

const radioOptions = [
  { label: 'Interesado en trabajar', value: 'interested' },
  { label: 'Trabajando con otros', value: 'working' },
  { label: 'No interesado en trabajar', value: 'not-interested' },
]

class Status extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFormOpen: false,
      radioValue: 'interested',
      date: '',
      hour: '',
      commentary: '',
    }
    this.send = this.send.bind(this)
    this.openForm = this.openForm.bind(this)
    this.closeForm = this.closeForm.bind(this)
    this.getFormInfo = this.getFormInfo.bind(this)
    this.getInputHandler = this.getInputHandler.bind(this)
  }

  componentDidMount() {
    const { setupStatus, setupRequest } = this.props
    setupStatus('Todo OK')
    setupRequest()
  }

  getInputHandler(inputName) {
    return e => {
      const inputValue = e.target.value
      this.setState(prevState => ({
        ...prevState,
        [inputName]: inputValue,
      }))
    }
  }

  getFormInfo() {
    const { date, hour, commentary, radioValue } = this.state
    return [
      { type: 'text', label: 'Fecha', value: date, changeHandler: this.getInputHandler('date') },
      { type: 'text', label: 'Hora', value: hour, changeHandler: this.getInputHandler('hour') },
      {
        type: 'radio',
        label: 'Aspirante',
        value: radioValue,
        options: radioOptions,
        changeHandler: this.getInputHandler('radioValue'),
      },
      {
        type: 'multiline',
        label: 'Comentario',
        value: commentary,
        changeHandler: this.getInputHandler('commentary'),
      },
    ]
  }

  openForm() {
    this.setState({ isFormOpen: true })
  }

  closeForm() {
    this.setState({ isFormOpen: false })
  }

  send() {
    const { status, enqueueSnackbar, closeSnackbar } = this.props
    enqueueSnackbar(status.message, { variant: 'info' })
    enqueueSnackbar(status.message, { variant: 'warning' })
    enqueueSnackbar(status.message, { variant: 'success' })
    enqueueSnackbar(status.message, { variant: 'error' })
    setTimeout(closeSnackbar, 2000)
  }

  render() {
    // const { status } = this.props
    // const { isFormOpen } = this.state
    return (
      <>
        {/* <Paper
          style={{
            width: '50%',
            padding: 25,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <h2 style={{ marginTop: 0 }}>{status.message}</h2>
          <Button onClick={this.send} color="primary" variant="contained">
            Send notistack
          </Button>
          <Button onClick={this.openForm} color="primary" variant="contained">
            Open Form
          </Button>
        </Paper>
        <Form
          open={isFormOpen}
          close={this.closeForm}
          fieldsInfo={this.getFormInfo()}
          title="Fecha Jornada de presentación"
          submitText="Agendar"
        /> */}
        <Paper style={{ background: 'lightgrey' }}>
          <StatusTestForm />
        </Paper>
      </>
    )
  }
}

Status.defaultProps = {
  status: { message: '' },
}

Status.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  setupStatus: PropTypes.func.isRequired,
  setupRequest: PropTypes.func.isRequired,
  status: PropTypes.object,
}

const mapDispatchToProps = dispatch => ({
  setupStatus: text => dispatch(messageAction(text)),
  setupRequest: () => dispatch(requestAction())
})

const mapStateToProps = state => ({ status: state.status })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Status))
