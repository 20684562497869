import React from 'react'
import { Paper, Typography, IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'
import propTypes from 'prop-types'
import ArrowBackIosIcon from '@material-ui/icons/KeyboardArrowLeft'
import ArrowForwardIosIcon from '@material-ui/icons/KeyboardArrowRight'
import _ from 'lodash'
import moment from 'moment'
import { getEventColor, getModuleFromHour } from '../../Utils/functions'

const defaultModules = [{ label: '8 AM', startCol: 2, endCol: 3 },
  { label: '9 AM', startCol: 3, endCol: 4 },
  { label: '10 AM', startCol: 4, endCol: 5 },
  { label: '11 AM', startCol: 5, endCol: 6 },
  { label: '12 PM', startCol: 6, endCol: 7 },
  { label: '1-2 PM', startCol: 7, endCol: 8 },
  { label: '3 PM', startCol: 8, endCol: 9 },
  { label: '4 PM', startCol: 9, endCol: 10 }]

const extendedMods = [
  { label: '8 AM', startCol: 2, endCol: 3 },
  { label: '9 AM', startCol: 3, endCol: 4 },
  { label: '10 AM', startCol: 4, endCol: 5 },
  { label: '11 AM', startCol: 5, endCol: 6 },
  { label: '12 PM', startCol: 6, endCol: 7 },
  { label: '1-2 PM', startCol: 7, endCol: 8 },
  { label: '3 PM', startCol: 8, endCol: 9 },
  { label: '4 PM', startCol: 9, endCol: 10 },
  { label: '5 PM', startCol: 10, endCol: 11 },
  { label: '6 PM', startCol: 11, endCol: 12 },
  { label: '7 PM', startCol: 12, endCol: 13 },
  { label: '8 PM', startCol: 13, endCol: 14 },
  { label: '', startCol: 14, endCol: 15 }]

const styles = theme => ({
  container: {
    display: 'grid',
    padding: 6,
    gridTemplateRows: '50px 1fr',
    gridTemplateColumns: '10% 5px 1fr 5px 1fr 5px 1fr 5px 1fr 5px 1fr 5px',
  },
  modules: {
    gridRow: '2 / 3',
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'end',
  },
  header: {
    gridRow: '1 / 2',
    gridColumn: '1 / 13',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 6,
    '& > *': {
      width: '32%'
    },
    '& > *:last-child': {
      textAlign: 'end'
    },
    '& > *:first-child': {
      textAlign: 'start'
    }

  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    color: theme.palette.grey.dark,
  },
  dayEvents: {
    gridRow: '2 / 3',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    alignItems: 'center',
    justifyItems: 'start',
    background: 'white'
  },
  verticalDivider: {
    background: '#ebebeb',
    margin: '6px 0',
    gridRow: '2 / 3',
    borderRadius: 5,
  },
  mark: {
    color: theme.palette.grey.dark,
    fontWeight: 900,
    fontSize: 12
  },
  event: {
    margin: '3px 6px 0 6px',
    placeSelf: 'stretch',
    borderRadius: 5,
    fontSize: 12,
    padding: '0 6px',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

const getUsedModules = day => {
  const usedModules = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
  day.events.slice(0, 3).forEach(event => {
    if (event.start === event.end) {
      usedModules[String(event.end)] += 1
      return
    }
    _.range(Number(event.start), Number(event.end) + 1).forEach(mod => {
      usedModules[String(mod)] += 1
    })
  })
  return usedModules
}

const getEventColumns = (usedModules, eventModules) => {
  let overlapCount = 1
  eventModules.forEach(mod => {
    if (usedModules[String(mod)] > overlapCount) {
      overlapCount = usedModules[String(mod)]
    }
  })
  if (overlapCount === 1) {
    return 'auto / span 6'
  }
  if (overlapCount === 2) {
    return 'auto / span 3'
  }
  return 'auto / span 2'
}

class Cronogram extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderDays() {
    const { classes, days, extend } = this.props
    let lastColEnd = 2
    const gridRows = extend ? '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
    const modules = extend ? extendedMods : defaultModules
    return days.map(dayInfo => {
      const used = getUsedModules(dayInfo)
      const lunchPresent = (used['5'] === 0)
      const dayComponent = (
        <>
          <div style={{ gridColumn: `${lastColEnd} / ${1 + lastColEnd}` }} className={classes.verticalDivider} key={`${dayInfo.name}-divider`} />
          <div className={classes.dayEvents} style={{ gridColumn: `${1 + lastColEnd} / ${2 + lastColEnd}`, gridTemplateRows: gridRows }} key={dayInfo.name}>
            <div style={{ gridRow: '1 / 2', gridColumns: '1 / 3', padding: '0 12px' }}>
              <Typography className={classes.mark}>{dayInfo.name}</Typography>
            </div>
            {
              dayInfo.events.slice(0, 3).map(event => {
                const duration = `${event.full.module} - ${event.full.end_module}`
                const eventModules = _.range(event.start, event.end + 1)
                const eventColumns = getEventColumns(used, eventModules)
                return (
                  <div
                    key={event.id}
                    className={classes.event}
                    style={{
                      background: getEventColor(event.type, { key: 'type' }),
                      gridRow: `${modules[Number(event.start)].startCol} / ${modules[event.end].endCol}`,
                      gridColumn: eventColumns }}
                  >
                    <Tooltip title={`${duration} ${event.title}`}>
                      <Typography
                        className={classes.mark}
                        style={{ color: event.font, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        {event.title}
                      </Typography>
                    </Tooltip>
                  </div>
                )
              })
            }
            {lunchPresent && (
              <div style={{ gridRow: '7 / 8', gridColumn: '1 / 7', justifySelf: 'center' }}>
                <Typography className={classes.mark}>Almuerzo</Typography>
              </div>
            )}
          </div>
        </>
      )
      lastColEnd = 2 + lastColEnd
      return dayComponent
    })
  }

  renderModules() {
    let lastModuleEnd = 2
    const currentHour = moment().hour()
    const currentModule = getModuleFromHour(`${currentHour}:00`)
    const { classes, extend } = this.props
    const modules = extend ? extendedMods : defaultModules
    return modules.map((module, idx) => {
      const highlight = idx === Number(currentModule) ? '#ebebeb' : null
      const moduleComponent = (
        <div
          style={{ gridRow: `${lastModuleEnd} / ${1 + lastModuleEnd}`, background: highlight, padding: '0 8px', margin: '0 6px', borderRadius: 2, textAlign: 'right' }}
          key={`${module.label}-${module.startCol}-${module.endCol}`}
        >
          <Typography className={classes.mark}>{module.label}</Typography>
        </div>
      )
      lastModuleEnd += 1
      return moduleComponent
    })
  }

  render() {
    const { style,
      classes,
      elevation,
      handleOpenForm,
      date,
      theme,
      changeDate,
      extend } = this.props
    const startDay = date.startOf('isoWeek').format('DD')
    const endDay = date.endOf('isoWeek').subtract({ days: 2 }).format('DD')
    const gridRows = extend ? '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
    return (
      <Paper style={style} className={classes.container} elevation={elevation}>
        <div className={classes.header}>
          <div>
            <Typography display="inline" className={classes.title}>Cronograma de semana: </Typography>
          </div>
          <div className={classes.dateHeader}>
            <div>
              <IconButton size="small" onClick={changeDate('subtract')}>
                <ArrowBackIosIcon style={{ color: theme.palette.grey.dark }} />
              </IconButton>
            </div>
            <div>
              <Typography className={classes.title} style={{ fontWeight: 500 }}>{`Lunes ${startDay} - Viernes ${endDay} `}</Typography>
            </div>
            <div>
              <IconButton size="small" onClick={changeDate('add')}>
                <ArrowForwardIosIcon style={{ color: theme.palette.grey.dark }} />
              </IconButton>
            </div>
          </div>
          <div>
            <IconButton onClick={handleOpenForm} size="small"><AddIcon /></IconButton>
          </div>
        </div>
        <div className={classes.modules} style={{ gridTemplateRows: gridRows }}>
          {this.renderModules()}
        </div>
        {this.renderDays()}
        <div className={classes.verticalDivider} />
      </Paper>
    )
  }
}

Cronogram.propTypes = {
  date: propTypes.object.isRequired,
  changeDate: propTypes.func.isRequired,
  style: propTypes.object.isRequired,
  elevation: propTypes.number.isRequired,
  classes: propTypes.object.isRequired,
  days: propTypes.arrayOf(propTypes.object),
  handleOpenForm: propTypes.func.isRequired,
  theme: propTypes.object.isRequired,
  extend: propTypes.bool.isRequired
}

Cronogram.defaultProps = {
  days: []
}

export default withStyles(styles, { withTheme: true })(Cronogram)
