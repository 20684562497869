import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import autobind from '../../../../Utils/autobind'
import { getFunctionalEvaluationAction } from '../../../../Actions/FunctionalEvaluaction'
import SliderInput from '../../../../Shared/Fields/SliderInput'
import Comment from '../../../../Shared/Fields/Comment'

const style = theme => ({
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    paddingBottom: 0
  },
  tab: {
    marginRight: 12,
    fontWeight: 400,
    borderBottom: '2px solid transparent'
  },
  divider: {
    background: '#8A8A8A',
    margin: '0 12px 12px 12px'
  },
  selected: {
    borderRadius: 0,
    fontWeight: 600,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  container: {
    textAlign: 'left',
    width: '750px'
  },
  title: {
    margin: '3px 0',
    fontWeight: 600
  },
  bigTitle: {
    margin: '3px 0',
    fontWeight: 600,
    fontSize: 16
  },
  skill: {
    padding: 12,
    margin: '6px 0'
  },
  activity: {
    margin: '24px 0 6px 0'
  },
  tasks: {
    marginLeft: 24
  }
})

class FunctionalContainer extends Component {
  constructor() {
    super()
    this.state = {
      form: {},
      parts: []
    }
    autobind(FunctionalContainer, this)
  }

  componentDidMount() {
    const { event, getEvaluation, contenders } = this.props
    const contender = contenders.selected
    const userId = contender.user.id
    const body = {
      id: event.event_id,
      form_id: event.form_id
    }
    getEvaluation(body).then(() => {
      this.setupForm(userId)
    })
  }

  setupForm(userId) {
    const { forms } = this.props
    const form = forms[userId]
    const parts = Object.keys(form)
    this.setState({ form, parts })
  }

  renderForm() {
    const { classes } = this.props
    const { parts, form } = this.state
    return parts.map((title, index) => {
      const actualForm = form[title]
      const questions = actualForm.map(groupOrQuestion => {
        if (groupOrQuestion.type === 'plain') {
          const activity = Object.keys(groupOrQuestion)[1]
          const skills = Object.keys(groupOrQuestion[activity])
          const skillQuestions = groupOrQuestion[activity]
          return (
            <div>
              <div className={classes.activity}>
                <Typography variant="h2" className={classes.title}>{activity.split('&')[0]}</Typography>
              </div>
              <div>
                {skills.map((skill, skillIndex) => {
                  const steps = [
                    { value: 1, label: 'Sin Apoyo', index: 0 },
                    { value: 2, label: 'Poco Apoyo', index: 1 },
                    { value: 3, label: 'Apoyo Ocasional', index: 2 },
                    { value: 4, label: 'Bastante Apoyo', index: 3 },
                    { value: 5, label: 'Apoyo Total', index: 4 },
                  ]
                  return (
                    <div className={classes.skill}>
                      <div>
                        <Typography variant="subtitle1">{`${skillIndex + 1}-${skill}`}</Typography>
                      </div>
                      <div>
                        <SliderInput
                          disabled
                          complete={{ steps, bold: true }}
                          value={skillQuestions[skill].answer}
                        />
                      </div>
                      <div>
                        <Comment value={skillQuestions[skill].comment || 'Sin comentarios'} disabled />
                      </div>
                    </div>
                  )
                })}

              </div>
            </div>
          )
        }
        if (groupOrQuestion.type === 'group') {
          const { options } = groupOrQuestion
          return options.map(option => {
            const tasks = Object.keys(groupOrQuestion[option])

            return (
              <div>
                <div className={classes.activity}>
                  <Typography variant="h2" className={classes.bigTitle}>{`${option}`}</Typography>
                </div>
                <div className={classes.tasks}>
                  {tasks.map(task => {
                    const taskObject = groupOrQuestion[option][task]
                    const skills = Object.keys(taskObject)

                    return (
                      <div>
                        <div className={classes.activity}>
                          <Typography variant="h2" className={classes.title}>{task}</Typography>
                        </div>
                        {skills.map((skill, skillIndex) => {
                          const steps = [
                            { value: 1, label: 'Sin Apoyo', index: 0 },
                            { value: 2, label: 'Poco Apoyo', index: 1 },
                            { value: 3, label: 'Apoyo Ocasional', index: 2 },
                            { value: 4, label: 'Bastante Apoyo', index: 3 },
                            { value: 5, label: 'Apoyo Total', index: 4 },
                          ]
                          return (
                            <div>
                              <div className={classes.skill}>
                                <div>
                                  <Typography variant="subtitle1">{`${skillIndex + 1}-${skill}`}</Typography>
                                </div>
                                <div>
                                  <SliderInput
                                    disabled
                                    complete={{ steps, bold: true }}
                                    value={taskObject[skill].answer}
                                  />
                                </div>
                                <div>
                                  <Comment value={taskObject[skill].comment || 'Sin comentarios'} disabled />
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })
        }
        return null
      })
      return (
        <div className={classes.activity}>
          {index > 0 && <Divider />}
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          <div>
            {questions}
          </div>
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {this.renderForm()}
      </div>
    );
  }
}

FunctionalContainer.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  getEvaluation: propTypes.func.isRequired,
  forms: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired
}

FunctionalContainer.defaultProps = {

}

const mapStateToProps = state => ({
  forms: state.functional.forms,
  events: state.events,
  contenders: state.contenders
})

const mapDispatchToProps = dispatch => ({
  getEvaluation: body => dispatch(getFunctionalEvaluationAction(body)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(FunctionalContainer))
