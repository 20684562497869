import React from 'react'
import { Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import PercentageBar from '../../../Shared/PercentageBar'

class ProfilePercentage extends React.Component {
  render() {
    const { title, barColor, percentage } = this.props
    return (
      <div>
        <Typography style={{ margin: '12px 0', color: '#3E3E3E', fontWeight: 900, fontSize: 13 }}>
          {title}
        </Typography>
        <div>
          <PercentageBar barColor={barColor} percentage={percentage} />
        </div>
      </div>
    )
  }
}

ProfilePercentage.propTypes = {
  title: propTypes.string,
  percentage: propTypes.number,
  barColor: propTypes.string,
}

ProfilePercentage.defaultProps = {
  title: 'Título',
  percentage: 0,
  barColor: null
}

export default ProfilePercentage
