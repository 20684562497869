// eslint-disable-next-line import/prefer-default-export
export const checkIfFinished = (question, value, subquestions, subquestionAnswers = {}, answer) => {
  const isBaseAnswered = value !== ''
  const hasSubquestions = subquestions?.length > 0
  const requireLevel = question.required

  // Check if type slider because of a bug with the answer "0" [No se observa],
  // it expects to specify diagnosis, but that should not be there
  if (question.type === 'slider') return isBaseAnswered

  if (requireLevel === 1 || !requireLevel) return isBaseAnswered

  if (requireLevel === 2) {
    // console.log(question)
    // console.log(value)
    // console.log(subquestions)
    // console.log(subquestionAnswers)
    // console.log(answer)
    if (!isBaseAnswered) return false
    if (!hasSubquestions) return true

    const shouldBeAnswered = question.expected_value.toString().split('&').includes(value)

    if (!shouldBeAnswered) return true

    let hasAllValues = true
    subquestions.forEach((subquestion, index) => {
      // console.log(`${answer?.id}&${index}`)
      if (!subquestionAnswers[`${answer?.id}&${index}`]) hasAllValues = false
    })

    return hasAllValues
  }
  return true
}

export const shouldAlertInput = (question, value, subquestions, subquestionsAnswers) => {
  if (!subquestions || !question) return false
  console.log(question)
  const isBaseAnswered = value !== ''
  const finalAnswer = Array.isArray(question?.answers)
    ? question?.answers?.find(answer => answer.id === question.name)
    : question?.answers
  return isBaseAnswered
    && !checkIfFinished(
      question,
      value,
      subquestions,
      subquestionsAnswers,
      finalAnswer
    )
}
