/* eslint-disable indent */
/* eslint-disable import/no-cycle */
import React from 'react'
import propTypes from 'prop-types'
import { TextField, MultilineTextField } from '../Shared/Fields/Text'
import Select from '../Shared/Fields/Select'
import MultipleSelect from '../Shared/Fields/MultipleSelect'
import RadioGroup from '../Shared/Fields/RadioGroup'
import SliderInput from '../Shared/Fields/SliderInput'
import Checkbox from '../Shared/Fields/Checkbox'
import TitleInput from '../Shared/Fields/TitleInput'
import LongDateInput from '../Shared/Fields/LongDateInput'
import DateInput from '../Shared/Fields/DateInput'
import DateInterval from '../Shared/Fields/DateInterval'
import './style.css'

class FlexibleInput extends React.Component {
  getComponent() {
    const { complete } = this.props
    switch (complete.type) {
      case 'text': {
        return TextField
      }
      case 'checkbox': {
        return Checkbox
      }
      case 'slider': {
        return SliderInput
      }
      case 'multiline': {
        return MultilineTextField
      }
      case 'select': {
        return Select
      }
      case 'multiple': {
        return MultipleSelect
      }
      case 'radio': {
        return RadioGroup
      }
      case 'title': {
        return TitleInput
      }
      case 'date': {
        return DateInput
      }
      case 'long-date': {
        return LongDateInput
      }
      case 'date-interval': {
        return DateInterval
      }
      default: {
        return TextField
      }
    }
  }

  render() {
    const { onChange,
      complete, comment, value,
      onComment, onSubchange, subquestions,
      disabled, highlighted, answered, disableNoObservation } = this.props
    const ToRender = this.getComponent()
    return (
      <>
        <ToRender
          onChange={onChange}
          value={value}
          complete={complete}
          comment={comment}
          onComment={onComment}
          onSubchange={onSubchange}
          subquestionsAnswers={subquestions}
          disabled={disabled}
          highlighted={highlighted}
          answered={answered}
          disableNoObservation={disableNoObservation}
        />
      </>
    )
  }
}

FlexibleInput.propTypes = {
  onChange: propTypes.func.isRequired,
  value: propTypes.oneOfType([
    propTypes.string, propTypes.arrayOf(propTypes.string), propTypes.number
  ]),
  complete: propTypes.object.isRequired,
  onComment: propTypes.func,
  comment: propTypes.string,
  subquestions: propTypes.object,
  onSubchange: propTypes.func,
  disabled: propTypes.bool,
  highlighted: propTypes.bool,
  answered: propTypes.bool,
  disableNoObservation: propTypes.bool,
}

FlexibleInput.defaultProps = {
  value: '',
  comment: '',
  onComment: undefined,
  onSubchange: undefined,
  subquestions: {},
  disabled: false,
  highlighted: false,
  answered: false,
  disableNoObservation: false
}

export default FlexibleInput

export const renderSubquestion = (complete, onChange, subquestionsAnswers, disabled, answered) => {
  const { subquestions } = complete
  const newSubquestions = [...subquestions]
  return newSubquestions.map((question, index) => {
    const actualAnswer = subquestionsAnswers[`${complete.name}&${index}`]
    // eslint-disable-next-line no-param-reassign
    question.name = `${complete.name}&${index}`
    return (
      <div key={index.toString()} style={{ margin: '6px 0' }}>
        <FlexibleInput
          complete={question}
          onChange={onChange}
          value={actualAnswer}
          disabled={disabled}
          answered={answered}
        />
      </div>
    )
  })
}
