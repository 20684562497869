import React, { Component } from 'react'
import { IconButton, ListItem, ListItemIcon, ListItemText, Menu, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { createRotative, editRotative, getRotatives } from 'API/Contenders'
import { connect } from 'react-redux'
import { Add, AddCircle, Edit, MoreVert } from '@material-ui/icons'
import autobind from 'Utils/autobind'
import { TextField } from 'Shared/Fields/Text'
import ButtonsWithIcon from 'Shared/Buttons/ButtonsWithIcon'
import Rotative from './Rotative'

const style = () => ({
  container: {
    textAlign: 'start',
    padding: 8
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      width: '40%'
    },
    '& > *:last-child': {
      flexGrow: 1
    }
  },
  rotative: {
    '& > h6': {
      background: '#ececec',
      padding: '3px 6px',
      marginBottom: 12
    },
    '& > *': {
      margin: '3px 0'
    }
  },
  inputsContainer: {
    padding: 6
  }
})

class Rotatives extends Component {
  constructor() {
    super()
    this.state = {
      rotatives: [],
      menuAnchor: null,
      params: {},
      editRotatives: false,
      createRotatives: false
    }
    autobind(Rotatives, this)
  }

  async componentDidMount() {
    const { contender: { contender: { id } } } = this.props
    const response = await getRotatives({ id })
    const { data: { info: rotatives } } = response
    this.setState({ rotatives })
  }

  handleOpenMenu() {
    const element = document.getElementById('menu-anchor')
    const { menuAnchor } = this.state
    if (menuAnchor) return this.setState({ menuAnchor: null })
    return this.setState({ menuAnchor: element })
  }

  handleChange(event) {
    const { target: { name, value } } = event
    const { params } = this.state
    params[name] = value
    this.setState({ params })
  }

  handleToggleEdit() {
    const { editRotatives } = this.state
    this.setState({ editRotatives: !editRotatives, menuAnchor: null })
  }

  handleToggleCreate() {
    const { createRotatives } = this.state
    this.setState({ createRotatives: !createRotatives, menuAnchor: null })
  }

  async handleEdit(body) {
    const response = await editRotative(body)
    const { data: { info: rotatives } } = response
    this.setState({ rotatives, editRotatives: false })
  }

  async handleCreate() {
    const { params } = this.state
    const { contender: { contender: { id } } } = this.props
    const body = { ...params, contender_id: id }
    console.log(body)
    const response = await createRotative(body)
    const { data: { info: rotatives } } = response
    this.setState({ rotatives, createRotatives: false })
  }

  render() {
    const { classes } = this.props;
    const { rotatives, menuAnchor, params, editRotatives, createRotatives } = this.state
    console.log(rotatives)
    return (
      <Paper className={classes.container}>
        <div className={classes.header}>
          <Typography variant="subtitle1" color="primary">Cronograma Rotativas</Typography>
          <IconButton size="small" id="menu-anchor" onClick={this.handleOpenMenu}>
            <MoreVert />
          </IconButton>
        </div>
        <div className={classes.rotatives}>
          {rotatives.map((rotative, index) => (
            <Rotative
              key={rotative.id}
              rotative={rotative}
              index={index}
              onSubmit={this.handleEdit}
              edit={editRotatives}
            />
          ))}
          {createRotatives && (
            <div className={classes.rotative}>
              <Typography variant="subtitle1">
                {`Rotativa ${rotatives.length + 1}`}
              </Typography>
              <div className={classes.inputsContainer}>
                <div className={classes.input}>
                  <Typography variant="subtitle1">Unidad:</Typography>
                  <TextField complete={{ name: 'unit' }} value={params.unit} onChange={this.handleChange} />
                </div>
                <div className={classes.input}>
                  <Typography variant="subtitle1">Profesional PCF:</Typography>
                  <TextField complete={{ name: 'professional' }} value={params.professional} onChange={this.handleChange} />
                </div>
                <div className={classes.input}>
                  <Typography variant="subtitle1">Mentor:</Typography>
                  <TextField complete={{ name: 'mentor' }} value={params.mentor} onChange={this.handleChange} />
                </div>
                <div className={classes.input}>
                  <Typography variant="subtitle1">Fecha inicio:</Typography>
                  <TextField complete={{ name: 'date' }} value={params.date} onChange={this.handleChange} />
                </div>
              </div>
              <div style={{ marginTop: 12, justifyContent: 'end', display: 'flex' }}>
                <ButtonsWithIcon Icon={Add} variant="text" color="primary" size="small" onClick={this.handleCreate}>
                  Agregar
                </ButtonsWithIcon>
              </div>
            </div>
          )}
        </div>
        <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={this.handleOpenMenu}>
          <div className={classes.menu}>
            <ListItem button onClick={this.handleToggleEdit}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary="Editar Rotativa" />
            </ListItem>
            <ListItem button onClick={this.handleToggleCreate}>
              <ListItemIcon>
                <AddCircle />
              </ListItemIcon>
              <ListItemText primary="Agregar Rotativa" />
            </ListItem>
          </div>
        </Menu>
      </Paper>
    );
  }
}

Rotatives.propTypes = {
  classes: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
}

Rotatives.defaultProps = {

}

const mapStateToProps = state => ({
  contender: state.contenders.selected
})

export default connect(mapStateToProps)(withStyles(style)(Rotatives))
