import React, { Component } from 'react'
import { Paper, Typography, Radio, Button, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import autobind from '../../../../Utils/autobind'
import FamiliarInterviewWithIndicators from './FamiliarInterviewWithIndicators'
import FamiliarInterviewWithoutIndicators from './FamiliarInterviewWithoutIndicators'
import SetViewContext from '../../../Contenders/Profile/SetViewProvider'

const style = theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  selectedText: {
    color: theme.palette.confirm.main
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  container: {
    padding: 24,
    maxWidth: '60vw'
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'start',
    margin: 12
  },
  underline: {
    textDecoration: 'underline'
  }
})

class FamiliarInterview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null
    }
    autobind(FamiliarInterview, this)
  }

  handleSelect(event) {
    const { target } = event
    this.setState({ selected: target.value })
  }

  handleStartInterview() {
    const { selected } = this.state
    const { event } = this.props
    const { setView } = this.context
    if (selected === '0') {
      const view = <FamiliarInterviewWithIndicators event={event} />
      setView(view, 'Entrevista con Indicadores')
    } else {
      const view = <FamiliarInterviewWithoutIndicators event={event} />
      setView(view, 'Entrevista sin Indicadores')
    }
  }

  render() {
    const { classes } = this.props
    const { selected } = this.state
    return (
      <Paper className={classes.container}>
        <div className={classes.options}>
          <Typography variant="h2" style={{ margin: '24px 0 12px 12px' }}>Seleccionar opción de entrevista</Typography>
          <div className={classes.radio}>
            <Radio color="primary" checked={selected === '0'} onClick={this.handleSelect} value={0} />
            <div style={{ marginLeft: 24 }}>
              <Typography variant="subtitle1">Mostrar indicadores en entrevista</Typography>
              <Typography variant="caption">
                recomendado cuando hay
                {' '}
                <span className={classes.underline}>un solo participante</span>
                {' '}
                en la entrevista
              </Typography>
            </div>
          </div>
          <div className={classes.radio}>
            <Radio color="primary" checked={selected === '1'} onClick={this.handleSelect} value={1} />
            <div style={{ marginLeft: 24 }}>
              <Typography variant="subtitle1">Ejecutar link con indicadores</Typography>
              <Typography variant="caption">
                recomendado cuando hay
                {' '}
                <span className={classes.underline}>más de un participante</span>
                {' '}
                en la entrevista
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.button}>
          <Button disabled={!selected} variant="contained" color="secondary" onClick={this.handleStartInterview}>
            Comenzar Entrevista
          </Button>
        </div>
      </Paper>
    )
  }
}

FamiliarInterview.contextType = SetViewContext

FamiliarInterview.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired
}

FamiliarInterview.defaultProps = {

}

export default withStyles(style)(FamiliarInterview)
