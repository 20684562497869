import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import SupportLevel from './SupportLevel'
import CompetenciesProfile from './CompetenciesProfile'
import POI from './POI'
import EAVD from './EAVD'
import Gencat from './Gencat'
import Individual from './Individual'
import Familiar from './Familiar'

const style = () => ({

})
class Evaluations extends Component {
  render() {
    const { classes, values } = this.props;
    return (
      <div className={classes.container}>
        <SupportLevel values={values} />
        <CompetenciesProfile values={values} />
        <div className="no-print">
          <POI />
          <EAVD />
          <Gencat />
          <Individual />
          <Familiar />
        </div>
      </div>
    );
  }
}

Evaluations.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
}

Evaluations.defaultProps = {
}
export default withStyles(style)(Evaluations)
