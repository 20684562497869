/* eslint-disable import/prefer-default-export */
import instance from '../Utils/client'
import { callSnackbar } from '../Utils/snackbars'

export const commentParticipant = async body => {
  const response = await instance({ method: 'post', url: `/contenders/${body.user_id}/comment`, data: body })
  if (response.data.status !== 'success') return callSnackbar('No se pudo editar comentario', 'error')
  return callSnackbar('Comentario editado exitosamente', 'success')
}

export const getEvaluationComment = async body => {
  const response = await instance({
    method: 'get',
    url: 'evaluation_comments',
    params: body
  })
  return response
}

export const editEvaluationComment = async body => {
  const response = await instance({
    method: 'post',
    url: 'evaluation_comments',
    data: body
  })
  if (response.data.status === 'success') { callSnackbar('Editado correctamente', 'success') }
  return response
}

export const destroyEvaluation = async body => {
  const response = await instance({
    method: 'delete',
    url: `evaluations/${body.id}/destroy_evaluation`
  })
  return response
}

export const destroyEvaluationGroup = async body => {
  const response = await instance({
    method: 'delete',
    url: `evaluations/${body.id}/destroy_evaluation_group`
  })
  return response
}

export const discardEvaluationGroup = async body => {
  const response = await instance({
    method: 'delete',
    url: `evaluations/${body.id}/discard_evaluation_group`
  })
  return response
}

export const undiscardEvaluationGroup = async body => {
  const response = await instance({
    method: 'put',
    url: `evaluations/${body.id}/undiscard_evaluation_group`
  })
  return response
}

export const discardEvaluation = async body => {
  const response = await instance({
    method: 'delete',
    url: `evaluations/${body.id}/discard_evaluation`
  })
  return response
}

export const undiscardEvaluation = async body => {
  const response = await instance({
    method: 'put',
    url: `evaluations/${body.id}/undiscard_evaluation`
  })
  return response
}
