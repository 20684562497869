import React, { Component } from 'react'
import { IconButton, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { CloudDownload } from '@material-ui/icons'
import autobind from 'Utils/autobind'
import html2canvas from 'html2canvas'

const style = () => ({
  container: {
    position: 'absolute',
    top: 6,
    right: 6,
    zIndex: 2
  }
})

class DownloadButton extends Component {
  constructor() {
    super()
    autobind(DownloadButton, this)
  }

  handleDownload() {
    const { title, name } = this.props
    const element = document.getElementById(title)
    html2canvas(element, { scale: 1.2 }).then(canvas => {
      const base64 = canvas?.toDataURL('image/jpg')
      const a = document.createElement('a')
      a.href = base64
      a.download = `${name}`
      a.click()
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <IconButton onClick={this.handleDownload}>
          <CloudDownload />
        </IconButton>
      </div>
    );
  }
}

DownloadButton.propTypes = {
  classes: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
  name: propTypes.string
}

DownloadButton.defaultProps = {
  name: 'Resultado'
}
export default withStyles(style)(DownloadButton)
