import React, { Component } from 'react'
import { withStyles, Typography, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import FlexibleInput from '../Utils/FieldRenderer'

const style = () => ({
  container: {
    padding: 12,
    textAlign: 'left',
  },
  textInput: {
    textAlign: 'left',
  },
  textInputLabel: {
    textAlign: 'left',
  },
  button: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

class GeneralForm extends Component {
  renderInputs() {
    const {
      info,
      handleChange,
      handleComment,
      params,
      saved,
      comments,
      savedComments,
      handleSubchange,
      disabled,
      highlighted,
      enumerate
    } = this.props
    return info.map((input, index) => {
      let value = ''
      const completeInput = { ...input }
      let subquestions = {}
      const savedValue = saved[input.name]
      const paramsValue = params[input.name]
      if (savedValue) {
        if (savedValue.answer !== undefined) {
          value = savedValue.answer
        } else {
          value = savedValue
        }
        if (savedValue.subquestions) {
          subquestions = savedValue.subquestions
        }
      } else if (paramsValue) {
        if (paramsValue.answer !== undefined) {
          value = paramsValue.answer
        } else {
          value = paramsValue
        }
        if (paramsValue.subquestions) {
          subquestions = paramsValue.subquestions
        }
      }
      if (enumerate) {
        completeInput.text = `${index + 1} - ${input.text}`
      }
      return (
        <FlexibleInput
          key={input.name}
          value={value}
          onChange={handleChange}
          onComment={handleComment}
          onSubchange={handleSubchange}
          complete={completeInput}
          comment={savedComments[input.name] || comments[input.name]}
          subquestions={subquestions || []}
          disabled={disabled}
          highlighted={highlighted && !value}
        />
      )
    })
  }

  render() {
    const { classes, title, submit, handleSubmit, areas, withButton, bigScreen } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="body1">{title}</Typography>
        <div
          style={{
            display: 'grid',
            gridTemplateAreas: `"${areas}"`,
            gridGap: bigScreen ? 12 : 24,
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          }}
        >
          {this.renderInputs()}
        </div>
        {withButton && (
          <div className={classes.button}>
            <Button variant="contained" color="secondary" onClick={handleSubmit}>
              {submit}
            </Button>
          </div>
        )}
      </div>
    )
  }
}

GeneralForm.propTypes = {
  classes: propTypes.object.isRequired,
  info: propTypes.arrayOf(propTypes.object),
  params: propTypes.object.isRequired,
  handleChange: propTypes.func.isRequired,
  handleSubchange: propTypes.func.isRequired,
  handleComment: propTypes.func.isRequired,
  handleSubmit: propTypes.func,
  title: propTypes.string,
  submit: propTypes.string,
  areas: propTypes.string,
  withButton: propTypes.bool,
  saved: propTypes.object,
  comments: propTypes.object,
  savedComments: propTypes.object,
  bigScreen: propTypes.bool,
  disabled: propTypes.bool,
  highlighted: propTypes.bool,
  enumerate: propTypes.bool
}

GeneralForm.defaultProps = {
  info: [],
  submit: 'Guardar',
  areas: '',
  withButton: true,
  title: '',
  handleSubmit: undefined,
  saved: {},
  comments: {},
  savedComments: {},
  bigScreen: true,
  disabled: false,
  highlighted: false,
  enumerate: false,
}

export default withStyles(style)(GeneralForm)
