import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import ReportTitles from '../ReportTitles'
import ResultPercentages from '../../../../Results/ResultPercentages'
import RadarChart from '../../../../../Shared/Charts/RadarChart'
// import { MultilineTextField } from '../../../../../Shared/Fields/Text'

const style = theme => ({
  cards: {
    display: 'grid',
    gridTemplateAreas: "'c1 c1 g' 'c2 c2 g'",
    gridGap: 12
  },
  container: {
    marginBottom: 48,
  },
  tag: {
    textAlign: 'center',
    '& > h2': {
      background: 'whitesmoke',
      borderRadius: 24,
      padding: '6px 12px',
      width: 150,
      margin: 'auto',
      textAlign: 'center'
    }
  },
  card: {
    padding: 12
  },
  progressBar: {
    background: theme.palette.indigo.lighter,
    borderRadius: 22,
    height: 24,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0',
  },
  progress: {
    height: 24,
    borderRadius: 22,
    background: theme.palette.indigo.light,
  },
  endText: {
    position: 'absolute',
    right: 12,
  },
  chart: {
    width: '70%',
    margin: 'auto'
  },
  input: {
    marginTop: 12
  },
  definition: {
    textAlign: 'justify',
    marginTop: 12,
    lineHeight: 1.75
  }
})

const radarOptions = {
  color: '#1F8095BB',
  maxTicksLimit: 6,
  height: '300px'
}

function getSupportLevel(value) {
  if (!value) return 0
  const section = value < 100 ? Math.floor(value / 20) : 4
  const levels = [
    'Apoyo Total',
    'Bastante Apoyo',
    'Apoyo Ocasional',
    'Poco Apoyo',
    'Sin Apoyo'
  ]
  return levels[section]
}

function getSupportDefinition(value) {
  const definitions = [
    'Siempre; cada vez que la persona participa en la actividad necesita apoyo extra que otros de su edad no necesitan.',
    'Muy frecuente; en la mayoría de las ocasiones en las que ocurre la actividad, la persona necesita un apoyo extra que otros de su edad no necesitan, sólo ocasionalmente la persona no necesita apoyo extra.',
    'Frecuente; para que la persona participe en la actividad necesitará apoyo extraordinario aproximadamente en la mitad de ocasiones.',
    'Infrecuente; la persona necesitará ocasionalmente alguien que le preste un apoyo extraordinario que otros de su edad no necesitan, pero en la mayoría de ocasiones no necesita apoyo extra.',
    'No significativa; las necesidades de apoyo de la persona no son diferentes a otros de su misma edad.',
  ]
  if (!value) return definitions[0]
  const section = value < 100 ? Math.floor(value / 20) : 4
  return definitions[section]
}

class SupportLevel extends Component {
  render() {
    const { classes, values, contenders } = this.props
    const results = contenders.profileResults
    const { avd, gencat } = results
    return (
      <div className={classes.container}>
        <ReportTitles title="Nivel de apoyo" />
        <div className={classes.cards}>
          <Paper style={{ gridArea: 'c1' }} className={classes.card}>
            <Typography variant="subtitle1">Requerimiento de apoyo</Typography>
            <div className={classes.tag}>
              <Typography variant="h2">{getSupportLevel(values?.support_level)}</Typography>
            </div>
            <div className={classes.definition}>
              <Typography variant="body1">
                {getSupportDefinition(values?.support_level)}
              </Typography>
            </div>
          </Paper>
          <Paper style={{ gridArea: 'c2' }} className={classes.card}>
            <ResultPercentages
              title="Índice de autonomía:"
              subtitle1="Ponderación Postulante"
              subtitle2="Ponderación Acompañante"
              result={{
                handler_percentage: avd?.handler_percentage,
                companion_percentage: avd?.companion_percentage,
                average_percentage: avd?.average_percentage
              }}
              containerStyle={{}}
            />
          </Paper>
          {gencat && (
            <Paper style={{ gridArea: 'g' }} className={classes.card}>
              <Typography variant="subtitle1">Niveles de apoyo de competencias</Typography>
              <div className={classes.chart}>
                <RadarChart name="competencias" title="" options={radarOptions} data={gencat} />
              </div>
            </Paper>
          )}
        </div>
        {/* <div className={classes.input}>
          <MultilineTextField
          complete={{ rows: 2, placeholder: 'Comentarios sobre nivel de apoyo' }} />
        </div> */}
      </div>
    );
  }
}

SupportLevel.propTypes = {
  classes: propTypes.object.isRequired,
  values: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
}

SupportLevel.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(SupportLevel))
