import instance from '../Utils/client'
import createSnackbar from '../Utils/snackbars'

const createAuthorizationAction = body => {
  const REQUEST = instance({
    url: '/authorizations',
    data: body,
    method: 'post',
  })
  return {
    type: 'CREATE_AUTHORIZATION',
    payload: REQUEST,
  }
}

const loadAuthorizationsAction = () => ({
  type: 'LOAD_AUTHORIZATIONS',
  payload: instance({
    method: 'get',
    url: '/authorizations',
  }),
})

const approveAuthorizationAction = authId => {
  const REQUEST = instance({
    method: 'put',
    url: `/authorizations/${authId}/approve`,
  })
  createSnackbar(REQUEST)
  return {
    type: 'APPROVE_AUTHORIZATION',
    payload: REQUEST
  }
}

const rejectAuthorizationAction = authId => {
  const REQUEST = instance({
    method: 'delete',
    url: `/authorizations/${authId}`,
  })
  createSnackbar(REQUEST)
  return {
    type: 'REJECT_AUTHORIZATION',
    payload: REQUEST
  }
}

export { approveAuthorizationAction,
  createAuthorizationAction,
  loadAuthorizationsAction,
  rejectAuthorizationAction }
