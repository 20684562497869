import React, { Component } from 'react'
import propTypes from 'prop-types'

export default class Conditional extends Component {
  render() {
    const { condition, hasElse, children } = this.props
    const kids = React.Children.toArray(children)
    return (
      <>
        {condition
          ? kids[0]
          : hasElse && children[1]}
      </>
    )
  }
}

Conditional.propTypes = {
  condition: propTypes.bool,
  hasElse: propTypes.bool,
  children: propTypes.node.isRequired
}

Conditional.defaultProps = {
  condition: false,
  hasElse: false,
}
