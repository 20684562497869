import React from 'react'
import { Paper, withStyles, IconButton, MenuItem, Menu } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import propTypes from 'prop-types'
import PageChanger from './PageChanger'
import autobind from '../../../../Utils/autobind'
import CriteriumBarChart from './CriteriumLIneChart'
import InterviewGencat from './InterviewGencat'
import SetViewContext from '../../../Contenders/Profile/SetViewProvider'

const style = () => ({
  container: {
    gridColumn: '1 / 3'
  },
  threeDotsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 12px'
  },
  menu: {
    position: 'absolute',
    width: '200px',
    right: '24px',
    top: '205px',
  },
  submenu: {
    position: 'absolute',
    width: '150px',
    right: '95%',
    top: '75%',
  }
})

class SecondPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openSubmenu: null,
      openMenu: null,
    }
    autobind(SecondPage, this)
  }

  handlePrevPage() {
    const { handleNextStep } = this.props
    handleNextStep(0)
  }

  handleSubmenu(id) {
    return () => {
      let element = id
      if (id !== null) {
        element = document.getElementById(id)
      }
      this.setState({ openSubmenu: element })
    }
  }

  handleMenu(id) {
    return () => {
      let element = id
      if (id !== null) {
        element = document.getElementById(id)
      }
      this.setState({ openMenu: element })
    }
  }

  handleSetView(viewComponent, name) {
    const { setView } = this.context
    return () => {
      setView(viewComponent, name)
    }
  }

  renderCriteriumCharts() {
    const { gencatResults } = this.props
    const [gencat] = gencatResults
    return gencat.results.map(criterium => {
      const chartData = criterium.questions?.map(question => ({
        label: question.text,
        value: question.value,
        color: question.score < 2 ? '#d6502c' : '#6CD2B8'
      }))
      return (
        <CriteriumBarChart
          data={chartData}
          chartRef={criterium.short}
          title={criterium.name}
          totalScore={criterium.score}
          relativeScore={criterium.std_score_percentage}
        />
      )
    })
  }

  render() {
    const { classes, gencatResults, event } = this.props
    const { openMenu, openSubmenu } = this.state
    if (!gencatResults) { return null }
    return (
      <Paper className={classes.container}>
        <PageChanger text="2 de 2" handlePrev={this.handlePrevPage} title="Resultado por dimensión" />
        <div className={classes.threeDotsContainer}>
          <IconButton size="small" color="primary" onClick={this.handleMenu('menu')} id="menu">
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={openMenu}
            open={openMenu !== null}
            onClose={this.handleMenu(null)}
          >
            <MenuItem>Descargar</MenuItem>
            <MenuItem
              disabled={Object.keys(event.dynamic_criterium_id).length === 0}
              onClick={this.handleSubmenu('submenu')}
              id="submenu"
            >
              Seleccionar para Entrevista
            </MenuItem>
            <Menu
              anchorEl={openSubmenu}
              open={openSubmenu !== null}
              onClose={this.handleSubmenu(null)}
            >
              <MenuItem
                onClick={this.handleSetView(<InterviewGencat
                  event={event}
                  title="Familiar"
                  withCheckbox
                />, 'Selección entrevista Familiar')}
              >
                Familiar
              </MenuItem>
            </Menu>
          </Menu>
        </div>
        {this.renderCriteriumCharts()}
      </Paper>
    )
  }
}

SecondPage.contextType = SetViewContext

SecondPage.propTypes = {
  classes: propTypes.object.isRequired,
  handleNextStep: propTypes.func.isRequired,
  gencatResults: propTypes.arrayOf(propTypes.object),
  event: propTypes.object.isRequired
}

SecondPage.defaultProps = {
  gencatResults: undefined
}

export default withStyles(style)(SecondPage)
