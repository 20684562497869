import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

const style = () => ({
  container: {
    '& > h2': {
      marginBottom: 24,
      fontWeight: 600
    },
    height: 250,
    paddingBottom: 42
  }
})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

class ProgressLineChart extends Component {
  componentDidMount() {
    const { data } = this.props
    const chartElement = document.getElementById('lineLevels')
    const myChartRef = chartElement.getContext('2d');
    chartElement.height = '250'
    const labels = data.map((_, index) => `Evaluación ${index + 1}`)

    this.chart = new Chart(myChartRef, {
      type: 'line',
      data: {
        // Bring in data
        labels,
        datasets: [
          {
            fill: false,
            data,
            backgroundColor: colors,
            radius: 6
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              minRotation: 0,
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            offset: true,
          },
          ],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 0,
              max: 100,
              stepSize: 25,
              offset: true
            },
            gridLines: {
              display: true,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        },
        legend: {
          display: false
        }
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (prevProps.data !== data) {
      const datasets = [
        {
          fill: false,
          data,
          backgroundColor: colors,
          radius: 6
        },
      ]
      this.chart.data.labels = data.map((_, index) => `Evaluación ${index + 1}`)
      this.chart.data.datasets = datasets
      this.chart.update()
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h2">Progreso de evaluaciones</Typography>
        <canvas id="lineLevels" ref={this.chartRef} />
      </div>
    );
  }
}

ProgressLineChart.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
}

ProgressLineChart.defaultProps = {

}
export default withStyles(style)(ProgressLineChart)
