import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { getFormResultAction } from '../../../../Actions/Contenders'
import Conditional from '../../../../Shared/Conditional'
import FirstPage from './FirstPage'
import SecondPage from './SecondPage'
import StepContainer from '../../../../Shared/StepContainer'
import GencatTabs from './GencatTabs'
import GencatResultForm from './GencatResultForm'
import autobind from '../../../../Utils/autobind'
import { editEvaluationComment, getEvaluationComment } from '../../../../API/Evaluations'
import GlobalComment from '../../../../Shared/GlobalComment'

const style = theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 24
  },
  button: {
    color: theme.palette.grey.border,
    height: 20,
    width: 20,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12
  },
  commentBox: {
    margin: '24px 12px',
    textAlign: 'left'
  }
})

class GencatResult extends Component {
  constructor() {
    super()
    this.state = {
      comment: ''
    }
    autobind(GencatResult, this)
  }

  async componentDidMount() {
    const { getResultGencat, contender, event } = this.props
    const body = {
      user_id: contender.user.id,
      type: 'gencat',
      e_id: event.event_id,
      f_id: event.form_id,
    }
    getResultGencat(body)

    const commentBody = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contender.user.id,
    }
    const response = await getEvaluationComment(commentBody)
    if (response.data.status !== 'success') return null
    return this.setState({ comment: response.data.info.comment })
  }

  handleChange(event) {
    const { target } = event
    this.setState({ comment: target.value })
  }

  handleSave() {
    const { comment } = this.state
    const { event, contender } = this.props
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contender.user.id,
      comment
    }
    editEvaluationComment(body)
  }

  render() {
    const { classes, results, event } = this.props
    return (
      <GencatTabs>
        <>
          <div style={{ margin: '12px 12px' }}>
            <GlobalComment event={event} />
          </div>
          <div className={classes.container}>

            <Conditional condition={Object.keys(results).length > 0}>
              <StepContainer>
                <FirstPage gencatResults={results ? results.results : undefined} />
                <SecondPage
                  gencatResults={results ? results.results : undefined}
                  event={event}
                />
              </StepContainer>
            </Conditional>
          </div>
        </>
        <div>
          <GencatResultForm results={results?.results} />
        </div>
      </GencatTabs>
    )
  }
}

GencatResult.propTypes = {
  classes: propTypes.object.isRequired,
  results: propTypes.arrayOf(propTypes.object),
  getResultGencat: propTypes.func.isRequired,
  contender: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
}

GencatResult.defaultProps = {
  results: []
}

const mapStateToProps = state => ({
  results: state.contenders.results,
  contender: state.contenders.selected
})

const mapDispatchToProps = dispatch => ({
  getResultGencat: body => dispatch(getFormResultAction(body)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(GencatResult))
