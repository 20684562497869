import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { Menu, MenuItem, Typography, withStyles } from '@material-ui/core'
import { AddCircle, MoreVert, Person } from '@material-ui/icons'
import { withSnackbar } from 'notistack'
import { disableUserAccount, enableUserAccount } from 'API/User'
import Loader from 'Shared/Loader'
import ButtonsWithIcon from 'Shared/Buttons/ButtonsWithIcon'
import GeneralTable from 'Shared/GeneralTable'
import loadHandlersAction from 'Actions/Handlers'
import { registerAction } from 'Actions/Login'
import autobind from 'Utils/autobind'
import { grantPermission } from 'Utils/functions'
import CreateDialog from './CreateDialog'
import { handlerInfo, handlerFilter, handlerSortable } from './HandlerTableInfo'

const style = theme => ({
  mainContainer: {
    padding: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2)
  },
  menuItem: {
    width: 200,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > svg': {
      height: 18,
      width: 18
    }
  }
})

const enterprises = ['Dimerc', 'Excon Huechuraba', 'Translogic', 'DMG S.A', 'Clinica universidad de los andes']
const programs = ['ConInclusion', 'ConFuturo']
const areas = [
  'Conocimiento de la Persona',
  'Familia',
  'Salud',
  'Inclusión Laboral',
  'Desarrollo']

const chooseRandom = array => array[Math.floor(Math.random() * array.length)]

const handlerInfoParser = data => data.map(element => (
  {
    user_id: element.user.id,
    name: element.user.name,
    enabled: element.user.enabled ? { name: 'Activo', color: '#48A4B0' } : { name: 'Inactivo', color: '#6A6A6A' },
    program: chooseRandom(programs),
    enterprise: chooseRandom(enterprises),
    commune: element.commune || 'No ingresado',
    areas: element.areas,
    area: chooseRandom(areas),
    created_at: element.handler.created_at,
    updated_at: element.handler.updated_at,
  }
))

class HandlersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openCreate: false,
      openActions: false,
      anchor: '',
      clickedHandler: null,
    }
    autobind(HandlersList, this)
  }

  handleCreateClose() {
    this.setState({ openCreate: false })
  }

  handleOpenCreate() {
    this.setState({ openCreate: true })
  }

  handleRegister(body) {
    const { register, enqueueSnackbar } = this.props
    register(body, enqueueSnackbar)
  }

  async handleDisableAccount() {
    const { clickedHandler } = this.state
    const { getAllHandlers } = this.props
    if (clickedHandler.enabled.name === 'Activo') {
      await disableUserAccount(clickedHandler.user_id)
    } else {
      await enableUserAccount(clickedHandler.user_id)
    }
    await getAllHandlers()
    this.handleCloseActions()
  }

  handleCloseActions() {
    this.setState({
      openActions: false,
      anchor: '',
      clickedHandler: null
    })
  }

  handleOpenActions(row) {
    return event => {
      const { target } = event
      this.setState({
        openActions: true,
        anchor: target,
        clickedHandler: row
      })
    }
  }

  renderActionMenu() {
    const { classes } = this.props
    const { clickedHandler } = this.state
    const action = clickedHandler?.enabled.name === 'Activo' ? 'Desactivar' : 'Activar'
    return (
      <MenuItem onClick={this.handleDisableAccount}>
        <div className={classes.menuItem}>
          <Typography variant="subtitle1">
            {clickedHandler ? `${action} cuenta` : ''}
          </Typography>
          <Person />
        </div>
      </MenuItem>
    )
  }

  render() {
    const { openCreate, anchor, openActions } = this.state
    const { handlers, getAllHandlers, classes, user } = this.props
    const handlerList = handlerInfoParser(handlers.all)
    const canAction = grantPermission(user, ['director'])
    const actions = canAction ? [
      { name: 'Acciones', icon: MoreVert, action: this.handleOpenActions },
    ] : []
    return (
      <div className={classes.mainContainer}>
        <Loader action={getAllHandlers}>
          <div className={classes.header}>
            <ButtonsWithIcon Icon={AddCircle} onClick={this.handleOpenCreate}>
              Agregar facilitador
            </ButtonsWithIcon>
          </div>
          <GeneralTable
            data={handlerList}
            info={handlerInfo}
            actions={actions}
            filters={handlerFilter}
            sortings={handlerSortable}
            name="handlers"
            disablePagination
          />
          <CreateDialog
            open={openCreate}
            onClose={this.handleCreateClose}
            onSubmit={this.handleRegister}
          />
          <Menu anchorEl={anchor} open={openActions} onClose={this.handleCloseActions}>
            {this.renderActionMenu()}
          </Menu>
        </Loader>
      </div>
    )
  }
}

HandlersList.propTypes = {
  classes: propTypes.object.isRequired,
  handlers: propTypes.object.isRequired,
  register: propTypes.func.isRequired,
  user: propTypes.object.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  getAllHandlers: propTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  getAllHandlers: () => dispatch(loadHandlersAction()),
  register: (body, snackbar) => dispatch(registerAction(body, snackbar)),
})

const mapStateToProps = state => ({
  handlers: state.handler,
  user: state.user.current,
})

export default withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(HandlersList)))
