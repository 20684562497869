import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import IndicatorChart from './IndicatorChart'

const style = theme => ({
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 2
  },
  commentsTitle: {
    marginBottom: theme.spacing.unit * 2
  },
  commentAuthor: {
    color: 'black',
  },
  commentContainer: {
    marginBottom: theme.spacing.unit * 2,
    '&:last-child': {
      marginBottom: 0
    }
  }
})

class IndicatorAnswer extends React.Component {
  render() {
    const { complete, value, classes } = this.props
    const options = ['No observado'].concat(complete.steps.map(step => step.label))
    return (
      <>
        <div className={classes.inline}>
          <IndicatorChart body={value} options={options} name={complete.text} fullWidth />
        </div>
        <Typography className={classes.commentsTitle}> Comentarios: </Typography>
        {value.map(answer => (
          <div className={classes.commentContainer}>
            <Typography className={classes.commentAuthor}>
              {answer.companion_name}
            </Typography>
            <Typography>
              {answer.comment}
            </Typography>
          </div>
        ))}
      </>
    )
  }
}

IndicatorAnswer.propTypes = {
  classes: propTypes.object.isRequired,
  complete: propTypes.object.isRequired,
  value: propTypes.arrayOf(propTypes.oneOfType([propTypes.string, propTypes.number])),
}

IndicatorAnswer.defaultProps = {
  value: [],
}

export default withStyles(style)(IndicatorAnswer)
