import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    margin: '24px 0',
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    flexGrow: 1,
    height: 1,
    background: '#2C2C2C42',
    margin: '0 12px'
  }
})

class SectionTitle extends Component {
  render() {
    const { classes, title } = this.props;
    return (
      <div className={classes.container}>
        <Typography variant="h2">{title}</Typography>
        <div className={classes.line} />
      </div>
    );
  }
}

SectionTitle.propTypes = {
  classes: propTypes.object.isRequired,
  title: propTypes.string
}

SectionTitle.defaultProps = {
  title: 'Titulo'
}
export default withStyles(style)(SectionTitle)
