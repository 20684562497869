import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

const style = () => ({

})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

class ResultCharts extends Component {
  componentDidMount() {
    const chartElement = document.getElementById('comparison')
    const myChartRef = chartElement.getContext('2d');
    chartElement.maxHeight = '250'
    const [datasets, labels] = this.generateDatasets()

    this.chart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels,
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              minRotation: 0,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
            offset: true,
          },
          ],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 0,
              max: 100,
              stepSize: 25,
              callback: value => `${value}% `
            },
            gridLines: {
              display: true,
              drawBorder: false
            },
            scaleLabel: {
              display: true,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            }
          }]
        },
        legend: {
          display: true,
        },
        animation: {
          onComplete: () => {
            const chartInstance = this.chart;
            const { ctx } = chartInstance;
            ctx.font = 'Montserrat, sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.chart.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const metadata = dataset.data[index];
                // eslint-disable-next-line no-underscore-dangle
                ctx.fillText(metadata, bar._model.x, bar._model.y - 5);
              });
            });
          }
        }
      },
      plugins: [
        {
          id: 'custom_canvas_background_color',
          beforeDraw: chart => {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        },
      ]
    })
  }

  generateDatasets() {
    const { data } = this.props
    const labels = Object.keys(data)
    const processed = {}
    const avgs = []
    labels.forEach(label => {
      const skillValues = data[label]
      const totalEvaluators = Object.keys(skillValues).length
      let skillSum = 0
      Object.entries(skillValues).forEach(([position, value]) => {
        if (!Array.isArray(processed[position])) {
          processed[position] = []
        }
        processed[position].push(value)
        skillSum += value
      })
      if (totalEvaluators > 0) {
        const skillAvg = skillSum / totalEvaluators
        avgs.push(skillAvg)
      }
    })
    const datasets = Object.entries(processed).map(([label, values], index) => ({
      fill: false,
      barThickness: 25,
      data: values,
      backgroundColor: colors[index],
      label: label.split(':')[0],
    }))
    datasets.splice(0, 0, {
      fill: false,
      type: 'line',
      pointRadius: 5,
      pointHoverRadius: 6,
      borderWidth: 2,
      borderColor: '#6a6a6a',
      data: avgs,
      backgroundColor: '#6A6A6A',
      label: 'Promedio Total por ámbito'
    })
    return [datasets, labels]
  }

  render() {
    const { classes } = this.props;
    this.generateDatasets()
    return (
      <div className={classes.container}>
        <Typography variant="h2" style={{ marginBottom: 12, fontWeight: 600 }}>Porcentaje de Aspecto por evaluador</Typography>
        <canvas style={{ height: 350, maxHeight: 350 }} id="comparison" ref={this.chartRef} />
      </div>
    );
  }
}

ResultCharts.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
}

ResultCharts.defaultProps = {

}
export default withStyles(style)(ResultCharts)
