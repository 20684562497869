import React, { Component } from 'react'
import { withStyles, Typography, Button, Divider } from '@material-ui/core'
import propTypes from 'prop-types'
import Conditional from '../../../../Shared/Conditional'

const style = theme => ({
  answer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '60%',
    margin: '5px 0'
  },
  container: {
    padding: 24,
    textAlign: 'left'
  },
  editButton: {
    color: theme.palette.confirm.main,
    border: `2px solid ${theme.palette.confirm.main}`,
  },
  questionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  questionsTitle: {
    marginBottom: 12,
  },
})

class ActiveDiagnosis extends Component {
  getAnswerFromIndex(index) {
    const { answer } = this.props
    let result = false
    if (answer.subquestions) {
      Object.entries(answer.subquestions).forEach(([id, value]) => {
        const subIndex = parseInt(id.split('&')[1], 10)
        if (index === subIndex) {
          result = value
        }
      })
    }
    return result
  }

  dateIntervalAnswer(start, end, ans) {
    const { classes } = this.props
    const splitAns = ans.split('&')
    return (
      <React.Fragment key={ans}>
        <div className={classes.answer}>
          <Typography variant="h2">{start}</Typography>
          <Typography variant="h2" style={{ fontWeight: 600 }}>{splitAns[0]}</Typography>
        </div>
        <div className={classes.answer}>
          <Typography variant="h2">{end}</Typography>
          <Typography variant="h2" style={{ fontWeight: 600 }}>{splitAns[1]}</Typography>
        </div>
      </React.Fragment>
    )
  }

  simpleAnswer(text, ans) {
    const { classes } = this.props
    return (
      <div className={classes.answer} key={text}>
        <Typography variant="h2">{text}</Typography>
        <Typography variant="h2" style={{ fontWeight: 600 }}>{ans}</Typography>
      </div>
    )
  }

  renderAnswerGroups() {
    const { groups, groupDividerText } = this.props
    const groupsAmount = groups.length
    return groups.map((group, idx) => (
      <div style={idx !== groupsAmount - 1 ? { marginBottom: 24 } : {}}>
        <Typography>
          {`${groupDividerText} ${idx + 1}`}
        </Typography>
        {this.renderAnswerGroup(group)}
      </div>
    ))
  }

  renderAnswerGroup(group) {
    const { answerId, answer, classes } = this.props
    return group.question.map((q, idx) => {
      const groupKey = `${answerId}#${group.id}#${idx}`
      const answerObj = answer.groups[groupKey]
      const answerText = answerObj ? answerObj.answer : ''
      return (
        <div className={classes.answer} key={groupKey}>
          <Typography variant="h2">{q.text}</Typography>
          <Typography variant="h2" style={{ fontWeight: 600 }}>{answerText}</Typography>
        </div>
      )
    })
  }

  renderAnswers() {
    const { questions } = this.props
    return questions.map((question, index) => {
      const ans = this.getAnswerFromIndex(index)
      if (ans) {
        if (question.type === 'date-interval') {
          return (
            this.dateIntervalAnswer(question.start_label, question.end_label, ans)
          )
        } if (question.type === 'select' || question.type === 'radio') {
          return (
            this.simpleAnswer(question.text, question.options[ans])
          )
        }
        return (
          this.simpleAnswer(question.text, ans)
        )
      }
      return null
    })
  }

  renderQuestions() {
    const { classes, full } = this.props
    if (full) {
      return full.questions.filter(q => q.checked).map((question, index) => <Typography key={question.id} variant="subtitle2" className={classes.question}>{`${index + 1} - ${question.text}`}</Typography>)
    }
    return null
  }

  renderQuestion() {
    const { title, classes, done, handleSelect, groups } = this.props
    return (
      <>
        <div className={classes.questionTitle}>
          <Typography variant="h2" style={{ fontWeight: 600 }}>{title}</Typography>
          <Button className={classes.editButton} variant="outlined" onClick={handleSelect(title)}>
            {done ? 'Editar' : 'Seleccionar'}
            {' '}
            Preguntas
          </Button>
        </div>
        {this.renderAnswers()}
        {groups.length > 0 && this.renderAnswerGroups()}
        <Conditional condition={done}>
          <>
            <Divider />
            <div className={classes.selectedContainer}>
              <Typography variant="subtitle1" color="primary" className={classes.questionsTitle}>Preguntas de indagación:</Typography>
              {this.renderQuestions()}
            </div>

          </>
        </Conditional>
      </>
    )
  }

  render() {
    const { classes, questions, done, groups } = this.props
    const renderCondition = questions.length > 0 || groups.length > 0
    return (
      <div className={classes.container} style={{ background: done ? '#f1f4fa' : 'unset' }}>
        {renderCondition && this.renderQuestion()}
      </div>
    )
  }
}

ActiveDiagnosis.propTypes = {
  answer: propTypes.object,
  questions: propTypes.arrayOf(propTypes.object),
  groups: propTypes.arrayOf(propTypes.object),
  classes: propTypes.object.isRequired,
  title: propTypes.string,
  groupDividerText: propTypes.string,
  done: propTypes.bool,
  answerId: propTypes.string,
  handleSelect: propTypes.func,
  full: propTypes.object
}

ActiveDiagnosis.defaultProps = {
  answer: { subquestions: [] },
  questions: [],
  title: '',
  groupDividerText: 'Respuesta',
  done: false,
  groups: [],
  answerId: undefined,
  handleSelect: undefined,
  full: { questions: [] }
}

export default withStyles(style)(ActiveDiagnosis)
