import React, { Component } from 'react'
import { withStyles, Paper, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import { max } from 'lodash'
import Pendings from '../Handlers/Pendings'
import BarChart from '../../Shared/Charts/BarChart'
import {
  genderOptions,
  statusOptions,
  comunesOptions,
  typeOptions,
  agesOptions,
  profileOptions,
} from './testingData'
import CircleChart from '../../Shared/Charts/CircleChart'
// import LineChart from '../../Shared/Charts/LineChart'
// import RadarChart from '../../Shared/Charts/RadarChart'
import { getAgeData, getCommuneData, getComorbilityData, getDisabilityData, getGenderData, getProfileData, getStateData } from '../../API/Dashboard'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import DownloadButton from './DownloadButton'

const style = () => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridTemplateRows: '30vh 30vh auto auto auto auto 500px',
    padding: 12,
    gridGap: 16,
    gridTemplateAreas: `'u1 u1 u1 u2 u2 u2 u3 u3 u3 u4 u4 u4'
                        'g1 g1 g1 g1 g1 g1 g1 g1 p0 p0 p0 p0'
                        'g2 g2 g2 g2 g3 g3 g3 g3 p0 p0 p0 p0'
                        'g4 g4 g4 g4 g4 g4 g4 g6 g6 g6 g6 g6'
                        'g7 g7 g7 g7 g7 g7 g7 g7 g8 g8 g8 g8'
                        'g11 g11 g11 g11 g11 g11 g11 g10 g10 g10 g10 g10'
                        'g12 g12 g12 g12 g12 g12 g12 g12 g12 g12 g12 g12'
                        `
  }
})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#6A6A6A',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

const colorsSecond = [
  '#ffb33d', // aspirante
  '#38b4a9', // trabajando
  '#d5602c', // postulante
  '#FB8368', // candidato
  '#1f8095', // egresado
  '#27676A', // Egresado con apoyo
  '#6A6A6A' // derivado
]

const colorComorbility = [
  '#6A6A6A',
  '#38b4a9'
]

const ageRanges = [
  [18, 25],
  [26, 35],
  [36, 45],
  [46, 55],
  [56, 65],
]

class Dashboard extends Component {
  constructor() {
    super()
    this.state = {
      sex: [],
      states: null,
      disabilities: null,
      ages: null,
      communes: null,
      profiles: null,
      comorbilities: null,
    }
  }

  async componentDidMount() {
    const sexResponse = await getGenderData()
    this.setState({ sex: sexResponse.data.sexes })

    const stateResponse = await getStateData()
    const statusArray = stateResponse.data
    const status = {}
    statusArray.forEach(element => {
      if (status[element]) {
        status[element] += 1
      } else {
        status[element] = 1
      }
    })
    const orderStates = ['Aspirante', 'Postulante', 'Trabajando', 'Egresado']
    const states = Object.keys(status).sort((a, b) => {
      if (orderStates.indexOf(a) > orderStates.indexOf(b)) return 1
      if (orderStates.indexOf(a) < orderStates.indexOf(b)) return -1
      return 0
    }).map((key, index) => (
      { label: key, value: status[key], color: colorsSecond[index] }))
    this.setState({ states })

    const disabilityResponse = await getDisabilityData()
    const disabilityArray = disabilityResponse.data
    const disability = {}
    disabilityArray.forEach(element => {
      if (disability[element]) {
        disability[element] += 1
      } else {
        disability[element] = 1
      }
    })
    const disabilities = Object.keys(disability)
      .map((key, index) => ({ label: key, value: disability[key], color: colors[index] }))
    this.setState({ disabilities })

    const communeResponse = await getCommuneData()
    const communeArray = communeResponse.data
    const commune = {}
    communeArray.forEach(element => {
      if (commune[element]) {
        commune[element] += 1
      } else {
        commune[element] = 1
      }
    })
    const communes = Object.keys(commune)
      .map(key => ({ label: key, value: commune[key], color: colors[3] }))
    this.setState({ communes })

    const ageResponse = await getAgeData()
    const ageArray = ageResponse.data.ages
    // Add ranges as labels
    const age = {}

    ageRanges.forEach(range => {
      age[`${range}`] = 0
    })

    ageArray.forEach(element => {
      // Find index of range for element

      const suitableRange = ageRanges.find(range => {
        const [low, high] = range
        if (high) {
          const isLEQ = parseInt(element, 10) <= high
          const isGEQ = parseInt(element, 10) >= low
          return isLEQ && isGEQ
        }
        const isGEQ = parseInt(element, 10) >= low
        return isGEQ
      })

      age[`${suitableRange}`] += 1
    })

    const ages = Object.keys(age)
      .map(key => ({ label: key.replace(',', ' - '), value: age[key], color: colorsSecond[5] }))
    this.setState({ ages })

    const comorbilityResponse = await getComorbilityData()
    const comorbility = comorbilityResponse.data
    const comorbilities = Object.keys(comorbility)
      .map((key, index) => ({
        label: key,
        value: comorbility[key],
        color: colorComorbility[index]
      }))
    this.setState({ comorbilities })

    const profileResponse = await getProfileData()
    const profileArray = profileResponse?.data || []
    const profile = {}
    profileArray.forEach(element => {
      if (profile[element]) {
        profile[element] += 1
      } else {
        profile[element] = 1
      }
    })
    const profiles = Object.keys(profile)
      .map((key, index) => ({ label: key, value: profile[key], color: colors[index + 2] }))
    this.setState({ profiles })
  }

  render() {
    const { classes } = this.props
    const { sex, states, disabilities, ages, communes, profiles, comorbilities } = this.state

    const genderData = [
      { label: 'Femenino', value: sex.filter(s => s === 'Femenino').length, color: '#27676a' },
      { label: 'Masculino', value: sex.filter(s => s === 'Masculino').length, color: '#ffb33d' }
    ]

    genderOptions.maxValue = sex.length
    if (states) { statusOptions.maxValue = max(states.map(s => s.value)) + 2 }
    if (ages) { agesOptions.maxValue = max(ages.map(s => s.value)) + 2 }
    if (communes) { comunesOptions.maxValue = max(communes.map(s => s.value)) + 2 }

    let amountDisabilities = 0
    if (disabilities) { amountDisabilities = disabilities.reduce((x, y) => x + y.value, 0) }

    return (
      <div className={classes.gridContainer}>
        <Paper elevation={3} style={{ gridArea: 'u1', padding: 24, position: 'relative' }}>
          <div>
            <Typography variant="subtitle1">
              Nº de usuarios
            </Typography>
          </div>
        </Paper>
        <Paper elevation={3} style={{ gridArea: 'u2', padding: 24, position: 'relative' }}>
          <div>
            <Typography variant="subtitle1">
              Nº de programas
            </Typography>
          </div>
        </Paper>
        <Paper elevation={3} style={{ gridArea: 'u3' }} />
        <Paper elevation={3} style={{ gridArea: 'u4' }} />
        <Paper elevation={3} style={{ gridArea: 'g1' }} />
        <Paper elevation={3} style={{ gridArea: 'g2', position: 'relative' }}>
          <DownloadButton title="generos-cont" />
          <div id="generos-cont" style={{ padding: 24, borderRadius: 8 }}>
            <BarChart
              name="generos"
              title="Género de usuarios"
              data={genderData}
              options={genderOptions}
            />
          </div>
        </Paper>
        <Paper elevation={3} style={{ gridArea: 'g3', position: 'relative' }}>
          {!comorbilities ? <LoaderAnimator loading />
            : (
              <>
                <DownloadButton title="comorbilidad-cont" />
                <div id="comorbilidad-cont" style={{ padding: 24, borderRadius: 8 }}>
                  <CircleChart
                    name="comorbilidad"
                    title="Nº de usuarios con comorbilidad"
                    data={comorbilities}
                    options={genderOptions}
                  />
                </div>
              </>
            )}
        </Paper>
        <Paper elevation={3} style={{ gridArea: 'g4', position: 'relative' }}>
          <DownloadButton title="status-cont" />
          {!states ? <LoaderAnimator loading />
            : (
              <div id="status-cont" style={{ padding: 24, borderRadius: 8 }}>
                <CircleChart
                  name="status"
                  title="Estado de usuarios"
                  data={states}
                  options={statusOptions}
                />
              </div>
            )}
        </Paper>
        <Paper elevation={3} style={{ gridArea: 'g5' }} />
        <Paper elevation={3} style={{ gridArea: 'g6', padding: 24, position: 'relative' }}>
          {!disabilities ? <LoaderAnimator loading />
            : (
              <CircleChart
                name="Tipos"
                title="Tipos de discapacidad intelectual"
                data={disabilities
                  .map(dis => (
                    { ...dis, value: Math.round((dis.value * 100) / amountDisabilities)
                    }))}
                options={typeOptions}
              />
            )}
        </Paper>
        <Paper elevation={3} style={{ gridArea: 'g10', padding: 24, position: 'relative' }}>
          {!profiles ? <LoaderAnimator loading />
            : (
              <CircleChart
                name="Perfiles"
                title="Perfiles de competencias"
                data={profiles}
                options={profileOptions}
              />
            )}
        </Paper>
        <Paper elevation={3} style={{ gridArea: 'g11', padding: 24, position: 'relative' }}>
          {!ages ? <LoaderAnimator loading />
            : (
              <BarChart
                name="edades"
                title="Edades"
                data={ages}
                options={agesOptions}
              />
            )}
        </Paper>
        <Paper elevation={3} style={{ gridArea: 'g12', position: 'relative', height: '350px' }}>
          <DownloadButton title="Comunas-cont" />
          {!communes ? <LoaderAnimator loading />
            : (
              <div id="Comunas-cont" style={{ padding: 24, borderRadius: 8 }}>
                <BarChart
                  name="Comunas"
                  title="Comunas donde viven los usuarios"
                  data={communes}
                  options={comunesOptions}
                />
              </div>
            )}
        </Paper>
        <Pendings
          style={{ gridArea: 'p0' }}
        />
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: propTypes.object.isRequired,
}

export default withStyles(style)(Dashboard)
