import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import MonthSelector from '../Calendar/MonthSelector'
import SearchBar from '../../Shared/Search'

const style = () => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '10fr 1fr 4fr',
    gridGap: 12,
    alignItems: 'center',
  },
})

class HistoryHeader extends Component {
  render() {
    const { classes, currentDate, changeDate, handleToday, searchFunc } = this.props
    return (
      <div className={classes.container}>
        <MonthSelector handleChange={changeDate} currentDate={currentDate} backgroundColor={null} />
        <Button className={classes.button} variant="contained" color="primary" onClick={handleToday}>
          Hoy
        </Button>
        <SearchBar
          fieldStyle={{ marginRight: 12 }}
          searchFunction={searchFunc}
        />
      </div>
    )
  }
}

HistoryHeader.defaultProps = {
  handleToday: () => {},
}

HistoryHeader.propTypes = {
  classes: propTypes.object.isRequired,
  currentDate: propTypes.object.isRequired,
  changeDate: propTypes.func.isRequired,
  searchFunc: propTypes.func.isRequired,
  handleToday: propTypes.func,
}

export default withStyles(style)(HistoryHeader)
