import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Select from 'Shared/Fields/Select'
import autobind from 'Utils/autobind'
import { getPrgramDataResume, getPrograms } from 'API/programs'
import { downloadElement, transformToOptions } from 'Utils/functions'
import { CloudDownload } from '@material-ui/icons'
import AutoevaluationUser from './AutoevaluationUser'

const style = () => ({
  container: {
    padding: 24
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      color: '#3E3E3E',
      marginRight: 6
    },
    marginBottom: 12
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
    '& > *': {
      marginLeft: 6
    }
  },
  // charts: {
  //   display: 'flex',
  //   flexWrap: 'wrap'
  // }
})

class CompetencyChartsDialog extends Component {
  constructor() {
    super()
    this.state = {
      programs: [],
      selected: null,
      name: 'autoevaluation_result_by_program',
      selectedId: null,
      nextPage: false,
      data: {}
    }
    autobind(CompetencyChartsDialog, this)
  }

  async componentDidMount() {
    const response = await getPrograms()
    const { data: { info: programs } } = response
    this.setState({ programs })
  }

  handleChange(event) {
    const { programs } = this.state
    const { target } = event
    const selectedProgram = programs.find(program => program.id === target.value)
    this.setState({ selected: selectedProgram, selectedId: target.value })
  }

  async handleContinue() {
    const { selectedId, name } = this.state
    this.setState({ nextPage: true })
    const body = { name, program_id: selectedId }
    const response = await getPrgramDataResume(body)
    const { data: { info: programData } } = response
    this.setState({ data: programData })
  }

  handleDownloadAll() {
    const { data } = this.state
    const users = data?.by_user || {}
    const userNames = Object.keys(users)

    userNames.forEach(name => {
      const chartId = `${name}chart`
      downloadElement(chartId, `Autoevaluación-${name}`)
    })
  }

  handleClose() {
    const { onClose } = this.props
    this.setState({ nextPage: false, selected: null, selectedId: null })
    onClose()
  }

  renderCharts() {
    const { selected, data } = this.state
    const { classes } = this.props
    const users = data?.by_user || []
    const charts = Object.keys(users).map(userData => (
      <AutoevaluationUser data={{ [userData]: users[userData] }} />
    ))

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <CloudDownload color="default" />
          <Typography variant="h1">
            {`Reporte Autoevaluación: ${selected?.name}`}
          </Typography>
        </div>
        <div className={classes.charts}>
          {charts}
        </div>
        <div className={classes.buttons}>
          <Button onClick={() => this.setState({ nextPage: false })}>
            Volver
          </Button>
          <Button color="primary" variant="contained" onClick={this.handleDownloadAll}>
            Descargar Todo
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { programs, selected, selectedId, nextPage } = this.state
    const { classes, open } = this.props;

    const startView = (
      <div className={classes.container}>
        <div className={classes.header}>
          <CloudDownload color="default" />
          <Typography variant="h1">{selected ? selected.name : 'Seleccionar Programa'}</Typography>
        </div>
        <Select
          complete={{ options: transformToOptions(programs) }}
          onChange={this.handleChange}
          value={selectedId}
        />
        <div className={classes.buttons}>
          <Button onClick={this.handleClose}>
            Volver
          </Button>
          <Button color="primary" variant="contained" disabled={!selectedId} onClick={this.handleContinue}>
            Siguiente
          </Button>
        </div>
      </div>
    )

    const charts = this.renderCharts()

    return (
      <Dialog open={open} onClose={this.handleClose} fullWidth maxWidth={nextPage ? 'md' : 'sm'}>
        {nextPage ? charts : startView}
      </Dialog>
    );
  }
}

CompetencyChartsDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func
}

CompetencyChartsDialog.defaultProps = {
  open: true,
  onClose: undefined
}
export default withStyles(style)(CompetencyChartsDialog)
