import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

class Message extends Component {
  render() {
    return (
      <>
        <Typography variant="subtitle1">Queridas familias, </Typography>
        <Typography variant="subtitle1">
          Los queremos invitar a contestar esta escala que mide cómo perciben las familias
          su calidad de vida familiar.
          <br />
          Sus respuestas entregarán información acerca de todos aquellos aspectos que hacen
          fuerte a su familia, y aquellos que se pueden fortalecer para que su familia tenga
          una mejor calidad de vida.
        </Typography>
        <Typography variant="subtitle1">
          El modelo de calidad de vida familiar incluye las siguientes dimensiones:
          <br />
          - Clima familiar
          <br />
          - Bienestar económico
          <br />
          - Autonomía
          <br />
          - Organización y funcionamiento familiar
          <br />
          - Apoyo familiar a la persona con discapacidad intelectual
          <br />
          - Sobrecarga de la(s) persona(s) que otorga(n) la mayor cantidad de apoyos a la
          persona con discapacidad.
        </Typography>
        <Typography variant="subtitle1">
          Los resultados obtenidos entregarán información sobre como Uds. como familia se
          perciben en cada una de estas dimensiones. A partir de los resultados, les haremos
          llegar un informe con las puntuaciones obtenidas y algunas sugerencias generales
          que puedan ayudarles a funcionar de manera más eficaz con su entorno, mejorando
          así las condiciones de vida de cada uno de los miembros de su familia.
        </Typography>
        <Typography variant="subtitle1">
          Para responder esta escala:
          <br />
          - Incluya a las personas a quienes considera parte de su familia (pueden o no estar
          relacionados por lazos sanguíneos o legales),
          y a quienes apoyan y cuidan el uno/a al otro/a regularmente. No incluya a parientes
          (familia extensa) que se relacionan con su familia solo de vez en cuando.
          <br />
          - Como la vida de las familias experimenta cambios a través del tiempo, por favor,
          mientras llena el cuestionario piense acerca de lo
          que ha sucedido en la vida de su familia durante los últimos 6 meses.
          <br />
          - La escala consta de 39 afirmaciones donde Ud. debe indicar la frecuencia con
          que esta situación ocurre en su familia: Nunca, Raramente,
          A veces, A menudo y Siempre.
          <br />
          - Es importante que al responder sea lo más honesto/a posible y que tenga presente
          que la información entregada es confidencial.
          <br />
          - En caso de que algún ítem le resulte confuso, no dude en consultarnos para poder
          aclarar todas sus dudas.
        </Typography>
        <Typography variant="subtitle1">
          Equipo Salud y Familia
          <br />
          Fundación ConTrabajo
        </Typography>
      </>
    );
  }
}

export default Message
