import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import Chart from 'chart.js'

const style = () => ({
  container: {
    padding: 24
  }
})

const colors = [
  '#1F8095',
  '#D5602C',
  '#FFB33D',
  '#38B4A9',
  '#FB8368',
  '#3E3E3E',
  '#C37D2B',
  '#27676A',
  '#98B3FA',
  '#FFE54C'
]

class ComparisonChart extends Component {
  componentDidMount() {
    const { data } = this.props
    const chartElement = document.getElementById('comparison')
    const myChartRef = chartElement.getContext('2d');
    chartElement.maxHeight = '200px'
    const labels = data.map((el, index) => `Autoevaluación N°${index + 1}`)

    this.chart = new Chart(myChartRef, {
      type: 'line',
      data: {
        // Bring in data
        labels,
        datasets: [
          {
            data,
            backgroundColor: colors,
            radius: 8,
            fill: false,
            borderColor: '#606060',
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        hover: {
          mode: null
        },
        tooltip: {
          enabled: false
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            scaleLabel: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
            },
            offset: true
          }],
          yAxes: [{
            ticks: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
              min: 1,
              max: 7,
              stepSize: 1,
              callback: value => `${value}.0 `
            },
            gridLines: {
              display: true,
              drawBorder: false
            },
            scaleLabel: {
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'bold',
              display: true,
            },
            offset: true,
          }]
        }
      }
    })
  }

  generateDatasets() {
    const { data } = this.props
    const labels = ['12', '2']
    return [data, labels]
  }

  render() {
    const { classes } = this.props;
    this.generateDatasets()
    return (
      <div className={classes.container}>
        <canvas style={{ height: 250, maxHeight: 250 }} id="comparison" ref={this.chartRef} />
      </div>
    );
  }
}

ComparisonChart.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
}

ComparisonChart.defaultProps = {

}
export default withStyles(style)(ComparisonChart)
