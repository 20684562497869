import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { AddCircle, Delete, Edit, KeyboardArrowRight } from '@material-ui/icons'
import moment from 'moment'
import { getProgramsAction } from '../../Actions/ProgramAction'
import autobind from '../../Utils/autobind'
import ButtonsWithIcon from '../../Shared/Buttons/ButtonsWithIcon'
import GeneralTable from '../../Shared/GeneralTable'
import { createAuthorizationAction } from '../../Actions/Authorizations'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import ProgramDialog from './ProgramDialog'
import SuccessDialog from './SuccessDialog'
import { getEnterprisesAction } from '../../Actions/EnterpriseAction'
import { getCommunesAction } from '../../Actions/Communes'

const style = theme => ({
  bread: {
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    '& > *': {
      margin: '0 12px'
    },
    '& > *:last-child': {
      color: theme.palette.primary.main
    }
  },
  container: {
    padding: 12,
    margin: '24px 12px'
  },
  leftButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '24px 0'
  }
})

function calculateDuration(value, complete) {
  const duration = moment(complete.end_month).diff(moment(complete.start_month), 'months')
  return duration > 1 ? `${duration} Meses` : `${duration} Mes`
}

function formatTypes(value) {
  const types = ['ConFuturo', 'ConInclusión']
  return types[value]
}

const entityFormat = entities => value => entities?.find(e => e.id === value)?.name

class Programs extends Component {
  constructor() {
    super()
    this.state = {
      openDialog: false,
      loading: false,
      selected: null,
      option: null,
      openSuccess: false
    }
    autobind(Programs, this)
  }

  componentDidMount() {
    const { getPrograms, getCommunes } = this.props
    this.setState({ loading: true })
    getCommunes()
    getPrograms().then(() => this.setState({ loading: false }))
  }

  handleToggleDialog(option) {
    return () => {
      const { openDialog } = this.state
      this.setState({ openDialog: !openDialog, option, selected: null })
    }
  }

  handleToggleSuccess() {
    const { openSuccess } = this.state
    this.setState({ openSuccess: !openSuccess })
  }

  handleCloseDialog() {
    this.setState({ openDialog: false })
  }

  handleCreate(params) {
    const { createAuthorization } = this.props
    const authBody = {
      extra_info: params,
      type_id: 1,
      text: `Creación de programa ${params.name}`
    }
    createAuthorization(authBody)
    this.handleCloseDialog()
    this.setState({ openSuccess: true })
  }

  handleOpenDialog(option) {
    return program => () => {
      this.setState({ selected: program, option, openDialog: true })
    }
  }

  handleEdit(params) {
    const { createAuthorization } = this.props
    const { selected } = this.state
    const body = { ...params, id: selected.id }

    const authBody = {
      extra_info: body,
      type_id: 3,
      text: `Edición de programa ${params.name}`
    }
    createAuthorization(authBody)

    this.handleCloseDialog()
    this.setState({ selected: null, openSuccess: true })
  }

  handleDelete() {
    const { selected } = this.state
    const { createAuthorization } = this.props
    const body = { id: selected.id }

    const authBody = {
      extra_info: body,
      type_id: 4,
      text: `Eliminación de programa ${selected.name}`
    }

    createAuthorization(authBody)
    this.handleCloseDialog()
    this.setState({ selected: null, openSuccess: true })
  }

  getSubmit() {
    const { option } = this.state
    if (option === 'Crear') return this.handleCreate
    if (option === 'Editar') return this.handleEdit
    return this.handleDelete
  }

  render() {
    const { classes, programs, enterprises, communes } = this.props
    const { loading, openDialog, selected, option, openSuccess } = this.state

    const allPrograms = programs.all || []
    const allEnterprises = enterprises.all || []
    const allCommunes = communes.all || []

    const tableInfo = [
      { name: 'Nombre', label: 'name' },
      { name: 'Empresa', label: 'enterprise_id', format: entityFormat(allEnterprises) },
      { name: 'Duración', label: 'start_month', format: calculateDuration },
      { name: 'Comuna', label: 'commune_id', format: entityFormat(allCommunes) },
      { name: 'Tipo de programa', label: 'program_type', format: formatTypes }
    ]

    const actions = [
      { name: 'Editar programa', icon: Edit, action: this.handleOpenDialog('Editar') },
      { name: 'Eliminar programa', icon: Delete, action: this.handleOpenDialog('Eliminar') }
    ]

    return (
      <div className={classes.container}>
        <Paper className={classes.bread}>
          <Typography variant="h1">Configuración general</Typography>
          <KeyboardArrowRight />
          <Typography variant="h1">Programas de intermediación laboral</Typography>
        </Paper>
        <div className={classes.leftButton}>
          <ButtonsWithIcon color="secondary" Icon={AddCircle} onClick={this.handleToggleDialog('Crear')}>Agregar programa</ButtonsWithIcon>
        </div>
        {loading
          ? <LoaderAnimator loading />
          : (
            <GeneralTable
              info={tableInfo}
              data={allPrograms}
              actions={actions}
            />
          )}

        <ProgramDialog
          onSubmit={this.getSubmit()}
          open={openDialog}
          onClose={this.handleCloseDialog}
          selected={selected}
          option={option}
          communes={communes}
        />
        <SuccessDialog open={openSuccess} onClose={this.handleToggleSuccess} />
      </div>
    );
  }
}

Programs.propTypes = {
  classes: propTypes.object.isRequired,
  communes: propTypes.object.isRequired,
  getPrograms: propTypes.func.isRequired,
  getCommunes: propTypes.func.isRequired,
  createAuthorization: propTypes.func.isRequired,
  programs: propTypes.arrayOf(propTypes.object).isRequired,
  enterprises: propTypes.arrayOf(propTypes.object).isRequired,
}

Programs.defaultProps = {

}

const mapStateToProps = state => ({
  programs: state.programs,
  enterprises: state.enterprises,
  communes: state.communes
})

const mapDispatchToProps = dispatch => ({
  getPrograms: () => dispatch(getProgramsAction()),
  getEnterprises: () => dispatch(getEnterprisesAction()),
  createAuthorization: body => dispatch(createAuthorizationAction(body)),
  getCommunes: () => dispatch(getCommunesAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Programs))
