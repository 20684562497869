import stateMaker from '../Utils/reducers'

const enterpriseReducer = (state = { all: [] }, action) => {
  switch (action.type) {
    case 'GET_ENTERPRISES': {
      return stateMaker(state, 'all', 'fetch', action)
    }
    case 'EDIT_ENTERPRISE': {
      return stateMaker(state, 'all', 'fetch', action)
    }
    case 'CREATE_ENTERPRISE': {
      return stateMaker(state, 'all', 'fetch', action)
    }
    default: {
      return state
    }
  }
}

export default enterpriseReducer
