import React, { Component } from 'react'
import propTypes from 'prop-types'
import './animation.css'

function checkVisible() {
  const element = document.getElementById('alwaysOnTop')
  const container = document.getElementById('mainContent')
  if (element) {
    const position = element.getBoundingClientRect();
    if (position.top <= 0 && container.scrollTop > 48) {
      const classes = Array.from(element.classList.values())
      if (classes.indexOf('not-visible') === -1) {
        element.classList.add('not-visible')
        element.classList.remove('go-home')
      }
    }
    if (container.scrollTop <= 48) {
      const classes = Array.from(element.classList.values())
      if (classes.indexOf('go-home') === -1) {
        element.classList.remove('not-visible')
        element.classList.add('go-home')
      }
    }
  }
}

class AlwaysVisible extends Component {
  componentDidMount() {
    document.getElementById('mainContent').addEventListener('scroll', checkVisible, false)
  }

  render() {
    const { children } = this.props
    return (
      <div id="alwaysOnTop" className="always">
        {children}
      </div>
    )
  }
}

AlwaysVisible.propTypes = {
  children: propTypes.node.isRequired,
}

AlwaysVisible.defaultProps = {

}

export default AlwaysVisible
