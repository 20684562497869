import stateMaker from '../Utils/reducers'

const competencyEvaluationsReducer = (state = {
  evaluations: [],
  selected_form: {},
  result: {},
  allResults: {}
}, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'GET_COMPETENCY_EVALUATIONS':
      return stateMaker(newState, 'evaluations', 'fetch', action)
    case 'GET_COMPETENCY_EVALUATION':
      return stateMaker(newState, 'selected_form', 'fetch', action)
    case 'CREATE_COMPETENCY_EVALUATION':
      return stateMaker(newState, 'selected_form', 'fetch', action)
    case 'GET_COMPETENCY_EVALUATION_RESULT': {
      newState.result = action.payload.data.info
      return newState
    }
    case 'GET_ALL_COMPETENCIES_RESULT': {
      return stateMaker(newState, 'allResults', 'fetch', action)
    }
    default:
      return state
  }
}

export default competencyEvaluationsReducer
