import React, { Component } from 'react'
import { Checkbox, Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = theme => ({
  title: {
    '& > h2': {
      fontWeight: 600,
      fontSize: 16
    },
    marginTop: 24
  },
  question: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '3px 0'
  },
  checkQuestion: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '3px 0',
    '& > *': {
      marginRight: 12
    }
  },
  questionText: {
    fontWeight: 600,
    color: '#1a1a1a'
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    fontSize: 18,
    margin: '12px 0 6px 0'
  },
  questionTitle: {
    padding: '3px 6px',
    color: '#1a1a1a',
    width: '100%',
    fontSize: 15,
    fontWeight: 600,
  },
  subquestions: {
    paddingLeft: 24
  },
  answer: {
    flexBasis: '50%'
  },
  divider: {
    background: theme.palette.primary.main
  },
  big: {
    height: 2
  }
})

function getGroupAnswer(groupQuestion, question, subgroupId, subIndex) {
  const groupAnswers = question.answers.body.groups
  if (groupAnswers) {
    const answerId = `${Object.keys(groupAnswers)[0].split('#')[0]}#${subgroupId}#${subIndex}`
    const answer = groupAnswers[answerId] ? groupAnswers[answerId].answer : null
    const subquestions = groupAnswers[answerId] ? groupAnswers[answerId].subquestions : null
    return [answer, subquestions]
  }
  return [false, false]
}

class HealthCriterium extends Component {
  renderGroupSubquestions(subquestions, answers) {
    const { classes } = this.props
    return subquestions.map((subquestion, index) => {
      const answerId = Object.keys(answers).find(key => key.split('&')[1] === index.toString())
      const answer = answers[answerId]
      if (answer) {
        switch (subquestion.type) {
          case 'text': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography variant="subtitle2">{answer}</Typography>
                </div>
              </div>
            )
          }
          case 'select': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography variant="subtitle2">{subquestion.options[answer]}</Typography>
                </div>
              </div>
            )
          }
          case 'radio': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography variant="subtitle2">{subquestion.options[answer]}</Typography>
                </div>
              </div>
            )
          }
          default: {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography variant="subtitle2">{answer}</Typography>
                </div>
              </div>
            )
          }
        }
      }
      return null
    })
  }

  renderSingleSubgroupQuestion(question, answer, subquestionsAnswers) {
    const { classes } = this.props
    const { subquestions } = question
    const hasSubquestions = subquestions && subquestions.length > 0 && subquestionsAnswers
    switch (question.type) {
      case 'text': {
        return (
          <div className={classes.question}>
            <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
            <div className={classes.answer}>
              <Typography variant="subtitle2">{answer}</Typography>
            </div>
          </div>
        )
      }
      case 'select': {
        return (
          <>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
              <div className={classes.answer}>
                <Typography variant="subtitle2">{question.options[answer]}</Typography>
              </div>
            </div>
            <div className={classes.subquestions}>
              {hasSubquestions && this.renderGroupSubquestions(subquestions, subquestionsAnswers)}
            </div>
          </>
        )
      }
      case 'radio': {
        return (
          <>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
              <div className={classes.answer}>
                <Typography variant="subtitle2">{question.options[answer]}</Typography>
              </div>
            </div>
            <div className={classes.subquestions}>
              {hasSubquestions && this.renderGroupSubquestions(subquestions, subquestionsAnswers)}
            </div>
          </>
        )
      }
      case 'date-interval': {
        const splitAnswer = answer.split('&')
        return (
          <div className={classes.subquestions}>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionText}>{question.start_label}</Typography>
              <div className={classes.answer}>
                <Typography variant="subtitle2">{splitAnswer[1]}</Typography>
              </div>
            </div>
            {question.has_box
          && (
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionText}>{question.has_box}</Typography>
              <div className={classes.answer}>
                <Typography variant="subtitle2">{splitAnswer[0] === '0' ? 'No' : 'Si'}</Typography>
              </div>
            </div>
          )}
            {splitAnswer[0] === '0' && (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{question.end_label}</Typography>
                <div className={classes.answer}>
                  <Typography variant="subtitle2">{splitAnswer[2]}</Typography>
                </div>
              </div>
            )}
          </div>
        )
      }
      default: {
        return (
          <div className={classes.question}>
            <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
            <div className={classes.answer}>
              <Typography variant="subtitle2">{answer}</Typography>
            </div>
          </div>
        )
      }
    }
  }

  renderSubgroups(question) {
    const { classes } = this.props
    const groups = question.answers.subquestion_groups || []
    return groups.map((group, groupIndex) => {
      const { id } = group
      const questions = group.question
      const renderedQuestions = questions.map((groupQuestion, index) => {
        const [answer, subquestions] = getGroupAnswer(groupQuestion, question, id, index)
        if (answer) {
          return this.renderSingleSubgroupQuestion(groupQuestion, answer, subquestions)
        }
        return null
      })
      return (
        <React.Fragment key={id}>
          {renderedQuestions}
          {groupIndex < (groups.length - 1) && <Divider className={classes.divider} />}
        </React.Fragment>
      )
    })
  }

  renderSubquestions(question) {
    const { classes } = this.props
    const { subquestions } = question
    const subquestionAnswers = question?.answers?.body?.subquestions || {}
    return subquestions.map((subquestion, index) => {
      const answerId = Object.keys(subquestionAnswers).find(key => key.split('&')[1] === index.toString())
      const answer = subquestionAnswers[answerId]
      if (answer) {
        switch (subquestion.type) {
          case 'text': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography variant="subtitle2">{answer}</Typography>
                </div>
              </div>
            )
          }
          case 'select': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography variant="subtitle2">{subquestion.options[answer]}</Typography>
                </div>
              </div>
            )
          }
          case 'radio': {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography variant="subtitle2">{subquestion.options[answer]}</Typography>
                </div>
              </div>
            )
          }
          case 'checkbox': {
            const answers = answer.split('&')
            return (
              <>
                {answers.map(checkAnswer => (
                  <div className={classes.checkQuestion}>
                    <Checkbox checked color="primary" />
                    <div className={classes.answer}>
                      <Typography variant="subtitle2">{subquestion.boxes[checkAnswer]}</Typography>
                    </div>
                  </div>
                ))}
              </>
            )
          }
          case 'date-interval': {
            const splitAnswer = answer.split('&')
            return (
              <div className={classes.subquestions}>
                <div className={classes.question}>
                  <Typography variant="subtitle2" className={classes.questionText}>{subquestion.start_label}</Typography>
                  <div className={classes.answer}>
                    <Typography variant="subtitle2">{splitAnswer[1]}</Typography>
                  </div>
                </div>
                {question.has_box
              && (
                <div className={classes.question}>
                  <Typography variant="subtitle2" className={classes.questionText}>{subquestion.has_box}</Typography>
                  <div className={classes.answer}>
                    <Typography variant="subtitle2">{splitAnswer[0] === '0' ? 'No' : 'Si'}</Typography>
                  </div>
                </div>
              )}
                {splitAnswer[0] === '0' && (
                  <div className={classes.question}>
                    <Typography variant="subtitle2" className={classes.questionText}>{subquestion.end_label}</Typography>
                    <div className={classes.answer}>
                      <Typography variant="subtitle2">{splitAnswer[2]}</Typography>
                    </div>
                  </div>
                )}
              </div>
            )
          }
          default: {
            return (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionText}>{subquestion.text}</Typography>
                <div className={classes.answer}>
                  <Typography variant="subtitle2">{answer}</Typography>
                </div>
              </div>
            )
          }
        }
      } else {
        return null
      }
    })
  }

  renderSingleQuestion(question, postType) {
    const { classes } = this.props
    const wasTitle = postType === 'title'
    switch (question.type) {
      case 'title': {
        return (
          <>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.sectionTitle}>{question.text}</Typography>
            </div>
            {
              wasTitle
            && (
              <div className={classes.question}>
                <Typography variant="subtitle2" className={classes.questionTitle}>No hay respuesta...</Typography>
              </div>
            )
            }
          </>
        )
      }
      case 'radio': {
        return (
          <>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionTitle}>{question.text}</Typography>
            </div>
            {this.renderSubgroups(question)}
            {question.subquestions && this.renderSubquestions(question)}
          </>
        )
      }
      default: {
        return (
          <div className={classes.question}>
            <Typography variant="subtitle1" className={classes.questionText}>{question.text}</Typography>
            <div className={classes.answer}>
              <Typography variant="subtitle1">{question.answers.body ? question.answers.body.answer : ''}</Typography>
            </div>
          </div>
        )
      }
    }
  }

  renderQuestions(questions) {
    return questions.map((question, index) => this.renderSingleQuestion(question, index < questions.length - 1 ? questions[index + 1].type : 'text'))
  }

  render() {
    const { classes, form, title } = this.props
    const questions = form.filter(question => (question.type === 'title' && question.reset_enumeration) || (question.type === 'radio' && question.answers.body && question.answers.body.answer === '0'))
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h2">{title}</Typography>
          <Divider className={`${classes.divider} ${classes.big}`} />
        </div>
        {this.renderQuestions(questions)}
      </div>
    );
  }
}

HealthCriterium.propTypes = {
  classes: propTypes.object.isRequired,
  form: propTypes.object.isRequired,
  title: propTypes.string
}

HealthCriterium.defaultProps = {
  title: 'Titulo'
}

export default withStyles(style)(HealthCriterium)
