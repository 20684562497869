/* eslint-disable import/no-cycle */
import React, { Component } from 'react'
import { withTheme, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import MuiCheckbox from '@material-ui/core/Checkbox'
import { renderSubquestion } from '../../Utils/FieldRenderer'
import Comment from './Comment'

const AnsweredCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#48A4B0',
    },
  },
  checked: {},
})(props => <MuiCheckbox {...props} />);

class Checkbox extends Component {
  handleValue(index) {
    const { onChange, complete, value } = this.props
    const list = value ? value.toString().split('&') : []
    if (list.indexOf(index.toString()) === -1) {
      list.push(index)
    } else {
      list.splice(list.indexOf(index.toString()), 1)
    }
    const result = list.join('&')
    return () => {
      const event = { target: { name: complete.name, value: result } }
      onChange(event)
    }
  }

  renderBoxes() {
    const { complete, value, disabled, answered } = this.props
    const list = value ? value.toString().split('&') : []
    return complete && complete.boxes && complete.boxes.map((box, index) => {
      const checked = list.indexOf(index.toString()) !== -1
      const CheckboxComp = answered ? AnsweredCheckbox : MuiCheckbox
      return (
        <div style={{ display: 'flex', alignItems: 'center' }} key={box}>
          <CheckboxComp
            color="primary"
            onClick={this.handleValue(index)}
            checked={checked}
            disabled={disabled}
          />
          <Typography variant="body1">{box}</Typography>
        </div>
      )
    })
  }

  render() {
    const {
      complete,
      onSubchange,
      subquestionsAnswers,
      value,
      comment,
      onComment,
      disabled,
      highlighted,
      hideTitle,
      answered } = this.props
    const { subquestions } = complete
    const valueList = value ? value.split('&') : []
    let shouldShow = false
    if (subquestions) {
      const intersection = valueList.filter(element => complete.expected_value.toString().split('&').includes(element))
      shouldShow = subquestions && intersection.length > 0
    }
    return (
      <>
        <div style={{ gridArea: complete && `area-${complete.id}` }} className={highlighted ? 'highlighted' : ''}>
          {(!answered && !hideTitle) && <Typography style={{ fontWeight: complete.bold ? 600 : 400, color: complete.bold ? '#3E3E3E' : '#707070' }}>{complete.text}</Typography>}
          {answered && <Typography style={{ fontWeight: 900, fontSize: 14, color: 'black' }} display="inline">{complete.text}</Typography>}
          <div style={{ display: 'grid', gridTemplateColumns: complete.columns || '1fr 1fr' }}>
            {this.renderBoxes()}
          </div>
          {shouldShow && renderSubquestion(complete,
            onSubchange,
            subquestionsAnswers,
            disabled,
            answered)}
        </div>
        {complete.has_comment && (
          <Comment
            value={comment}
            onChange={onComment}
            name={complete.name}
            area={`comment-${complete.id}`}
            commentLabel={complete.comment_label}
            disabled={disabled}
          />
        )}
      </>
    )
  }
}

Checkbox.propTypes = {
  complete: propTypes.object.isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  comment: propTypes.string,
  onComment: propTypes.func,
  onSubchange: propTypes.func,
  subquestionsAnswers: propTypes.object,
  disabled: propTypes.bool,
  highlighted: propTypes.bool,
  answered: propTypes.bool,
  hideTitle: propTypes.bool
}

Checkbox.defaultProps = {
  value: '',
  comment: '',
  onComment: undefined,
  onSubchange: undefined,
  subquestionsAnswers: {},
  disabled: false,
  highlighted: false,
  answered: false,
  hideTitle: false,
}

export default withTheme(Checkbox)
