import React, { Component } from 'react'
import propTypes from 'prop-types'
import { withStyles, Typography, Divider } from '@material-ui/core'
import { connect } from 'react-redux'
import autobind from '../../../../Utils/autobind'
import IndividualInterviewPOI from '../POI/IndividualInterviewPOI'
import InterviewAVD from '../AVD/InterviewAVD'
import InterviewHealthTab from '../HealthTab/InterviewHealthTab'
import InterviewGencat from '../Gencat/InterviewGencat'
import SetViewContext from '../../../Contenders/Profile/SetViewProvider'

const familiarSectionsText = ['Relaciones familiares', 'Experiencia educacional', 'Características personales, intereses y proyección', 'Perfil del candidato']
const individualSectionsText = ['Relaciones familiares', 'Experiencia educacional y laboral', 'Características personales, intereses y proyección']

const style = theme => ({
  question: {
    paddingLeft: 12,
    margin: '6px 6px 6px 0'
  },
  container: {
    marginBottom: 36
  },
  title: {
    marginBottom: 12,
    fontSize: 15
  },
  questionContainer: {
    margin: '6px 0 12px 0'
  },
  link: {
    marginBottom: 36,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    transition: 'all 0.2s linear',
    '&:hover': {
      color: theme.palette.grey.dark,
      transform: 'scale(1.01)'
    },
    textDecoration: 'underline'
  }
})

class EditableQuestionGroup extends Component {
  constructor(props) {
    super(props)
    autobind(EditableQuestionGroup, this)
  }

  handleChangeView(meta) {
    return () => {
      // Here we choose where to redirect based on meta.route
      const { setView } = this.context
      const { interviewType } = this.props
      let newView
      let name = 'Selección Entrevista'
      switch (meta.route) {
        case 'gathering': {
          name = 'Selección Pauta Observación Inicial'
          newView = (
            <IndividualInterviewPOI
              event={meta}
              title={interviewType}
            />
          )
          break
        }
        case 'avd': {
          name = 'Selección Actividades de Vida Diaria'
          newView = (
            <InterviewAVD
              event={meta}
              title={interviewType}
            />
          )
          break
        }
        case 'ficha-salud': {
          name = 'Selección Ficha de Salud'
          newView = (
            <InterviewHealthTab
              event={meta}
              title={interviewType}
            />
          )
          break
        }
        case 'gencat': {
          name = 'Selección Evaluación de Calidad de Vida'
          newView = (
            <InterviewGencat
              event={meta}
              title={interviewType}
            />
          )
          break
        }
        default: {
          name = 'Selección Pauta Observación Inicial'
          newView = (
            <IndividualInterviewPOI
              event={meta}
              title={interviewType}
            />
          )
          break
        }
      }
      setView(newView, name)
    }
  }

  renderQuestions() {
    const { groups, classes, interviewType } = this.props
    const sectionsText = interviewType === 'Individual' ? individualSectionsText : familiarSectionsText
    return Object.entries(groups).map(([group, info], index) => (
      <div key={`group${index + 1}`}>
        <Typography variant="subtitle1" className={classes.title}>{group}</Typography>
        <div className={classes.questionContainer}>
          {info.questions.map((question, i) => {
            const sectionIndex = question.section_index
            const generatedFrom = question.generated_from
            const sectionAssigned = sectionIndex === -1 ? '(No asignada) ' : `(Asignada a ${sectionsText[sectionIndex]}) `
            const generatedFromName = generatedFrom ? `(Desde ${generatedFrom})` : ''
            return (
              <div key={`group${index + 1}${i + 1}`}>
                <Typography variant="h2" className={classes.question} display="inline" key={question}>{`${i + 1}. ${question.text}`}</Typography>
                <Typography display="inline">
                  {sectionAssigned}
                </Typography>
                <Typography display="inline" style={{ fontWeight: 'bold' }}>
                  {generatedFromName}
                </Typography>
              </div>
            )
          })}
        </div>
        <Typography
          variant="h2"
          className={classes.link}
          role="button"
          onClick={this.handleChangeView(info.meta)}
        >
          + Agregar preguntas de indagación

        </Typography>
      </div>
    ))
  }

  render() {
    const { classes, title } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h2">{title}</Typography>
        <Divider />
        {this.renderQuestions()}
      </div>
    )
  }
}

EditableQuestionGroup.contextType = SetViewContext

EditableQuestionGroup.propTypes = {
  classes: propTypes.object.isRequired,
  groups: propTypes.object,
  title: propTypes.string,
  interviewType: propTypes.string,
}

EditableQuestionGroup.defaultProps = {
  groups: {},
  title: 'Cargando...',
  interviewType: 'Individual'
}

const mapStateToProps = state => ({
  contender: state.contenders.selected
})

export default connect(mapStateToProps)(withStyles(style)(EditableQuestionGroup))
