import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import ReportTitles from '../ReportTitles'
import RadarChart from '../../../../../Shared/Charts/RadarChart'
import LQI from '../../../../Results/Individuals/Gencat/LQI'
import Percentile from '../../../../Results/Individuals/Gencat/Percentile'

const style = () => ({
  cards: {
    display: 'grid',
    gridTemplateAreas: "'chart c1 c2' 'chart obs obs'",
    gridGap: 12,
    gridTemplateColumns: '1fr 1fr 1fr'
  },
  observations: {
    padding: 12,
    '& > *': {
      margin: '3px 0'
    }
  },
  chart: {
    width: '70%',
    margin: 'auto'
  },
  card: {
    padding: 12
  },
  container: {
    marginBottom: 48
  }
})

const radarOptions = {
  color: '#1F8095BB',
  maxTicksLimit: 6,
  height: '300px'
}

class Gencat extends Component {
  render() {
    const { classes, contenders } = this.props
    const results = contenders.profileResults
    const { gencat_complete: gencat, gencat: dataForChart, comments } = results
    if (!gencat) return null
    if (!dataForChart) return null
    const firstGencat = gencat.results[0]
    return (
      <div className={classes.container}>
        <ReportTitles title="Escala de Calidad de Vida" />
        <div className={classes.cards}>
          <Paper style={{ gridArea: 'chart' }} className={classes.card}>
            <Typography variant="subtitle1">Gencat</Typography>
            <div className={classes.chart}>
              <RadarChart name="gencats" title="" options={radarOptions} data={dataForChart} />
            </div>
          </Paper>
          <Paper style={{ gridArea: 'c1' }}>
            <LQI quality={[firstGencat.quality_index || 0]} />
          </Paper>
          <Paper style={{ gridArea: 'c2' }}>
            <Percentile data={[firstGencat.percentile || 0]} />
          </Paper>
          <div style={{ gridArea: 'obs' }} className={classes.observation}>
            <Typography variant="subtitle1">Observaciones de Escala de Calidad de Vida</Typography>
            <Typography variant="body1">
              {comments?.gencat?.comment || 'No hay comentarios'}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

Gencat.propTypes = {
  classes: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
}

Gencat.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(Gencat))
