import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import DownloadableSection from 'Shared/DownloadableSection'
import RecurrentContext from '../RecurrentEvaluations/RecurrentContext'
import { getEvaluationGroup, getFormEvaluation } from '../../API/Recurrent'
import calculateAverageAndResults from './utils'
import LineChartUserMultiple from './Charts/LineChartUserMultiple'
import WebChartMultiple from './Charts/WebChartMultiple'
import CriteriaBarChartMultiple from './Charts/CriteriaBarChartMultiple'
import GlobalComment from '../../Shared/GlobalComment'
import LoaderAnimator from '../../Shared/LoaderAnimator'

const style = () => ({
  headerCharts: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '&>*': {
      width: '48%'
    },
    margin: '36px 0'
  }
})

class EDPSMultipleResults extends Component {
  constructor() {
    super()
    this.state = {
      finalData: [],
      loading: false,
    }
  }

  async componentDidMount() {
    const calculatedGroups = []
    const { getEvaluations, event, type } = this.context
    this.setState({ loading: true })
    const response = await getEvaluations(event)
    if (response.data.status !== 'success') return null
    const groups = response.data.info
    const doneGroups = groups.filter(group => group.status === 'check')
    const evaluationGroups = await this.groupEvaluations(doneGroups)
    evaluationGroups.forEach(group => {
      const groupData = calculateAverageAndResults(group, type)
      calculatedGroups.push(groupData)
    })
    return this.setState({ finalData: calculatedGroups, loading: false })
  }

  async groupEvaluations(doneGroups) {
    const { event } = this.context
    const groupPromises = doneGroups.map(group => {
      // Get the group
      const groupBody = { form_id: event.form_id, evaluation_group_id: group.id }
      return getEvaluationGroup(groupBody)
    })
    const groupsResponses = await Promise.all(groupPromises)

    // Separate evaluations
    const groupedEvaluationsData = []
    groupsResponses.forEach(response => groupedEvaluationsData.push(response.data.info.evaluations))

    // Get evaluations results and group
    const evaluationGroupPromises = groupedEvaluationsData.map(evaluationGroup => {
      const evaluationResponses = evaluationGroup.filter(evaluation => evaluation.status === 'check').map(evaluation => {
        const evaluationBody = { form_id: event.form_id, evaluation_id: evaluation.id }
        return getFormEvaluation(evaluationBody)
      })
      return Promise.all(evaluationResponses)
    })

    const evaluationsResponses = await Promise.all(evaluationGroupPromises)
    const groupedEvaluationsResults = evaluationsResponses
      .map(evaluationGroup => evaluationGroup
        .map(evaluationResponse => evaluationResponse.data.info))

    return groupedEvaluationsResults
  }

  render() {
    const { classes } = this.props;
    const { event, contender } = this.context
    const { finalData = [], loading } = this.state
    return (
      <div className={classes.container}>
        <GlobalComment event={event} />
        <LoaderAnimator loading={loading} />
        <>
          <DownloadableSection id="head-section" name={`Progreso EDPS -${contender?.name}`} margin>
            <div className={classes.headerCharts}>
              <div>
                <LineChartUserMultiple data={finalData} />
              </div>
              <div>
                <WebChartMultiple data={finalData} />
              </div>
            </div>
          </DownloadableSection>
          <DownloadableSection id="down-chart" name={`Dimensiones EDPS - ${contender?.name}`} margin>
            <div style={{ margin: '64px 0' }}>
              <CriteriaBarChartMultiple data={finalData} />
            </div>
          </DownloadableSection>
        </>
      </div>
    );
  }
}
EDPSMultipleResults.contextType = RecurrentContext

EDPSMultipleResults.propTypes = {
  classes: propTypes.object.isRequired
}

EDPSMultipleResults.defaultProps = {

}
export default withStyles(style)(EDPSMultipleResults)
