import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import autobind from '../../Utils/autobind'
import EvaluationParticipants from './EvaluationParticipants'
import MainForm from './MainForm'
import FunctionalContext from './NewFormContext'
import { getFunctionalEvaluationAction, sendSkillAnswerAction } from '../../Actions/FunctionalEvaluaction'
import { advanceParticipantsToCandidate } from '../../API/Events'
import { markFormAsFinishedAction } from '../../Actions/Events'
import LoaderAnimator from '../../Shared/LoaderAnimator'

const style = () => ({
  container: {
    display: 'flex',
    padding: 24,
    paddingBottom: 0
  }
})

class FunctionalEvaluation extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      comments: {},
      selectedParticipant: '',
      selectedTab: 0,
      questionIndex: 1,
      loading: true
    }

    autobind(FunctionalEvaluation, this)
  }

  componentDidMount() {
    const { getEvaluation, match } = this.props
    const body = {
      id: match.params.id,
      form_id: match.params.formId
    }

    getEvaluation(body).then(response => {
      const participants = Object.keys(response.payload.data.info)
      this.setState({ selectedParticipant: participants[0] })
      this.setupParams()
    })
  }

  componentWillUnmount() {
    this.handleSend(false, false)
  }

  handleSelectParticipant(selectedParticipant) {
    return () => {
      this.setState({ selectedParticipant })
      this.handleSend(false, false)
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
    this.handleSend(false, false)
  }

  handleComment(event) {
    const { target } = event
    const { comments } = this.state
    comments[target.name] = target.value
    this.setState({ comments })
  }

  handleSelectTab(selectedTab) {
    return () => {
      this.setState({ selectedTab })
      const element = document.getElementById('form-container')
      element.scrollTo(0, 0)
      this.handleSend(false)
    }
  }

  handleSend(isFinal = true, showSnack = true) {
    const { params, comments } = this.state
    const { match, sendSkillAnswer, checkForm, events, history, enqueueSnackbar } = this.props
    const answers = { ...params }
    const answersComments = { ...comments }
    const filteredAnswers = Object.entries(answers).filter(([id]) => !id.includes('noanswer'))
    if (filteredAnswers.length > 0) {
      const body = filteredAnswers.map(key => ({
        id: key[0],
        body: { answer: answers[key[0]] },
        comment: answersComments[key[0]] || '',
      }))
      const { id } = match.params
      sendSkillAnswer({ answers: body }, id).then(() => {
        if (showSnack) {
          enqueueSnackbar('Respuestas Guardadas', { variant: 'success' })
        }
      })
    }
    if (isFinal) {
      const { id, formId } = match.params
      events.selected.participants.forEach(participant => {
        const checkBody = {
          form_id: formId,
          user_id: participant.id,
          event_id: id,
          status: 'check'
        }
        checkForm(checkBody)
      })
      advanceParticipantsToCandidate(id)
      history.push(`/events/${id}/functional/${formId}/results`)
    }
  }

  handleNextQuestion() {
    const { questionIndex } = this.state
    this.setState({ questionIndex: questionIndex + 1 })
  }

  setupParams() {
    const { forms } = this.props
    const { params, comments } = this.state
    Object.values(forms).forEach(form => {
      Object.values(form).forEach(activities => {
        activities.forEach(activity => {
          if (activity.type === 'plain') {
            const activityKeys = Object.keys(activity).filter(key => key !== 'type')
            activityKeys.forEach(key => {
              const answers = Object.values(activity[key])
              answers.forEach(answer => {
                if (answer.answer !== null) {
                  params[answer.id] = answer.answer
                  comments[answer.id] = answer.comment
                }
              })
            })
          } else {
            activity.options.forEach(activityKey => {
              const activityAnswer = Object.values(activity[activityKey])
                .map(skill => Object.values(skill))
              activityAnswer.forEach(answers => {
                answers.forEach(answer => {
                  if (answer.answer !== null) {
                    params[answer.id] = answer.answer
                    comments[answer.id] = answer.comment
                  }
                })
              })
            })
          }
        })
      })
    })
    this.setState({ params, comments, loading: false })
  }

  render() {
    const { classes, forms, events } = this.props
    const {
      selectedParticipant,
      selectedTab,
      params,
      comments,
      questionIndex, loading } = this.state
    const actualForm = forms[selectedParticipant]
    const selectedParticipantName = events.selected.participants
      .find(participant => selectedParticipant === participant.id)
    return (
      <div className={classes.container}>
        <LoaderAnimator loading={loading}>
          <EvaluationParticipants
            participants={events.selected.participants}
            selected={selectedParticipant}
            onSelect={this.handleSelectParticipant}
            onSubmit={this.handleSend}
          />
        </LoaderAnimator>
        <FunctionalContext.Provider value={{
          onChange: this.handleChange,
          onComment: this.handleComment,
          nextQuestion: this.handleNextQuestion,
          comments,
          params,
          selectedParticipant,
          questionIndex,
        }}
        >
          <LoaderAnimator loading={loading}>
            <MainForm
              tabs={[]}
              onSelectTab={this.handleSelectTab}
              selectedTab={selectedTab}
              onChange={this.handleChange}
              params={params}
              forms={actualForm}
              selectedParticipant={selectedParticipantName ? selectedParticipantName.name : ''}
            />
          </LoaderAnimator>
        </FunctionalContext.Provider>
      </div>
    )
  }
}

FunctionalEvaluation.propTypes = {
  classes: propTypes.object.isRequired,
  getEvaluation: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  forms: propTypes.arrayOf(propTypes.object),
  events: propTypes.object.isRequired,
  sendSkillAnswer: propTypes.func.isRequired,
  checkForm: propTypes.func.isRequired,
  history: propTypes.object.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
}

FunctionalEvaluation.defaultProps = {
  forms: []
}

const mapStateToProps = state => ({
  forms: state.functional.forms,
  events: state.events
})

const mapDispatchToProps = dispatch => ({
  getEvaluation: body => dispatch(getFunctionalEvaluationAction(body)),
  sendSkillAnswer: (body, id) => dispatch(sendSkillAnswerAction(body, id)),
  checkForm: body => dispatch(markFormAsFinishedAction(body))
})

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(FunctionalEvaluation))
)
