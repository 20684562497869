import React from 'react'
import { withStyles, Typography, makeStyles, Grid, Paper, Button } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { Visibility, Create, GetApp } from '@material-ui/icons'
import { loadExternalUsersAction, createExternalContenderAction } from '../../Actions/User'
import Header from './Header/index'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import GeneralTable from '../../Shared/GeneralTable'
import CreateOrganizationUserDialog from './CreateUser'
import BarChart from '../../Shared/Charts/BarChart'
import CircleChart from '../../Shared/Charts/CircleChart'
import autobind from '../../Utils/autobind'
import {
  genderData,
  genderOptions
} from '../Dashboard/testingData'

const style = theme => ({
  container: {
    padding: 12
  },
  tabsContainer: {
    marginTop: 12,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '150px 220px 330px auto'
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  unactiveBottomBorder: {
    borderBottom: `1px solid ${theme.palette.grey.dark}`,
  },
  activeBottomBorder: {
    borderBottom: `2px solid ${theme.palette.confirm.main}`,
  },
  activeText: {
    color: theme.palette.confirm.main,
    fontWeight: 'bold'
  },
  chartContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: 'auto auto',
    padding: 12,
    gridGap: 16,
    gridTemplateAreas: `'u1 u1 u2 u2'
                        'g1 g1 g2 g2'`
  },
  removeDecoration: {
    textDecoration: 'none'
  }
})

const textActionStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    marginRight: 6,
    textDecoration: 'underline',
    fontWeight: 'bold'
  },
  confirmDarkColor: {
    color: theme.palette.confirm.dark,
  },
  confirmMainColor: {
    color: theme.palette.confirm.main,
  },
}))

const tableInfo = [
  { name: 'Nombre', label: 'name' },
  { name: 'Rut', label: 'rut' },
  { name: 'Género', label: 'gender' },
  { name: 'Edad', label: 'age' },
  { name: 'Realizado por', label: 'allocation' },
]

const tabsName = ['Inicio', 'Ficha de Salud', 'Caracterización Socio-demográfica']

const goToFichaActionIcon = () => {
  const classes = textActionStyles()
  return (
    <div className={classes.container}>
      <Typography className={classNames(classes.text, classes.confirmDarkColor)}>
        Ver Ficha
      </Typography>
      <Visibility className={classes.confirmDarkColor} />
    </div>
  )
}

const goToCSDActionIcon = () => {
  const classes = textActionStyles()
  return (
    <div className={classes.container}>
      <Typography className={classNames(classes.text, classes.confirmDarkColor)}>
        Ver formulario
      </Typography>
      <Visibility className={classes.confirmDarkColor} />
    </div>
  )
}

const goToEditFichaActionIcon = () => {
  const classes = textActionStyles()
  return (
    <div className={classes.container}>
      <Typography className={classNames(classes.text, classes.confirmMainColor)}>
        Editar formulario
      </Typography>
      <Create className={classes.confirmMainColor} />
    </div>
  )
}

const downloadFichaActionIcon = ({ row }) => {
  const classes = textActionStyles()
  return (
    <div className={classes.container}>
      <Typography>
        <a
          href={`${process.env.REACT_APP_API_URL}/api/downloads/ficha_salud?user_id=${row.id}`}
          className={classNames(classes.text, classes.confirmMainColor)}
        >
          Descargar
        </a>
      </Typography>
      <GetApp className={classes.confirmMainColor} />
    </div>
  )
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      openCreateUser: false,
      activeTab: 0
    }
    autobind(Dashboard, this)
  }

  componentDidMount() {
    const { loadExternalUsers, user } = this.props
    loadExternalUsers({ external_organization_id: user.organization_id, remove_coordinator: true })
  }

  handleTabChange(tabIndex) {
    return () => {
      this.setState({ activeTab: tabIndex })
    }
  }

  handleDisplayCreateUser(state) {
    return () => {
      this.setState({ openCreateUser: state })
    }
  }

  handleCreateUser(body) {
    const { createExternalUser } = this.props
    createExternalUser(body)
  }

  handleGoToFicha(row) {
    const { history } = this.props
    return () => {
      history.push(`/external/${row.id}/health`)
    }
  }

  handleGoToCSD(row) {
    const { history } = this.props
    return () => {
      history.push(`/external/${row.id}/csd`)
    }
  }

  handleGoToAnsweredFicha(row) {
    const { history } = this.props
    return () => {
      history.push(`/external/${row.id}/health-answered`)
    }
  }

  handleGoToAnsweredCSD(row) {
    const { history } = this.props
    return () => {
      history.push(`/external/${row.id}/csd-answered`)
    }
  }

  renderTabs() {
    const { classes } = this.props
    const { activeTab } = this.state
    return (
      <div className={classes.tabsContainer}>
        {
          tabsName.map((tabName, idx) => {
            const isActive = idx === activeTab
            return (
              <Grid
                key={tabName}
                className={classNames({
                  [classes.tab]: true,
                  [classes.activeBottomBorder]: isActive,
                  [classes.unactiveBottomBorder]: !isActive
                })}
                onClick={this.handleTabChange(idx)}
              >
                <Typography
                  variant="h1"
                  className={classNames({
                    [classes.activeText]: isActive
                  })}
                >
                  {tabName}
                </Typography>
              </Grid>
            )
          })
        }
        <div className={classNames(classes.unactiveBottomBorder)} />
      </div>
    )
  }

  renderDashboard() {
    const { classes } = this.props
    return (
      <div className={classes.chartContainer}>
        <Paper elevation={3} style={{ gridArea: 'u1', padding: 24 }}>
          <BarChart
            name="generos"
            title="Género de postulanes ingresados"
            data={genderData}
            options={genderOptions}
          />
        </Paper>
        <Paper elevation={3} style={{ gridArea: 'u2', padding: 24 }}>
          <CircleChart
            name="Jornada"
            title="Jornada laboral"
            data={genderData}
            options={genderOptions}
          />
        </Paper>
      </div>
    )
  }

  renderHealthUsersTable() {
    const { classes, externalUsers, user } = this.props
    const actions = [
      { name: 'Ir a Ficha de Salud', icon: goToFichaActionIcon, action: this.handleGoToAnsweredFicha },
      { name: 'Editar formulario', icon: goToEditFichaActionIcon, action: this.handleGoToFicha },
      { name: 'Descargar', icon: downloadFichaActionIcon, action: () => () => {} }
    ]
    return (
      <div className={classes.container}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" color="primary">
            <a
              href={`${process.env.REACT_APP_API_URL}/api/downloads/ficha_salud?external_organization=${user.organization_id}`}
              className={classNames(classes.activeText, classes.removeDecoration)}
            >
              Descargar Fichas
            </a>
          </Button>
        </div>
        <GeneralTable
          info={tableInfo}
          data={externalUsers}
          actions={actions}
        />
      </div>
    )
  }

  renderCSDUsersTable() {
    const { classes, externalUsers } = this.props
    const csdActions = [
      { name: 'Revisar formulario', icon: goToCSDActionIcon, action: this.handleGoToAnsweredCSD },
      { name: 'Editar formulario', icon: goToEditFichaActionIcon, action: this.handleGoToCSD },
    ]
    return (
      <div className={classes.container}>
        <GeneralTable
          info={tableInfo}
          data={externalUsers}
          actions={csdActions}
        />
      </div>
    )
  }

  render() {
    const { user } = this.props
    const { loading, openCreateUser, activeTab } = this.state

    return (
      <>
        <Header handleAddClick={this.handleDisplayCreateUser(true)} />
        {
          loading ? <LoaderAnimator loading />
            : (
              <>
                {this.renderTabs()}
                {activeTab === 0 && this.renderDashboard()}
                {activeTab === 1 && this.renderHealthUsersTable()}
                {activeTab === 2 && this.renderCSDUsersTable()}
                <CreateOrganizationUserDialog
                  open={openCreateUser}
                  onClose={this.handleDisplayCreateUser(false)}
                  onSave={this.handleCreateUser}
                  externalOrganizationId={user.organization_id}
                />
              </>
            )
        }
      </>
    )
  }
}

Dashboard.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  loadExternalUsers: propTypes.func.isRequired,
  createExternalUser: propTypes.func.isRequired,
  externalUsers: propTypes.arrayOf(propTypes.object).isRequired,
  history: propTypes.object.isRequired
}

const mapDispatchToProps = dispatch => ({
  loadExternalUsers: params => dispatch(loadExternalUsersAction(params)),
  createExternalUser: body => dispatch(createExternalContenderAction(body))
})

const mapStateToProps = state => ({
  user: state.user.current,
  externalUsers: state.user.external,
})

export default connect(mapStateToProps,
  mapDispatchToProps)(withRouter(withStyles(style)(Dashboard)))
