import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'
import BarChart from 'Shared/Charts/BarChart'
import DownloadableSection from 'Shared/DownloadableSection'
import RecurrentContext from '../RecurrentEvaluations/RecurrentContext'
import { getFormEvaluation, getFormEvaluations } from '../../API/Recurrent'
import ComparisonChart from './Components/ComparisonChart'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import GlobalComment from '../../Shared/GlobalComment'

const style = () => ({
  container: {
    padding: 12
  }
})

class AutoevaluationMultipleResult extends Component {
  constructor() {
    super()
    this.state = {
      evaluations: [],
      results: [],
      loading: false
    }
  }

  async componentDidMount() {
    const { event } = this.context
    const response = await getFormEvaluations(event)
    if (response.data.status !== 'success') return this.setState({ loading: true })
    this.setState({ loading: true })
    const evaluations = response.data.info
    const totals = evaluations.filter(evaluation => evaluation.status === 'check').map(evaluation => this.getEvaluationResult(evaluation))
    const results = await Promise.all(totals)
    this.setState({
      evaluations: results.map(r => r[0]), loading: false, results: results.map(r => r[1])
    })
    return results
  }

  async getEvaluationResult(evaluation) {
    const { event } = this.context
    const body = {
      form_id: event.form_id,
      evaluation_id: evaluation.id,
    }
    const response = await getFormEvaluation(body)
    if (response.data.status !== 'success') return null
    const evaluationInfo = response.data.info
    const mainForm = evaluationInfo['Auto-evaluación'].empty
    const sections = Object.keys(mainForm)
    const results = []
    sections.forEach(section => {
      const sectionQuestions = mainForm[section]
      let totalValue = 0
      const sectionQuestionsAmount = sectionQuestions.length * 3
      sectionQuestions.forEach(question => {
        totalValue += question.answers[0].body?.answer || 0
      })
      const sectionPercentage = Math.round((totalValue * 100) / sectionQuestionsAmount)
      results.push({ label: section, value: sectionPercentage })
    })

    const groupoOfQuestions = Object.values(mainForm)
    const questions = groupoOfQuestions.flat()
    const total = questions
      .reduce((prev, question) => prev + (question?.answers[0]?.body?.answer || 0), 0)
    return [(((total * 6) / (questions.length * 3)) + 1).toFixed(2), results]
  }

  render() {
    const { classes } = this.props
    const { event, contender } = this.context
    const { evaluations, loading, results } = this.state
    const chartData = []
    const sections = results[0]?.map(r => r.label) || []
    sections.forEach(section => {
      chartData.push({ label: section.split(':')[0], value: [] })
    })
    results.forEach(data => {
      data.forEach((section, index) => {
        chartData[index].value.push(section.value)
      })
    })
    return (
      <div className={classes.container}>
        <div style={{ margin: '12px 0' }}>
          <Typography variant="subtitle1">Comentarios</Typography>
          <GlobalComment event={event} />
        </div>
        {loading ? <LoaderAnimator loading />
          : (
            <div>
              <DownloadableSection margin id="top" name={`Desempeño general autoevaluación ${contender?.name}`}>
                <Typography variant="h2" style={{ fontWeight: 600, margin: '24px 0 12px 0' }}>Desempeño general de evaluaciones</Typography>
                <ComparisonChart data={evaluations} />
              </DownloadableSection>
              <DownloadableSection margin id="bot" name={`Logro por ámbito ${contender?.name}`}>
                <Typography variant="h2" style={{ fontWeight: 600, margin: '24px 0 12px 0' }}>Porcentaje de logro por ámbito</Typography>
                <BarChart
                  data={chartData}
                  multiple
                  name="porcentajeambitos"
                  showTitle={false}
                  options={{
                    detachedLabels: false,
                    width: '100%',
                    maxValue: 100,
                    stepSize: 25,
                    yEnableGrid: true,
                    callback: value => `${value} %`,
                    legend: true
                  }}
                />
              </DownloadableSection>
            </div>
          )}
      </div>
    )
  }
}

AutoevaluationMultipleResult.contextType = RecurrentContext

AutoevaluationMultipleResult.propTypes = {
  classes: propTypes.object.isRequired
}

AutoevaluationMultipleResult.defaultProps = {
}

export default withStyles(style)(AutoevaluationMultipleResult)
