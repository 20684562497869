import React, { Component } from 'react'
import { Button, Collapse, Dialog, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { MoveToInbox } from '@material-ui/icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import DialogHeader from '../../../../Shared/Dialogs/DialogHeader'
import Select from '../../../../Shared/Fields/Select'
import { TextField } from '../../../../Shared/Fields/Text'
import DialogButtons from '../../../../Shared/Dialogs/DialogButtons'
import autobind from '../../../../Utils/autobind'
import { getContenderStatesAction } from '../../../../Actions/ContenderState'
import { getProgramsAction } from '../../../../Actions/ProgramAction'
import loadHandlersAction from '../../../../Actions/Handlers'
import { transformToOptions } from '../../../../Utils/functions'
import { createAuthorizationAction } from '../../../../Actions/Authorizations'

const style = () => ({
  container: {
    padding: 12
  },
  form: {
    '& > *': {
      margin: '6px 0'
    }
  }
})

function getStateObject(stateId, allStates) {
  if (!stateId) return {}
  const state = allStates.find(s => s.id === stateId)
  return state
}

class StateChangeDialog extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    autobind(StateChangeDialog, this)
  }

  componentDidMount() {
    const { getContenderStates, getAllHandlers, getPrograms } = this.props
    getAllHandlers()
    getPrograms()
    getContenderStates()
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleSubmit() {
    const { params } = this.state
    const { match, createAuthorization, onClose } = this.props
    const { id } = match.params
    const body = {
      type_id: 2,
      contender_id: id,
      text: params.text,
      extra_info: { ...params }
    }
    createAuthorization(body)
    onClose()
  }

  render() {
    const { classes, open, onClose, programs, contenders } = this.props;
    const { params } = this.state
    // const allHandlers = handler.all || []
    const allPrograms = programs.all || []
    const allStates = [...contenders.states] || []
    const selectedState = getStateObject(params?.state_id, allStates)
    const isDisable = !params.text || !params.state_id || (selectedState.name === 'Trabajando' && !params.program_id)
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <DialogHeader icon={MoveToInbox} title="Cambio de estado" onClose={onClose} />
          <div className={classes.form}>
            <TextField
              onChange={this.handleChange}
              complete={{ text: 'Motivo', name: 'text' }}
              value={params.text}
            />
            <Select
              onChange={this.handleChange}
              complete={{ options: transformToOptions(allStates), text: 'Estado', name: 'state_id' }}
              value={params.state_id}
            />
            <Collapse in={selectedState.name === 'Trabajando'}>
              <Select
                onChange={this.handleChange}
                complete={{ options: transformToOptions(allPrograms), text: 'Programa', name: 'program_id' }}
                value={params.program_id}
              />
            </Collapse>
          </div>
          <DialogButtons>
            <Button onClick={onClose}>
              Volver
            </Button>
            <Button onClick={this.handleSubmit} color="primary" variant="contained" disabled={isDisable}>
              Enviar solicitud
            </Button>
          </DialogButtons>
        </div>
      </Dialog>
    );
  }
}

StateChangeDialog.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool,
  onClose: propTypes.func,
  getAllHandlers: propTypes.func.isRequired,
  getPrograms: propTypes.func.isRequired,
  getContenderStates: propTypes.func.isRequired,
  createAuthorization: propTypes.func.isRequired,
  contenders: propTypes.object.isRequired,
  // handler: propTypes.object.isRequired,
  programs: propTypes.object.isRequired,
  match: propTypes.object.isRequired
}

StateChangeDialog.defaultProps = {
  open: false,
  onClose: undefined
}

const mapStateToProps = state => ({
  contenders: state.contenders,
  handler: state.handler,
  programs: state.programs,
})

const mapDispathToProps = dispatch => ({
  getContenderStates: () => dispatch(getContenderStatesAction()),
  getAllHandlers: () => dispatch(loadHandlersAction()),
  getPrograms: () => dispatch(getProgramsAction()),
  createAuthorization: body => dispatch(createAuthorizationAction(body))

})

export default withRouter(
  connect(mapStateToProps, mapDispathToProps)(withStyles(style)(StateChangeDialog))
)
