/* eslint-disable import/prefer-default-export */
import instance from '../Utils/client'

export const updateCertificates = async id => {
  const response = await instance({
    method: 'post',
    url: `events/${id}/update_participants_certificate`,
  })
  return response
}

export const advanceParticipantsToCandidate = async id => {
  const response = await instance({
    method: 'post',
    url: `events/${id}/change_to_candidate`,
  })
  return response
}

export const loadEvent = body => instance({
  method: 'get',
  url: `/events/${body.id}`,
  params: body
})

export const sendAnswers = (body, id) => instance({
  method: 'put',
  url: `/events/${id}/answer`,
  data: body
})

export const changeFormStatus = body => instance({
  method: 'post',
  url: `events/${body.event_id}/forms/${body.form_id}/status`,
  params: body
})
