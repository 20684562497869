import React, { Component } from 'react'
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = theme => ({
  secondHeader: {
    background: theme.palette.grey.light,
    textAlign: 'center',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  secondCell: {
    background: theme.palette.grey.light,
    textAlign: 'center'
  },
  secondFooter: {
    background: theme.palette.grey.light,
    borderBottom: 'unset',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    padding: 6
  },
  firstFooter: {
    borderBottom: 'unset'
  }
})
class CompetenciesTable extends Component {
  renderCompetencies() {
    const { data, classes } = this.props
    return data.map((competencie, index) => (
      <TableRow key={`${index + 12}-5`}>
        <TableCell>
          <Typography variant="body1">{competencie.name}</Typography>
        </TableCell>
        <TableCell className={classes.secondCell}>
          <Typography variant="subtitle1">{competencie.support_level}</Typography>
        </TableCell>
      </TableRow>
    ))
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.secondHeader}>
                <Typography variant="subtitle1">Nivel de Apoyo</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderCompetencies()}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={classes.firstFooter} />
              <TableCell className={classes.secondFooter} />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

CompetenciesTable.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.arrayOf(propTypes.object)
}

CompetenciesTable.defaultProps = {
  data: []
}
export default withStyles(style)(CompetenciesTable)
