import instance from '../Utils/client'
import createSnackbar from '../Utils/snackbars'

const googleSyncAction = (code, userId, snackbar) => {
  const REQUEST = instance({
    method: 'post',
    url: '/users/google_login',
    data: {
      code,
      user_id: userId,
    },
  })
  createSnackbar(REQUEST, snackbar)
  return {
    type: 'GOOGLE_SYNC',
    payload: REQUEST,
  }
}


// eslint-disable-next-line import/prefer-default-export
export { googleSyncAction }
