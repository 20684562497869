import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import moment from 'moment'
import { PersonAdd } from '@material-ui/icons'
import Pendings from './Pendings'
import Tasks from './Tasks'
import Cronogram from './Cronogram'
import CronogramForm from './CronogramForm'
import TaskForm from './TaskForm'
import autobind from '../../Utils/autobind'
import { alerts } from '../Contenders/Profile/fake'
// import Alerts from '../Contenders/Profile/Alerts'
import { getCronogramEvents, dateFilter, getCronogramObject } from '../../Utils/functions'
import { loadEventsAction, createEventAction } from '../../Actions/Events'
import { loadContendersAction } from '../../Actions/Contenders'
import GeneralTable from '../../Shared/GeneralTable'
import { loadUserTasksAction, createTaskAction, checkUserTaskAction } from '../../Actions/Task'
import { contenderInfo, contenderTestList } from './HandlerTableInfo'
import withMediaQuery from '../../Shared/withMediaQuery'
import ButtonsWithIcon from '../../Shared/Buttons/ButtonsWithIcon'
import AssignmentDialog from './AssignmentDialog'
import { assignContenderToHandler } from '../../API/Contenders'
import { getHandlerFromUser } from '../../API/Handlers'

const style = {
  container: {
  },
  cronoContainer: {
    padding: 12,
  },
  tablePendingFlex: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    justifyContent: 'space-between',
    '& > *:first-child': {
      flexBasis: 500,
      flexGrow: 1,
      margin: 12,
      maxHeight: 450
    },
    '& > *:last-child': {
      flexBasis: 250,
      flexGrow: 1,
      margin: 12,
      maxHeight: 450
    }
  },
  taskContainer: {
    padding: 12,
    maxWidth: 500
  },
  retrictedTable: {
    maxHeight: 450,
    overflow: 'auto'
  },
  cornerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 12px'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: 12,
    flexWrap: 'wrap-reverse',
    '& > *': {
      margin: 12,
      flexGrow: 1
    },
    '& > *:last-child': {
      minWidth: 350
    }
  },
}

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cronogram: false,
      task: false,
      cronogramDate: moment(),
      alertList: alerts,
      openAssign: false,
      handler: null
    }
    autobind(Profile, this)
  }

  async componentDidMount() {
    const { loadEvents, loadContenders, loadTasks, user } = this.props
    const response = await getHandlerFromUser({ id: user.id })
    if (response?.status === 200) {
      const handler = response.data.info
      this.setState({ handler })
    }
    loadContenders()
    loadEvents(true)
    loadTasks(user.id)
  }

  handleOpenForm(formName) {
    return () => {
      this.setState({ [formName]: true })
    }
  }

  handleOpenAssign() {
    const { openAssign } = this.state
    this.setState({ openAssign: !openAssign })
  }

  handleCloseForm(formName) {
    return () => {
      this.setState({ [formName]: false })
    }
  }

  handleAddEvent(event) {
    const { createEvent, enqueueSnackbar } = this.props
    createEvent(event, enqueueSnackbar)
  }

  handleAddTask(task) {
    const { createTask, enqueueSnackbar, user } = this.props
    createTask(user.id, task, enqueueSnackbar)
  }

  handleRemoveAlert(alertId) {
    return () => {
      this.setState(prevState => ({ alertList:
        prevState.alertList.filter(el => el.id !== alertId) }))
    }
  }

  async handleAssign(contenderId) {
    const { user: { id }, loadContenders } = this.props
    const body = { id, contender_id: contenderId }
    await assignContenderToHandler(body)
    loadContenders()
  }

  handleCronogramDate(type) {
    return () => {
      if (type === 'add') {
        return this.setState(prevState => ({
          cronogramDate: prevState.cronogramDate.clone().add({ weeks: 1 }) }))
      }
      return this.setState(prevState => ({
        cronogramDate: prevState.cronogramDate.clone().add({ weeks: -1 }) }))
    }
  }

  handleGotoUser(row) {
    return () => {
      const { history } = this.props
      history.push(`/contenders/${row.id}`)
    }
  }

  handleTaskCheck(taskId) {
    const { checkTask, enqueueSnackbar, user } = this.props
    checkTask(user.id, taskId, enqueueSnackbar)
  }

  render() {
    const { classes, events, tasks, contenders, mediaQuery } = this.props
    const { cronogram, task, cronogramDate, openAssign, handler } = this.state
    const allContenders = contenders.all || []
    const cronogramInfo = getCronogramEvents(events.all)
    const cronogramEvents = getCronogramObject(dateFilter(cronogramDate.clone().startOf('isoWeek'),
      cronogramDate.clone().endOf('isoWeek').subtract({ days: 2 }),
      { data: cronogramInfo.events, key: 'date' }))

    const assignedContenders = allContenders
      .filter(contender => contender?.handlers?.includes(handler?.id))
    const contenderList = contenderTestList(assignedContenders)
    return (
      <>
        <div className={classes.container}>
          <div className={classes.cronoContainer}>
            <Cronogram
              date={cronogramDate}
              extend={cronogramEvents.extend}
              changeDate={this.handleCronogramDate}
              elevation={3}
              days={cronogramEvents.events}
              handleOpenForm={this.handleOpenForm('cronogram')}
              handleAddTask={this.handleAddTask}
            />
          </div>
          <CronogramForm
            open={cronogram}
            handleAdd={this.handleAddEvent}
            handleClose={this.handleCloseForm('cronogram')}
          />
          <div className={classes.flexContainer}>
            <Paper>
              <div className={classes.cornerButton}>
                <Typography variant="subtitle1">Usuarios Asignados</Typography>
                <ButtonsWithIcon buttonProps={{ style: { color: '#27676A' } }} size="small" color="primary" Icon={PersonAdd} variant="text" onClick={this.handleOpenAssign}>Asignar usuarios</ButtonsWithIcon>
              </div>
              <div className={classes.retrictedTable}>
                <GeneralTable
                  data={contenderList}
                  info={contenderInfo}
                  disablePagination
                  disableFilters
                  responsive={!mediaQuery}
                  mainAction={this.handleGotoUser}
                />
              </div>
            </Paper>
            <Pendings />
          </div>
          <div className={classes.taskContainer}>
            <Tasks
              elevation={3}
              tasks={tasks}
              handleOpenForm={this.handleOpenForm('task')}
              checkTask={this.handleTaskCheck}
            />
          </div>
          <TaskForm
            open={task}
            handleSave={this.handleAddTask}
            handleClose={this.handleCloseForm('task')}
          />
          <AssignmentDialog
            contenders={allContenders}
            open={openAssign}
            onClose={this.handleOpenAssign}
            onAssign={this.handleAssign}
            handler={handler}
          />
        </div>
      </>
    )
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  loadEvents: PropTypes.func.isRequired,
  events: PropTypes.object.isRequired,
  loadTasks: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  loadContenders: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object),
  createTask: PropTypes.func.isRequired,
  checkTask: PropTypes.func.isRequired,
  contenders: PropTypes.object.isRequired,
  mediaQuery: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
}

Profile.defaultProps = {
  tasks: [],
}

const mapStateToProps = state => ({
  events: state.events,
  contenders: state.contenders,
  user: state.user.current,
  tasks: state.task.selected,
})

const mapDispatchToProps = dispatch => ({
  loadEvents: onlyActive => dispatch(loadEventsAction(onlyActive)),
  loadContenders: () => dispatch(loadContendersAction()),
  createEvent: (event, snackbar) => dispatch(createEventAction(event, snackbar)),
  loadTasks: userId => dispatch(loadUserTasksAction(userId)),
  createTask: (task, snackbar) => dispatch(createTaskAction(task, snackbar)),
  checkTask: (userId, taskId, snackbar) => dispatch(checkUserTaskAction(userId, taskId, snackbar)),
})

export default withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(withMediaQuery('(min-width:1350px')(Profile))))
