import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import BarChart from 'Shared/Charts/BarChart'
import DownloadButton from 'Components/Dashboard/DownloadButton'

const style = () => ({
  container: {
    padding: 24,
    '& > h2': {
      fontWeight: 600,
    },
    '& > h6': {
      marginBottom: 24
    },
    borderRadius: 24
  },
  mainContainer: {
    position: 'relative',
    margin: '12px 0',
  },
  labels: {
    margin: '0 0 24px 24px'
  },
  label: {
    height: 16,
    width: 32,
    borderRadius: 3,
    marginRight: 6
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24
  },
  chart: {
    display: 'flex',
    '& > *:first-child': {
      flexGrow: 1,
    }
  }
})

const supportLevels = [
  'Sin apoyo',
  'Poco Apoyo',
  'Apoyo Ocasional',
  'Bastante Apoyo',
  'Apoyo Total'
]

class CompetencyUsersChart extends Component {
  render() {
    const { classes, data, subtitle, name } = this.props;
    const labels = Object.keys(data)
    const values = Object.values(data).map(rots => Object.values(rots))
    const finalData = labels.map((crit, index) => ({ label: crit.split(':')[0], value: values[index] }))
    return (
      <div className={classes.mainContainer}>
        <DownloadButton title={`${name}chart`} name={`${name}-${subtitle}`} />
        <Paper className={classes.container} id={`${name}chart`}>
          <Typography variant="h2">{name}</Typography>
          <Typography variant="subtitle1">{subtitle || 'Porcentaje de logro por dimensión'}</Typography>
          <div className={classes.chart}>
            <BarChart
              data={finalData}
              name={`competencias${name}`}
              showTitle={false}
              multiple
              options={{
                detachedLabels: false,
                maxValue: 5,
                minValue: 1,
                stepSize: 1,
                yEnableGrid: true,
                callback: value => `${value === -1 ? 'No ingresado' : supportLevels[value - 1]}`,
                legend: false,
                width: '100%',
                barThickness: 24
              }}
            />
            <div className={classes.labels}>
              <div className={classes.labelContainer}>
                <div className={classes.label} style={{ background: '#1F8095', }} />
                <Typography variant="subtitle1">EDC 3</Typography>
              </div>
              <div className={classes.labelContainer}>
                <div className={classes.label} style={{ background: '#D5602C', }} />
                <Typography variant="subtitle1">EDC 6</Typography>
              </div>
              <div className={classes.labelContainer}>
                <div className={classes.label} style={{ background: '#FFB33D' }} />
                <Typography variant="subtitle1">EDC 9</Typography>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

CompetencyUsersChart.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
  subtitle: propTypes.string,
  name: propTypes.string
}

CompetencyUsersChart.defaultProps = {
  subtitle: null,
  name: ''
}
export default withStyles(style)(CompetencyUsersChart)
