import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { loadEvent } from '../../API/Events'
import FlexibleInput from '../../Utils/FieldRenderer'
import LoaderDialog from '../../Shared/LoaderDialog'

const style = () => ({
  container: {
    maxWidth: 700,
    margin: 'auto',
    padding: 24,
    textAlign: 'start'
  },
  message: {
    '& > *': {
      margin: '12px 0'
    }
  },
  question: {
    margin: '24px 0 32px',
    '& > div > p': {
      fontWeight: '600 !important',
      marginBottom: '0 !important'
    }
  }
})

class ReviewGencat extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      loading: false,
      event: {}
    }
  }

  async componentDidMount() {
    const { match: { params: { id, user_id: userId } } } = this.props;

    this.setState({ loading: true })
    const response = await loadEvent({ id })
    const { data: { status, info: event } } = response
    if (status !== 'success') return this.setState({ loading: false })
    this.setState({ loading: false, event })

    const params = {}
    if (!event) return null
    const mainForm = event.forms['Escala de Calidad de Vida Familiar']
    const stageNames = Object.keys(mainForm)
    const stages = stageNames.map(key => mainForm[key][key])
    stages.forEach(stage => {
      stage.forEach(question => {
        const answer = question?.answers?.find(a => a.user_id === userId)
        if (answer?.body) { params[answer.id] = answer.body }
      })
    })
    return this.setState({ params })
  }

  renderForm() {
    const { classes } = this.props
    const { match: { params: { user_id: userId } } } = this.props;
    const { params, event } = this.state
    if (!Object.keys(event).length) return null
    const mainForm = event.forms['Escala de Calidad de Vida Familiar']
    const stageNames = Object.keys(mainForm)
    const stages = stageNames.map(key => mainForm[key][key])
    return stages.map((stage, index) => {
      const name = stageNames[index]
      return (
        <div className={classes.form} key={name}>
          {index > 0 && <Divider className={classes.divider} />}
          {stage.map(question => {
            const questionName = question?.answers?.find(a => a.user_id === userId).id
            const complete = { ...question, name: questionName }
            return (
              <div className={classes.question} key={question?.id}>
                <FlexibleInput
                  complete={complete}
                  onChange={this.handleChange}
                  value={params[questionName]}
                  disabled
                  disableNoObservation
                />
              </div>
            )
          })}
        </div>
      )
    })
  }

  render() {
    const { classes, match: { params: { user_id: userId } } } = this.props;
    const { loading, event: { participants = [] } } = this.state
    const participant = participants.find(pt => pt.id === userId)

    return (
      <div className={classes.container}>
        <LoaderDialog open={loading} />
        <Typography variant="h1">
          {`Escala de Calidad de Vida Familiar de: ${participant?.name || 'Cargando...'}`}
        </Typography>
        {this.renderForm()}
      </div>
    );
  }
}

ReviewGencat.propTypes = {
  classes: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
}

ReviewGencat.defaultProps = {

}
export default withStyles(style)(ReviewGencat)
