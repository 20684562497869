import React, { Component } from 'react'
import propTypes from 'prop-types'
import Chart from 'chart.js'
import './Setup'
import { Typography, withStyles } from '@material-ui/core'
import Conditional from '../Conditional'

const style = () => ({
  labels: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: 100
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    marginRight: 12
  }
})

class RadarChart extends Component {
  componentDidMount() {
    this.renderChart()
  }

  componentDidUpdate(prevProps) {
    const { data, options } = this.props
    if (data !== prevProps.data) {
      const labels = Object.keys(data)
      const values = Object.values(data)
      this.chart.data.labels = labels
      this.chart.data.datasets = [
        {
          data: values,
          backgroundColor: options.color,
          borderColor: options.color,
          pointRadius: 0,
        },
      ]
      this.chart.update()
    }
  }

  renderChart() {
    const { name, data, options } = this.props
    const chartElement = document.getElementById(name)
    const myChartRef = chartElement.getContext('2d');
    chartElement.parentNode.style.height = options.height;
    chartElement.parentNode.style.width = '100%';

    const labels = Object.keys(data)
    const values = Object.values(data)
    // eslint-disable-next-line no-new
    this.chart = new Chart(myChartRef, {
      type: 'radar',
      data: {
        // Bring in data
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: options.color,
            borderColor: options.color,
            pointRadius: 2,
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        scale: {
          beginAtZero: true,
          gridLines: {
            color: 'rgba(0, 0, 0, 0.2)',
          },
          ticks: {
            display: false,
            maxTicksLimit: options.maxTicksLimit,
            max: options.maxValue,
            min: options.minValue
          },
          pointLabels: {
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'bold',
            fontColor: 'black',
            fontSize: 12,
          }
        }
      }
    })
  }

  renderLabels() {
    const { classes, data } = this.props
    return Object.keys(data).map((element, index) => {
      const toReturn = (
        <div className={classes.label} key={`${index + 1} - label`}>
          <div className={classes.dot} style={{ background: element.color }} />
          <Typography variant="body1" style={{ fontWeight: 600 }}>{element.label}</Typography>
        </div>
      )
      return toReturn
    })
  }

  render() {
    const { name, title, classes, options, showTitle } = this.props
    return (
      <div>
        {showTitle && (
          <Typography
            variant="subtitle1"
            color="grey"
            style={{ textAlign: 'start', marginBottom: 24 }}
          >
            {title}

          </Typography>
        )}
        <div>
          <canvas
            id={name}
            ref={this.chartRef}
          />
        </div>
        <Conditional condition={options.detachedLabels}>
          <div className={classes.labels}>
            {this.renderLabels()}
          </div>
        </Conditional>
      </div>
    )
  }
}

RadarChart.propTypes = {
  title: propTypes.string,
  name: propTypes.string.isRequired,
  classes: propTypes.object.isRequired,
  data: propTypes.object,
  options: propTypes.object,
  showTitle: propTypes.bool,
}

RadarChart.defaultProps = {
  showTitle: true,
  title: '',
  data: [],
  options: {
    height: '250px',
    width: '100%',
    barThickness: 64,
    enableHover: false,
    detachedLabels: true,
    minValue: 0,
    maxValue: 100,
    stepSize: 20,
  }
}

export default withStyles(style)(RadarChart)
