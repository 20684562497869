import React, { Component } from 'react'
import { Button, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    height: 18,
    width: 18,
    marginRight: 6
  }
})
class ButtonsWithIcon extends Component {
  render() {
    const {
      classes, Icon, color, size, variant, onClick, children, buttonProps } = this.props;
    return (
      <Button
        className={classes.container}
        color={color}
        variant={variant}
        size={size}
        onClick={onClick}
        {...buttonProps}
      >
        <Icon className={classes.icon} />
        {children}
      </Button>
    );
  }
}

ButtonsWithIcon.propTypes = {
  classes: propTypes.object.isRequired,
  Icon: propTypes.node.isRequired,
  children: propTypes.node.isRequired,
  color: propTypes.string,
  variant: propTypes.string,
  size: propTypes.string,
  onClick: propTypes.func,
  buttonProps: propTypes.object,
  spaced: propTypes.bool,
}

ButtonsWithIcon.defaultProps = {
  color: 'primary',
  size: 'medium',
  variant: 'contained',
  onClick: undefined,
  buttonProps: {},
  spaced: false
}

export default withStyles(style)(ButtonsWithIcon)
