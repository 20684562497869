import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: 6
    }
  }
})

class DialogButtons extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <div className={classes.container}>
        {children}
      </div>
    );
  }
}

DialogButtons.propTypes = {
  classes: propTypes.object.isRequired,
  children: propTypes.node.isRequired
}

DialogButtons.defaultProps = {
}

export default withStyles(style)(DialogButtons)
