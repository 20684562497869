import React from 'react';
import propTypes from 'prop-types'
import { Dialog, IconButton, Typography, withStyles } from '@material-ui/core';
import { HelpOutline, HighlightOff } from '@material-ui/icons'
import autobind from '../../Utils/autobind';

const style = () => ({
  dialog: {
    padding: 24,
    position: 'relative'
  },
  definition: {
    margin: '12px 0'
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12
  }
})

class CSDTooltipContainer extends React.Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(CSDTooltipContainer, this)
  }

  handleOpen() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  renderOptions(options) {
    const { classes } = this.props
    return options.map(option => {
      const { title, content } = option
      return (
        <div className={classes.definition}>
          <Typography variant="subtitle1">
            <span style={{ fontWeight: 600, fontStyle: 'italic', color: '#d76135' }}>{title}</span>
            {' '}
            {content}
          </Typography>
        </div>
      )
    })
  }

  renderTooltips() {
    const { tooltip, classes } = this.props
    return tooltip.map(tooltipObject => {
      const { title, content = '', options = [] } = tooltipObject
      return (
        <div className={classes.definition}>
          <Typography variant="subtitle1" color="primary" style={{ fontWeight: 600 }}>{title}</Typography>
          <Typography variant="subtitle1">{content}</Typography>
          {options.length > 0 && this.renderOptions(options)}
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props
    const { open } = this.state
    return (
      <div>
        <IconButton onClick={this.handleOpen}>
          <HelpOutline />
        </IconButton>
        <Dialog open={open} onClose={this.handleOpen} fullWidth maxWidth="md">
          <div className={classes.dialog}>
            <Typography
              variant="h2"
              style={{ fontWeight: 600 }}
            >
              Definiciones Caracterización Socio-demográfica

            </Typography>
            <IconButton size="small" className={classes.closeButton} onClick={this.handleOpen}>
              <HighlightOff />
            </IconButton>
            {this.renderTooltips()}
          </div>
        </Dialog>
      </div>
    )
  }
}

CSDTooltipContainer.propTypes = {
  tooltip: propTypes.object.isRequired,
  classes: propTypes.object.isRequired,
}

export default withStyles(style)(CSDTooltipContainer);
