import React from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import propTypes from 'prop-types'
import Percentile from './Percentile'
import LQI from './LQI'
import LineChartGencat from './LineChartGencat'
import RadarChart from '../../../../Shared/Charts/RadarChart'
import PageChanger from './PageChanger'
import autobind from '../../../../Utils/autobind'
import OnTouchTooltip from '../../../../Shared/OnTouchTooltip'

const style = theme => ({
  button: {
    color: theme.palette.grey.border,
    height: 20,
    width: 20,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12
  },
  titleResult: {
    fontWeight: 900,
    fontSize: 14,
    color: '#6A6A6A',
  },
})

const radarOptions = {
  color: 'rgba(233, 169, 131, 0.8)',
  maxTicksLimit: 6,
  height: '250px',
  width: '100%',
}

class FirstPage extends React.Component {
  constructor(props) {
    super(props)
    autobind(FirstPage, this)
  }

  handleNextPage() {
    const { handleNextStep } = this.props
    handleNextStep(1)
  }

  render() {
    const { gencatResults, classes } = this.props
    const gencatResultArray = Array.isArray(gencatResults) ? gencatResults : []
    const percentile = gencatResultArray.map(gencat => parseInt(gencat.percentile, 10))
    const quality = gencatResultArray.map(gencat => parseInt(gencat.quality_index, 10))
    const radarData = gencatResultArray.map(gencat => {
      const radar = {}
      gencat.results.forEach(info => {
        radar[info.short] = info.percentile
      })
      return radar
    })
    const lineData = gencatResultArray.map(gencat => {
      const lines = gencat.results.map(info => (
        {
          label: info.name.split(' '),
          value: info.relative_percentage,
          color: '#1F8095'
        }
      ))
      return lines
    })
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
          <Paper>
            <Percentile data={percentile} />
          </Paper>
          <Paper>
            <LQI quality={quality} />
          </Paper>
        </div>
        <Paper style={{ padding: 12 }}>
          <div className={classes.inline}>
            <Typography className={classes.titleResult}>Perfil de calidad de vida</Typography>
            <OnTouchTooltip title="Comparación de resultados de dimensiones. Mientras más cerca del centro este el resultado, mas apoyos requerirá la persona en esa dimensión.">
              <HelpOutline className={classes.button} />
            </OnTouchTooltip>
          </div>
          <RadarChart
            options={radarOptions}
            data={radarData[0]}
            name="percentiles"
          />
        </Paper>
        <Paper style={{ gridColumn: '1 / 3', padding: 12 }}>
          <LineChartGencat title="Percentiles de dimensiones" data={lineData[0]} name="stdscore" />
        </Paper>
        <PageChanger text="1 de 2" containerStyle={{ gridColumn: '1 / 3' }} handleNext={this.handleNextPage} />
      </>
    )
  }
}

FirstPage.propTypes = {
  gencatResults: propTypes.arrayOf(propTypes.object),
  classes: propTypes.object.isRequired,
  handleNextStep: propTypes.func.isRequired
}

FirstPage.defaultProps = {
  gencatResults: undefined
}

export default withStyles(style)(FirstPage)
