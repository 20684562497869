const climaFamiliar = value => {
  if (value >= 69) return 99
  if (value >= 68) return 98
  if (value >= 68) return 97
  if (value >= 67) return 96
  if (value >= 67) return 95
  if (value >= 66) return 94
  if (value >= 66) return 93
  if (value >= 66) return 92
  if (value >= 66) return 91
  if (value >= 66) return 90
  if (value >= 65) return 85
  if (value >= 64) return 80
  if (value >= 63) return 75
  if (value >= 62) return 70
  if (value >= 62) return 65
  if (value >= 62) return 60
  if (value >= 61) return 55
  if (value >= 61) return 50
  if (value >= 60) return 45
  if (value >= 60) return 40
  if (value >= 59) return 35
  if (value >= 58) return 30
  if (value >= 57) return 25
  if (value >= 56) return 20
  if (value >= 55) return 15
  if (value >= 53) return 10
  if (value >= 52) return 9
  if (value >= 51) return 8
  if (value >= 50) return 7
  if (value >= 49) return 6
  if (value >= 48) return 5
  if (value >= 46) return 4
  if (value >= 44) return 3
  if (value >= 42) return 2
  if (value >= 39) return 1
  return 1
}

const AutonomiaPersona = value => {
  if (value >= 60) return 99
  if (value >= 59) return 98
  if (value >= 58) return 97
  if (value >= 58) return 96
  if (value >= 57) return 95
  if (value >= 57) return 94
  if (value >= 56) return 93
  if (value >= 56) return 92
  if (value >= 55) return 91
  if (value >= 55) return 90
  if (value >= 53) return 85
  if (value >= 52) return 80
  if (value >= 51) return 75
  if (value >= 50) return 70
  if (value >= 49) return 65
  if (value >= 48) return 60
  if (value >= 47) return 55
  if (value >= 47) return 50
  if (value >= 45) return 45
  if (value >= 44) return 40
  if (value >= 43) return 35
  if (value >= 42) return 30
  if (value >= 41) return 25
  if (value >= 39) return 20
  if (value >= 37) return 15
  if (value >= 35) return 10
  if (value >= 35) return 9
  if (value >= 34) return 8
  if (value >= 33) return 7
  if (value >= 32) return 6
  if (value >= 31) return 5
  if (value >= 29) return 4
  if (value >= 28) return 3
  if (value >= 26) return 2
  if (value >= 24) return 1
  return 1
}

const Bienestar = value => {
  if (value >= 25) return 99
  if (value >= 25) return 98
  if (value >= 25) return 97
  if (value >= 25) return 96
  if (value >= 25) return 95
  if (value >= 25) return 94
  if (value >= 25) return 93
  if (value >= 25) return 92
  if (value >= 25) return 91
  if (value >= 25) return 90
  if (value >= 25) return 85
  if (value >= 24) return 80
  if (value >= 24) return 75
  if (value >= 23) return 70
  if (value >= 23) return 65
  if (value >= 22) return 60
  if (value >= 22) return 55
  if (value >= 21) return 50
  if (value >= 21) return 45
  if (value >= 20) return 40
  if (value >= 19) return 35
  if (value >= 18) return 30
  if (value >= 17) return 25
  if (value >= 17) return 20
  if (value >= 16) return 15
  if (value >= 15) return 10
  if (value >= 15) return 9
  if (value >= 14) return 8
  if (value >= 14) return 7
  if (value >= 14) return 6
  if (value >= 13) return 5
  if (value >= 12) return 4
  if (value >= 12) return 3
  if (value >= 11) return 2
  if (value >= 9) return 1
  return 1
}

const Organizacion = value => {
  if (value >= 55) return 99
  if (value >= 55) return 98
  if (value >= 55) return 97
  if (value >= 54) return 96
  if (value >= 53) return 95
  if (value >= 53) return 94
  if (value >= 53) return 93
  if (value >= 53) return 92
  if (value >= 52) return 91
  if (value >= 52) return 90
  if (value >= 51) return 85
  if (value >= 51) return 80
  if (value >= 50) return 75
  if (value >= 49) return 70
  if (value >= 48) return 65
  if (value >= 48) return 60
  if (value >= 47) return 55
  if (value >= 47) return 50
  if (value >= 46) return 45
  if (value >= 46) return 40
  if (value >= 45) return 35
  if (value >= 44) return 30
  if (value >= 43) return 25
  if (value >= 42) return 20
  if (value >= 41) return 15
  if (value >= 39) return 10
  if (value >= 38) return 9
  if (value >= 37) return 8
  if (value >= 36) return 7
  if (value >= 35) return 6
  if (value >= 34) return 5
  if (value >= 32) return 4
  if (value >= 31) return 3
  if (value >= 30) return 2
  if (value >= 27) return 1
  return 1
}

const Apoyo = value => {
  if (value >= 40) return 99
  if (value >= 38) return 98
  if (value >= 38) return 97
  if (value >= 38) return 96
  if (value >= 37) return 95
  if (value >= 37) return 94
  if (value >= 37) return 93
  if (value >= 36) return 92
  if (value >= 36) return 91
  if (value >= 36) return 90
  if (value >= 35) return 85
  if (value >= 34) return 80
  if (value >= 34) return 75
  if (value >= 33) return 70
  if (value >= 33) return 65
  if (value >= 32) return 60
  if (value >= 31) return 55
  if (value >= 31) return 50
  if (value >= 30) return 45
  if (value >= 30) return 40
  if (value >= 29) return 35
  if (value >= 28) return 30
  if (value >= 27) return 25
  if (value >= 26) return 20
  if (value >= 25) return 15
  if (value >= 23) return 10
  if (value >= 23) return 9
  if (value >= 23) return 8
  if (value >= 22) return 7
  if (value >= 21) return 6
  if (value >= 21) return 5
  if (value >= 20) return 4
  if (value >= 19) return 3
  if (value >= 19) return 2
  if (value >= 15) return 1
  return 1
}

const Sobrecarga = value => {
  // 7 preguntas de sobrecarga
  const maxValue = 7 * 5
  const result = Math.round((value * 100) / maxValue)
  return result
}

const getPercentileFamiliar = (value, criteria) => {
  if (criteria === 'Clima Familiar') return climaFamiliar(value)
  if (criteria === 'Autonomía de la Persona con Discapacidad Intelectual') return AutonomiaPersona(value)
  if (criteria === 'Bienestar Económico') return Bienestar(value)
  if (criteria === 'Organización y funcionamiento familiar') return Organizacion(value)
  if (criteria === 'Apoyo familiar a la persona con discapacidad intelectual') return Apoyo(value)
  if (criteria === 'Sobrecarga del cuidador') return Sobrecarga(value)
  return value
}

export const getFinalPercentile = value => {
  if (value >= 159) return 99
  if (value >= 155) return 98
  if (value >= 153) return 97
  if (value >= 153) return 96
  if (value >= 151) return 95
  if (value >= 149) return 94
  if (value >= 149) return 93
  if (value >= 148) return 92
  if (value >= 148) return 91
  if (value >= 147) return 90
  if (value >= 145) return 85
  if (value >= 143) return 80
  if (value >= 141) return 75
  if (value >= 139) return 70
  if (value >= 137) return 65
  if (value >= 135) return 60
  if (value >= 134) return 55
  if (value >= 132) return 50
  if (value >= 130) return 45
  if (value >= 129) return 40
  if (value >= 127) return 35
  if (value >= 125) return 30
  if (value >= 121) return 25
  if (value >= 119) return 20
  if (value >= 116) return 15
  if (value >= 111) return 10
  if (value >= 109) return 9
  if (value >= 107) return 8
  if (value >= 106) return 7
  if (value >= 104) return 6
  if (value >= 102) return 5
  if (value >= 96) return 4
  if (value >= 92) return 3
  if (value >= 88) return 2
  if (value >= 81) return 1
  return 1
}

// Nivel de apoyo

function apoyoFamliliar(value) {
  if (value <= 55) return 0
  if (value <= 58) return 1
  if (value <= 60) return 2
  if (value <= 61) return 3
  return 3
}
function apoyoAutonomia(value) {
  if (value <= 37) return 0
  if (value <= 42) return 1
  if (value <= 46) return 2
  if (value <= 47) return 3
  return 3
}
function apoyoBienestar(value) {
  if (value <= 16) return 0
  if (value <= 18) return 1
  if (value <= 20) return 2
  if (value <= 21) return 3
  return 3
}
function apoyoOrganizacion(value) {
  if (value <= 41) return 0
  if (value <= 44) return 1
  if (value <= 46) return 2
  if (value <= 47) return 3
  return 3
}
function apoyoApoyo(value) {
  if (value <= 25) return 0
  if (value <= 28) return 1
  if (value <= 30) return 2
  if (value <= 31) return 3
  return 3
}
function apoyoSobrecarga(value) {
  if (value <= 17) return [0, 0]
  const normalizedValue = (value - 17)
  const maxValue = 35 - 17
  const percentile = (normalizedValue * 100) / maxValue
  return [1, percentile]
}

export const getSupportLevelFamiliar = (value, criteria) => {
  if (criteria === 'Clima Familiar') return apoyoFamliliar(value)
  if (criteria === 'Autonomía de la Persona con Discapacidad Intelectual') return apoyoAutonomia(value)
  if (criteria === 'Bienestar Económico') return apoyoBienestar(value)
  if (criteria === 'Organización y funcionamiento familiar') return apoyoOrganizacion(value)
  if (criteria === 'Apoyo familiar a la persona con discapacidad intelectual') return apoyoApoyo(value)
  if (criteria === 'Sobrecarga del cuidador') return apoyoSobrecarga(value)
  return value
}

export default getPercentileFamiliar
