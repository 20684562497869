import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import RecurrentContainer from '../../RecurrentEvaluations/RecurrentContainer'
import RecurrentContext from '../../RecurrentEvaluations/RecurrentContext'
import { getFormEvaluation, getUserEvaluations } from '../../../API/Recurrent'
import autobind from '../../../Utils/autobind'
import GencatIndividualResult from './GencatIndividualResult'
import SetViewContext from '../../Contenders/Profile/SetViewProvider'
import MultipleResultsGencat from './MultipleResultsGencat'
import FamiliarCard from './FamiliarCard'

const style = () => ({

})

function handleGetEvaluations(event) {
  return getUserEvaluations(event)
}

function handleGotoEvaluation() {
  // eslint-disable-next-line no-alert
  alert('Debes ingresar a este evento a través del calendario o la lista de eventos pendientes de tu perfil')
}

class GencatResultsContainer extends Component {
  constructor() {
    super()
    autobind(GencatResultsContainer, this)
  }

  handleGetResult(evaluationInfo) {
    const { event } = this.props
    const body = {
      form_id: event.form_id,
      evaluation_id: evaluationInfo.id,
    }
    getFormEvaluation(body).then(response => {
      if (response.data.status !== 'success') return false
      const evaluation = response.data.info
      const { setView } = this.context
      return setView(
        <GencatIndividualResult
          evaluation={evaluationInfo.id}
          event={evaluation}
          eventInfo={event}
        />,
        `Escala de Calidad de Vida Familiar N°${evaluationInfo?.name}`
      )
    })
  }

  render() {
    const { classes, event, contenders } = this.props;
    const { selected: { user: { id: userId } }, selected: { user } } = contenders
    event.user_id = userId
    return (
      <div className={classes.container}>
        <RecurrentContext.Provider value={{
          event,
          getEvaluations: handleGetEvaluations,
          getEvaluation: handleGotoEvaluation,
          getEvaluationResult: this.handleGetResult,
          createNewEvaluation: () => {},
          evaluationName: 'Escala de Calidad de Vida Familiar',
          createButtonName: 'Nueva Evaluación',
          disabledCreate: true,
          user,
          card: FamiliarCard
        }}
        >
          <RecurrentContainer resultTab={MultipleResultsGencat} />
        </RecurrentContext.Provider>
      </div>
    );
  }
}

GencatResultsContainer.contextType = SetViewContext

GencatResultsContainer.propTypes = {
  classes: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  contenders: propTypes.object.isRequired,
}

GencatResultsContainer.defaultProps = {

}

const mapStateToProps = state => ({
  contenders: state.contenders
})

export default connect(mapStateToProps)(withStyles(style)(GencatResultsContainer))
