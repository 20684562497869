/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { Delete, Edit, NoteAdd, Work } from '@material-ui/icons'
import ConfirmDeleteDialog from 'Shared/Dialogs/ConfirmDeleteDialog'
import { createAuthorization } from 'API/Authorizations'
import { createEnterpriseAction, editEnterpriseAction, getEnterprisesAction } from '../../Actions/EnterpriseAction'
import autobind from '../../Utils/autobind'
import GeneralTable from '../../Shared/GeneralTable'
import ButtonsWithIcon from '../../Shared/Buttons/ButtonsWithIcon'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import { TextField } from '../../Shared/Fields/Text'
import { formatDate } from '../../Utils/functions'

const style = theme => ({
  container: {
    margin: 12
  },
  dialog: {
    padding: 24
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2)
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 12
    },
    marginBottom: 24
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: 6
    },
    margin: '12px 0'
  }
})

const tableInfo = [
  { label: 'name', name: 'Nombre' },
  { label: 'created_at', name: 'Fecha', format: formatDate },
]

class Enterprises extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      openDialog: false,
      openDeleteConfirmDialog: false,
      params: {},
      selected: {},
      option: null
    }
    autobind(Enterprises, this)
  }

  componentDidMount() {
    const { getEnterprises } = this.props
    this.setState({ loading: true })
    getEnterprises().then(() => this.setState({ loading: false }))
  }

  handleToggleDialog(option = null) {
    return () => {
      const { openDialog } = this.state
      this.setState({ openDialog: !openDialog, option })
    }
  }

  handleCloseDialog() {
    this.setState({ openDialog: false })
  }

  handleOpenEdit(enterprise) {
    return () => {
      const newParams = { name: enterprise.name }
      this.setState({ selected: enterprise, openDialog: true, params: newParams, option: 'Editar' })
    }
  }

  handleOpenConfirmDeleteDialog(enterprise) {
    return () => {
      this.setState({ selected: enterprise, openDeleteConfirmDialog: true, option: 'Borrar' })
    }
  }

  handleCloseConfirmDeleteDialog() {
    this.setState({ openDeleteConfirmDialog: false })
  }

  async handleCreateDeleteAuthorization() {
    const { selected } = this.state
    const body = {
      type_id: 8,
      text: `Borrar empresa ${selected.name}`,
      extra_info: { enterprise_id: selected.id }
    }
    await createAuthorization(body, 'eliminar')
    this.setState({ openDeleteConfirmDialog: false })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreate() {
    const { createEnterprise } = this.props
    const { params } = this.state
    const body = { ...params }
    createEnterprise(body)
    this.handleCloseDialog()
    this.setState({ params: {} })
  }

  handleEdit() {
    const { editEnterprise } = this.props
    const { selected, params } = this.state
    const body = { ...params }
    body.id = selected.id
    editEnterprise(body)
    this.handleCloseDialog()
    this.setState({ params: {} })
  }

  dialog() {
    const { classes } = this.props
    const { openDialog, params, option } = this.state
    return (
      <Dialog open={openDialog} onClose={this.handleCloseDialog} fullWidth maxWidth="sm">
        <div className={classes.dialog}>
          <div className={classes.dialogTitle}>
            <NoteAdd />
            <Typography variant="h1">
              {option}
              {' '}
              Empresa
            </Typography>
          </div>
          <TextField complete={{ text: 'Nombre empresa', name: 'name' }} value={params.name} onChange={this.handleChange} />
          <div className={classes.dialogButtons}>
            <Button onClick={this.handleCloseDialog}>
              Volver
            </Button>
            <Button onClick={option === 'Crear' ? this.handleCreate : this.handleEdit} color="primary" variant="contained">
              {option}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }

  render() {
    const { classes, enterprises } = this.props;
    const allEnterprises = enterprises.all || []
    const { loading, openDeleteConfirmDialog } = this.state
    const actions = [
      { name: 'Editar empresa', icon: Edit, action: this.handleOpenEdit },
      { name: 'Borrar empresa', icon: Delete, action: this.handleOpenConfirmDeleteDialog }
    ]
    return (
      <div className={classes.container}>
        <div className={classes.button}>
          <ButtonsWithIcon Icon={Work} onClick={this.handleToggleDialog('Crear')}>
            Agregar Empresa
          </ButtonsWithIcon>
        </div>
        {loading
          ? <LoaderAnimator loading />
          : <GeneralTable info={tableInfo} data={allEnterprises} actions={actions} />}
        {this.dialog()}
        <ConfirmDeleteDialog
          isOpen={openDeleteConfirmDialog}
          onConfirm={this.handleCreateDeleteAuthorization}
          onCancel={this.handleCloseConfirmDeleteDialog}
          description="Al confirmar, se generará una autorización para borrar esta empresa"
        />
      </div>
    );
  }
}

Enterprises.propTypes = {
  classes: propTypes.object.isRequired,
  getEnterprises: propTypes.func.isRequired,
  editEnterprise: propTypes.func.isRequired,
  createEnterprise: propTypes.func.isRequired,
  enterprises: propTypes.arrayOf(propTypes.object).isRequired
}

Enterprises.defaultProps = {

}

const mapStateToProps = state => ({
  enterprises: state.enterprises
})

const mapDispatchToProps = dispatch => ({
  getEnterprises: () => dispatch(getEnterprisesAction()),
  editEnterprise: body => dispatch(editEnterpriseAction(body)),
  createEnterprise: body => dispatch(createEnterpriseAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Enterprises))
