import React, { useState } from 'react'
import moment from 'moment'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import propTypes from 'prop-types'
import { TextField } from '../../Shared/Fields/Text'
import Select from '../../Shared/Fields/Select'
import { DatePicker } from '../../Shared/form/Fields'

const style = makeStyles(theme => ({
  mainContainer: {
    width: 500
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    marginRight: 6
  },
  input: {
    margin: '12px 0'
  },
  datepicker: {
    background: theme.palette.grey.light,
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    paddingLeft: 6
  },
  datepickerLabel: {
    fontWeight: 400,
    color: theme.typography.body1.color
  }
}))

const CreateDialog = ({ open, onClose, onSave, externalOrganizationId }) => {
  const [name, setName] = useState('')
  const [gender, setGender] = useState('')
  const [lastName, setLastName] = useState('')
  const [rut, setRut] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState(null)
  const classes = style()

  const handleChange = setFunc => e => {
    setFunc(e.target.value)
  }

  const handleDateChange = value => setDateOfBirth(value)

  const handleSave = () => {
    const finalName = `${name} ${lastName}`
    onSave({
      name: finalName,
      gender: parseInt(gender, 10),
      rut,
      date_birth: moment(dateOfBirth).format('DD-MM-YYYY'),
      organization_id: externalOrganizationId
    })
    onClose()
  }
  return (
    <Dialog open={open} PaperProps={{ className: classes.mainContainer }}>
      <DialogTitle>
        <div className={classes.titleContainer}>
          <NoteAddIcon className={classes.titleIcon} />
          Nuevo usuario de Redes
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.input}>
          <TextField
            onChange={handleChange(setName)}
            complete={{ name: 'name', text: 'Nombre', id: 'name-external' }}
            value={name}
          />
        </div>
        <div className={classes.input}>
          <TextField
            onChange={handleChange(setLastName)}
            complete={{ text: 'Apellido', name: 'lastname', id: 'lastname-external' }}
            value={lastName}
          />
        </div>
        <div className={classes.input}>
          <Select
            onChange={handleChange(setGender)}
            value={gender}
            complete={{ options: ['Masculino', 'Femenino'], id: 'gen-external', name: 'gender', text: 'Género' }}
          />
        </div>
        <div className={classes.input}>
          <TextField
            onChange={handleChange(setRut)}
            complete={{ text: 'Rut', name: 'rut', id: 'rut-external' }}
            value={rut}
          />
        </div>
        <div className={classes.input}>
          <Typography className={classes.datepickerLabel}>Fecha de nacimiento</Typography>
          <DatePicker
            value={dateOfBirth}
            onChange={handleDateChange}
            inputClassName={classes.datepicker}
            emptyLabel="-"
            disableToolbar={false}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}> Cancelar </Button>
        <Button onClick={handleSave} variant="contained" color="primary"> Guardar </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateDialog.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
  externalOrganizationId: propTypes.string.isRequired
}

export default CreateDialog
