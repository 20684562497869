import React, { Component } from 'react'
import propTypes from 'prop-types'
import { withStyles, Typography, Divider, Paper } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import FormState from './FormState'
import { checkIfAnswered, getAnswered, getTotal } from './utils'

const style = theme => ({
  text: {
    marginBottom: 24
  },
  container: {
    padding: 24
  },
  divider: {
    background: theme.palette.grey.dark,
    margin: '24px 0'
  },
  icon: {
    width: 76,
    position: 'absolute',
    top: 24,
    left: 24
  },
  paper: {
    padding: 24,
    maxWidth: '60%',
    margin: 'auto'
  },
  img: {
    width: '90%',
    marginTop: 24
  }
})

class GencatResult extends Component {
  componentDidMount() {
    const { forms, markReady } = this.props
    markReady(forms)()
  }

  handleSelect(value) {
    return () => {
      const { handleNextStep } = this.props
      handleNextStep(value)
    }
  }

  renderForms() {
    const { event, classes, match } = this.props
    if (event) {
      return Object.keys(event.forms).filter(formName => formName !== 'Escala de Calidad de Vida').map((formName, index) => (
        <div key={formName}>
          <FormState
            title={formName}
            completed={checkIfAnswered(event.forms[formName], match.params.contender_id)}
            select={this.handleSelect(index + 1)}
            answered={getAnswered(event.forms[formName], match.params.contender_id)}
            total={getTotal(event.forms[formName])}
          />
          <Divider className={classes.divider} />
        </div>
      ))
    } return null
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <img src="/Asset1.png" alt="logo" className={classes.icon} />
        <Paper className={classes.paper}>
          <Typography className={classes.text} variant="h1" color="primary">¡Gracias!</Typography>
          <Typography className={classes.text} variant="subtitle1">
            Es siguiente paso es la entrevista individual y familiar.
          </Typography>
          <Typography className={classes.text} variant="subtitle1">
            Nuestro equipo lo/la contactará para agendar durante los próximos 5 días hábiles.
          </Typography>
          <Divider className={classes.divider} />
          {this.renderForms()}
          <img src="/roadmap-02.png" alt="avance" className={classes.img} />
        </Paper>
      </div>
    )
  }
}

GencatResult.propTypes = {
  classes: propTypes.object.isRequired,
  handleNextStep: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  forms: propTypes.arrayOf(propTypes.object),
  markReady: propTypes.func.isRequired,
}

GencatResult.defaultProps = {
  forms: []
}

export default withRouter(withStyles(style)(GencatResult))
