import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import TextField from '../Contenders/FormTextfield'
import autobind from '../../Utils/autobind'

const styles = () => ({})
class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      description: ''
    }
    autobind(Form, this)
  }

  handleCloseWrapper() {
    const { handleClose } = this.props
    this.setState({ description: '' })
    handleClose()
  }

  handleSave() {
    const { handleSave, handleClose } = this.props
    handleSave(this.state)
    this.setState({ description: '' })
    handleClose()
  }

  handleChange(e) {
    this.setState({ description: e.target.value })
  }

  render() {
    const { handleClose, open } = this.props
    const { description } = this.state
    return (
      <Dialog open={open} onClose={handleClose} PaperProps={{ style: { width: '50vh' } }}>
        <DialogTitle>
          <Typography variant="h2">Crear tarea</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField autoFocus label="Descripción" onChange={this.handleChange} name="description" value={description} labelColor="black" />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseWrapper}> Cancelar </Button>
          <Button variant="contained" onClick={this.handleSave}> Crear </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

Form.propTypes = {
  open: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
  handleSave: propTypes.func.isRequired,
}

export default withStyles(styles)(Form)
