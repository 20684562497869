import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import { HelpOutline } from '@material-ui/icons'
import OnTouchTooltip from '../../../../Shared/OnTouchTooltip'

const style = theme => ({
  container: {
    padding: 12
  },
  numberContainer: {
    padding: '8px 12px',
    background: '#DCE2F2',
    width: 120,
    borderRadius: 10,
    '&>h1': {
      color: theme.palette.confirm.dark
    }
  },
  button: {
    color: theme.palette.grey.border,
    height: 20,
    width: 20,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12
  },
  centered: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  titleResult: {
    fontWeight: 900,
    fontSize: '14px',
  },
})

class LQI extends Component {
  render() {
    const { quality, classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.inline}>
          <Typography className={classes.titleResult}>Índice de calidad de vida</Typography>
          <OnTouchTooltip title="Suma de la puntuación estándar de cada de las 8 dimensiones. La escala va del 0 al 140 según la Escala Gencat.">
            <HelpOutline className={classes.button} />
          </OnTouchTooltip>
        </div>
        <div className={classes.centered}>
          <div className={classes.numberContainer}>
            <Typography variant="h1">{quality[0]}</Typography>
          </div>
        </div>
        <div style={{ textAlign: 'left' }}>
          <Typography style={{ fontSize: 12 }}>
            Para obtener el Índice de Calidad de vida primero se han de sumar las puntuaciones
            estándar de las ocho dimensiones. Este valor se convierte en el Índice de Calidad
            de vida (o puntuación estándar compuesta) mediante una tabla donde se
            deberá localizar la puntuación estándar total.
          </Typography>
        </div>
      </div>
    )
  }
}

LQI.propTypes = {
  classes: propTypes.object.isRequired,
  quality: propTypes.arrayOf(propTypes.oneOfType([propTypes.number, propTypes.string]))
}

LQI.defaultProps = {
  quality: []
}

export default withStyles(style)(LQI)
