/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FormsStatusContainer from './FormsStatusContainer'
import autobind from '../../Utils/autobind'
import { loadEventAction } from '../../Actions/Events'
import Conditional from '../../Shared/Conditional'

const style = () => ({
  container: {
    padding: 24,
    textAlign: 'left',
    marginTop: 57
  },
  title: {
    textAlign: 'center',
    maxWidth: 700,
    margin: 'auto',
    paddingBottom: 24
  },
  text: {
    marginBottom: 24
  },
  text_sub: {
    marginBottom: 24,
    fontSize: 16,
    fontWeight: 400
  },
  bold: {
    fontWeight: 600
  }
})

class Welcome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      amountReady: 0
    }
    autobind(Welcome, this)
  }

  componentDidMount() {
    const { handleShare, loadEvent, match } = this.props
    const contender = match.params.contender_id
    const eventID = match.params.event_id
    handleShare({ selected: contender })
    loadEvent(eventID, this.handleCallback, { fetch_groups: false, step_index: 0 })
  }

  handleSelectForm(value) {
    return () => {
      const { handleNextStep } = this.props
      handleNextStep(value)
    }
  }

  handleCallback() {
    this.setState({ loaded: true })
  }

  render() {
    const { classes, events, match, disabled } = this.props
    const { loaded, amountReady } = this.state
    if (amountReady === 2) {
      this.handleNextStep(3)()
    }
    const contender = match.params.contender_id
    let name = 'Cargando...'
    if (events.selected.participants) {
      name = events.selected.participants.find(p => p.id === contender).name
    }
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography className={classes.text} variant="h1" color="primary">
            Formularios para postulante
            {` ${loaded ? name : 'Cargando...'}`}
          </Typography>
          <Typography className={classes.text_sub} variant="h1">
            Muchas gracias por acceder a completar la información.
            Esta ficha contiene preguntas referentes a la salud de
            la persona con discapacidad que usted acompaña.La información
            aquí entregada es de carácter confidencial.
          </Typography>
          <Typography className={classes.text_sub} style={{ color: 'red' }} variant="h1">
            <span className={classes.bold}>Evitar dejar espacios en blanco.</span>
            {' '}
            Utilizar las casillas para comentar,
            y escribir "Sin información" o "No aplica" cuando corresponda.
          </Typography>
          <Typography className={classes.text_sub} style={{ color: 'red' }} variant="h1">
            Por favor recuerde responder
            {' '}
            <strong>ambas</strong>
            {' '}
            páginas de la evaluación.
          </Typography>
          <Conditional condition={disabled} hasElse>
            <Typography className={classes.text_sub} variant="caption">
              Este formulario tiene permisos solo de lectura
              y debe ser respondido por un acompañante.
              A continuación podrá revisar las respuestas entregadas hasta el momento.
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={classes.text_sub}>
              <Typography variant="h1" style={{ color: 'red' }}>
                Si hay alguna respuesta que no conoce, seleccione la opción Sin Información"

              </Typography>
              {/* <img src="/emoji_fuego.png" alt="icon" style={{ width: 32, height: 32 }} /> */}
            </div>
          </Conditional>
        </div>
        <Conditional condition={loaded}>
          <FormsStatusContainer
            event={events.selected}
            handleSelect={this.handleSelectForm}
            handleTell={this.handleTellIsReady}
            user={contender}
            disabled={disabled}
          />
        </Conditional>
      </div>
    )
  }
}

Welcome.propTypes = {
  events: propTypes.object.isRequired,
  classes: propTypes.object.isRequired,
  handleNextStep: propTypes.func.isRequired,
  handleShare: propTypes.func.isRequired,
  loadEvent: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  disabled: propTypes.bool
}

Welcome.defaultProps = {
  disabled: false
}

const mapStateToProps = state => ({
  events: state.events
})

const mapDispatchToProps = dispatch => ({
  loadEvent: (id, callback, params) => dispatch(loadEventAction(id, callback, params))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Welcome)))
