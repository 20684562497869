import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Paper, withStyles } from '@material-ui/core'
import { tabsNames } from '../fake'
import Tabs from './Tabs'
import BreadCrumbs from '../BreadCrumbs'

const style = () => ({
  container: {
    padding: 12,
  },
  tabContainer: {
    padding: 12,
    marginBottom: 12
  },
  breadcrumbs: {
    padding: 12,
    marginBottom: 24
  }
})

class ViewContainer extends Component {
  render() {
    const { children, returnToProfile, classes, selectedTab } = this.props
    return (
      <div className={classes.container}>
        <Paper className={`${classes.tabContainer} no-print`}>
          <Tabs names={tabsNames} selected={selectedTab} handler={returnToProfile} />
        </Paper>
        <Paper className={`${classes.breadcrumbs} no-print`}>
          <BreadCrumbs />
        </Paper>
        {children}
      </div>
    )
  }
}

ViewContainer.propTypes = {
  children: propTypes.node.isRequired,
  returnToProfile: propTypes.func,
  classes: propTypes.object.isRequired,
  selectedTab: propTypes.number
}

ViewContainer.defaultProps = {
  returnToProfile: undefined,
  selectedTab: 0
}

export default withStyles(style)(ViewContainer)
