import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import DownloadableSection from 'Shared/DownloadableSection'
import LineChartUser from './Charts/LineChartUser'
import WebChart from './Charts/WebChart'
import CriteriaBarChart from './Charts/CriteriaBarChart'

const style = () => ({
  headerCharts: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '&>*': {
      width: '48%'
    },
    margin: '36px 0',
    paddingBottom: 24
  }
})

class EDPSSingleCharts extends Component {
  render() {
    const { classes, data, name, contender } = this.props;
    return (
      <div className={classes.container}>
        <DownloadableSection id="head-section" name={`Resumen EDPS ${contender?.name} Ev_${name}`} margin>
          <div className={classes.headerCharts}>
            <div>
              <LineChartUser data={data} />
            </div>
            <div>
              <WebChart data={data} />
            </div>
          </div>
        </DownloadableSection>
        <DownloadableSection id="bottom" name={`Resultados por ámbitos EDPS ${contender?.name} Ev_${name}`} margin>
          <div style={{ marginTop: 64, paddingBottom: 48 }}>
            <CriteriaBarChart data={data} />
          </div>
        </DownloadableSection>
      </div>
    );
  }
}

EDPSSingleCharts.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  name: propTypes.string
}

EDPSSingleCharts.defaultProps = {
  name: '0'
}
export default withStyles(style)(EDPSSingleCharts)
