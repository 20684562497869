import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import BarChart from 'Shared/Charts/BarChart'
import DownloadButton from 'Components/Dashboard/DownloadButton'
import moment from 'moment'
import { firstUpperCase } from 'Utils/functions'

const style = () => ({
  container: {
    padding: 24,
    '& > h2': {
      fontWeight: 600,
    },
    '& > h6': {
      marginBottom: 24
    },
    borderRadius: 24
  },
  mainContainer: {
    position: 'relative',
    margin: 12,
  },
  labels: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: 12
    }
  },
  label: {
    height: 16,
    width: 32,
    borderRadius: 3,
    marginRight: 6
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center'
  }
})

const supportLevels = [
  'Sin apoyo',
  'Poco Apoyo',
  'Apoyo Ocasional',
  'Bastante Apoyo',
  'Apoyo Total'
]

class CompetencyMonthChart extends Component {
  render() {
    const { classes, data, subtitle, name } = this.props;
    const criteria = moment.months().map(month => firstUpperCase(month))
    const values = Object.values(data)
    const finalData = criteria.map((crit, index) => ({
      label: crit, value: values[index], color: '#1F8095'
    }))
    return (
      <div className={classes.mainContainer}>
        <DownloadButton title={`${name}chart`} name={`${name}-${subtitle}`} />
        <Paper className={classes.container} id={`${name}chart`}>
          <Typography variant="h2">{name}</Typography>
          <Typography variant="subtitle1">{subtitle}</Typography>
          <BarChart
            data={finalData}
            name={`performance${name}`}
            showTitle={false}
            options={{
              detachedLabels: false,
              maxValue: 5,
              minValue: 1,
              stepSize: 1,
              yEnableGrid: true,
              xEnableGrid: false,
              callback: value => `${value === -1 ? 'No ingresado' : supportLevels[value - 1]}`,
              legend: false,
              width: '100%',
              barThickness: 32,
              minRotation: 45,
              type: 'bar'
            }}
          />
        </Paper>
      </div>
    );
  }
}

CompetencyMonthChart.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
  subtitle: propTypes.string,
  name: propTypes.string
}

CompetencyMonthChart.defaultProps = {
  subtitle: '',
  name: ''
}
export default withStyles(style)(CompetencyMonthChart)
