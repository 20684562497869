import React, { Component } from 'react'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import moment from 'moment'
import Select from '../../Shared/Fields/Select'

const style = theme => ({
  container: {
    padding: 24,
    maxWidth: 600,
    margin: 'auto',
    '& > *': {
      margin: '0 0 24px'
    }
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 600
  }
})

class SelectParticipantForm extends Component {
  getParticipantsOptions() {
    const { event } = this.props
    const participants = event?.participants || []
    const options = participants
      .map(participant => ({ label: participant.name, value: participant.id }))
    return options
  }

  render() {
    const { classes, onSubmit, onChange, selected } = this.props;
    const options = this.getParticipantsOptions()
    return (
      <Paper className={classes.container}>
        <div>
          <Typography variant="h1">Escala de Calidad de Vida Familiar</Typography>
          <Typography variant="caption">{moment().format('DD [de] MMM [del] YYYY')}</Typography>
        </div>
        <Typography variant="h2" className={classes.subtitle}>Identificación de trabajador</Typography>
        <div>
          <Typography variant="subtitle1">Buscar por nombre</Typography>
          <Select onChange={onChange} complete={{ options }} value={selected} />
        </div>
        {/* <Typography variant="subtitle1">o buscar por</Typography>
        <div>
          <Typography variant="subtitle1">RUT</Typography>
          <Select complete={{}} />
        </div> */}
        <div className="right">
          <Button color="secondary" variant="contained" onClick={onSubmit} disabled={!selected}>
            Ingresar
          </Button>
        </div>
      </Paper>
    );
  }
}

SelectParticipantForm.propTypes = {
  classes: propTypes.object.isRequired,
  onSubmit: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
  event: propTypes.object,
  selected: propTypes.string,
}

SelectParticipantForm.defaultProps = {
  event: {},
  selected: ''
}

export default withStyles(style)(SelectParticipantForm)
