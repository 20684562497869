import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import DashboardTable from '../Components/External/Dashboard'
import Health from '../Components/External/Health/Health'
import ExternalCSD from '../Components/External/CSD/CSD'
import ExternalAnsweredCSD from '../Components/External/CSD/AnsweredCSD'
import AnsweredHealth from '../Components/External/Health/AnsweredHealth'
import ExternalLogin from '../Components/Login/ExternalUserLogin'

class Routes extends Component {
  componentDidMount() {
    // Check if user is admin here or has any permission
  }

  render() {
    const { user } = this.props
    return (
      <Switch>
        <PrivateRoute path="/external/:userId/csd" component={ExternalCSD} user={user} />
        <PrivateRoute path="/external/:userId/csd-answered" component={ExternalAnsweredCSD} user={user} />
        <PrivateRoute path="/external/:contenderId/health" component={Health} user={user} />
        <PrivateRoute path="/external/:userId/health-answered" component={AnsweredHealth} user={user} />
        <PrivateRoute path="/*" component={DashboardTable} user={user} />
      </Switch>
    )
  }
}

function PrivateRoute({ component: comp, user, path }) {
  if (user.current && user.current.id && user.current.origin === 1) {
    return (
      <>
        <Route exact path={path} component={comp} />
      </>
    )
  }
  return <Route exact path={path} component={ExternalLogin} />
}

PrivateRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.object, propTypes.func]).isRequired,
  user: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
}

Routes.propTypes = {
  user: propTypes.object.isRequired,
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps)(Routes)
