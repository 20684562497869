import React, { Component } from 'react'
import {
  Paper,
  withStyles,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  IconButton,
  Menu,
  Badge
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { NotificationsOutlined } from '@material-ui/icons'
import { connect } from 'react-redux'
import autobind from 'Utils/autobind'
import routes from '../../Router/routes_config'
import { grantPermission } from '../../Utils/functions'
import Notifications from '../Notifications'
import { getNotifications } from '../../API/notifications'

const style = () => ({
  container: {
    height: '100vh',
    overflowY: 'auto',
    background: '#F8F8F8',
    '@media (max-heigth:680px)': {
      paddingBottom: 56
    }
  },
  link: {
    textDecoration: 'none',
    color: '#3E3E3E',
  },
  listItem: {
    margin: 'auto',
    borderRadius: '5px',
    padding: '5px 12px',
    '&:hover': {
      background: '#38b4a92b'
    }
  },
  listIcon: {
    width: 36,
    minWidth: 0,
  },
  routeName: { color: '#3E3E3E', },
  title: {
    textAlign: 'left',
    fontSize: '16px',
    color: '#3E3E3E',
    fontWeight: 'bold',
  },
  icon: {
    color: '#3E3E3E',
  },
  selectedListItem: {
    backgroundColor: '#38B4A9',
    margin: 'auto',
    borderRadius: '5px',
    padding: '5px 12px',
    boxShadow: '-1px 1px 8px #103C469B',
    '& p': {
      color: 'white'
    },
    '& svg': {
      color: 'white'
    },
    '&:hover': {
      background: '#38b4a9bf'
    }
  },
  userName: {
    padding: '12px 12px 6px 12px',
    color: '#1a1a1a',
    fontWeight: 400
  },
  roleName: {
    padding: '0 12px 12px 12px',
    color: '#1a1a1a',
  },
  sectionTitle: {
    color: '#3E3E3E', textAlign: 'left', margin: '24px 12px 6px 12px'
  }
})

const translateRole = {
  director: 'Director/(a)',
  handler: 'Facilitador/(a)',
  admin: 'Administrador/(a)'
}
class SideBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      location: '',
      openNotifications: false,
      notifications: []
    }
    this.setLocation = this.setLocation.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    autobind(SideBar, this)
  }

  async componentDidMount() {
    this.setLocation()
    const element = document.getElementById('notifications')
    this.setState({ anchor: element })
    const response = await getNotifications()
    const { data: { info: notifications } } = response
    this.setState({ notifications })
    const self = this
    window.addEventListener('notificationArrive', async () => {
      const eventResponse = await getNotifications()
      const { data: { info: newNotifications } } = eventResponse
      self.setState({ notifications: newNotifications })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== window.location.pathname.split('/')[1]) {
      this.setLocation()
    }
  }

  handleOpen() {
    const { openNotifications } = this.state
    this.setState({ openNotifications: !openNotifications })
  }

  async handleLoadNotifications() {
    const response = await getNotifications()
    const { data: { info: notifications } } = response
    this.setState({ notifications })
  }

  setLocation() {
    const { close } = this.props
    this.setState({
      location: window.location.pathname.split('/')[1],
    })
    close()
  }

  renderList() {
    const { classes, user } = this.props
    const { location } = this.state
    return routes.map(routeInfo => {
      const info = routeInfo.sub_routes.map(route => {
        if (!grantPermission(user.current, route.roles)) { return null }
        return (
          <Link to={route.path} className={classes.link} key={route.id}>
            <ListItem
              button
              onClick={this.setLocation}
              className={route.path === `/${location}` ? classes.selectedListItem : classes.listItem}
            >
              <ListItemIcon className={classes.listIcon}>
                <route.icon classes={{ root: classes.icon }} />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body1" className={classes.routeName}>{route.name}</Typography>}
              />
            </ListItem>
          </Link>
        )
      })
      return (
        <div key={routeInfo.id}>
          {routeInfo.title
          && (
            <Typography
              variant="body2"
              className={classes.sectionTitle}
            >
              {routeInfo.title}
            </Typography>
          )}
          {info}
        </div>
      )
    })
  }

  render() {
    const { classes, user } = this.props
    const { openNotifications, anchor, notifications } = this.state
    return (
      <Paper className={`${classes.container} no-print`} square elevation={3}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 0px 24px 0px' }}>
          <div style={{ textAlign: 'left' }}>
            <img src="/logo_borde.png" alt="logo" style={{ width: '4vw', padding: 12, minWidth: 48 }} />
            <Typography variant="h2" className={classes.userName}>
              {user.current.name}
            </Typography>
            <Typography className={classes.roleName}>
              {translateRole[user.current.role]}
            </Typography>
          </div>
          <div id="notifications" style={{ marginRight: 12 }}>
            <Badge badgeContent={notifications.filter(n => !n.seen).length} color="primary">
              <IconButton color="secondary" onClick={this.handleOpen}>
                <NotificationsOutlined />
              </IconButton>
            </Badge>
            <Menu anchorEl={anchor} open={openNotifications} onClose={this.handleOpen}>
              <Notifications reload={this.handleLoadNotifications} notifications={notifications} />
            </Menu>
          </div>
        </div>
        {this.renderList()}
        <div style={{ margin: '12px 0' }} />
      </Paper>
    )
  }
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps)(withStyles(style)(SideBar))
