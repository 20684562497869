import UserCell from 'Shared/Table/UserCell'
import StatusCell from 'Shared/Table/StatusCell'

const periods = ['1 x cada 15 días', '1 x semana']

const statusesColors = {
  Aspirante: '#ffb33d',
  Postulante: '#d5602c',
  Candidato: '#FB8368',
  Trabajando: '#38b4a9',
  Egresado: '#1f8095',
  'Egresado con apoyo': '#27676A',
  Derivado: '#6A6A6A',
}
const getStatusObject = statusName => ({ name: statusName, color: statusesColors[statusName] })

const chooseRandom = array => array[Math.floor(Math.random() * array.length)]

export const contenderTestList = contenders => contenders.map(contender => {
  const info = {
    name: contender.user.name,
    program: contender.program || 'Sin programa',
    created_at: contender.contender.created_at,
    period: chooseRandom(periods),
    status: getStatusObject(contender.state_name),
    img: contender.user.photo,
    id: contender.contender.id
  }
  return info
})

function areasFormat(value) {
  if (!value.length) return 'No ingresado'
  const finalString = value.map(area => area.name).join(', ')
  return `${finalString.slice(0, 40)}${finalString.length > 40 ? '...' : ''}`
}

export const contenderInfo = [
  { name: 'Nombre', label: 'name', render: UserCell },
  { name: 'Programa', label: 'program' },
  { name: 'Estado', label: 'status', render: StatusCell },
]

export const cronogramDays = [
  { name: 'Lunes', events: [{ id: 0, title: 'Trabajo equipo Curriculum', start: 0, end: 3, type: 'Entrevistas', font: 'white' }, { id: 1, title: 'Reu Facilitadoras', start: 5, end: 5, type: 'Reunión', font: 'white' }, { id: 2, title: 'Capacitación Curriculum', start: 6, end: 6, type: 'Otro Evento', font: 'white' }] },
  { name: 'Martes', events: [{ id: 3, title: 'Reu Curriculum', start: 0, end: 0, type: 'Reunión', font: 'white' }, { id: 4, title: 'Grupo 7 Curriculum', start: 1, end: 1, type: 'Otro Evento', font: 'white' }, { id: 5, title: 'Grupo 8 Curriculum', start: 3, end: 3, type: 'Otro Evento', font: 'white' }, { id: 6, title: 'Taller Desarrollo PyS', start: 5, end: 5, type: 'Otro Evento', font: 'white' }, { id: 7, title: 'Taller Deporte 16:00', start: 6, end: 6, type: 'Otro Evento', font: 'white' }] },
  { name: 'Miércoles', events: [{ id: 8, title: 'Grupo 8 Curriculum', start: 1, end: 1, type: 'Otro Evento', font: 'white' }, { id: 9, title: 'Grupo 8 Curriculum', start: 3, end: 3, type: 'Otro Evento', font: 'white' }, { id: 10, title: 'Taller Desarrollo PyS', start: 5, end: 5, type: 'Otro Evento', font: 'white' }, { id: 11, title: 'Taller Deporte 16:00', start: 6, end: 6, type: 'Otro Evento', font: 'white' }] },
  { name: 'Jueves', events: [{ id: 12, title: 'Grupo 8 Curriculum', start: 1, end: 1, type: 'Otro Evento', font: 'white' }, { id: 13, title: 'Grupo 8 Curriculum', start: 3, end: 3, type: 'Otro Evento', font: 'white' }, { id: 14, title: 'Taller Desarrollo PyS', start: 5, end: 5, type: 'Otro Evento', font: 'white' }, { id: 15, title: 'Taller Deporte 16:00', start: 6, end: 6, type: 'Otro Evento', font: 'white' }] },
  { name: 'Viernes', events: [{ id: 16, title: 'Reu Feedback', start: 0, end: 0, type: 'Reunión', font: 'white' }, { id: 17, title: 'Jornada de Presentación', start: 1, end: 3, type: 'Jornada Presentación', font: 'white' }] }
]

export const handlerInfo = [
  { name: 'Nombre', label: 'name', render: UserCell, max_width: 150 },
  { name: 'Área(s)', label: 'areas', format: areasFormat },
  { name: 'Comuna', label: 'commune' },
  { name: 'Estado', label: 'enabled', render: StatusCell, color: 'black' },
]

export const handlerFilter = [
  { name: 'Programa', label: 'program', getter: dataObj => dataObj.program },
  { name: 'Comuna', label: 'commune', getter: dataObj => dataObj.commune },
]

export const handlerSortable = [
  { name: 'Ultima modificación', label: 'updated_at' },
  { name: 'Fecha de ingreso', label: 'created_at' }
]
