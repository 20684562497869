import React from 'react'
import './App.css'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import { withSnackbar } from 'notistack'
import { KeyboardArrowRight } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import NotificationsController from 'NotificationsController'
import Routes from './Router/Routes'
import ExternalRoutes from './Router/ExternalRoutes'
import SideBar from './Shared/Layouts/SideBar'
import autobind from './Utils/autobind'
import withMediaQuery from './Shared/withMediaQuery'
import Conditional from './Shared/Conditional'
import { logoutAction } from './Actions/Login'

const setupController = snackbar => ({
  type: 'LOAD_CONTROLLER',
  payload: snackbar,
})
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    autobind(App, this)
  }

  componentDidMount() {
    const { setController, enqueueSnackbar, logout } = this.props
    setController({ snackbar: enqueueSnackbar, logout })
    window.addEventListener('click', e => {
      const sidebar = document.getElementById('sidebar')
      if (sidebar && !sidebar.contains(e.target)) {
        this.setState({ open: false })
      }
    });
  }

  handleSidebarOpen() {
    this.setState(oldState => ({ open: !oldState.open }))
  }

  render() {
    const { user, mediaQuery } = this.props
    const { open } = this.state
    const matches = mediaQuery
    const barClass = open ? 'active-bar' : 'hidden-bar'
    const isLogged = user.current && user.current.id && user.current.role
    const loggedAndExternalUser = user.current && user.current.id && user.current.origin === 1
    const isDemo = window.location.origin.includes('dev.')
    if (loggedAndExternalUser) {
      return (
        <div className="main-content" id="mainContent">
          <ExternalRoutes />
        </div>
      )
    }
    return (
      <div className="App">
        {
          isLogged
            ? (
              <div className={matches ? 'main-grid-container' : ''}>
                <div className={matches ? 'bar' : barClass} id="sidebar">
                  <div className={matches ? 'son' : ''} style={{ zIndex: 1 }}>
                    <Conditional condition={!matches}>
                      <Fab
                        style={{ position: 'absolute', right: -52, top: 12, zIndex: 5 }}
                        onClick={this.handleSidebarOpen}
                        size="small"
                        className="no-print"
                      >
                        <KeyboardArrowRight
                          className={open ? 'icon open-icon' : 'icon'}
                          style={{ transition: 'all 0.2s linear' }}
                        />
                      </Fab>
                    </Conditional>
                    <SideBar close={this.handleSidebarOpen} />
                  </div>
                </div>

                <div className="main-content" id="mainContent">
                  {isDemo && (
                    <div className="DemoBar">
                      <Typography variant="h1">Esta es la versión DEMO</Typography>
                    </div>
                  )}
                  <Routes />
                  <NotificationsController />
                </div>
              </div>
            )
            : (
              <div className="main-content" id="mainContent">
                <Routes />
              </div>
            )
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  setController: snackbar => dispatch(setupController(snackbar)),
  logout: () => dispatch(logoutAction())
})

App.propTypes = {
  user: propTypes.object.isRequired,
  setController: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  mediaQuery: propTypes.bool.isRequired,
  logout: propTypes.func.isRequired
}

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withMediaQuery('(min-width:1100px')(App))
)
