import React, { Component } from 'react'
import { Button, withStyles, } from '@material-ui/core'
import propTypes from 'prop-types'
import { SaveRounded } from '@material-ui/icons'
import autobind from '../../../../Utils/autobind'
import './animations.css'

const style = theme => ({
  tabs: {
    fontWeight: 600
  },
  selectedTab: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    width: 130,
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      height: 2,
      width: '30%',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
      background: theme.palette.primary.main
    }
  },
  tab: {
    width: 130
  },
  showingTab: {
    padding: 12
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& > * > *': {
      width: 130
    }
  },
  buttondownload: {
    fontSize: 12,
    color: '#6A6A6A',
    background: '#ECECEC',
  },
})
class ReportTabs extends Component {
  constructor() {
    super()
    this.state = {
      selected: 0
    }
    autobind(ReportTabs, this)
  }

  handleSelectTab(selected) {
    return () => {
      this.setState({ selected })
    }
  }

  renderTabs() {
    const tabs = ['Antecedentes', 'Evaluaciones']
    const { classes } = this.props
    const { selected } = this.state
    return tabs.map((tab, index) => (
      <Button
        key={tab}
        className={selected === index ? `${classes.selectedTab} active` : classes.tab}
        onClick={this.handleSelectTab(index)}
      >
        {tab}
      </Button>
    ))
  }

  renderSelectedTab() {
    const { children } = this.props
    const { selected } = this.state
    const kids = React.Children.toArray(children)
    return kids[selected]
  }

  render() {
    const { classes, handleOpenDialog, disabled } = this.props;
    return (
      <div className={classes.container}>
        <div className={`${classes.header} no-print`}>
          <div className={classes.tabs}>
            {this.renderTabs()}
          </div>
          <div style={{ margin: 12 }}>
            {!disabled && <Button color="secondary" onClick={handleOpenDialog}>Finalizar</Button>}
            <Button className={classes.buttondownload} variant="contained" onClick={() => window.print()}>
              Descargar
              <SaveRounded />
            </Button>
          </div>
        </div>
        <div className={classes.showingTab}>
          {this.renderSelectedTab()}
        </div>
      </div>

    );
  }
}

ReportTabs.propTypes = {
  classes: propTypes.object.isRequired,
  children: propTypes.node.isRequired,
  handleOpenDialog: propTypes.func.isRequired,
  disabled: propTypes.bool
}

ReportTabs.defaultProps = {
  disabled: false
}
export default withStyles(style)(ReportTabs)
