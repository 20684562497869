import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import Participants from '../Results/Participants'
import { loadEventAction, sendAnswersAction, markFormAsFinishedAction } from '../../Actions/Events'
import { updateCertificates } from '../../API/Events'
import FormContainer from '../../Shared/FormContainer'
import autobind from '../../Utils/autobind'
import Loader from '../../Shared/Loader'
import AutoSaver from '../../Shared/AutoSaver'
import { updateCommune } from '../../API/Contenders'

const style = () => ({
  container: {
    padding: 12,
    display: 'flex',
    gridGap: 12,
    height: '100%',
    boxSizing: 'border-box'
  },
})

class Gathering extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
      params: {},
      saved: {},
      comments: {},
      savedComments: {},
    }

    this.interval = null

    autobind(Gathering, this)
  }

  componentDidMount() {
    const { events, user } = this.props
    const participants = events.selected.participants.filter(participant => participant.attended)
    const myParticipants = participants.filter(p => p.allocation === user.current.id)
    const notMyParticipants = participants.filter(p => p.allocation !== user.current.id)
    const finalParticipants = myParticipants.concat(notMyParticipants)
    if (finalParticipants[0]) {
      this.setState({ selected: finalParticipants[0].id })
    }
    this.setAnsweredQuestions()
    this.interval = setInterval(this.handleSave, 15000)
  }

  componentDidUpdate(prevProps) {
    const { events, user } = this.props
    if (JSON.stringify(prevProps.events.selected) !== JSON.stringify(events.selected)) {
      const participants = events.selected.participants.filter(participant => participant.attended)
      const myParticipants = participants.filter(p => p.allocation === user.current.id)
      const notMyParticipants = participants.filter(p => p.allocation !== user.current.id)
      const finalParticipants = myParticipants.concat(notMyParticipants)
      if (finalParticipants[0]) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ selected: finalParticipants[0].id })
      }
      this.setAnsweredQuestions()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    this.handleSave()
  }

  handleChange(event) {
    const { target } = event
    const { params, saved } = this.state
    const newSaved = { ...saved }
    const newParams = { ...params, ...newSaved }
    delete newSaved[target.name]
    if (newParams[target.name]) {
      newParams[target.name].answer = target.value
    } else {
      newParams[target.name] = { answer: target.value }
    }
    this.setState({ params: newParams, saved: newSaved })
  }

  handleSubchange(event) {
    const { target } = event
    const { params, saved } = this.state
    const newSaved = { ...saved }
    const newParams = { ...params, ...newSaved }
    const actualName = target.name.split('&')[0]
    delete newSaved[actualName]
    if (newParams[actualName].subquestions) {
      newParams[actualName].subquestions[target.name] = target.value
    } else {
      newParams[actualName] = {
        ...newParams[actualName], subquestions: { [target.name]: target.value }
      }
    }
    this.setState({ params: newParams, saved: newSaved })
  }

  handleComment(event) {
    const { target } = event
    const { comments, savedComments, saved, params } = this.state
    const newComments = { ...comments }
    const newSavedComments = { ...savedComments }
    delete newSavedComments[target.name]
    newComments[target.name] = target.value

    const newParams = { ...params }
    const newSaved = { ...saved }

    newParams[target.name] = newSaved[target.name] || newParams[target.name]
    this.setState({
      comments: newComments, savedComments: newSavedComments, params: newParams, saved: newSaved
    })
  }

  handleSave() {
    const { sendAnswers, match } = this.props
    const { params, saved, comments, savedComments } = this.state
    const newSaved = { ...saved, ...params }
    const newComments = { ...savedComments, ...comments }
    const answers = { ...params }
    const answersComments = { ...comments, ...savedComments }
    if (Object.keys(answers).length > 0) {
      const body = Object.keys(answers).map(key => ({
        id: key,
        body: answers[key],
        comment: answersComments[key],
      }))
      const { id } = match.params
      this.setState({ saved: newSaved, params: {}, savedComments: newComments, comments: {} })
      sendAnswers({ answers: body }, id)
      return true
    }
    return false
  }

  handleSelect(id) {
    this.setState({ selected: id })
    this.handleSave()
  }

  handleCheck() {
    const { markFormAsFinished, enqueueSnackbar, match, events } = this.props
    const { id } = match.params
    const participants = events.selected.participants.filter(participant => participant.attended)
    participants.forEach(participant => {
      events.selected.forms_id.forEach(form => {
        const body = {
          form_id: form.id,
          user_id: participant.id,
          event_id: id,
          status: 'check'
        }
        markFormAsFinished(body, enqueueSnackbar)
      })
    })
  }

  handleEnd() {
    this.handleSave()
    this.handleCheck()
    const { history, match: { params: { id } }, events } = this.props
    updateCertificates(id)
    updateCommune({ event_id: id })
    const formId = events.selected.forms_id[0].id
    history.push(`/events/${id}/result/${formId}`)
  }

  setAnsweredQuestions() {
    const { events } = this.props
    const { selected } = events
    const { forms } = selected
    const { saved, savedComments } = this.state
    const newSaved = { ...saved }
    const newSavedComments = { ...savedComments }
    if (forms) {
      Object.keys(forms).forEach(formGroupKey => {
        const formGroup = forms[formGroupKey]
        Object.keys(formGroup).forEach(formKey => {
          const form = formGroup[formKey]
          Object.keys(form).forEach(criteriumKey => {
            const criterium = form[criteriumKey]
            criterium.forEach(question => {
              const { answers } = question
              answers.forEach(answer => {
                if (answer.body !== null) {
                  newSaved[answer.id] = answer.body
                }
                if (answer.comment !== null) {
                  newSavedComments[answer.id] = answer.comment
                }
              })
            })
          })
        })
      })
      this.setState({ saved: newSaved, savedComments: newSavedComments })
    }
  }

  render() {
    const { classes, events, loadEvent, match } = this.props
    const { id } = match.params
    const { selected, params, saved, comments, savedComments } = this.state
    const participants = events.selected.participants.filter(participant => participant.attended)
    const selectedParticipant = participants.find(p => p.id === selected)
    const participantName = selectedParticipant ? selectedParticipant.name : ''
    const { forms } = events.selected || []
    let formInfo = {}
    if (forms) {
      formInfo = { ...forms[Object.keys(forms)[0]] }
    }
    return (
      <div className={classes.container}>
        <Loader action={loadEvent} params={[id, null, { form: 'gathering' }]}>
          <>
            <div style={{ minWidth: window.innerWidth > 800 ? '15vw' : '25vw', marginRight: 12 }}>
              <Participants
                data={participants}
                selected={selected}
                select={this.handleSelect}
                showFinish
                showActions
                submit={this.handleEnd}
              />
            </div>
            <div style={{ flexGrow: 1 }}>

              <FormContainer
                form={formInfo}
                userId={selected}
                userName={participantName}
                handleChange={this.handleChange}
                handleSubchange={this.handleSubchange}
                handleComment={this.handleComment}
                params={params}
                saved={saved}
                comments={comments}
                savedComments={savedComments}
                handleSave={this.handleSave}
                handleBox={this.handleBox}
              />
            </div>
            <AutoSaver onSave={this.handleSave} timeout={60000} />
          </>
        </Loader>
      </div>
    )
  }
}

Gathering.propTypes = {
  classes: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
  events: propTypes.object.isRequired,
  loadEvent: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  sendAnswers: propTypes.func.isRequired,
  history: propTypes.object.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  markFormAsFinished: propTypes.func.isRequired
}

const mapStateToProps = state => ({
  events: state.events,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  loadEvent: (id, callback, query) => dispatch(loadEventAction(id, callback, query)),
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id)),
  markFormAsFinished: (body, snackbar) => dispatch(markFormAsFinishedAction(body, snackbar))
})

export default withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Gathering)))
