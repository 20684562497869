/* eslint-disable import/prefer-default-export */
import instance from '../Utils/client'

export const getNotifications = () => instance({
  method: 'get',
  url: 'notifications',
})

export const markNotificationsAsSeen = () => instance({
  method: 'put',
  url: 'notifications/seen',
})
