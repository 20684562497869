import instance from '../Utils/client'
import createSnackbar from '../Utils/snackbars'

// Deploy test
const loadContendersAction = () => ({
  type: 'LOAD_CONTENDERS',
  payload: instance({
    method: 'get',
    url: '/contenders',
  }),
})

const loadContenderAction = id => ({
  type: 'LOAD_CONTENDER',
  payload: instance({
    method: 'get',
    url: `/contenders/${id}`,
  }),
})

const loadContenderEventsAction = id => ({
  type: 'LOAD_CONTENDER_EVENTS',
  payload: instance({
    method: 'get',
    url: `/contenders/${id}/events`,
  }),
})

const searchContenderEventsAction = (id, query) => ({
  type: 'SEARCH_CONTENDER_EVENTS',
  payload: instance({
    method: 'get',
    url: `/contenders/${id}/events?query=${query}`,
  }),
})

const createContenderAction = (contender, snackbar, callback) => {
  const req = instance({
    method: 'post',
    url: '/contenders',
    data: contender,
  }).then(response => {
    callback(response)
    return response
  })
  createSnackbar(req, snackbar)
  return {
    type: 'CREATE_CONTENDER',
    payload: req,
  }
}

const commentResultAction = (id, body, snackbar) => {
  const REQUEST = instance({ method: 'post', url: `/contenders/${id}/comment`, data: body })
  createSnackbar(REQUEST, snackbar)
  return {
    type: 'COMMENT_RESULT',
    payload: REQUEST,
  }
}

const contactCreationAction = (id, body) => {
  const REQUEST = instance({ method: 'post', url: `/contenders/${id}/contact`, data: body })
  return {
    type: 'CREATE_CONTACT',
    payload: REQUEST,
  }
}

const getContenderFormsAction = id => {
  const REQUEST = instance({
    method: 'get',
    url: `/contenders/${id}/forms`,
  })
  return {
    type: 'GET_CONTENDER_FORMS',
    payload: REQUEST
  }
}

const getFormAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `/events/${body.e_id}/forms/${body.f_id}`,
    params: { ...body.params }
  })
  return {
    type: 'GET_CONTENDER_FORMS',
    payload: REQUEST
  }
}

const getFormResultAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `/events/${body.e_id}/forms/${body.f_id}/result`,
    params: {
      user: body.user_id,
      type: body.type,
      short: body.short,
      with_answers: body.withanswers
    }
  })
  return {
    type: 'GET_FORM_RESULTS',
    payload: REQUEST
  }
}

const getProfileResultsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `/contenders/${body.c_id}/profile_results`,
  })
  return {
    type: 'GET_PROFILE_RESULTS',
    payload: REQUEST
  }
}

const getDynamicCriteriaFormsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `/forms/${body.form_id}/dynamic_criteria/${body.dynamic_id}`
  })
  return {
    type: 'GET_DYNAMIC_QUESTIONS',
    payload: REQUEST
  }
}

// DEPRECATED
const getResultGencatAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `contenders/${body.contender_id}/${body.route}-result`
  })
  return {
    type: 'GET_FORM_RESULTS',
    payload: REQUEST
  }
}

const getReportAnswersAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `contenders/${body.contender_id}/report_answers`
  })
  return {
    type: 'GET_REPORT_ANSWERS',
    payload: REQUEST
  }
}

const getReportInfoAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `contenders/${body.contender_id}/report-info`
  })
  return {
    type: 'GET_REPORT_INFO',
    payload: REQUEST
  }
}

const addQuestionToDynamicCriteriaAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `/dynamic_criteria/${body.criteria_id}/add_question`,
    data: body,
    params: { criterium_name: body.criterium_name }
  })
  return {
    type: 'GET_DYNAMIC_QUESTIONS',
    payload: REQUEST
  }
}

const createQuestionDynamicCriteriumAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `/dynamic_criteria/${body.criterium_id}/create_question`,
    data: body
  })
  return {
    type: 'GET_DYNAMIC_QUESTIONS',
    payload: REQUEST
  }
}

const createBinnacleEntryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `/contenders/${body.c_id}/notes`,
    data: body.data,
    params: { author: body.user_id }
  })
  return {
    type: 'CREATE_BINNACLE_ENTRY',
    payload: REQUEST
  }
}

const deleteBinnacleEntryAction = body => {
  const REQUEST = instance({
    method: 'delete',
    url: `contenders/${body.c_id}/notes/${body.n_id}`,
    params: body
  })
  return {
    type: 'DELETE_BINNACLE_ENTRY',
    payload: REQUEST
  }
}

const getContenderEventsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `contenders/${body.c_id}/events`,
  })
  return {
    type: 'GET_CONTENDER_EVENTS',
    payload: REQUEST
  }
}

const editContenderAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `contenders/${body.id}/edit`,
    data: body
  })
  // TODO create snackbar
  return {
    type: 'EDIT_CONTENDER',
    payload: REQUEST
  }
}

const editFlexContenderAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `contenders/${body.id}/edit_flex`,
    data: body
  })
  return {
    type: 'EDIT_CONTENDERS',
    payload: REQUEST
  }
}

const editContenderContactAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `contenders/${body.id}/edit_contacts`,
    data: body
  })
  return {
    type: 'EDIT_CONTENDER',
    payload: REQUEST
  }
}

const editContenderPhotoAction = (body, id) => {
  const REQUEST = instance({
    method: 'post',
    url: `contenders/${id}/edit_photo`,
    data: body
  })
  return {
    type: 'EDIT_CONTENDER',
    payload: REQUEST
  }
}

const saveReportAnswerAction = body => {
  const REQUEST = instance({
    method: 'put',
    url: `contenders/${body.contender_id}/report_answers/${body.report_answer_id}`,
    data: body.request_body
  })
  return {
    type: 'SAVE_REPORT_ANSWER',
    payload: REQUEST
  }
}

const getReportResultsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: `contenders/${body.id}/report`
  })
  return {
    type: 'GET_RYS_REPORT',
    payload: REQUEST
  }
}

const checkCheckupAction = checkupId => {
  const REQUEST = instance({
    method: 'post',
    url: `checkups/${checkupId}/check`
  })
  return {
    type: 'CHECK_CHECKUP',
    payload: REQUEST
  }
}

const checkCheckupExternalAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `checkups/check_external/${body.user_id}`,
    data: { checkups: body.checkups }
  })
  return {
    type: 'CHECK_CHECKUP_EXTERNAL',
    payload: REQUEST
  }
}

const setCheckupFollowDateAction = body => {
  const REQUEST = instance({
    method: 'put',
    url: `checkups/${body.checkup_id}/follow_date`,
    data: body
  })
  return {
    type: 'SET_CHECKUP_FOLLOW_DATE',
    payload: REQUEST
  }
}

const setCheckupCommentAction = body => {
  const REQUEST = instance({
    method: 'put',
    url: `checkups/${body.checkup_id}/comment`,
    data: body
  })
  return {
    type: 'SET_CHECKUP_COMMENT',
    payload: REQUEST
  }
}

const updateGenderByAnswerAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: `contenders/${body.user_id}/update_gender_by_answer`,
  })
  return {
    type: 'UPDATE_GENDER_BY_ANSWER',
    payload: REQUEST
  }
}

export {
  addQuestionToDynamicCriteriaAction,
  createContenderAction,
  commentResultAction,
  contactCreationAction,
  createQuestionDynamicCriteriumAction,
  checkCheckupExternalAction,
  checkCheckupAction,
  createBinnacleEntryAction,
  editContenderAction,
  editContenderContactAction,
  editContenderPhotoAction,
  editFlexContenderAction,
  getContenderFormsAction,
  getFormResultAction,
  getResultGencatAction,
  getFormAction,
  getDynamicCriteriaFormsAction,
  getProfileResultsAction,
  getReportAnswersAction,
  getReportResultsAction,
  getReportInfoAction,
  loadContendersAction,
  loadContenderAction,
  loadContenderEventsAction,
  searchContenderEventsAction,
  saveReportAnswerAction,
  setCheckupFollowDateAction,
  setCheckupCommentAction,
  deleteBinnacleEntryAction,
  getContenderEventsAction,
  updateGenderByAnswerAction
}
