import React, { Component } from 'react'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import FloatingComment from 'Shared/FloatingComment'
import autobind from '../../../Utils/autobind'
import SliderInput from '../../../Shared/Fields/SliderInput'
import { sendSkillAnswerAction } from '../../../Actions/FunctionalEvaluaction'
import { allocateEvaluationAction } from '../../../Actions/Events'
import SetViewContext from '../../Contenders/Profile/SetViewProvider'

const style = theme => ({
  question: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '6px 0',
    '& > div:first-child': {
      width: '58%',
      margin: '0 1%'
    },
    '& > div:nth-child(2)': {
      width: '38%',
      margin: '0 1%'
    }
  },
  bold: {
    fontWeight: 600
  },
  date: {
    margin: '12px 0',
    textAlign: 'start',
    '& > *': {
      fontSize: '0.8rem'
    }
  },
  container: {
    padding: 12
  },
  sectionTitle: {
    padding: '6px 0',
    margin: '24px 0 12px',
    position: 'relative',
    fontWeight: 600,
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      height: 1,
      width: '100%',
      background: theme.palette.primary.main
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: 12
    }
  }
})

const steps = [
  { value: 1, label: 'Sin Apoyo', index: 0 },
  { value: 2, label: 'Poco Apoyo', index: 1 },
  { value: 3, label: 'Apoyo Ocasional', index: 2 },
  { value: 4, label: 'Bastante Apoyo', index: 3 },
  { value: 5, label: 'Apoyo Total', index: 4 },
]

class Evaluation extends Component {
  constructor() {
    super()
    this.state = {
      page: 0,
      params: {},
      comments: {},
      saved: {},
      savedComments: {}
    }
    autobind(Evaluation, this)
  }

  componentDidMount() {
    this.setupPreviousAnswers()
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
    this.handleSave()
  }

  handleComment(event) {
    const { target } = event
    const { comments } = this.state
    comments[target.name] = target.value
    this.setState({ comments })
  }

  handleChangePage(option) {
    return () => {
      const { page } = this.state
      const element = document.getElementById('start')
      element.scrollIntoView({ behavior: 'smooth' })
      if (option === 'next') return this.setState({ page: page + 1 })
      return this.setState({ page: page - 1 })
    }
  }

  handleSend() {
    const { params, comments, saved, savedComments } = this.state
    const { sendSkillAnswer, match, changeStatus, evaluation, allocateEvaluation } = this.props
    const answers = { ...saved, ...params }
    const finalComments = { ...savedComments, ...comments }
    const body = Object.entries(answers).map(([key, answer]) => ({
      id: key,
      body: { answer },
      comment: finalComments[key] || '',
    }))
    const { id } = match.params
    sendSkillAnswer({ answers: body }, id)
    const statusBody = {
      evaluation_id: evaluation,
      status: 'check'
    }
    allocateEvaluation(statusBody)
    changeStatus(statusBody)
    // Return to mainView
    const { setView } = this.context
    setView()
  }

  handleSave() {
    const { params, comments, saved, savedComments } = this.state
    const { sendSkillAnswer, match } = this.props
    const answers = { ...saved, ...params }
    const finalComments = { ...savedComments, ...comments }
    const newKeys = Array.from(new Set(
      Object.keys(answers).concat(Object.keys({ ...finalComments }))
    ))
    const body = newKeys.map(key => ({
      id: key,
      body: { answer: answers[key] },
      comment: finalComments[key] || '',
    }))
    const { id } = match.params
    sendSkillAnswer({ answers: body }, id)
  }

  setupPreviousAnswers() {
    const { competency } = this.props
    const { saved, savedComments } = this.state
    const { selected_form: form } = competency
    const activities = Object.keys(form)
    const completeForm = form
    activities.forEach(activity => {
      const tasks = completeForm[activity] // Array of activities
      tasks.forEach(task => {
        const mainText = Object.keys(task).find(key => key !== 'type')
        const skill = Object.keys(task[mainText])[0]
        const answerObject = task[mainText][skill]
        if (answerObject.answer) saved[answerObject.id] = answerObject.answer
        if (answerObject.comment) savedComments[answerObject.id] = answerObject.comment
      })
    })
    this.setState({ saved, savedComments })
  }

  renderForm() {
    const { page, params, comments, saved, savedComments } = this.state
    const { competency, classes, disabled } = this.props
    const { selected_form: form } = competency
    const activities = Object.keys(form).slice(page * 5, (page + 1) * 5)
    const completeForm = form
    return activities.map(activity => {
      const tasks = completeForm[activity] // Array of activities
      const questions = tasks.map((task, index) => {
        const mainText = Object.keys(task).find(key => key !== 'type')
        const skill = Object.keys(task[mainText])[0]
        const answerObject = task[mainText][skill]
        const questionText = `${index + 1}. ${skill}: ${mainText}`
        return (
          <div className={classes.question}>
            <SliderInput
              disabled={disabled}
              onComment={this.handleComment}
              onChange={this.handleChange}
              value={params[answerObject.id] || saved[answerObject.id] || ''}
              comment={comments[answerObject.id] || savedComments[answerObject.id] || ''}
              complete={{
                steps,
                text: questionText,
                has_comment: true,
                id: 1,
                name: answerObject.id,
              }}
            />
          </div>
        )
      })
      return (
        <div className={classes.section}>
          <Typography variant="h2" className={classes.sectionTitle}>{activity}</Typography>
          {questions}
        </div>
      )
    })
  }

  render() {
    const { classes, disabled, event, evaluation } = this.props;
    const { page } = this.state
    return (
      <Paper className={classes.container}>
        <FloatingComment event={event} evaluation={evaluation} />
        <div className={classes.date}>
          <Typography variant="h4" id="start">
            Fecha de realización:
            {' '}
            {' '}
            <span className={classes.bold}>{moment(new Date()).format('DD/MM/YYYY')}</span>
          </Typography>
        </div>
        <div className={classes.form}>
          {this.renderForm()}
        </div>
        <div className={classes.buttons}>
          {page === 0
            ? (
              <>
                {!disabled && (
                  <Button color="secondary" variant="outlined" onClick={this.handleSave}>
                    Guardar
                  </Button>
                )}
                <Button color="secondary" variant="contained" onClick={this.handleChangePage('next')}>
                  Siguiente
                </Button>
              </>
            )
            : (
              <>
                <Button color="secondary" variant="contained" onClick={this.handleChangePage('prev')}>
                  Volver
                </Button>
                {!disabled && (
                  <Button color="secondary" variant="outlined" onClick={this.handleSave}>
                    Guardar
                  </Button>
                )}
                {!disabled && (
                  <Button color="primary" variant="contained" onClick={this.handleSend}>
                    Terminar
                  </Button>
                )}
              </>
            )}

        </div>
      </Paper>
    );
  }
}

Evaluation.contextType = SetViewContext

Evaluation.propTypes = {
  classes: propTypes.object.isRequired,
  competency: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  sendSkillAnswer: propTypes.func.isRequired,
  changeStatus: propTypes.func.isRequired,
  allocateEvaluation: propTypes.func.isRequired,
  evaluation: propTypes.string.isRequired,
  disabled: propTypes.bool
}

Evaluation.defaultProps = {
  disabled: false
}

const mapStateToProps = state => ({
  competency: state.competency
})

const mapDispatchToProps = dispatch => ({
  sendSkillAnswer: (body, id) => dispatch(sendSkillAnswerAction(body, id)),
  allocateEvaluation: body => dispatch(allocateEvaluationAction(body))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Evaluation))
)
