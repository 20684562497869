import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'

const style = () => ({
  container: {
    margin: '12px 0'
  }
})

class Name extends Component {
  render() {
    const { classes, form } = this.props;
    const name = form['Parte I']?.empty[0]?.answers?.body?.answer
    return (
      <div className={classes.container}>
        <Typography variant="subtitle1">{`Nombre de quien respondió: ${' '} ${name}`}</Typography>
      </div>
    );
  }
}

Name.propTypes = {
  classes: propTypes.object.isRequired,
  form: propTypes.object.isRequired
}

Name.defaultProps = {

}
export default withStyles(style)(Name)
