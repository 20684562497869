import React, { Component } from 'react'
import { Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { Edit, HelpOutline, Save } from '@material-ui/icons'
import { connect } from 'react-redux'
import autobind from '../../../../../Utils/autobind'
import UpdatedFieldRenderer from '../../../../../Shared/UpdatedFieldRenderer'
import { sendAnswersAction } from '../../../../../Actions/Events'
import OnTouchTooltip from '../../../../../Shared/OnTouchTooltip'

const style = theme => ({
  title: {
    '& > * > h2': {
      fontWeight: 600,
      fontSize: 16
    }
  },
  question: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 750,
    width: '100%',
    margin: '2px 6px',
    '& > *': {
      fontSize: 13
    }
  },
  questionText: {
    color: '#6A6A6A',
    fontSize: 12,
    fontWeight: 600
  },
  questionsContainer: {
  },
  answerText: {
    color: '#3E3E3E',
    fontSize: 14,
    fontWeight: 400
  },
  divider: {
    background: theme.palette.primary.main
  },
  big: {
    height: 2
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  inputQuestion: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '6px 0',
    '& > *': {
      flexBasis: '30%',
      fontSize: 13,
      margin: '6px 1.5%'
    }
  }
})

function getQuestionAnswer(question, editable) {
  const body = editable ? question.answers.body : question.answers[0].body
  switch (question.type) {
    case 'text': {
      if (body) {
        return body.answer
      }
      return ''
    }
    case 'select': {
      if (body) {
        const { options } = question
        const { answer } = body
        return options[answer]
      }
      return ''
    }
    case 'radio': {
      if (body) {
        const { options } = question
        const { answer } = body
        return options[answer]
      }
      return ''
    }
    default: {
      if (body) {
        return body.answer
      }
      return ''
    }
  }
}

function getSubquestionAnswer(question, subquestion, index) {
  const subquestionAnswer = Object.entries(question.answers.body.subquestions)
    .find(([id]) => parseInt(id.split('&')[1], 10) === index)[1]
  switch (subquestion.type) {
    case 'text': {
      return subquestionAnswer
    }
    case 'select': {
      const { options } = subquestion
      return options[subquestionAnswer]
    }
    case 'radio': {
      const { options } = subquestion
      const { answer } = question.answers.body
      return options[answer]
    }
    default: {
      return question.answers.body.answer
    }
  }
}

function parseQuestionToComplete(question) {
  const complete = { ...question }
  complete.id = question.answers?.id
  complete.name = question.answers?.id
  complete.type = question.type
  complete.required = 0
  complete.text = ''
  return complete
}

class EditablePrevision extends Component {
  constructor() {
    super()
    this.state = {
      editing: false,
      params: {}
    }
    autobind(EditablePrevision, this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = { ...params[target.name], answer: target.value }
    this.setState({ params })
  }

  handleSubChange(event) {
    const { target } = event
    const { params } = this.state
    const mainQuestionId = target.name.split('&')[0]
    params[mainQuestionId] = {
      ...params[mainQuestionId],
      subquestions: { ...params[mainQuestionId].subquestion, [target.name]: target.value }
    }
    this.setState({ params })
  }

  handleOpenEdit() {
    const { editing, params } = this.state
    const { form } = this.props
    if (!editing) {
      // Precharge answered questions
      const questions = form['Parte I']['Previsión de salud y emergencia']
      questions.forEach(question => {
        const name = question?.answers?.id
        const answer = question?.answers?.body?.answer
        const subquestions = question?.answers?.body?.subquestions
        params[name] = { answer, subquestions }
      })
      this.setState({ params })
    }
    this.setState({ editing: !editing })
  }

  handleSave() {
    const { event, sendAnswers, reload } = this.props
    const { params } = this.state
    const answers = { ...params }
    if (Object.keys(answers).length > 0) {
      const body = Object.keys(answers).map(key => ({
        id: key,
        body: { ...answers[key] },
      }))
      this.setState({ params: {} })
      sendAnswers({ answers: body }, event.event_id).then(() => {
        reload()
        this.setState({ editing: false })
      })
    } else {
      this.setState({ editing: false })
    }
  }

  getQuestionInput(question) {
    const { params } = this.state
    return (
      <UpdatedFieldRenderer
        onSubchange={this.handleSubChange}
        value={params[question.answers.id]?.answer || ''}
        complete={parseQuestionToComplete(question)}
        onChange={this.handleChange}
        subquestions={params[question.answers.id]?.subquestions || ''}
      />
    )
  }

  renderSubquestions(question, subquestions) {
    const { classes } = this.props
    return subquestions.map((subquestion, index) => {
      const answer = (
        <div className={classes.question}>
          <Typography variant="subtitle2" className={classes.questionText}>
            {subquestion.text}
          </Typography>
          <Typography className={classes.answerText}>
            {getSubquestionAnswer(question, subquestion, index)}
          </Typography>
        </div>
      )
      return answer
    })
  }

  renderQuestions(questions) {
    return questions.map(question => {
      let subquestions = []
      if (question.subquestions) {
        subquestions = question.subquestions
      }
      const { classes, editable } = this.props
      const answer = (
        <div className={classes.question}>
          <Typography variant="subtitle2" className={classes.questionText}>
            {question.text}
            :
          </Typography>
          <Typography
            className={classes.answerText}
          >
            {getQuestionAnswer(question, editable)}
          </Typography>
        </div>
      )
      return (
        <>
          {answer}
          {question.answers.body
            && question.answers.body.subquestions
            && question?.answers?.body?.answer === question?.expected_value?.toString()
            && this.renderSubquestions(question, subquestions)}
        </>
      )
    })
  }

  renderInputQuestions(questions) {
    return questions.map(question => {
      const { classes } = this.props
      const answer = (
        <div className={classes.inputQuestion}>
          <Typography variant="subtitle2" className={classes.questionText}>
            {question.text}
            :
          </Typography>
          {this.getQuestionInput(question)}
        </div>
      )
      return (
        <>
          {answer}
        </>
      )
    })
  }

  render() {
    const { classes, form, editable = true } = this.props;
    const { editing } = this.state
    const questions = form['Parte I']['Previsión de salud y emergencia']
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <div className={classes.inline}>
            <Typography variant="h2">Previsión de salud y emergencias</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OnTouchTooltip title="Información general del usuario">
                <HelpOutline style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              </OnTouchTooltip>
              {editable
            && (
              <>
                {editing
                  ? (
                    <IconButton className="no-print" size="small" onClick={this.handleSave}>
                      <Save />
                    </IconButton>
                  )
                  : (
                    <IconButton className="no-print" size="small" onClick={this.handleOpenEdit}>
                      <Edit />
                    </IconButton>
                  )}
              </>
            )}
            </div>
          </div>
          <Divider className={`${classes.divider} ${classes.big}`} />
        </div>
        <div className={classes.questionsContainer}>
          {editing ? this.renderInputQuestions(questions) : this.renderQuestions(questions)}
        </div>
      </div>
    );
  }
}

EditablePrevision.propTypes = {
  classes: propTypes.object.isRequired,
  form: propTypes.object.isRequired,
  reload: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  sendAnswers: propTypes.func.isRequired,
  editable: propTypes.bool
}

EditablePrevision.defaultProps = {
  editable: true
}

const mapDispatchToProps = dispatch => ({
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id)),
})

export default connect(null, mapDispatchToProps)(withStyles(style)(EditablePrevision))
