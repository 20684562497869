import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import ClockIcon from '@material-ui/icons/AccessTime'
import propTypes from 'prop-types'
import moment from 'moment'
import TextField from '../Contenders/FormTextfield'
import autobind from '../../Utils/autobind'
import { TimePicker, DatePicker } from '../../Shared/form/Fields'

const initialState = {
  title: '',
  date: moment(),
  openModules: false,
  from: moment(),
  to: moment()
}

const styles = () => ({
  content: {
    display: 'grid',
    padding: 6,
    height: '70%',
    width: '100%',
    gridTemplateRows: '1fr 1frfr 1fr',
  },
})

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
    autobind(Form, this)
  }

  handleTitleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleTimeChange(key) {
    return e => {
      this.setState({
        [key]: e,
      })
    }
  }


  handleReset() {
    this.setState(initialState)
  }

  handleDateChange(e) {
    this.setState({
      date: e,
    })
  }

  handleSave() {
    const { handleAdd, handleClose } = this.props
    const { title, date, from, to } = this.state
    const eventObject = { date,
      comment: '',
      module: from.format('HH:mm'),
      end_module: to.format('HH:mm'),
      participants: [],
      title,
      owners: [],
      type: 6 }
    handleAdd(eventObject)
    this.handleReset()
    handleClose()
  }

  handleCloseWrapper() {
    const { handleClose } = this.props
    this.handleReset()
    handleClose()
  }

  render() {
    const { classes, open, handleClose } = this.props
    const { title, date, from, to } = this.state
    return (
      <Dialog open={open} onClose={handleClose} PaperProps={{ style: { height: '300px', width: '500px' } }}>
        <DialogTitle>
          <Typography>Añadir a cronograma</Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.content}>
            <div>
              <TextField label="Título de evento" onChange={this.handleTitleChange} name="title" value={title} labelColor="black" />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '7% 83%' }}>
              <div>
                <CalendarIcon />
              </div>
              <div>
                <DatePicker label="Fecha" value={date} onChange={this.handleDateChange} style={{ cursor: 'pointer' }} />
              </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '7% 83%', alignItems: 'center' }}>
              <div>
                <ClockIcon />
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: '22% 5% 22%', alignItems: 'center' }}>
                <div>
                  <TimePicker value={from} onChange={this.handleTimeChange('from')} format="[Desde] HH:mm" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  -
                </div>
                <div>
                  <TimePicker value={to} onChange={this.handleTimeChange('to')} format="[Hasta] HH:mm" />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseWrapper}> Cancelar </Button>
          <Button variant="contained" onClick={this.handleSave}> Crear </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

Form.propTypes = {
  classes: propTypes.object.isRequired,
  open: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
  handleAdd: propTypes.func.isRequired
}

export default withStyles(styles)(Form)
