import React, { Component } from 'react'
import { Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import { Edit, HelpOutline, Save } from '@material-ui/icons'
import { connect } from 'react-redux'
import autobind from '../../../../../Utils/autobind'
import UpdatedFieldRenderer from '../../../../../Shared/UpdatedFieldRenderer'
import { sendAnswersAction } from '../../../../../Actions/Events'
import OnTouchTooltip from '../../../../../Shared/OnTouchTooltip'

const style = theme => ({
  title: {
    '& > * > h2': {
      fontWeight: 600,
      fontSize: 16
    },
    marginTop: 24
  },
  question: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 750,
    width: '100%',
    margin: '3px 0'
  },
  questionText: {
    color: '#6A6A6A',
    fontSize: 12,
    fontWeight: 'bold',
  },
  answerText: {
    color: '#3E3E3E',
    fontSize: 14,
    fontWeight: 400,
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    fontSize: 18,
    margin: '12px 0 6px 0',
  },
  divider: {
    background: theme.palette.primary.main
  },
  big: {
    height: 2
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
})

function parseQuestionToComplete(question) {
  const complete = { ...question }
  complete.id = question.answers?.id
  complete.name = question.answers?.id
  complete.type = question.type
  complete.required = 0
  complete.has_comment = true
  return complete
}

class HealthFormResult extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      comments: {},
      editing: false
    }
    autobind(HealthFormResult, this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = { ...params[target.name], answer: target.value }
    this.setState({ params })
  }

  handleComment(event) {
    const { target } = event
    const { comments } = this.state
    comments[target.name] = { ...comments[target.name], comment: target.value }
    this.setState({ comments })
  }

  handleOpenEdit() {
    const { editing, params, comments } = this.state
    const { form } = this.props
    if (!editing) {
      // Precharge answered questions
      form.forEach(question => {
        const name = question?.answers?.id
        const answer = question?.answers?.body?.answer
        const subquestions = question?.answers?.body?.subquestions
        const comment = question?.answers?.comment
        params[name] = { answer, subquestions }
        comments[name] = { comment }
      })
      this.setState({ params, comments })
    }
    this.setState({ editing: !editing })
  }

  handleSave() {
    const { event, sendAnswers, reload } = this.props
    const { params, comments } = this.state
    const answers = { ...params }
    const answersComments = { ...comments }
    if (Object.keys(answers).length > 0) {
      const body = Object.keys(answers).map(key => ({
        id: key,
        body: { ...answers[key] },
        comment: answersComments[key]?.comment,
      }))
      this.setState({ params: {} })
      sendAnswers({ answers: body }, event.event_id).then(() => {
        reload()
        this.setState({ editing: false })
      })
    } else {
      this.setState({ editing: false })
    }
  }

  renderSingleQuestion(question) {
    const { classes, editable } = this.props
    const body = editable ? question?.answers.body : question?.answers[0].body
    const answer = body ? body.answer : null
    const comment = question?.answers?.comment
    switch (question.type) {
      case 'title': {
        return (
          <div className={classes.question}>
            <Typography variant="subtitle2" className={classes.sectionTitle}>{question.text}</Typography>
          </div>
        )
      }
      case 'radio': {
        return (
          <>
            <div className={classes.question}>
              <Typography variant="subtitle2" className={classes.questionText}>{question.text}</Typography>
              <Typography className={classes.answerText}>{question.options[answer]}</Typography>
            </div>
            {comment && <Typography style={{ marginLeft: 24 }} variant="subtitle2">{comment}</Typography>}
          </>
        )
      }
      default: {
        return (
          <div className={classes.question}>
            <Typography variant="subtitle1" className={classes.questionText}>{question.text}</Typography>
            <div className={classes.answer}>
              <Typography variant="subtitle1">{question?.answers.body ? question.answers.body.answer : ''}</Typography>
            </div>
          </div>
        )
      }
    }
  }

  renderQuestions(questions) {
    return questions.map(question => this.renderSingleQuestion(question))
  }

  renderQuestionInputs(questions) {
    const { params, comments } = this.state
    return questions.map(question => (
      <UpdatedFieldRenderer
        onSubchange={this.handleSubChange}
        value={params[question.answers.id]?.answer || ''}
        comment={comments[question.answers.id]?.comment || ''}
        complete={parseQuestionToComplete(question)}
        onChange={this.handleChange}
        onComment={this.handleComment}
        subquestions={params[question.answers.id]?.subquestions || ''}
      />
    ))
  }

  render() {
    const { editing } = this.state
    const { classes, form, title, editable, tooltip } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <div className={classes.inline}>
            <Typography variant="h2">{title}</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {tooltip && (
                <OnTouchTooltip title={tooltip}>
                  <HelpOutline style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </OnTouchTooltip>
              )}
              {editable
            && (
              <>
                {editing
                  ? (
                    <IconButton className="no-print" size="small" onClick={this.handleSave}>
                      <Save />
                    </IconButton>
                  )
                  : (
                    <IconButton className="no-print" size="small" onClick={this.handleOpenEdit}>
                      <Edit />
                    </IconButton>
                  )}
              </>
            )}
            </div>
          </div>
          <Divider className={`${classes.divider} ${classes.big}`} />
        </div>
        {editing
          ? this.renderQuestionInputs(form)
          : this.renderQuestions(form)}
      </div>
    );
  }
}

HealthFormResult.propTypes = {
  classes: propTypes.object.isRequired,
  form: propTypes.object.isRequired,
  title: propTypes.string,
  sendAnswers: propTypes.func.isRequired,
  reload: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  editable: propTypes.bool,
  tooltip: propTypes.string
}

HealthFormResult.defaultProps = {
  title: 'Titulo',
  editable: true,
  tooltip: ''
}

const mapDispatchToProps = dispatch => ({
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id)),
})

export default connect(null, mapDispatchToProps)(withStyles(style)(HealthFormResult))
